<template>
  <main>
    <div
      v-if="userIsAuthenticated === false"
      data-v-a23099b6=""
      data-v-67cdb7dd=""
      class="mv2 pa3 br1 flex justify-between f4 b--red bg-washed-red"
    >
      <div
        data-v-a23099b6=""
        class="fw5 mid-gray dark-red"
      >
        <span
          data-v-67cdb7dd=""
          data-v-a23099b6=""
        >Your account still has not been activated. Please complete activation before filing a claim. </span>
        <a
          data-v-67cdb7dd=""
          href="/account-activation/step1"
          class="color-theme"
          data-v-a23099b6=""
        >Click here to
          complete setup.</a>
      </div>
    </div>
    <div v-if="!userHasAccess">
      <h1 class="f2 lh-copy pt3">
        Your Claims
      </h1>
      <ak-alert
        v-if="user.membership_status === 'CANCELED'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing
          team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'UNPAID'"
        color="red"
      >
        <span>Your coverage plan is currently unpaid. Please proceed to <router-link
          to="/account/dashboard"
          class="color-theme"
        >Account Settings</router-link> to resolve or </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'PAST_DUE'"
        color="red"
      >
        <span>Your coverage plan is currently past due. Please proceed to <router-link
          to="/account/dashboard"
          class="color-theme"
        >Account Settings</router-link> to resolve or </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'BANNED'"
        color="red"
      >
        <span>Your account has been banned. If you think this was done in error, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Inquiry Regarding Banned Account"
          class="color-theme"
        >please
          contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INACTIVE'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INVALID_USER'"
        color="red"
      >
        <span>Your account has been marked as invalid by our system due to unpaid premiums or other account issues. If you think this was done in error, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>
    </div>
    <div v-else>
      <h1 class="f2 lh-copy pt3">
        {{ $t('titles.allclaims', { allclaims: 'All Claims' }) }}
      </h1>
      <div
        v-if="userHasAccess && !draft_claims.length"
        class="mv3 mw5-ns"
      >
        <ak-button
          primary
          block
          @click.native="fileclaim"
        >
          {{ $t('titles.addclaims', { addclaims: 'File New Claim' }) }}
        </ak-button>
      </div>
      <div v-if="all_claims">
        <div class="grid-one mt4 mb3 mb4-ns">
          <div
            v-for="(claim, claim_index) in all_claims"
            :key="claim_index"
            class=""
          >
            <ak-claim-card-v2
              :id="claim.id"
              :version="claim.version"
              :description="claim.description"
              :devices="claim.devices"
              :filed-on="claim.filed_on"
              :known-damage="claim.known_damage"
              :loss-day="claim.loss_day"
              :loss-month="claim.loss_month"
              :loss-type="claim.loss_type"
              :loss-year="claim.loss_year"
              :status="claim.status"
              :claim-devices="claim.claim_devices"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AkButton from './Button'
import AkClaimCardV2 from './ClaimCardV2'
import AkAlert from './Alert'
import { GET_FEATURE_FLAG, GET_USER } from '@/store/index'
import { CLAIM_V2 } from '@/constants/feature_flag'
import { CUSTOM_CLAIMS_URL } from '@/constants/ui-settings/uiSettings'
import { GET_PARTNER } from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'AkClaimsV2Page',
  components: {
    AkButton,
    AkClaimCardV2,
    AkAlert
  },
  data () {
    return {
      customClaimsUrl: CUSTOM_CLAIMS_URL
    }
  },
  computed: {
    ...mapGetters({
      partner: GET_PARTNER
    }),
    user () {
      return this.$store.state.user
    },
    all_claims () {
      return (this.$store.state.claims.claims.Open).concat(this.$store.state.claims.claims.Draft).concat(this.$store.state.claims.claims.Closed)
    },
    userHasAccess () {
      const status = this.user.membership_status
      return status !== 'BANNED' && status !== 'CANCELED' && status !== 'UNPAID' && status !== 'PAST_DUE' && status !== 'INACTIVE' && status !== 'INVALID_USER'
    },
    userIsAuthenticated () {
      return this.$store.state.claims.claims.authenticated
    },
    uiSettings () {
      const uiSettings = this.partner && this.partner.settings && this.partner.settings.ui_settings
      return uiSettings || {}
    },
    fileClaimsUrl () {
      const customClaimsUrl = this.uiSettings[this.customClaimsUrl]
      return customClaimsUrl ? '/select-loss-type' : '/file-claim'
    },
    viewClaimsUrl () {
      const customClaimsUrl = this.uiSettings[this.customClaimsUrl]
      return customClaimsUrl
    }
  },
  mounted () {
    this.$store.dispatch('getClaims')
    this.$store.dispatch('getDevices')
  },
  methods: {
    fileclaim () {
      // Temporal solution for redirect users with only devices of kind Phone to the new version of the claim.
      const featureFlagClaimV2 = this.$store.getters[GET_FEATURE_FLAG](CLAIM_V2)
      const user = this.$store.getters[GET_USER]
      const { partner_property: partnerProperty } = user
      let path = this.fileClaimsUrl
      if (featureFlagClaimV2 && featureFlagClaimV2.state === true && partnerProperty === null) {
        path = '/select-loss-type'
      }
      this.$router.push(path)
    },
    urlToViewClaimDetail (claimId, claimVersion) {
      const feature = this.$store.getters[GET_FEATURE_FLAG](CLAIM_V2)
      const user = this.$store.getters[GET_USER]
      const { partner_property: partnerProperty } = user
      let path = `/claims/${claimId}`
      if (feature && feature.state && claimVersion === 2 && (partnerProperty === null || this.viewClaimsUrl)) {
        const t = this.$store.state.token
        path = `${this.viewClaimsUrl || process.env.VUE_APP_CLAIMS_V2}claim-detail/${claimId}?t=${t}`
      }
      return path
    },
    urlToViewClaimDraft (claimId, claimVersion) {
      const feature = this.$store.getters[GET_FEATURE_FLAG](CLAIM_V2)
      const user = this.$store.getters[GET_USER]
      const { partner_property: partnerProperty } = user
      let path = `/file-claim2/${claimId}`
      if (feature && feature.state && claimVersion === 2 && (partnerProperty === null || this.viewClaimsUrl)) {
        const t = this.$store.state.token
        path = `${this.viewClaimsUrl || process.env.VUE_APP_CLAIMS_V2}affected-items/${claimId}?t=${t}`
      }
      return path
    }
  }
}
</script>

<style scoped>
</style>
