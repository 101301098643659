<template>
  <div
    :class="phoneError || isDuplicate || isInvalid ? 'container-error' : ''"
    class="flex flex-row container flex-wrap"
  >
    <div class="flex flex-column align-center info">
      <div class="tl inter f6 fw6">
        {{ deviceModel }}
      </div>
      <div class="tl inter f7 silver mt2">
        {{ planInfo }}
      </div>
    </div>
    <input
      v-model="phoneNumber"
      :class="phoneError || isDuplicate || isInvalid || (phoneNumber.trim().length !== 14 && phoneNumber.trim().length > 0) ? 'error' : 'valid'"
      :disabled="disabled"
      :maxlength="14"
      class="inter f6 input-reset ba db br2 phone-input ml3 mr3 w-100"
      placeholder="Cell Phone Number"
    >
    <div
      v-show="phoneError"
      :class="phoneError ? '' : 'invisible'"
      class="color-theme f6 position"
    >
      This phone number is already used by another account
    </div>
    <div
      v-show="isDuplicate"
      :class="isDuplicate ? '' : 'invisible'"
      class="color-theme f6 position"
    >
      Please enter a unique phone number for every plan
    </div>
    <div
      v-show="isInvalid"
      :class="isInvalid ? '' : 'invisible'"
      class="color-theme f6 position"
    >
      Please enter a valid phone number
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {checkValidNumber, formatPhoneNumber} from '@/utils'
import {GET_PARTNER_PROPERTY_ID} from '@/store/registrationv2'
import {GET_PARTNER_DEVICES, GET_PARTNER_UI_SETTINGS} from '../../../../store/registrationv2'

export default {
  name: 'PhoneForm',
  props: {
    deviceModel: String,
    planInfo: String,
    index: Number,
    setPhoneNumber: Function,
    devices: Array
  },
  data () {
    return {
      phoneNumber: '',
      // planName: this.planInfo,
      phoneError: false,
      isDuplicate: false,
      isInvalid: false
    }
  },
  methods: {
    ...mapGetters({
      getPartnerPropertyId: GET_PARTNER_PROPERTY_ID
    }),
    showError () {
      if (this.$store.state.registration.phone_errorV3.index === this.index) {
        this.phoneError = this.$store.state.registration.phone_errorV3.error
      }
    },
    checkPhone () {
      if (this.phoneNumber.trim().length > 3) {
        this.isInvalid = checkValidNumber(this.phoneNumber.trim())
      } else {
        this.isInvalid = false
      }
      if (this.phoneNumber.trim().length === 14) {
        if (!this.isInvalid) {
          this.$store.dispatch('checkPhoneExistsV3', {phone: this.phoneNumber, index: this.index})
        }
      }
    },
    checkDuplicate () {
      this.isDuplicate = this.devices.some(({phone}, index) => phone !== '' && phone === this.phoneNumber && index !== this.index)
    },
    setPhone () {
      if (!this.phoneError && !this.isDuplicate && !this.isInvalid) {
        this.setPhoneNumber(this.phoneNumber.trim(), this.index)
      } else {
        this.setPhoneNumber('', this.index)
      }
      if (this.phoneNumber.trim().length !== 14) {
        this.setPhoneNumber('', this.index)
        this.phoneError = false
      }
    }
  },
  computed: {
    ...mapGetters({
      partnerDevices: GET_PARTNER_DEVICES,
      uiSettings: GET_PARTNER_UI_SETTINGS
    }),
    disabled () {
      if (this.uiSettings.block_edit_phone_numbers !== undefined) {
        return this.uiSettings.block_edit_phone_numbers
      }
      return false
    }
  },
  watch: {
    '$store.state.registration.phone_errorV3': 'showError',
    'phoneNumber' () {
      this.checkPhone()
      this.setPhone()
      this.checkDuplicate()
    },
    phoneError: 'setPhone',
    isDuplicate: 'setPhone',
    isInvalid: 'setPhone'
  },
  mounted () {
    // const infoArray = this.planInfo.split('-')
    // if (!(infoArray[0].includes('Akko') || infoArray[0].includes('Phone')) && infoArray[1] && infoArray[2]) {
    //   this.planName = infoArray[1] + '•' + infoArray[2]
    //   this.planName = this.planName.trim()
    // }
    if (this.getPartnerPropertyId() !== undefined) {
      this.phoneNumber = String(this.partnerDevices[this.index].phone_number)
    }
  },
  updated () {
    this.phoneNumber = formatPhoneNumber(this.phoneNumber)
  }
}
</script>

<style scoped>
.container {
  max-width: 80%;
}

.container-error {
  position: relative;
  min-height: 4rem;
}

.position {
  position: absolute;
  bottom: 0;
}

.phone-input {
  padding: 10px 15px;
  height: 3em;
  width: fit-content;
  border-color: var(--theme-gray);
}

.phone-input::placeholder {
  color: var(--theme-gray);
}

.error, .error:focus {
  border-color: var(--theme-primary);
}

.valid, .valid:focus {
  border-color: var(--theme-gray);
}

.info {
  min-width: 13em;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 1280px) {
  .phone-input {
    width: 17em;
    margin-left: 6px;
  }
}

@media screen and (max-width: 900px) {
  .phone-input {
    width: 25em;
    margin-left: 0;
    margin-top: 10px;
    height: 2.7em;
  }

  .position {
    position: relative;
  }

  .container {
    max-width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .phone-input {
    width: 90%;
    margin-top: 10px;
  }

  .container {
    max-width: 60%;
  }
}

@media screen and (max-width: 375px) {
  .container {
    max-width: 100%;
  }

  .phone-input {
    height: 2.4em;
  }
}
</style>
