<template>
  <div class="mt7 mh3 mh4-ns">
    <div class="mw8-ns center">
      <div class="tc">
        Sorry this page doesnt exists yet.
        <router-link
          to="/"
          class="color-theme"
        >
          Go to our home page
        </router-link>
        <span v-if="loggedIn"> or
          <router-link
            to="/"
            class="color-theme"
          >go to your account dashboard.</router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FourZeroFour',
  computed: {
    loggedIn () {
      return this.$store.state.token && this.$store.state.token !== ''
    }
  }
}
</script>

<style>

</style>
