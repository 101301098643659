  // hello world
<template>
  <div class="mt5 mh3 mh4-ns">
    <transition
      name="component-fade"
      mode="out-in"
      appear=""
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AkRegistrationBase',
  computed: {
  },
  mounted () {
    var refreshCases = [
      ('registration/checkout', 'registration/redeem'),
      ('registration/redeem', 'registration/checkout'),
      ('direct=y', 'direct=n'),
      ('direct=n', 'direct=y')
    ]
    const lastFullPath = this.$store.state.registration.last_full_path
    // prevent hacking
    refreshCases.forEach((unit) => {
      if (lastFullPath.includes(unit[0]) && this.$route.fullPath.includes(unit[1])) {
        this.$store.commit('RESET_PROMO_ALL')
        this.$store.commit('RESET_REDEMPTION_ALL')
        this.$store.commit('RESET_ACTIVATION_FEE')
      }
    })
    // if (this.$route.query.direct) {
    //   if (this.$route.query.direct.toLowerCase() === 'y') {
    //     this.$store.commit('SET_ACTIVATION_FEE', {
    //       applies: false,
    //       value: 0
    //     })
    //     this.$store.commit('RESET_PROMO_ALL')
    //     this.$store.commit('RESET_REDEMPTION_ALL')
    //   } else {
    //     this.$store.commit('SET_ACTIVATION_FEE', {
    //       applies: true,
    //       value: 5
    //     })
    //     this.$store.commit('RESET_PROMO_ALL')
    //     this.$store.commit('RESET_REDEMPTION_ALL')
    //   }
    // }
    this.$store.commit('SET_FULL_PATH', this.$route.fullPath)

    document.body.classList.add('bg-cream')
    this.$store.dispatch('getAllCoverageOptions')
  },
  beforeDestroy () {
    document.body.classList.remove('bg-cream')
  }
}
</script>

<style scoped>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
