<template>
  <main class="mt3 responsive-margin">
    <div class="mt3 flex flex-column inter responsive">
      <div class="flex f5 top-container-realign items-center mb2 flex-row">
        <div class="pl4 silver">
          <router-link
            class="no-underline account-hover soft-grey"
            to="/account/dashboard"
          >
            Account Settings
          </router-link>
        </div>
        <div class="soft-grey">
          &nbsp;  /  &nbsp;
        </div>
        <div class="soft-grey">
          Billing
        </div>
      </div>
      <h2 class="pl4 f2 mb3 title-realign cera-round-pro">
        Billing
      </h2>
    </div>
    <div class="flex flex-column items-center justify-center dib v-mid center tc pv4 pv5-ns ph2 tc-l">
      <img
        src="@/assets/appicons/Bills.svg"
        class="h4 w4 dib pa2"
        title="Bills"
      >
      <h2 class="f2 mb3 black title-realign cera-round-pro mt0">
        {{ headerText }}
      </h2>
      <span class="f5 gray inter">{{ bodyText }}</span>
      <div class="mv5-l mv4 ph2 tc-l br3 background inter">
        <div
          v-show="partnerPropertyWebsite !== undefined"
          class="partner-info"
        >
          <a
            class="black no-underline inline-flex items-center ma2 br2 pa2"
            :href="partnerPropertyWebsite"
            target="_blank"
            rel="noopener noreferrer"
            title="Website"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 0C5.1435 0 3.36311 0.737492 2.05025 2.05025C0.73752 3.36309 0 5.1437 0 7C0 8.8563 0.737492 10.6369 2.05025 11.9498C3.36308 13.2625 5.1437 14 7 14C8.8563 14 10.6369 13.2625 11.9498 11.9498C13.2625 10.6369 14 8.8563 14 7C13.998 5.14407 13.2599 3.36483 11.9475 2.05253C10.6352 0.74012 8.85579 0.00205713 7.00006 5.71119e-05L7 0ZM0.999951 7.00006C1.00006 6.49391 1.06446 5.98991 1.19147 5.50005H4.07064C4.0251 5.99659 4.0001 6.50005 4.0001 7.00006C4.0001 7.50006 4.0251 8.00352 4.07064 8.50007H1.19147C1.06446 8.01021 1.00006 7.5062 0.999951 7.00006ZM4.99998 7.00006C4.99998 6.46857 5.02599 5.97059 5.06952 5.50005H8.93069C8.97422 5.97059 9.00022 6.46857 9.00022 7.00006C9.00022 7.53155 8.97422 8.02952 8.93069 8.50007H5.06952C5.02599 8.02952 4.99998 7.53155 4.99998 7.00006ZM9.92945 5.50005H12.8086C13.064 6.48377 13.064 7.51635 12.8086 8.50007H9.92945C9.97499 8.00352 9.99999 7.50006 9.99999 7.00006C9.99999 6.50005 9.97499 5.99659 9.92945 5.50005ZM12.4494 4.50004H9.806C9.66113 3.36846 9.31056 2.2729 8.7715 1.26744C9.57665 1.51889 10.3201 1.93653 10.9537 2.49333C11.5874 3.05014 12.0971 3.73375 12.45 4.49992L12.4494 4.50004ZM6.99997 1.00001C7.62698 1.00001 8.4395 2.2865 8.80602 4.50004H5.19399C5.5605 2.2865 6.37302 1.00001 7.00003 1.00001H6.99997ZM5.22841 1.26753C4.68935 2.27291 4.33878 3.36844 4.19392 4.50013H1.54993C1.90284 3.73395 2.41253 3.05035 3.04625 2.49354C3.67986 1.93674 4.42326 1.51911 5.22841 1.26765V1.26753ZM1.54981 9.50017H4.19335C4.3381 10.6318 4.68878 11.7273 5.22784 12.7328C4.42269 12.4812 3.6794 12.0636 3.04591 11.5068C2.41231 10.95 1.90259 10.2663 1.54981 9.50026V9.50017ZM6.99986 13.0002C6.37285 13.0002 5.56033 11.7137 5.19381 9.50017H8.80584C8.43933 11.7137 7.62681 13.0002 6.9998 13.0002H6.99986ZM8.77141 12.7327C9.31048 11.7273 9.66105 10.6318 9.80591 9.50008H12.4499C12.097 10.2663 11.5873 10.9499 10.9536 11.5067C10.32 12.0635 9.57656 12.4811 8.77141 12.7326V12.7327Z"
                fill="#060920"
              />
            </svg>
            <span class="f6 ml3 pr2">{{ partnerPropertyWebsite }}</span>
          </a>
          <span class="dn dib-l moon-gray">|</span>
        </div>
        <div
          v-show="partnerPropertyPhone !== undefined"
          class="partner-info"
        >
          <a
            class="black no-underline inline-flex items-center ma2 br2 pa2"
            :href="phoneHref"
            title="Phone"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.9423 10.0329L12.9294 11.8311C12.9289 11.9981 12.8935 12.163 12.8255 12.3155C12.7575 12.468 12.6584 12.6046 12.5346 12.7165C12.4108 12.8285 12.2649 12.9134 12.1064 12.9657C11.9479 13.0181 11.7802 13.0367 11.614 13.0205C9.77105 12.8068 8.00387 12.1637 6.45452 11.143C5.01291 10.2123 3.7939 8.97561 2.88406 7.52073C1.88234 5.94972 1.26477 4.16484 1.08138 2.31069C1.0676 2.14482 1.0885 1.97791 1.14276 1.82057C1.19702 1.66323 1.28345 1.51891 1.39654 1.3968C1.50963 1.2747 1.6469 1.17747 1.79963 1.11133C1.95235 1.04518 2.11717 1.01156 2.2836 1.0126L4.0818 1.02556C4.37271 1.0248 4.65397 1.12984 4.87316 1.32111C5.09236 1.51239 5.23452 1.77684 5.27316 2.06517C5.34491 2.64118 5.48159 3.20722 5.68059 3.7525C5.75969 3.96762 5.77546 4.20091 5.72604 4.42472C5.67662 4.64853 5.56407 4.85348 5.40174 5.01529L4.63502 5.77103C5.47748 7.2778 6.71101 8.52925 8.20548 9.39335L8.9722 8.6376C9.13633 8.47762 9.34288 8.36804 9.56738 8.32185C9.79188 8.27566 10.0249 8.2948 10.2389 8.37699C10.7812 8.58384 11.3452 8.72867 11.9202 8.80871C12.211 8.85189 12.4759 9.00046 12.6644 9.22618C12.8528 9.4519 12.9518 9.73902 12.9423 10.0329Z"
                stroke="#060920"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="f6 ml3 pr2">{{ partnerPropertyPhone }}</span>
          </a>
          <span class="dn dib-l moon-gray">|</span>
        </div>
        <div
          v-show="partnerPropertyEmail !== undefined"
          class="partner-info"
        >
          <a
            class="black no-underline inline-flex items-center ma2 br2 pa2"
            :href="emailHref"
            title="Email"
          >
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.9897 2.26124C16.9958 1.41582 16.2846 0.718949 15.4094 0.712639L2.6783 0.620849C1.80304 0.614538 1.08193 1.30108 1.07583 2.14651L1.00934 11.3693C1.00324 12.2147 1.71438 12.9116 2.58964 12.9179L15.3207 13.0097C16.196 13.016 16.9171 12.3295 16.9232 11.484L16.9897 2.26124ZM15.3983 2.24977L9.00504 6.04671L2.66722 2.15798L15.3983 2.24977ZM15.3318 11.4726L2.60072 11.3808L2.65613 3.69511L8.99396 7.58384L15.3872 3.7869L15.3318 11.4726Z"
                fill="#060920"
                stroke="white"
                stroke-width="0.2"
              />
            </svg>
            <span class="f6 ml3 pr2">{{ partnerPropertyEmail }}</span>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { brandingAllowed } from '@/services/partnerPermissions'
import { GET_PARTNER } from '@/store'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AkUnifiedBillingPartners',
  computed: {
    ...mapState({
      partnerPropertyName: state => state.user.partner_property.name,
      partnerPropertyWebsite: state => state.user.partner_property.website,
      partnerPropertyPhone: state => state.user.partner_property.phone_no,
      partnerPropertyEmail: state => state.user.partner_property.email
    }),
    ...mapGetters({
      partner: GET_PARTNER
    }),
    emailHref () {
      return `mailto:${this.partnerPropertyEmail}?subject=Request&body=Hi!%20I%20would%20like%20to`
    },
    phoneHref () {
      return `tel:+${this.partnerPropertyPhone}`
    },
    headerText () {
      return brandingAllowed(this.partner) ? 'Your Billing' : 'Your billing is managed by one of our trusted partners'
    },
    bodyText () {
      return brandingAllowed(this.partner) ? 'Please click the link below or reach out to the listed contact phone/email to make updates to your billing info or plans.' : 'Please sign in to your account through their platform or contact them directly for billing details'
    }
  }
}
</script>

<style scoped>

.responsive {
  width: 55%;
}

.title-realign {
  margin-left: -3px
}

.top-container-realign {
  margin-top: 1px;
}



.account-hover {
  cursor: pointer;
}

.account-hover:hover {
  color: #7A7A7C;
}

.soft-grey {
  color: #9BA1AB;
}

.chevron {
  color: #9BA1AB;
  height: 17px;
  width: 17px;
}

.account-hover {
  cursor: pointer;
}

.account-hover:hover {
  color: #7A7A7C;
  text-decoration: underline;
}
.background {
  background-color: var(--theme-light-gray);
}

@media (max-width: 1280px) {

  .responsive-margin {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .responsive-none {
    display: none;
  }

  .responsive {
    width: 100%;
  }

  .responsive-margin {
    margin-top: 0;
  }
}

.partner-info {
  display: inline-flex;
  text-align: center;
  align-items: center;
}
</style>
