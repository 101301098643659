<template>
  <main>
    <div class="flex items-end ">
      <span class="f2 b title-responsive">Add new item</span>
    </div>
    <div v-if="!userHasAccess">
      <ak-alert
        v-if="user.membership_status === 'CANCELED'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          class="color-theme"
          :href="'mailto:'+SUPPORT_EMAIL+'?Subject='+RE_ENABLE_PLAN"
        >please contact our billing
          team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'UNPAID'"
        color="red"
      >
        <span>Your coverage plan is currently unpaid. Please proceed to <router-link
          class="color-theme"
          to="/account/dashboard"
        >Account Settings</router-link> to resolve or </span>
        <a
          class="color-theme"
          :href="'mailto:'+SUPPORT_EMAIL+'?Subject='+RE_ENABLE_PLAN"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'PAST_DUE'"
        color="red"
      >
        <span>Your coverage plan is currently past due. Please proceed to <router-link
          class="color-theme"
          to="/account/dashboard"
        >Account Settings</router-link> to resolve or </span>
        <a
          class="color-theme"
          :href="'mailto:'+SUPPORT_EMAIL+'?Subject='+RE_ENABLE_PLAN"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'BANNED'"
        color="red"
      >
        <span>Your account has been banned. If you think this was done in error, </span>
        <a
          class="color-theme"
          :href="'mailto:'+SUPPORT_EMAIL+'?Subject=Inquiry Regarding Banned Account'"
        >please contact
          our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INACTIVE'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          class="color-theme"
          :href="'mailto:'+SUPPORT_EMAIL+'?Subject='+RE_ENABLE_PLAN"
        >please contact our billing
          team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INVALID_USER'"
        color="red"
      >
        <span>Your account has been marked as invalid by our system due to unpaid premiums or other account issues. If you think this was done in error, </span>
        <a
          class="color-theme"
          :href="'mailto:'+SUPPORT_EMAIL+'?Subject='+RE_ENABLE_PLAN"
        >please contact our billing
          team.</a>
      </ak-alert>
    </div>

    <router-link
      class="no-underline mv4 db"
      to="/add-device-category"
    >
      <div class="color-theme">
        &larr; View all categories
      </div>
    </router-link>

    <div
      v-if="isEligibleForAkkoStudentUpgrade"
      class="lh-copy fw3 pt3"
    >
      <ak-alert
        class="f5"
        color="red"
      >
        <a
          :href="`mailto:billing@getakko.com?subject=Please upgrade to AKKO Student Plan - ${$store.state.user.email}`"
          class="color-theme"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to upgrade to an {{ internalCoverageUpgrade.item_covered }} Student Plan for
          ${{ coverage_upgrade_monthly_cost }} more per month.
        </a>
      </ak-alert>
    </div>

    <div
      class="grid-fourth mt4"
      :class="[ !userHasAccess ? 'pb5' : '' ]"
    >
      <div>
        <label
          class="f6 db mb2 inter"
          for="device_type"
        >Item<span class="red">*</span></label>
        <select
          id="device_type"
          v-model="device_type"
          class="b--black-20 pa2 mb2 db w-100 br1"
          :class="{'not-allowed-text-color': isNotAllowedItemType(is_active_coverage_restrictions, coverage_restriction_details, device_type), 'placeholder': device_type === ''}"
          type="text"
          @change="changeItem($event)"
        >
          <option value="">
            Select a type of item
          </option>
          <option
            v-for="(type, index) in visibleItemTypes"
            :key="index"
            :value="type.name.toLowerCase()"
          >
            {{ type.name }}
          </option>
          <option value="other">
            Other
          </option>
        </select>
      </div>

      <div>
        <label
          class="f6 db mb2 inter"
          for="brand"
        >Brand<span class="red">*</span></label>
        <select
          v-if="device_type === 'phone'"
          id="brand"
          v-model="brand"
          :class="brand === '' ? 'placeholder' : ''"
          class="ba b--black-20 pa2 mb2 db w-100 br1"
          @change="changePhoneMake"
        >
          <option
            class="placeholder"
            value=""
          >
            Select a brand
          </option>
          <option
            v-for="(make) in allMakes"
            :key="make"
            :value="make"
          >
            {{ make }}
          </option>
        </select>
        <select
          v-else-if="brands && brands[device_type]"
          id="brand"
          v-model="brand"
          :class="brand === '' ? 'placeholder' : ''"
          class="ba b--black-20 pa2 mb2 db w-100 br1"
          @change="changeBrand"
        >
          <option
            class="placeholder"
            value=""
          >
            Select a brand
          </option>
          <option
            v-for="(name, index) in brands[device_type]"
            :key="index"
            :value="name"
          >
            {{ name }}
          </option>
          <option value="other">
            Other
          </option>
        </select>
        <input
          v-else
          id="brand"
          v-model="brand"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>

      <div>
        <label
          class="f6 db mb2 inter"
          for="model"
        >Model<span class="red">*</span></label>
        <select
          v-if="device_type === 'phone'"
          id="model"
          v-model="model"
          :class="model === '' ? 'placeholder' : ''"
          class="ba b--black-20 pa2 mb2 db w-100 br1"
          @change="changePhoneModel"
        >
          <option value="">
            Select a model
          </option>
          <option
            v-for="(model) in filterModelsBySelectedMake(brand)"
            :key="model"
            :value="model"
          >
            {{ model }}
          </option>
        </select>
        <select
          v-else-if="models && models[device_type] && models[device_type][brand]"
          id="model"
          v-model="model"
          :class="model === '' ? 'placeholder' : ''"
          class="ba b--black-20 pa2 mb2 db w-100 br1"
          @change="changeModel"
        >
          <option value="">
            Select a model
          </option>
          <option
            v-for="(name, index) in models[device_type][brand]"
            :key="index"
            :value="name"
          >
            {{ name }}
          </option>
          <option value="other">
            Other
          </option>
        </select>
        <input
          v-else
          id="model"
          v-model="model"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>

      <div v-if="!isSchoolLaptopPlan && !isSchoolIpadPlan">
        <label
          class="f6 db mb2 inter"
          for="price"
        >Purchase price<span class="red">*</span></label>
        <div class="flex">
          <input
            id="price"
            v-model.number="price"
            class="flex-grow br5-box-left input-reset ba br--left b--black-20 pa2 br1 tr"
            type="number"
            @blur="enteredPricePhoneModel"
            @focus="toggleKeyboard(true)"
            @keypress="checkPriceLength($event, price.value)"
          >
          <div class="flex items-center inter br5-box-right bg-black-10 pa2">
            .00
          </div>
        </div>
      </div>

      <div v-if="device_type === 'laptop'">
        <label
          class="f6 db mb2 inter"
          for="model_number"
        >Model
          {{ brand === 'Apple' && device_type === 'laptop' ? 'Year' : 'Number' }}</label>
        <select
          v-if="brand === 'Apple' && device_type === 'laptop'"
          id="model_number"
          v-model="model_number"
          :class="model_number === '' ? 'placeholder' : ''"
          class="ba b--black-20 pa2 mb2 db w-100 br1"
        >
          <option
            class="placeholder"
            value=""
          >
            Select a year
          </option>
          <option
            v-for="(year) in available_years"
            :key="year"
            :value="year"
          >
            {{ year }}
          </option>
        </select>
        <input
          v-else
          id="model_number"
          v-model="model_number"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>

      <div
        v-if="(device_type === 'laptop' || device_type === 'tablet' || device_type === 'tv') && !isSchoolIpadPlan"
      >
        <label
          class="f6 db mb2 inter"
          for="screen_size"
        >Screen Size</label>
        <div class="flex">
          <input
            id="screen_size"
            v-model.number="screen_size"
            class="flex-grow input-reset br5-box-left ba br--left b--black-20 pa2 mb2 br1 tr"
            type="tel"
            @blur="toggleKeyboard(false)"
            @focus="toggleKeyboard(true)"
          >
          <div class="flex items-center inter br5-box-right bg-black-10 pa2 mb2">
            in.
          </div>
        </div>
      </div>

      <div v-if="device_type === 'laptop' || device_type === 'phone'">
        <label
          class="f6 db mb2 inter"
          for="storage"
        >Storage</label>
        <div class="flex">
          <input
            id="storage"
            v-model.number="storage"
            class="flex-grow input-reset br5-box-left ba br--left b--black-20 w-60 pa2 mb2 br1 tr"
            type="tel"
            @blur="toggleKeyboard(false)"
            @focus="toggleKeyboard(true)"
          >
          <div class="flex items-center inter br5-box-right bg-black-10 pa2 mb2">
            GB
          </div>
        </div>
      </div>

      <div v-if="device_type === 'phone'">
        <label
          class="f6 db mb2 inter"
          for="carrier"
        >Carrier</label>
        <select
          id="carrier"
          v-model="carrier"
          :class="carrier === '' ? 'placeholder' : ''"
          class="b--black-20 pa2 mb2 db w-100 br1"
        >
          <option value="">
            Select a carrier
          </option>
          <option value="att">
            AT&T
          </option>
          <option value="verizon">
            Verizon
          </option>
          <option value="tmobile">
            T-Mobile
          </option>
          <option value="sprint">
            Sprint
          </option>
          <option value="spectrum">
            Spectrum
          </option>
          <option value="xfinity">
            Xfinity
          </option>
          <option value="boost">
            Boost
          </option>
          <option value="metro">
            Metro
          </option>
          <option value="straighttalk">
            Straight Talk
          </option>
          <option value="mint">
            Mint Mobile
          </option>
          <option value="cricket">
            Cricket
          </option>
          <option value="google fi">
            Google Fi
          </option>
          <option value="consumer-wireless">
            Consumer Wireless
          </option>
          <option value="other">
            Other
          </option>
        </select>
      </div>

      <div v-if="device_type === 'phone' && carrier === 'other'">
        <label
          class="f6 db mb2 inter"
          for="storage"
        >Other Carrier</label>
        <div class="flex">
          <input
            id="device_carrier_other"
            v-model="device_carrier_other"
            class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
            type="text"
            @blur="toggleKeyboard(false)"
            @focus="toggleKeyboard(true)"
          >
        </div>
      </div>

      <div v-if="device_type === 'phone'">
        <fieldset class="input-reset bn pa0 ma0">
          <legend class="inter f6 b db mb2">
            Do you pay in installments
          </legend>
          <div class="flex justify-around">
            <input
              id="installments1"
              v-model="installments"
              :value="true"
              class="dn"
              name="installments"
              type="radio"
            >
            <label
              class="flex-grow pv2 inter b br-0 ba tc br5-box-left br--left b--grey silver bg-white pointer"
              for="installments1"
              tabindex="0"
              @keyup.enter="installments=true"
            >Yes</label>
            <input
              id="installments2"
              v-model="installments"
              :value="false"
              class="dn"
              name="installments"
              type="radio"
            >
            <label
              class="flex-grow pv2 inter bl-0 b ba tc br5-box-right br--right b--grey silver bg-white pointer"
              for="installments2"
              tabindex="0"
              @keyup.enter="installments=false"
            >No</label>
          </div>
        </fieldset>
      </div>

      <div v-if="device_type === 'laptop' || device_type === 'tv'">
        <fieldset class="input-reset bn pa0 ma0">
          <legend class="f6 inter b db mb2">
            {{ device_type === 'tv' ? 'Is it a smart TV' : 'Is it touchscreen' }}
          </legend>
          <div class="flex justify-around">
            <input
              id="touchscreen1"
              v-model="touchscreen"
              :value="true"
              class="dn"
              name="touchscreen"
              type="radio"
            >
            <label
              class="flex-grow pv2 tc b ba br-0 br5-box-left b--grey silver bg-white pointer"
              for="touchscreen1"
              tabindex="0"
              @keyup.enter="touchscreen=true"
            >Yes</label>
            <input
              id="touchscreen2"
              v-model="touchscreen"
              :value="false"
              class="dn"
              name="touchscreen"
              type="radio"
            >
            <label
              class="flex-grow pv2 tc b ba bl-0 br5-box-right b--grey silver bg-white pointer"
              for="touchscreen2"
              tabindex="0"
              @keyup.enter="touchscreen=false"
            >No</label>
          </div>
        </fieldset>
      </div>

      <div v-if="!isSchoolIpadPlan">
        <label class="f6 db mb2 inter">Condition when purchased<span class="red">*</span></label>
        <select
          v-model="condition"
          class="b--black-20 pa2 mb2 db w-100 br1"
        >
          <option value="new">
            New
          </option>
          <option value="refurbished">
            Refurbished
          </option>
          <option value="used">
            Used (Gift/Private sale)
          </option>
        </select>
      </div>

      <div v-if="!isSchoolLaptopPlan && !isSchoolIpadPlan">
        <label
          class="f6 db mb2 inter"
          for="nickname"
        >Where was it purchased?<span class="red">*</span></label>
        <input
          id="purchased_at"
          v-model="purchased_at"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>

      <div v-if="!isSchoolLaptopPlan && !isSchoolIpadPlan">
        <label
          class="f6 db mb2 inter"
          for="nickname"
        >When was it purchased?<span class="red">*</span></label>
        <input
          id="purchase_date"
          v-model="purchase_date"
          :max="todayString"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="date"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>

      <div>
        <label
          class="f6 db mb2 inter"
          for="serial_number"
        >Serial Number<span
          v-if="device_type === 'tablet' || device_type === 'phone' || device_type === 'laptop'"
          class="red"
        >*</span></label>
        <input
          id="serial_number"
          v-model="serial_number"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
        <a
          v-if="device_type === 'phone'"
          class="red inter f7 no-underline"
          href="https://help.getakko.com/en/articles/4641960-how-to-find-my-serial-number-on-an-iphone-samsung-or-google-phone"
          target="_blank"
        >Click
          here to find your serial number</a>
      </div>

      <div v-if="device_type === 'phone'">
        <label
          class="f6 db mb2 inter"
          for="imei"
        >IMEI<span class="red">*</span></label>
        <input
          id="imei"
          v-model="imei"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          maxlength="16"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
        <a
          v-if="device_type === 'phone'"
          class="red inter f7 no-underline"
          href="https://help.getakko.com/en/articles/6082165-how-to-find-my-imei-number-on-an-iphone-samsung-or-google-phone"
          target="_blank"
        >Click
          here to find your IMEI number</a>
      </div>

      <div v-if="!isSchoolIpadPlan">
        <label
          class="f6 db mb2 inter"
          for="nickname"
        >Nickname<span class="red">*</span></label>
        <input
          id="nickname"
          v-model="nickname"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>

      <div v-if="isSchoolLaptopPlan">
        <div>
          <label
            class="f6 db mb2 inter"
            for="nickname"
          >Student name</label>
          <input
            id="device_student_name"
            v-model="device_student_name"
            class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
            type="text"
            @blur="toggleKeyboard(false)"
            @focus="toggleKeyboard(true)"
          >
        </div>
      </div>

      <div v-if="isSchoolLaptopPlan">
        <label
          class="f6 db mb2 inter"
          for="price"
        >Student school</label>
        <div class="flex">
          <input
            id="device_student_school"
            v-model="device_student_school"
            class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
            type="text"
            @blur="toggleKeyboard(false)"
            @focus="toggleKeyboard(true)"
          >
        </div>
      </div>
    </div>

    <ak-alert
      v-if="$store.state.errors.length"
      color="red"
    >
      <span>{{ $store.state.errors[0] }}</span>
    </ak-alert>

    <div
      v-if="userHasAccess"
      class="mt4 mb5 mw5-ns"
    >
      <ak-button
        :class="submitBtnClass"
        :disabled="!formComplete"
        block
        primary
        @click.native="addDevice"
      >
        Add Item
      </ak-button>
    </div>

    <ak-modal
      v-if="user.payment_processor === 'PARTNER' && openPlanErrorModal"
      title="Unsupported Item Type"
      @close="resetState"
    >
      <div slot="modal-body">
        <div class="mb4">
          Please refer to your plan's details for information on covered item types.
        </div>
      </div>
      <div
        slot="modal-footer"
        class="pa3 flex-ns flex-wrap justify-end"
      >
        <div class="order-1 mb3 mb0-ns">
          <router-link
            class="no-underline dib-ns"
            to="/account/dashboard"
          >
            <ak-button
              block
              primary
            >
              Go to Billing
            </ak-button>
          </router-link>
        </div>
        <div class="mr3-ns">
          <ak-button
            block
            secondary
            @click.native="resetState"
          >
            Close
          </ak-button>
        </div>
      </div>
    </ak-modal>

    <ak-modal
      v-else-if="openPlanErrorModal"
      title="Upgrade Your Plan Now to Cover More Items!"
      @close="resetState"
    >
      <div
        v-if="!isSchoolIpadPlan"
        slot="modal-body"
      >
        <plan-upgrade-card
          :title="akkoPlanUpgradeStrings.title"
          :sub-heading="akkoPlanUpgradeStrings.subHeading"
          :plan-benefits-summary="akkoPlanUpgradeStrings.planBenefitsSummary"
          :plan-benefits-line-items="akkoPlanUpgradeStrings.planBenefitsLineItems"
          :deductible-items="akkoPlanUpgradeStrings.deductibleItems"
          :price-increase="`+$${coverage_upgrade_monthly_cost}`"
        />
      </div>
      <div
        v-if="isSchoolIpadPlan"
        slot="modal-body"
      >
        <div class="mb4">
          Your plan only protects your district-issued iPads.
        </div>
      </div>
      <div
        slot="modal-footer"
        class="pa3 flex-ns flex-wrap justify-end"
      >
        <div class="order-1 mb3 mb0-ns">
          <ak-button
            block
            primary
            @click.native="debounceMethod(() => updateCoverage(internalCoverageUpgrade.id))"
          >
            Upgrade Now
          </ak-button>
        </div>
        <div class="mr3-ns">
          <ak-button
            block
            secondary
            @click.native="resetState"
          >
            Close
          </ak-button>
        </div>
      </div>
    </ak-modal>

    <ak-modal
      v-if="primaryAccountHolderWithAdditionalPlans"
      class="bold"
      title="Reminder"
      @close="resetState"
    >
      <div slot="modal-body">
        <div class="mb4">
          Each plan you registered has its own login. Each member of your group must login from their own
          phone to add their device and verify it
        </div>
        <div class="mb2">
          The following plan holders each have their own login:
        </div>
        <div
          v-for="(plan, index) in $store.state.user.related_users"
          :key="index"
        >
          {{ formattedPhone(plan.phone) }} - {{ plan.coverage_name }}
        </div>
        <br>
        <router-link
          class="no-underline dib-ns"
          target="_blank"
          to="/add-plan"
        >
          <a
            class="color-theme inter mb4"
            rel="noopener"
            target="_blank"
          >Click here if you need coverage for additional
            phones.</a>
        </router-link>
      </div>
    </ak-modal>

    <ak-modal
      v-if="primaryAccountHolderWithoutAdditionalPlans"
      title="Notice"
      @close="resetState"
    >
      <div slot="modal-body">
        <div class="mb4">
          {{ maxPhones === 1 ? 'Your plan only covers 1 phone.' : `Your Plan only covers ${maxPhones} phones` }}
        </div>
        <router-link
          class="no-underline dib-ns"
          target="_blank"
          to="/add-plan"
        >
          <a
            class="color-theme inter mb4"
            rel="noopener"
            target="_blank"
          >Click here if you need coverage for additional
            phones.</a>
        </router-link>
      </div>
    </ak-modal>

    <ak-modal
      v-if="showWrongPhoneCoverageModal"
      title="This phone is not covered by your currently selected coverage"
      @close="resetState"
    >
      <div
        v-if="!isSchoolIpadPlan"
        slot="modal-body"
      >
        <div v-if="selectedPhoneModel">
          <div
            v-if="wrongCoveragePriceDifference > 0"
            class="mb4"
          >
            Your plan will be ${{ wrongCoveragePriceDifference }} more per
            {{ user.coverage_plan_interval === 'annual' ? 'year' : 'month' }} for your new {{ selectedPhoneModel.make }}
            {{ selectedPhoneModel.model }}.
          </div>
          <div
            v-if="wrongCoveragePriceDifference < 0"
            class="mb4"
          >
            Your plan will be ${{ -wrongCoveragePriceDifference }} less per
            {{ user.coverage_plan_interval === 'annual' ? 'year' : 'month' }} for your new {{ selectedPhoneModel.make }}
            {{ selectedPhoneModel.model }}.
          </div>
        </div>
        <div v-else>
          <div
            v-if="wrongCoveragePriceDifference > 0"
            class="mb4"
          >
            Your plan will be ${{ wrongCoveragePriceDifference }} more per
            {{ user.coverage_plan_interval === 'annual' ? 'year' : 'month' }}.
          </div>
          <div
            v-if="wrongCoveragePriceDifference < 0"
            class="mb4"
          >
            Your plan will be ${{ -wrongCoveragePriceDifference }} less per
            {{ user.coverage_plan_interval === 'annual' ? 'year' : 'month' }}.
          </div>
        </div>
        <ak-button
          block
          class="w-auto"
          primary
          @click.native="debounceMethod(updateCoverage)"
        >
          Confirm
        </ak-button>
      </div>
      <div
        v-if="isSchoolIpadPlan"
        slot="modal-body"
      >
        <div class="mb4">
          Your plan only protects your district-issued iPads.
        </div>
      </div>
    </ak-modal>

    <ak-modal-with-only-body
      v-if="showNoCoveredPhones"
      @close="resetState"
    >
      <div slot="modal-body">
        <upgrade-home-plus-plan
          :coverage-upgrade="coverage_upgrade_home_plan"
          :update-coverage="updateCoverage"
          :reset-state="resetState"
          :current-coverage-price="current_coverage_price"
        />
      </div>
    </ak-modal-with-only-body>
  </main>
</template>
<script>
import AkAlert from './Alert'
import AkButton from './Button'
import AkModal from './Modal'
import axios from 'axios'
import _ from 'lodash'
import { SUPPORT_EMAIL, RE_ENABLE_PLAN } from '../constants/add-devices/addDevices'
import { akkoPlanUpgradeStrings } from '@/constants/add-plan/akkoPlanUpgrade'
import { mapActions, mapState } from 'vuex'
import PLAN_TAGS from '../constants/add-devices/planTags'
import UpgradeHomePlusPlan from './upgrade-plan/UpgradeHomePlusPlan'
import AkModalWithOnlyBody from './ModalWithOnlyBody'
import PlanUpgradeCard from '@/components/add-plan/PlanUpgradeCard'

// Properly format todays day
const d = new Date()
const year = d.getFullYear()
const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`
const day = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`
const today = `${year}-${month}-${day}`

export default {
  name: 'AkAddDevice',
  components: {
    AkButton,
    AkAlert,
    AkModal,
    UpgradeHomePlusPlan,
    AkModalWithOnlyBody,
    PlanUpgradeCard
  },
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: String,
      default: ''
    },
    types: {
      type: Array,
      default: () => []
    },
    brands: {
      type: Object,
      default: () => ({})
    },
    models: {
      type: Object,
      default: () => ({})
    },
    coverageUpgrade: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      device_type: '',
      brand: '',
      model: '',
      nickname: '',
      imei: '',
      price: '',
      serial_number: '',
      model_number: '',
      screen_size: '',
      touchscreen: false,
      storage: '',
      carrier: '',
      device_carrier_other: '',
      installments: false,
      condition: 'new',
      purchased_at: '',
      purchase_date: today,
      number: '',
      device_student_school: '',
      device_student_name: '',
      tmpPhone: null,
      updateCoverageConfirmed: false,
      SUPPORT_EMAIL: SUPPORT_EMAIL,
      RE_ENABLE_PLAN: RE_ENABLE_PLAN,
      showNoCoveredPhones: false,
      homePlusPlanDetail: [
        this.$i18n.t('planssettings.homeplusplan.feat1'),
        this.$i18n.t('planssettings.homeplusplan.feat2'),
        this.$i18n.t('planssettings.homeplusplan.feat3'),
        this.$i18n.t('planssettings.homeplusplan.feat4'),
        this.$i18n.t('planssettings.homeplusplan.feat5'),
        this.$i18n.t('planssettings.homeplusplan.feat6')
      ],
      akkoPlanUpgradeStrings: akkoPlanUpgradeStrings,
      internalCoverageUpgrade: this.coverageUpgrade,
    }
  },
  computed: {
    ...mapState({
      coverageOptions: state => state.registrationv2.registrationCoverageOptions,
      countries: state => state.registrationv2.countries,
      currencyBasedPhoneModels: state => state.registrationv2.currencyBasedPhoneModels,
      selectedCountryCurrency: state => state.registrationv2.selectedCountryCurrency,
      currentUser: state => state.user
    }),
    todayString () {
      return today
    },
    maxPhones () {
      return this.$store.state.user.max_phones
    },
    primaryAccountHolderWithAdditionalPlans () {
      return this.maxPhonesError && this.$store.state.user.primary_account_holder.id === this.$store.state.user.user_id && this.$store.state.user.related_users.length > 1
    },
    primaryAccountHolderWithoutAdditionalPlans () {
      return this.maxPhonesError && this.$store.state.user.primary_account_holder.id === this.$store.state.user.user_id && this.$store.state.user.related_users.length === 0
    },
    formComplete () {
      let basicCheck = this.device_type !== '' &&
        !this.phonePlanError &&
        !this.laptopPlanError &&
        !this.phoneLaptopTabletWatchPlanError &&
        !this.schoolLaptopPlanError &&
        !this.maxPhonesError &&
        this.brand !== '' &&
        this.model !== '' &&
        this.nickname !== '' &&
        this.condition !== '' &&
        this.purchase_date !== '' &&
        this.priceValid &&
        (this.device_type === 'laptop' || this.device_type === 'phone' || this.device_type === 'tablet' ? this.serial_number !== '' : true) &&
        (this.device_type === 'phone' ? this.imei !== '' : true)

      if (!this.isSchoolLaptopPlan) {
        basicCheck = basicCheck && this.price !== '' && this.purchased_at !== ''
      }

      if (this.isSchoolIpadPlan) {
        basicCheck = this.brand !== '' && this.model !== '' && this.serial_number !== ''
      }

      return basicCheck
    },
    priceValid () {
      return this.price !== '' && parseInt(this.price) > 0 && parseInt(this.price) <= 99999
    },
    phonePlanError () {
      return (
        this.$store.state.user.coverage_item_covered === 'PHONE' && (this.device_type && this.device_type !== 'phone')
      )
    },
    planOnlyCoverError () {
      return this.phonePlanError ? '1 phone'
      : (this.laptopPlanError ? '1 Laptop/Tablet'
      : (this.schoolLaptopPlanError ? 'laptops'
      : '1 Laptop/Tablet/Phone/SmartWatch'))
    },
    isEligibleForAkkoStudentUpgrade () {
      // If student is on phone-only plan, display upgrade to compatible AKKO Student Plan
      // only if the price difference is greater than zero.
      return (this.$store.state.user.coverage_plan_type === 'student' && this.coverage_upgrade_monthly_cost > 0 && this.$store.state.user.coverage_item_covered === 'PHONE') && this.interalCoverageUpgrade
    },
    showPriceAndPurchasePlaceFields () {
      return this.$store.state.user.coverage_item_covered === 'SCHOOL_LAPTOP' || (this.$store.state.user.coverage_item_covered === 'AKKO')
    },
    laptopPlanError () {
      return (
        this.$store.state.user.coverage_item_covered === 'LAPTOP_TABLET' &&
        (
          this.$store.state.devices.filter(d => (d.type === 'laptop' || d.type === 'tablet')).length >= 1 ||
          (this.device_type && !['laptop', 'tablet'].includes(this.device_type))
        )
      )
    },
    schoolLaptopPlanError () {
      return this.$store.state.user.coverage_item_covered === 'SCHOOL_LAPTOP' &&
        (
          (this.device_type && !['laptop'].includes(this.device_type))
        )
    },
    phoneLaptopTabletWatchPlanError () {
      return (
        this.$store.state.user.coverage_item_covered === 'PHONE_LAPTOP_TABLET_SMARTWATCH' &&
        (
          this.$store.state.devices.filter(d => (d.type === 'laptop' || d.type === 'tablet' || d.type === 'phone' || d.type === 'smartwatch')).length >= 1 ||
          (this.device_type && !['laptop', 'tablet', 'smartwatch', 'phone'].includes(this.device_type))
        )
      )
    },
    openPlanErrorModal () {
      return !this.updateCoverageConfirmed && (this.phonePlanError || this.laptopPlanError || this.phoneLaptopTabletWatchPlanError || this.schoolLaptopPlanError)
    },
    maxPhonesError () {
      return (
        this.device_type === 'phone' &&
        this.$store.state.devices.filter(d => d.type === 'phone').length >= this.maxPhones &&
        !this.has_unlimited_phone_allowed
      )
    },
    coverage_upgrade_monthly_cost () {
      const currentPlanPrice = this.$store.state.user.coverage_price
      const upgradedPlanPrice = this.interalCoverageUpgrade.price
      const costDiff = (upgradedPlanPrice - currentPlanPrice).toFixed(2)
      return this.interalCoverageUpgrade.plan_interval === 'annual' ? (costDiff / 12).toFixed(2) : costDiff
    },
    payload () {
      return {
        category: this.category,
        type: this.device_type,
        brand: this.brand,
        model: this.model,
        nickname: this.nickname,
        price: parseInt(this.price) ? parseInt(this.price) : 0,
        serial_number: this.serial_number,
        model_number: this.model_number,
        screen_size: parseInt(this.screen_size) ? parseInt(this.screen_size) : 0,
        touchscreen: this.touchscreen,
        storage: parseInt(this.storage) ? parseInt(this.storage) : 0,
        carrier: this.carrier,
        device_carrier_other: this.device_carrier_other,
        imei: this.imei,
        condition: this.condition,
        purchased_at: this.purchased_at,
        purchase_date: this.purchase_date,
        installments: this.installments,
        device_student_school: this.device_student_school,
        device_student_name: this.device_student_name
      }
    },
    submitBtnClass () {
      return {'not-allowed-cursor': !this.formComplete, 'inter': true}
    },
    user () {
      return this.$store.state.user
    },
    userHasAccess () {
      const status = this.user.membership_status
      return status !== 'BANNED' && status !== 'CANCELED' && status !== 'UNPAID' && status !== 'PAST_DUE' && status !== 'INACTIVE' && status !== 'INVALID_USER'
    },
    selectedPhoneModel () {
      if (this.device_type === 'phone' && this.brand !== '' && this.model !== '') {
        const filtered = this.currencyBasedPhoneModels
          .filter(phone => phone.make === this.brand && phone.model === this.model)

        return (filtered.length !== 0) ? filtered[0] : undefined
      }
      return undefined
    },
    selectedPhoneModelCoverage () {
      if (this.selectedPhoneModel) {
        const currentCoverageInterval = this.$store.state.user.coverage_plan_interval

        if (currentCoverageInterval === 'monthly') {
          const filtered = this.coverageOptions
            .filter(coverage => coverage.id === this.selectedPhoneModel.monthly_coverage_id)

          return filtered.length !== 0 ? filtered[0] : undefined
        }
        if (currentCoverageInterval === 'annual') {
          const filtered = this.coverageOptions
            .filter(coverage => coverage.id === this.selectedPhoneModel.annual_coverage_id)

          return filtered.length !== 0 ? filtered[0] : undefined
        }
      } else if (this.tmpPhone) {
        return this.enteredPricePhoneCoverage()
      }

      return undefined
    },
    wrongCoveragePriceDifference () {
      if (this.selectedPhoneModel) {
        // TODO: AA-615 create GET endpoint for a single coverage by id
        const currentCoverage = this.coverageOptions.find(coverage => coverage.id === this.$store.state.user.coverage_id)
        if (currentCoverage && Array.isArray(currentCoverage.tags) && currentCoverage.tags.includes(process.env.VUE_APP_EXCLUDE_VALIDATION_FOR_COVERAGE_TAG)) {
          return 0
        } else {
          if (this.selectedPhoneModelCoverage && this.$store.state.user.coverage_id !== this.selectedPhoneModelCoverage.id) {
            return this.selectedPhoneModelCoverage.price - this.$store.state.user.coverage_price
          } else if (this.tmpPhone && this.$store.state.user.coverage_id !== this.enteredPricePhoneCoverage().id) {
            return this.enteredPricePhoneCoverage().price - this.$store.state.user.coverage_price
          }
        }
      }
      return 0
    },
    showWrongPhoneCoverageModal () {
      const isAkkoPlan = this.$store.state.user.coverage_item_covered === 'AKKO'
      return !isAkkoPlan && this.wrongCoveragePriceDifference !== 0 && !this.updateCoverageConfirmed
    },
    isSchoolLaptopPlan () {
      // Hardcoding specific need for ZH Partner related users
      if (this.$store.state.user.partner_property && this.$store.state.user.partner_property.partner_id === 1829) {
        return true
      }

      return this.$store.state.user.coverage_item_covered === 'SCHOOL_LAPTOP' ||
        (this.$store.state.user.partner_property && this.$store.state.user.partner_property.property_type &&
          this.$store.state.user.partner_property.property_type.includes('K12'))
    },
    isSchoolIpadPlan () {
      // Hardcoding specific need for ZH Partner related users
      if (this.$store.state.user.partner_property && this.$store.state.user.partner_property.partner_id === 1829) {
        return true
      }

      return this.$store.state.user.coverage_item_covered === 'SCHOOL_DEVICE'
    },
    allMakes () {
      return new Set(['Apple', 'Samsung', 'Google', ...this.currencyBasedPhoneModels.map(model => model.make).sort()])
    },
    allModels () {
      return new Set(this.currencyBasedPhoneModels.map(model => model.model).sort())
    },
    phoneValueCoverages () {
      return this.currencyBasedPhoneModels.filter(model => model.make.toLowerCase() === 'other')
        .map(model => (
          {
            value: Number(model.model.split(' ')[0].replace('$', '').replace(',', '')),
            coverageId: this.$store.state.user.coverage_plan_interval === 'monthly' ? model.monthly_coverage_id : model.annual_coverage_id,
            ...model
          }
        ))
    },
    coverage_tags () {
      return this.$store.state.user.coverage_tags
    },
    is_active_coverage_restrictions () {
      return !!this.$store.state.user.coverage_restriction_active
    },
    coverage_restriction_details () {
      return this.$store.state.user.coverage_restriction_details
    },
    has_unlimited_phone_allowed () {
      return this.$store.state.user.coverage_tags.includes(PLAN_TAGS.UNLIMITED_PHONES_ALLOWED)
    },
    current_coverage_price () {
      return this.$store.state.user.coverage_price
    },
    coverage_upgrade_home_plan () {
      const currentCoverage = this.coverageOptions.find(coverage => coverage.id === this.$store.state.user.coverage_id)
      const upgradedCoverage = this.coverageOptions.find(coverage => coverage.id === currentCoverage.coverage_upgrade_path)
      return upgradedCoverage
    },
    available_years () {
      const currentYear = (new Date()).getFullYear()
      let availableYears = []
      for (let i = 0; i < 20; i++) {
        availableYears.push((currentYear - i).toString())
      }
      return availableYears
    },
    visibleItemTypes () {
      return this.types.filter((type) => {
        return !this.isNotVisibleItemType(this.is_active_coverage_restrictions, this.coverage_restriction_details, type.name)
      })
    },
  },
  async mounted () {
    this.getDevices()
    let userCountry = this.countries.find((country) => {
      return this.currentUser.country_code === country.code
    })
    this.setCurrentCountryCurrency(userCountry)
    this.getFilteredCoverageOptions({
      currency_code: this.selectedCountryCurrency.currency_code
    })
    // await this.getUpgradedCoverage() // this call might be redundant since the previous page already calls it
  },
  methods: {
    ...mapActions([
      'setCurrentCountryCurrency',
      'getFilteredCoverageOptions',
      'getDevices'
    ]),
    debounceMethod: _.debounce(function (e) {
      e()
    }, 3000, {'leading': true}),
    addDevice () {
      this.$store.dispatch('addDevice', this.payload)
    },
    resetState () {
      this.device_type = ''
      this.brand = ''
      this.model = ''
      this.price = ''
      this.tmpPhone = null
      this.updateCoverageConfirmed = false
      this.showNoCoveredPhones = false
    },
    formattedPhone (phone) {
      try {
        let num = '('
        num = num + phone.substring(0, 3) + ') '
        num = num + phone.substring(3, 6) + '-'
        num = num + phone.substring(6, 10)
        return num
      } catch (e) {
        return null
      }
    },
    acceptNumber () {
      const formattedNumber = this.number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.number = !formattedNumber[2] ? formattedNumber[1] : '(' + formattedNumber[1] + ') ' + formattedNumber[2] + (formattedNumber[3] ? '-' + formattedNumber[3] : '')
    },
    isNumber (evt) {
      evt = evt || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    checkPriceLength (evt, val) {
      evt = evt || window.event
      this.isNumber(evt)
      if (val.length >= 5) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    countDeviceTypesByItemType (itemType) {
      return this.$store.state.devices.filter((device) => device.type === itemType).length + 1
    },
    changeItem () {
      this.setShowNoCoveredPhones(this.is_active_coverage_restrictions, this.coverage_restriction_details, this.device_type)
      this.brand = ''
      this.model = ''
      this.price = ''
      this.serial_number = ''
      this.model_number = ''
      this.screen_size = ''
      this.touchscreen = false
      this.storage = ''
      this.carrier = ''
      this.device_carrier_other = ''
      this.installments = false
      this.number = ''
      this.nickname = `My ${this.device_type === 'other' ? 'Item ' + this.countDeviceTypesByItemType(this.device_type) : this.device_type.charAt(0).toUpperCase() + this.device_type.slice(1) + ' ' + this.countDeviceTypesByItemType(this.device_type)}`
      this.imei = ''
    },
    changeBrand () {
      this.model = ''
      this.price = ''
      this.serial_number = ''
      this.model_number = ''
      this.screen_size = ''
      this.touchscreen = false
      this.storage = ''
      this.carrier = ''
      this.device_carrier_other = ''
      this.number = ''
      this.installments = false
      this.imei = ''
    },
    changeModel () {
      this.price = ''
      this.serial_number = ''
      this.model_number = ''
      this.screen_size = ''
      this.touchscreen = false
      this.storage = ''
      this.carrier = ''
      this.device_carrier_other = ''
      this.imei = ''
      this.installments = false
    },
    async getUpgradedCoverage () {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/coverages/${this.$store.state.user.coverage_plan_type || 'adult'}/${this.$store.state.user.coverage_plan_interval}/`)
      this.interalCoverageUpgrade = res.data
    },
    toggleKeyboard (trueOrFalse) {
      this.$store.commit('TOGGLE_KEYBOARD', trueOrFalse)
    },
    updateCoverage (coverageId = this.selectedPhoneModelCoverage.id) {
      this.$store.dispatch('updateCoverage', {coverage_id: coverageId})
      this.resetState()
      this.updateCoverageConfirmed = true
    },
    changePhoneMake () {
      this.changeBrand()
    },
    changePhoneModel () {
      this.changeModel()
    },
    filterModelsBySelectedMake (make) {
      return new Set(this.currencyBasedPhoneModels.filter(makeModel => makeModel.make === make)
        .sort((a, b) => {
          if (a.display_order && b.display_order) {
            return a.display_order - b.display_order
          } else if (a.display_order && !b.display_order) {
            return 1
          } else if (!a.display_order && b.display_order) {
            return -1
          } else {
            return a.make.localeCompare(b.make)
          }
        }).map(makeModel => makeModel.model))
    },
    enteredPricePhoneModel () {
      this.toggleKeyboard(false)
      if (this.device_type === 'phone' && this.model.toLowerCase() === 'other' && this.price !== '') {
        const values = this.phoneValueCoverages.map(cov => cov.value)

        const max = Math.max(...values)
        const min = Math.min(...values)

        let priceCoverage

        if (this.price >= max) {
          priceCoverage = this.phoneValueCoverages.filter(option => option.value === max)
        } else if (this.price <= min) {
          priceCoverage = this.phoneValueCoverages.filter(option => option.value === min)
        } else {
          const nearestHundred = Math.ceil(this.price / 100) * 100
          priceCoverage = this.phoneValueCoverages.filter(option => option.value === nearestHundred)
        }

        this.tmpPhone = priceCoverage.length !== 0 ? priceCoverage[0] : undefined
        return this.tmpPhone
      }

      this.tmpPhone = null
      return undefined
    },
    enteredPricePhoneCoverage () {
      if (this.tmpPhone) {
        const currentCoverageInterval = this.$store.state.user.coverage_plan_interval

        if (currentCoverageInterval === 'monthly') {
          const filtered = this.coverageOptions
            .filter(coverage => coverage.id === this.tmpPhone.monthly_coverage_id)

          return filtered.length !== 0 ? filtered[0] : undefined
        }
        if (currentCoverageInterval === 'annual') {
          const filtered = this.coverageOptions
            .filter(coverage => coverage.id === this.tmpPhone.annual_coverage_id)

          return filtered.length !== 0 ? filtered[0] : undefined
        }
      }
      // this.$store.dispatch('updateCoverage', {coverage_id: this.selectedPhoneModelCoverage.id})
      window.location = 'mailto:billing@getakko.com?Subject=Please update my plan - ' + this.user.phone_no + '&body=I want to add my' + this.selectedPhoneModel.make + ' ' + this.selectedPhoneModel.model + '.'
    },
    setShowNoCoveredPhones (isActiveRestrictions, coverageRestrictionDetails, itemTypeName) {
      this.showNoCoveredPhones = this.isNotAllowedItemType(isActiveRestrictions, coverageRestrictionDetails, itemTypeName)
    },
    isNotAllowedItemType (isActiveRestrictions, coverageRestrictionDetails, itemTypeName) {
      if (!coverageRestrictionDetails) return false
      return coverageRestrictionDetails.findIndex(restriction => {
        return (
          isActiveRestrictions &&
          restriction.active && !restriction.include && !!restriction.item_type_id &&
          restriction.item_type_name.toLowerCase() === itemTypeName.toLowerCase()
        )
      }) >= 0
    },
    isNotVisibleItemType (isActiveRestrictions, coverageRestrictionDetails, itemTypeName) {
      if (!coverageRestrictionDetails) return false
      return coverageRestrictionDetails.findIndex(restriction => {
        return (
          isActiveRestrictions &&
          restriction.active && !restriction.visible && !!restriction.item_type_id &&
          restriction.item_type_name.toLowerCase() === itemTypeName.toLowerCase()
        )
      }) >= 0
    },
    upgradeHomePlusPlan () {
      this.$router.push('/add-akko-plan')
    }
  },
}
</script>

<style scoped>
.flex-basis-4 {
  flex-basis: 16em;
}

.flex-basis-3 {
  flex-basis: 12em;
}

.flex-basis-25 {
  flex-basis: 23%;
}

label, legend {
  color: #757D8A;
}

.title-responsive {
    margin-top: 1.5rem;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757D8A;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #757D8A;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #757D8A;
}

.br5-box-left {
  border-radius: 5px 0 0 5px;
}

.br5-box-right {
  border-radius: 0 5px 5px 0;
}

input, select {
  height: 40px;
  border-radius: 5px;
  border: 1px solid #E8E8E8;
}

.b--grey {
  border-color: #E8E8E8;
}

.placeholder {
  color: #7e7e7e;
}

input[type=radio]:checked + label {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
  border-radius: 5px;
}

input[type=radio]:checked + label.br5-box-left {
  margin-right: -5px;
  z-index: 1;
}

input[type=radio]:checked + label.br5-box-right {
  margin-left: -5px;
  z-index: 1;
}

.grid-fourth {
  grid-column-gap: 1.5em;
  grid-row-gap: 2.25em;
}

.not-allowed-cursor {
  cursor: not-allowed;
}

.not-allowed-text-color {
  color: #cacaca;
}

@media (max-width: 768px) {

  .title-responsive {
    font-size: 24px;
    margin-bottom: -10px;
    margin-top: 1rem;
  }
}

/* Hide input number arrows in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide input number arrows in  Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
