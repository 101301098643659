import axios from 'axios'
import {
  SUBMIT_NAME,
  SUBMIT_SCHOOL,
  SUBMIT_ADDRESS,
  SUBMIT_APT,
  SUBMIT_HEAR_ABOUT_US,
  SUBMIT_COVERAGE,
  SUBMIT_BIRTHDAY,
  SUBMIT_ALT_EMAIL,
  SUBMIT_EMAIL,
  SUBMIT_MOST_EXPENSIVE,
  CLEAR_ACTIVATION,
  SET_ERRORS,
  SET_LOADING,
  SET_JUST_ACTIVATED,
  SET_COMPLETE,
  SUBMIT_PREVIOUS_PROVIDER,
  SUBMIT_STUDENT_CHECK,
  SET_SUCCESS
} from './mutations'
import * as Sentry from '@sentry/vue'

const api = process.env.VUE_APP_API_URL

const activation = {
  state: {
    coverage: {
      id: 0,
      name: '',
      price: 0,
      max_coverage: 0
    },
    // school: '',
    schoolType: 'undergrad',
    year: '',
    fname: '',
    lname: '',
    email: '',
    email_alt: '',
    phone: '',
    student: '',
    dateOfBirth: {
      day: '',
      month: '',
      year: ''
    },
    studentVerification: {
      school: '',
      school_verification_file: null,
      type: '',
      year: '',
      student: ''
    },
    address: {
      formatted_address: '',
      res_type: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      apt: '',
      rent_own: '',
      business_name: ''
    },
    switching_protection: false,
    past_protection_provider: '',
    mostExpensiveItem: {
      brand: '',
      model: '',
      screenSize: '',
      mostExpensive: 'laptop',
      mostExpensiveText: '',
      price: 2000
    },
    hear_about_us: {
      how: '',
      other: ''
    },
    complete: false,
    loading: false,
    errors: null,
    success: false
  },
  mutations: {
    [SUBMIT_NAME] (state, payload) {
      state.fname = payload.fname
      state.lname = payload.lname
    },
    [SUBMIT_PREVIOUS_PROVIDER] (state, payload) {
      state.switching_protection = payload.switching_protection
      state.past_protection_provider = payload.past_protection_provider
    },
    [SUBMIT_SCHOOL] (state, payload) {
      // state.school = payload.school
      state.schoolType = payload.schoolType
      state.year = payload.year
    },
    [SUBMIT_BIRTHDAY] (state, payload) {
      state.dateOfBirth.day = payload.dob_day
      state.dateOfBirth.month = payload.dob_month
      state.dateOfBirth.year = payload.dob_year
    },
    [SUBMIT_ADDRESS] (state, payload) {
      state.address = {
        formatted_address: `${payload.street} ${payload.apt} ${payload.city}, ${payload.state} ${payload.zip}`,
        res_type: payload.res_type,
        street: payload.street,
        apt: payload.apt,
        city: payload.city,
        state: payload.state,
        zip: payload.zip,
        rent_own: payload.rent_own,
        business_name: payload.business_name
      }
    },
    [SUBMIT_APT] (state, payload) {
      state.address.apt = payload.apt
    },
    [SUBMIT_HEAR_ABOUT_US] (state, payload) {
      state.hear_about_us.how = payload.hear_about_us
      state.hear_about_us.other = payload.hear_about_us_other
    },
    [SUBMIT_COVERAGE] (state, payload) {
      state.coverage = payload
    },
    [SUBMIT_ALT_EMAIL] (state, payload) {
      state.email_alt = payload.email_alt
    },
    [SUBMIT_EMAIL] (state, payload) {
      state.email = payload.email
    },
    [SUBMIT_MOST_EXPENSIVE] (state, payload) {
      state.mostExpensiveItem = {
        ...state.mostExpensiveItem,
        ...payload
      }
    },
    [SET_COMPLETE] (state, payload) {
      state.complete = payload
    },
    [SET_SUCCESS] (state, payload) {
      state.success = payload
    },
    [SET_JUST_ACTIVATED] (state, payload) {
      state.success = payload
    },
    [SET_LOADING] (state, payload) {
      state.loading = payload
    },
    [SET_ERRORS] (state, payload) {
      state.errors = payload
    },
    [CLEAR_ACTIVATION] (state) {
      state.coverage = {
        id: 0,
        name: '',
        price: 0
      }
      // state.school = ''
      state.schoolType = 'undergrad'
      state.year = ''
      state.fname = ''
      state.lname = ''
      state.email = ''
      state.email_alt = ''
      state.phone = ''
      state.dateOfBirth = {
        day: '',
        month: '',
        year: ''
      }
      state.address = {
        formatted_address: '',
        street: '',
        apt: '',
        city: '',
        state: '',
        zip: '',
        res_type: '',
        rent_own: ''
      }
      state.hear_about_us = {
        how: '',
        other: ''
      }
      state.mostExpensiveItem = {
        brand: '',
        model: '',
        screenSize: '',
        mostExpensive: 'laptop',
        mostExpensiveText: '',
        price: 2000
      }
      state.complete = false
      state.loading = false
      state.errors = null
    },
    [SUBMIT_STUDENT_CHECK] (state, payload) {
      state.studentVerification = {
        school: payload.name,
        school_verification_file: payload.file,
        type: payload.type,
        year: payload.year,
        student: payload.student
      }
    }
  },
  actions: {
    submitActivation ({ commit }, userId) {
      commit(SET_LOADING, true)
      commit(SET_ERRORS, null)
      const activation = this.state.activation
      const formData = new FormData()
      formData.append('school_type', activation.studentVerification.type)
      formData.append('year', activation.studentVerification.year)
      formData.append('first_name', activation.fname)
      formData.append('last_name', activation.lname)
      formData.append('alt_email', activation.email_alt)
      formData.append('email', activation.email) // TODO: Revisit fields to be sent for activation
      formData.append('phone_no', this.state.user.phone_no)
      formData.append('birthday', `${activation.dateOfBirth.month} ${activation.dateOfBirth.day}, ${activation.dateOfBirth.year}`)
      formData.append('address', activation.address.formatted_address)
      formData.append('street', activation.address.street)
      formData.append('property_type', activation.address.rent_own)
      formData.append('how_heard_about_akko', activation.hear_about_us.how)
      formData.append('how_heard_about_akko_other', activation.hear_about_us.other)
      formData.append('apt', activation.address.apt)
      formData.append('city', activation.address.city)
      formData.append('state', activation.address.state)
      formData.append('zip', activation.address.zip)
      formData.append('res_type', activation.address.res_type)
      formData.append('site_url', 'https://app.getakko.com')
      formData.append('business_name', activation.address.business_name)
      formData.append('school', activation.studentVerification.school)
      formData.append('switching_protection', activation.switching_protection)
      formData.append('past_protection_provider', activation.past_protection_provider)
      formData.append('student', activation.studentVerification.student)

      // General user identification for Sentry
      Sentry.setUser({ userId })

      if (activation.studentVerification.school_verification_file) {
        let fileUploadPayload = {
          type: 'STUDENT_VERIFICATION',
          entity_id: this.state.user_id,
          file: activation.studentVerification.school_verification_file
        }
        this.dispatch('uploadToFilePipeline', fileUploadPayload)
      }

      axios.put(
        `${api}/users/${this.state.user_id}/activate/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`, 'Content-Type': 'multipart/form-data'
          }
        }
      ).then(() => {
        commit(SET_SUCCESS, true)
        try {
          window.mixpanel.identify(userId)
          window.mixpanel.track('Activation', {
            'date': new Date()
          })
        } catch (e) {
          Sentry.captureException(e)
        }
        commit(SET_LOADING, false)
        commit(SET_JUST_ACTIVATED, true)
        commit(CLEAR_ACTIVATION, {})
      })
        .catch((error) => {
          Sentry.withScope(function (scope) {
            scope.setTag('Operation', 'activation')
            scope.setLevel('warning')
            scope.setUser({id: userId})

          // Forcing to send payload to Sentry
            console.error(JSON.stringify(activation))
            console.error(JSON.stringify(error))
            Sentry.captureException(new Error('Error on user activation'))
          })
          commit(SET_LOADING, false)
          var message = error
          try {
            message = error.response.data.message
          } catch (e) {
            console.warn('Error parsing error message:', e);
          }
          commit(SET_ERRORS, { status: error.response.status, message: message })
        })
    },
    updateLead () {
      const activation = this.state.activation
      const lead = {
        email: this.state.user.email,
        school_type: activation.schoolType,
        year: activation.year,
        first_name: activation.fname,
        last_name: activation.lname,
        alt_email: activation.email_alt,
        phone_no: activation.phone,
        birthday: `${activation.dateOfBirth.month} ${activation.dateOfBirth.day}, ${activation.dateOfBirth.year}`,
        address: activation.address.formatted_address,
        street: activation.address.street,
        apt: activation.address.apt,
        city: activation.address.city,
        state: activation.address.state,
        zip: activation.address.zip,
        res_type: activation.address.res_type,
        device_brand: activation.mostExpensiveItem.brand,
        device_model: activation.mostExpensiveItem.model,
        device_screen_size: activation.mostExpensiveItem.screenSize,
        device: activation.mostExpensiveItem.mostExpensive,
        device_text: activation.mostExpensiveItem.mostExpensiveText,
        device_price: activation.mostExpensiveItem.price,
        complete: activation.complete
      }
      axios.post(`${api}/leads/`, lead)
      .catch((error) => console.log(error))
    }
  },
  getters: {

  }
}

export default activation
