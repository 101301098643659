<template>
  <div>
    <!-- TO-DO: remove because getTheme is not in use anymore -->
    <!-- <router-view v-if="!isThemeLoading" /> -->
    <router-view />
    <div
      v-if="loading && !registrationState && !spinnerText"
      class="fixed top-0 left-0 z-999 w-100 h-100 bg-white-50 fade flex justify-center items-center"
    >
      <svg
        class=""
        height="38"
        stroke="#000"
        viewBox="0 0 38 38"
        width="38"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          fill-rule="evenodd"
        >
          <g
            stroke-width="2"
            transform="translate(1 1)"
          >
            <circle
              cx="18"
              cy="18"
              r="18"
              stroke-opacity=".5"
            />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                dur="1s"
                from="0 18 18"
                repeatCount="indefinite"
                to="360 18 18"
                type="rotate"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>

    <div
      v-if="registrationState"
      class="fixed top-0 left-0 z-999 w-100 h-100 bg-white-50 fade flex justify-center items-center"
    >
      <div
        id="modal"
        class="ma2 mv4 bg-white mt5-ns mb6-ns ba b--light-gray br2 mw7 center-ns animated-slow slide-in"
        style="min-width: 300px"
      >
        <div class="flex pa3 flex-column justify-center items-center">
          <p class="inter f6 pv3">
            {{ registrationError || 'Processing payment...' }}
          </p>
          <svg
            v-if="!registrationError"
            class=""
            height="38"
            stroke="#000"
            viewBox="0 0 38 38"
            width="38"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              fill="none"
              fill-rule="evenodd"
            >
              <g
                stroke-width="2"
                transform="translate(1 1)"
              >
                <circle
                  cx="18"
                  cy="18"
                  r="18"
                  stroke-opacity=".5"
                />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    from="0 18 18"
                    repeatCount="indefinite"
                    to="360 18 18"
                    type="rotate"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
        <hr
          v-if="!!registrationError"
          class="b--black-10"
        >
        <slot
          v-if="!!registrationError"
          name="modal-footer"
        >
          <div class="pa3 flex justify-end">
            <ak-button
              new-secondary
              @click.native="closeRegistrationErrorModal"
            >
              Close
            </ak-button>
          </div>
        </slot>
      </div>
    </div>
    <div
      v-if="spinnerText && loading"
      class="fixed top-0 left-0 z-999 w-100 h-100 bg-white-50 fade flex justify-center items-center"
    >
      <div
        id="modal"
        class="ma2 mv4 bg-white mt5-ns mb6-ns ba b--light-gray br2 mw7 center-ns animated-slow slide-in"
        style="min-width: 300px"
      >
        <div class="flex pa3 flex-column justify-center items-center">
          <p class="inter f6 pv3">
            {{ spinnerText }}
          </p>
          <svg
            class=""
            height="38"
            stroke="#000"
            viewBox="0 0 38 38"
            width="38"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              fill="none"
              fill-rule="evenodd"
            >
              <g
                stroke-width="2"
                transform="translate(1 1)"
              >
                <circle
                  cx="18"
                  cy="18"
                  r="18"
                  stroke-opacity=".5"
                />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    from="0 18 18"
                    repeatCount="indefinite"
                    to="360 18 18"
                    type="rotate"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import AkButton from '@/components/Button'
import { identifyUser } from './services/posthog'
import { parseJwt } from '@/utils'

// Time set for the logout of a user that has no activity (in seconds)
const LOGOUT_TIME = 20 * 60

export default {
  name: 'App',
  components: {
    AkButton
  },
  data () {
    return {}
  },
  computed: {
    loggedIn () {
      return this.$store.state.token && this.$store.state.token !== ''
    },
    loading () {
      return this.$store.state.loading
    },
    isThemeLoading () {
      return this.$store.state.isThemeLoading
    },
    inApp () {
      return true
    },
    isActivated () {
      return this.$store.state.user.is_activated
    },
    spinnerText () {
      return this.$store.state.spinnerText
    },
    registrationState () {
      return this.$store.state.registrationv2.registering
    },
    registrationError () {
      return this.$store.state.registrationv2.error
    }
  },
  mounted () {
    this.setTheme()
    this.checkUserActive()
    this.setUserActivity()
    this.$store.commit('SET_REGISTRATION_STATE_OFF')
    this.$store.commit('SET_REGISTRATION_ERROR', '')

    localStorage.setItem('scrollPosition', {y: 0, x: 0})
    this.$store.dispatch('getFeatureFlags')

    this.$store.watch(function (state) {
      return state.user
    }, function (oldstate, newstate) {
      const {email, first_name, user_id, phone_no, intercom_hashes} = newstate
      if (email && first_name && user_id && phone_no && intercom_hashes) {
        this.$intercom.update({
          userId: user_id,
          user_id: user_id,
          name: first_name,
          email: email,
          phone: phone_no,
          user_hash: intercom_hashes.web_hash
        })
      }
      if (user_id) {
        identifyUser(user_id)
      }
    },
    {deep: true})
    this.loginWithTokenIfExist()
  },
  updated () {
    this.setUserActivity()
  },
  methods: {
    setTheme () {
      document.documentElement.style.setProperty('--theme-primary', process.env.VUE_APP_WHITE_LABEL_COLOR)
    },
    logOut () {
      this.$store.commit('SET_LOGIN_MESSAGE', {
        message: 'You were logged out due to inactivity',
        type: 'error'
      })
      this.$store.commit('SET_TOKEN', {access_token: '', id: 0})
      this.$store.commit('CLEAR_USER')
      this.$router.push('/login')
    },
    checkUserActive () {
      if (this.loggedIn) {
        if (!this.$cookies.isKey('userActive')) {
          this.logOut()
        }
      }
    },
    setUserActivity () {
      const timeout = LOGOUT_TIME * 1000
      if (this.loggedIn) {
        this.$cookies.set('userActive', '', LOGOUT_TIME)
        setTimeout(() => {
          this.checkUserActive()
        }, timeout)
      }
    },
    closeRegistrationErrorModal () {
      this.$store.commit('SET_REGISTRATION_STATE_OFF')
      this.$store.commit('SET_REGISTRATION_ERROR', '')
    },
    loginWithTokenIfExist () {
      const queryParams = this.$route.query
      if (queryParams.t) {
        const decoded_token = parseJwt(queryParams.t)
        this.$store.dispatch('loginWithToken', {access_token: queryParams.t, id: decoded_token.sub})
      }
    }
  }
}
/* eslint-enable camelcase */
</script>

<style>
/* Global CSS vars */
:root {
  --theme-primary: #f3103d;
  --theme-green: #00AC1F;
  --theme-cream: #FFFFFF;
  --theme-black: #222222;
  --theme-pink: #fff5f6;
  --theme-light-pink: #FEF9FA;
  --theme-gray: #c8c8c8;
  --theme-light-gray: #FAFAFA;
  --theme-medium-gray: #e1e0e0;
  --theme-dark-gray: #06092070;
  --theme-dark-green: #2F7D22;
  --theme-almost-black: #999;
  --theme-gray-text: #9ba1ab;
  --theme-dark-gray-text: #757D8A
}

.bold {
  font-weight: 900;
}


.scroll-theme::-webkit-scrollbar-thumb {
  width: 4px;
  background: var(--theme-primary);
}

.img-square {
  object-fit: cover;
}

.cera-round-pro {
  font-family: 'Cera Round Pro Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

.cera-round-pro-bold {
  font-family: 'Cera Round Pro Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.cera-round-pro-medium {
  font-family: 'Cera Round Pro Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.inter {
  font-family: 'Inter Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.inter-semi-bold {
  font-family: 'Inter Semi Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


/* Transitions and Animations */
.transition-fast {
  transition-duration: .2s
}

.hover-lift {
  transition-duration: 100ms;
  transition-timing-function: linear;
}

.hover-lift:hover {
  transform: translate(0, -0.25em);
}


/* google places styles  */
.pac-container {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pac-item {
  padding: 5px;
  font-family: 'Inter Regular';
  font-size: 0.75rem;
}

.pac-item-query {
  font-weight: bold;
  font-size: 0.75rem;
}

.pac-icon {
  display: none;
}


/* Functional CSS for colors */
input:focus, select:focus {
  border-color: var(--theme-primary);
  outline: none;
}

.color-theme {
  color: var(--theme-primary);
}

.color-gray {
  color: var(--theme-dark-gray);
}

.bg-theme {
  background-color: var(--theme-primary);
}

.b-theme {
  border-color: var(--theme-primary);
}

.bg-pink {
  background-color: var(--theme-light-pink);
}

.bg-gray {
  background-color: var(--theme-gray);
}

.fill-theme {
  fill: var(--theme-primary)
}

.bg-theme-active:active {
  background-color: var(--theme-primary);
}

.color-green {
  color: var(--theme-green);
}

.bg-green {
  background-color: var(--theme-green);
}

.b-green {
  border-color: var(--theme-green);
}

.border-gray {
  border-color: var(--theme-dark-gray);
}

.border-light-gray {
  border-color: var(--theme-gray);
}

.color-cream {
  color: var(--theme-cream);
}

.bg-cream {
  background-color: var(--theme-cream);
}

.b-cream {
  border-color: var(--theme-cream);
}

@media screen and (min-width: 30em) {
  .bg-cream-ns {
    background-color: var(--theme-cream);
  }
}

.black {
  color: var(--theme-black)
}

.white-active:active {
  color: #FFFFFF;
}

.b-solid {
  border-style: solid;
}

/* Functional CSS for shadows (box and text) */
.shadow-theme {
  /*
   * box-shadow: 0px 2px 4px 0px rgba( 249, 25, 66, .2 );
   */
  box-shadow: 0 5px 15px 0px rgba(35, 31, 32, 0.2);
}

.new-shadow-theme {
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.08)
}

@media screen and (min-width: 30em) {
  .shadow-theme-ns {
    box-shadow: 0 5px 15px 0px rgba(35, 31, 32, 0.2);
    /*
     * box-shadow: 0px 2px 4px 0px rgba( 249, 25, 66, .2 );
     */
  }
}

.shadow-card {
  border-radius: 10px;
  box-shadow: 0 2px 15px 0px rgba(35, 31, 32, 0.2);
}

.br20 {
  border-radius: 10px;
}

.shadow-card-nav {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.2);
}

.shadow-card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.shadow-btn {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)
}

.shadow-txt {
  text-shadow: 0 1px 8px #000000;
}

.shadow-txt-reset {
  text-shadow: none;
}

.v-ttp {
  vertical-align: text-top;
}

.flex-grow {
  flex-grow: 1;
}

.flex-basis-3 {
  flex-basis: 8em;
}

@media screen and (min-width: 30em) {
  .flex-grow-off-ns {
    flex-grow: 0
  }
}

/* Reusable custom grids */
.grid-half {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
}

.grid-third {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
}

.grid-fourth {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
}

.grid-one {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 1.25em;
}

@media screen and (min-width: 35em) {
  .grid-half {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 1.25em;
  }

  .grid-third {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.25em;
  }

  .grid-fourth {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1.25em;
  }
}

@media screen and (min-width: 64em) {
  .grid-half {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 1.25em;
  }

  .grid-third {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.25em;
  }

  .grid-fourth {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1.25em;
  }
}

/* Some global rules/resets */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Cera Round Pro Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

select {
  height: 36px;
  background-color: #FFFFFF;
}

input[type="date"] {
  height: 36px;
  background-color: #FFFFFF;
}

.img-zoom input[type=checkbox] {
  display: none
}

.img-zoom img {
  transition: transform 0.25s ease;
  cursor: zoom-in
}

.img-zoom input[type=checkbox]:checked ~ img {
  transform: scale(2);
  opacity: 1;
  cursor: zoom-out
}

.register-modal {
  min-width: 300px;
  max-width: 600px;
}

.loss-type-label {
  background: var(--theme-primary);
  color: #fff;
  border-radius: 3px;
  padding: 3px;
  margin-right: 2px;
}
</style>
