import AkCreditDebitCard from '../credit-debit-card/CreditDebitCard.vue'
import AkACH from '../ach/Ach.vue'

export const PAYMENT_METHODS = [
  {
    label: 'Bank Account',
    value: AkACH.name
  },
  {
    label: 'Debit/Credit Card',
    value: AkCreditDebitCard.name
  }
]
