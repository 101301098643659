<template>
  <main>
    <div
      class="flex flex-row items-center responsive-title pl2 pr2 pb2"
      @click="goBack"
    >
      <arrow-back1 />
      <h3 class="ml3 f4 cera-round-pro">
        {{ $t('billingsettings.billingsettingsheader', {billingsettingsheader: 'Billing'}) }}
      </h3>
    </div>
    <div class="inter container">
      <div class="cell-multiple pv2 ph3 mv2 mh0 flex flex-column justify-center">
        <div class="flex justify-between items-center">
          <span class="silver-element">{{ $t('billingsettings.nextpayment', {nextpayment: 'Next Payment'}) }}</span>
        </div>
        <div
          class="flex justify-between items-center"
          style="margin-top: .75rem"
        >
          <label class="f5 fw5 db">{{ nextInvoiceDate }}</label>
          <span>{{ nextInvoiceAmount }}</span>
        </div>
      </div>
      <div class="silver-element pl3 mt3">
        {{ membershipStatus }}
      </div>

      <div
        class="ph3 mt4 pay-now-responsive"
        style="overflow: auto"
      >
        <a
          v-if="user.invoice_information.invoice_url && user.primary_account_holder.id == user.user_id"
          :href="user.invoice_information.invoice_url"
          class="no-underline pay-btn dib-ns"
        >
          <ak-button
            :disabled="!user.is_activated"
            class="pay-btn inter"
            primary
          >Pay Now</ak-button>
        </a>
        <ak-button
          class="ph3 flex hide-drop"
          :class="(showPreviousPayments) ? 'pad-payments-list' : ''"
          @click.native="showPreviousPayments=!showPreviousPayments"
        >
          <span
            class="color-theme inter"
            :class="(showPreviousPayments) ? 'pad-payments-list' : ''"
          >{{ showPreviousPayments ? 'Hide Previous Payments' : 'View Previous Payments' }}
            <img
              :src="showPreviousPayments ? '@/assets/appicons/cross-red.svg' : '@/assets/appicons/down-arrow.svg'"
              alt=""
              class="mr1"
              width="12"
            >
          </span>
        </ak-button>
      </div>
      <!-- Previous payments list -->
      <div
        v-if="showPreviousPayments"
        class="ph3 pv2 payments-list-background mh3"
      >
        <div
          v-for="(invoice, index) in user.invoice_information.all_invoices.slice(0, 3)"
          :key="index"
          class="payment-methods"
        >
          <a
            :href="invoice.hosted_invoice_url"
            class="flex justify-between mv2 no-underline items-center cell pointer black payments-list"
            target="_blank"
          >
            <label class="f5 pointer db flex">{{ getInvoiceDate(invoice) }}<span
              class="silver-element ml1"
            > {{ invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1) }}</span><span
              class="color-theme no-underline flex ml1"
            >
              <svg
                height="17px"
                viewBox="0 0 48 48"
                width="17px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g id="surface8346467">
                  <path
                    d="M 41.46875 4.988281 C 41.417969 4.988281 41.363281 4.992188 41.308594 5 L 27.5 5 C 26.960938 4.992188 26.457031 5.277344 26.183594 5.742188 C 25.910156 6.210938 25.910156 6.789062 26.183594 7.257812 C 26.457031 7.722656 26.960938 8.007812 27.5 8 L 37.878906 8 L 22.4375 23.441406 C 22.046875 23.816406 21.890625 24.375 22.027344 24.898438 C 22.164062 25.425781 22.574219 25.835938 23.101562 25.972656 C 23.625 26.109375 24.183594 25.953125 24.558594 25.5625 L 40 10.121094 L 40 20.5 C 39.992188 21.039062 40.277344 21.542969 40.742188 21.816406 C 41.210938 22.089844 41.789062 22.089844 42.257812 21.816406 C 42.722656 21.542969 43.007812 21.039062 43 20.5 L 43 6.691406 C 43.058594 6.253906 42.925781 5.8125 42.628906 5.484375 C 42.335938 5.15625 41.910156 4.972656 41.472656 4.988281 Z M 12.5 8 C 8.375 8 5 11.375 5 15.5 L 5 35.5 C 5 39.625 8.375 43 12.5 43 L 32.5 43 C 36.625 43 40 39.625 40 35.5 L 40 25.5 C 40.007812 24.960938 39.722656 24.457031 39.257812 24.183594 C 38.789062 23.910156 38.210938 23.910156 37.742188 24.183594 C 37.277344 24.457031 36.992188 24.960938 37 25.5 L 37 35.5 C 37 38.003906 35.003906 40 32.5 40 L 12.5 40 C 9.996094 40 8 38.003906 8 35.5 L 8 15.5 C 8 12.996094 9.996094 11 12.5 11 L 22.5 11 C 23.039062 11.007812 23.542969 10.722656 23.816406 10.257812 C 24.089844 9.789062 24.089844 9.210938 23.816406 8.742188 C 23.542969 8.277344 23.039062 7.992188 22.5 8 Z M 12.5 8 "
                    style=" stroke:none;fill-rule:nonzero;fill:rgb(95.294118%,6.27451%,23.921569%);fill-opacity:1;"
                  />
                </g>
              </svg>
            </span></label>
            <span class="pointer">{{ getInvoiceAmount(invoice) }}</span>
          </a>
          <hr class="line">
        </div>
        <router-link
          class="flex justify-center no-underline cell payments-list mt2 items-center color-theme payments-list"
          to="/account/invoices"
        >
          View More
        </router-link>
      </div>

      <div class="pa3 mt2 flex  items-center cell">
        <span class="silver-element">{{ $t('billingsettings.paymentmethods', {paymentmethods: 'Payment Methods'}) }}</span>
      </div>
      <div class="mh3">
        <div v-if="user.credit_card_brand && user.credit_card_last4">
          <div class="pt3 pr3 mb3 pb0-ns flex justify-between items-center cell">
            <label class="f5 db card-info">
              <img
                :src="getCardBrandLogo(user.credit_card_brand)"
                alt="Credit Card Logo"
                class="mb40 logo-card"
              >
              <div class="flex flex-column">
                <div class="flex flex-row responsive-column mb1">
                  <p class="ml2 f5"> {{ user.credit_card_brand }}</p>
                  <span class="ml2 f5">({{
                    $t('billingsettings.endswith', {endswith: 'ends with'})
                  }} *{{ user.credit_card_last4 }})</span>
                </div>
                <!-- TODO INFORMATION NOT AVAILABLE  -->
                <!--                <span class="ml2 f5 expiration">{{ $t('billingsettings.expires', {expires: 'Expires'}) }} 03/26</span>-->
              </div>
            </label>
            <span class="default-card">{{ $t('billingsettings.selectedas', {selectedas: 'Default'}) }}</span>
          </div>
          <!-- add payment method modal starts -->
          <UpdatePaymentMethodModal
            v-if="showCreditCardModal"
            ref="cardForm"
            :can-add-payment-method="canAddPaymentMethod"
            :reset-state="resetState"
            :show-credit-card-modal="showCreditCardModal"
          />
          <!-- add payment method modal ends -->
          <div
            v-if="user.credit_card_brand && user.credit_card_last4 && !showCreditCardModal"
            class="dib-ns mb4 btn-add-parent"
          >
            <ak-button
              block
              class="btn-add inter"
              secondary
              @click.native="user.payment_method !== 'CREDIT_DEBIT_CARD' ? openIntercomChat : showCreditCardModal=true"
            >
              Update Payment
              Method
            </ak-button>
          </div>
        </div>
        <div v-else>
          If you'd like to update your payment method, please email
          <a
            class="color-theme"
            href="mailto:billing@getakko.com"
            rel="noopener noreferrer"
            target="_blank"
          >billing@getakko.com</a>.
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import AkButton from '../../../Button.vue'
import ArrowBack1 from '../../icons/ArrowBack.vue'
import UpdatePaymentMethodModal from './UpdatePaymentMethodForm.vue'
import _ from 'lodash'
import { CURRENCY, LANGUAGE, MASTERCARD, VISA, DATE_MONTH, DATE_DAY, DATE_YEAR } from '../../../../constants/account-settings/accountSettings'

export default {
  name: 'BillingComponent',
  components: {
    UpdatePaymentMethodModal,
    AkButton,
    ArrowBack1
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    canAddPaymentMethod: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showPreviousPayments: false,
      showCreditCardModal: false,
      SelectedPaymentMethod: '',
      windowWidth: window.innerWidth
    }
  },
  computed: {
    stripeElements () {
      return this.$stripe.elements()
    },
    user () {
      return this.$store.state.user
    },
    nextInvoiceDate () {
      return this.formatDate(this.user.invoice_information.upcoming_invoices.next_payment_attempt)
    },
    nextInvoiceAmount () {
      return (this.user.invoice_information.upcoming_invoices.total / 100).toLocaleString(LANGUAGE, {
        style: 'currency',
        currency: CURRENCY
      })
    },
    previousInvoices () {
      return this.user.invoice_information.all_invoices
    },
    membershipStatus () {
      return _.startCase(this.user.membership_status.toLowerCase())
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    if (!this.isMobile) {
      this.$intercom.boot({})
    }
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  },
  methods: {
    openIntercomChat () {
      if (!this.isMobile) {
        this.$intercom.show()
      }
    },
    beforeRouteLeave (to, from, next) {
      if (!this.isMobile) {
        this.$intercom.shutdown()
        next()
      }
    },
    resetState () {
      this.showCreditCardModal = false
    },
    formatDate (date) {
      const toDate = new Date(date * 1000)
      return toDate.toLocaleDateString(LANGUAGE, {
        month: DATE_MONTH,
        day: DATE_DAY,
        year: DATE_YEAR
      })
    },
    getInvoiceDate (invoice) {
      return this.formatDate(invoice.status_transitions.paid_at)
    },
    getInvoiceAmount (invoice) {
      return (invoice.total / 100).toLocaleString(LANGUAGE, {style: 'currency', currency: CURRENCY})
    },
    getCardBrandLogo (brand) {
      if (!brand) return ''
      
      switch (brand.toUpperCase()) {
        case VISA:
          return require('@/assets/appicons/icon-visa.png')
        case MASTERCARD:
          return require('@/assets/appicons/icon-mastercard.png')
        default:
          return require('@/assets/appicons/icon-visa.png')
      }
    },
    goBack () {
      this.$router.push('/account/dashboard')
    }
  }
}
</script>

<style scoped>

.cell {
  height: 50px;
}

.container {
  padding-left: 1rem;
}

main {
  margin-right: 1rem;
  margin-top: 1rem;
  padding-top: .25rem
}

.silver-element {
  color: #757D8A;
}
.cell-multiple {
  height: 71px;
  border-radius: 4px;
}

.default-card {
  color: #757D8A;
  font-style: italic;
}

.expiration {
  color: #757D8A;
}

.logo-card {
  max-width: 2.5rem;
}

.cell-multiple:hover {
  background-color: #fafafa;
}

.line {
  border: 1px solid #E8E8E8;
}

.content:last-child hr {
  display: none;
}

.pay-btn {
  height: 34px;
  width: 89px;
  letter-spacing: -0.3px;
  white-space: nowrap;
  border-radius: 5px;
}

.hide-drop {
  float: left;
  background-color: #FAFAFA;
  padding: 0.7rem;
  border-radius: 5px;
  border: none;
}

.payments-list {
  background-color: #FAFAFA;
  width: 100%;
  padding: .7rem;
  border-radius: 4px;
}

.payments-list:hover {
  background-color: #E8E8E8;
}

.responsive-title {
  display: none;
}

.payments-list-background {
  background-color: #FAFAFA;

  border-radius: 5px 0 5px 5px;
}

.btn-add {
  height: 30px;
  font-weight: 500;
  margin-top: 1rem;
  border-radius: 5px;
}

.card-info {
  display: flex;
  align-items: center;
}

.pad-payments-list {
  width: 100%;
  text-align: left;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.line {
  border: 1px solid #E8E8E8;
}

.payment-methods:last-child hr {
  display: none;
}

@media (max-width: 768px) {
  .pay-now-responsive {
    display: flex;
    flex-direction: column;
  }

  .responsive-column {
    display: flex;
    flex-direction: column;
  }

  .payments-list-background {
    margin-top: 0;
    border-radius: 0 0 5px 5px;
  }

  .pay-btn {
    width: 100%;
    height: 40px
  }

  .btn-add {
    width: 100%;
    height: 40px;
  }

  .btn-add-parent {
    width: 100%;
  }

  .responsive-title {
    display: flex;
  }

  .container {
    padding-left: 0;
  }

  .cell {
    height: unset;
  }

  .cell-multiple {
    height: 74px;
    border-radius: 4px;
  }

  .hide-drop {
    float: right;
    background-color: #FAFAFA;
    width: 100%;
    border: none;
    margin-top: 0.5rem;
  }

  .pad-payments-list {
    padding-bottom: 0.5rem;
    border-radius: 5px 5px 0 0;
    text-align: center;
  }

  main {
    padding-top: 0;
    padding-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

</style>
