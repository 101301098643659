<template>
  <div class="relative sider sider-bg">
    <div class="tc mb4 flex justify-center items-center padding">
      <div class="flex steps">
        <div class="line bg-gray" />
        <div class="flex flex-column items-start justify-between menu steps">
          <progress-status
            v-for="status in statusData"
            :key="status.thisStep"
            :current-step="currentStep"
            :this-step="status.thisStep"
            :step-label="status.label"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressStatus from './ProgressStatus.vue'

export default {
  name: 'RedeemProgress',
  components: {
    ProgressStatus
  },
  props: {
    currentStep: Number
  },
  data () {
    return {
      statusData: [
        {thisStep: 0, label: 'Activation Code'},
        {thisStep: 1, label: 'Personal Information'},
        {thisStep: 2, label: 'Terms & Conditions'}
      ]
    }
  }
}
</script>

<style scoped>
  .sider {
    width: 30%;
    margin-right: 20px;
    border-radius: 10px;
  }
  .sider-bg {
    background-color: #FEF9FA;
  }
  .line {
    width: 1px;
    height: 12em;
    margin-top: 5px;
  }
  .padding {
    padding: 4em 0 0 2.5em;
  }
  .steps {
    height: 14em;
    min-width: 13em;
  }
  @media screen and (max-width: 1030px) {
    .padding {
      padding-left: 3em;
    }
    .steps {
      width: 46px;
      min-width: 45px;
    }
    .sider {
      width: 20%;
    }
  }
  @media screen and (max-width: 700px) {
    .padding {
      padding: 0;
      align-content: center;
      padding-top: 10px;
    }
    .sider {
      width: 100%;
      height: 6em;
    }
    .steps {
      width: 65%;
      height: 6em;
      flex-direction: column;
      justify-content: center;
    }
    .menu {
      height: 50px;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .line {
      margin-bottom: -24px;
      width: 100%;
      height: 1px;
    }
  }
</style>
