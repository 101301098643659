<template>
  <div class="flex justify-center">
    <div class="w4">
      <Multiselect
        :value="selectedCountryCurrency"
        :options="countriesList"
        :searchable="false"
        :allow-empty="false"
        deselect-label=""
        select-label=""
        track-by="id"
        label="currency_code"
        @input="setCurrentCountryCurrency"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'AkCurrencyToggle',
  components: {
    Multiselect
  },
  props: {
    planType: {
      type: String,
      default: undefined
    },
    planInterval: {
      type: String,
      default: undefined
    }
  },
  mounted () {
    if (this.countriesList.length === 0) {
      this.getCountryList({
        plan_type: this.planType,
        plan_interval: this.planInterval
      })
    } else {
      this.getFilteredCoverageOptions({
        currency_code: this.selectedCountryCurrency.currency_code,
        plan_interval: this.planInterval,
        plan_type: this.planType
      })
    }
  },
  methods: {
    ...mapActions([
      'setCurrentCountryCurrency',
      'getFilteredCoverageOptions',
      'getCountryList'
    ])
  },
  computed: {
    ...mapState({
      countriesList: state => state.registrationv2.countries,
      selectedCountryCurrency: state => state.registrationv2.selectedCountryCurrency
    })
  },
  watch: {
    selectedCountryCurrency (newCurrency) {
      this.getFilteredCoverageOptions({
        currency_code: newCurrency.currency_code,
        plan_interval: this.planInterval,
        plan_type: this.planType
      })
    },
    planType (newplanType) {
      this.getFilteredCoverageOptions({
        currency_code: this.selectedCountryCurrency.currency_code,
        plan_interval: this.planInterval,
        plan_type: newplanType
      })
    },
    planInterval (newPlanInterval) {
      this.getFilteredCoverageOptions({
        currency_code: this.selectedCountryCurrency.currency_code,
        plan_interval: newPlanInterval,
        plan_type: this.planType
      })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
