<template>
  <div v-if="!userHasAccess">
    <Alert
      v-if="user.membership_status === 'CANCELED'"
      color="red"
    >
      <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
      <a
        href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        class="color-theme"
      >please contact our billing team.</a>
    </Alert>

    <Alert
      v-if="user.membership_status === 'UNPAID'"
      color="red"
    >
      <span>Your coverage plan is currently unpaid. Please proceed to <router-link
        to="/account/dashboard"
        class="color-theme"
      >Account Settings</router-link> to resolve or </span>
      <a
        href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        class="color-theme"
      >contact our billing team</a>.
    </Alert>

    <Alert
      v-if="user.membership_status === 'PAST_DUE'"
      color="red"
    >
      <span>Your coverage plan is currently past due. Please proceed to <router-link
        to="/account/dashboard"
        class="color-theme"
      >Account Settings</router-link> to resolve or </span>
      <a
        href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        class="color-theme"
      >contact our billing team</a>.
    </Alert>

    <Alert
      v-if="user.membership_status === 'BANNED'"
      color="red"
    >
      <span>Your account has been banned. If you think this was done in error, </span>
      <a
        href="mailto:billing@getakko.com?Subject=Inquiry Regarding Banned Account"
        class="color-theme"
      >please contact our billing team.</a>
    </Alert>

    <Alert
      v-if="user.membership_status === 'INACTIVE'"
      color="red"
    >
      <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
      <a
        href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        class="color-theme"
      >please contact our billing team.</a>
    </Alert>

    <Alert
      v-if="user.membership_status === 'INVALID_USER'"
      color="red"
    >
      <span>Your account has been marked as invalid by our system due to unpaid premiums or other account issues. If you think this was done in error, </span>
      <a
        href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        class="color-theme"
      >please contact our billing team.</a>
    </Alert>
  </div>
</template>

<script>
import Alert from '@/components/Alert'

export default {
  name: 'AKNotifications',
  components: {
    Alert
  },
  props: {
    userHasAccess: {
      type: Boolean
    },
    user: {
      type: Object
    }
  }
}
</script>

<style scoped>
</style>
