<template>
  <main>
    <div class="flex items-end ">
      <span class="f2 b title-responsive ml4-ns">{{ $t('storage.evidence', { evidence: 'Evidence Storage' }) }}</span>
    </div>
    <div class="mv2">
      <div
        class="mv2 br1 flex justify-between f6 inter ml4-ns"
      >
        <div class="fw2 mid-gray mt3">
          <span>
            Use the cloud storage here to keep relevant photos, documents, media, or other evidence in the event of any claim!
            <br>
            <br>
            You can upload photos of your device(s), as well as videos or audio files to provide further details and support your claim. In addition, you can upload pictures or PDFs of receipts or contracts. Please be aware that all devices you want to protect still need to be added under the "items" section of your AKKO account and properly categorized.            <br>
            <br>
            Please refrain from storing any sensitive personal information or highly-private documents. AKKO is not liable for any damages or repercussions due to files you upload being accessed unauthorized. All files can be viewed via the unique file URL by anyone with access to it if you post or share it anywhere.          </span>
        </div>
      </div>
    </div>

    <div class="mv4 ml4-ns">
      <div class="mb2 inter">
        {{ $t('storage.usage', { usage: 'Usage' }) }}
      </div>
      <div class="flex">
        <div class="dark-gray mr2">
          0
        </div>
        <div class="flex-grow bg-moon-gray br2">
          <div
            class="bg-green h-100 br2"
            :style="`width: ${100 * files.size / 49000000000}%`"
          />
        </div>
        <div class="dark-gray ml2">
          50GB
        </div>
      </div>
    </div>

    <div class="flex flex-wrap w-100 pl4-ns center pt2 pt0-xl mt1-ns">
      <div
        v-for="(file_category, index) of files.files"
        :key="index"
        class="mr1 mt2"
      >
        <div
          class="db pv2 ph2 f6 tc b ba br3 pointer inter"
          :class="{ 'bg-theme': isActive(index), 'bg-white': !isActive(index), 'color-cream': isActive(index), 'color-theme': !isActive(index)}"
          @click="showStorageCategory = index"
        >
          <p>{{ index }}</p>
        </div>
      </div>
    </div>

    <div
      v-for="(file_category, index) of files.files"
      :key="index"
    >
      <div v-show="showStorageCategory == index">
        <h2 class="f3 mv3 ml4-ns">
          {{ index }} {{ $t('storage.files', { files: 'files' }) }}
        </h2>
        <div class="mb6 mb4-ns">
          <div class="overflow-auto">
            <table
              class="f6 w-100 pl4-ns center"
              cellspacing="0"
              aria-describedby="Stored files"
            >
              <thead>
                <tr class="">
                  <th
                    class="tl pa3 bg-white"
                    scope="col"
                  >
                    {{ $t('storage.file', { file: 'File' }) }}
                  </th>
                  <th
                    class="tl pa3 bg-white"
                    scope="col"
                  >
&nbsp;
                  </th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <td class="tl pa3 bg-white">
                    {{ file_category.length }} {{ $t('storage.files', { files: 'files' }) }}
                  </td>
                  <td class="tl pa3 bg-white" />
                </tr>
              </tfoot>
              <tbody class="lh-copy">
                <tr
                  v-for="(file, file_index) of file_category"
                  :key="file_index"
                  class="stripe-dark"
                >
                  <td class="pa3">
                    <a
                      class="color-theme no-underline inter"
                      :href="file.url"
                    >
                      {{ file.file_name.substring(file.file_name.indexOf('/') + 1) }}
                    </a>
                  </td>
                  <td class="tr pa3 dark-red">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="pointer"
                      @click="showDeleteMessage(file.id)"
                    >
                      <polyline points="3 6 5 6 21 6" />
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <ak-modal
      v-if="displayDeleteModal != false"
      :title="$t('storage.alert1', { alert1: 'Are you sure?' })"
      @close="displayDeleteModal = false"
    >
      <div slot="modal-body">
        <p>{{ $t('storage.alert2', { alert2: 'Are you sure you want to delete this file?' }) }}</p>
      </div>
      <div
        slot="modal-footer"
        class="pa3 flex flex-wrap justify-between justify-end-ns"
      >
        <div class="mr3-ns">
          <ak-button
            secondary
            block
            @click.native="displayDeleteModal = false"
          >
            {{ $t('edit.cancel', { cancel: 'Cancel' }) }}
          </ak-button>
        </div>
        <div class="">
          <ak-button
            primary
            block
            @click.native="deleteFile(displayDeleteModal)"
          >
            {{ $t('edit.delete', { delete: 'Delete' }) }}
          </ak-button>
        </div>
      </div>
    </ak-modal>
    <ak-modal
      v-if="showUploadModal"
      :title="$t('storage.modalheader', { modalheader: 'Select Files to Upload' })"
      @close="showUploadModal = false"
    >
      <ak-file-input
        slot="modal-body"
        @filesUpdated="(payload) => newFiles = payload"
      />

      <div
        slot="modal-footer"
        class="pa3 flex flex-wrap justify-between justify-end-ns"
      >
        <div class="mr3-ns">
          <ak-button
            secondary
            block
            @click.native="showUploadModal = false"
          >
            {{ $t('edit.cancel', { cancel: 'Cancel' }) }}
          </ak-button>
        </div>
        <div class="">
          <ak-button
            primary
            block
            :disabled="newFiles.length === 0"
            @click.native="uploadFiles"
          >
            {{ $t('edit.upload', { upload: 'Upload' }) }}
          </ak-button>
        </div>
      </div>
    </ak-modal>

    <ak-fab @click.native="showUploadModal = true" />

    <!-- <div class="mv2">
      <div
        class="mv2 br1 flex justify-between f6">
        <div class="fw2 mid-gray">
          <span>
            <br>
            <br>
            NOTICE:
            <br>
            <br>
            Please refrain from storing any sensitive personal information or highly-private documents as AKKO is not liable
            for any damages or repercussions due to files you upload being accessed unauthorized. All files can be viewed via the unique file URL by anyone
            with access to it if you post or share it anywhere.
          </span>
        </div>
      </div>
    </div> -->
  </main>
</template>

<script>
import AkButton from './Button'
import AkFab from './Fab'
import AkModal from './Modal'
import AkFileInput from './FileInput'

export default {
  name: 'AkStoragePage',
  components: {
    AkButton,
    AkFab,
    AkModal,
    AkFileInput
  },
  data () {
    return {
      showUploadModal: false,
      showStorageCategory: '',
      displayDeleteModal: false,
      file_list: {},
      currentTab: '',
      newFiles: []
    }
  },
  computed: {
    files () {
      return this.$store.state.files
    }
  },
  mounted () {
    this.$store.dispatch('getStorageFiles')
  },
  methods: {
    uploadFiles () {
      this.$store.dispatch('uploadFiles', this.newFiles)
      this.showUploadModal = false
    },
    isActive (index) {
      return this.showStorageCategory === index
    },
    showDeleteMessage (itemId) {
      this.displayDeleteModal = itemId
    },
    deleteFile (itemId) {
      this.displayDeleteModal = false
      this.$store.dispatch('deleteFile', {id: itemId})
    }
  }
}
</script>

<style scoped>



.title-responsive {
    margin-top: 1.5rem;
  }

@media (max-width: 768px) {

  .title-responsive {
    font-size: 24px;
    margin-bottom: -10px;
    margin-top: 1rem;
  }

  .height {
    height: auto;
  }
}
</style>
