<template>
  <div>
    <h1 class="f2 lh-copy pt3 tl-ns tc tc-m">
      Claim Submitted
    </h1>
    <div class="tl-ns tc tc-m">
      <img
        class="claim-logo "
        src="@/assets/icons/claim-info.png"
        alt=""
      >
    </div>
    <div class="black f5 mb4 lh-copy">
      We’re here to make the process of filing a claim and getting your repairs/replacements completed as quickly and easily as possible!
      <br>
      If you need any assistance with this process, just reach out to us and we’ll walk you through each step!
    </div>

    <div class="w-100 flex flex-row justify-around">
      <a
        class="db color-theme no-underline mt2 cera-round-pro-medium"
        href="mailto:claims+support@getakko.com"
        target="_blank"
        rel="noopener noreferrer"
      >Email Support</a>
      <a
        class="db color-theme no-underline mt2 cera-round-pro-medium pointer"
        @click.native="openIntercomChat"
      >Chat Support</a>
    </div>

    <hr class="center mv4 hr">

    <div class="w-100 mb3 tl-ns tc tc-m">
      <h2>IMPORTANT</h2>
    </div>


    <p class="lh-copy black mb2">
      Make sure you have all the information and documents needed to complete filing your claim. The claims agent assigned to your claim may request more information.
    </p>

    <p class="lh-copy black mb2">
      Expect to receive a reply within approximately 2-4 business days of filing your claim.
    </p>

    <p class="lh-copy black mb2">
      Your plan protects your covered property worldwide. If your loss happened outside of the U.S., a police report is required in order to file a claim.
    </p>

    <div class="mb4 flex flex-row items-center">
      <router-link
        :to="featureFlagRedirectClaimV2 ? '/claims-v2' : '/claims'"
        class="no-underline"
      >
        <ak-button primary>
          Back to Claims
        </ak-button>
      </router-link>
      <a
        class="db color-theme no-underline mt2 cera-round-pro-medium pointer ml3"
        @click.native="openIntercomChat"
      >I still have questions</a>
    </div>
  </div>
</template>

<script>
import AkButton from './Button'
import { GET_FEATURE_FLAG } from '@/store/index'
import { REDIRECT_CLAIM_V2 } from '@/constants/feature_flag'

export default {
  name: 'AkClaimInfoPage',
  components: {
    AkButton
  },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },
    featureFlagRedirectClaimV2 () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](REDIRECT_CLAIM_V2)
      return feature ? feature.state : false
    }
  },
  mounted () {
    if (!this.isMobile) {
      this.$intercom.boot({})
    }
  },
  methods: {
    openIntercomChat () {
      if (!this.isMobile) {
        this.$intercom.show()
      }
    },
    beforeRouteLeave (to, from, next) {
      if (!this.isMobile) {
        this.$intercom.shutdown()
        next()
      }
    }
  }
}
</script>

<style scoped>
.claim-logo {
  width: 120px;
  height: 120px;
}
.hr {
  background-color: #ECECEC;
  height: 2px;
  border: none;
}
</style>
