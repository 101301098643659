<template>
  <main class="main-box">
    <akko-header />
    <div class="circle" />
    <div class="mt-5 container-box">
      <redeem-progress
        :current-step="registration_step"
      />
      <div class="flex form-area flex-column justify-between">
        <div>
          <activation-code
            v-model="redemption_code"
            :current-step="registration_step"
            :check-redemption-code="checkRedemptionCode"
          />
          <div v-show="registration_step === PERSONAL_INFO">
            <personal-form
              title="Personal Information"
              subtitle="Enter your personal information"
              first-label="Cell Phone Number"
              second-label="Email Address"
              auto-focus
              :first-value.sync="phone_no"
              :second-value.sync="email"
              :phone-error="phoneError"
              :email-error="emailError"
              :max-length="14"
            />
          </div>
          <agreement
            :accept.sync="accept"
            :registration-step="registration_step"
          />
          <div
            v-show="errorList.length > 0"
            class="mb3"
          >
            <span class="inter-medium red mt3 mb2">Error submitting registration:</span>
            <span
              v-for="(e, index) in errorList"
              :key="index"
              class="red"
              v-html="e"
            />
          </div>
        </div>
        <div class="flex justify-end buttons">
          <ak-button
            v-if="registration_step > ACTIVATION_CODE"
            class="w4"
            shadow
            new-secondary
            @click.native="setCurrentStep(registration_step - 1)"
          >
            Previous
          </ak-button>
          <ak-button
            :new-disabled="!enableButton()"
            class="ml3 w4"
            shadow
            new-primary
            @click.native="registration_step === TERMS_CONDS
              ? submit()
              : setCurrentStep(registration_step + 1)"
          >
            {{ registration_step === TERMS_CONDS ? 'Register' : 'Continue' }}
          </ak-button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AkButton from '@/components/Button'
import Agreement from '@/components/registration/Agreement'
import ActivationCode from '@/components/registration/forms/ActivationCode'
import RedeemProgress from '@/components/registration/progressSideBar/RedeemProgress'
import REDEEM_STEPS from '../../constants/registration/redeemSteps'
import AkkoHeader from '../AkkoHeader.vue'
import PersonalForm from './forms/PersonalForm.vue'

const {ACTIVATION_CODE, PERSONAL_INFO, TERMS_CONDS} = REDEEM_STEPS

import { checkEmailFormat, formatPhoneNumber } from '@/utils'

export default {
  name: 'AkRedeem',
  components: {
    AkButton,
    AkkoHeader,
    RedeemProgress,
    ActivationCode,
    PersonalForm,
    Agreement
  },
  beforeRouteLeave (to, from, next) {
    this.$intercom.shutdown()
    next()
  },
  data () {
    return {
      email: '',
      phone_no: '',
      accept: false,
      redemption_code: '',
      registration_step: ACTIVATION_CODE,
      ACTIVATION_CODE: ACTIVATION_CODE,
      PERSONAL_INFO: PERSONAL_INFO,
      TERMS_CONDS: TERMS_CONDS
    }
  },
  computed: {
    phoneError () {
      return (
        this.$store.state.registration.phone_error ||
        this.phone_no.trim().length < 14
      )
    },
    emailError () {
      return checkEmailFormat(this.email.trim())
    },
    loading () {
      return this.$store.state.loading
    },
    registration () {
      return this.$store.state.registration
    },
    errorList () {
      return this.$store.state.errors
    },
    codeRemainsEqual () {
      return this.redemption_code.toLowerCase() === this.registration.redemption.code.toLowerCase()
    },
  },
  mounted () {
    this.$store.commit('SET_ERRORS', null)
    this.redemption_code = this.$store.state.registration.redemption.code || this.$route.query.code
    this.$store.dispatch('getAllCoverageOptions')
    this.$store.commit('RESET_PROMO_ALL')
  },
  updated () {
    if (this.registration_step === PERSONAL_INFO) {
      this.setPhoneNumber()
      this.emailError()
    }
  },
  methods: {
    setPhoneNumber () {
      this.phone_no = formatPhoneNumber(this.phone_no)

      this.$store.dispatch('checkPhoneExists', {phone: this.phone_no.trim()})
      this.$store.commit('SET_PHONE', {phone_no: this.phone_no.trim()})
    },
    async submit () {
      window.fbq('trackCustom', 'Clicked Redeem')

      this.$store.commit('SET_LOADING', true)

      const payload = {
        'redemption_code': this.redemption_code,
        'email': this.email,
        'phone_no': this.phone_no
      }

      this.$store.dispatch('redeemAndRegister', payload)
    },
    checkRedemptionCode () {
      const payload = {
        redemption_code: this.redemption_code.toUpperCase()
      }
      this.$store.dispatch('checkRedemptionCode', payload)
    },
    setCurrentStep (newStep) {
      this.$store.commit('SET_ERRORS', null)
      this.registration_step = newStep
    },
    enableButton () {
      switch (this.registration_step) {
        case ACTIVATION_CODE: {
          return (
            this.registration.redemption.status === 'valid' &&
            this.codeRemainsEqual
          )
        }
        case PERSONAL_INFO: {
          return (!this.emailError && !this.phoneError)
        }
        case TERMS_CONDS: {
          return this.accept
        }
      }
    }
  }
}
</script>

<style scoped>
  .input-group-flex {
    display: flex;
    align-items: stretch;
  }
  .text-field-flex {
    flex: 1;
  }
  .circle {
    position: fixed;
    background-color: var(--theme-pink);
    width: 600px;
    height: 600px;
    border-radius: 50%;
    right: -300px;
    bottom: -300px;
    z-index: -1
  }
  .main-box {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding-top: 6em
  }
  .container-box {
    background: white;
    box-shadow: 0 1px 3px 0 rgba(32, 48, 80, 0.16);
    border-radius: 6px;
    width: 93%;
    min-height: 35em;
    justify-self: center;
    padding: 1.5em;
    display: flex;
    flex-direction: row;
    margin-bottom: 6em
  }
  .form-area {
    width: 70%;
    padding: 3em 0 0 4em
  }
  .input-group-flex {
    display: flex;
    align-items: stretch
  }
  .text-field-flex {
    flex: 1;
  }
  .submit-btn {
    flex: 1;
    cursor: pointer;
    border: 3px solid #f6103d;
    padding: 4px 12px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 800;
    background-color: #f6103d
  }
  .buttons {
    height: 2.5em;
  }
  #custom-button {
    height: 30px;
    outline: 1px solid grey;
    background-color: green;
    padding: 5px;
    color: white;
  }
  #card-error {
    color: red;
  }
  .StripeElement {
    background-color: white;
    padding: 10px 12px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0,.2);
  }
  .StripeElement--invalid {
    border-color: var(--theme-primary);
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5;
  }
  @media screen and (max-width: 1030px) {
    .form-area {
      width: 80%;
      padding: 2em 0em 0em 2em;
    }
  }
  @media screen and (max-width: 700px) {
    .container-box {
      flex-direction: column;
    }
    .form-area {
      width: 100%;
      min-height: 27em;
      padding-left: 0;
    }
  }
  @media screen and (max-width: 500px) {
    .container-box {
      padding: 1em;
    }
  }
</style>
