import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

// plugins
import createPersistedState from 'vuex-persistedstate'
import {SET_ORDER_AMOUNT, SET_STRIPE_CUSTOMER_ID, SET_SUBSCRIPTION_ID} from './mutations'
import registration from './registration'
import registrationv2 from './registrationv2'
import activation from './activation'
import claims from './claims'
import router from '../router'
import * as Sentry from '@sentry/vue'
import {trackOnMixpanel, MIXPANEL_EVENTS} from '@/services/tracking'

const isProduction = process.env.NODE_ENV === 'production'
const debug =!isProduction
const api = process.env.VUE_APP_API_URL

Vue.use(Vuex)

// Utils
// Registration types: 1 solo, 2 family plan, 3 multi-phone
export const SET_REGISTRATION_TYPE = 'SET_REGISTRATION_TYPE'

// mutation-types.js
const SET_COVERAGE_OPTIONS = 'SET_COVERAGE_OPTIONS'
const SET_ALL_PHONE_MODELS = 'SET_ALL_PHONE_MODELs'
const SET_PHONE_MODEL = 'SET_PHONE_MODEL'
const ADD_MESSAGE = 'ADD_MESSAGE'
const DELETE_MESSAGE = 'DELETE_MESSAGE'
const SET_LOADING = 'SET_LOADING'
// TO-DO: remove SET_THEME_LOADING, is not being used anymore
// const SET_THEME_LOADING = 'SET_THEME_LOADING'
const TOGGLE_KEYBOARD = 'TOGGLE_KEYBOARD'
const SET_LOGIN_MESSAGE = 'SET_LOGIN_MESSAGE'
const SET_JUST_ACTIVATED = 'SET_JUST_ACTIVATED'

const SET_ERRORS = 'SET_ERRORS'
const SET_TOKEN = 'SET_TOKEN'
export const SET_ADD_PLAN_ERROR = 'SET_ADD_PLAN_ERROR'
export const SET_ADD_PLAN_SELECTED_COVERAGES = 'SET_ADD_PLAN_SELECTED_COVERAGES'
export const SET_SPINNER_TEXT = 'SET_SPINNER_TEXT'

// User
const SET_USER = 'SET_USER'
const CLEAR_USER = 'CLEAR_USER'
const SET_PARTNER = 'SET_PARTNER'
const SET_CONTRACT = 'SET_CONTRACT'
const SET_SECONDARY_CONTRACTS = 'SET_SECONDARY_CONTRACTS'
const SET_CONTRACT_LINE_ITEMS_DEVICE_RESTRICTIONS = 'SET_CONTRACT_LINE_ITEMS_DEVICE_RESTRICTIONS'
const SET_THEMES = 'SET_THEMES'

// Devices
const SET_DEVICES = 'SET_DEVICES'
const SET_IMAGES = 'SET_IMAGES'
const RESET_LOADED = 'RESET_LOADED'
const SET_CURRENT_DEVICE = 'SET_CURRENT_DEVICE'
const SET_FEATURE_FLAG = 'SET_FEATURE_FLAG'
const SET_ITEM_OPERABILITIES = 'SET_ITEM_OPERABILITIES'
const SET_ITEM_SPECIFICATIONS = 'SET_ITEM_SPECIFICATIONS'
const SET_ITEM_PURCHASE_CONDITIONS = 'SET_ITEM_PURCHASE_CONDITIONS'
const SET_MOBILE_OPERATORS = 'SET_MOBILE_OPERATORS'

const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES'
const SET_DEVICE_IMAGE_RETRY = 'SET_DEVICE_IMAGE_RETRY'

// Getters
export const GET_USER_JUST_ACTIVATED = 'GET_USER_JUST_ACTIVATED'
export const GET_USER = 'GET_USER'
export const GET_PARTNER = 'GET_PARTNER'
export const GET_THEMES = 'GET_THEMES'

// Storage
const SET_FILES = 'SET_FILES'

// Getters
export const GET_FEATURE_FLAG = 'GET_FEATURE_FLAG'
export const GET_DEVICES = 'GET_DEVICES'

const RETRY_TIMEOUT = 5000
const RETRY_INTERVAL = 1000

export default new Vuex.Store({
  modules: {
    registration,
    registrationv2,
    activation,
    claims
  },
  state: {
    registrationType: NaN,
    spinnerText: null,
    token: '',
    user_id: 0,
    user: {
      user_id: '',
      email: '',
      is_verified: true,
      is_activated: true,
      subscription_id: '',
      external_id: '',
      first_name: '',
      last_name: '',
      school: '',
      year: '',
      phone_no: '',
      coverage_id: '',
      coverage_name: '',
      second_coverage_id: '',
      third_coverage_id: '',
      related_users: []
    },
    devices: [],
    devicesLoaded: false,
    currentDevice: {
      id: 0,
      type: '',
      brand: '',
      model: '',
      nickname: '',
      serial_number: '',
      images: [],
      photos_submitted: false
    },
    files: {
      size: 0,
      files: []
    },
    errors: [],
    addPlanError: '',
    messages: [],
    loginMessage: {
      message: '',
      type: ''
    },
    loading: false,
    isThemeLoading: false,
    user_just_activated: false,
    keyboardOpen: false,
    coverageOptions: [],
    addPlanSelectedCoverages: [],
    allPhoneModels: [{
      id: '',
      make: '',
      model: '',
      monthly_coverage_id: '',
      annual_coverage_id: '',
      repair_deductible: '',
      replacement_cost: '',
      replacement_deductible: ''
    }],
    phoneModel: {
      id: '',
      make: '',
      model: '',
      monthly_coverage_id: '',
      annual_coverage_id: '',
      repair_deductible: '',
      replacement_cost: '',
      replacement_deductible: ''
    },
    feature_flags: [],
    itemOperabilities: [],
    retryDeviceImages: false,
    partner: {},
    contract: {},
    secondaryContracts: [],
    contract_line_items: [],
    item_specifications: [],
    themes: [],
    item_purchase_conditions: [],
    mobile_operators: [],
    productCategories: []
  },
  mutations: {
    [SET_REGISTRATION_TYPE] (state, payload) {
      state.registrationType = payload
    },
    [SET_COVERAGE_OPTIONS] (state, payload) {
      state.coverageOptions = payload
    },
    [SET_ADD_PLAN_SELECTED_COVERAGES] (state, payload) {
      if (payload) {
        state.addPlanSelectedCoverages.push(payload)
      } else {
        state.addPlanSelectedCoverages = []
      }
    },
    [SET_ALL_PHONE_MODELS] (state, payload) {
      state.allPhoneModels = payload
    },
    [SET_PHONE_MODEL] (state, payload) {
      state.phoneModel = payload
    },
    [SET_ERRORS] (state, payload) {
      payload ? state.errors = [payload] : state.errors = []
    },
    [SET_ADD_PLAN_ERROR] (state, payload) {
      payload ? state.addPlanError = payload : state.addPlanError = ''
    },
    [ADD_MESSAGE] (state, payload) {
      state.messages.push(payload)
    },
    [DELETE_MESSAGE] (state, payload) {
      state.messages.splice(payload, 1)
    },
    [SET_SPINNER_TEXT] (state, payload) {
      state.spinnerText = payload
    },
    [SET_TOKEN] (state, payload) {
      state.token = payload.access_token
      state.user_id = payload.id
    },
    [SET_SUBSCRIPTION_ID] (state, payload) {
      state.subscription_id = payload
    },
    [SET_ORDER_AMOUNT] (state, payload) {
      state.order_amount = payload
    },
    [SET_STRIPE_CUSTOMER_ID] (state, payload) {
      state.stripe_customer_id = payload
    },
    [SET_USER] (state, payload) {
      state.user = payload
    },
    [CLEAR_USER] (state) {
      state.user = {
        user_id: '',
        email: '',
        is_verified: true,
        is_activated: true,
        subscription_id: '',
        external_id: '',
        first_name: '',
        last_name: '',
        school: '',
        year: '',
        phone_no: '',
        coverage_id: '',
        coverage_name: '',
        second_coverage_id: '',
        third_coverage_id: '',
        related_users: []
      }
    },
    [SET_DEVICES] (state, payload) {
      state.devices = payload
      state.devicesLoaded = true
    },
    [SET_IMAGES] (state, payload) {
      state.currentDevice.photos_submitted = payload
    },
    [RESET_LOADED] (state) {
      state.devicesLoaded = false
    },
    [SET_CURRENT_DEVICE] (state, payload) {
      state.currentDevice = payload
    },
    [SET_FILES] (state, payload) {
      state.files = payload
    },
    [SET_LOGIN_MESSAGE] (state, payload) {
      state.loginMessage = payload
    },
    [SET_LOADING] (state, payload) {
      state.loading = payload
    },
    // TO-DO: remove SET_THEME_LOADING, is not being used anymore
    // [SET_THEME_LOADING] (state, payload) {
    //   state.isThemeLoading = payload
    // },
    [TOGGLE_KEYBOARD] (state, payload) {
      state.keyboardOpen = payload
    },
    [SET_JUST_ACTIVATED] (state, payload) {
      state.user_just_activated = payload
    },
    [SET_FEATURE_FLAG] (state, payload) {
      state.feature_flags = payload
    },
    [SET_ITEM_OPERABILITIES] (state, payload) {
      state.itemOperabilities = payload
    },
    [SET_PARTNER] (state, payload) {
      state.partner = payload
    },
    [SET_CONTRACT] (state, payload) {
      state.contract = payload.length ? payload[0] : {}
    },
    [SET_SECONDARY_CONTRACTS] (state, payload) {
      state.secondaryContracts = payload
    },
    [SET_CONTRACT_LINE_ITEMS_DEVICE_RESTRICTIONS] (state, payload) {
      state.contract_line_items = payload
    },
    [SET_ITEM_SPECIFICATIONS] (state, payload) {
      state.item_specifications = payload
    },
    [SET_THEMES] (state, payload) {
      state.themes = payload
    },
    [SET_ITEM_PURCHASE_CONDITIONS] (state, payload) {
      state.item_purchase_conditions = payload
    },
    [SET_MOBILE_OPERATORS] (state, payload) {
      state.mobile_operators = payload
    },
    [SET_PRODUCT_CATEGORIES] (state, payload) {
      state.productCategories = payload
    },
    [SET_DEVICE_IMAGE_RETRY] (state, payload) {
      state.retryDeviceImages = payload
    }
  },
  actions: {
    addPlan ({commit}, payload) {
      commit(SET_LOADING, true)
      const requestBody = {
        coverageId: payload.coverage.id,
        phoneNumber: payload.phoneNumber
      }
      axios.post(`${api}/coverages/add_plan/`, requestBody, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then(() => {
          commit(SET_ADD_PLAN_SELECTED_COVERAGES, payload)
          commit(SET_LOADING, false)
          commit(SET_ADD_PLAN_ERROR)
          router.push('/add-plan/confirmation')
        })
        .catch((error) => {
          commit(SET_ADD_PLAN_SELECTED_COVERAGES)
          commit(SET_LOADING, false)
          let message = error
          try {
            message = error.response.data.message
          } catch (e) {
            message = 'Error on adding a plan'
          }
          commit(SET_ADD_PLAN_ERROR, message)
          if (error && error.response && error.response.status === 401) {
            router.replace('/')
          }
        })
    },
    // TO-DO: remove getThemes, is not being used anymore
    // getThemes ({commit}, params) {
    //   commit(SET_THEME_LOADING, true)
    //   axios.get(`${api}/api/v1/configurations/themes/`, {params: params})
    //     .then((response) => {
    //       commit(SET_THEMES, response.data)
    //       commit(SET_THEME_LOADING, false)
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //       commit(SET_THEME_LOADING, false)
    //     })
    // },
    getPartner ({commit}) {
      commit(SET_LOADING, true)
      axios.get(`${api}/api/v1/claims/partner/`, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then((response) => {
          commit(SET_PARTNER, response.data.data)
          commit(SET_LOADING, false)
        })
        .catch((error) => {
          console.log(error)
          commit(SET_LOADING, false)
        })
    },
    getAllCoverageOptions ({commit}) {
      axios.get(`${api}/coverages/`)
        .then((response) => {
          commit(SET_COVERAGE_OPTIONS, response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getAllPhoneModels ({commit}) {
      axios.get(`${api}/phone_models`)
        .then((response) => {
          commit(SET_ALL_PHONE_MODELS, response.data)
        })
        .catch((error) => {
          console.log(error)
          commit(SET_ALL_PHONE_MODELS, [{make: 'error'}])
        })
    },
    getPhoneModel ({commit}, payload) {
      axios.get(`${api}/phone_models/${payload.id}`)
        .then((response) => {
          commit(SET_PHONE_MODEL, response.data)
        })
        .catch((error) => {
          console.log(error)
          commit(SET_PHONE_MODEL, {make: 'error'})
        })
    },
    async login ({commit}, payload) {
      commit(SET_LOGIN_MESSAGE, {message: '', type: ''})

      try {
        const { data } = await axios.post(`${api}/login/`, {email: payload.email.toLowerCase(), password: payload.password});

        if (isProduction) {
          try {
            if (window.mixpanel) {
              window.mixpanel.identify(data.id);
              trackOnMixpanel(MIXPANEL_EVENTS.LOGIN)
            }
            window.dataLayer.push({ 'userId': data.id });
          } catch (e) {
            Sentry.captureException(e)
          }
        }

        commit(SET_TOKEN, data);
        let redirectUrl = payload.query_params && payload.query_params.redirect
        if (payload.query_params.section) {
          redirectUrl = redirectUrl + '#' + payload.query_params.section
        }
        if (redirectUrl) {
          const token = payload.query_params && payload.query_params.token
          router.push({path: redirectUrl, query: {token: token}})
        } else {
          router.push('/')
        }
      } catch (err) {
        if (isProduction) {
          try {
            // clean current user on GTM
            window.dataLayer.push({
              'userId': undefined
            })
          } catch (e) {
            Sentry.captureException(e)
          }
        }
        commit(SET_LOGIN_MESSAGE, {
          message: 'Please check your email/phone number and password combination.',
          type: 'error'
        })
        commit(SET_TOKEN, {access_token: '', id: 0})
        commit(CLEAR_USER)
      }
    },
    loginWithToken ({commit}, payload) {
      commit(SET_LOGIN_MESSAGE, {message: '', type: ''})

      if (isProduction) {
        try {
          // Register just created user as current user on GTM
          window.dataLayer.push({
            'userId': payload.id
          })
        } catch (e) {
          Sentry.captureException(e)
        }
      }

      commit(SET_TOKEN, payload)
      window.mixpanel.identify(payload.id)
      trackOnMixpanel(MIXPANEL_EVENTS.LOGIN)
      router.push('/')
    },
    resetpw ({commit}, payload) {
      commit(SET_TOKEN, {access_token: '', id: 0})
      commit(CLEAR_USER)
      commit(SET_LOADING, true)
      axios.post(`${api}/resetpassword/`, {email: payload.email, site_url: 'https://app.getakko.com'})
        .then(() => {
          if (isProduction) {
            try {
              // clean current user on GTM
              window.dataLayer.push({
                'userId': undefined
              })
            } catch (e) {
              Sentry.captureException(e)
            }
          }
          router.replace('/login')
          commit(SET_LOADING, false)
          commit(SET_LOGIN_MESSAGE, {
            message: 'Password successfully reset. Please check your email or text messages.',
            type: ''
          })
        })
        .catch((error) => {
          console.log(error)
          commit(SET_LOADING, false)
        })
    },
    getUserInformation ({commit}, callback) {
      if (this.state.token && this.state.user_id) {
        axios.get(`${api}/users/${this.state.user_id}`, {
          headers: {Authorization: `Bearer ${this.state.token}`}
        })
          .then((response) => {
            commit(SET_USER, response.data)
            commit(SET_LOADING, false)
            if (callback) {
              callback()
            }
          })
          .catch((error) => {
            console.log(error)
            commit(SET_TOKEN, {access_token: '', id: 0})
            commit(CLEAR_USER)
            commit(SET_LOADING, false)
            if (isProduction) {
              try {
                // clean current user on GTM
                window.dataLayer.push({
                  'userId': undefined
                })
              } catch (e) {
                Sentry.captureException(e)
              }
            }
            router.replace('/login')
          })
      } else {
        // send user to login page
        commit(CLEAR_USER)
        if (isProduction) {
          try {
            // clean current user on GTM
            window.dataLayer.push({
              'userId': undefined
            })
          } catch (e) {
            Sentry.captureException(e)
          }
        }
        router.replace('/login')
      }
    },
    getActiveContract ({commit}, includeSecondaryUsers = false) {
      let queryParams = `is_active=true&include_secondary_users=${includeSecondaryUsers}`
      return axios.get(`${api}/users/${this.state.user_id}/contracts/?${queryParams}`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          let data = response.data
          let primaryContract = []
          if (includeSecondaryUsers) {
            let primaryIndex = data.findIndex((contract) => contract.user.id === this.state.user_id)
            if (primaryIndex !== -1) {
              primaryContract = data.splice(primaryIndex, 1)
              commit(SET_CONTRACT, primaryContract)
              commit(SET_SECONDARY_CONTRACTS, data)
            }
          } else {
            commit(SET_CONTRACT, data)
          }
        })
        .catch((error) => {
          commit(SET_ERRORS, error)
        })
    },
    async getContractLineItemsDeviceRestrictions ({commit}) {
      return axios.get(`${api}/users/${this.state.user_id}/contracts/${this.state.contract.id}/contract-line-items/device-restrictions/`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          commit(SET_CONTRACT_LINE_ITEMS_DEVICE_RESTRICTIONS, response.data)
        })
        .catch((error) => {
          commit(SET_ERRORS, error)
        })
    },
    async getAllItemSpecifications ({commit}) {
      axios.get(`${api}/devices/v2/item_categories/`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          commit(SET_ITEM_SPECIFICATIONS, response.data.data)
        })
        .catch((error) => {
          commit(SET_ERRORS, error)
        })
    },
    updatePassword ({commit}, payload) {
      commit(SET_LOADING, true)
      commit(SET_ERRORS)
      axios.put(`${api}/users/${this.state.user_id}/password/?version=2`, payload, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then(() => {
          commit(SET_LOADING, false)
          const redirectUrl = payload.query_params && payload.query_params.redirect
          if (redirectUrl) {
            const token = payload.query_params && payload.query_params.token
            router.push({path: redirectUrl, query: {token: token}})
          }
          commit(SET_TOKEN, {access_token: '', id: 0})
          commit(CLEAR_USER)
          router.push('/login')
          // this.dispatch('login', {email: this.state.user.email, password: payload.password})
        })
        .catch((error) => {
          commit(SET_LOADING, false)
          commit(SET_ERRORS, error.response.data.message)
          console.log(error)
        })
    },
    updateProfile ({commit}, payload) {
      commit(SET_LOADING, true)
      axios.put(`${api}/users/${this.state.user_id}`, payload, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then(() => {
          commit(SET_USER, {...this.state.user, ...payload})
          commit(SET_LOADING, false)
          commit(SET_ERRORS)
          commit(ADD_MESSAGE, {data: 'Profile updated successfully'})
        })
        .catch((error) => {
          commit(SET_LOADING, false)
          var message = error
          try {
            message = error.response.data.message
          } catch (e) {
            console.warn('Error parsing error message:', e);
          }
          commit(SET_ERRORS, {message})
        })
    },
    verifyPhoneNumber ({commit}, payload) {
      commit(SET_LOADING, true)
      axios.post(`${api}/users/${this.state.user_id}/verify_phone/`, payload, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then((response) => {
          console.log({response})
          commit(SET_LOADING, false)
          commit(SET_ERRORS)
          commit(ADD_MESSAGE, {data: 'Phone number was successfully updated'})
          this.dispatch('getUserInformation')
        })
        .catch((error) => {
          commit(SET_LOADING, false)
          var message = error
          try {
            message = error.response.data.message
          } catch (e) {
            console.warn('Error parsing error message:', e);
          }
          commit(SET_ERRORS, {message})
        })
    },
    updateCreditCard ({commit}, payload) {
      commit(SET_LOADING, true)
      axios.put(`${api}/users/${this.state.user_id}/credit_card/`, payload, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then(() => {
          commit(SET_LOADING, false)
          this.dispatch('getUserInformation')
        })
        .catch((error) => {
          commit(SET_LOADING, false)
          console.log(error)
        })
    },
    updateReferralCode ({commit}, payload) {
      commit(SET_ERRORS)
      commit(SET_LOADING, true)
      axios.put(`${api}/users/${this.state.user_id}`, payload, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then(() => {
          commit(SET_LOADING, false)
          this.dispatch('getUserInformation')
        })
        .catch((error) => {
          commit(SET_LOADING, false)
          commit(SET_ERRORS, error)
        })
    },
    updateCoverage ({commit}, payload) {
      commit(SET_LOADING, true)
      axios.put(`${api}/users/${this.state.user_id}/coverage/`, payload, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then(() => {
          this.dispatch('getUserInformation')
        })
        .catch((error) => {
          commit(SET_LOADING, false)
          console.log(error)
        })
    },
    addDevice ({commit}, payload) {
      commit(SET_ERRORS, null)
      axios.post(`${api}/devices/`, payload, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then((response) => {
          commit(ADD_MESSAGE, {text: 'Item successfully added to your plan', deleteable: true, color: 'green'})
          router.push(`/devices/${response.data.id}`)
        })
        .catch((error) => {
          console.log(error)
          commit(SET_ERRORS, error.response.data.message || error.response.data)
        })
    },
    addDeviceV2 ({commit}, payload) {
      commit(SET_ERRORS, null)
      axios.post(`${api}/api/v2/devices/`, payload, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then((response) => {
          commit(ADD_MESSAGE, {text: 'Item successfully added to your plan', deleteable: true, color: 'green'})
          router.push(`/devices/${response.data.id}`)
        })
        .catch((error) => {
          console.log(error)
          commit(SET_ERRORS, error.response.data.message || error.response.data)
        })
    },
    getDevices ({commit}) {
      commit(RESET_LOADED)
      commit(SET_ERRORS)
      axios.get(`${api}/devices/`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          commit(SET_DEVICES, response.data)
        })
        .catch((error) => {
          commit(SET_ERRORS, error)
        })
    },
    getCurrentDevice ({commit}, payload) {
      axios.get(`${api}/devices/${payload.id}`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          commit(SET_CURRENT_DEVICE, response.data)
        })
        .catch((error) => {
          if (error.response.data) {
            console.log(error.response.data)
          }
        })
    },
    retryGetCurrentDevice ({commit}, payload) {
      let startTime = (new Date()).getTime()
      if (this.state.retryDeviceImages) {
        return
      } else {
        commit(SET_DEVICE_IMAGE_RETRY, true)
      }
      const retry = function () {
        let canPoll = ((new Date()).getTime() - startTime) <= RETRY_TIMEOUT
        if (canPoll) {
          this.dispatch('getCurrentDevice', payload)
          setTimeout(() => { retry() }, RETRY_INTERVAL)
        } else {
          commit(SET_DEVICE_IMAGE_RETRY, false)
        }
      }.bind(this)
      retry()
    },
    deleteDevice ({commit}, payload) {
      axios.delete(`${api}/devices/${payload.id}`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then(() => {
          commit(SET_CURRENT_DEVICE, {
            id: 0,
            type: '',
            brand: '',
            model: '',
            nickname: '',
            serial_number: ''
          })
          commit(ADD_MESSAGE, {text: 'Item successfully removed from your plan', deleteable: true, color: 'green'})
          router.push('/devices')
        })
        .catch((error) => {
          if (error.response.data) {
            console.log(error.response.data)
          }
        })
    },
    deleteDeviceFile ({commit}, payload) {
      commit(SET_LOADING, true)
      axios(
        {
          method: 'DELETE',
          url: `${api}/devices/${payload.device_id}/files/${payload.file_id}`,
          headers: {
            Authorization: `Bearer ${this.state.token}`
          },
          data: {
            'file_type': payload.file_type
          }
        })
          .then(() => {
            commit(SET_LOADING, false)
            this.dispatch('getCurrentDevice', {id: payload.device_id})
          })
          .catch(() => {
            commit(SET_LOADING, false)
          })
    },
    uploadToFilePipeline ({commit}, payload) {
      const filePipelineApiKey = process.env.VUE_APP_FILE_PIPELINE_API_KEY
      const filePipelineApiPath = process.env.NODE_ENV === 'development'
        ? process.env.VUE_APP_IMAGES_API_ENDPOINT
        : api
      commit(SET_LOADING, true)
      commit(SET_SPINNER_TEXT, 'Upload in progress, do not exit out of page.')
      const filePipelineUrl = new URL(filePipelineApiPath + '/upload')
      filePipelineUrl.search = new URLSearchParams({
        api_key: filePipelineApiKey,
        id: payload.entity_id, // device_id or claim_id
        type: payload.type, // ITEM or CLAIM or STUDENT_VERIFICATION
        filename: encodeURI(payload.file.name)
      })
      return axios.get(`${filePipelineUrl}`)
        .then((response) => {
          commit(SET_LOADING, false)
          commit(SET_SPINNER_TEXT, null)
          const url = response.data.url
          return axios.put(url, payload.file, {
            headers: response.data.headers
          })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          commit(SET_LOADING, false)
          commit(SET_SPINNER_TEXT, null)
          console.log(error)
          commit(SET_ERRORS, error.response.data.message)
        })
    },
    submitDeviceMedia ({commit}, payload) {
      commit(SET_LOADING, true)
      const data = {
        'files': [{
          'side': payload.side,
          'file_name': encodeURI(payload.file.name)
        }]
      }
      axios.post(`${api}/devices/${payload.entity_id}/files/v2/`, data, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then(() => {
          return this.dispatch('uploadToFilePipeline', payload)
        })
        .then(() => {
          commit(SET_LOADING, false)
          commit(SET_SPINNER_TEXT, null)
          this.dispatch('getCurrentDevice', {id: payload.entity_id})
        })
        .catch((error) => {
          commit(SET_LOADING, false)
          console.log(error)
          commit(SET_ERRORS, error.response.data.message)
        })
    },
    submitImage ({commit}, payload) {
      commit(SET_LOADING, true)
      const formData = new FormData()
      formData.append('side', payload.side)
      formData.append('fromWeb', true)
      formData.append('image', payload.image, payload.image.name)
      axios.post(`${api}/devices/${payload.device_id}/images/`, formData, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then((response) => {
          commit(SET_LOADING, false)
          commit(SET_IMAGES, response.data.photos_submitted)
          this.dispatch('getCurrentDevice', {id: payload.device_id})
        })
        .catch((error) => {
          commit(SET_LOADING, false)
          console.log(error)
          commit(SET_ERRORS, error.response.data.message)
        })
    },
    submitReceipt ({commit}, payload) {
      commit(SET_LOADING, true)
      const formData = new FormData()
      formData.append('side', payload.side)
      formData.append('receipt', payload.image, payload.image.name)
      formData.append('fromWeb', 'true')
      axios.post(
        `${api}/devices/${payload.device_id}/receipts/`,
        formData,
        {headers: {Authorization: `Bearer ${this.state.token}`}}
      )
        .then(() => {
          commit(SET_LOADING, false)
          this.dispatch('getCurrentDevice', {id: payload.device_id})
        })
        .catch((error) => {
          commit(SET_LOADING, false)
          if (error.response.data && error.response.data.message) {
            commit(SET_ERRORS, error.response.data.message)
          }
        })
    },
    getStorageFiles ({commit}) {
      axios.get(`${api}/files/`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          commit(SET_FILES, response.data)
        })
        .catch((error) => {
          if (error.response.data) {
            console.log(error.response.data)
          }
        })
    },
    uploadFiles ({commit}, payload) {
      commit(SET_LOADING, true)
      const formData = new FormData()
      formData.append('fromWeb', 'true')
      for (let f of payload) {
        formData.append('files', f.file, f.file.name)
      }
      axios.post(`${api}/files/`, formData, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then(() => {
          commit(SET_LOADING, false)
          // TODO: handle this better
          this.dispatch('getStorageFiles')
        })
        .catch((error) => {
          console.log(error)
          commit(SET_LOADING, false)
        })
    },
    deleteFile ({commit}, payload) {
      commit(SET_LOADING, true)
      axios.delete(`${api}/files/${payload.id}`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then(() => {
          this.dispatch('getStorageFiles')
          commit(SET_LOADING, false)
        })
        .catch((error) => {
          console.log(error)
          commit(SET_LOADING, false)
        })
    },
    confirmEmail ({commit}, payload) {
      // Set the loading flag
      commit('SET_LOADING', true)
      commit(SET_ERRORS, null)

      axios.get(`${api}/confirm/${payload.token}`)
        .then(() => {
          const redirectUrl = payload.redirectUrl
          if (redirectUrl) {
            router.push(redirectUrl)
          }
          commit(SET_LOADING, false)
        })
        .catch((error) => {
          console.log(error)
          commit(SET_ERRORS, 'Unable to verify account')
          commit(SET_LOADING, false)
        })
    },
    requestVerificationEmail () {
      axios.post(`${api}/resendemail/`, {site_url: 'https://app.getakko.com'}, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    resetJustActivated ({commit}) {
      commit(SET_JUST_ACTIVATED, false)
    },
    getFeatureFlags ({commit}) {
      axios.get(`${api}/feature_flags/`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          commit(SET_FEATURE_FLAG, response.data.data)
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error)
        })
    },
    resetCurrentDevice ({commit}) {
      commit(SET_CURRENT_DEVICE, {
        id: 0,
        type: '',
        brand: '',
        model: '',
        nickname: '',
        serial_number: '',
        images: [],
        photos_submitted: false
      })
    },
    getItemOperabilities ({commit}) {
      axios.get(`${api}/devices/item-operabilities/`)
        .then((response) => {
          commit(SET_ITEM_OPERABILITIES, response.data.data)
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error)
        })
    },
    async getItemPurchaseConditions ({commit}) {
      axios.get(`${api}/api/v1/devices/item-purchase-conditions/`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          commit(SET_ITEM_PURCHASE_CONDITIONS, response.data.data)
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error)
        })
    },
    async getMobileCarriers ({commit}) {
      axios.get(`${api}/mobile_operators/?country_code=${this.state.user.country_code}`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          commit(SET_MOBILE_OPERATORS, response.data.operators)
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error)
        })
    },
    async getProductCategories ({commit}) {
      axios.get(`${api}/products/categories/`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      })
        .then((response) => {
          commit(SET_PRODUCT_CATEGORIES, response.data.data)
        })
    }
  },
  getters: {
    [GET_FEATURE_FLAG]: (state) => {
      return (featureFlag) => {
        return state.feature_flags.find((ff) => ff.feature === featureFlag)
      }
    },
    [GET_DEVICES]: (state) => {
      return state.devices
    },
    [GET_USER]: (state) => {
      return state.user
    },
    [GET_PARTNER]: (state) => {
      return state.partner
    },
    [GET_THEMES]: (state) => {
      return state.themes
    },
    [GET_USER_JUST_ACTIVATED]: (state) => state.user_just_activated,
    getDevicesByQuery: (state) => (query) => {
      const matchSubStrToQuery = (query, inputString) => {
        if (typeof inputString === 'string') {
          inputString = inputString.toLowerCase()
          query = query.toLowerCase()
          return inputString.includes(query)
        }
        return false
      }
      const matchSubStrToQueryCallback = (query) => {
        return (inputString) => matchSubStrToQuery(query, inputString)
      }

      const findQueryInValues = (query, inputObject) => {
        return Object.values(inputObject).some(matchSubStrToQueryCallback(query))
      }
      const findQueryInValuesCallback = (query) => {
        return (inputObject) => findQueryInValues(query, inputObject)
      }

      if (query === '') {
        return state.devices
      }
      return state.devices.filter(findQueryInValuesCallback(query))
    },
    itemRestrictions: (state) => {
      let itemRestrictionLineItem = state.contract_line_items.find((lineItem) => {
        return Object.keys(lineItem.protection_plan).length !== 0
      })
      return itemRestrictionLineItem ? itemRestrictionLineItem.protection_plan.device_restrictions : undefined
    },
    itemPurchaseConditions: (state) => {
      return state.item_purchase_conditions
    },
    mobileOperators: (state) => {
      return state.mobile_operators
    },
    isUserPrimary: (state) => {
      return state.user.user_id === state.user.primary_account_holder.id
    }
  },
  plugins: [
    createPersistedState({
      paths: ['registration', 'registrationv2', 'activation', 'token', 'user_id', 'user', 'coverageOptions', 'countries', 'selectedCountryCurrency']
    })
  ],
  strict: debug
})
