<template>
  <main class="">
    <div class="flex items-end ">
      <span class="f2 b title-responsive ml4-ns">{{ $t('titles.hi', { faq: 'Hi,' }) }} {{ user.first_name }}</span>
    </div>
    <div class="mv4-l mv2" />
    <div class="flex flex-wrap justify-evenly content">
      <div class="w-30-l pa4 bg-white account-card mb4-l mb4-m pointer">
        <router-link
          class="no-underline dib-ns"
          to="/devices"
        >
          <div class="responsive-card">
            <div class="red-circle">
              {{ devices.length }}
            </div>
            <img
              alt=""
              class="mr1 mb1"
              src="@/assets/appicons/yourStuff.svg"
            >
            <div class="flex flex-column justify-center text-responsive">
              <div class="mb2 cera-round-pro-medium fw1">
                {{ $t('titles.stuff', { stuff: 'Your Stuff' }) }}
              </div>
              <span class="f6 inter">{{ itemsSubheader }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="w-30-l pa4 bg-white account-card mb4-l mb4-m mb0 pointer">
        <router-link
          class="no-underline dib-ns"
          :to="featureFlagRedirectClaimV2 ? '/claims-v2' : '/claims'"
        >
          <div class="responsive-card">
            <div class="red-circle">
              {{ claims.Open.length }}
            </div>
            <img
              alt=""
              class="mr1 mb1"
              src="@/assets/appicons/yourClaims.svg"
            >
            <div class="flex flex-column justify-center text-responsive">
              <div class="mb2 cera-round-pro-medium fw1">
                {{ $t('titles.claims', { claims: 'Your Claims' }) }}
              </div>
              <span class="f6 inter">{{ claimsSubheader }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="w-30-l pa4 bg-white account-card mb4-l mb4-m mb0 pointer">
        <router-link
          class="no-underline dib-ns"
          to="/account/dashboard"
        >
          <div class="responsive-card">
            <div class="red-circle">
              <img
                alt=""
                class="icon-account"
                src="@/assets/appicons/yourAccountCheck.svg"
              >
            </div>
            <img
              alt=""
              class="mr1 mb1"
              src="@/assets/appicons/yourAccount.svg"
            >
            <div class="flex flex-column justify-center text-responsive">
              <div class="mb2 cera-round-pro-medium fw1">
                {{ $t('titles.account', { account: 'Your Account' }) }}
              </div>
              <span class="f6 inter">Information &amp; Settings</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="w-30-l empty-card pa4 bg-white mb4-l mb4-m mb0 o1" />
    </div>
  </main>
</template>

<script>
import { GET_FEATURE_FLAG } from '@/store/index'
import { REDIRECT_CLAIM_V2 } from '@/constants/feature_flag'

export default {
  name: 'DashboardItems',
  props: {
    devices: {
      type: Array,
      default () {
        return []
      }
    },
    user: {
      type: Object,
      default () {
        return {}
      }
    },
    claims: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    itemsSubheader () {
      if (this.devices.length === 0) {
        return 'No items added to your plan yet'
      }
      if (this.devices.length === 1) {
        return '1 item added to your plan'
      }
      return `${this.devices.length} items added to your plan`
    },
    claimsSubheader () {
      if (!this.claims.Open || !this.claims.Open.length) {
        return 'No currently open claims'
      }
      if (this.claims.Open.length === 1) {
        return '1 claim currently open'
      }
      return `${this.claims.Open.length} claims currently open`;
    },
    featureFlagRedirectClaimV2 () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](REDIRECT_CLAIM_V2)
      return feature ? feature.state : false
    }
  }
}
</script>

<style scoped>

.account-card {
  border-radius: 5px;
  border: 1px solid #E8E8E8;
}

.height {
  height: 50px;
}

.justify-evenly {
  justify-content: space-evenly;
}

.border {
  border: 1px dashed var(--theme-primary);
  border-radius: 5px;
}

.image-width {
  width: 27px;
}

.title-responsive {
   margin-top: 1.5rem;
  }

.red-circle {
  border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 6px;
    background: var(--theme-primary);
    border: 0px solid #000;
    color: white;
    text-align: center;
    font-size: 12px;
    position: absolute;
    left: -10px;
    top: -3px;
}

.icon-account {
  margin-top: 2px;
}

.responsive-card {
    position: relative;
  }

@media (min-width: 1281px) {
  .text-responsive {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1280px) {
  .account-card {
    width: 45%
  }

  .text-responsive {
    margin-left: 1rem;
  }

  .empty-card {
    width: 45%
  }

  .responsive-card {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .account-card {
    width: 100%;
    border: none;
    border-bottom: 1px solid #E8E8E8;
    border-radius: 0px;
    margin-bottom: 0px;
    padding: 1.9rem 0rem;
  }

  .account-card:nth-child(3) {
    border: none;
  }


  .empty-card {
    display: none;
  }

  .responsive-card {
    display: flex;
    flex-direction: row;
  }

  .title-responsive {
    font-size: 24px;
    margin-bottom: -10px;
    margin-top: 1rem;
  }

  .text-responsive {
    margin-left: 1rem;
  }

  .height {
    height: auto;
  }

  .red-circle {
    left: 0px;
    top: -5px;
}
}


a {
  color: inherit;
}

</style>
