import axios from 'axios'
import {
  SUBMIT_COVERAGE,
  SUBMIT_COVERAGE_SECOND,
  SUBMIT_COVERAGE_THIRD,
  SUBMIT_EMAIL,
  SET_PHONE,
  SET_SUBSCRIPTION_ID,
  SET_ORDER_AMOUNT,
  SET_STRIPE_CUSTOMER_ID,
  CLEAR_REGISTRATION,
  SET_ERRORS,
  SET_TOKEN,
  SET_ACTIVATION_FEE,
  SET_FULL_PATH,
  SET_REG_EMAIL_ERROR,
  SET_REG_PHONE_ERROR,
  SET_REG_PHONE_ERROR_V3,
  SET_LOADING,
  SET_PROMO,
  RESET_PROMO_STATUS,
  RESET_PROMO_ALL,
  SET_REDEMPTION,
  RESET_REDEMPTION_STATUS,
  RESET_REDEMPTION_ALL,
  RESET_ACTIVATION_FEE,
  RESET_COVERAGE_SECOND,
  RESET_COVERAGE_THIRD,
  SET_VERIFICATION_FILE,
  SET_SCHOOL
} from './mutations'
import router from '../router'

const isProduction = process.env.NODE_ENV === 'production'
const api = process.env.VUE_APP_API_URL

const registration = {
  state: {
    coverage: {
      id: 0,
      name: '',
      price: 0,
      deductible: 0,
      max_coverage: 0
    },
    // optional (only for redemption code)
    coverage_second: {
      id: 0,
      name: '',
      price: 0,
      deductible: 0,
      max_coverage: 0
    },
    // optional (only for redemption code)
    coverage_third: {
      id: 0,
      name: '',
      price: 0,
      deductible: 0,
      max_coverage: 0
    },
    email: '',
    phone_no: '',
    subscription_id: '',
    order_amount: '',
    stripe_customer_id: '',
    school: '',
    verification_file: null,
    email_error: false,
    phone_error: false,
    promo: {
      code: '',
      status: '',
      amount_off: 0,
      duration_in_months: 1
    },
    redemption: {
      code: '',
      status: '',
      supplier: '',
      amount_off: 0,
      msg: '',
      notes: '',
      partner: ''
    },
    activation_fee: {
      applies: false,
      value: 5
    },
    last_full_path: ''
  },
  mutations: {
    [SUBMIT_COVERAGE] (state, payload) {
      state.coverage = payload
    },
    [SUBMIT_COVERAGE_SECOND] (state, payload) {
      state.coverage_second = payload
    },
    [SUBMIT_COVERAGE_THIRD] (state, payload) {
      state.coverage_third = payload
    },
    [SUBMIT_EMAIL] (state, payload) {
      state.email = payload.email
    },
    [SET_PHONE] (state, payload) {
      state.phone_no = payload.phone_no
    },
    [SET_VERIFICATION_FILE] (state, payload) {
      state.verification_file = payload.verification_file
    },
    [SET_SCHOOL] (state, payload) {
      state.school = payload.school
    },
    [SET_ACTIVATION_FEE] (state, payload) {
      state.activation_fee = payload
    },
    [SET_FULL_PATH] (state, payload) {
      state.last_full_path = payload
    },
    [SET_REG_EMAIL_ERROR] (state, payload) {
      state.email_error = payload
    },
    [SET_REG_PHONE_ERROR] (state, payload) {
      state.phone_error = payload
    },
    [SET_REG_PHONE_ERROR_V3] (state, payload) {
      state.phone_errorV3 = payload
    },
    [SET_PROMO] (state, payload) {
      state.promo.code = payload.code
      state.promo.status = payload.status
      state.promo.amount_off = parseInt(payload.amount_off, 10)
      state.promo.duration_in_months = parseInt(payload.duration_in_months)
    },
    [RESET_PROMO_STATUS] (state) {
      state.promo.status = ''
      state.promo.amount_off = 0
      state.promo.duration_in_months = 1
    },
    [RESET_PROMO_ALL] (state) {
      state.promo.code = ''
      state.promo.status = ''
      state.promo.amount_off = 0
      state.promo.duration_in_months = 1
    },
    [SET_REDEMPTION] (state, payload) {
      state.redemption.code = payload.code
      state.redemption.status = payload.status
      state.redemption.supplier = payload.supplier
      state.redemption.amount_off = parseInt(payload.amount_off, 10)
      state.redemption.msg = payload.msg
      state.redemption.notes = payload.notes
      state.redemption.partner = payload.partner
    },
    [RESET_REDEMPTION_STATUS] (state) {
      state.redemption.status = ''
      state.redemption.supplier = ''
      state.redemption.amount_off = 0
      state.redemption.msg = 0
      state.redemption.notes = ''
    },
    [RESET_REDEMPTION_ALL] (state) {
      state.redemption.code = ''
      state.redemption.status = ''
      state.redemption.supplier = ''
      state.redemption.amount_off = 0
      state.redemption.msg = 0
      state.redemption.notes = ''
    },
    [RESET_ACTIVATION_FEE] (state) {
      state.activation_fee = {
        applies: false,
        value: 5
      }
    },
    [RESET_COVERAGE_SECOND] (state) {
      state.coverage_second = {
        id: 0,
        name: '',
        price: 0,
        deductible: 0,
        max_coverage: 0
      }
    },
    [RESET_COVERAGE_THIRD] (state) {
      state.coverage_third = {
        id: 0,
        name: '',
        price: 0,
        deductible: 0,
        max_coverage: 0
      }
    },
    [CLEAR_REGISTRATION] (state) {
      state.coverage = {
        id: '',
        name: '',
        price: 0,
        deductible: 0,
        max_coverage: 0
      }
      state.coverage_second = {
        id: '',
        name: '',
        price: 0,
        deductible: 0,
        max_coverage: 0
      }
      state.coverage_third = {
        id: '',
        name: '',
        price: 0,
        deductible: 0,
        max_coverage: 0
      }
      state.email = ''
      state.verification_file = null
      state.school = ''
      state.email_error = false
      state.phone_error = false
      state.phone_errorV3 = {}
      state.promo = {
        code: '',
        status: '',
        amount_off: 0,
        duration_in_months: 1
      }
      state.redemption = {
        code: '',
        status: '',
        supplier: '',
        amount_off: 0,
        msg: '',
        notes: '',
        partner: ''
      }
      state.activation_fee = {
        applies: false,
        value: 5
      }
      state.last_full_path = ''
    }
  },
  actions: {
    checkEmailExists ({ commit }, payload) {
      axios.post(`${api}/checkemail/`, payload)
      .then(() => {
        commit(SET_REG_EMAIL_ERROR, false)
      })
      .catch((error) => {
        if (error.response.status === 409) {
          commit(SET_REG_EMAIL_ERROR, true)
        }
      })
    },
    checkPhoneExists ({ commit }, payload) {
      axios.post(`${api}/checkphone/`, payload)
      .then(() => {
        commit(SET_REG_PHONE_ERROR, false)
      })
      .catch((error) => {
        if (error.response.status === 409) {
          commit(SET_REG_PHONE_ERROR, true)
        }
      })
    },
    checkPhoneExistsV3 ({ commit }, payload) {
      axios.post(`${api}/checkphone/`, { phone: payload.phone })
      .then(() => {
        commit(SET_REG_PHONE_ERROR_V3, {error: false, index: payload.index})
      })
      .catch((error) => {
        if (error.response.status === 409) {
          commit(SET_REG_PHONE_ERROR_V3, {error: true, index: payload.index})
        }
      })
    },
    generateLead () {
      if (this.state.registrationv2.primaryEmail) {
        const lead = {
          email: this.state.registrationv2.primaryEmail,
          phone_no: this.state.registrationv2.coverages.map(coverage => coverage.phone)[0],
          state: this.state.registrationv2.addressState
        }
        axios.post(`${api}/leads/`, lead)
        .catch((error) => console.log(error))

        if (isProduction) {
          // Also send zapier hook
          axios.get(`https://hook.integromat.com/ang72yt8x3e9pa4nc6anmps8azkqey9o?email=${encodeURIComponent(lead.email)}&phone_no=${encodeURIComponent(lead.phone_no)}`)
          .catch((error) => console.log(error))
        }
      }
    },
    checkPromo ({ commit }, payload) {
      const promoData = {
        code: payload.promo_code,
        coverage_id: this.state.registration.coverage.id
      }
      commit(RESET_PROMO_STATUS)
      axios.post(`${api}/checkpromo/`, promoData)
      .then((response) => {
        commit(SET_PROMO, {
          code: payload.promo_code,
          status: response.data.status,
          amount_off: (response.data.amount_off || 0),
          duration_in_months: (response.data.duration_in_months || 1)
        })
      })
      .catch((error) => {
        var message = error
        try {
          message = error.response.data.message
        } catch (e) {
          console.warn('Error parsing error message:', e);
        }
        commit(SET_ERRORS, message)
      })
    },
    checkRedemptionCode ({ commit }, payload) {
      const redemptionData = {
        code: payload.redemption_code,
        coverage_id: this.state.registration.coverage.id
      }
      commit(RESET_REDEMPTION_STATUS)
      commit(RESET_ACTIVATION_FEE)
      commit(RESET_COVERAGE_SECOND)
      commit(RESET_COVERAGE_THIRD)
      axios.post(`${api}/checkredemptioncode/`, redemptionData)
      .then((response) => {
        var amountOff = 0
        // Set primary coverage and addon coverages for valid redemption code
        if (response.data.status === 'valid') {
          commit(SUBMIT_COVERAGE, response.data.coverage)
          amountOff = response.data.redemption_value
          if (response.data.coverage_second) {
            commit(SUBMIT_COVERAGE_SECOND, response.data.coverage_second)
          }
          if (response.data.coverage_third) {
            commit(SUBMIT_COVERAGE_THIRD, response.data.coverage_third)
          }
        }
        // Set redemtpion code data
        commit(SET_REDEMPTION, {
          code: payload.redemption_code,
          status: response.data.status,
          supplier: response.data.supplier,
          amount_off: amountOff,
          msg: response.data.message,
          notes: response.data.notes,
          partner: response.data.partner_property ? response.data.partner_property.name : ''
        })
        // Set activation fee (if this redemption code has one)
        if (response.data.activation_fee !== undefined) {
          commit(SET_ACTIVATION_FEE, {
            applies: true,
            value: response.data.activation_fee
          })
        }
      })
      .catch(() => {
        commit(SET_REDEMPTION, {
          status: 'invalid'
        })
      })
    },
    redeemAndRegister ({ commit }, payload) {
      commit(SET_LOADING, true)
      commit(SET_ERRORS, null)
      var redemptionCode = ''
      if (this.state.registration.redemption.status.toLowerCase() === 'valid') {
        redemptionCode = this.state.registration.redemption.code
      }
      const registrationForm = {
        email: payload.email,
        phone_no: payload.phone_no,
        coverage_id: this.state.registration.coverage.id,
        redemption_code: redemptionCode.toUpperCase(),
        site_url: 'https://app.getakko.com'
      }
      axios.post(`${api}/registrations/partner-redeem/`, registrationForm)
      .then((response) => {
        commit(SET_ORDER_AMOUNT, response.data.amount)
        commit(SET_TOKEN, response.data)
        if (payload.verification_file == null) {
          commit(SET_LOADING, false)
          commit(CLEAR_REGISTRATION, {})
          router.push('/registration/complete')
        }
      })
      .catch((error) => {
        commit(SET_LOADING, false)
        var message = error
        try {
          message = error.response.data.message
        } catch (e) {
          console.warn('Error parsing error message:', e);
        }
        commit(SET_ERRORS, message)
      })
    },
    submitRegistration ({ commit }, payload) {
      commit(SET_LOADING, true)
      commit(SET_ERRORS, null)
      var promoCode = ''
      if (this.state.registration.promo.status.toLowerCase() === 'valid') {
        promoCode = this.state.registration.promo.code
      }
      var redemptionCode = ''
      if (this.state.registration.redemption.status.toLowerCase() === 'valid') {
        redemptionCode = this.state.registration.redemption.code
      }
      const registrationForm = {
        email: payload.email,
        phone_no: payload.phone_no,
        coverage_id: this.state.registration.coverage.id,
        stripe_token: payload.stripe_token,
        card_last_name: payload.card_last_name,
        card_first_name: payload.card_first_name,
        promo_code: promoCode.toUpperCase(),
        redemption_code: redemptionCode.toUpperCase(),
        site_url: 'https://app.getakko.com',
        activation_fee: this.state.registration.activation_fee.value,
        // backend is expecting this variable
        referral_code: payload.referral_code ? payload.referral_code : ''
      }
      axios.post(`${api}/registrations/`, registrationForm)
      .then((response) => {
        commit(SET_SUBSCRIPTION_ID, response.data.stripe_subscription_id)
        commit(SET_STRIPE_CUSTOMER_ID, response.data.subscription_id)
        commit(SET_ORDER_AMOUNT, response.data.amount)
        commit(SET_TOKEN, response.data)
        if (payload.verification_file == null) {
          commit(SET_LOADING, false)
          commit(CLEAR_REGISTRATION, {})
          router.push('/registration/complete')
        }
        if (payload.verification_file != null) {
          const verificationData = new FormData()
          verificationData.append('verification_file', payload.verification_file, payload.verification_file.name)
          verificationData.append('school', payload.school)
          axios.post(`${api}/registrations/verification/images/`, verificationData, { headers: { Authorization: `Bearer ${this.state.token}` } })
          .then(() => {
            commit(SET_LOADING, false)
            commit(CLEAR_REGISTRATION, {})
            router.push('/registration/complete')
          })
          .catch((error) => {
            commit(SET_LOADING, false)
            var message = error
            try {
              message = error.response.data.message
            } catch (e) {
              console.warn('Error parsing error message:', e);
            }
            commit(SET_ERRORS, message)
          })
        }
      })
      .catch((error) => {
        commit(SET_LOADING, false)
        var message = error
        try {
          message = error.response.data.message
        } catch (e) {
          console.warn('Error parsing error message:', e);
        }
        commit(SET_ERRORS, message)
      })
    }
  },
  getters: {

  }
}

export default registration
