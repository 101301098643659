const CLAIM_STATUS = Object.freeze({
  DRAFT: 'DRAFT',
  NEW: 'NEW',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  FRAUD_PRE_FILING: 'FRAUD_PRE_FILING',
  FRAUD_POST_FILING: 'FRAUD_POST_FILING',
  DENIED_WAITING_PERIOD: 'DENIED_WAITING_PERIOD',
  DENIED_PHOTO_BLOCK: 'DENIED_PHOTO_BLOCK',
  CLOSED_BY_USER: 'CLOSED_BY_USER',
  UNCOVERED_LOSS: 'UNCOVERED_LOSS',
  COVERED_BY_MANUFACTURER_WARRANTY: 'COVERED_BY_MANUFACTURER_WARRANTY',
  NOT_COVERED: 'NOT_COVERED'
})

export default CLAIM_STATUS
