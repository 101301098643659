<template>
  <div>
    <div
      class="tc bg-white shadow-theme br-all"
      :class="[
        disabled ? 'gray' : '',
        featured ? 'b-theme b-solid bw1' : '',
      ]"
    >
      <div
        v-if="showSavingsBanner"
        class="f4 bg-theme white tc pv3 savings-banner"
      >
        <strong>SAVE ${{ savings.toLocaleString() }}!</strong>
      </div>

      <div class="pa4">
        <div
          v-if="annual"
          class="mb4 fw3"
        >
          <div class="b">
            <span class="f5 v-top">$</span>
            <span
              v-if="planType === 'family'"
              class="price"
            >{{ (price / 12).toFixed(2) }}</span>
            <span
              v-else
              class="price"
            >{{ (price / 12).toFixed(2) }}</span>
            <span class="f5"> / month </span>
          </div>
          <div class="mt2 silver">
            <span
              :v-text="$t('planssettings.phoneonly.billed')"
              class="f5"
            />
            <span class="f6 v-top">$</span>
            <span class="f5">{{ price.toFixed(2) }}</span>
            <span class="f6"> / year </span>
          </div>
          <!-- <ak-two-year-upfront-checkbox :annualValue="price.toFixed(2)" /> -->
        </div>

        <div
          v-if="!annual"
          class="mb4 fw3"
        >
          <div class="b">
            <span class="f5 v-top">$</span>
            <span
              v-if="planType === 'family'"
              class="price"
            >{{ price.toFixed(2) }}</span>
            <span
              v-else
              class="price"
            >{{ price.toFixed(2) }}</span>
            <span class="f5"> / month </span>
          </div>
          <div class="mt2 silver">
            <span class="f5">Total: </span>
            <span class="f6 v-top">$</span>
            <span class="f5">{{ (price * 12).toFixed(2) }}</span>
            <span class="f6"> / year </span>
          </div>
        </div>

        <div class="mt3 mb3">
          <svg
            v-if="current"
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            class="color-green dib v-sub"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M12 22s8-4 8-10V4l-8-2-8 2v8c0 6 8 10 8 10z" />
          </svg>
          <div class="f4 fw4 ttc color-theme">
            {{ name }}
          </div>
          <div
            v-if="planType === 'family'"
            class="f6 fw4 fw3 mt2"
          >
            5% Multi-plan discount applied
          </div>
        </div>

        <div
          v-if="showFeatures"
          class="f6"
        >
          <hr class="b-theme bg-theme b-solid mv3 mw3 center">
          <div
            v-if="planType === 'AKKO'"
            class="mt3 black"
          >
            <div class="lh-copy">
              Add 1 Phone + 25 items*
            </div>
            <div class="silver lh-copy f6">
              Damage &amp; Theft Protection
            </div>
          </div>
          <div
            v-if="planType === 'family'"
            class="mt3 black"
          >
            <div class="lh-copy">
              Add 1 Phone + 25 items per account
            </div>
            <div class="silver lh-copy f6">
              Damage &amp; Theft Protection
            </div>
          </div>

          <div
            v-if="planType === 'PHONE'"
            class="mt3 black"
          >
            <div class="lh-copy">
              {{ phoneMake }} - {{ phoneModel }}
            </div>
          </div>

          <div
            v-if="planType === 'family'"
            class="mt3 black"
          >
            <div class="lh-copy">
              $29-99 Deductibles
            </div>
            <div class="silver lh-copy f6">
              for phones
            </div>
          </div>
          <div
            v-if="planType === 'family'"
            class="mt3 black"
          >
            <div class="lh-copy">
              $49 Student Deductible
            </div>
            <div class="silver lh-copy f6">
              for all other items
            </div>
          </div>
          <div
            v-if="planType === 'family'"
            class="mt3 black"
          >
            <div class="lh-copy">
              $99 Adult Deductible
            </div>
            <div class="silver lh-copy f6">
              for all other items
            </div>
          </div>
          <div
            v-if="planType === 'AKKO'"
            class="mt3 black"
          >
            <div class="lh-copy">
              $29-99 Deductibles
            </div>
            <div class="silver lh-copy f6">
              for phones
            </div>
          </div>
          <div
            v-if="planType === 'AKKO'"
            class="mt3 black"
          >
            <div class="lh-copy">
              ${{ deductible }} Deductible
            </div>
            <div class="silver lh-copy f6">
              for all other items
            </div>
          </div>

          <div v-if="planType === 'PHONE'">
            <div class="mt3 black">
              <div class="lh-copy">
                ${{ phoneRepairDeductible }} Deductible
              </div>
              <div class="silver lh-copy f6">
                for repairs
              </div>
            </div>
            <div class="mt3 black">
              <div class="lh-copy">
                ${{ phoneReplacementDeductible }} Deductible
              </div>
              <div class="silver lh-copy f6">
                for replacement/theft
              </div>
            </div>
          </div>

          <!-- <div class="mt3 black">
            <div class="lh-copy">Apple Store repairs</div>
            <div class="silver lh-copy f6">fully covered</div>
          </div> -->

          <!-- <div class="mt3 black">
            <div class="lh-copy">"A" Rated Coverage </div>
            <div class="silver lh-copy f6">by Allmerica Insurance</div>
          </div> -->
        </div>

        <div class="mt4 mb3 mw5 f4 center">
          <ak-button
            block
            :disabled="current || disabled"
            :secondary="!featured && !current"
            :primary="featured && !current"
            @click.native="submit"
          >
            <span>Select</span>
          </ak-button>
        </div>

        <div class="mt3 silver lh-copy f6">
          <div v-if="activationFee">
            +$5 one-time activation fee
          </div>
          <div>Upgrade or downgrade anytime</div>
          <div>Prorated adjustments</div>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import AkButton from './Button'

export default {
  name: 'AkCoverageCard',
  components: {
    AkButton
  },
  props: {
    name: {
      type: String,
      default: 'Basic'
    },
    price: {
      type: Number,
      default: 10
    },
    deductible: {
      type: Number,
      default: 50
    },
    phoneRepairDeductible: {
      type: Number,
      default: 50
    },
    phoneReplacementDeductible: {
      type: Number,
      default: 50
    },
    claims: {
      type: Number,
      default: 2000
    },
    featured: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: false
    },
    coverageid: {
      type: Number,
      required: true
    },
    annual: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    savings: {
      type: Number,
      default: 0
    },
    activationFee: {
      type: Boolean,
      default: false
    },
    showFeatures: {
      type: Boolean,
      default: true
    },
    editPlan: {
      type: Boolean,
      default: false
    },
    planType: {
      type: String,
      default: 'AKKO'
    },
    phoneMake: {
      type: String,
      default: ''
    },
    phoneModel: {
      type: String,
      default: ''
    }
  },
  computed: {
    showSavingsBanner () {
      return this.annual && this.savings !== 0
    }
  },
  methods: {
    submit () {
      const coverage = {
        id: this.coverageid,
        name: this.name,
        price: this.price,
        deductible: this.deductible,
        max_coverage: this.claims
      }
      this.$store.commit('SUBMIT_COVERAGE', coverage)
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.v-sub {
  vertical-align: sub;
}

.price {
  font-size: 3.5em;
}

.br-all {
  border-radius: 20px;
}

.savings-banner {
  border-radius: 20px 20px 0 0;
  margin: 0 -1px;
}

.bs-custom {
  box-shadow: 0px 5px 30px 0px rgba(0,0,0,0.2);
}
</style>
