<template>
  <div class="mt3 responsive-margin">
    <div class="mt3 flex flex-column inter responsive-none">
      <div class="flex f5 top-container-realign items-center mb2 flex-row">
        <div class="pl4 silver">
          <router-link
            class="no-underline account-hover soft-grey"
            to="/account/dashboard"
          >
            {{ $t('menu.accountsettings', { accountsettings: 'Account Settings' }) }}
          </router-link>
        </div>
        <div class="soft-grey">
          &nbsp;  /  &nbsp;
        </div>
        <div class="soft-grey">
          {{ $t('menu.referrals', { referrals: 'Referrals' }) }}
        </div>
      </div>
      <h2 class="pl4 f2 mb3 title-realign cera-round-pro">
        Refer & Earn!
      </h2>
    </div>
    <div class="flex flex-row">
      <div class="responsive width">
        <ReferralsComponent />
      </div>
      <div
        class="responsive-none w-40"
        style="visibility: hidden"
      >
        <RightComponent
          image="referrals.png"
          name="Share AKKO with your friends & family"
        />
      </div>
    </div>
  </div>
</template>

<script>

import ReferralsComponent from './ReferralsComponent.vue'
import RightComponent from '../RightComponent.vue'

export default {
  name: 'UnifiedReferrals',
  components: {
    ReferralsComponent,
    RightComponent
  },
  props: {
    screen: String
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  }
}
</script>

<style scoped>
.container {
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: normal;
  align-content: normal;
  margin-left: 0;
}

.title-realign {
  margin-left: -3px
}

.top-container-realign {
  margin-top: 1px;
}



.soft-grey {
  color: #9BA1AB;
}

.chevron {
  color: #9BA1AB;
  height: 17px;
  width: 17px;
}

.account-hover {
  cursor: pointer;
}

.account-hover:hover {
  color: #7A7A7C;
  text-decoration: underline;
}


.width {
  width: 100%;
}

@media (max-width: 1280px) {

  .responsive-margin {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .responsive-none {
    display: none;
  }

  .responsive-margin {
    margin-top: 0;
  }

  .responsive {
    width: 100%;
  }
}
</style>
