<template>
  <div>
    <div
      v-show="isStudent"
      class="flex flex-row pv3 ph4"
    >
      <label class="inter black-40 f6 body-text ml3 tl i">
        Student plans require verification for eligibility. Further materials such as email, school ID, transcript, receipt of tuition paid, and/or class schedule may be supplied during account activation.
      </label>
    </div>
    <div
      class="flex flex-row pv3 items-start"
      :class="customClass"
    >
      <input
        id="agreement"
        type="checkbox"
        class="di pointer min"
        :checked="isChecked"
        @input="onCheckChange"
      >
      <label
        for="agreement"
        class="inter black-40 f6 body-text pointer ml3 tl"
      >
        I agree that I have thoroughly read, understand, and accept the
        <a
          class="black"
          href="https://getakko.com/legal"
          target="_blank"
        >Terms of Service & Conditions</a>
        as well as the
        <a
          class="black"
          href="https://getakko.com/coverage-info-faqs/"
          target="_blank"
        >AKKO Website's FAQs</a>
        about plan protections and claims. I also acknowledge and agree to receive and view all plan documentation, including Terms and Conditions and Plan Confirmation (if any), electronically as well as receive automated transactional messages via email and SMS in accordance with AKKO's Privacy Policy.
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TermsCheckbox',
    props: {
      isChecked: Boolean,
      isStudent: Boolean,
      onCheck: Function,
      customClass: String
    },
    methods: {
      onCheckChange (event) {
        this.onCheck(event.target.checked)
      }
    }
  }
</script>

<style scoped>
  .body-text {
    line-height: 1.8;
    margin-top: -5px;
  }
  .min {
    min-width: 15px;
  }
</style>
