<template>
  <main
    class="flex bg-white new-shadow-theme br3  custom-padding responsive-box"
    :class="isSomeComplete ? 'mr5' : 'custom-width' "
  >
    <div class="flex flex-column w-100 responsive-plan">
      <div class="cera-round-pro-medium f4 mb3 fw5 pl3-l">
        Choose Your Plan
      </div>
      <div class="flex flex-row flex-wrap w-100 justify-between cards mv2">
        <plan-select-toggle-c3
          :is-phone-only="isPhoneOnly"
          :set-akko-plan="setAkkoPlan"
          :set-phone-plan="setPhonePlan"
          :set-value="setValue"
        />
      </div>
    </div>
  </main>
</template>

<script>
import PlanSelectToggleC3 from '../PlanSelectToggle3c'

export default {
  name: 'AkSelectPlan3c',
  components: {
    PlanSelectToggleC3,
  },
  props: {
    isPhoneOnly: {
      type: Boolean,
      default: false
    },
    setValue: Function,
    completeData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isSomeComplete () {
      return this.completeData.includes(true)
    }
  },
  methods: {
    setAkkoPlan (event) {
      this.setValue(false, event)
    },
    setPhonePlan (event) {
      this.setValue(true, event)
    }
  }
}
</script>

<style scoped>
.custom-padding {
  padding: 2.5em 4% 2.5em 4%;
}

.plan-be {
  width: 195px;
}

.gradual-width {
  width: 55%;
}

.custom-width {
  max-width: 850px;
  width: max-content;
}

.vertical-bar {
  border: 0.7px solid #E8E8E8;
  background-color: #E8E8E8
}

@media (max-width: 1280px) {
  .responsive-box {
    width: 80%;
    margin: 1em 0;
  }
}

@media (max-width: 1000px) {
  .cards {
    justify-content: center;
  }

  .outside {
    align-self: center;
  }
}

@media (max-width: 600px) {
  .responsive-box {
    width: 100%;
    margin: 1em 0;
  }

  .custom-padding {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .responsive-plan {
    border: 1px solid var(--theme-medium-gray);
    padding: 1.5em 2.2em;
    border-radius: 0.5em;
    background-color: white;
  }
}
</style>
