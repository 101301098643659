<template>
  <div class="flex flex-row input">
    <div class="flex justify-center items-center inter f7 label w3">
      {{ label }}
    </div>
    <select
      id="make"
      :disabled="isDisabled"
      :value="getValue()"
      class="ba pl2 mb2 w-100 inter black-70 f7 select z-1 bg-transparent pointer"
      @change="onChange"
    >
      <option
        v-if="isDisabled && getValue()"
        value=""
      >
        {{ getValue() }}
      </option>
      <option
        v-else
        value=""
      >
        {{ placeholder }}
      </option>
      <option
        v-for="(option) in getOptions()"
        :key="option"
        :value="option"
      >
        {{ option }}
      </option>
    </select>
    <arrow-icon class="absolute z-0" />
  </div>
</template>

<script>
import ArrowIcon from '../icons/ArrowIcon.vue'

export default {
  name: 'AkDeviceInput',
  components: {
    ArrowIcon
  },
  props: {
    optionsSet: {
      type: Set,
      default: []
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    setValue: Function,
    disabled: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  computed: {
    isDisabled () {
      return this.disabled && this.disabled.brand !== undefined || false
    }
  },
  watch: {
    optionsSet: function () {
      if (this.value !== '') {
        this.setValue(this.index, this.value)
      }
      this.$forceUpdate()
    },
    value: function () {
      this.$forceUpdate()
    }
  },
  methods: {
    onChange (event) {
      this.setValue(this.index, event.target.value)
    },
    getValue () {
      if (this.value === '') {
        if (this.isDisabled && this.label === 'Model' && this.disabled.model) {
          // very nasty side effect, but required in order to provide desired user experience without big refactor
          this.setValue(this.index, this.disabled.model)
          return this.disabled.model
        }
      }
      return this.value
    },
    getOptions () {
      return this.optionsSet
    }
  }
}
</script>

<style scoped>
.label {
  border: 1px solid var(--theme-medium-gray);
  border-right-width: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: var(--theme-gray);
}

.select {
  border: 1px solid var(--theme-medium-gray);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 100%;
  padding-right: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.input {
  position: relative;
  height: 2.3em;
}

.absolute {
  position: absolute;
  right: 5%;
  top: 40%;
}
</style>
