<template>
  <main>
    <!-- Debo poner un fondo tipo boton en el status_text -->
    <h1 class="f2 lh-copy pt3">
      Claim #{{ currentClaim.id }} ({{ status_text }})
    </h1>

    <div class="mt4 mb3 pa4 bg-white shadow-card br2">
      <!--  full card -->
      <div class="grid-half mt4">
        <div>
          <label
            for="date_submitted"
            class="f3 b db mb4"
          >Date Submitted:</label>
          <span
            id="date_submitted"
            class="inter f4"
          >{{ date_submitted }}</span>
        </div>

        <div>
          <label
            for="date_of_loss"
            class="f3 b db mb4"
          >Date of Loss:</label>
          <span
            id="date_of_loss"
            class="inter f4"
          >{{ loss_date }}</span>
        </div>
      </div>

      <div class="grid-half mt5">
        <div v-if="!featureFlagMultiLossTypeActive">
          <label
            for="type_of_loss"
            class="f3 b db mb4"
          >Type of Loss:</label>
          <span
            id="type_of_loss"
            class="inter f4"
          >{{ currentClaim.loss_type }} </span>
        </div>

        <div>
          <label
            for="paypal_email"
            class="f3 b db mb4"
          >PayPal Email:</label>
          <span
            id="paypal_email"
            class="inter f4"
          >{{ currentClaim.paypal_email }} </span>
        </div>
      </div>

      <div class="grid mt5">
        <div>
          <label class="f3 b db mb4">Items Affected:</label>

          <div
            v-if="currentClaim.devices.length > 0"
            class="grid-third mt4 mb6 mb4-ns"
          >
            <div
              v-for="(device, index) in currentClaim.devices"
              :key="index"
              class=""
            >
              <router-link
                :to="`/devices/${device.id}`"
                class="no-underline black"
              >
                <ak-claim-device-tile
                  :id="device.id"
                  :brand="device.brand"
                  :model="device.model"
                  :nickname="device.nickname ? device.nickname : `Item ${index+1}`"
                  :type="device.type"
                  :serial="device.serial_number"
                  :loss-types="device.loss_types"
                  :known-damage="device.known_damage"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="grid mt5">
        <div>
          <label
            for="description_of_incident"
            class="f3 b db mb4"
          >Description of Incident:</label>
          <span
            id="description_of_incident"
            class="inter f4"
          >{{ currentClaim.description }} </span>
        </div>
      </div>

      <div
        v-if="!featureFlagMultiLossTypeActive"
        class="grid mt5"
      >
        <div>
          <label
            for="previous_known_damage"
            class="f3 b db mb4"
          >Previous Known Damage:</label>
          <span
            id="previous_known_damage"
            class="f4"
          >{{ currentClaim.known_damage }} </span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AkClaimDeviceTile from './ClaimDeviceTile'
import CLAIM_STATUS_TEXT from '@/constants/claims/claimStatusText'
import randomBytes from 'randombytes';
import { GET_FEATURE_FLAG } from '@/store/index'
import { MULTI_LOSS_TYPE } from '@/constants/feature_flag'

export default {
  name: 'AkClaimPage',
  components: {
    AkClaimDeviceTile,
  },
  data () {
    return {
      curr_img: -1,
      hiddenImages: []
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    claims () {
      return this.$store.state.claims.claims
    },
    currentClaim () {
      return this.$store.state.claims.currentClaim
    },
    devices () {
      return this.$store.state.devices
    },
    filed_days_ago () {
      const msInDay = 1000 * 60 * 60 * 24
      const now = new Date().getTime()
      const filedTime = new Date(this.currentClaim.filed_on).getTime()
      const diffference = now - filedTime
      const daysAgo = Math.round(diffference / msInDay)
      if (daysAgo < 1) {
        return 'Filed within the last day'
      }
      if (daysAgo === 1) {
        return 'Filed yesterday'
      }
      return `Filed ${daysAgo} days ago`
    },
    date_submitted () {
      return new Date(this.currentClaim.filed_on).toISOString().slice(0, 10)
    },
    loss_date () {
      const dateLoss = new Date(
        this.currentClaim.loss_year, this.currentClaim.loss_month - 1, this.currentClaim.loss_day
      )
      return dateLoss.toISOString().slice(0, 10)
    },
    status_text () {
      return CLAIM_STATUS_TEXT[this.currentClaim.status]
    },
    loading () {
      return this.$store.state.loading
    },
    getClaimImages () {
      return this.$store.state.claims.claimImages
    },
    featureFlagMultiLossTypeActive () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](MULTI_LOSS_TYPE)
      return feature ? feature.state : false
    }
  },
  mounted () {
    this.$store.dispatch('getCurrentClaim', {id: this.$route.params.id})
    if (this.$store.state.currentClaim.status === 'DRAFT') {
      this.goToClaimFilling()
    }
  },
  beforeDestroy () {
    this.$store.commit('SET_CURRENT_CLAIM', {
      id: 0,
      loss_type: '',
      loss_day: 0,
      loss_month: 0,
      loss_year: 0,
      description: '',
      known_damage: '',
      status: 1,
      filed_on: '',
      devices: [],
      images: []
    })
  },
  methods: {
    getPayload () {
      return {
        id: this.currentClaim.id,
        newImages: this.$store.state.claims.claimImages.filter((img) => !!img.id === false),
        // Images deleted (hidden) by the user
        hiddenImages: this.hiddenImages
      }
    },
    generate_name (submitedFileName) {
      let prefix = `CLAIM/${this.currentClaim.id}/`
      let extension = submitedFileName.substr(submitedFileName.lastIndexOf('.') + 1, submitedFileName.length)
      return prefix + randomBytes(20).toString('hex') + '.' + extension
    },
    deleteImage (id) {
      // take image, delete it and refresh currentClaim.images
      this.hiddenImages.push(id)
      // take image, delete it and refresh claimImages
      this.$store.commit('DELETE_IMAGE', id)
      this.$store.dispatch('patchClaim', this.getPayload())
    },
    goToClaimFilling () {
      this.$router.push('/file-claim2/' + this.currentClaim.id)
    }
  }
}
</script>

<style scoped>
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
.imageUploadForm {
  background: var(--theme-primary);
  width: 200px;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  padding-top: .75em;
  padding-bottom: .75em;
  color: white;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
}
.imageUploadForm .uploadButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}

.img-list-container{
  position: relative;
  margin-bottom: 30px;
}
.images-list-item-remove-btn {
  display: block;
  margin: 2px auto;
  width: 100px;
  text-align: center;
  cursor: pointer;
  padding: 3px;
  border-radius: 3px;
  background: var(--theme-primary);
  color: #ffffff;
}
</style>
