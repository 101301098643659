var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"flex db fw5 pv3 ph4 br-pill pointer ba transition-fast cera-round-pro",class:[
    _vm.block && 'w-100',
    _vm.primary && !_vm.disabled && 'bg-theme white b-theme',
    _vm.secondary && !_vm.disabled && 'bg-white color-theme b-theme bg-theme-active white-active',
    _vm.green && !_vm.disabled && 'bg-green white b-green',
    _vm.newSecondary && !_vm.newDisabled && 'bg-white inter default-button b-theme color-theme ba f7',
    _vm.newPrimary && !_vm.newDisabled && 'bg-theme inter default-button b-theme white color-white ba f7',
    _vm.shadow && 'shadow-style',
    _vm.bordered && !_vm.disabled && 'bg-theme white b--white',
    _vm.thin ? 'pv2' : 'pv3',
    _vm.newDisabled && 'inter bg-light-grey gray b--black-20 default-button disabled f7',
    _vm.disabled && 'bg-light-grey gray b--gray br3',
    _vm.customClass,
    _vm.upsell && 'upsell'
  ],attrs:{"disabled":_vm.disabled || _vm.newDisabled},on:{"click":_vm.emitClick}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }