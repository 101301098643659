<template>
  <div>
    <div
      v-for="(lineItem, index) in contractLineItems"
      :key="index"
      class="ph3 flex flex-column justify-center"
    >
      <div class="flex justify-between items-center responsive-text">
        <span class="mb1">{{ lineItem.product.friendly_name }}</span>
        <span v-if="!isBilledByPartner">${{ lineItemPrice(lineItem.product_price.current_price) }}</span>
      </div>
      <div
        v-if="!isBilledByPartner"
        class="flex justify-between"
      >
        <span class="description">
          {{ lineItemDescription(lineItem.product_price) }}
        </span>
        <slot name="changePlan" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContractLineItems',
  props: {
    contractLineItems: {
      type: Array,
      default: () => { return [] }
    },
    isBilledByPartner: {
      type: Boolean,
      default: false
    },
    categoryName: {
      type: String,
      default: 'COVERAGE'
    },
    discount: {
      type: Object,
      default: () => { return {} }
    },
    phone: {
      type: String,
      default: ''
    }
  },
  methods: {
    lineItemPrice (price) {
      let priceInDollars = (price / 100)
      if (Object.keys(this.discount).length > 0) {
        priceInDollars = priceInDollars * (100 - this.discount.amount) / 100
      }
      return priceInDollars.toFixed(2)
    },
    lineItemDescription (lineItemProductPrice) {
      let intervalFormatted = lineItemProductPrice.interval.charAt(0).toUpperCase() + lineItemProductPrice.interval.slice(1).toLowerCase()
      return `${intervalFormatted} • Phone: ${this.phone}`
    }
  }
}

</script>


<style scoped>
.description {
  color: #757D8A;
}

</style>
