<template>
  <main>
    <div class="height flex items-center justify-between">
      <span class="f2 lh-copy pt3">{{ currentDevice.nickname }}</span>
    </div>
    <div
      v-if="!userHasAccess"
      class="mb4"
    >
      <ak-alert
        v-if="user.membership_status === 'CANCELED'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        >please contact our billing
          team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'UNPAID'"
        color="red"
      >
        <span>Your coverage plan is currently unpaid. Please proceed to <router-link
          class="color-theme"
          to="/account/dashboard"
        >Account Settings</router-link> to resolve or </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'PAST_DUE'"
        color="red"
      >
        <span>Your coverage plan is currently past due. Please proceed to <router-link
          class="color-theme"
          to="/account/dashboard"
        >Account Settings</router-link> to resolve or </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'BANNED'"
        color="red"
      >
        <span>Your account has been banned. If you think this was done in error, </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Inquiry Regarding Banned Account"
        >please contact
          our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INACTIVE'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        >please contact our billing
          team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INVALID_USER'"
        color="red"
      >
        <span>Your account has been marked as invalid by our system due to unpaid premiums or other account issues. If you think this was done in error, </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        >please contact our billing
          team.</a>
      </ak-alert>
    </div>

    <div class="f4 lh-copy fw4 mt2 mb3 ttc inter">
      {{ currentDevice.brand }} - {{ currentDevice.model.trim() === '' ? currentDevice.type : currentDevice.model }}
    </div>

    <div
      v-if="currentDevice.serial_number"
      class="silver f6 fw4 mb3 inter"
    >
      ({{ currentDevice.serial_number }})
    </div>

    <div
      v-if="!hidePhonePictureNotice && !photosSubmitted && isPhone && !isSchoolIpadPlan"
      class="mv2 inter lh-copy"
    >
      <div
        class="mv2 br1 flex justify-between f6"
      >
        <div class="fw2 mid-gray border-box">
          <h3 class="color-theme">
            INSTRUCTIONS
          </h3>
          <br>
          <div class="flex">
            <ak-icon-with-text
              justify="justify-start"
              :svg="require('@/assets/appicons/ExclamationMark.svg')"
              text="<span>You <span class='fw6 inter-semi-bold' style='text-decoration: underline;'>MUST</span> upload photos of your item for coverage on it to be active. <span class='inter-semi-bold'>You may not upload any screenshots</span>. <br></br> To upload photos of your phone or tablet, you MUST take photos using the device's own front and rear cameras in front of a mirror (you do not need to be visible in the photos). Make sure any case is removed. If you have a screen protector on which is damaged or scratched at all, also remove it. If your device appears damaged in any way, it cannot be protected.</span>"
            />
          </div>

          <br>
          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="To take a photo of the front of your device, hold it up to the mirror and use the front-facing &quot;selfie&quot; camera."
          />

          <br>
          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="To take a photo of the back of your device, hold it up to the mirror and use the rear-facing camera."
          />

          <br>
          <br>


          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="Also include one photo of your phone's screen while it is displaying the 'about' page in the device's settings which shows the model and serial # of your phone displayed on the screen.
                  Take this photo using another phone, tablet, camera, or the camera on your laptop then upload the photo here as well. Your phone's about page cannot be displayed in 'dark mode.'
                  The about page must have a white/bright background, not black. You can <a class='color-theme' href='https://intercom.help/akko-inc/en/articles/5128104-how-to-take-photos-of-your-phone-and-add-them-to-your-akko-account' target='_blank' rel='noopener noreferrer'>view example photos here</a> and instructions on taking these photos."
          />
        </div>
      </div>
    </div>

    <div
      v-if="!hideLaptopPictureNotice && !isSchoolIpadPlan"
      class="mv2 inter lh-copy"
    >
      <div class="mv2 br1 flex justify-between f6">
        <div class="fw2 mid-gray border-box">
          <h3 class="color-theme">
            INSTRUCTIONS
          </h3>
          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/ExclamationMark.svg')"
            text="You <span class='fw6 inter-semi-bold' style='text-decoration: underline;'>MUST</span> upload photos of your device for coverage on it to be active. <span class='inter-semi-bold'> You may not upload any screenshots</span>. To upload photos of your laptop, open your laptop with the screen powered 'ON' and displaying the laptop's system info."
          />

          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Settings.svg') "
            svg-style="min-width: 17px"
            text="On a Mac: Click the &quot;Apple&quot; logo in top left corner and select &quot;About This Mac.&quot;"
          />

          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Settings.svg')"
            svg-style="min-width: 17px"
            text="On a PC: Go to &quot;Settings&quot; > click &quot;System&quot; > then click &quot;About&quot;"
          />

          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="Then take a clear photo showing the entire screen and keyboard of your laptop while the screen is powered on with the system info containing the device's serial number displayed. Then take a photo of the back/bottom of your laptop."
          />
        </div>
      </div>
    </div>

    <div
      v-if="!hideTVPictureNotice"
      class="mv2 inter lh-copy"
    >
      <div class="mv2 br1 flex justify-between f6">
        <div class="fw2 mid-gray border-box">
          <h3 class="color-theme">
            INSTRUCTIONS
          </h3>
          <br>
          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/ExclamationMark.svg')"
            text="You <span class='fw6 inter-semi-bold' style='text-decoration: underline;'>MUST</span> upload photos of your item for coverage on it to be active.<span class='inter-semi-bold'>You may not upload any screenshots</span>. <br></br> Be sure the screen is powered on with some type of picture displayed on the screen so it can be seen to be in working and undamaged condition with no defects."
          />

          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="Also include a photo of the back of the device, and a photo of the serial number displayed on the screen."
          />

          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="If you have an older device that does not have this, you can show the serial number displayed on the manufacturer sticker/printed area."
          />
        </div>
      </div>
    </div>

    <div
      v-if="!hideTVNotice"
      class="mv2 inter lh-copy"
    >
      <div class="mv2 br1 flex justify-between f6">
        <div class="fw2 mid-gray border-box">
          <h3 class="color-theme">
            INSTRUCTIONS
          </h3>
          <br>
          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/ExclamationMark.svg')"
            text="You <span class='fw6 inter-semi-bold' style='text-decoration: underline;'>MUST</span> upload photos of your item for coverage on it to be active.<span class='inter-semi-bold'>You may not upload any screenshots</span>. <br></br> Please upload a photo with the screen powered on with some type of picture displayed so it can be seen to be in working and undamaged condition with no defects."
          />

          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="Please include a photo of the back of the TV (in its entirety), and a photo of the serial number displayed on the TV, making sure the entire TV is displayed in the photo. If you have an older device that does not have this, you can show the serial number displayed on the manufacturer sticker/printed areas."
          />
        </div>
      </div>
    </div>

    <div
      v-if="!hideConsolePictureNotice"
      class="mv2 inter lh-copy"
    >
      <div class="mv2 br1 flex justify-between f6">
        <div class="fw2 mid-gray border-box">
          <h3 class="color-theme">
            INSTRUCTIONS
          </h3>
          <br>
          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/ExclamationMark.svg')"
            text="You <span class='fw6 inter-semi-bold' style='text-decoration: underline;'>MUST</span> upload photos of your item for coverage on it to be active.<span class='inter-semi-bold'>You may not upload any screenshots</span>."
          />

          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="Be sure to include photos of the front and back of the physical device itself, as well as a photo of the settings screen of the gaming console  displaying the serial number on a TV or monitor so it can be seen to be in working and undamaged condition with no defects. "
          />
        </div>
      </div>
    </div>

    <div
      v-if="!hideOtherElectronicOrEntertainment"
      class="mv2 inter lh-copy"
    >
      <div class="mv2 br1 flex justify-between f6">
        <div class="fw2 mid-gray border-box">
          <h3 class="">
            NOTICE
          </h3>
          <br>
          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/ExclamationMark.svg')"
            text="You <span class='fw6 inter-semi-bold' style='text-decoration: underline;'>MUST</span> upload photos of your item for coverage on it to be active. <span class='inter-semi-bold'>You may not upload any screenshots</span>."
          />

          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="Please include a photo of the front and back of the device, and a photo of the serial number displayed on the screen (not a screenshot) so it can be seen to be in working and undamaged condition with no defects."
          />

          <br>

          <ak-icon-with-text
            v-show="currentDevice.type !== 'gaming handheld' && currentDevice.type !== 'streaming device'"
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="If you have an older device that does not have this, you can show the serial number displayed on the manufacturer sticker/printed area."
          />
        </div>
      </div>
    </div>

    <div
      v-if="!hideAccesoriesPictureNotice"
      class="mv2 inter lh-copy"
    >
      <div class="mv2 br1 flex justify-between f6">
        <div class="fw2 mid-gray border-box">
          <h3 class="">
            NOTICE
          </h3>
          <br>
          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/ExclamationMark.svg')"
            text="You <span class='fw6 inter-semi-bold' style='text-decoration: underline;'>MUST</span> upload photos of your item for coverage on it to be active. <span class='inter-semi-bold'>You may not upload any screenshots</span>.<br>Please keep track of your purchase receipt for this item. "
          />

          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="If you do not have a copy of your purchase receipt, try to find the best alternative you can such as a picture/screenshot of the transaction on a credit card/bank statement, or in a PayPal/Venmo account. "
          />

          <br>

          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/Picture.svg')"
            text="If you ever purchase items through person-to-person marketplaces such as Craigslist, request the seller send you a brief email as a receipt which includes the name of the item purchased, the amount of the purchase, and the date of the purchase.<br></br>Receipts are not required for coverage, but preferred as they help process claims quickest. "
          />
        </div>
      </div>
    </div>

    <div
      v-if="currentDeviceImages && currentDeviceImages.length > 0 && hasReceipt"
      class="mt2"
    >
      <ak-button @click.native="viewImage(deviceReceiptIndex)">
        <svg
          class="feather feather-file-text"
          fill="none"
          height="19"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
          <polyline points="14 2 14 8 20 8" />
          <line
            x1="16"
            x2="8"
            y1="13"
            y2="13"
          />
          <line
            x1="16"
            x2="8"
            y1="17"
            y2="17"
          />
          <polyline points="10 9 9 9 8 9" />
        </svg>
        <span class="ml1 v-top">View Receipt</span>
      </ak-button>
    </div>

    <div
      v-if="currentDeviceImages && currentDeviceImages.length > 0"
      class="flex flex-wrap items-stretch mv4"
    >
      <div
        v-for="(img, index) in currentDeviceImagesNotReceipt"
        :key="index"
        class="h5 mr3-ns flex-grow flex-basis-4 pointer mb5 mt2"
      >
        <div
          v-if="img.public_url === ''"
          class="h-100 w-100 flex items-center"
          @click="viewImage(index)"
        >
          Image processing, please check back later.
        </div>
        <img
          v-else
          :alt="`${img.side}`"
          :src="img.public_url"
          class="img-square dim h-100 w-100"
          @click="viewImage(index)"
        >
        <span>{{ img.side }}</span>
      </div>

      <div
        v-if="deviceHasScreen() && userXPIsWeb && !required_images_uploaded.includes('front')"
        class="flex-grow flex-basis-4 pa5 max-h5 dim pointer mr3-ns mb3 ba b--dashed  b-theme tc bg-white"
        @click="showImageModal=true;image_side='';image_type='type__front'"
      >
        <upload-photo-icon title="Take Front photo (required)" />
      </div>

      <div
        v-if="deviceHasScreen() && userXPIsWeb && !required_images_uploaded.includes('back')"
        class="flex-grow flex-basis-4 pa5 max-h5 dim pointer mr3-ns mb3 ba b--dashed  b-theme tc bg-white"
        @click="showImageModal=true;image_side='';image_type='type__back'"
      >
        <upload-photo-icon title="Take Back photo (required)" />
      </div>

      <div
        v-if="!photosSubmitted && isPhone && deviceHasScreen() && userXPIsWeb && !required_images_uploaded.includes('about')"
        class="flex-grow flex-basis-4 pa5 max-h5 dim pointer mr3-ns mb3 ba b--dashed  b-theme tc bg-white"
        @click="showImageModal=true;image_side='';image_type='type__about'"
      >
        <upload-photo-icon title="Take About photo (required)" />
      </div>

      <div
        v-if="!deviceHasScreen() && userXPIsWeb"
        class="flex-grow flex-basis-4 pa5 max-h5 dim pointer mr3-ns mb3 ba b--dashed  b-theme tc bg-white"
        @click="showImageModal=true;image_side=''"
      >
        <upload-photo-icon title="Take photos of item" />
      </div>

      <div
        v-if="!hasReceipt && !isSchoolLaptopPlan && userXPIsWeb && !isSchoolIpadPlan"
        class="flex-grow flex-basis-4 pa5 max-h5 dim pointer mr3-ns mb3 ba b--dashed  b-theme tc bg-white"
        @click="showReceiptModal=true"
      >
        <svg
          class="feather feather-file-text"
          fill="none"
          height="100"
          stroke="#F91942"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.5"
          viewBox="0 0 24 24"
          width="100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
          <polyline points="14 2 14 8 20 8" />
          <line
            x1="16"
            x2="8"
            y1="13"
            y2="13"
          />
          <line
            x1="16"
            x2="8"
            y1="17"
            y2="17"
          />
          <polyline points="10 9 9 9 8 9" />
        </svg>
        <div class="color-theme fw4 f4">
          Add Receipt Photo
        </div>
      </div>
    </div>

    <div
      v-else
      class="mt4"
    >
      <div
        v-if="deviceHasScreen() && userXPIsWeb && !isSchoolIpadPlan"
        class="flex flex-wrap"
      >
        <div
          class="flex-grow flex-basis-4 pa5 max-h5 dim pointer  mb3 ba b--dashed  b-theme tc bg-white"
          @click="showImageModal=true;image_side='';image_type='type__front'"
        >
          <upload-photo-icon title="Add Front photo (required)" />
        </div>

        <div
          class="flex-grow flex-basis-4 pa5 max-h5 dim pointer mh3-ns mb3 ba b--dashed  b-theme tc bg-white"
          @click="showImageModal=true;image_side='';image_type='type__back'"
        >
          <upload-photo-icon title="Add Back photo (required)" />
        </div>

        <div
          class="flex-grow flex-basis-4 pa5 max-h5 dim pointer  mb3 ba b--dashed  b-theme tc bg-white"
          @click="showImageModal=true;image_side='';image_type='type__about'"
        >
          <upload-photo-icon title="Add About photo (required)" />
        </div>
      </div>
      <div
        v-else-if="userXPIsWeb && !isSchoolIpadPlan"
        class="flex"
      >
        <div
          class="flex-grow flex-basis-4 pa5 max-h5 dim pointer  mb3 ba b--dashed  b-theme tc bg-white"
          @click="showImageModal=true;image_side=''"
        >
          <upload-photo-icon title="Add photos of item (required)" />
        </div>
      </div>

      <div
        v-if="!hasReceipt && !isSchoolLaptopPlan && userXPIsWeb && !isSchoolIpadPlan"
        class="flex-grow flex-basis-4 pa5 max-h5 dim pointer mb3 ba b--dashed b-theme tc bg-white"
        @click="showReceiptModal=true"
      >
        <svg
          class="feather feather-file-text"
          fill="none"
          height="100"
          stroke="#F91942"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.5"
          viewBox="0 0 24 24"
          width="100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
          <polyline points="14 2 14 8 20 8" />
          <line
            x1="16"
            x2="8"
            y1="13"
            y2="13"
          />
          <line
            x1="16"
            x2="8"
            y1="17"
            y2="17"
          />
          <polyline points="10 9 9 9 8 9" />
        </svg>
        <div class="color-theme fw4 f3">
          Add Receipt Photo
        </div>
      </div>
    </div>

    <div
      v-if="!isSchoolLaptopPlan && !isSchoolIpadPlan"
      class="mv2 inter lh-copy"
    >
      <div
        v-if="currentDevice.price > 100 && currentDeviceReceipt(currentDeviceImages) === false"
        class="mv2 br1 flex justify-between f6"
      >
        <div class="fw2 mid-gray">
          <br>
          <br>
          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/ExclamationMark.svg')"
            text="Please keep track of your purchase receipt for this item. If you do not have a copy of your purchase receipt, try to find the best alternative you can such as a picture/screenshot of the transaction on a credit card/bank statement, or in a PayPal/Venmo account. If you ever purchase items through person-to-person marketplaces such as Craigslist, request the seller send you a brief email as a receipt which includes the name of the item purchased, the amount of the purchase, and the date of the purchase. This can serve as a receipt as well. Receipts are not required for coverage, but preferred as they help process claims quickest."
          />

          <br>
          <br>
          <ak-icon-with-text
            justify="justify-start"
            :svg="require('@/assets/appicons/UploadFile.svg')"
            text="You can upload any picture or PDF of your receipt here along with your item's images or email a copy of your receipts to <a class='color-theme' href='mailto:receipts@getakko.com' target='_blank' rel='noopener noreferrer'>Receipts@getAKKO.com</a> for us to keep track of for you."
          />
        </div>
      </div>
    </div>

    <div
      v-if="userXPIsWeb"
      class="mt4 mb4 mr2 dib-ns"
    >
      <ak-button
        :disabled="currentDevice.has_active_claims"
        block
        class="remove-button inter br3-ns"
        primary
        @click.native="showDeleteModal=true"
      >
        Remove item from plan
      </ak-button>
    </div>
    <div
      v-if="userHasAccess && userXPIsWeb"
      class="mt4 mb4 dib-ns "
    >
      <ak-button
        block
        primary
        class="inter br3-ns"
        @click.native="addDevice"
      >
        Add another item
      </ak-button>
    </div>

    <ak-modal
      v-if="expandImage !== -1"
      :title="expandImageTitle"
      @close="expandImage=-1"
    >
      <div
        v-if="currentDeviceImages[expandImage].processing_status.status === false"
        slot="modal-body"
        class="w-100 tc flex items-center"
      >
        Image processing, please check back later.
      </div>
      <div
        v-else-if="!(currentDeviceImages[expandImage].type === 'file')"
        slot="modal-body"
        class="w-100 tc"
      >
        <img
          :src="currentDeviceImages[expandImage].public_url"
          alt=""
        >
      </div>
      <div
        v-else
        slot="modal-body"
        class="w-100 tc"
      >
        <a
          :href="currentDeviceImages[expandImage].public_url"
          target="_blank"
        >Click here to download your receipt</a>
      </div>
      <div
        slot="modal-footer"
        class="pa3 flex-ns flex-wrap justify-end"
      >
        <div
          v-if="userXPIsWeb"
          class="order-1 mb3 mb0-ns"
        >
          <ak-button
            block
            secondary
            @click.native="deleteFile(currentDeviceImages[expandImage])"
          >
            Delete
          </ak-button>
        </div>
        <div class="mr3-ns">
          <ak-button
            block
            secondary
            @click.native="expandImage=-1"
          >
            Close
          </ak-button>
        </div>
      </div>
    </ak-modal>

    <ak-modal
      v-if="showImageModal"
      title="Upload a Photo"
      @close="resetImage"
    >
      <div
        slot="modal-body"
        class=""
      >
        <div
          :class="image_file ? 'bg-washed-green mb3' : 'bg-washed-red'"
          class="pv5 w-100 tc"
        >
          <input
            id="file"
            accept="image/*,.heic,.heif,.HEIC,.HEIF"
            class="input-reset dn"
            type="file"
            @change="processImage($event, image_type)"
          >
          <label
            :class="image_file ? 'bg-green b-green' : 'bg-theme b-theme'"
            class="dib fw5 pv2 ph5 br-pill pointer ba bw1  white "
            for="file"
          >
            <svg
              class="v-sub mr1"
              fill="white"
              height="18"
              viewBox="0 0 20 18"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
              />
            </svg>
            <span class="white">{{ image_file ? 'File Selected' : 'Choose a File' }}</span>
          </label>
        </div>
        <span class="red fs15">{{ upload_error }}</span>
        <div v-if="image_file">
          <div
            v-if="!image_type && !deviceHasScreen()"
            class="db fw6 f6 mt1 mb2"
          >
            Choose photo type:
          </div>
          <div
            v-if="!image_type && !deviceHasScreen()"
            class="flex flex-wrap"
          >
            <input
              :id="'photo-type__front'"
              v-model="image_side"
              :value="'front'"
              class="dn"
              type="radio"
            >
            <label
              :for="'photo-type__front'"
              class="db pv2 mr2 mb2 ph3 f6 tc b ba br-pill b-theme bg-white color-theme pointer"
            >Front</label>
            <input
              :id="'photo-type__back'"
              v-model="image_side"
              :value="'back'"
              class="dn"
              type="radio"
            >
            <label
              :for="'photo-type__back'"
              class="db pv2 mr2 mb2 ph3 f6 tc b ba br-pill b-theme bg-white color-theme pointer"
            >Back</label>
            <input
              :id="'photo-type__left'"
              v-model="image_side"
              :value="'left'"
              class="dn"
              type="radio"
            >
            <label
              :for="'photo-type__left'"
              class="db pv2 mr2 mb2 ph3 f6 tc b ba br-pill b-theme bg-white color-theme pointer"
            >Left</label>
            <input
              :id="'photo-type__right'"
              v-model="image_side"
              :value="'right'"
              class="dn"
              type="radio"
            >
            <label
              :for="'photo-type__right'"
              class="db pv2 mr2 mb2 ph3 f6 tc b ba br-pill b-theme bg-white color-theme pointer"
            >Right</label>
            <input
              :id="'photo-type__about'"
              v-model="image_side"
              :value="'about'"
              class="dn"
              type="radio"
            >
            <label
              :for="'photo-type__about'"
              class="db pv2 mr2 mb2 ph3 f6 tc b ba br-pill b-theme bg-white color-theme pointer"
            >About Device
              Page</label>
          </div>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="pa3 flex-ns flex-wrap justify-end"
      >
        <div class="order-1 mb3 mb0-ns">
          <ak-button
            v-if="userXPIsWeb"
            :disabled="!image_file || !image_side.length"
            block
            primary
            @click.native="submitImage"
          >
            Upload Image
          </ak-button>
        </div>
        <div class="mr3-ns">
          <ak-button
            block
            secondary
            @click.native="resetImage"
          >
            Close
          </ak-button>
        </div>
      </div>
    </ak-modal>

    <ak-modal
      v-if="showReceiptModal"
      title="Upload Receipt"
      @close="resetImage"
    >
      <div
        slot="modal-body"
        class=""
      >
        <span class="red">{{ upload_error }}</span>
        <div
          :class="receipt_file_name ? 'bg-washed-green' : 'bg-washed-red'"
          class="pv5 w-100 tc"
        >
          <input
            id="file"
            accept="image/*,application/pdf,.heic,.heif,.HEIC,.HEIF"
            class="input-reset dn"
            type="file"
            @change="processReceipt($event)"
          >
          <label
            :class="receipt_file_name ? 'bg-green b-green' : 'bg-theme b-theme'"
            class="dib fw5 pv2 ph5 br-pill pointer ba bw1  white "
            for="file"
          >
            <svg
              class="v-sub mr1"
              fill="white"
              height="18"
              viewBox="0 0 20 18"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
              />
            </svg>
            <span class="white">{{ receipt_file_name ? 'File Selected' : 'Choose a File' }}</span>
          </label>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="pa3 flex-ns flex-wrap justify-end"
      >
        <div
          v-if="userXPIsWeb"
          class="order-1 mb3 mb0-ns"
        >
          <ak-button
            :disabled="!receipt_file"
            block
            primary
            @click.native="submitReceipt"
          >
            Upload Receipt
          </ak-button>
        </div>
        <div class="mr3-ns">
          <ak-button
            block
            secondary
            @click.native="resetImage"
          >
            Close
          </ak-button>
        </div>
      </div>
    </ak-modal>

    <ak-modal
      v-if="showDeleteModal"
      title="Remove Item"
      @close="showDeleteModal=false"
    >
      <div
        slot="modal-body"
        class="flex-ns flex-wrap"
      >
        Are you sure you want to remove this item from your plan?
      </div>
      <div
        slot="modal-footer"
        class="pa3 flex-ns flex-wrap justify-end"
      >
        <div
          v-if="userXPIsWeb"
          class="order-1 mb3 mb0-ns"
        >
          <ak-button
            block
            primary
            @click.native="deleteDevice"
          >
            Yes, remove this item
          </ak-button>
        </div>
        <div>
          <ak-button
            block
            secondary
            @click.native="showDeleteModal=false"
          >
            Close
          </ak-button>
        </div>
      </div>
    </ak-modal>
  </main>
</template>

<script>
import AkButton from './Button'
import AkModal from './Modal'
import AkAlert from './Alert'
import UploadPhotoIcon from './UploadPhotoIcon'
import AkIconWithText from './IconWithText'

export default {
  name: 'AkDevicePage',
  components: {
    AkButton,
    AkModal,
    AkAlert,
    UploadPhotoIcon,
    AkIconWithText
  },
  data () {
    return {
      showDeleteModal: false,
      showImageModal: false,
      showReceiptModal: false,
      required_images: ['front', 'back', 'about'],
      front_uploaded: false,
      image_file: null,
      image_side: '',
      image_type: '',
      image_file_name: '',
      receipt_file: null,
      receipt_file_name: '',
      expandImage: -1,
      expandImageTitle: '',
      upload_error: ''
    }
  },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },
    user () {
      return this.$store.state.user
    },
    devices () {
      return this.$store.state.devices
    },
    currentDevice () {
      return this.$store.state.currentDevice
    },
    currentDeviceImages () {
      return this.currentDevice.files === undefined ? [] : this.currentDevice.files
    },
    currentDeviceImagesNotReceipt () {
      return this.currentDeviceImages.filter(img => img.side !== 'receipt')
    },
    hasImagesProcessing () {
      return this.currentDeviceImages.some((image) => {
        if (image.processing_status) {
          return !image.processing_status.status
        }
        return false
      })
    },
    hasReceipt () {
      return this.deviceReceiptIndex > -1
    },
    deviceReceiptIndex () {
      if (!this.currentDeviceImages) {
        return -1
      }
      return this.currentDeviceImages.findIndex(img => img.side === 'receipt')
    },
    hideAccesoriesPictureNotice () {
      return this.currentDevice.category !== 'accessories' && this.currentDevice.category !== 'audio' && this.currentDevice.category !== 'photography' && this.currentDevice.category !== 'transport' && this.currentDevice.category !== 'small appliances' && this.currentDevice.category !== 'sports'
    },
    hideOtherElectronicOrEntertainment () {
      return (this.currentDevice.type !== 'other' && this.currentDevice.type !== 'gaming handheld' && this.currentDevice.type !== 'streaming device' && !(this.currentDevice.category in ['electronics', 'entertainment']))
    },
    hidePhonePictureNotice () {
      return (this.currentDevice.type !== 'phone' && this.currentDevice.type !== 'tablet') ||
        (this.currentDeviceImages && this.currentDeviceImages.filter(img => img.side === 'front').length > 0 &&
          this.currentDeviceImages.filter(img => img.side === 'back').length > 0 && this.currentDeviceImages.filter(img => img.side === 'about').length > 0)
    },
    hideLaptopPictureNotice () {
      return this.currentDevice.type !== 'laptop' ||
        (this.currentDeviceImages && this.currentDeviceImages.filter(img => img.side === 'front').length > 0 &&
          this.currentDeviceImages.filter(img => img.side === 'back').length > 0 && this.currentDeviceImages.filter(img => img.side === 'about').length > 0)
    },
    hideTVPictureNotice () {
      return (this.currentDevice.type !== 'smartwatch' && this.currentDevice.type !== 'desktop') ||
        (this.currentDeviceImages && this.currentDeviceImages.filter(img => img.side === 'front').length > 0 &&
          this.currentDeviceImages.filter(img => img.side === 'back').length > 0 && this.currentDeviceImages.filter(img => img.side === 'about').length > 0)
    },
    hideTVNotice () {
      return (this.currentDevice.type !== 'tv') || (this.currentDeviceImages && this.currentDeviceImages.filter(img => img.side === 'front').length > 0 &&
        this.currentDeviceImages.filter(img => img.side === 'back').length > 0 && this.currentDeviceImages.filter(img => img.side === 'about').length > 0)
    },
    hideConsolePictureNotice () {
      return (this.currentDevice.type !== 'gaming console') || (this.currentDeviceImages && this.currentDeviceImages.filter(img => img.side === 'front').length > 0 &&
        this.currentDeviceImages.filter(img => img.side === 'back').length > 0 && this.currentDeviceImages.filter(img => img.side === 'about').length > 0)
    },
    userHasAccess () {
      const status = this.user.membership_status
      return status !== 'BANNED' && status !== 'CANCELED' && status !== 'UNPAID' && status !== 'PAST_DUE' && status !== 'INACTIVE' && status !== 'INVALID_USER'
    },
    userXPIsWeb () {
      return this.userHasAccess && (this.user.user_experience_path === 'web' || this.user.user_experience_path === 'all')
    },
    isSchoolLaptopPlan () {
      // Hardcoding specific need for ZH Partner related users
      if (this.$store.state.user.partner_property && this.$store.state.user.partner_property.partner_id === 1829) {
        return true
      }

      return this.$store.state.user.coverage_item_covered === 'SCHOOL_LAPTOP' ||
        (this.$store.state.user.partner_property && this.$store.state.user.partner_property.property_type &&
          this.$store.state.user.partner_property.property_type.includes('K12'))
    },
    isSchoolIpadPlan () {
      // Hardcoding specific need for ZH Partner related users
      if (this.$store.state.user.partner_property && this.$store.state.user.partner_property.partner_id === 1829) {
        return true
      }

      return this.$store.state.user.coverage_item_covered === 'SCHOOL_DEVICE'
    },
    isPhone () {
      return this.currentDevice.type === 'phone'
    },
    photosSubmitted () {
      return this.currentDevice.photos_submitted
    },
    required_images_uploaded () {
      return this.currentDeviceImages.map(img => img.side)
    }
  },
  watch: {
    currentDeviceImages () {
      if (this.hasImagesProcessing) {
        this.$store.dispatch('retryGetCurrentDevice', {id: this.$route.params.id})
      }
    }
  },
  mounted () {
    this.checkDevice()
  },
  beforeDestroy () {
    this.$store.dispatch('resetCurrentDevice')
  },
  methods: {
    deleteDevice () {
      this.showDeleteModal = false
      this.$store.dispatch('deleteDevice', {id: this.currentDevice.id})
    },
    deviceHasScreen () {
      const devicesWithScreen = ['laptop', 'phone', 'tv']
      return devicesWithScreen.includes(this.currentDevice.type)
    },
    required_image_uploaded (image) {
      return this.required_images_uploaded.includes(image)
    },
    processImage (event, type) {
      this.image_file = event.target.files[0]
      this.image_file_name = this.image_file.name
      if (type.length > 0) {
        switch (type) {
          case 'type__front':
            this.image_side = 'front'
            break
          case 'type__back':
            this.image_side = 'back'
            break
          case 'type__about':
            this.image_side = 'about'
            break
          default:
            this.image_side = ''
            break
        }
      }
    },
    processReceipt (event) {
      this.receipt_file = event.target.files[0]
      this.receipt_file_name = this.receipt_file.name
    },
    resetImage () {
      this.showImageModal = false
      this.showReceiptModal = false
      this.image_file = null
      this.image_file_name = ''
      this.receipt_file = null
      this.receipt_file_name = ''
      this.image_type = ''
    },
    currentDeviceReceipt (images) {
      if (images.length > 0) {
        for (var i = 0; i < images.length; i++) {
          if (images[i].side === 'receipt') {
            return images[i]
          }
        }
      }
      return false
    },
    viewImage (index) {
      this.expandImage = index
      const title = this.currentDeviceImages[index].side
      this.expandImageTitle = `${title.charAt(0).toUpperCase() + title.slice(1)} of ${this.currentDevice.model}`
    },
    submitImage () {
      this.upload_error = ''
      if (this.image_side === '') {
        this.upload_error = 'Please select type'
        return
      }
      this.showImageModal = false
      this.showReceiptModal = false
      if (this.userXPIsWeb) {
        this.$store.dispatch('submitDeviceMedia', {
          file: this.image_file,
          side: this.image_side,
          entity_id: this.currentDevice.id,
          type: 'ITEM'
        })
        this.resetImage()
      }
    },
    submitReceipt () {
      this.showImageModal = false
      this.showReceiptModal = false
      this.$store.dispatch('submitDeviceMedia', {
        file: this.receipt_file,
        side: 'receipt',
        entity_id: this.currentDevice.id,
        type: 'ITEM'
      })
      this.resetImage()
    },
    deleteFile (file) {
      this.expandImage = -1
      let payload = {
        device_id: this.currentDevice.id,
        file_id: file.id,
        file_type: file.type
      }
      this.$store.dispatch('deleteDeviceFile', payload)
    },
    addDevice () {
      this.$router.push('/add-device-category')
    },
    checkDevice () {
      this.$store.dispatch('getCurrentDevice', {id: this.$route.params.id})
    }
  }
}
</script>

<style scoped>
.v-sub {
  vertical-align: sub;
}

.flex-basis-4 {
  flex-basis: 16em;
  border-radius: 5px;
}

.height {
  height: 50px;
}


.pv2 {
  padding-top: .75em;
  padding-bottom: .75em;
}

.max-h5 {
  max-height: 16em;
}

input[type=radio]:checked + label {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}

input[type=checkbox]:checked:not(.normal) + label {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}

.fs15 {
  font-size: 15px;
}

.border-box {
  border-style: solid;
  border-radius: 5px;
  border: 1px solid #E8E8E8;
  padding: 20px;
  border-width: 1px;
}

.remove-button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

@media (max-width: 768px) {

.height {
  height: auto;
}

}

</style>
