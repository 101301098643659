<template>
  <div class="main">
    <img
      :src="require(`@/assets/appicons/${image}`)"
      alt=""
      class="mb40"
    >
    <div class="f16 mb3">
      {{ name ? name : 'Your privacy will always be our priority' }}
    </div>
    <div class="silver tl f12 mb20">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor eu tristique porttitor ultricies ullamcorper ipsum natoque. Consectetur nulla ut quam non convallis sit suscipit. Neque proin bibendum lectus nam. Senectus ut quis dapibus ultricies ullamcorper ipsum
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightComponent',
  props: {
    name: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  },
  methods: {}
}
</script>

<style scoped>
.main {
    text-align: center;
    padding: 49px;
    box-sizing: border-box;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
}
.mb20 {
  margin-bottom: 21px;
}
.mb40 {
  margin-bottom: 40px;
}
@media (max-width: 960px) {
.f16 {
  white-space: inherit;
  text-align: left;
}

.main {
  padding: 25px
}


}
</style>
