<template>
  <div class="w-100">
    <div
      :class="[
        disabled ? 'gray' : '',
        featured ? 'b-theme b-solid bw1' : '',
      ]"
      class="flex flex-column tc bg-white shadow-theme br-all w-100"
    >
      <div
        v-if="showSavingsBanner"
        class="f4 bg-theme white tc pv3 savings-banner"
      >
        <strong>SAVE ${{ savings.toLocaleString() }}!</strong>
        <p
          v-if="showExtraSavings"
          class="extra-savings"
        >
          + Extra ${{ extraSavings.toFixed(2) }} OFF - Multi-Plan
          savings!
        </p>
      </div>

      <div class="pa4">
        <div
          v-if="annual"
          class="mb4 fw3"
        >
          <div class="b">
            <span class="f5 v-top">$</span>
            <span class="price">{{
              phoneModels.length > 1 ? (price / 12).toFixed(2) : (price / 12).toFixed(2)
            }}</span>
            <span class="f5"> / month </span>
          </div>
          <div class="mt2 silver">
            <span
              class="f5"
              v-text="'Billed:'"
            />
            <span class="f6 v-top">$</span>
            <span class="f5">{{ totalAnnualValue }}</span>
            <span class="f6"> / year </span>
          </div>
        </div>

        <div
          v-if="!annual"
          class="mb4 fw3"
        >
          <div class="b">
            <span class="f5 v-top">$</span>
            <span class="price">{{ totalAnnualValue }}</span>
            <span class="f5"> / month </span>
          </div>
          <div class="mt2 silver">
            <span class="f5">Total: </span>
            <span class="f6 v-top">$</span>
            <span class="f5">{{
              phoneModels.length > 1 ? (price * 12.0).toFixed(2) : (price * 12).toFixed(2)
            }}</span>
            <span class="f6"> / year </span>
          </div>
        </div>

        <div class="mt3 mb3">
          <svg
            v-if="current"
            class="color-green dib v-sub"
            fill="none"
            height="24px"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 22s8-4 8-10V4l-8-2-8 2v8c0 6 8 10 8 10z" />
          </svg>
          <div class="f4 fw4 ttc color-theme">
            {{ name }}
          </div>
          <div
            v-if="phoneModels.length > 1"
            class="f6 fw4 fw3 mt2"
          >
            5% Multi-plan discount applied
          </div>
        </div>

        <div
          v-if="showFeatures && planType === 'PHONE'"
          class="f6"
        >
          <hr class="b-theme bg-theme b-solid mv3 mw3 center">

          <div
            v-for="(model, index) in phoneModelsWithCount"
            :key="index"
          >
            <div class="mt3 black">
              <div
                v-if="annual"
                class="f5 fw4 color-theme"
              >
                {{ model.count && model.count > 1 ? `${model.count} x` : '' }} {{ model.make }} {{ model.model }} -
                ${{ coveragePriceAnnual(model.annual_coverage_id) }}
                /yr {{ model.count && model.count > 1 ? `each` : '' }}
              </div>

              <div
                v-if="!annual"
                class="f5 fw4 color-theme"
              >
                {{ model.count && model.count > 1 ? `${model.count} x` : '' }} {{ model.make }} {{ model.model }} -
                ${{ coveragePriceMonthly(model.monthly_coverage_id) }}
                /mo {{ model.count && model.count > 1 ? `each` : '' }}
              </div>
            </div>
            <div class="mt3 flex flex-column">
              <div class="mb2">
                DEDUCTIBLES
              </div>
              <div class="black flex flex-column">
                <div class="lh-copy silver fs7">
                  ${{ model.screen_replacement_deductible }} for screen replacement
                </div>
                <div class="lh-copy silver fs7">
                  ${{ model.repair_deductible }} for repairs
                </div>
                <div class="lh-copy silver fs7">
                  ${{ model.replacement_deductible }} for replacement/theft
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt4 mb3 mw5 f4 center">
          <ak-button
            :disabled="current || disabled"
            :primary="featured && !current"
            :secondary="!featured && !current"
            block
            @click.native="submit"
          >
            <span>{{ conversionCaption }}</span>
          </ak-button>
        </div>

        <div class="mt3 silver lh-copy f6">
          <div v-if="activationFee">
            +$5 one-time activation fee
          </div>
          <div>Upgrade or downgrade anytime</div>
          <div>Prorated adjustments</div>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import AkButton from './Button'

export default {
  name: 'AkSummaryCard',
  components: {
    AkButton
  },
  props: {
    conversionCaption: {
      type: String,
      default: 'Select'
    },
    name: {
      type: String,
      default: 'Basic'
    },
    price: {
      type: Number,
      default: 10
    },
    featured: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: false
    },
    coverageIds: {
      type: Array,
      default: () => []
    },
    annual: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    savings: {
      type: Number,
      default: 0
    },
    activationFee: {
      type: Boolean,
      default: false
    },
    showFeatures: {
      type: Boolean,
      default: true
    },
    editPlan: {
      type: Boolean,
      default: false
    },
    planType: {
      type: String,
      default: 'AKKO'
    },
    phoneModels: {
      type: Array,
      default: () => []
    },
    selectedCoverages: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    totalAnnualValue () {
      return this.phoneModels.length > 1 ? this.price.toFixed(2) : this.price.toFixed(2)
    },
    showExtraSavings () {
      return this.phoneModels.length > 1
    },
    extraSavings () {
      return this.phoneModels.reduce((acum, phone) => {
        const coverage = this.getCoverage(phone.annual_coverage_id)
        if (coverage === undefined) return 0
        return acum + coverage.price * 0.05
      }, 0)
    },
    showSavingsBanner () {
      return this.annual && this.savings !== 0
    },
    phoneModelsWithCount () {
      const keys = {}
      this.phoneModels.forEach(phoneCoverage => {
        const key = `${phoneCoverage.id}-${phoneCoverage.annual_coverage_id}-${phoneCoverage.monthly_coverage_id}`
        if (keys[key]) {
          keys[key].count += 1
        } else {
          keys[key] = {
            count: 1,
            content: {...phoneCoverage}
          }
        }
      })
      const result = []

      for (let key in keys) {
        result.push({
          count: keys[key].count,
          ...keys[key].content
        })
      }
      return result
    }
  },
  methods: {
    submit () {
      const coverage = {
        id: this.coverageid,
        name: this.name,
        price: this.price,
        deductible: this.deductible,
        max_coverage: this.claims
      }
      this.$store.commit('SUBMIT_COVERAGE', coverage)
      this.$emit('click')
    },
    getCoverage (id) {
      const cov = this.selectedCoverages.find(cov => {
        return cov.id === id
      })
      return cov
    },
    coveragePriceAnnual (annualCoverageId) { // intentional duplicate code in case discount logic splits between monthly and annual
      let coverage = this.getCoverage(annualCoverageId)
      if (coverage === undefined) return 0
      let finalPrice = this.phoneModels.length > 1 ? coverage.price * 0.95 : coverage.price
      return finalPrice.toFixed(2)
    },
    coveragePriceMonthly (monthlyCoverageId) {
      let coverage = this.getCoverage(monthlyCoverageId)
      if (coverage === undefined) return 0
      let finalPrice = this.phoneModels.length > 1 ? coverage.price * 0.95 : coverage.price
      return finalPrice.toFixed(2)
    }
  }
}
</script>

<style scoped>
.extra-savings {
  padding-top: 0.5em;
  font-size: 0.8em;
}

.v-sub {
  vertical-align: sub;
}

.price {
  font-size: 3.5em;
}

.br-all {
  border-radius: 20px;
}

.savings-banner {
  border-radius: 20px 20px 0 0;
  margin: 0 -1px;
}

.bs-custom {
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.2);
}
</style>
