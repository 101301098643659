import axios from 'axios'

import {
  SET_ERRORS,
  SET_LOADING
} from './mutations'

import router from '../router'

const api = process.env.VUE_APP_API_URL

const SET_CLAIMS = 'SET_CLAIMS'
const SET_CURRENT_CLAIM = 'SET_CURRENT_CLAIM'
const SET_IMAGES_CLAIM = 'SET_IMAGES_CLAIM'
const SET_IMAGES_CLAIM_ID = 'SET_IMAGES_CLAIM_ID'
const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
const SET_IMAGES_FROM_API = 'SET_IMAGES_FROM_API'
export const DELETE_IMAGE = 'DELETE_IMAGE'
export const DELETE_CURRENT_CLAIM_IMAGE = 'DELETE_CURRENT_CLAIM_IMAGE'
export const SET_LOSS_TYPES = 'SET_LOSS_TYPES'

const claims = {
  state: {
    claims: {
      Open: [],
      Closed: [],
      Draft: []
    },
    currentClaim: {
      id: 0,
      loss_type: '',
      loss_day: 0,
      loss_month: 0,
      loss_year: 0,
      description: '',
      known_damage: '',
      status: 1,
      filed_on: '',
      devices: [],
      images: [],
      paypal_email: ''
    },
    claimImages: [],
    currentTab: 0,
    lossTypes: null
  },
  mutations: {
    [SET_CLAIMS] (state, payload) {
      state.claims = payload
    },
    [SET_CURRENT_CLAIM] (state, payload) {
      state.currentClaim = payload
    },
    [SET_IMAGES_FROM_API] (state, payload) {
      state.claimImages = payload.map((img) => ({ ...img, filename: img.file_name }))
    },
    [SET_IMAGES_CLAIM] (state, payload) {
      state.claimImages.push(payload)
    },
    [SET_IMAGES_CLAIM_ID] (state, payload) {
      const claimImages = state.claimImages
      const tmpImages = payload.map((serverImg) => {
        const claimImage = claimImages.find((cimg) => cimg.filename === serverImg.file_name)
        return { ...serverImg, ...claimImage }
      })
      state.claimImages = tmpImages
    },
    [SET_CURRENT_TAB] (state, payload) {
      state.currentTab = payload
    },
    [DELETE_IMAGE] (state, id) {
      state.claimImages = state.claimImages.filter((img) => img.id !== id)
    },
    [DELETE_CURRENT_CLAIM_IMAGE] (state, id) {
      const imageIndex = state.currentClaim.images.findIndex(item => item.id === id)
      state.currentClaim.images.splice(imageIndex, 1)
    },
    [SET_LOSS_TYPES] (state, payload) {
      state.lossTypes = payload
    }
  },
  actions: {
    getClaims ({commit}) {
      axios.get(`${api}/claims/`, {
        headers: { Authorization: `Bearer ${this.state.token}` }
      })
        .then((response) => {
          commit(SET_CLAIMS, response.data)
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error)
        })
    },
    getCurrentClaim ({commit}, payload) {
      commit(SET_ERRORS)
      return new Promise((resolve, reject) =>
        axios.get(`${api}/claims/${payload.id}`, {
          headers: { Authorization: `Bearer ${this.state.token}` }
        })
          .then((response) => {
            commit(SET_CURRENT_CLAIM, response.data)
            commit(SET_IMAGES_FROM_API, response.data.images)
            let tabToShow = 0
            if (this.state.claims.currentClaim.images.length > 0) {
              tabToShow = 4
            } else if (this.state.claims.currentClaim.description) {
              tabToShow = 3
            } else if (this.state.claims.currentClaim.loss_type) {
              tabToShow = 2
            } else if (this.state.claims.currentClaim.devices.length > 0) {
              tabToShow = 1
            }
            commit(SET_CURRENT_TAB, tabToShow)
            resolve(response)
          })
          .catch((error) => {
            console.log(error)
            commit(SET_ERRORS, error)
            reject(error)
          })
      )
    },
    fileClaim ({commit}, payload) {
      commit(SET_ERRORS, null)
      commit(SET_LOADING, true)
      const formData = new FormData()
      let images = payload.images
      delete payload.images
      for (let key in payload) {
        if (key === 'images') {
          for (let img of payload[key]) {
            formData.append('images', img.file, img.file.name)
          }
        } else {
          formData.append(key, payload[key])
        }
      }
      axios.post(`${api}/claims/`, formData, { headers: { Authorization: `Bearer ${this.state.token}` } })
        .then((response) => {
          let claimId = response.data.id
          let uploads = []
          for (let image of images) {
            let payload = {
              type: 'CLAIM',
              entity_id: claimId,
              file: image.file
            }
            uploads.push(this.dispatch('uploadToFilePipeline', payload))
          }
          return Promise.all(uploads)
        })
        .then(() => {
          router.push('/claim-info')
          commit(SET_LOADING, false)
        })
        .catch((error) => {
          console.log(error)
          commit(SET_LOADING, false)
        })
    },
    submitPartialClaim ({commit}, payload) {
      commit(SET_ERRORS, null)
      commit(SET_LOADING, true)
      const formData = new FormData()
      for (let key in payload) {
        // At this moment we just have [Date of loss, Description of Incident]
        if (key === 'devices') {
          for (let device of payload[key]) {
            formData.append('devices', device)
          }
        } else {
          formData.append(key, payload[key])
        }
      }
      axios.post(`${api}/v2/claims/`, formData, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then((response) => {
          this.dispatch('getCurrentClaim', {id: response.data.id})
          router.push('/file-claim2/' + response.data.id)
          commit(SET_LOADING, false)
          // this._vm.$toast.success('Claim created')
        })
        .catch((error) => {
          console.log(error)
          commit(SET_LOADING, false)
        })
    },
    patchClaim ({commit}, payload, callback) {
      commit(SET_ERRORS, null)
      commit(SET_LOADING, true)
      const formData = new FormData()
      let newImages = []
      for (let key in payload) {
        if (key === 'newImages') {
          const onlyImagesNotStoresOnDatabaes = payload[key].filter((img) => img['id'] === undefined)
          for (let img of onlyImagesNotStoresOnDatabaes) {
            newImages.push({name: img.filename})
          }
          formData.append('images', JSON.stringify(newImages))
        } else if (key === 'devices') {
          for (let device of payload[key]) {
            formData.append('devices', device)
          }
        } else if (key === 'hiddenImages') {
          formData.append('hiddenImages', JSON.stringify(payload[key]))
        } else {
          formData.append(key, payload[key])
        }
      }
      axios.patch(
        `${api}/v2/claims/${payload.id}`, formData, {headers: {Authorization: `Bearer ${this.state.token}`}})
        .then((response) => {
          commit(SET_IMAGES_CLAIM_ID, response.data['images'])
          if (newImages.length > 0) {
            // at this step we'll keep asking until we receive status for images processing
            // eslint-disable-next-line no-inner-declarations
            function getImagesStatus () {
              axios.get(
                `${api}/v2/claims/${payload.id}/images_status`)
                .then((response) => {
                  let allComplete = response.data['all_complete']
                  if (allComplete) {
                    commit(SET_LOADING, false)
                    if (response.data['status']) { // images has been processed
                      callback()
                      // this._vm.$toast.success('Information was saved')
                    } else {
                      this._vm.$toast.error('There was an error processing ' + response.data['errors'][0].filename)
                      // TODO also show error message and show more than one error ?
                    }
                  } else {
                    setTimeout(function () {
                      getImagesStatus()
                    }, 4000)
                  }
                  if (this.state.claimImages) {
                    this.state.claimImages = []
                  }
                  commit(SET_LOADING, false)
                }).catch((error) => {
                  console.log(error)
                  this._vm.$toast.error('Error with the service, retry again later: ' + error)
                  commit(SET_LOADING, false)
                })
            }

            getImagesStatus()
          } else {
            // this._vm.$toast.success('Information was saved')
            commit(SET_LOADING, false)
          }
          if (payload.tab === 4) { // on the last step, return user to list of claims
            router.push('/claims')
          }
        })
        .catch((error) => {
          console.log(error)
          this._vm.$toast.error('Error with the service, retry again later: ' + error)
          commit(SET_LOADING, false)
        })
    },
    deleteClaim ({commit}, payload) {
      commit(SET_LOADING, true)
      axios.delete(`${api}/claims/${payload.id}`, {
        headers: {Authorization: `Bearer ${this.state.token}`}
      }).then(
        response => {
          if (response.status === 200) {
            window.location.reload()
          } else {
            this._vm.$toast.error('Error cancelling claim')
          }
          commit(SET_LOADING, false)
        }
      ).catch(
        error => {
          commit(SET_LOADING, false)
          this._vm.$toast.error('Error with the service, please retry again later: ' + error)
        }
      )
    },
    getLossTypes ({commit}) {
      axios.get(`${api}/loss_type_categories_with_children`, {
        headers: { Authorization: `Bearer ${this.state.token}` }
      })
        .then((response) => {
          commit(SET_LOSS_TYPES, response.data)
        })
        .catch((error) => {
          console.log(error.response ? error.response.data : error)
        })
    }
  }
}

export default claims
