<template>
  <div class="flex flex-column pointer">
    <div class="flex flex-row tc flex items-center">
      <input
        id="akkoPlan"
        :checked="!isPhoneOnly"
        class="dn br3 "
        name="planToggle"
        type="radio"
        @input="setAkkoPlan"
      >
      <label
        class="f7  inter pv2 tc b buttonUnselected left pointer "
        for="akkoPlan"
        tabindex="0"
      >
        AKKO Plan
      </label>
      <div class="cera-round-pro color-gray mh2">
        OR
      </div>
      <input
        id="phonePlan"
        :checked="isPhoneOnly"
        class="dn "
        name="planToggle"
        type="radio"
        @input="setPhonePlan"
      >
      <label
        class="f7 pv2 tc b buttonUnselected left pointer inter"
        for="phonePlan"
        tabindex="0"
      >
        Phone Only
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanSelectToggle',
  props: {
    isPhoneOnly: {
      type: Boolean,
      default: false
    },
    setValue: Function,
    setAkkoPlan: Function,
    setPhonePlan: Function
  }
}
</script>

<style scoped>

.padding {
  padding-left: 10%;
}

.buttonUnselected {
  width: 100px;
  background-color: white;
  border: 1px solid var(--theme-primary);
  border-radius: 5px;
  color: var(--theme-primary);
  min-width: 6em;
  z-index: 0;

}

input[type=radio]:checked + label {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: white;
  border-radius: 5px;
  border-width: 1px;
  z-index: 1;
  font-family: 'Cera Round Pro Regular';
}

@media (max-width: 400px) {
  .buttonUnselected {
    font-size: 0.6em;
  }
}
</style>
