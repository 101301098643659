<template>
  <div
    id="parent"
    class="upsell-container upsell-container-parent"
  >
    <div
      id="prices"
      class="upsell-container content-start"
    >
      <span
        v-if="showDifference"
        id="prices-percentage"
        class="upsell-container f5 fw5"
      >
        {{ formattedPercentage }}
      </span>
      <span
        v-if="showDifference"
        id="prices-retail"
        class="price-amounts text-grey fw4"
      >{{ formattedRetailPrice }}</span>
      <div class="current-container pr3 fw5">
        <span
          id="prices-current"
          class="price-amounts"
        >{{ formattedCurrentPrice }}</span>
        <span>/ Month</span>
      </div>
    </div>
    <div
      id="prices-description"
      class="inter f7 fw5 text-grey"
    >
      <p>Billed on the same interval as your primary subscription ({{ interval }})</p>
    </div>
  </div>
</template>
<script>
import INTERVALS from '@/constants/registration/planIntervals'
export default {
  name: 'UpsellPrices',
  props: {
    upsellRetailPrice: {
      type: Number,
      default: 0
    },
    upsellCurrentPrice: {
      type: Number,
      default: 0
    },
    interval: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedRetailPrice () {
      var monthlyRate = this.convertToMonthlyRate(this.upsellRetailPrice)
      return `$${this.convertToDollars(monthlyRate)}`
    },
    formattedCurrentPrice () {
      var monthlyRate = this.convertToMonthlyRate(this.upsellCurrentPrice)
      return `$${this.convertToDollars(monthlyRate)}`
    },
    formattedPercentage () {
      let percentage = this.calculatePercentage(this.upsellRetailPrice, this.upsellCurrentPrice)
      return `${percentage}% off!`
    },
    showDifference () {
      return this.calculatePercentage(this.upsellRetailPrice, this.upsellCurrentPrice) > 0
    }
  },
  methods: {
    convertToDollars (value) {
      if (value !== null) {
        return parseFloat((value / 100)).toFixed(2).toString().replace(/\.00$/, '')
      }
      return null
    },
    calculatePercentage (retailPrice, currentPrice) {
      let difference = retailPrice - currentPrice
      return parseFloat(difference / retailPrice * 100).toFixed().toString()
    },
    convertToMonthlyRate (value) {
      if (value !== null) {
        return this.interval === INTERVALS.ANNUAL ? Math.round(value / 12) : value
      }
      return null
    }
  }
}
</script>
<style scoped>
.upsell-container {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  padding: 0px;
}

#parent {
  gap: 21px;
}

#prices {
  border-right: 1px solid #E8E8E8;
  gap: 16px;
  white-space: nowrap;
}

#prices-percentage {
  align-items: center;
  background: #2F7D22;
  border-radius: 5px;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  justify-content: center;
  line-height: 16px;
  padding: 0.125rem 0.313rem;

}

.price-amounts {
  font-size: 2.25rem;
}

#prices-retail {
  text-decoration: line-through;
}

#prices-description {
  line-height: 160%;
}

.current-container {
  color: #575757;
}

.text-grey {
  color: var(--theme-gray-text)
}

@media (max-width: 479px) {
  .upsell-container-parent {
    display: block;
    margin: 0 0.5rem;
  }

  .content-start {
    border-right: none !important;
  }
}
</style>
