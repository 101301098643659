<template>
  <main>
    <div class="inter">
      <div
        class="mobile-flex flex-row items-center pl2 pr2 pb2"
        @click="goBack"
      >
        <arrow-back1 />
        <h3 class="ml3 f4 cera-round-pro">
          {{ $t('menu.loginsecurity', {loginsecurity: 'Login & Security'}) }}
        </h3>
      </div>
      <div
        v-if="!editEmail"
        class="mv2 ph3 flex flex-column justify-center items-start height-dv"
      >
        <div class="flex flex-column">
          <span class="mt1 silver-element">{{ $t('loginsettings.emaill', {emaill: 'Email'}) }}</span>
        </div>
        <div class="flex flex-row justify-between w-100">
          <span class="data">{{ user.email }}</span>
          <span
            class="color-theme data pointer"
            @click="editEmailAddress"
          >{{ $t('edit.edit', {edit: 'Edit'}) }}</span>
        </div>
      </div>

      <!--Edit Mail Desktop-->
      <div
        v-if="editEmail"
        class="row-edit mv2 desktop modal-opened-row"
      >
        <span class="elementLeft modal-opened-row-text">{{
          $t('loginsettings.editemail', {editemail: 'Edit your email address'})
        }}</span>
        <div class="modal w-75">
          <form class="flex-column">
            <div class="mt3 mb3 flex-grow mh3-ns">
              <label
                class="f6 b db mb2 label"
                for="email"
              >{{ $t('loginsettings.emailu', {emailu: 'EMAIL'}) }}</label>
              <input
                id="email"
                v-model="email"
                v-focus
                class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
                type="text"
              >
            </div>
          </form>
          <div class="pa3 flex justify-between justify-end-ns">
            <div class="mr3 flex-grow flex-grow-off-ns">
              <ak-button
                block
                class="inter fw6 modal-button cancel"
                secondary
                @click.native="resetState"
              >
                {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
              </ak-button>
            </div>
            <div class="flex-grow flex-grow-off-ns">
              <ak-button
                :disabled="emailError"
                block
                class="inter modal-button fw6 save"
                primary
                @click.native="updateEmail"
              >
                {{ $t('edit.save', {save: 'Save'}) }}
              </ak-button>
            </div>
          </div>
        </div>
      </div>

      <!--Edit Mail Mobile-->
      <div
        v-if="editEmail"
        class="modal flex-row mobile w-75"
      >
        <form class="flex-column mh3 mt4">
          <div class="mb3">
            <span>{{ $t('loginsettings.editemailm', {editemailm: 'Edit Email'}) }}</span>
          </div>
          <div class="mt3 mb3 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="email-mobile"
            >{{
              $t('loginsettings.emailu', {emailu: 'EMAIL'})
            }}</label>
            <input
              id="email-mobile"
              v-model="email"
              v-focus
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
            >
          </div>
        </form>
        <div class="flex mh4 flex-row pb3 justify-between">
          <div class="w-48">
            <ak-button
              block
              class="modal-button inter"
              secondary
              @click.native="resetState"
            >
              {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
            </ak-button>
          </div>
          <div class="w-48">
            <ak-button
              :disabled="emailError"
              block
              class="modal-button inter"
              primary
              @click.native="updateEmail"
            >
              {{ $t('edit.save', {save: 'Save'}) }}
            </ak-button>
          </div>
        </div>
      </div>
      <hr class="line mh3">
      <!--PHONE-->
      <div
        v-if="!editPhone"
        class="mv2 ph3 flex flex-column justify-center items-start height-dv"
      >
        <div class="flex flex-column">
          <span class="mt1 silver-element">{{ $t('loginsettings.phonel', {phonel: 'Phone Number'}) }}</span>
        </div>
        <div class="flex flex-row justify-between w-100">
          <span class="data">{{ formatPhoneNumber }}</span>
          <span
            class="color-theme data pointer"
            @click="updatePhoneShow"
          >{{ $t('edit.edit', {edit: 'Edit'}) }}</span>
        </div>
      </div>

      <!--Edit phone Desktop-->
      <div
        v-if="editPhone && isDesktop && !showPhoneVerificationModal"
        class="row-edit mv2 desktop modal-opened-row"
      >
        <span class="elementLeft modal-opened-row-text">{{
          $t('loginsettings.editphone', {editphone: 'Edit your phone number'})
        }}</span>
        <div class="modal w-75">
          <form class="flex-column">
            <div class="mt3 mb3 flex-grow mh3-ns">
              <label
                class="f6 b db mb2 label"
                for="email"
              >{{
                $t('loginsettings.phoneu', {phoneu: 'PHONE NUMBER'})
              }}</label>
              <InputMask
                id="phone"
                v-model="phone_no"
                v-focus
                class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
                mask="(999) 999-9999"
                type="tel"
              />
              <div
                v-show="phoneErrorMessage"
                slot="modal-body"
                class="f6 red mh3-ns"
              >
                {{ phoneErrorMessage }}
              </div>
            </div>
          </form>
          <div class="pa3 flex justify-between justify-end-ns">
            <div class="mr3 flex-grow flex-grow-off-ns">
              <ak-button
                block
                class="inter fw6 modal-button cancel"
                secondary
                @click.native="resetState"
              >
                {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
              </ak-button>
            </div>
            <div class="flex-grow flex-grow-off-ns">
              <ak-button
                :disabled="!canUpdatePhone"
                block
                class="inter modal-button fw6 save"
                primary
                @click.native="updatePhone"
              >
                {{ $t('edit.save', {save: 'Save'}) }}
              </ak-button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="editPhone && isDesktop && showPhoneVerificationModal"
        class="row mv2 desktop modal-opened-row"
      >
        <span
          class="elementLeft modal-opened-row-text"
        >{{
          $t('loginsettings.phonecodeverify', {phonecodeverify: 'A verification code was sent to your new phone number'})
        }}</span>
        <div class="modal w-75">
          <form
            slot="modal-body"
            class="flex-column"
          >
            <div class="mt3 mb3 flex-grow mh3-ns">
              <label
                class="f6 b db mb2 label"
                for="phone_verification_code"
              >{{ $t('loginsettings.phonecode', {phonecode: 'CODE'}) }}</label>
              <input
                id="phone_verification_code"
                v-model="phone_verification_code"
                v-focus
                class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
                type="text"
              >
              <div
                v-show="phoneVerificationErrorMessage"
                slot="modal-body"
                class="f6 red mh3-ns"
              >
                {{ phoneVerificationErrorMessage }}
              </div>
            </div>
          </form>
          <div
            slot="modal-footer"
            class="pa3 flex flex-row justify-between justify-end-ns"
          >
            <div class="mr3 flex-grow flex-grow-off-ns">
              <ak-button
                block
                class="modal-button inter fw6 cancel"
                secondary
                @click.native="resetState"
              >
                {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
              </ak-button>
            </div>
            <div class="flex-grow flex-grow-off-ns">
              <ak-button
                :disabled="!canVerifyPhoneCode"
                block
                class="modal-button inter fw6 save"
                primary
                @click.native="verifyPhoneVerificationCode"
              >
                {{ $t('edit.save', {save: 'Save'}) }}
              </ak-button>
            </div>
          </div>
        </div>
      </div>
      <!--Edit phone mobile-->
      <div
        v-if="editPhone && !isDesktop"
        class="modal flex-row mobile w-75"
      >
        <div v-if="editPhone && !isDesktop && !showPhoneVerificationModal">
          <form class="flex-column mh3 mt4">
            <div class="mb3">
              <span>{{ $t('loginsettings.editphonem', {editphonem: 'Edit Phone Number'}) }}</span>
            </div>
            <div class="mt3 mb3 flex-grow mh3-ns">
              <label
                class="f6 b db mb2 label"
                for="phone-mobile"
              >{{ $t('loginsettings.phoneu', {phoneu: 'PHONE NUMBER'}) }}</label>
              <InputMask
                id="phone-mobile"
                v-model="phone_no"
                v-focus
                class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
                mask="(999) 999-9999"
                type="tel"
              />
              <div
                v-show="phoneErrorMessage"
                slot="modal-body"
                class="f6 red mh3-ns"
              >
                {{ phoneErrorMessage }}
              </div>
            </div>
          </form>
          <div class="flex mh4 flex-row pb3 justify-between">
            <div class="w-48">
              <ak-button
                block
                class="modal-button inter"
                secondary
                @click.native="resetState"
              >
                {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
              </ak-button>
            </div>
            <div class="w-48">
              <ak-button
                :disabled="!canUpdatePhone"
                block
                class="modal-button inter"
                primary
                @click.native="updatePhone"
              >
                {{ $t('edit.save', {save: 'Save'}) }}
              </ak-button>
            </div>
          </div>
        </div>
        <div v-if="editPhone && !isDesktop && showPhoneVerificationModal">
          <form class="flex-column mh3 mt4">
            <div class="mb3">
              <span>{{
                $t('loginsettings.phonecodeverify', {phonecodeverify: 'A verification code was sent to your new phone number'})
              }}</span>
            </div>
            <div class="mt3 mb3 flex-grow mh3-ns">
              <label
                class="f6 b db mb2 label"
                for="phone_verification_code_mobile"
              >{{
                $t('loginsettings.phonecode', {phonecode: 'CODE'})
              }}</label>
              <input
                id="phone_verification_code_mobile"
                v-model="phone_verification_code"
                v-focus
                class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
                type="text"
              >
              <div
                v-show="phoneVerificationErrorMessage"
                slot="modal-body"
                class="f6 red mh3-ns"
              >
                {{ phoneVerificationErrorMessage }}
              </div>
            </div>
          </form>
          <div
            slot="modal-footer"
            class="flex mh4 flex-row pb3 justify-between"
          >
            <div class="w-48">
              <ak-button
                block
                class="modal-button inter"
                secondary
                @click.native="resetState"
              >
                {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
              </ak-button>
            </div>
            <div class="w-48">
              <ak-button
                :disabled="!canVerifyPhoneCode"
                block
                class="inter modal-button"
                primary
                @click.native="verifyPhoneVerificationCode"
              >
                {{ $t('edit.save', {save: 'Save'}) }}
              </ak-button>
            </div>
          </div>
        </div>
      </div>

      <hr class="line mh3">

      <!--Password-->
      <div
        v-if="!editPassword && !isErrorPassword"
        class="ph3 mv2 flex flex-column justify-center items-start height-dv"
      >
        <div class="flex flex-column">
          <span class="mt1 silver-element">{{ $t('loginsettings.passwordl', {passwordl: 'Password'}) }}</span>
        </div>
        <div class="flex flex-row justify-between w-100">
          <span class="data">&lowast;&lowast;...&lowast;&lowast;</span>
          <span
            class="color-theme data pointer"
            @click="updatePasswordShow"
          >{{
            $t('edit.edit', {edit: 'Edit'})
          }}</span>
        </div>
      </div>

      <!--Edit Pass Desktop-->
      <div
        v-if="editPassword || isErrorPassword"
        class="row-edit mv2 desktop modal-opened-row"
      >
        <div class="elementLeft modal-opened-row-text">
          {{ $t('loginsettings.editpassword', {editpassword: 'Edit your password'}) }}
        </div>

        <div class="modal w-75">
          <form
            slot="modal-body"
            class="flex-column"
          >
            <div class="mt3 mb3 flex-grow mh3-ns">
              <label
                class="f6 b db mb2 label"
                for="current"
              >{{
                $t('loginsettings.passwordcurrent', {passwordcurrent: 'CURRENT PASSWORD'})
              }}</label>
              <input
                id="current"
                v-model="current"
                v-focus
                :type="showPasswords ? 'text' : 'password'"
                class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              >
            </div>
            <div
              v-show="isErrorPassword"
              slot="modal-body"
              class="f6 red mh3-ns current-error"
            >
              {{ $store.state.errors[0] }}
            </div>
            <div class="mt4 mb3 flex-grow mh3-ns">
              <label
                class="f6 b db mb2 label"
                for="pass"
              >{{ $t('loginsettings.passwordnew', {passwordnew: 'NEW PASSWORD'}) }}</label>
              <input
                id="pass"
                v-model="password"
                :type="showPasswords ? 'text' : 'password'"
                class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              >
            </div>
            <div class="mt4 mb3 flex-grow mh3-ns">
              <label
                class="f6 b db mb2 label"
                for="confirm"
              >{{
                $t('loginsettings.passwordconfirm', {passwordconfirm: 'CONFIRM PASSWORD'})
              }}</label>
              <input
                id="confirm"
                v-model="confirm"
                :type="showPasswords ? 'text' : 'password'"
                class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              >
            </div>
            <div class="mb3 flex-grow mh3-ns flex items-center">
              <a
                class="underline pointer color-theme f7"
                @click="showPasswords = !showPasswords"
              >{{
                showPasswords ? $t('loginsettings.passwordhide', {passwordhide: 'Hide Password'}) : $t('loginsettings.passwordshow', {passwordshow: 'Show Password'})
              }}</a>
            </div>
            <div
              slot="modal-body"
              class="f65 red ml3 mb2"
              :class="(passwordLength) ? 'green' : 'red' "
            >
              {{ (passwordLength) ? '✓' : '✘' }}
              {{ $t('loginsettings.passwordtip1', {passwordtip1: 'At least 8 characters long'}) }}
            </div>
            <div
              slot="modal-body"
              class="f65 red ml3 mb2"
              :class="(passwordUpper) ? 'green' : 'red'"
            >
              {{ passwordUpper ? '✓' : '✘' }}
              {{ $t('loginsettings.passwordtip2', {passwordtip2: 'Contain at least one uppercase character'}) }}
            </div>
            <div
              slot="modal-body"
              class="f65 red ml3 mb2"
              :class="(passwordLower) ? 'green' : 'red'"
            >
              {{ passwordLower ? '✓' : '✘' }}
              {{ $t('loginsettings.passwordtip3', {passwordtip3: 'Contain at least one lowercase character'}) }}
            </div>
            <div
              slot="modal-body"
              class="f65 red ml3 mb2"
              :class="(passwordNumber) ? 'green' : 'red'"
            >
              {{ passwordNumber ? '✓' : '✘' }}
              {{ $t('loginsettings.passwordtip4', {passwordtip4: 'Contain at least one number'}) }}
            </div>
            <div
              slot="modal-body"
              class="f65 red ml3 mb2"
              :class="(passwordSpecial) ? 'green' : 'red'"
            >
              {{ passwordSpecial ? '✓' : '✘' }}
              {{ $t('loginsettings.passwordtip5', {passwordtip5: 'Contain at least one special character'}) }}
            </div>
            <div
              slot="modal-body"
              class="f65 red ml3 mb4"
              :class="(passwordsMatch) ? 'green' : 'red'"
            >
              {{ passwordsMatch ? '✓' : '✘' }} {{ $t('loginsettings.passwordtip6', {passwordtip6: 'Passwords match'}) }}
            </div>
          </form>
          <div
            slot="modal-footer"
            class="pa3 flex flex-row justify-between justify-end-ns"
          >
            <div class="mr3 flex-grow flex-grow-off-ns">
              <ak-button
                block
                class="modal-button inter fw6 cancel"
                secondary
                @click.native="resetState"
              >
                {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
              </ak-button>
            </div>
            <div class="flex-grow flex-grow-off-ns">
              <ak-button
                :disabled="!validPassword || current === ''"
                block
                class="modal-button inter fw6 save"
                primary
                @click.native="updatePassword"
              >
                {{ $t('edit.save', {save: 'Save'}) }}
              </ak-button>
            </div>
          </div>
        </div>
      </div>
      <!--Edit Pass Mobile-->
      <div
        v-if="editPassword || isErrorPassword"
        class="modal flex-row mobile w-75"
      >
        <form class="flex-column mh3 mt4">
          <div class="mb3">
            <span>{{ $t('loginsettings.editpasswordm', {editpasswordm: 'New Password'}) }}</span>
          </div>
          <div class="mt3 mb3 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="current-mobile"
            >{{
              $t('loginsettings.passwordcurrent', {passwordcurrent: 'CURRENT PASSWORD'})
            }}</label>
            <input
              id="current-mobile"
              v-model="current"
              v-focus
              :type="showPasswords ? 'text' : 'password'"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
            >
          </div>
          <div
            v-show="isErrorPassword"
            slot="modal-body"
            class="f6 red mh3-ns current-error"
          >
            {{ $store.state.errors[0] }}
          </div>
          <div class="mt3 mb3 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="pass-mobile"
            >{{ $t('loginsettings.passwordnew', {passwordnew: 'NEW PASSWORD'}) }}</label>
            <input
              id="pass-mobile"
              v-model="password"
              :type="showPasswords ? 'text' : 'password'"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
            >
          </div>
          <div class="mt3 mb3 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="confirm-mobile"
            >{{
              $t('loginsettings.passwordconfirm', {passwordconfirm: 'CONFIRM PASSWORD'})
            }}</label>
            <input
              id="confirm-mobile"
              v-model="confirm"
              :type="showPasswords ? 'text' : 'password'"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
            >
          </div>
          <div class="mb3 flex-grow mh3-ns flex items-center">
            <a
              class="underline pointer color-theme f7"
              @click="showPasswords = !showPasswords"
            >{{ showPasswords ? 'Hide Password' : 'Show Password' }}</a>
          </div>
          <div
            slot="modal-body"
            class="f65 mh3-ns mb1"
            :class="(passwordLength) ? 'green' : 'red' "
          >
            {{ (passwordLength) ? '✓' : '✘' }}
            {{ $t('loginsettings.passwordtip1', {passwordtip1: 'At least 8 characters long'}) }}
          </div>
          <div
            slot="modal-body"
            class="f65 mh3-ns mb1"
            :class="(passwordUpper) ? 'green' : 'red'"
          >
            {{ passwordUpper ? '✓' : '✘' }}
            {{ $t('loginsettings.passwordtip2', {passwordtip2: 'Contain at least one uppercase character'}) }}
          </div>
          <div
            slot="modal-body"
            class="f65 mh3-ns mb1"
            :class="(passwordLower) ? 'green' : 'red'"
          >
            {{ passwordLower ? '✓' : '✘' }}
            {{ $t('loginsettings.passwordtip3', {passwordtip3: 'Contain at least one lowercase character'}) }}
          </div>
          <div
            slot="modal-body"
            class="f65 mh3-ns mb1"
            :class="(passwordNumber) ? 'green' : 'red'"
          >
            {{ passwordNumber ? '✓' : '✘' }}
            {{ $t('loginsettings.passwordtip4', {passwordtip4: 'Contain at least one number'}) }}
          </div>
          <div
            slot="modal-body"
            class="f65 mh3-ns mb1"
            :class="(passwordSpecial) ? 'green' : 'red'"
          >
            {{ passwordSpecial ? '✓' : '✘' }}
            {{ $t('loginsettings.passwordtip5', {passwordtip5: 'Contain at least one special character'}) }}
          </div>
          <div
            slot="modal-body"
            class="f65 mh3-ns mb3"
            :class="(passwordsMatch) ? 'green' : 'red'"
          >
            {{ passwordsMatch ? '✓' : '✘' }} {{ $t('loginsettings.passwordtip6', {passwordtip6: 'Passwords match'}) }}
          </div>
        </form>
        <div class="flex mh-responsive flex-row pb3 justify-between">
          <div class="w-48">
            <ak-button
              block
              class="modal-button inter"
              secondary
              @click.native="resetState"
            >
              {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
            </ak-button>
          </div>
          <div class="w-48">
            <ak-button
              :disabled="!validPassword || current === ''"
              block
              class="modal-button inter"
              primary
              @click.native="updatePassword"
            >
              {{ $t('edit.save', {save: 'Save'}) }}
            </ak-button>
          </div>
        </div>
      </div>

      <!--      <hr class="line mh3"/>-->

      <!--      <div class="mv2 ph3 flex flex-column justify-center items-start height-dv">-->
      <!--        <div class="flex flex-column">-->
      <!--          <span class="mt1 silver">2 Factor Authentication</span>-->
      <!--        </div>-->
      <!--        <div class="flex flex-row justify-between w-100">-->
      <!--          <span class="data">Disabled</span>-->
      <!--          <span class="color-theme pointer data">Edit</span>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </main>
</template>

<script>

import AkButton from '../../../Button.vue'
import {checkEmailFormat} from '../../../../utils'
import InputMask from 'primevue/inputmask'
import ArrowBack1 from '../../icons/ArrowBack.vue'
import { ERROR_CODE, ERROR_PHONE_NUMBER } from '../../../../constants/account-settings/accountSettings'

export default {
  name: 'LoginAndSecurity',
  components: {
    AkButton,
    InputMask,
    ArrowBack1
  },
  props: {
    name: String
  },
  data () {
    return {
      current: '',
      editEmail: false,
      editPhone: false,
      showPhoneVerificationModal: false,
      phone_verification_code: '',
      editPassword: false,
      email: '',
      phone_no: null,
      password: '',
      confirm: '',
      showPasswords: false,
      windowWidth: null,
      isErrorPassword: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isDesktop () {
      return this.windowWidth >= 767
    },
    formatPhoneNumber () {
      const cleaned = ('' + this.user.phone_no).replace(/\D/g, '')
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        const intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return null
    },
    emailError () {
      return checkEmailFormat(this.email.trim())
    },
    phoneErrorMessage () {
      const {errors, messages} = this.$store.state
      if (!errors || errors.length === 0) {
        if (messages && messages.length > 0) {
          this.$store.state.errors = []
          this.$store.state.messages = []
          this.showPhoneModal = false
          this.showPhoneVerificationModal = true
          return
        }
        return ''
      }

      return errors[0].message || ERROR_PHONE_NUMBER
    },
    phoneVerificationErrorMessage () {
      const {errors, messages} = this.$store.state

      if (!errors || errors.length === 0) {
        if (messages && messages.length > 0) return this.resetState()
        return ''
      }

      return errors[0].message || ERROR_CODE
    },
    canUpdatePhone () {
      return this.isValidPhone(this.phone_no)
    },
    canVerifyPhoneCode () {
      return this.phone_verification_code && this.phone_verification_code.trim() !== ''
    },
    passwordsMatch () {
      return (this.password === this.confirm && this.password.length >= 8)
    },
    passwordLength () {
      return this.password.length >= 8
    },
    passwordUpper () {
      return (this.password.toLowerCase() !== this.password)
    },
    passwordLower () {
      return (this.password.toUpperCase() !== this.password)
    },
    passwordNumber () {
      return /\d/.test(this.password)
    },
    passwordSpecial () {
      const specialChars = /[`!@#$%^&*()_+\-=[{}\];':"\\|,.<>/?~]/
      return specialChars.test(this.password)
    },
    validPassword () {
      return (
        this.passwordsMatch &&
        this.passwordUpper &&
        this.passwordLower &&
        this.passwordNumber &&
        this.passwordSpecial &&
        this.passwordLength
      )
    }
  },
  watch: {
    '$store.state.errors' (val) {
      if (val && this.$store.state.errors.length > 0) {
        this.isErrorPassword = true
      }
    }
  },
  mounted () {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    if (this.user.email) this.email = this.user.email
    if (this.user.phone_no) this.phone_no = this.formatPhoneNumber
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  },
  methods: {
    resetState () {
      this.editEmail = false
      this.editPhone = false
      this.showPhoneVerificationModal = false
      this.phone_verification_code = ''
      this.editPassword = false
      this.email = ''
      this.phone_no = null
      this.password = ''
      this.confirm = ''
      this.showPasswords = false
      this.isErrorPassword = false
    },
    editEmailAddress () {
      this.email = this.user.email || ''
      this.editEmail = true
      this.editPassword = false
      this.editPhone = false
    },
    updatePhoneShow () {
      this.editPhone = true
      this.editEmail = false
      this.editPassword = false
    },
    updatePasswordShow () {
      this.editPhone = false
      this.editEmail = false
      this.editPassword = true
    },
    updateEmail () {
      let payload = {}
      if (this.editEmail) {
        payload.email = this.email
      }
      this.$store.dispatch('updateProfile', payload)
      this.resetState()
    },
    updatePassword () {
      this.$store.dispatch('updatePassword', {password: this.password, current_password: this.current, actor: this.$store.state.user_id.toString()})
      this.showPasswordModal = this.isErrorPassword
      this.current = ''
      this.password = ''
      this.confirm = ''
      this.resetState()
    },
    updatePhone () {
      let payload = {}
      if (this.phone_no.trim() !== '') {
        payload.phone_no = this.phone_no.trim()
      }
      this.$store.dispatch('updateProfile', payload)
    },
    verifyPhoneVerificationCode () {
      const payload = {}
      payload.code = this.phone_verification_code.trim()
      this.$store.dispatch('verifyPhoneNumber', payload)
      this.resetState()
    },
    goBack () {
      this.$router.push('/account')
    }
  }
}
</script>

<style scoped>

input {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
}

input:focus {
  border: 1px solid #F3103D;
}

.height-dv {
  height: 71px;
}

.mh-responsive {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  height: 71px;
  border-radius: 5px;
  padding: 0 18px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757D8A;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #757D8A;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #757D8A;
}


.row-edit {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  height: 71px;
  border-radius: 5px;
  padding: 0 18px;
}

.row:hover, .mobile-row:hover {
  background: #FAFAFA;
  border-radius: 4px;
}

.modal-opened-row {
  align-items: flex-start;
  height: auto;
}

.silver-element{
  color: #757D8A;
}

.modal-button {
  height: 40px;
  border-radius: 5px;
}

.modal-opened-row-text {
  margin-top: 2.75em;
}

.modal {
  width: 75%;
  align-items: flex-start;
}

.save {
  width: 90px;
}

.cancel {
  width: 105px;
  border-width: 1.5px;
}

.height-dv:hover {
  background-color: #fafafa;
  border-radius: 4px;
}

.line {
  border: 1px solid #E8E8E8;
}

.content:last-child hr {
  display: none;
}

.label {
  color: #757D8A;
  font-size: 11px;
}

.mobile-flex, .mobile {
  display: none;
}

.elementLeft {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  color: #757D8A;
}

.data {
  margin-top: .75rem;
}

main {
  padding-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  padding-top: .25rem;
}

.f65 {
  font-size: .825rem;
}


@media (max-width: 768px) {

  .mobile-flex {
    display: flex;
  }

  .mobile {
    display: block;
  }

  .w-48 {
    width: 48%;
  }

  .height-dv {
    height: 74px;
  }

  .desktop {
    display: none;
  }

  .modal {
    width: 100%
  }

  main {
    padding-top: 0;
    padding-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

@media (max-width: 489px) {

  .mh-responsive {
  margin-left: 1rem;
  margin-right: 1rem;
}

}


</style>
