import posthog from 'posthog-js'

export const initTracking = function () {
  posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
    api_host: process.env.VUE_APP_POSTHOG_HOST
  })

  posthog.register({
    commitRef: process.env.VUE_APP_COMMIT_REF
  })
}

export const trackEvent = function (event, payload) {
  posthog.capture(event, payload)
}

export const identifyUser = function (userId) {
  posthog.identify(`user_${userId}`, { usedConsumerSite: true })
}

export const reset = function () {
  posthog.reset()
}
