<template>
  <div :class="get_class()">
    <div
      class="grid-two pointer"
      @click="(e) => toggleInclude(e)"
    >
      <div>
        <div class="mb3 f4">
          <span class="cera-round-pro-medium">{{ $t('titles.claimsid', { claimsid: 'Claim' }) }} #{{ id }}</span>
          <span class="cera-round-pro-medium">
            <small
              class="loss-type-label cera-round-pro"
              :style="get_label_style()"
            >
              {{ status_text }}
            </small>
          </span>
        </div>
        <div>
          <div>
            <a
              :href="urlToViewClaimDetail()"
              class="no-underline black"
            >
              <span class="f5 mb2 color-theme title-responsive">
                View Details
                <img
                  src="@/assets/icons/ic_greater_than_right.svg"
                  alt=""
                  class="mr1"
                >
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="f6 silver inter toggle-info">
        <span>{{ filed_days_ago }}</span>
        <img
          :class="{ 'chevron-rotate': includeOpen }"
          alt="Chevron"
          class="chevron"
          src="@/assets/appicons/Chevron.svg"
        >
      </div>
    </div>

    <hr
      v-if="includeOpen"
      class="center mv3"
    >
    <div v-if="includeOpen">
      <div class="f6 silver inter mb3">
        AFFECTED DEVICES
      </div>
      <div
        v-for="claim_device in claimDevices"
        :key="claim_device.id"
      >
        <a
          :href="urlToViewClaimDeviceAffected(claim_device.claim_device_id)"
          class="no-underline black"
        >
          <div class="grid-two hover-grey-bg">
            <div class="hov pt-15">
              <div class="d-inline w-50-px">
                <img
                  :src="icon(claim_device.type)"
                  alt=""
                  class="img-responsive v-mid mr2 mb1"
                  :class="icon(claim_device.type) !== '' ? 'icon-affected-item' : ''"
                >
              </div>
              <div class="d-inline">
                <span class="inter; font-size:1rem;">{{ claim_device.brand }} {{ claim_device.model }}</span><br>
                <small
                  v-for="loss_type in claim_device.loss_types"
                  :key="loss_type.id"
                  class="f6 silver inter"
                >
                  <span v-if="loss_type.name == 'Other'">{{ loss_type.loss_type_category }} - </span>{{ loss_type.name }}
                </small>
              </div>
            </div>
            <div class="hov toggle-info pt-30">
              <img
                src="@/assets/icons/ic_greater_than_right_grey.svg"
                alt=""
                class="mr1"
              >
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CLAIM_STATUS_TEXT from '@/constants/claims/claimStatusText'
import CLAIM_STATUS from '@/constants/claims/claimStatus'
import { GET_FEATURE_FLAG } from '@/store/index'
import { MULTI_LOSS_TYPE, CLAIM_V2 } from '@/constants/feature_flag'

export default {
  name: 'AkClaimCardV2',
  props: {
    id: {
      type: Number,
      required: true
    },
    version: {
      type: Number,
      required: true
    },
    lossType: {
      type: String,
      required: true
    },
    lossDay: {
      type: Number,
      required: true
    },
    lossMonth: {
      type: Number,
      required: true
    },
    lossYear: {
      type: Number,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    knownDamage: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    filedOn: {
      type: String,
      required: true
    },
    devices: {
      type: Array,
      required: false,
      default: () => []
    },
    claimDevices: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      includeOpen: false
    }
  },
  computed: {
    filed_days_ago () {
      const filedTime = new Date(this.filedOn)
      const day = filedTime.getUTCDate()
      const month = filedTime.getMonth() + 1
      const year = filedTime.getFullYear()
      return `Filed on ${year}-${month}-${day}`
    },
    status_text () {
      return CLAIM_STATUS_TEXT[this.status]
    },
    featureFlagMultiLossTypeActive () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](MULTI_LOSS_TYPE)
      return feature ? feature.state : false
    }
  },
  methods: {
    get_class () {
      return 'pa4 bg-white shadow-theme bw3 br20 hover-lift'
    },
    get_label_style () {
      let theme = [CLAIM_STATUS.APPROVED].includes(this.status) ? 'background: var(--theme-primary);' : 'background: gray;'
      if (this.status === CLAIM_STATUS.DRAFT) {
        theme = 'background: #D4D4D4; color: #575757'
      } else if (this.status === CLAIM_STATUS.NEW) {
        theme = 'background: #DCEFFF; color: #2C90E7'
      }
      return theme
    },
    delete_claim () {
      this.$store.dispatch(
        'deleteClaim',
        {id: this.id}
      )
    },
    toggleInclude (e) {
      this.includeOpen = !this.includeOpen
      e.stopPropagation()
    },
    icon (deviceType) {
      /* Electronics */
      if (deviceType === 'phone') {
        return require('@/assets/appicons/PhoneNew.svg')
      }
      if (deviceType === 'tablet') {
        return require('@/assets/appicons/TabletNew.svg')
      }
      if (deviceType === 'laptop') {
        return require('@/assets/appicons/LaptopNew.svg')
      }
      if (deviceType === 'desktop') {
        return require('@/assets/appicons/DesktopNew.svg')
      }
      if (deviceType === 'smartwatch') {
        return require('@/assets/appicons/SmartwatchNew.svg')
      }
      if (deviceType === 'tv') {
        return require('@/assets/appicons/TVNew.svg')
      }
      if (deviceType === 'gaming console') {
        return require('@/assets/appicons/GameControllerNew.svg')
      }
      if (deviceType === 'gaming handheld') {
        return require('@/assets/appicons/GameControllerNew.svg')
      }
      if (deviceType === 'streaming device') {
        return require('@/assets/appicons/TVNew.svg')
      }
      /* Accessories */
      if (deviceType === 'camera') {
        return require('@/assets/appicons/CameraNew.svg')
      }
      if (deviceType === 'sunglasses') {
        return require('@/assets/appicons/SunglassesNew.svg')
      }
      if (deviceType === 'glasses') {
        return require('@/assets/appicons/SunglassesNew.svg')
      }
      if (deviceType === 'backpack') {
        return require('@/assets/appicons/BackpackNew.svg')
      }
      if (deviceType === 'watch') {
        return require('@/assets/appicons/WatchNew.svg')
      }
      /* Audio */
      if (deviceType === 'headphones') {
        return require('@/assets/appicons/HeadphoneNew.svg')
      }
      if (deviceType === 'speakers') {
        return require('@/assets/appicons/SpeakerNew.svg')
      }
      if (deviceType === 'professional audio equipment') {
        return require('@/assets/appicons/AudioEquipmentNew.svg')
      }
      if (deviceType === 'dj equipment') {
        return require('@/assets/appicons/DJEquipmentNew.svg')
      }
      /* Photography */
      if (deviceType === 'lens') {
        return require('@/assets/appicons/CameraLensNew.svg')
      }
      if (deviceType === 'camera accessory') {
        return require('@/assets/appicons/CameraNew.svg')
      }
      /* Transport */
      if (deviceType === 'bicycle') {
        return require('@/assets/appicons/BicycleNew.svg')
      }
      if (deviceType === 'electric bike') {
        return require('@/assets/appicons/BicycleNew.svg')
      }
      if (deviceType === 'skateboard') {
        return require('@/assets/appicons/SkateboardNew.svg')
      }
      if (deviceType === 'electric skateboard') {
        return require('@/assets/appicons/SkateboardNew.svg')
      }
      if (deviceType === 'scooter') {
        return require('@/assets/appicons/ScooterNew.svg')
      }
      if (deviceType === 'electric scooter') {
        return require('@/assets/appicons/ScooterNew.svg')
      }
      /* Appliances */
      if (deviceType === 'microwave') {
        return require('@/assets/appicons/MicrowaveNew.svg')
      }
      /* Sports */
      if (deviceType === 'golf clubs') {
        return require('@/assets/appicons/GolfClubsNew.svg')
      }
      if (deviceType === 'skiis') {
        return require('@/assets/appicons/SkiisNew.svg')
      }
      if (deviceType === 'snowboard') {
        return require('@/assets/appicons/SnowboardNew.svg')
      }
      if (deviceType === 'surfboard') {
        return require('@/assets/appicons/SurfboardNew.svg')
      }
      if (deviceType === 'guitar') {
        return require('@/assets/appicons/GuitarNew.svg')
      }
      if (deviceType === 'rollerskates') {
        return require('@/assets/appicons/RollerSkatesNew.svg')
      }
      return ''
    },
    urlToViewClaimDetail () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](CLAIM_V2)
      let path = `/claims/${this.id}`
      if (feature && feature.state && this.version === 2) {
        const t = this.$store.state.token
        path = `${process.env.VUE_APP_CLAIMS_V2}claim-detail/${this.id}?t=${t}`
      }
      return path
    },
    urlToViewClaimDeviceAffected (deviceId) {
      const feature = this.$store.getters[GET_FEATURE_FLAG](CLAIM_V2)
      let path = `/claims/${this.id}`
      if (feature && feature.state && this.version === 2) {
        const t = this.$store.state.token
        path = `${process.env.VUE_APP_CLAIMS_V2}claim-detail/${this.id}/devices/${deviceId}?t=${t}`
      }
      return path
    }
  }
}
</script>

<style>
hr {
  border: 1px solid #F6F5F5;
}

.grid-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 1.25em;
}

.hover-grey-bg:hover {
  background-color: #F6F5F5;
  cursor: pointer;
}

.d-inline {
  display: inline-block;
}

.w-50-px {
  width: 50px;
}

.mt-custom {
  margin-top: 5px;
}

.chevron-rotate {
  transform: rotate(180deg);
}

.chevron-rotate-270 {
  transform: rotate(270deg);
}

.toggle-info {
  cursor: pointer;
  text-align: right;
}

.icon-affected-item {
  width: 80%;
  padding-bottom: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-30 {
  padding-top: 30px;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 1100px) {
  .img-responsive{
    width: 45px;
  }
}


@media (max-width: 768px) {
  .img-responsive{
    width: auto;
  }
}
</style>
