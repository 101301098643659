import * as Sentry from '@sentry/vue'

export const initSentry = function (app, router, environment) {
  Sentry.init({
    app,
    dsn: 'https://df0b4e580c664db19bf0a9e75b43ed26@o537383.ingest.sentry.io/5655502',
    integrations: [
      Sentry.browserTracingIntegration({ router}),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    debug: environment !== 'production',
    environment,
  });

  Sentry.setContext("build", {
    commitRef: process.env.VUE_APP_COMMIT_REF,
  });
}