<template>
  <ShareNetwork
    class="flex white no-underline bg-theme pv2 ph3 br-pill pointer ba bw1 db b-theme"
    url="https://getAKKO.com"
    :description="description"
    :network="networkName"
    :quote="description"
    :title="postTitle"
  >
    {{ title }}
  </ShareNetwork>
</template>

<script>
export default {
  name: 'AkShareButton',
  props: {
    title: {
      type: String,
      default: 'Twitter'
    },
    networkName: {
      type: String,
      default: 'twitter'
    },
    postTitle: {
      type: String,
      default: 'I just signed up with AKKO to protect my phone and all electronics for only $17/mo. Check it out!'

    },
    description: {
      type: String,
      default: 'I just signed up with AKKO to protect my phone and all electronics for only $17/mo. Check it out!'
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
