<template>
  <main>
    <div class="flex items-end ">
      <span class="f2 b title-responsive">{{ $t('titles.additems', { additems: 'Add new item' }) }}</span>
    </div>
    <div class="mv4-l mv2" />

    <div v-if="!userHasAccess">
      <ak-alert
        v-if="user.membership_status === 'CANCELED'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'UNPAID'"
        color="red"
      >
        <span>Your coverage plan is currently unpaid. Please proceed to <router-link
          to="/account/dashboard"
          class="color-theme"
        >Account Settings</router-link> to resolve or </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'PAST_DUE'"
        color="red"
      >
        <span>Your coverage plan is currently past due. Please proceed to <router-link
          to="/account/dashboard"
          class="color-theme"
        >Account Settings</router-link> to resolve or </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'BANNED'"
        color="red"
      >
        <span>Your account has been banned. If you think this was done in error, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Inquiry Regarding Banned Account"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INACTIVE'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INVALID_USER'"
        color="red"
      >
        <span>Your account has been marked as invalid by our system due to unpaid premiums or other account issues. If you think this was done in error, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>
    </div>

    <div class="mt4">
      <div class="mb4-ns f3">
        Please select a category:
      </div>

      <div class="card-grid">
        <div
          v-for="(category, index) in allowedCategories"
          :key="index"
          class="pa3 bg-white account-card mb2-m pointer"
          @click="chooseCategory(category)"
        >
          <div class="responsive-card">
            <div class="flex flex-row items-center mb2">
              <img
                alt=""
                class="mr1 w-20-l width-responsive-icon filter-shadow"
                :src="category.icon"
              >
              <div class="flex-column">
                <div class="pl2 ttc f4 mb2 mb0-ns">
                  {{ category.name }}
                </div>
                <span class="pl2 f6 inter color-text text-responsive-not"> {{ category.description }}</span>
              </div>
            </div>
            <span class="f6 inter color-text text-responsive"> {{ category.description }}</span>
          </div>
        </div>
      </div>
    </div>

    <ak-modal
      v-if="showNotCoveredModal"
      title="You have to upgrade your plan to cover this category"
      @close="resetState"
    >
      <div slot="modal-body">
        <div>
          <div class="mb4">
            This category is not covered by your currently selected coverage
          </div>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="pa3 flex-ns flex-wrap justify-end"
      >
        <div
          v-if="!useDeviceRestrictions"
          class="order-1 mb3 mb0-ns"
        >
          <ak-button
            block
            primary
            @click.native="debounceMethod(() => updateCoverage(coverageUpgrade.id))"
          >
            Upgrade
          </ak-button>
        </div>
        <div class="mr3-ns">
          <ak-button
            block
            secondary
            @click.native="resetState"
          >
            Close
          </ak-button>
        </div>
      </div>
    </ak-modal>
  </main>
</template>

<script>
import AkAlert from './Alert'
import AkModal from './Modal'
import AkButton from './Button'
import { DEVICE_DATA } from '../constants/add-devices/addDevices'
import COVERAGE_RESTRICTION_TYPES from '../constants/add-devices/coverageRestrictionTypes'
import axios from 'axios'
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { GET_FEATURE_FLAG } from '@/store/index'
import { DEVICE_RESTRICTIONS } from '@/constants/feature_flag'

export default {
  name: 'AddDeviceCategory',
  components: {
    AkAlert,
    AkModal,
    AkButton
  },
  data () {
    return {
      showNotCoveredModal: false,
      coverageRestrictionTypes: COVERAGE_RESTRICTION_TYPES,
      coverageUpgrade: {}
    }
  },
  computed: {
    ...mapGetters({
      itemRestrictions: 'itemRestrictions'
    }),
    user () {
      return this.$store.state.user
    },
    userHasAccess () {
      const status = this.user.membership_status
      return status !== 'BANNED' && status !== 'CANCELED' && status !== 'UNPAID' && status !== 'PAST_DUE' && status !== 'INACTIVE' && status !== 'INVALID_USER'
    },
    coverage_tags () {
      return this.$store.state.user.coverage_tags
    },
    is_active_coverage_restrictions () {
      return !!this.$store.state.user.coverage_restriction_active
    },
    coverage_restriction_details () {
      return this.$store.state.user.coverage_restriction_details
    },
    userHasDeviceRestrictions () {
      if (this.$store.state.contract_line_items.length) {
        return this.itemRestrictions !== undefined
      } else {
        return false
      }
    },
    featureFlagDeviceRestrictions () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](DEVICE_RESTRICTIONS)
      return feature ? feature.state : false
    },
    useDeviceRestrictions () {
      return this.featureFlagDeviceRestrictions && this.userHasDeviceRestrictions
    },
    categories () {
      if (this.featureFlagDeviceRestrictions && this.userHasDeviceRestrictions) {
        return this.$store.state.item_specifications.map((category) => {
          return {...category, icon: require(`@/assets/appicons/${category.name.split(' ').join('')}.svg`)}
        })
      } else {
        return DEVICE_DATA
      }
    },
    allowedCategories () {
      return this.categories.filter((category) => {
        return !this.isNotAllowedCategory(this.is_active_coverage_restrictions, this.coverage_restriction_details, category.name, category.id)
      })
    }
  },
  async mounted () {
    await this.getUserInformation()
    await this.getUpgradedCoverage()
    await this.getActiveContract()
    await this.getContractLineItemsDeviceRestrictions()
    if (this.useDeviceRestrictions) {
      await this.getAllItemSpecifications()
      await this.getItemPurchaseConditions()
      await this.getMobileCarriers()
    }
  },
  methods: {
    ...mapActions([
      'getUserInformation',
      'getActiveContract',
      'getContractLineItemsDeviceRestrictions',
      'getAllItemSpecifications',
      'getItemPurchaseConditions',
      'getMobileCarriers'
    ]),
    chooseCategory (category) {
      if (this.useDeviceRestrictions) {
        this.$router.push({
          name: 'Add Device V2',
          params: {
            categoryData: category
          }
        })
      } else {
        this.$router.push({
          name: 'Add Device',
          params: {
            config: category,
            category: category.name,
            types: category.types,
            brands: category.brands,
            models: category.models,
            coverageUpgrade: this.coverageUpgrade
          }
        })
      }
    },
    isNotAllowedCategory (isActiveRestrictions, coverageRestrictionDetails, categoryName, categoryId = '') {
      if (this.useDeviceRestrictions) {
        let category = this.itemRestrictions.item_categories.find((category) => {
          return category.id === categoryId
        })
        if (!category) return false;
        return category?.quantity_allowed === 0 || category?.remainder === 0
      }
      if (!coverageRestrictionDetails) return false
      return coverageRestrictionDetails.findIndex(restriction => {
        return (
          isActiveRestrictions &&
          restriction.active && !restriction.include && !!restriction.item_category_id &&
          restriction.item_category_name.toLowerCase() === categoryName.toLowerCase()
        )
      }) >= 0
    },
    isNotVisibleCategory (isActiveRestrictions, coverageRestrictionDetails, categoryName) {
      if (!coverageRestrictionDetails || this.useDeviceRestrictions) return false
      return coverageRestrictionDetails.findIndex(restriction => {
        return (
          isActiveRestrictions &&
          restriction.active && !restriction.visible && !!restriction.item_category_id &&
          restriction.item_category_name.toLowerCase() === categoryName.toLowerCase()
        )
      }) >= 0
    },
    chooseNotCoveredCategory () {
      this.showNotCoveredModal = true
    },
    resetState () {
      this.showNotCoveredModal = false
    },
    updateCoverage (coverageId = this.selectedPhoneModelCoverage.id) {
      this.$store.dispatch('updateCoverage', {coverage_id: coverageId})
      this.resetState()
    },
    async getUpgradedCoverage () {
      const res = await axios.get(`${process.env.VUE_APP_API_URL}/coverages/${this.$store.state.user.coverage_plan_type || 'adult'}/${this.$store.state.user.coverage_plan_interval}/`)
      this.coverageUpgrade = res.data
    },
    debounceMethod: _.debounce(function (e) {
      e()
    }, 3000, {'leading': true})
  },
}
</script>

<style scoped>
.flex-basis-4 {
  flex-basis: 16em;
}

.justify-evenly {
  justify-content: space-evenly;
}

.account-card {
  border-radius: 5px;
  border: 1px solid #E8E8E8;
}


.filter-shadow {
  filter: drop-shadow(0px 1px 1px rgb(0 0 0 / 0.3));
}
.title-responsive {
    margin-top: 1.5rem;
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}
.responsive-card {
    position: relative;
  }

.color-text {
  color: #575757;
}


.flex-basis-3 {
  flex-basis: 12em;
}

.text-responsive-not{
  display: none;
}

.not-allowed-background {
  background-color: #cacaca;
}

@media (max-width: 1280px) {
  .card-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr;
  }

  .account-card {
    width: 100%;
    border: none;
    border-bottom: 1px solid #E8E8E8;
    border-radius: 0px;
    margin-bottom: 0px;
    padding: 1.9rem 0rem;
  }

  .account-card:last-child {
    border-bottom: 0px solid #E8E8E8;
  }


  .width-responsive-icon {
    width: 53px;
  }


  .text-responsive{
    display: none;
  }
  .text-responsive-not {
    display: block;
  }


  .title-responsive {
    font-size: 24px;
    margin-bottom: -10px;
    margin-top: 1rem;
  }
}
</style>
