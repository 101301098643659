
export const USER_WEB_BRANDING = 'user_web.branding'
export const ALLOW = 'ALLOW'
export const DISALLOW = 'DISALLOW'

export function selectPartnerPermissionValueByScope (permissions, scope) {
  const permission = permissions.find((p) => p.scope === scope)
  return permission ? permission.value : null
}

export function brandingAllowed (partner) {
  return partner && partner.partner_permissions && selectPartnerPermissionValueByScope(partner.partner_permissions, USER_WEB_BRANDING) === ALLOW
}
