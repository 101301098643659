<template>
  <img
    alt="AKKO Genius"
    class="integration-logo"
    src="@/assets/icons/Akko-Genius-Concierge-Logo.png"
  >
</template>

<script>
export default {
  name: 'GenuisCPRLogo'
  // TODO UPLOAD REAL LOGO
}
</script>

<style scoped>
img {
  max-width: unset;
}

.integration-logo {
  height: 5em;
}

@media screen and (max-width: 600px) {
  .integration-logo {
    height: 3.3em;
  }
}

@media screen and (max-width: 400px) {
  .integration-logo {
    height: 2.5em;
  }
}

</style>
