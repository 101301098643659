<template>
  <div
    v-if="shouldShowCreditCardModal"
    class="row responsive-column mv2 desktop modal-opened-row"
  >
    <span class="elementLeft modal-opened-row-text">Update Payment Method</span>
    <div class="modal">
      <form
        slot="modal-body"
        class="flex-column"
      >
        <div class="flex flex-row mt3 mb3 flex-grow">
          <div class="flex-grow firstname-box">
            <label
              class="f5 fw5 db mb2 silver label"
              for="first_name"
            >FIRST NAME</label>
            <input
              id="first_name"
              v-model="cardFirstName"
              v-focus
              class="input-reset ba b--black-20 pa2 db w-100 br5"
              placeholder="First Name"
              required
              type="text"
            >
          </div>
          <div class="flex-grow lastname-box">
            <label
              class="f5 fw5 db mb2 silver label"
              for="last_name"
            >LAST NAME</label>
            <input
              id="last_name"
              v-model="cardLastName"
              class="input-reset ba b--black-20 pa2 db w-100 br5"
              placeholder="Last Name"
              required
              type="text"
            >
          </div>
        </div>

        <div class="mt3 mb3 flex-grow">
          <label class="f5 fw5 db mb2 silver label">CREDIT CARD NUMBER</label>
          <div
            id="card-number"
            :class="cardErrorMessage ? 'border-red': ''"
            class="input-reset ba b--black-20 pa2 db w-100 br5"
          />
          <div class="inter f6 mt1 color-theme">
            {{ cardErrorMessage }}
          </div>
        </div>

        <div class="mt3 mb3 flex-grow">
          <label
            class="f5 fw5 db mb2 silver label"
            for="emailAddress"
          >EMAIL ADDRESS</label>
          <input
            id="emailAddress"
            v-model="emailAddress"
            :class="emailError && unfocusedEmail ? 'border-red': ''"
            class="input-reset ba b--black-20 pa2 db w-100 br5"
            placeholder="Enter Email Address"
            required
            type="email"
            @blur="unfocusedEmail = true"
            @focus="unfocusedEmail = false"
          >
          <div
            :class="emailError ? '' : 'invisible'"
            class="color-theme f6 mt2 w-100 mb2"
          >
            {{ emailError && unfocusedEmail ? 'Please enter a valid email' : '' }}
          </div>
        </div>

        <div class="flex flex-row mt3">
          <div class="expiry-box w-50 flex-grow">
            <label class="f5 fw5 db mb2 silver label">EXPIRY DATE</label>
            <div
              id="card-expiry"
              class="input-reset ba b--black-20 pa2 db w-100 br5"
            />
          </div>

          <div class="cvc-box w-50 flex-grow">
            <label class="f5 fw5 db mb2 silver label">CVC NUMBER</label>
            <div
              id="card-cvc"
              class="input-reset  ba b--black-20 pa2 db w-100 br5"
            />
          </div>
        </div>
      </form>
      <div class="mv4 flex modal-footer justify-between">
        <div class="mr2 flex-grow">
          <ak-button
            block
            class="inter cancel fw5 modal-button"
            secondary
            @click.native="resetState"
          >
            Cancel
          </ak-button>
        </div>
        <div class="flex-grow mb-3-ns ml2">
          <ak-button
            :disabled="!canAddPaymentMethod || !isDataFilled"
            block
            custom-class="inter fw5 modal-button"
            primary
            @click.native="updatePaymentMethod"
          >
            Update
          </ak-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AkButton from '@/components/Button.vue'
import {GET_PAYMENT_DATA, SET_PAYMENT_DATA, SET_REGISTRATION_PRIMARY_EMAIL} from '../../../../store/registrationv2'
import {mapGetters, mapMutations} from 'vuex'
import {checkEmailFormat} from '../../../../utils'
import {isEmpty} from 'lodash'

export default {
  name: 'UpdatePaymentMethodModal',
  components: {
    AkButton
  },
  props: {
    showCreditCardModal: {
      type: Boolean,
      default: false
    },
    resetState: {
      type: Function,
      default: () => {
      }
    },
    canAddPaymentMethod: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cardNumber: '',
      emailAddress: '',
      cardFirstName: '',
      cardLastName: '',
      cardCvc: '',
      cardExpiry: '',
      cardInfoCompleted: false,
      cardErrorMessage: '',
      cardInfo: {
        cardNumber: false,
        cardCvc: false,
        cardExpiry: false
      },
      isCreditCard: true,
      unfocusedEmail: true,
      shouldShowCreditCardModal: this.showCreditCardModal,
    }
  },
  mounted () {
    this.initStripe()
  },
  beforeDestroy () {
    this.cardNumber.destroy()
    this.cardExpiry.destroy()
    this.cardCvc.destroy()
  },
  computed: {
    ...mapGetters({
      paymentData: GET_PAYMENT_DATA
    }),
    user () {
      return this.$store.state.user
    },
    stripeElements () {
      return this.$stripe.elements()
    },
    emailError () {
      return checkEmailFormat(this.emailAddress.trim()) && this.emailAddress !== ''
    },
    isBankDataFilled () {
      return !isEmpty(this.paymentData())
    },
    isDataFilled () {
      return this.cardInfoCompleted && this.emailAddress.trim() !==
        '' && !this.emailError && this.cardFirstName.trim() !== '' && this.cardLastName.trim() !== ''
    }
  },
  methods: {
    ...mapMutations({
      setPaymentData: SET_PAYMENT_DATA,
      setPrimaryEmail: SET_REGISTRATION_PRIMARY_EMAIL
    }),
    initStripe () {
      const style = {
        base: {
          '::placeholder': {
            color: '#c8c8c8'
          }
        },
        invalid: {
          color: '#f3103d',
          iconColor: '#f3103d'
        }
      }
      this.cardNumber = this.stripeElements.create('cardNumber', {style})
      this.cardNumber.mount('#card-number')
      this.cardExpiry = this.stripeElements.create('cardExpiry', {style})
      this.cardExpiry.mount('#card-expiry')
      this.cardCvc = this.stripeElements.create('cardCvc', {style})
      this.cardCvc.mount('#card-cvc')
      this.cardNumber.on('change', this.enableForm)
      this.cardExpiry.on('change', this.enableForm)
      this.cardCvc.on('change', this.enableForm)
    },
    setCreditCardFilled () {
      this.cardInfoCompleted = this.cardInfo.cardNumber && this.cardInfo.cardCvc && this.cardInfo.cardExpiry
      return this.cardInfoCompleted
    },
    enableForm: function (event) {
      this.cardInfo[event.elementType] = !!event.complete
    },
    async updatePaymentMethod () {
      if (this.isCreditCard) {
        const token = await this.$stripe.createToken(this.cardNumber)
        this.$store.commit('SET_LOADING', true)
        if (token.error) {
          this.cardErrorMessage = token.error.message
        } else {
          this.cardErrorMessage = ''
          const stripeToken = token.token.id
          this.setPaymentData({
            accountMetadata: this.paymentData.accountMetadata,
            'stripe_token': token,
            'card_first_name': this.cardFirstName,
            'card_last_name': this.cardLastName,
            'public_token': undefined,
            'account_id': undefined,
            'plaid_client_id': undefined,
            method: 'CREDIT_DEBIT_CARD'
          })

          const tokenPayload = {'stripe_token': stripeToken}
          await this.$store.dispatch('updateCreditCard', tokenPayload)
          const profilePayload = {}
          if (this.cardLastName.trim() !== '') {
            const updatedLastName = this.cardLastName.trim()
            profilePayload.last_name = updatedLastName
            this.user.last_name = updatedLastName
          }
          if (this.cardFirstName.trim() !== '') {
            const updatedName = this.cardFirstName.trim()
            profilePayload.first_name = updatedName
            this.user.first_name = updatedName
          }
          if (this.emailAddress.trim() !== '') {
            const updatedEmail = this.emailAddress.trim()
            profilePayload.email = updatedEmail
            this.user.email = updatedEmail
            this.setPrimaryEmail(updatedEmail)
          }
          await this.$store.dispatch('updateProfile', profilePayload)
        }
        this.setPrimaryEmail(this.emailAddress)
        this.$store.commit('SET_LOADING', false)
        this.resetState()
      }

      // TODO: POST to backend bank
      this.$store.commit('SET_LOADING', false)
      this.shouldShowCreditCardModal = false
    }
  },
  watch: {
    'cardInfo.cardNumber': 'setCreditCardFilled',
    'cardInfo.cardCvc': 'setCreditCardFilled',
    'cardInfo.cardExpiry': 'setCreditCardFilled'
  }

}
</script>

<style scoped>

.modal-opened-row-text {
  margin-top: 2.75em;
}

.modal {
  width: 75%
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  border-radius: 5px;
  padding: 0 18px;
}

.modal-opened-row {
  align-items: flex-start;
}

.elementLeft {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  color: #757D8A;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757D8A;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #757D8A;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #757D8A;
}

main {
  /*padding-left: 1rem;*/
  margin-right: 1rem;
  margin-top: 1rem;
  padding-top: .25rem
}

::placeholder {
  color: #c8c8c8;
}

.br5 {
  border-radius: 5px;
}

.responsive-column {
  flex-direction: row;
}

.border-red {
  border-color: #f3103d;
}

.modal-footer {
  flex-direction: row;
}

.inter {
  font-family: 'Inter', sans-serif;
}

.StripeElement--focus {
  border-color: #f3103d;
}

.StripeElement--invalid {
  border-color: #f3103d;
}

.cancel {
  border-width: 1px;
}

input {
  border-radius: 5px;
}

.modal-button {
  height: 40px;
}

.content:last-child hr {
  display: none;
}

.payment-methods:last-child hr {
  display: none;
}

.cvc-box, .lastname-box {
  margin-left: 0.5rem;
}

.expiry-box, .firstname-box {
  margin-right: 0.5rem;
}

.label {
  color: #757D8A;
  font-size: 11px;
  font-weight: 700;
}

@media (max-width: 768px) {

  .modal-opened-row-text {
    margin-top: 1rem;
    color: black;
  }

  .row {
    padding: 0;
  }

  .responsive-column {
    flex-direction: column;
  }

  .modal {
    width: 100%;
  }

  .mb-3-ns {
    margin-bottom: 1rem;
  }

  form {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  main {
    padding-top: 0;
    padding-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

/* Radio Card */
label.radio-card {
  cursor: pointer;
  min-width: 240px;
}

label.radio-card .card-content-wrapper {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
  padding: 15px;
  display: grid;
  transition: 200ms linear;
}

label.radio-card .check-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  transition: 200ms linear;
  position: relative;
}

label.radio-card .check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url('@/assets/appicons/RedCheck.svg');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}

label.radio-card input[type=radio] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

label.radio-card input[type=radio] + .card-content-wrapper {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 1px #9ba1ab;
}

label.radio-card input[type=radio]:checked + .card-content-wrapper {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 1px #f3103d;
}

label.radio-card input[type=radio]:checked + .card-content-wrapper .check-icon:before {
  transform: scale(1);
  opacity: 1;
}
</style>
