<template>
  <main class="mw8-ns center-ns mb4 mb0-ns">
    <akko-header />
    <h1 class="f3 lh-copy pt5 pb2 mb3 tc mw7-ns center-ns mt5-ns cera-round-pro">
      Complete Your Profile
    </h1>
    <h4 class="f5 fw4 tc cera-round-pro color-theme">
      All fields required unless marked otherwise
    </h4>

    <div
      v-if="loading"
      class="fixed top-0 left-0 z-999 w-100 h-100 bg-white-50 fade flex justify-center items-center"
    >
      <div
        id="modal"
        class="ma2 mv4 bg-white mt5-ns mb6-ns ba b--light-gray br2 mw7 center-ns animated-slow slide-in"
        style="min-width: 300px"
      >
        <div class="flex pa3 flex-column justify-center items-center">
          <p class="inter f6 pv3">
            Processing activation...
          </p>
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#000"
            class=""
          >
            <g
              fill="none"
              fill-rule="evenodd"
            >
              <g
                transform="translate(1 1)"
                stroke-width="2"
              >
                <circle
                  stroke-opacity=".5"
                  cx="18"
                  cy="18"
                  r="18"
                />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div
      v-if="activationError.status === 400 && showModal"
      class="fixed top-0 left-0 z-999 w-100 flex justify-center items-center"
    >
      <div class="b-theme bg-theme w-100 borders">
        <div class="cera-round-pro-medium f5 mv3-l mt3 mb2 fw5 white justify-center db tc ph0-l ph3 relative">
          <p>Please check that all required fields are populated.</p>

          <svg
            :class="!isBigMobile ? 'closeIcon' : 'dn'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x-circle"
            @click="setShowModal(false)"
          ><circle
            cx="12"
            cy="12"
            r="10"
          /><line
            x1="15"
            y1="9"
            x2="9"
            y2="15"
          /><line
            x1="9"
            y1="9"
            x2="15"
            y2="15"
          /></svg>
          <div :class="isBigMobile ? 'flex justify-center ph3 mt2' : 'dn'">
            <ak-button
              new-secondary
              custom-class="bg-cream"
              @click.native="setShowModal(false)"
            >
              CLOSE
            </ak-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="activationError.status === 401"
      class="fixed top-0 left-0 z-999 w-100 h-100 bg-white-50 fade flex justify-center items-center"
    >
      <div
        id="modal"
        class="ma2 mv4 bg-white mt5-ns mb6-ns ba b--light-gray br2 mw7 center-ns animated-slow slide-in"
        style="min-width: 300px"
      >
        <div class="flex pa3 flex-column justify-center items-center">
          <p class="inter f6 pv3">
            Session expired. Redirecting to login...
          </p>
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#000"
            class=""
          >
            <g
              fill="none"
              fill-rule="evenodd"
            >
              <g
                transform="translate(1 1)"
                stroke-width="2"
              >
                <circle
                  stroke-opacity=".5"
                  cx="18"
                  cy="18"
                  r="18"
                />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div
      v-if="activationError.status === 409 && showModal"
      class="fixed top-0 left-0 z-999 w-100 flex justify-center items-center"
    >
      <div class="b-theme bg-theme w-100 borders">
        <div
          v-if="activationError.message"
          class="cera-round-pro-medium f5 mv3-l mt3 mb2 fw5 white justify-center db tc ph0-l ph3 relative"
        >
          <p>{{ activationError.message }}</p>
          <svg
            :class="!isBigMobile ? 'closeIcon' : 'dn'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x-circle"
          ><circle
            cx="12"
            cy="12"
            r="10"
          /><line
            x1="15"
            y1="9"
            x2="9"
            y2="15"
          /><line
            x1="9"
            y1="9"
            x2="15"
            y2="15"
          /></svg>
          <div :class="isBigMobile ? 'flex justify-center ph3 mt2' : 'dn'">
            <ak-button
              new-secondary
              custom-class="bg-cream"
              @click.native="setShowModal(false)"
            >
              CLOSE
            </ak-button>
          </div>
        </div>
        <div
          v-else
          class="cera-round-pro-medium f5 mv3-l mt3 mb2 fw5 white justify-center db tc ph0-l ph3 relative"
        >
          Phone number and/or email already in use. If the problem persists, please <span
            class="underline link dim pointer"
            @click="openIntercomChat"
          >contact support</span>.

          <svg
            :class="!isBigMobile ? 'closeIcon' : 'dn'"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x-circle"
          ><circle
            cx="12"
            cy="12"
            r="10"
          /><line
            x1="15"
            y1="9"
            x2="9"
            y2="15"
          /><line
            x1="9"
            y1="9"
            x2="15"
            y2="15"
          /></svg>
          <div :class="isBigMobile ? 'flex justify-center ph3 mt2' : 'dn'">
            <ak-button
              new-secondary
              custom-class="bg-cream"
              @click.native="setShowModal(false)"
            >
              CLOSE
            </ak-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="activationError.status === 500 && showModal"
      class="fixed top-0 left-0 z-999 w-100 h-100 bg-white-50 fade flex justify-center items-center"
    >
      <div class="fixed top-0 left-0 w-100 flex justify-center items-center">
        <div class="b-theme bg-theme w-100 borders">
          <div class="cera-round-pro-medium f5 mv3-l mt3 mb2 fw5 white justify-center db tc ph0-l ph3 relative">
            <div>
              Oops! Something went wrong on our end. Please try again shortly. If the problem persists, please <span
                class="underline link dim pointer"
                @click="openIntercomChat"
              >contact support</span>.
            </div>

            <svg
              :class="!isBigMobile ? 'closeIcon' : 'dn'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x-circle"
            ><circle
              cx="12"
              cy="12"
              r="10"
            /><line
              x1="15"
              y1="9"
              x2="9"
              y2="15"
            /><line
              x1="9"
              y1="9"
              x2="15"
              y2="15"
            /></svg>
            <div :class="isBigMobile ? 'flex justify-center ph3 mt2' : 'dn'">
              <ak-button
                new-secondary
                custom-class="bg-cream"
                @click.native="setShowModal(false)"
              >
                CLOSE
              </ak-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form>
      <div class="flex flex-column w-100 bg-white br3 new-shadow-theme mr5 h-100 custom-padding responsive-box">
        <div class="cera-round-pro-medium f4 mb4 fw5">
          Personal Details
        </div>
        <div class="mb3">
          <div class="flex flex-row-l flex-column items-start">
            <input
              id="fname"
              v-model="fname"
              v-focus
              class="inter f6 input-reset ba db mr3-l mb3 mb0-l br2 code-input"
              placeholder="First Name"
              type="text"
              autocomplete="given-name"
              @blur="submitName"
            >
            <input
              id="lname"
              v-model="lname"
              class="inter f6 input-reset ba db mr3-l mb3 mb0-l br2 code-input"
              placeholder="Last Name"
              type="text"
              autocomplete="family-name"
              @blur="submitName"
            >
            <div class="w-100">
              <input
                id="email"
                v-model="email"
                class="inter f6 input-reset ba db br2 code-input w-100"
                placeholder="Email"
                type="email"
                autocomplete="email"
                :readonly="isEmailSet"
                :disabled="isEmailSet"
                @blur="submitEmail"
              >
              <div
                v-if="emailError"
                class="red f7"
              >
                <span>Invalid email address</span>
              </div>
            </div>
          </div>
        </div>

        <div class="mb3">
          <div class="flex items-center responsive-column">
            <div class="w-70-l w-100 mr3 responsive-margin">
              <div class="mb3">
                <input
                  id="email_alt"
                  v-model="email_alt"
                  class="inter f6 input-reset ba db mr3 br2 code-input w-100 mb1"
                  placeholder="Alternate Email (Optional)"
                  type="email"
                  autocomplete="alternate email"
                  @blur="submitAltEmail"
                >
                <div
                  v-if="altEmailError"
                  class="red f7"
                >
                  <span>Invalid email address</span>
                </div>
              </div>
              <InputMask
                id="phone"
                v-model="phone"
                class="inter f6 input-reset ba db mr3 br2 code-input w-100 mt1 mb3"
                type="tel"
                mask="+1 (999) 999-9999"
                placeholder="Phone #"
                :readonly="isPhoneSet"
                :disabled="isPhoneSet"
                @blur="submitAltEmail"
              />
              <span
                v-show="phone !== '' && ![10, 11].includes(phone.replace(/[^0-9]/g, '').length)"
                class="red f7"
              >
                Please enter a valid phone number
              </span>
              <span
                v-show="phoneError"
                class="red f7"
              >
                A user with this phone number already exists.
                Please verify your entry or
                <a
                  class="color-theme underline dib"
                  href="mailto:support@getakko.com"
                >contact support</a>
              </span>
            </div>
            <div class="w-100">
              <div class="br3 bw1 b--silver bg-white flex flex-column main-container relative justify-around">
                <div class="cera-round-pro flex">
                  Birthday
                </div>
                <input
                  v-model="birthday"
                  type="date"
                  :max="todayString"
                  class="inter f6 input-reset ba db br2 code-input w-100"
                  @focus="toggleKeyboard(true)"
                  @blur="setSubmitDate()"
                  @change="setSubmitDate()"
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class=""
          :class="{'mb4': student === 'no' || !student, 'mb3': student === 'yes'}"
        >
          <div v-if="!isCoverageTypeStudent && !isYoung">
            <fieldset class="input-reset bn pa0 ma0">
              <legend class="b db mb2 cera-round-pro">
                Are you a student?
              </legend>
              <div class="flex justify-around w-100 w-50-l">
                <input
                  id="student-yes"
                  v-model="student"
                  class="dn"
                  type="radio"
                  :value="'yes'"
                  name="student"
                  @change="submitStudentData"
                >
                <label
                  tabindex="0"
                  for="student-yes"
                  class="flex-grow pv2 tc inter b ba br1 br--left b--silver silver bg-white pointer left-button"
                  @keyup.enter="student='yes'"
                >Yes</label>
                <input
                  id="student-no"
                  v-model="student"
                  class="dn"
                  type="radio"
                  :value="'no'"
                  name="student"
                  @change="submitStudentData"
                >
                <label
                  tabindex="0"
                  for="student-no"
                  class="flex-grow pv2 inter tc b ba bl-0 br1 br--right b--silver silver bg-white pointer right-button"
                  @keyup.enter="student='no'"
                >No</label>
              </div>
            </fieldset>
          </div>
          <div
            v-if="(student === 'yes' || isCoverageTypeStudent) && !isYoung"
            class="flex justify-between flex-column "
          >
            <div class="mt3 fw5 cera-round-pro-medium f5">
              School Details
            </div>

            <div class="flex flex-column flex-row-l">
              <input
                id="school"
                v-model="school"
                class="inter f6 input-reset ba db mr3-l  mt3 mb3 mb0-l br2 code-input w-50-l w-100"
                placeholder="School Name"
                type="text"
                @blur="submitStudentData"
              >
              <div class="flex justify-between flex-column flex-row-l w-50-l w-100">
                <div class="flex flex-row input mt3-l mt0 schools mb3 mb0-l">
                  <select
                    id="schoolType"
                    v-model="schoolType"
                    type="text"
                    class="ba pl2 mb2-l mb3 w-100 inter black-70 f7 select z-1 bg-transparent "
                    @blur="submitStudentData"
                  >
                    <option
                      disabled
                      value=""
                    >
                      Select school type
                    </option>
                    <option value="college">
                      College
                    </option>
                    <option value="graduate">
                      Graduate School
                    </option>
                    <option value="law">
                      Law School
                    </option>
                    <option value="med">
                      Med School
                    </option>
                    <option value="mba">
                      MBA
                    </option>
                    <option value="other">
                      Other
                    </option>
                  </select>
                  <arrow-icon class="absolute z-0" />
                </div>

                <div class="flex flex-row input mt3-l mt0 schools">
                  <select
                    v-if="schoolType === 'middle'"
                    id="schoolYear"
                    v-model="schoolYear"
                    type="text"
                    class="ba pl2 mb2 w-100 inter black-70 f7 select z-1 bg-transparent "
                    @blur="submitStudentData"
                  >
                    <option
                      disabled
                      value=""
                    />
                    <option value="6th">
                      6th grade
                    </option>
                    <option value="7th">
                      7th grade
                    </option>
                    <option value="8th">
                      8th grade
                    </option>
                  </select>
                  <select
                    v-else
                    id="schoolYear"
                    v-model="schoolYear"
                    type="text"
                    class="ba pl2 mb2 w-100 inter black-70 f7 select z-1 bg-transparent "
                    @blur="submitStudentData"
                  >
                    <option
                      disabled
                      value=""
                    >
                      Select school year
                    </option>
                    <option value="1st">
                      1st
                    </option>
                    <option value="2nd">
                      2nd
                    </option>
                    <option value="3rd">
                      3rd
                    </option>
                    <option value="4th">
                      4th
                    </option>
                    <option
                      v-if="schoolType !== 'high'"
                      value="5th"
                    >
                      5th
                    </option>
                    <option
                      v-if="schoolType !== 'high'"
                      value="6th"
                    >
                      6th
                    </option>
                    <option
                      v-if="schoolType !== 'high' && schoolType !== 'college'"
                      value="7th"
                    >
                      7th
                    </option>
                    <option
                      v-if="schoolType !== 'high' && schoolType !== 'college'"
                      value="8th"
                    >
                      8th
                    </option>
                  </select>
                  <arrow-icon class="absolute z-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="(student === 'yes' || isCoverageTypeStudent) && !isYoung"
          class="mb4-l mb3 mt4-l mt3"
        >
          <p class="b db mb3 cera-round-pro">
            Please upload proof of your student status (school ID, unofficial transcript, receipt of tuition paid, or class schedule)
          </p>
          <div
            class="pv5 w-100 tc round-image"
            :class="image_file_name ? 'bg-washed-green' : 'bg-washed-red'"
          >
            <input
              id="file"
              class="input-reset dn"
              type="file"
              accept="image/*, .pdf,.heic,.heif,.HEIC,.HEIF"
              @change="processImage($event)"
              @blur="submitStudentData"
            >
            <label
              for="file"
              class="dib fw5 pv2 ph5 br-pill pointer ba bw1  white "
              :class="image_file_name ? 'bg-green b-green' : 'bg-theme b-theme'"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="18"
                viewBox="0 0 20 18"
                class="v-sub mr1"
                fill="white"
              >
                <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
              </svg>
              <span class="white">{{ image_file_name ? 'File Selected' : 'Upload Transcript/ID' }}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="flex flex-column w-100 bg-white br3 new-shadow-theme mr5 h-100 custom-padding responsive-box">
        <div class="cera-round-pro-medium f4 mb4 fw5">
          Current Address
          <br>
        </div>
        <div class="mb3-l mb0">
          <div class="flex flex-row-l flex-column">
            <div class="flex flex-row input w-20-l w-100 mr3-l mb3 mb0-l">
              <select
                id="country"
                v-model="country"
                type="text"
                class="ba pl2 mb2 w-100  inter black-70 f7 select z-1 bg-transparent"
              >
                <option
                  disabled
                  value=""
                >
                  Select country
                </option>
                <option value="US">
                  United States
                </option>
                <option value="CA">
                  Canada
                </option>
                <option value="KE">
                  Kenya
                </option>
              </select>
              <arrow-icon class="absolute z-0" />
            </div>

            <input
              id="street"
              v-model="street"
              class="inter f6 input-reset ba db mr3-l mb3 mb0-l br2 code-input"
              placeholder="Street"
              type="text"
              autocomplete="address-line1"
              :disabled="country === ''"
              @blur="submitAddress"
            >

            <input
              id="apt"
              v-model="apt"
              class="inter f6 input-reset ba db mr3-l mb3 mb0-l br2 code-input"
              placeholder="Unit #"
              type="text"
              autocomplete="address-line2"
              :disabled="country === ''"
              @blur="submitAddress"
            >

            <input
              id="city"
              v-model="city"
              class="inter f6 input-reset ba db mr3-l mb3 mb0-l br2 code-input"
              placeholder="City"
              type="text"
              autocomplete="address-level2"
              :disabled="country === ''"
              @blur="submitAddress"
            >
          </div>
        </div>

        <div class="mb3-l mb0">
          <div class="flex flex-row-l flex-column">
            <input
              id="state"
              v-model="state"
              class="inter f6 input-reset ba db mr3-l mb3 mb0-l br2 code-input"
              placeholder="State/province"
              type="text"
              autocomplete="address-level1"
              :disabled="country === ''"
              @blur="submitAddress"
            >

            <div
              v-if="country !== 'KE'"
              class="flex flex-column"
            >
              <input
                id="zip"
                v-model="zip"
                maxlength="10"
                class="inter f6 input-reset ba db mr3-l mb3 mb0-l br2 code-input"
                placeholder="ZIP Code"
                type="text"
                autocomplete="postal-code"
                :disabled="country === ''"
                @blur="submitAddress"
              >
              <div
                v-if="zipCodeError && zip.length !== 0"
                class="red mt1"
              >
                <h6>Invalid ZIP code</h6>
              </div>
            </div>

            <div
              v-if="!isYoung"
              class="flex flex-row input w-20-l w-100"
            >
              <select
                id="res_type"
                v-model="res_type"
                type="text"
                class="ba pl2 mb2 w-100 inter black-70 f7 select z-1 bg-transparent"
                :disabled="country === ''"
                @blur="submitAddress"
              >
                <option
                  disabled
                  value=""
                >
                  Select residence type
                </option>
                <option value="apartment">
                  Apartment/Condo
                </option>
                <option value="house">
                  House
                </option>
                <option value="townhome">
                  Townhome
                </option>
              </select>
              <arrow-icon class="absolute z-0" />
            </div>
          </div>
        </div>

        <div class="mb3-l mb0">
          <div class="">
            <div v-if="!isYoung">
              <fieldset class="input-reset bn pa0 ma0">
                <legend class="b db cera-round-pro mb3 pt3">
                  Do you rent or own the property?
                </legend>
                <div class="flex justify-around w-100 w-50-l">
                  <input
                    id="rent_own-rent"
                    v-model="rent_own"
                    class="dn"
                    type="radio"
                    :value="'RENT'"
                    name="rent_own"
                    @change="submitAddress"
                  >
                  <label
                    tabindex="0"
                    for="rent_own-rent"
                    class="flex-grow inter pv2 tc b ba br1 br--left b--silver silver bg-white pointer left-button"
                    @keyup.enter="rent_own='rent'"
                  >Rent</label>
                  <input
                    id="rent_own-own"
                    v-model="rent_own"
                    class="dn"
                    type="radio"
                    :value="'OWN'"
                    name="rent_own"
                    @change="submitAddress"
                  >
                  <label
                    tabindex="0"
                    for="rent_own-own"
                    class="flex-grow inter pv2 tc b ba bl-0 br1 br--right b--silver silver bg-white pointer right-button"
                    @keyup.enter="rent_own='own'"
                  >Own</label>
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div class="cera-round-pro-medium f4 mb4 mt3 fw5">
          Other info
          <br>
        </div>

        <div
          v-if="!isYoung"
          class="flex flex-row-l flex-column mb3-l mb0"
        >
          <div class="input-reset bn pa0 ma0 w-50-l w-100">
            <label
              for="business-yes"
              class="b db mb2 cera-round-pro"
            >Do you own a business/are you a sole proprietor?</label>

            <div class="flex flex-row input mb3 mb0-l">
              <select
                id="business-yes"
                v-model="isBusiness"
                type="text"
                class="ba pl2 mb2 w-100 inter black-70 f7 select z-1 bg-transparent"
                @blur="submitAddress"
              >
                <option
                  disabled
                  value=""
                >
                  Select
                </option>
                <option value="yes">
                  Yes
                </option>
                <option value="no">
                  No
                </option>
              </select>
              <arrow-icon class="absolute z-0" />
            </div>
          </div>
          <div
            v-if="isBusiness === 'yes'"
            class="flex flex-column input-reset bn pa0 ma0 ml3-l ml0 w-50-l w-100"
          >
            <label
              v-if="!isMobile"
              for="business-yes"
              class="mb2 o-0"
            >Do you own a business/are you a sole proprietor?</label>
            <input
              id="businessName"
              v-model="businessName"
              class="inter f6 input-reset ba db mr3-l mb0-l mb3 br2 code-input"
              placeholder="Business Name"
              type="text"
              @change="submitAddress"
            >
          </div>
        </div>

        <div
          v-if="isUserPrimaryAccountHolder && !isYoung"
          class="flex flex-row-l flex-column mb3-l mb0"
        >
          <div class="input-reset bn pa0 ma0 w-50-l w-100">
            <label
              for="res_type"
              class="b db mb2 cera-round-pro"
            >Are you switching from another protection plan provider?</label>

            <div class="flex flex-row input mb3 mb0-l">
              <select
                id="res_type"
                v-model="comesFromOtherProvider"
                type="text"
                class="ba pl2 mb2 w-100 inter black-70 f7 select-big z-1 bg-transparent"
                @blur="submitPreviousProvider"
              >
                <option
                  disabled
                  value=""
                >
                  Select
                </option>
                <option value="yes">
                  Yes
                </option>
                <option value="no">
                  No
                </option>
              </select>
              <arrow-icon class="absolute z-0" />
            </div>
          </div>

          <div
            v-if="comesFromOtherProvider === 'yes'"
            class="flex flex-column input-reset bn pa0 ma0 ml3-l ml0 w-50-l w-100"
          >
            <label
              v-if="!isMobile"
              for="res_type"
              class="mb2 o-0"
            >Are you switching from another protection plan provider?</label>

            <input
              id="prevProviderName"
              v-model="prevProviderName"
              class="inter f6 input-reset ba db mr3-l mb3 mb0-l br2 code-input"
              placeholder="Previous protection plan name"
              type="text"
              @change="submitPreviousProvider"
            >
          </div>
        </div>

        <div
          v-if="isUserPrimaryAccountHolder && !userFromPartners"
          class="flex flex-row-l flex-column mb3-l mb0"
        >
          <div class="input-reset bn pa0 ma0 w-50-l w-100">
            <label
              for="hear_about_us"
              class="b db mb2 cera-round-pro"
            >How did you hear about us?</label>
            <div class="flex flex-row input mb3 mb0-l">
              <select
                id="hear_about_us"
                v-model="hear_about_us"
                type="text"
                class="ba pl2 mb2 w-100 inter black-70 f7 select-big z-1 bg-transparent"
                @blur="submitHearAboutUs"
              >
                <option
                  disabled
                  value=""
                >
                  Select
                </option>
                <option value="GOOGLE">
                  Google
                </option>
                <option value="FACEBOOK">
                  Facebook
                </option>
                <option value="INSTAGRAM">
                  Instagram
                </option>
                <option value="SPOTIFY">
                  Spotify
                </option>
                <option value="TIKTOK">
                  TikTok
                </option>
                <option value="YOUTUBE">
                  YouTube
                </option>
                <option value="EMAIL">
                  Email
                </option>
                <option value="FRIEND_OR_FAMILY">
                  A friend or family member
                </option>
                <option value="OTHER">
                  Other
                </option>
              </select>
              <arrow-icon class="absolute z-0" />
            </div>
          </div>

          <div
            v-if="hear_about_us === 'OTHER'"
            class="flex flex-column input-reset bn pa0 ma0 ml3-l ml0 w-50-l w-100"
          >
            <label
              v-if="!isMobile"
              for="hear_about_us_other"
              class="mb2 o-0"
            >Type answer here</label>
            <input
              id="hear_about_us_other"
              v-model="hear_about_us_other"
              class="inter f6 input-reset ba db mr3-l mb0-l mb3 br2 code-input"
              type="text"
              placeholder="Type answer here"
              :maxlength="400"
              @blur="submitHearAboutUs"
            >
          </div>
        </div>

        <div class="mv3">
          <input
            id="accept"
            v-model="accept"
            type="checkbox"
            class="di"
          >
          <label
            for="accept"
            class="f6 fw5 di mb2 lh-copy inter fw4"
          >
            I agree that I have thoroughly read, understand, and accept the
            <a
              href="https://getakko.com/legal"
              target="_blank"
              class="color-theme"
            >terms of service &amp; conditions</a>
            as well as the <a
              href="https://getakko.com/coverage-info-faqs/"
              target="_blank"
              rel="noopener"
              class="color-theme"
            >AKKO website's FAQs</a> about plan protections and claims.
            I also acknowledge and agree to receive and view all plan documentation, including Terms and Conditions and Plan Confirmation (if any), electronically as well as receive automated transactional messages via email and SMS in accordance with AKKO's Privacy Policy.
          </label>
        </div>
      </div>
    </form>

    <div
      v-if="devMode"
      class="tc mv4"
    >
      <button @click="testForm">
        Test Form
      </button>
    </div>

    <div class="mt4 mb6 ph6-ns center-ns">
      <!-- <router-link to='/account-activation/step3' class="no-underline"> -->
      <ak-button
        :disabled="!stepComplete"
        primary
        block
        class="inter"
        @click.native="submit"
      >
        Complete Your Profile
      </ak-button>
      <!-- </router-link> -->
    </div>
  </main>
</template>

<script>
import AkkoHeader from '../AkkoHeader.vue'
import ArrowIcon from '../registration/multiplePhonePlan/icons/ArrowIcon.vue'
import AkButton from '@/components/Button'
import InputMask from 'primevue/inputmask'
import * as Sentry from '@sentry/vue'
import {checkEmailFormat} from '@/utils'

const d = new Date()
const year = d.getFullYear()
const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`
const day = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`
const today = `${year}-${month}-${day}`

export default {
  name: 'AkPersonalInfo',
  components: {
    AkkoHeader,
    ArrowIcon,
    AkButton,
    InputMask
  },
  data () {
    return {
      // state.school: '',
      loading: false,
      activationError: '',
      showModal: false,
      fname: '',
      lname: '',
      email: '',
      email_alt: '',
      phone: '',
      birthday: '',
      dob_day: '',
      dob_month: '',
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'],
      dob_year: '',
      street: '',
      apt: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      res_type: '',
      rent_own: '',
      hear_about_us: '',
      hear_about_us_other: '',
      student: '',
      school: '',
      image_file: null,
      image_file_name: '',
      isStudentCoverage: false,
      businessName: '',
      isStudentSwitchVisible: false,
      isBusiness: '',
      schoolYear: '',
      schoolType: '',
      comesFromOtherProvider: '',
      prevProviderName: '',
      coverage_plan_type: null,
      accept: false,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    todayString () {
      return today
    },
    isMobile () {
      return this.windowWidth <= 960
    },
    isBigMobile () {
      return this.windowWidth <= 1050
    },
    phoneError () {
      return this.$store.state.registration.phone_error && !this.isPhoneSet
    },
    zipCodeError () {
      return this.zip.length === 0
     // return ![5, 6, 10].includes(this.zip.length)
    },
    getRightDate () {
      return this.birthday.split('-')
    },
    getRightMonth () {
      return this.months[this.getRightDate[1] - 1]
    },
    altEmailError () {
      return checkEmailFormat(this.email_alt.trim())
    },
    emailError () {
      return checkEmailFormat(this.email.trim())
    },
    stepComplete () {
      return this.accept &&
      !this.zipCodeError &&
      this.zip.length !== 0 &&
      !this.altEmailError &&
      !this.emailError &&
      this.fname.trim() !== '' &&
      this.lname.trim() !== '' &&
      !this.phoneError &&
      [10, 11].includes(this.phone.replace(/[^0-9]/g, '').length) &&
      this.birthday !== '' &&
      this.dob_day !== '' &&
      this.dob_month.trim() !== '' &&
      this.dob_year !== '' &&
      this.street.trim() !== '' &&
      this.city.trim() !== '' &&
      this.state.trim() !== '' &&
      (this.isYoung || this.res_type !== '') &&
      (this.rent_own || this.isYoung) &&
      (this.student === 'no' || !this.student || (this.student === 'yes' && !this.isYoung && this.school !== null && this.school.trim() !== '' && this.schoolType !== '' && this.schoolYear !== '') || (this.student === 'yes' && this.isYoung)) &&
      ((this.isBusiness === 'select' || this.isBusiness === '') && this.isYoung || (this.isBusiness === 'no' || !this.isBusiness || this.isBusiness === 'yes' && this.businessName.trim() !== '')) &&
      ((this.comesFromOtherProvider === 'select' || this.comesFromOtherProvider === '') && this.isYoung || (!this.isUserPrimaryAccountHolder || (this.comesFromOtherProvider === 'no' || this.comesFromOtherProvider === 'yes' && this.prevProviderName.trim() !== ''))) &&
      (!this.isUserPrimaryAccountHolder || (this.hear_about_us.trim() !== '') || this.userFromPartners)
    },
    isYoung () {
      const today = new Date()
      const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      let dateOfBirth = new Date(this.dob_year, Months.indexOf(this.dob_month), this.dob_day)
      const ageDate = new Date(today - dateOfBirth)

      return Math.abs(ageDate.getUTCFullYear() - 1970) <= 17
    },
    devMode () {
      return process.env.NODE_ENV === 'development'
    },
    isEmailSet () {
      return !!this.$store.state.user.email
    },
    isPhoneSet () {
      return !!this.$store.state.user.phone_no
    },
    userFromPartners () {
      return !!(this.$store.state.user.partner_property || this.$store.state.user.sales_rep_id)
    },
    isCoverageTypeStudent () {
      return this.coverage_plan_type === 'student'
    },
    isUserPrimaryAccountHolder () {
      try {
        return this.$store.state.user.user_id === this.$store.state.user.primary_account_holder.id
      } catch (e) {
        // Capturing additional dat for future troubleshooting
        Sentry.setUser({ userId: this.$store.state.user.user_id })
        Sentry.captureException(e)
        return false
      }
    }
  },
  watch: {
    isEmailSet () {
      this.email = this.$store.state.user.email
    },
    isPhoneSet () {
      this.phone = this.$store.state.user.phone_no
    },
    '$store.state.activation.loading': 'isLoading',
    '$store.state.activation.errors': 'isActivationError',
    '$store.state.activation.success': 'isSucceed',
    birthday: 'setDate',
    country: 'updateAddress'
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    if (!this.isMobile) {
      this.$intercom.boot({})
    }
    window.fbq('trackCustom', 'Personal Info')
    const user = this.$store.state.user
    this.email = user.email ? user.email : ''
    this.phone = user.phone_no
    this.coverage_plan_type = user.coverage_plan_type
    const activation = this.$store.state.activation
    this.fname = activation.fname
    this.lname = activation.lname
    this.email_alt = activation.email_alt
    this.dob_day = activation.dateOfBirth.day
    this.dob_month = activation.dateOfBirth.month
    this.dob_year = activation.dateOfBirth.year
    this.street = activation.address.street
    this.apt = activation.address.apt
    this.city = activation.address.city
    this.state = activation.address.state
    this.zip = activation.address.zip
    this.res_type = activation.address.res_type
    this.rent_own = activation.address.rent_own
    this.hear_about_us = activation.hear_about_us.how
    this.hear_about_us_other = activation.hear_about_us.other
    this.isStudentSwitchVisible = user.coverage_plan_type === 'adult' || user.coverage_item_covered === 'PHONE'
    this.student = user.coverage_plan_type === 'student' ? 'yes' : 'no'
    this.school = activation.studentVerification.school || user.school
    this.image_file = activation.studentVerification.school_verification_file
    this.isBusiness = activation.address.business_name !== '' ? 'yes' : 'select'
    this.businessName = activation.address.business_name
    this.schoolType = activation.studentVerification.type
    this.schoolYear = activation.studentVerification.year
    this.comesFromOtherProvider = activation.switching_protection ? 'yes' : 'select'
    this.prevProviderName = activation.past_protection_provider
    this.accept = false

    this.$store.dispatch('getUserInformation')
    const token = this.$route.query.token
    if (token) {
      this.$store.dispatch('confirmEmail', {token: token})
    }
    this.submitAltEmail()
    this.submitEmail()
  },
  destroyed () {
    this.$store.dispatch('updateLead')
  },
  methods: {
    updateAddress () {
      this.apt = ''
      this.street = ''
      this.city = ''
      this.state = ''
      this.zip = ''
      const google = window.google
    /* eslint-disable no-new */
    /* eslint-disable no-unused-vars */
      let binder = document.getElementById('street')
      const addressAutocomplete = new google.maps.places.Autocomplete(
      binder, {
        types: ['address'],
        fields: ['address_components']
      })
      addressAutocomplete.setComponentRestrictions({country: [this.country]})
      addressAutocomplete.addListener('place_changed', () => {
        let place = {
          address_components: [],
          ...addressAutocomplete.getPlace()
        }

        this.street = `${addressAutocomplete.getPlace().address_components[0].long_name} ${addressAutocomplete.getPlace().address_components[1].long_name}`
        addressAutocomplete.getPlace().address_components.forEach((component) => {
          component.types.forEach((type) => {
            if (type === 'locality') {
              this.city = component.long_name
            }
            if (type === 'postal_code') {
              this.zip = component.long_name
            }
            if (type === 'administrative_area_level_1') {
              this.state = component.short_name
            }
          })
          this.submitAddress()
        })
      })
      google.maps.event.clearInstanceListeners(binder)
      return
    },
    setDate () {
      this.dob_day = parseInt(this.getRightDate[2])
      this.dob_month = this.getRightMonth
      this.dob_year = parseInt(this.getRightDate[0])
    },
    setSubmitDate () {
      this.submitStudentData()
      this.submitBday()
      this.toggleKeyboard(false)
    },
    setShowModal (value) {
      this.showModal = value
    },
    openIntercomChat () {
      if (!this.isMobile) {
        this.$intercom.show()
      }
    },
    beforeRouteLeave (to, from, next) {
      if (!this.isMobile) {
        this.$intercom.shutdown()
        next()
      }
    },
    toggleKeyboard (trueOrFalse) {
      this.$store.commit('TOGGLE_KEYBOARD', trueOrFalse)
    },
    isSucceed () {
      if (this.$store.state.activation.success) {
        this.$router.push('/account-activation/step3')
      }
    },
    isLoading () {
      this.loading = this.$store.state.activation.loading
    },
    isActivationError () {
      this.showModal = true
      this.activationError = this.$store.state.activation.errors
      if (this.$store.state.activation.errors.status === 401) {
        setTimeout(function () { this.$router.push('/login') }.bind(this), 3000)
      }
    },
    submit () {
      this.submitStudentData()
      this.$store.commit('SET_COMPLETE', true)
      this.$store.dispatch('submitActivation', this.$store.state.user.user_id)
    },
    submitName () {
      this.$store.commit('SUBMIT_NAME', {fname: this.fname.trim(), lname: this.lname.trim()})
    },
    submitAltEmail () {
      this.$store.commit('SUBMIT_ALT_EMAIL', {email_alt: this.email_alt.trim()})
    },
    submitEmail () {
      this.$store.commit('SUBMIT_EMAIL', {email: this.email.trim()})
    },
    submitBday () {
      this.$store.commit('SUBMIT_BIRTHDAY', {dob_day: this.dob_day, dob_month: this.dob_month.trim(), dob_year: this.dob_year})
    },
    processImage (event) {
      this.image_file = event.target.files[0]
      if (!this.image_file || !this.image_file.name) return
      this.image_file_name = this.image_file.name
      this.$store.commit('SUBMIT_STUDENT_CHECK', {
        name: this.student === 'yes' && !this.isYoung ? (this.school ? this.school.trim() : '') : '',
        file: this.student === 'yes' && !this.isYoung ? this.image_file : null,
        type: this.student === 'yes' && !this.isYoung ? this.schoolType.trim() : '',
        year: this.student === 'yes' && !this.isYoung ? this.schoolYear.trim() : '',
        student: this.student
      })
    },
    submitAddress () {
      const payload = {
        res_type: this.isYoung ? '' : this.res_type.trim(),
        street: this.street.trim(),
        apt: this.apt.trim(),
        city: this.city.trim(),
        state: this.state.trim(),
        zip: this.zip.trim(),
        rent_own: this.isYoung ? '' : this.rent_own.trim(),
        business_name: this.isYoung ? '' : this.isBusiness === 'yes' && !this.isYoung ? this.businessName.trim() : ''
      }
      this.$store.commit('SUBMIT_ADDRESS', payload)
    },
    submitPreviousProvider () {
      this.$store.commit('SUBMIT_PREVIOUS_PROVIDER', {
        switching_protection: this.comesFromOtherProvider === 'yes',
        past_protection_provider: this.prevProviderName
      })
    },
    submitHearAboutUs () {
      this.$store.commit('SUBMIT_HEAR_ABOUT_US', {
        hear_about_us: this.hear_about_us.trim(),
        hear_about_us_other: this.hear_about_us_other.trim() || ''
      })
    },
    generateRange (start, stop) {
      var range = []
      for (let i = start; i <= stop; i++) {
        range.push(i)
      }
      return range
    },
    submitStudentData () {
      if (!this.isStudentSwitchVisible) {
        this.student = 'yes'
      }
      if (this.isYoung) {
        this.student = 'yes'
      }
      this.$store.commit('SUBMIT_STUDENT_CHECK', {
        name: this.student === 'yes' && !this.isYoung && this.school ? this.school.trim() : '',
        file: this.student === 'yes' && !this.isYoung ? this.image_file : null,
        type: this.student === 'yes' && !this.isYoung && this.schoolType ? this.schoolType.trim() : '',
        year: this.student === 'yes' && !this.isYoung && this.schoolYear ? this.schoolYear.trim() : '',
        student: this.student
      })
    },
    testForm () {
      this.fname = 'Test'
      this.lname = 'User'
      this.phone = '5656565656'
      this.dob_month = 'April'
      this.dob_day = 18
      this.dob_year = 1997
      this.street = 'Main St'
      this.city = 'San Francisco'
      this.state = 'CA'
      this.zip = '81718'
      this.res_type = 'apartment'
      this.rent_own = 'rent'
      this.hear_about_us = this.userFromPartners ? '' : 'friend_or_family'
      this.hear_about_us_other = ''
      this.submitName()
      this.submitAltEmail()
      this.submitBday()
      this.submitAddress()
      this.submitHearAboutUs()
    }
  }
}
</script>

<style scoped>

.grid-address,
.grid-birthday {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
}
.left-button {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.schools {
  width: 45%
}
.right-button {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.borders {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.custom-padding {
  padding: 2.5em 4% 2em 4%;
}
.responsive-box {
  margin-top: 20px;
}
.round-image {
  border-radius: 5px;
}
.code-input {
    border-color: var(--theme-gray);
    padding: 10px 15px 10px 15px;
  }
  .code-input::placeholder {
    color: rgb(126, 121, 121);
  }
  .code-input:focus {
    border-color: var(--theme-gray);
    outline: none;
  }
.main-container {
    border: 1px dashed var(--theme-medium-gray);
    padding: 1em 3.5%;
    min-height: 7em;
    min-width: 16em;
  }
  .label {
    border: 1px solid var(--theme-medium-gray);
    border-right-width: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: var(--theme-gray);
  }
  .big-label {
    border: 1px solid var(--theme-medium-gray);
    border-right-width: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: var(--theme-gray);
    width: 100%;
    white-space: nowrap;
    padding: 10px;
  }
  .closeIcon {
    position: absolute;
    top: -3px;
    right: 25px;
  }
  .select {
    border: 1px solid var(--theme-medium-gray);
    border-radius: 4px;
    height: 100%;
    padding-right: 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
  .select-big {
    border: 1px solid var(--theme-medium-gray);
    border-radius: 4px;
    height: 100%;
    padding-right: 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
  .input {
    position: relative;
    height: 2.39em;
  }
  .absolute {
    position: absolute;
    right: 15px;
    top: 40%;
  }

@media screen and (min-width: 30em) {
.grid-address {
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 1fr 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1.25em;
}
.grid-birthday {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-column-gap: 1em;
  grid-row-gap: 1.25em;
}
}
input[type=radio]:checked + label{
  background-color: var(--theme-pink);
  border-color: var(--theme-primary);
  color: var(--theme-primary);
  border-width: 1px;
}

@media (max-width: 990px) {
    .select {
      border-radius: 4px;
    }
    .schools {
    width: 100%
    }
}

@media (max-width: 505px) {
    .responsive-none {
      display: none;
    }
}
@media (max-width: 400px) {
    .responsive-school {
      flex-direction: column;
    }
}
@media (max-width: 786px) {
    .responsive-column {
      flex-direction: column;
    }
    .responsive-margin {
      margin-right: 0;
    }
}

</style>

