<template>
  <div class="tc mb4 bg-white shadow-theme br-all br20 pv3 flex-1">
    <h2 class="mt4">
      {{ title }}
    </h2>
    <div class="mb4">
      <h3 class="gray">
        {{ subtitle }}
      </h3>

      <div class="mt4">
        <div
          class="f5 fw4 gray"
          :class="descriptionStyles"
        >
          <strong v-html="description" />
        </div>
      </div>

      <div class="flex flex-column mv4">
        <div class="flex justify-center">
          <label
            class="b mid-gray"
            :class="!isMobile ? 'f3' : 'f5'"
          >$</label>
          <label
            class="b mid-gray"
            :class="!isMobile ? 'f1-ns' : 'f3'"
          >{{ price }}</label>
        </div>
        <label class="mid-gray f4">{{ $t('planssettings.basic.range', { range: 'per month' }) }}</label>
      </div>

      <div
        v-if="buttonPrimary"
        class="dib-ns w-90 mt3"
        :class="isMobile ? 'card-margin' : ''"
      >
        <ak-button
          primary
          block
          @click.native="onClick"
        >
          <strong>
            {{ buttonTitle }}
          </strong>
        </ak-button>
      </div>
      <div
        v-else
        class="dib-ns w-90 mt3"
        :class="isMobile ? 'card-margin' : ''"
      >
        <ak-button
          secondary
          block
          @click.native="onClick"
        >
          <strong>
            {{ buttonTitle }}
          </strong>
        </ak-button>
      </div>

      <div class="f5 fw gray mt4">
        <ak-icon-with-text
          v-for="(info, index) in planData"
          :key="index"
          :label-class="planData.length-1 === index ? 'b mid-gray' : ''"
          div-class="mv3"
          :text="info"
          :svg="require('@/assets/appicons/Check.svg')"
          svg-style="margin-top: -1px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AkIconWithText from './IconWithText'
import AkButton from './Button'
export default {
  name: 'AkPlanCard',
  components: {
    AkIconWithText,
    AkButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    descriptionStyles: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
    buttonPrimary: {
      type: Boolean,
      default: false
    },
    buttonTitle: {
      type: String,
      default: ''
    },
    onClick: {
      type: Function
    },
    planData: {
      type: Array
    }
  },
  data () {
    return {
    }
  },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  }
}
</script>

<style scoped>
.card-margin {
  margin: auto;
}
</style>
