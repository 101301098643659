<template>
  <main>
    <div class="mt4">
      <label
        for="email"
        class="f6 b db mb2"
      >{{ labels.primaryEmail }}</label>
      <input
        id="email"
        v-model="primaryEmail"
        class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
        name="email"
        type="email"
        autocomplete="email"
        @blur="setEmail()"
      >
      <div
        v-if="emailValidationMessages.help"
        class="f6 silver mt2"
      >
        {{ emailValidationMessages.help }}
      </div>
      <div
        v-if="emailValidationMessages.error"
        class="red f7 mt2"
      >
        {{ emailValidationMessages.error }}
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  GET_REGISTRATION_PRIMARY_EMAIL,
  SET_REGISTRATION_PRIMARY_EMAIL
} from '@/store/registrationv2'

export default {
  name: 'AkEmailForm',
  props: {
    isStudentPlan: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      labels: {
        primaryEmail: 'Primary Email Address'
      },
      primaryEmail: ''
    }
  },
  mounted () {
    this.loadData()
  },
  computed: {
    ...mapGetters({
      registrationPrimaryEmail: GET_REGISTRATION_PRIMARY_EMAIL
    }),
    emailValidationMessages () {
      let error, help

      if (!this.emailValid && (this.primaryEmail || '').trim()) {
        error = 'Must use a valid email address'
      }

      if (this.requiresStudentValidation) {
        help = 'If you do not have a .edu email, enter an alternate email address. You will need to provide verification of student status.'
      }

      return { error, help }
    },
    emailValid () {
      return (this.primaryEmail || '').trim() ? this.primaryEmail.includes('@') && this.primaryEmail.includes('.') : false
    },
    hasEduEmail () {
      return !!this.primaryEmail && (!RegExp(/.+@.+\....+/).test(this.primaryEmail) ||
      this.primaryEmail.trim().endsWith('.edu'))
    },
    isDataFilled () {
      return !!this.primaryEmail && !!this.emailValid
    }
  },
  methods: {
    ...mapMutations({
      setPrimaryEmail: SET_REGISTRATION_PRIMARY_EMAIL
    }),
    loadData () {
      this.primaryEmail = this.registrationPrimaryEmail
      if (this.isStudentPlan) {
        this.labels.primaryEmail = "Student's Email (Use .edu Address)"
      }
    },
    setEmail () {
      if (this.primaryEmail) {
        this.setPrimaryEmail(this.primaryEmail)
        this.$store.dispatch('generateLead')
      }
    }
  }
}
</script>
