<template>
  <main>
    <div class="height flex items-center justify-between mb3">
      <span class="f2 lh-copy pt3">Add AKKO Plan</span>
    </div>
    <div class="mv4-l mv2" />
    <ak-alert
      v-if="error"
      color="red"
    >
      <span>{{ error }}</span>
    </ak-alert>
    <div class="mr3-ns w-50 flex-grow flex-basis-2 mb4 mt4">
      <div style="flex:100%">
        <div>
          <fieldset class="input-reset bn">
            <legend class="f6 b db mb2">
              {{ $t('planssettings.addakko.choose') }}
            </legend>
            <div class="flex justify-around">
              <input
                id="planType1"
                v-model="planType"
                class="dn"
                type="radio"
                :value="'adult'"
                name="planType"
              >
              <label
                tabindex="0"
                for="planType1"
                class="flex-grow pv2 tc b ba br1 br--left b--silver silver bg-white pointer"
                @keyup.enter="planType='adult'"
              >
                {{ $t('planssettings.addakko.adult') }}
              </label>
              <input
                id="planType2"
                v-model="planType"
                class="dn"
                type="radio"
                :value="'student'"
                name="planType"
              >
              <label
                tabindex="0"
                for="planType2"
                class="flex-grow pv2 tc b ba bl-0 br1 br--right b--silver silver bg-white pointer"
                @keyup.enter="planType='student'"
              >
                {{ $t('planssettings.addakko.student') }}
              </label>
            </div>
          </fieldset>
        </div>
      </div>

      <div
        class="mt3"
        style="flex: 100%"
      >
        <fieldset class="input-reset bn">
          <label class="f6 b db mb2">{{ $t('planssettings.phoneonly.usersphone') }}</label>
          <input
            id="phoneNumber"
            v-model="phoneNumber"
            class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
            type="text"
            @input="acceptNumber"
            @focus="toggleKeyboard(true)"
            @blur="phoneOnBlur"
          >
          <span
            v-show="errorList.length === 0 && phoneNumber !== '' && !validPhoneNumber"
            class="red f7"
          >
            {{ $t('planssettings.addakko.entervalid') }}
          </span>
          <div v-show="errorList.length > 0">
            <span
              v-for="(e, index) in errorList"
              :key="index"
              class="red f7"
            >{{ e.message || e }}</span>
          </div>
        </fieldset>
      </div>
    </div>

    <fieldset class="input-reset bn">
      <div class="black lh-copy">
        {{ $t('planssettings.addakko.selected') }} {{ selectedCoverage.make && selectedCoverage.model ? selectedCoverage.make + ' ' + selectedCoverage.model + ' - ': '' }}
        {{ selectedCoverage.name }}
      </div>
      <div class="black lh-copy mt3">
        Total: ${{ selectedCoverage.price }} ({{ selectedCountryCurrency.currency_code }})
      </div>
    </fieldset>

    <div class="mt3">
      <div class="dib-ns w-25">
        <ak-button
          primary
          block
          :disabled="!formComplete"
          @click.native="showAddPlanConfirmationModal"
        >
          {{ $t('planssettings.addnewplan') }}
        </ak-button>
      </div>
    </div>

    <ak-modal
      v-if="showConfirmationModal"
      title="Confirm adding new plan"
      @close="resetState"
    >
      <div slot="modal-body">
        <div>
          {{ $t('planssettings.phoneonly.adding') }}
          <br>
          <br>
          <div class="flex flex-column justify-between pl4 h3">
            <span>{{ $t('planssettings.phoneonly.plan') }} {{ selectedCoverage.name }}<br></span>
            <span>{{ $t('planssettings.phoneonly.number') }} {{ phoneNumber }}<br></span>
            <span>{{ $t('planssettings.phoneonly.charge') }} ${{ coveragePriceWithDiscount }} {{ $t('planssettings.phoneonly.more') }} {{ selectedCoverage.plan_interval === 'annual' ? 'billed annually' : 'per month' }}<br></span>
          </div>
        </div>
      </div>

      <div
        slot="modal-footer"
        class="pa3 flex flex-wrap justify-between justify-end-ns"
      >
        <div class="mr3 flex-grow flex-grow-off-ns">
          <ak-button
            secondary
            block
            @click.native="resetState"
          >
            {{ $t('planssettings.phoneonly.nothanks') }}
          </ak-button>
        </div>
        <div class="flex-grow flex-grow-off-ns">
          <ak-button
            primary
            block
            @click.native="addAkkoPlan"
          >
            {{ $t('planssettings.phoneonly.confirm') }}
          </ak-button>
        </div>
      </div>
    </ak-modal>
  </main>
</template>

<script>
import AkButton from '@/components/Button'
import AkModal from '@/components/Modal'
import AkAlert from '@/components/Alert'

import {
  SET_REGISTRATION_TYPE,
  SET_ADD_PLAN_ERROR
} from '../../store/index'
import { CHECK_GENERAL_ERRORS } from '../../store/registrationv2'
import REGISTRATION_TYPE from '../../constants/registration/registrationType'
import { isEmpty } from 'lodash'
import { mapActions, mapState, mapGetters } from 'vuex'
import PLAN_TYPES from '@/constants/registration/planTypes'

export default {
  name: 'AkAddAkkoPlan',
  components: {
    AkButton,
    AkModal,
    AkAlert
  },
  data () {
    return {
      showConfirmationModal: false,
      phoneNumber: '',
      planType: PLAN_TYPES.ADULT,
      selectedPhones: [
        {
          make: '',
          model: ''
        }
      ]
    }
  },computed: {
    ...mapState({
      selectedCountryCurrency: state => state.registrationv2.selectedCountryCurrency,
      currentUser: state => state.user
    }),
    ...mapGetters({
      getAkkoPlanCoverageData: 'getAkkoPlanCoverageData'
    }),
    coveragePriceWithDiscount () {
      const discounts = this.currentUser.discounts
      const emptyDiscounts = !discounts || discounts.length === 0
      const defaultDiscount = Number((this.selectedCoverage.price * 0.05).toFixed(2))
      const discount = emptyDiscounts ? defaultDiscount : discounts.reduce((accumulativeDiscount, currentDiscount) => {
        return this.calculateDiscountAmount(currentDiscount, accumulativeDiscount)
      }, 0)

      const twoDecimalsPrice = (this.selectedCoverage.price - discount).toFixed(2)
      const priceWithDiscount = Number(twoDecimalsPrice)
      return priceWithDiscount > 0 ? twoDecimalsPrice : 0
    },
    errorList () {
      return this.$store.state.errors
    },
    error () {
      return this.$store.state.addPlanError
    },
    formComplete () {
      return this.phoneNumber && this.validPhoneNumber && this.planType && isEmpty(this.$store.state.errors)
    },
    validPhoneNumber () {
      return [10, 11].includes(this.phoneNumber.replace(/[^0-9]/g, '').length)
    },
    selectedCoverage () {
      let [akkoCoverage] = this.getAkkoPlanCoverageData([this.currentUser.coverage_plan_interval], this.planType)
      return akkoCoverage
    }
  },
  mounted () {
    window.fbq('trackCustom', 'Phone Select Billing Period')
    this.getFilteredCoverageOptions({
      currency_code: this.selectedCountryCurrency.currency_code,
      plan_interval: this.currentUser.coverage_plan_interval
    })

    this.$store.commit(SET_ADD_PLAN_ERROR)
    this.$store.commit(SET_REGISTRATION_TYPE, REGISTRATION_TYPE.MULTI_PHONE)
  },
  methods: {
    calculateDiscountAmount (discount, existingDiscount = 0) {
      let discountAmount = 0
      const intervalMonths = this.selectedCoverage.plan_interval === 'monthly' ? 12 : 1
      const numberMonths = Math.min(discount.number_months || Infinity, intervalMonths)
      if (discount.amount_type === 'FIXED') {
        discountAmount = existingDiscount + discount.amount * numberMonths
      } else if (discount.amount_type === 'PERCENT') {
        discountAmount = (this.selectedCoverage.price - existingDiscount / intervalMonths) * discount.amount / 100 * numberMonths
      }

      return Number(discountAmount.toFixed(2))
    },
    ...mapActions({
      checkPhone: CHECK_GENERAL_ERRORS,
      getFilteredCoverageOptions: 'getFilteredCoverageOptions'
    }),
    resetState () {
      this.showConfirmationModal = false
      this.phoneNumber = ''
    },
    showAddPlanConfirmationModal () {
      this.showConfirmationModal = true
    },
    addAkkoPlan () {
      this.$store.dispatch('addPlan', {
        coverage: this.selectedCoverage,
        phoneNumber: this.phoneNumber
      })
      this.resetState()
    },
    acceptNumber () {
      const formattedNumber = this.phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.phoneNumber = !formattedNumber[2] ? formattedNumber[1] : '(' + formattedNumber[1] + ') ' + formattedNumber[2] + (formattedNumber[3] ? '-' + formattedNumber[3] : '')
    },
    toggleKeyboard (trueOrFalse) {
      this.$store.commit('TOGGLE_KEYBOARD', trueOrFalse)
    },
    phoneOnBlur () {
      this.toggleKeyboard(false)
      this.checkPhone({phone: this.phoneNumber})
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 1279px) and (min-width: 480px) {
  .w-50 {
    width: 100%;
  }
  .w-25 {
    width: 50%;
    margin: 0 25%;
  }
}

@media (max-width: 768px) {

.height {
  height: auto;
}

}

@media screen and (max-width: 479px) {
  .w-50 {
    width: 100%;
  }
  .w-25 {
    width: 100%;
  }
}


.height {
  height: 50px;
}


.flex-basis-4 {
  flex-basis: 16em;
}
.flex-basis-3 {
  flex-basis: 12em;
}
.flex-basis-25 {
  flex-basis: 23%;
}
input[type=radio]:checked + label{
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}
.grid-fourth {
  grid-column-gap: 1.5em;
  grid-row-gap: 2.25em;
}
.not-allowed-cursor {
  cursor: not-allowed;
}

/* Hide input number arrows in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide input number arrows in  Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
