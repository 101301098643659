<template>
  <div class="flex">
    <div
      :class="internalApplied ? 'area-applied' : ''"
      class="flex flex-row justify-between items-center pa2 border-gray pointer discount-area"
      @click="onClick()"
    >
      <div class="flex items-center h-100">
        <div class="flex flex-row-l flex-column pricetag">
          <div class="cera-round-pro color-theme discount-text">
            {{ discount }} {{ subDiscount }}
          </div>
        </div>
        <div class="flex flex-row-l flex-column mh3 tl items-center-l">
          <div class="inter f6-ns f7 mr3-l">
            {{ title }}
          </div>
          <div
            v-if="subtitle"
            class="inter f6-ns f7 color-gray mt1 mt0-l"
          >
            {{ subtitle }}
          </div>
        </div>
      </div>
      <div
        :class="internalApplied ? 'btn-applied' : 'color-theme btn-apply'"
        class="pv1 ph2 f6 cera-round-pro-medium"
      >
        {{ internalApplied ? 'Applied' : 'Apply ›' }}
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex'
import {TWO_YEAR_UPFRONT_KEY} from '@/constants/registration/discountKeys'
import {
  GET_INTERNAL_DISCOUNT_VALUES,
  GET_REGISTRATION_INTERVAL,
  GET_TWO_YEARS_UPFRONT,
  REQUEST_INTERNAL_DISCOUNT_VALUES,
  SET_REGISTRATION_DISCOUNTS_ADD,
  SET_REGISTRATION_DISCOUNTS_REMOVE,
  SET_REGISTRATION_ENTERED_DISCOUNT_REMOVE,
  SET_TWO_YEARS_UPFRONT
} from '@/store/registrationv2'
import INTERVALS from '@/constants/registration/planIntervals'

export default {
  name: 'CarouselDiscount',
  props: {
    discount: {
      type: String,
      default: ''
    },
    subDiscount: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    isCoupon: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: ''
    },
    applied: {
      type: Boolean,
      default: false,
    },
    isBiggerCoupon: {
      type: Boolean,
      default: false,
    },
    setisBiggerCoupon: {
      type: Function,
      default: () => {}
    },
    isTwoYear: {
      type: Boolean,
      default: false,
    },
    setAnnual: {
      type: Function,
      default: () => {}
    },
    code: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dataReady: false,
      internalApplied: this.applied
    }
  },
  computed: {
    ...mapGetters({
      twoYearsUpfront: GET_TWO_YEARS_UPFRONT,
      internalDiscountValues: GET_INTERNAL_DISCOUNT_VALUES,
      interval: GET_REGISTRATION_INTERVAL
    })
  },
  watch: {
    interval () {
      if (this.$store.state.registrationv2.interval === INTERVALS.MONTHLY && this.isTwoYear) {
        if (this.twoYearsUpfront) {
          this.removeDiscount(this.internalDiscountValues[TWO_YEAR_UPFRONT_KEY])
        }
        this.setTwoYearsUpfront(false)
        this.internalApplied = false
      }
    }
  },
  async mounted () {
    await this.requestInternalDiscountValues()
    this.dataReady = true
    if (this.isTwoYear) {
      if (this.twoYearsUpfront && this.$store.state.registrationv2.interval === INTERVALS.ANNUAL) {
        this.addDiscount(this.internalDiscountValues[TWO_YEAR_UPFRONT_KEY])
      }
      if (this.$store.state.registrationv2.interval === INTERVALS.MONTHLY) {
        this.internalApplied = false
        this.setTwoYearsUpfront(false)
      }
    }
  },
  methods: {
    onClick () {
      if (this.isTwoYear) {
        this.setTwoYearsUpfront(!this.twoYearsUpfront)
        const updateDiscount = this.twoYearsUpfront ? this.addDiscount : this.removeDiscount
        const twoYearUpfrontDiscount = this.internalDiscountValues[TWO_YEAR_UPFRONT_KEY]
        if (this.isBiggerCoupon) {
          this.removeEnteredDiscount()
          this.$emit('update:isBiggerCoupon', false)
          this.setisBiggerCoupon(false)
        }
        updateDiscount(twoYearUpfrontDiscount)
        this.setAnnual()
      } else if (this.isCoupon) {
        let discount = {
          'code': this.code
        }
        discount[this.code] = this.code
        this.removeDiscount(discount)
      }
    },
    ...mapMutations({
      setTwoYearsUpfront: SET_TWO_YEARS_UPFRONT,
      addDiscount: SET_REGISTRATION_DISCOUNTS_ADD,
      removeDiscount: SET_REGISTRATION_DISCOUNTS_REMOVE,
      removeEnteredDiscount: SET_REGISTRATION_ENTERED_DISCOUNT_REMOVE
    }),
    ...mapActions({
      requestInternalDiscountValues: REQUEST_INTERNAL_DISCOUNT_VALUES
    })
  }
}
</script>

<style scoped>

.border-gray {
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    width: 100%;
    min-width: auto;
    margin-right: 0;
    margin-bottom: 1rem;
    height: 50px;
  }

.discount-container {
  padding: 0.5rem 1rem;
  background-color: var(--theme-dark-green);
  border-radius: 5px;
  text-align: center;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.pricetag{
    position: relative;
    margin: 0 0.25rem 0 0;
    height: 38px;
    border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
    padding: 0 0.5rem 0 0;
    background: var(--theme-dark-green);
}

.pricetag:before{
    position: absolute;
    background: var(--theme-dark-green);
    content: "\25CF";
    color: white;
    font-size: 1em;
    line-height: 30px;
    text-indent: 8px;
    top: 5px;
    right: -11px;
    width: 29px;
    height: 29px;
    transform: rotate(45deg);
    border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
}

.discount-text {
  line-height: 38px;
  z-index: 1;
  white-space: nowrap;
  text-align: center;
  width: min-content;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  min-width: 55px;
}

.discount-area {
  height: 4rem;
  padding: 12px;
  border-radius: 5px;
}

.discount-area:hover {
  background-color: #FAFAFA;
  border: 1px solid #B9BFCA;
  .btn-apply {
    background-color: #E8E8E8;
  }
}

.btn-applied {
  background-color: #D5E5D3;
  color: var(--theme-dark-green);
  border-radius: 5px
}

.color-theme {
  color: var(--theme-cream);
}

.items-center-l {
  margin-left: 2rem;
  margin-right: 2rem;
}

.btn-apply {
  color: var(--theme-dark-green);
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 5px;
}

.area-applied {
  border: 1px solid #2F7D22 !important;
}

@media (max-width: 1280px) {
  .responsive-none {
    display: none;
  }

  .responsive-appear {
    display: block;
  }

  .border-gray {
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    width: 100%;
    min-width: auto;
    margin-right: 0;
    margin-bottom: 1rem;
    height: auto;
  }
}

@media (max-width: 375px) {
  .items-center-l {
    margin-left: 1rem;
    margin-right: 0;
  }

  .discount-text {
    width: min-content;
  }

  .discount-area {
    display: block;
    height: fit-content;
  }

  .btn-applied {
    margin-top: 1rem;
    width: 66px;
  }

  .btn-apply {
    margin-top: 1rem;
    width: 66px;
  }
}
</style>
