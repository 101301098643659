<template>
  <div />
</template>

<script>

export default {
  name: 'SupportChatPage',
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  },
  watch: {
    '$intercom' (newVal) {
      if (!newVal.visible) {
        this.$intercom.shutdown()
        this.$router.push('/support')
      }
    }
  },
  mounted () {
    this.openIntercomChat()
  },
  methods: {
    openIntercomChat () {
      const {email, first_name, user_id, phone_no} = this.$store.state.user
      this.$intercom.boot({
        userId: user_id,
        user_id: user_id,
        name: first_name,
        email: email,
        phone: phone_no
      })
      this.$intercom.show()
    }
  }
}
</script>

<style scoped>
button {
  all: unset;
  cursor: pointer;
}
</style>
