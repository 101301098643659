<template>
  <main class="mw7-ns center-ns">
    <!-- <div class="f5 db mb3">Coverages</div> -->
    <div class="mt4 mb4 pt4">
      <div class="f5 db mb3 fw6">
        Selected Plan
      </div>
      <div
        v-if="contactInfo.length > 1"
        class="mb3"
      >
        <label class="f6 fw5 di mb2 lh-copy">
          Please enter the cell phone number that will be associated with each plan below.
          Each person will receive a text message with their own login credentials after you checkout.
          Each person must login from their own phone and complete verification on their own device.
        </label>
      </div>
      <table>
        <thead>
          <tr>
            <th width="65%">
              Plan Type
            </th>
            <th width="25%">
              Cell Phone Number
            </th>
            <th width="10%">
              Primary
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(contact, index) in contactInfo"
            :key="`coverage-${index}`"
          >
            <td>
              {{
                selectedCoverages[index].make && selectedCoverages[index].model ?
                  (selectedCoverages[index].make + ' ' + selectedCoverages[index].model + ' - ') : ''
              }}
              {{ selectedCoverages[index].name }}
            </td>
            <td>
              <InputMask
                :id="`phone-${index}`"
                v-model="contact.phone"
                v-focus="index === 0"
                class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
                type="tel"
                :name="`phone-${index}`"
                mask="+1 (999) 999-9999"
                placeholder="+1"
                @input="setPhone(index)"
              />
              <div
                v-show="selectedCoverages[index].phoneError"
                class="red f7"
              >
                {{ selectedCoverages[index].phoneError }}
              </div>
              <div
                v-show="selectedCoverages[index].isInvalid"
                class="red f7"
              >
                Please enter a valid phone number
              </div>
            </td>
            <td>
              <input
                :id="`primary-${index}`"
                v-model="contact.primary"
                type="checkbox"
                class="di"
                @change="setPrimary(index)"
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr>

    <!-- <div class="mb3" v-show="errorList.length > 0">
      <span class="red">Error submitting registration:</span>
      <span class="red" v-for="(e, index) in errorList" :key="index">{{e}}</span>
    </div> -->

    <ak-payment-method
      ref="paymentForm"
      :is-student-plan="isStudentPlan"
    />

    <div class="mt4">
      <label
        for="promo_code"
        class="f6 b db mb2"
      >
        Discount / Referral Code
        <span
          v-if="discountMessage.error"
          style="color:red"
        > - INVALID - {{ discountMessage.error }}</span>
        <span
          v-else-if="discountMessage.message"
          style="color:green"
        > - VALID - {{ discountMessage.message }}</span>
      </label>
      <div class="input-group-flex mb2">
        <input
          id="promo_code"
          v-model="discount_code"
          class="input-reset ba b--black-20 pa2 db w-100 br1 ttu"
          type="text"
          @keyup.enter="submitDiscountCode"
        >
        <button
          class="f6 link dim ba bw2 ph3 pv2 dib dark-green submit-btn"
          @click="submitDiscountCode"
        >
          Submit
        </button>
      </div>
      <!-- <div v-if="coverage.name.toLowerCase().includes('monthly')" class="f6 i mb4"> -->
      <!--  Discount codes applied to monthly payment plans discount your payments for the first 4 months. -->
    </div>

    <br>

    <div class="mb2 f6">
      <div class="f5 db mb3 fw6">
        Checkout:
      </div>
      <div
        v-for="(coverage, index) in selectedCoverages"
        :key="index"
        class="black lh-copy"
      >
        ${{ coverage.price.toFixed(2) }} -
        {{ coverage.make && coverage.model ? coverage.make + ' ' + coverage.model + ' - ': '' }}
        {{ coverage.name }}
      </div>
      <div
        v-if="showDiscount"
        class="black lh-copy"
      >
        Discounts:
        <div
          v-for="discount in discountsToDisplay"
          :key="discount.name"
          class="little-left-padding"
        >
          -${{ discount.amount.toFixed(2) }} - {{ discount.name }}
        </div>
      </div>
      <div class="black lh-copy mt3">
        Total: ${{ total }} ({{ selectedCountryCurrency.currency_code }})
        <span v-if="oneTimeVerificationCharge">one-time verification charge</span>
        <span v-if="applyTwoYearsUpfrontDiscount">(2 year upfront charge applied)</span>
      </div>
      <div
        v-if="featureFlagMonthlyBillingActive"
        class="mw5-ns left-ns tc mb4 pt4 lh-copy flex"
      >
        <input
          id="interval1"
          v-model="interval"
          class="dn"
          type="radio"
          :value="'annual'"
          name="interval"
        >
        <label
          tabindex="0"
          for="interval1"
          class="flex-grow pv2 tc b ba br1 br--right b--silver silver bg-white pointer"
          @keyup.enter="interval='annual'"
        >Annual</label>
        <span class="pv2 ph3 tc b silver">OR</span>
        <input
          id="interval2"
          v-model="interval"
          class="dn"
          type="radio"
          :value="'monthly'"
          name="interval"
        >
        <label
          tabindex="0"
          for="interval2"
          class="flex-grow pv2 tc b ba br1 br--left b--silver silver bg-white pointer"
          @keyup.enter="interval='monthly'"
        >Monthly</label>
      </div>
    </div>

    <div class="mv3">
      <input
        id="accept"
        v-model="accept"
        type="checkbox"
        class="di"
      >
      <label
        for="accept"
        class="f6 fw5 di mb2 lh-copy"
      >
        I agree that I have thoroughly read, understand, and accept the
        <a
          href="https://getakko.com/legal"
          target="_blank"
          class="color-theme"
        >terms of service &amp; conditions</a>
        as well as the <a
          href="https://getakko.com/coverage-info-faqs/"
          target="_blank"
          rel="noopener"
          class="color-theme"
        >AKKO website's FAQs</a> about plan protections and claims.
        I also acknowledge and agree to receive and view all plan documentation, including Terms and Conditions and Plan Confirmation (if any), electronically as well as receive automated transactional messages via email and SMS in accordance with AKKO's Privacy Policy.
      </label>
    </div>

    <div class="mv4 flex justify-center">
      <ak-button
        class="flex-grow flex-grow-off-ns inter fw6"
        :disabled="!formComplete || loading"
        green
        @click.native="submit"
      >
        Register
      </ak-button>
    </div>

    <div class="black f6 mv4 mw7-ns center tc lh-copy">
      Elite Risk Insurance Solutions Lic#0G40499. <!-- All coverage plans provided by NSSI and underwritten by
      <br />
      <a href="https://www.hanover.com/about-our-companies.html" target="_blank" rel="noopener noreferrer" class="black">
        Allmerica Financial Benefit Company a member of The Hanover Insurance Group, (est. 1852)
      </a> -->
    </div>
    <ak-modal
      v-if="showDisclaimerModal"
      title=""
      @close="toggleDisclaimerModal"
    >
      <div slot="modal-body">
        <div class="mb4">
          {{ minnesotaDiscalimer }}
        </div>
      </div>
    </ak-modal>
  </main>
</template>

<script>
import AkButton from '@/components/Button'
import AkPaymentMethod from '@/components/payment-methods/PaymentMethod'
import AkModal from '@/components/Modal'
import InputMask from 'primevue/inputmask'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  GET_REGISTRATION_DISCOUNTS,
  GET_REGISTRATION_INTERVAL,
  GET_DISCOUNT_MESSAGE,
  ADD_DISCOUNT_CODE,
  CHECK_PHONE_NUMBER,
  SUBMIT_REGISTRATION,
  SET_REGISTRATION_SELECTED_COVERAGE_VALUE,
  SET_REGISTRATION_ERROR,
  SET_REGISTRATION_STATE_OFF,
  SET_REGISTRATION_COVERAGES,
  GET_TWO_YEARS_UPFRONT,
  GET_REGISTRATION_SELECTED_COVERAGES,
  SET_REGISTRATION_INTERVAL,
  SET_REGISTRATION_DISCOUNTS_REMOVE,
  SET_TWO_YEARS_UPFRONT
} from '@/store/registrationv2'
import { GET_FEATURE_FLAG } from '@/store/index'
import { MONTHLY_BILLING } from '@/constants/feature_flag'

import INTERVALS from '@/constants/registration/planIntervals'
import Vue from 'vue'
import DISCOUNT_CODE from '../../constants/registration/discountCode'
import REGISTRATION_TYPE from '../../constants/registration/registrationType'
import { TWO_YEAR_UPFRONT_KEY } from '@/constants/registration/discountKeys'
import minnesotaDiscalimer from '@/constants/registration/minnesotaDisclaimerText'
import { checkValidNumber } from '@/utils'
const invalidState = 'MN'

export default {
  name: 'AkReviewWm',
  components: {
    AkButton,
    AkPaymentMethod,
    AkModal,
    InputMask
  },
  data () {
    return {
      accept: false,
      contactInfo: [],
      discount_code: null,
      oneTimeVerificationCharge: false.valueOf,
      minnesotaDiscalimer,
      showDisclaimerModal: false
    }
  },
  computed: {
    ...mapGetters({
      discounts: GET_REGISTRATION_DISCOUNTS,
      twoYearsUpfrontSelected: GET_TWO_YEARS_UPFRONT,
      intervalFromStore: GET_REGISTRATION_INTERVAL,
      registeredSelectedCoverages: GET_REGISTRATION_SELECTED_COVERAGES,
      discountMessage: GET_DISCOUNT_MESSAGE
    }),
    ...mapState({
      selectedCountryCurrency: state => state.registrationv2.selectedCountryCurrency
    }),

    // Coverage
    interval: {
      get () {
        return this.intervalFromStore
      },
      set (newName) {
        this.setInterval(newName)
        return newName
      }
    },
    applyTwoYearsUpfrontDiscount () {
      return this.twoYearsUpfrontSelected && this.interval === INTERVALS.ANNUAL
    },
    annual () {
      return this.$store.state.coverageOptions
        .filter(coverage => coverage.plan_interval === INTERVALS.ANNUAL)[0]
    },

    monthly () {
      return this.$store.state.coverageOptions
        .filter(coverage => coverage.plan_interval === INTERVALS.MONTHLY)[0]
    },

    primaryCoverageIndex () {
      return this.selectedCoverages.findIndex(coverage => coverage.primary)
    },

    // Discount
    enteredDiscount () {
      return this.discounts.find((discount) => discount.entered) || {}
    },
    showDiscount () {
      return `${this.discount}` !== '0'
    },
    intervalMonths () {
      switch (this.interval) {
        case INTERVALS.ANNUAL: return 12
        default: return 1
      }
    },

    // Pricing
    discount () {
      let amount = 0
      this.discounts.forEach((discount) => {
        if (discount.key_name !== TWO_YEAR_UPFRONT_KEY || this.interval === INTERVALS.ANNUAL) {
          amount += this.calculateDiscountAmount(discount, amount)
        }
      })
      return amount
    },
    discountsToDisplay () {
      let amount = 0

      // TODO: UW-176 - Sort discounts or do the calculations on backend
      const discountSortCriteria = (a, b) => {
        if (a.amount_type === b.amount_type) return a.amount - b.amount
        if (a.amount_type === 'PERCENT') return -1
        return 1
      }
      const sortedDiscounts = [...this.discounts].sort(discountSortCriteria)
      return sortedDiscounts.flatMap((discount) => {
        const result = {
          name: discount.name ? discount.name : discount.code_name
        }
        // TODO - a bit hacky; want to display multi-phone verbiage instead of family plan when applicable
        if (discount.code === DISCOUNT_CODE.FAMILY_PLAN &&
          this.$store.state.registrationType === REGISTRATION_TYPE.MULTI_PHONE) {
          result.name = 'Multi-Plan Discount'
        }

        if (discount.key_name === TWO_YEAR_UPFRONT_KEY && this.interval === INTERVALS.MONTHLY) {
          return []
        }

        result.amount = this.calculateDiscountAmount(discount, amount)
        amount += result.amount

        return result
      })
    },
    selectedCoverages () {
      const { annual, monthly } = this.registeredSelectedCoverages
      return this.interval === INTERVALS.ANNUAL ? annual.coverages : monthly.coverages
    },
    maxDiscount () {
      return this.discount.toFixed(2).toString()
    },
    price () {
      const { annual, monthly } = this.registeredSelectedCoverages
      return this.interval === INTERVALS.ANNUAL ? annual.totalPrice : monthly.totalPrice
    },
    monthlyPrice () {
      let divisor = 1
      switch (this.interval) {
        case INTERVALS.ANNUAL: divisor = 12; break
      }
      return this.price / divisor
    },
    total () {
      // when the payment after all discounts goes to 0.0USD,
      // we force to pay at least 1.0USD
      let total = this.applyTwoYearsUpfrontDiscount ? (this.price - this.discount) * 2 : this.price - this.discount
      if (this.discount > 0 && total === 0) {
        this.oneTimeVerificationCharge = true
        total = total + 1
      } else {
        this.oneTimeVerificationCharge = false
      }
      return total.toFixed(2)
    },

    // Student
    isStudentPlan () {
      return this.selectedCoverages.length === 1 && (this.selectedCoverages[0].name.includes('Student') || this.selectedCoverages[0].plan_type === 'student')
    },

    // Validation
    contactInfoValid () {
      return this.contactInfo.filter(contact => contact.primary).length === 1
    },
    validateCellPhone () {
      const phone = this.contactInfo[0].phone
      return !phone.includes('_') && phone.length !== 0
    },
    formComplete () {
      const generalChecks = this.contactInfoValid &&
        this.accept &&
        this.validateCellPhone

      const paymentChecks = this.$refs.paymentForm && this.$refs.paymentForm.paymentMethodCompleted

      return !!generalChecks && !!paymentChecks
    },

    loading () {
      return this.$store.state.loading
    },
    errorList () {
      return this.$store.state.errors
    },
    featureFlagMonthlyBillingActive () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](MONTHLY_BILLING)
      return feature ? feature.state : false
    }
  },
  methods: {
    ...mapActions({
      addDiscountCode: ADD_DISCOUNT_CODE,
      checkPhone: CHECK_PHONE_NUMBER,
      submitRegistration: SUBMIT_REGISTRATION,
      setAddressState: 'setAddressState',
      generateLead: 'generateLead'
    }),
    ...mapMutations({
      setRegistrationSelectedCoverageValue: SET_REGISTRATION_SELECTED_COVERAGE_VALUE,
      setRegistrationCoverages: SET_REGISTRATION_COVERAGES,
      setInterval: SET_REGISTRATION_INTERVAL,
      removeDiscount: SET_REGISTRATION_DISCOUNTS_REMOVE,
      setTwoYearsUpfront: SET_TWO_YEARS_UPFRONT
    }),

    // Data
    loadData () {
      this.discount_code = this.getDiscountCode(this.enteredDiscount)
      this.contactInfo = this.selectedCoverages.map(({ email, phone, primary }) => ({ email: email || '', phone: phone || '', primary }))
    },
    setPhone (index = this.primaryCoverageIndex) {
      const phone = (this.contactInfo[index].phone || '').trim()
      if (phone === '' || phone.length < 17) {
        this.selectedCoverages[index].isInvalid = false
        this.selectedCoverages[index].phoneError = ''
      }
      this.selectedCoverages[index].isInvalid = checkValidNumber(phone.trim())
      this.setRegistrationSelectedCoverageValue({ index, key: 'phone', value: phone })
      if (!this.selectedCoverages[index].isInvalid && phone.length > 16) {
        this.checkPhone({ index, phone })
      }
      this.generateLead()
    },
    setPrimary (index) {
      // was seeing weird behavior in firefox where checkboxes would not update correctly; see
      // https://github.com/vuejs/vue/issues/293#issuecomment-265716984
      // for context about nextTick solution
      const context = this
      if (index > -1) {
        Vue.nextTick(function () {
          for (let i = 0; i < context.contactInfo.length; ++i) {
            const value = index === i
            context.contactInfo[i].primary = value
            context.setRegistrationSelectedCoverageValue({index: i, key: 'primary', value})
          }
        })
      }
    },

    // Discount
    calculateDiscountAmount (discount, existingDiscount = 0) {
      let discountAmount = 0
      const numberMonths = Math.min(discount.number_months || Infinity, this.intervalMonths)
      if (discount.amount_type === 'FIXED') {
        discountAmount = discount.amount * numberMonths
      } else if (discount.amount_type === 'PERCENT') {
        discountAmount = (this.monthlyPrice - existingDiscount / this.intervalMonths) * discount.amount / 100 * numberMonths
      }

      return Number(discountAmount.toFixed(2))
    },
    getDiscountCode (discount = {}) {
      return discount.referral_code || discount.code
    },
    submitDiscountCode () {
      this.addDiscountCode({ code: this.discount_code, entered: true })
    },
    toggleDisclaimerModal () {
      this.showDisclaimerModal = !this.showDisclaimerModal
    },
    setRegionToValid (stateCode) {
      this.setAddressState(stateCode)
      if (stateCode !== '') {
        this.generateLead()
        if (stateCode === invalidState) {
          this.toggleDisclaimerModal()
        }
      }
    },
    async submit () {
      this.$store.commit('SET_LOADING', true)
      window.fbq('trackCustom', 'Clicked Register')

      const result = await this.$refs.paymentForm.submitPayment()

      if (!result || result.error) {
        this.$store.commit('SET_LOADING', false)
      } else {
        this.setRegistrationCoverages(this.selectedCoverages)
        if (this.interval === INTERVALS.MONTHLY) this.setTwoYearsUpfront(false)
        this.submitRegistration()
      }
    }
  },
  beforeMount () {
    this.setRegistrationCoverages(this.selectedCoverages.filter(c => c.make !== '' && c.model !== ''))
  },
  mounted () {
    this.$store.commit(SET_REGISTRATION_STATE_OFF)
    this.$store.commit(SET_REGISTRATION_ERROR, '')

    window.fbq('trackCustom', 'Registration v2 - Checkout')
    // this.$store.commit('SET_ERRORS', null)

    this.loadData()
    if (this.primaryCoverageIndex === -1) {
      this.setPrimary(0)
    }

    this.$store.dispatch('getAllCoverageOptions')
    this.$store.commit('RESET_REDEMPTION_ALL')
  }
}
</script>

<style scoped>

/* Keep radio button style on page referesh */
input[type="radio"]:checked + label {
    background-color: var(--theme-primary);
    border-color: var(--theme-primary);
    color: #FFFFFF;
}
hr {
  margin-bottom: 25px;
}

th {
  text-align: left;
}

td, th {
  padding: 8px;
}

.little-left-padding {
  padding-left: 1em;
}

.StripeElement {
  background-color: white;
  padding: 10px 12px;
  border-radius: .125rem;
  border: 1px solid rgba(0,0,0,.2);
}

.StripeElement--focus {
  border-color: #f3103c;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5;
}

.input-group-flex {
  display: flex;
  align-items: stretch;
}

.text-field-flex {
  flex: 1;
}

.promo-submit-btn {
  flex: 1;
  cursor: pointer;
  border: 3px solid #137752;
  padding: 4px 12px;
  color: #137752;
  font-size: .9rem;
  font-weight: 800;
}

@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

  /* Change Table Behavior */
  table, thead, tbody, th, td, tr {
    display: block;
  }

  /* Hide Headers */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  /* Border Between Rows */
  tr:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  tr {
    padding: 15px 0;
  }

  /* Row Behavior */
  td {
    border: none;
    position: relative;
    padding-left: 50%;
  }

  /* Labels */
  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /* Row Labels */
  td:nth-of-type(1):before { content: "Coverage Name"; }
  td:nth-of-type(2):before { content: "Phone Number"; }
  td:nth-of-type(3):before { content: "Primary"; }
}
</style>
