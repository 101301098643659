<template>
  <div class="db centered-container-ns">
    <div
      id="login-card"
      class="bg-white  ph3 ph4-ns mt6 mt0-ns pv5-ns br2-ns border-login"
    >
      <h1 class="f3 lh-copy fw3 tc ">
        {{ $t('login.title', { title: 'Customer Login' }) }}
      </h1>

      <div
        v-if="loginMessage.message"
        class="dark-red f6 mt4 tc br2 ba b--dark-red pa2"
      >
        {{ loginMessage.message }}
      </div>

      <form>
        <div class="mv4">
          <label
            for="number"
            class="f6 db mb2 inter"
          >{{ $t('login.phone', { phone: 'Cell Phone Number' }) }}<span class="red">*</span></label>
          <InputMask
            id="number"
            v-model="number"
            class="input-reset ba border-login pa2 mb2 db w-100 br2"
            type="tel"
            mask="+1 (999) 999-9999"
            placeholder="+1"
            @focus="toggleKeyboard(true)"
            @blur="toggleKeyboard(false)"
          />
          <span
            v-show="number !== '' && ![10, 11].includes(number.replace(/[^0-9]/g, '').length)"
            class="red f7"
          >
            {{ $t('login.alert', { alert: 'Please enter a valid phone number' }) }}
          </span>
        </div>

        <div class="mv4">
          <label
            for="password"
            class="f6 db mb2 inter"
          >{{ $t('login.pass', { pass: 'Password' }) }}</label>
          <input
            id="password"
            v-model="password"
            class="input-reset ba border-login pa2 mb2 db w-100 br2"
            type="password"
            autocomplete="current-password"
          >
          <router-link
            to="/forgotpassword"
            class="mt2 color-theme no-underline inter"
          >
            {{ $t('login.getpass', { getpass: 'Click here to get a new password.' }) }}
          </router-link>
        </div>

        <div class="mt4 mb3">
          <ak-button
            primary
            block
            class="br3-ns"
            @click.native.prevent="login"
          >
            {{ $t('login.log', { log: 'Log In' }) }}
          </ak-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AkButton from './Button'
import InputMask from 'primevue/inputmask'

export default {
  name: 'AkLogin',
  components: {
    AkButton,
    InputMask
  },
  data () {
    return {
      number: '',
      password: ''
    }
  },
  computed: {
    loginMessage () {
      return this.$store.state.loginMessage
    }
  },
  mounted () {
    document.body.classList.add('bg-cream-ns')
  },
  beforeDestroy () {
    document.body.classList.remove('bg-cream-ns')
  },
  methods: {
    login () {
      this.$store.dispatch('login', {email: this.number.replace('+1', '').trim(), password: this.password, query_params: this.$route.query})
    },
    acceptNumber () {
      const formattedNumber = this.number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.number = !formattedNumber[2] ? formattedNumber[1] : '(' + formattedNumber[1] + ') ' + formattedNumber[2] + (formattedNumber[3] ? '-' + formattedNumber[3] : '')
    },
    toggleKeyboard (trueOrFalse) {
      this.$store.commit('TOGGLE_KEYBOARD', trueOrFalse)
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 30em) {
.centered-container-ns {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
}

.border-login {
  border: 1px solid #E8E8E8;
}

#login-card {
  flex-basis: 24rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.sideSpan {
    position: relative;
    left: 7px;
    bottom: -28px;
}
</style>
