<template>
  <div>
    <svg
      width="100"
      height="100"
      viewBox="0 0 205 161"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Add File</title>
      <defs>
        <path
          id="path-1"
          d="M135.410228,17.7777778 L133.810056,12.6438241 C131.465875,5.12280981 124.5033,9.21099974e-15 116.62543,1.0658141e-14 L116.62543,-3.94745964e-16 L70.1914666,-3.94745964e-16 L70.1914666,3.55271368e-15 C62.4074738,4.98260996e-15 55.505393,5.00347022 53.0842963,12.4013641 L53.0842963,12.4013641 L51.3247661,17.7777778 L30,17.7777778 L30,17.7777778 C13.4314575,17.7777778 -2.02906125e-15,31.2092353 0,47.7777778 L-1.0658141e-14,112.222222 C-1.27116884e-15,128.790765 13.4314575,142.222222 30,142.222222 L30,142.222222 L156.666667,142.222222 C173.235209,142.222222 186.666667,128.790765 186.666667,112.222222 L186.666667,47.7777778 C186.666667,31.2092353 173.235209,17.7777778 156.666667,17.7777778 L135.410228,17.7777778 Z"
        />
      </defs>
      <g
        id="Icons"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Add_File"
          transform="translate(-38.000000, -5.000000)"
        >
          <g
            id="Add-File"
            transform="translate(38.000000, 5.000000)"
          >
            <g id="Group">
              <rect
                id="Rectangle-5"
                fill="#F91942"
                x="21.3333333"
                y="14.2222222"
                width="24.8888889"
                height="11.5555556"
                rx="3"
              />
              <g id="Combined-Shape">
                <use
                  fill="#FFE8EC"
                  fill-rule="evenodd"
                  xlink:href="#path-1"
                />
                <path
                  stroke="#F91942"
                  stroke-width="3"
                  d="M156.666667,19.2777778 L134.306583,19.2777778 L132.378004,13.0901721 C130.229172,6.19590899 123.846811,1.5 116.62543,1.5 L70.1914666,1.5 C63.0561399,1.5 56.7292325,6.08651437 54.5098938,12.8679171 L52.4121491,19.2777778 L30,19.2777778 C14.2598846,19.2777778 1.5,32.0376624 1.5,47.7777778 L1.5,112.222222 C1.5,127.962338 14.2598846,140.722222 30,140.722222 L156.666667,140.722222 C172.406782,140.722222 185.166667,127.962338 185.166667,112.222222 L185.166667,47.7777778 C185.166667,32.0376624 172.406782,19.2777778 156.666667,19.2777778 Z"
                />
              </g>
              <circle
                id="Oval-2"
                stroke="#F91942"
                stroke-width="3"
                fill="#FDBAC6"
                cx="176.777778"
                cy="132.333333"
                r="26.6666667"
              />
              <circle
                id="Oval-2"
                stroke="#F91942"
                stroke-width="3"
                fill="#FDBAC6"
                cx="93.3333333"
                cy="80"
                r="35.5555556"
              />
              <path
                id="Combined-Shape"
                d="M182.703704,126.407407 L182.703704,120.518519 C182.703704,118.861664 181.360558,117.518519 179.703704,117.518519 L173.851852,117.518519 C172.194998,117.518519 170.851852,118.861664 170.851852,120.518519 L170.851852,120.518519 L170.851852,126.407407 L164.962963,126.407407 C163.306109,126.407407 161.962963,127.750553 161.962963,129.407407 L161.962963,129.407407 L161.962963,135.259259 C161.962963,136.916114 163.306109,138.259259 164.962963,138.259259 L164.962963,138.259259 L170.851852,138.259259 L170.851852,144.148148 C170.851852,145.805002 172.194998,147.148148 173.851852,147.148148 L173.851852,147.148148 L179.703704,147.148148 C181.360558,147.148148 182.703704,145.805002 182.703704,144.148148 L182.703704,138.259259 L188.592593,138.259259 C190.249447,138.259259 191.592593,136.916114 191.592593,135.259259 L191.592593,129.407407 C191.592593,127.750553 190.249447,126.407407 188.592593,126.407407 L182.703704,126.407407 Z"
                fill="#F91942"
              />
              <rect
                id="Rectangle-5"
                fill="#FDBAC6"
                x="142.222222"
                y="37.3333333"
                width="24.8888889"
                height="11.5555556"
                rx="3"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div class="color-theme fw4 f4">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadPhotoIcon',
  props: {
    title: {
      type: String,
      default: 'Add other photos of item'
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
