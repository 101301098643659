<template>
  <div class="flex flex-column">
    <div class="w-100 pa3 flex items-center">
      <slot name="icon">
        <akko-plan-icon
          alt="AKKO Plan"
          height="60"
          width="60"
        />
      </slot>
      <div class="ml3">
        <h5 class="db cera-round-pro f5 pv2">
          {{ title }}
        </h5>
        <span class="cera-round-pro grey-font pv2">
          {{ subHeading }}
        </span>
      </div>
    </div>
    <div class="pa4 upgrade-info-container details-grey-font inter">
      <div class="flex flex-column">
        <div class="">
          <span class="cera-med db mv2 f5 fw1 black">
            What's Included?
          </span>
          <span class="db mv2 f5 fw4">
            {{ planBenefitsSummary }}
          </span>
        </div>
        <div class="flex flex-wrap f5 pt2 mb2">
          <div
            v-for="(item, index) in planBenefitsLineItems"
            :key="index"
            class="w-50-ns w-100 mb3 flex items-center fw5"
          >
            <img
              alt="✓"
              class="pr2"
              height="14"
              src="@/assets/appicons/Check.svg"
            >
            <span>
              {{ item }}
            </span>
          </div>
        </div>
        <div class="flex justify-between bt b--light-silver pt3 items-center">
          <div>
            <span class="cera-med fw5 f3">
              {{ priceIncrease }}
            </span>
            <span class="inter fw6">
              {{ paymentFrequency }}
            </span>
          </div>
          <div class="fw6 inter">
            <div
              v-for="(deductibleItem, index) in deductibleItems"
              :key="index"
              class="tr"
            >
              {{ deductibleItem }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AkkoPlanIcon from '@/components/registration/multiplePhonePlan/icons/AkkoPlanIcon'

export default {
  name: 'PlanUpgradeCard',
  components: {
    AkkoPlanIcon
  },
  props: {
    title: {
      type: String,
      default: 'Plan Title'
    },
    subHeading: {
      type: String,
      default: 'Subheading'
    },
    planBenefitsSummary: {
      type: String,
      default: 'Plan Benefits'
    },
    planBenefitsLineItems: {
      type: Array,
      default: () => [
        'item 1 description!',
        'item 2 description!',
        'item 3 description!',
        'item 4 description!',
        'item 5 description!',
        'item 6 description!'
      ]
    },
    priceIncrease: {
      type: String,
      default: '+$Value'
    },
    paymentFrequency: {
      type: String,
      default: 'Per Month'
    },
    deductibleItems: {
      type: Array,
      default: () => [
        'item 1',
        'item 2'
      ]
    }
  }
}
</script>
<style scoped>
.upgrade-info-container {
  border-radius: 5px;
  background-color: #eeeeee70;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif;
}

.grey-font {
  color: var(--theme-gray-text);
}

.details-grey-font {
  color: #575757;
}

</style>
