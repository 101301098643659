<template>
  <div class="flex flex-column w-100 justify-center self-center mb3">
    <div class="flex flex-row w-100 items-start ml1 w3">
      <invalid-icon
        v-if="!enteredDiscount.amount"
        :class="{invisible : !isInvalid}"
        class="confirmation-icon mb3 justify-start"
        width="20px"
      />
      <valid-icon
        v-if="!isInvalid && enteredDiscount.amount"
        class="confirmation-icon mb3 justify-start"
        width="20px"
      />
      <div
        v-if="isInvalid || enteredDiscount.amount"
        class="flex inter flex-column w-100 f6 justify-start items-start ml2 mt1"
      >
        <div class="mb3 inter confirmation-text">
          {{ isInvalid ? 'Invalid Code' : 'Valid Code' }}
        </div>
      </div>
    </div>
    <div
      v-show="showInput"
      class="flex flex-row w-100 mb2 items-center self-center container relative justify-between"
    >
      <input
        v-model="discountCode"
        :class="{ 'input-disabled': twoYearsUpfront }"
        :disabled="twoYearsUpfront"
        class="inter f5 input relative"
        placeholder="Enter Discount Code"
      >
      <div
        :class="{disabled: discountCode.length === 0 || twoYearsUpfront}"
        class="flex button inter-medium white f5 pointer"
        @click="submitDiscountCode"
      >
        Apply
      </div>
      <p
        v-if="showLoader"
        class="loader"
      >
        Loading...
      </p>
    </div>
  </div>
</template>

<script>
import {
  ADD_DISCOUNT_CODE,
  GET_PARTNER_DISCOUNTS,
  GET_PARTNER_PROPERTY_ID,
  GET_REGISTRATION_COVERAGES,
  GET_REGISTRATION_DISCOUNTS,
  GET_TWO_YEARS_UPFRONT,
  SET_REGISTRATION_DISCOUNTS_REMOVE
} from '@/store/registrationv2'
import {trackOnMixpanel, MIXPANEL_EVENTS} from '@/services/tracking'

import DISCOUNT_CODE from '../../../../constants/registration/discountCode'

import ValidIcon from '../../forms/icons/ValidIcon.vue'
import InvalidIcon from '../../forms/icons/InvalidIcon.vue'

import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'DiscountCode3c',
  components: {
    ValidIcon,
    InvalidIcon
  },
  props: {
    price: Number,
    totalPrice: String,
    oneTimeVerification: Boolean,
    discount: Number,
    isBiggerCoupon: Boolean,
    totalPriceWithoutDiscount: Number
  },
  data () {
    return {
      familyCode: false,
      loading: false,
      resetInput: true,
      discountCode: ''
    }
  },
  computed: {
    ...mapGetters({
      coverages: GET_REGISTRATION_COVERAGES,
      discounts: GET_REGISTRATION_DISCOUNTS,
      twoYearsUpfront: GET_TWO_YEARS_UPFRONT,
      partnerDiscounts: GET_PARTNER_DISCOUNTS,
      getPartnerPropertyId: GET_PARTNER_PROPERTY_ID
    }),
    showLoader () {
      return !this.resetInput && !(this.enteredDiscount.code_name || this.isInvalid) && this.loading
    },
    isInvalid () {
      return !this.resetInput && (this.enteredDiscount.error || this.familyCode)
    },
    enteredDiscount () {
      return this.discounts.find((discount) => discount.entered) || {}
    },
    total () {
      let total = this.price.toFixed(2) - this.discount.toFixed(2)
      if (this.discount > 0 && total === 0) {
        this.$emit('update:oneTimeVerification', true)
        total = 1
      } else {
        this.$emit('update:oneTimeVerification', false)
      }
      return total.toFixed(2)
    },
    showInput () {
      if (localStorage.getItem('is_input')) {
        const isInput = localStorage.getItem('is_input')
        return JSON.parse(isInput.toLowerCase())
      } else {
        return true
      }
    }
  },
  watch: {
    'price': {
      handler () {
        this.$emit('update:totalPrice', this.total)
      },
      immediate: true
    },
    'isBiggerCoupon': {
      handler () {
        this.$emit('update:totalPrice', this.total)
      },
      immediate: true
    },
    'twoYearsUpfront': {
      handler (val) {
        if (val) {
          this.discountCode = ''
          this.resetInput = true
        }
      },
      immediate: true
    },
    'enteredDiscount' (val) {
      if (val.amount) {
        this.discountCode = ''
        this.resetInput = true
      }
    }
  },
  mounted () {
    this.$emit('update:totalPrice', this.total)

    // Apply discount code from Cookie
    const discountFromCookie = this.$cookies.get('akko-dc')
    if (discountFromCookie) {
      this.discountCode = discountFromCookie
      this.submitDiscountCode()
    }
  },
  updated () {
    this.$emit('update:totalPrice', this.total)

    if (!this.discountCode) {
      this.discountCode = ''
      this.resetInput = true
    }
  },
  methods: {
    ...mapActions({
      addDiscountCode: ADD_DISCOUNT_CODE
    }),
    ...mapMutations({
      removeDiscountCode: SET_REGISTRATION_DISCOUNTS_REMOVE
    }),
    submitDiscountCode () {
      if (this.discountCode.length === 0 || this.twoYearsUpfront) {
        return
      }
      trackOnMixpanel(MIXPANEL_EVENTS.APPLY_PROMOTION)
      this.loading = true
      this.resetInput = false
      if (this.enteredDiscount.code) this.removeDiscountCode(this.enteredDiscount)
      if (!this.discountCode || this.discountCode.toUpperCase() === DISCOUNT_CODE.FAMILY_PLAN.toUpperCase()) {
        this.familyCode = true
        return
      }
      this.familyCode = false
      if (this.isBiggerCoupon) {
        this.removeDiscountCode(DISCOUNT_CODE.FAMILY_PLAN)
      }
      if (this.getPartnerPropertyId !== undefined) {
        const pd = this.partnerDiscounts.find(pd => pd.code.toUpperCase() === this.discountCode.toUpperCase())
        if (!pd) {
          this.addDiscountCode({code: 'invalid', entered: true})
          this.loading = false
          return
        }
      }
      this.addDiscountCode({code: this.discountCode, entered: true})
      this.loading = false
    }
  }
}
</script>

<style scoped>
.container {
  height: 2.5em;
}

.input {
  height: 2.6em;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px;
  border: dashed 1px var(--theme-primary);
  border-radius: 5px;
  width: 78%;
  background-color: #FEF9FA;
}

::placeholder {
  color: #c8c8c8;
}

.button {
  height: 2.6em;
  width: 20%;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-primary);
  border: dashed 1px var(--theme-primary);
  border-radius: 5px;
}

.disabled {
  background-color: var(--theme-light-gray);
  border: solid 1px rgba(0, 0, 0, .2);
  color: #777;
  cursor: not-allowed;
}

.input-disabled {
  background-color: var(--theme-light-gray);
  border: dashed 1px rgba(0, 0, 0, .2);
  color: #777;
  cursor: not-allowed;
}

.loader {
  position: absolute;
  right: 28%;
}

.confirmation-text {
  margin-top: 1px
}

.invisible {
  visibility: hidden;
  display: none;
}
</style>
