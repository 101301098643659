<template>
  <main class="center-ns mb4">
    <div v-if="phoneMake !== '' && phoneMake !=='error'">
      <div class="mw5-ns center-ns tc mb4 pt4 lh-copy flex">
        <input
          id="monthlyToggle1"
          v-model="monthlyToggle"
          class="dn"
          type="radio"
          :value="false"
          name="monthlyToggle"
        >
        <label
          tabindex="0"
          for="monthlyToggle1"
          class="flex-grow pv2 tc b ba br1 br--right b--silver silver bg-white pointer"
          @keyup.enter="monthlyToggle=false"
        >Annual</label>
        <span class="pv2 ph3 tc b silver">OR</span>
        <input
          id="monthlyToggle2"
          v-model="monthlyToggle"
          class="dn"
          type="radio"
          :value="true"
          name="monthlyToggle"
        >
        <label
          tabindex="0"
          for="monthlyToggle2"
          class="flex-grow pv2 tc b ba br1 br--left b--silver silver bg-white pointer"
          @keyup.enter="monthlyToggle=true"
        >Monthly</label>
      </div>

      <div class="mb4 center-ns flex-ns flex-wrap justify-center items-end">
        <div>
          <ak-coverage-card
            :name="coverageName"
            :price="monthlyToggle ? monthly.price : annual.price"
            :deductible="monthlyToggle ? monthly.deductible : annual.deductible"
            :claims="monthlyToggle ? monthly.max_coverage : annual.max_coverage"
            :featured="monthlyToggle ? false : true"
            :coverageid="monthlyToggle ? monthly.id : annual.id"
            :annual="!monthlyToggle"
            :savings="savings"
            :activation-fee="false"
            :plan-type="'PHONE'"
            :phone-repair-deductible="phoneRepairDeductible"
            :phone-replacement-deductible="phoneReplacementDeductible"
            :phone-make="phoneMake"
            :phone-model="phoneModel"
            @click="submit"
          />
        </div>
      </div>

      <div class="mb4 f7 f5-ns mw7-ns center-ns tc cera-round-pro">
        <span class="lh-copy">Plans can be cancelled at anytime for a pro-rated refund.</span>
        <br>
        Contact <a
          class="color-theme lh-copy"
          href="mailto:billing@getakko.com"
        >billing@getakko.com</a>
        <br>
        <br>
        <div class="black f6 mb3 mw7-ns center tc lh-copy">
          Elite Risk Insurance Solutions Lic#0G40499.
        </div>
      </div>
    </div>

    <div v-if="phoneMake === 'error'">
      <div class="mb4 f7 f5-ns mw7-ns center-ns tc cera-round-pro">
        <br>
        <br>
        <br>
        <span class="lh-copy">There was an error accessing this plan,
          <a
            class="color-theme lh-copy"
            href="https://getakko.com/phone-protection-plans/choose-brand/"
          >
            please go back to re-select your plan</a>
        </span>
      </div>
    </div>

    <div v-if="phoneMake === ''">
      <div class="mb4 f7 f5-ns mw7-ns center-ns tc cera-round-pro">
        <br>
        <br>
        <br>
        <span class="lh-copy">Loading plan data...</span>
      </div>
    </div>
  </main>
</template>

<script>
import AkCoverageCard from '@/components/CoverageCard'

export default {
  name: 'AkSelectCoverage',
  components: {
    AkCoverageCard
  },
  beforeRouteLeave (to, from, next) {
    this.$intercom.shutdown()
    next()
  },
  data () {
    return {
      coverageAmount: 2000,
      monthlyToggle: false
    }
  },
  computed: {
    monthly () {
      const phoneModelMonthly = this.$store.state.coverageOptions.filter(
        el => el.id === this.$store.state.phoneModel.monthly_coverage_id)

      return phoneModelMonthly.length > 0 ? phoneModelMonthly[0] : this.$store.state.coverageOptions
        .filter(el => el.max_coverage === this.coverageAmount && el.name.includes('Monthly') && el.name.includes('Adult'))[0]
    },
    annual () {
      const phoneModelAnnual = this.$store.state.coverageOptions.filter(
        el => el.id === this.$store.state.phoneModel.annual_coverage_id)

      return phoneModelAnnual.length > 0 ? phoneModelAnnual[0] : this.$store.state.coverageOptions
        .filter(el => el.max_coverage === this.coverageAmount && el.name.includes('Annual') && el.name.includes('Adult'))[0]
    },
    coverageName () {
      return 'AKKO Phone Plan'
    },
    savings () {
      return ((this.monthly.price * 12) - this.annual.price)
    },
    phoneRepairDeductible () {
      return this.$store.state.phoneModel.repair_deductible
    },
    phoneReplacementDeductible () {
      return this.$store.state.phoneModel.replacement_deductible
    },
    phoneMake () {
      return this.$store.state.phoneModel.make
    },
    phoneModel () {
      return this.$store.state.phoneModel.model
    }
  },
  mounted () {
    // this.$intercom.boot({})
    window.fbq('trackCustom', 'Phone Select Billing Period')
    this.$store.dispatch('getAllCoverageOptions')
    this.$store.dispatch('getPhoneModel', {id: this.$route.query.model})
  },
  methods: {
    submit () {
      this.$store.commit('RESET_PROMO_ALL', false)
      this.$store.commit('RESET_REDEMPTION_ALL', false)
      this.$router.push('/registration/adult-checkout')
    }
  }
}
</script>

<style scoped>
input[type=radio]:checked + label{
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}
</style>
