<template>
  <main>
    <div
      v-if="userIsAuthenticated === false"
      data-v-a23099b6=""
      data-v-67cdb7dd=""
      class="mv2 pa3 br1 flex justify-between f4 b--red bg-washed-red"
    >
      <div
        data-v-a23099b6=""
        class="fw5 mid-gray dark-red"
      >
        <span
          data-v-67cdb7dd=""
          data-v-a23099b6=""
        >Your account still has not been activated. Please complete activation before filing a claim. </span>
        <a
          data-v-67cdb7dd=""
          href="/account-activation/step1"
          class="color-theme"
          data-v-a23099b6=""
        >Click here to
          complete setup.</a>
      </div>
    </div>
    <div v-if="!userHasAccess">
      <h1 class="f2 lh-copy pt3">
        Your Claims
      </h1>
      <ak-alert
        v-if="user.membership_status === 'CANCELED'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing
          team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'UNPAID'"
        color="red"
      >
        <span>Your coverage plan is currently unpaid. Please proceed to <router-link
          to="/account/dashboard"
          class="color-theme"
        >Account Settings</router-link> to resolve or </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'PAST_DUE'"
        color="red"
      >
        <span>Your coverage plan is currently past due. Please proceed to <router-link
          to="/account/dashboard"
          class="color-theme"
        >Account Settings</router-link> to resolve or </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'BANNED'"
        color="red"
      >
        <span>Your account has been banned. If you think this was done in error, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Inquiry Regarding Banned Account"
          class="color-theme"
        >please
          contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INACTIVE'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INVALID_USER'"
        color="red"
      >
        <span>Your account has been marked as invalid by our system due to unpaid premiums or other account issues. If you think this was done in error, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>
    </div>
    <div v-else>
      <div v-if="draft_claims.length">
        <h1 class="f2 lh-copy pt3">
          Your Draft Claim
        </h1>
        <div
          v-if="userHasAccess"
          class="grid-half mt4 mb3 mb4-ns"
        >
          <div
            v-for="(claim, claim_index) in draft_claims"
            :key="claim_index"
            class=""
          >
            <a
              :href="urlToViewClaimDraft(claim.id, claim.version)"
              class="no-underline black"
            >
              <ak-claim-card
                :id="claim.id"
                :description="claim.description"
                :devices="claim.devices"
                :filed-on="claim.filed_on"
                :known-damage="claim.known_damage"
                :loss-day="claim.loss_day"
                :loss-month="claim.loss_month"
                :loss-type="claim.loss_type"
                :loss-year="claim.loss_year"
                :status="claim.status"
              />
            </a>
          </div>
        </div>
      </div>

      <h1 class="f2 lh-copy pt3">
        {{ $t('titles.oclaims', { oclaims: 'Your Open Claims' }) }}
      </h1>
      <div
        v-if="userHasAccess && !draft_claims.length"
        class="mv3 mw5-ns"
      >
        <ak-button
          primary
          block
          @click.native="fileclaim"
        >
          {{ $t('titles.addclaims', { addclaims: 'File New Claim' }) }}
        </ak-button>
      <!-- This also let user fill a claim, let's hide it until we decide about the UI
      <router-link v-if="open_claims.length === 0" to='/file-claim' class="no-underline color-theme">
        <div class="pv5 max-h5 dim mb3 ba b--dashed bw2 b-theme tc bg-white f4 fw5 lh-copy">
          You have no claims yet.
          <br />
          Click to file a new claim
        </div>
      </router-link>
      -->
      </div>
      <div v-if="open_claims">
        <div class="grid-half mt4 mb3 mb4-ns">
          <div
            v-for="(claim, claim_index) in open_claims"
            :key="claim_index"
            class=""
          >
            <a
              :href="urlToViewClaimDetail(claim.id, claim.version)"
              class="no-underline black"
            >
              <ak-claim-card
                :id="claim.id"
                :description="claim.description"
                :devices="claim.devices"
                :filed-on="claim.filed_on"
                :known-damage="claim.known_damage"
                :loss-day="claim.loss_day"
                :loss-month="claim.loss_month"
                :loss-type="claim.loss_type"
                :loss-year="claim.loss_year"
                :status="claim.status"
                :claim-devices="claim.claim_devices"
              />
            </a>
          </div>
        </div>
      </div>

      <div v-if="closed_claims">
        <div v-show="closed_claims && closed_claims.length > 0">
          <h1 class="f2 lh-copy pt3">
            Your Closed Claims
          </h1>
          <div class="grid-half mt4 mb6 mb4-ns">
            <div
              v-for="(claim, claim_index) in closed_claims"
              :key="claim_index"
              class=""
            >
              <router-link
                :to="`/claims/${claim.id}`"
                class="no-underline black"
              >
                <ak-claim-card
                  :id="claim.id"
                  :description="claim.description"
                  :devices="claim.devices"
                  :filed-on="claim.filed_on"
                  :known-damage="claim.known_damage"
                  :loss-day="claim.loss_day"
                  :loss-month="claim.loss_month"
                  :loss-type="claim.loss_type"
                  :loss-year="claim.loss_year"
                  :status="claim.status"
                  :claim-devices="claim.claim_devices"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <ak-fab v-if="userHasAccess" @click.native="fileclaim" /> -->
  </main>
</template>

<script>
import AkButton from './Button'
import AkClaimCard from './ClaimCard'
import AkAlert from './Alert'
import { GET_FEATURE_FLAG, GET_USER } from '@/store/index'
import { CLAIM_V2 } from '@/constants/feature_flag'
import { CUSTOM_CLAIMS_URL, ALLOW_CLAIMS_V2 } from '@/constants/ui-settings/uiSettings'
import { GET_PARTNER } from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'AkClaimsPage',
  components: {
    AkButton,
    AkClaimCard,
    AkAlert
  },
  data () {
    return {
      customClaimsUrl: CUSTOM_CLAIMS_URL,
      allowClaimsV2: ALLOW_CLAIMS_V2
    }
  },
  computed: {
    ...mapGetters({
      partner: GET_PARTNER
    }),
    user () {
      return this.$store.state.user
    },
    closed_claims () {
      return this.$store.state.claims.claims.Closed
    },
    open_claims () {
      return this.$store.state.claims.claims.Open
    },
    draft_claims () {
      return this.$store.state.claims.claims.Draft
    },
    userHasAccess () {
      const status = this.user.membership_status
      return status !== 'BANNED' && status !== 'CANCELED' && status !== 'UNPAID' && status !== 'PAST_DUE' && status !== 'INACTIVE' && status !== 'INVALID_USER'
    },
    userIsAuthenticated () {
      return this.$store.state.claims.claims.authenticated
    },
    uiSettings () {
      const uiSettings = this.partner && this.partner.settings && this.partner.settings.ui_settings
      return uiSettings || {}
    },
    fileClaimsUrl () {
      const customClaimsUrl = this.uiSettings[this.customClaimsUrl]
      return customClaimsUrl ? '/select-loss-type' : '/file-claim'
    },
    viewClaimsUrl () {
      const customClaimsUrl = this.uiSettings[this.customClaimsUrl]
      return customClaimsUrl
    }
  },
  mounted () {
    this.$store.dispatch('getClaims')
    this.$store.dispatch('getDevices')
  },
  methods: {
    fileclaim () {
      // Temporal solution for redirect users with only devices of kind Phone to the new version of the claim.
      const featureFlagClaimV2 = this.$store.getters[GET_FEATURE_FLAG](CLAIM_V2)
      const user = this.$store.getters[GET_USER]
      const { partner_property: partnerProperty } = user
      let path = this.fileClaimsUrl
      if (featureFlagClaimV2 && featureFlagClaimV2.state === true && (partnerProperty === null || (partnerProperty !== null && this.uiSettings[this.allowClaimsV2]))) {
        path = '/select-loss-type'
      }
      this.$router.push(path)
    },
    urlToViewClaimDetail (claimId, claimVersion) {
      const feature = this.$store.getters[GET_FEATURE_FLAG](CLAIM_V2)
      const user = this.$store.getters[GET_USER]
      const { partner_property: partnerProperty } = user
      let path = `/claims/${claimId}`
      if (feature && feature.state && claimVersion === 2 && (partnerProperty === null || this.viewClaimsUrl)) {
        const t = this.$store.state.token
        path = `${this.viewClaimsUrl || process.env.VUE_APP_CLAIMS_V2}claim-detail/${claimId}?t=${t}`
      }
      return path
    },
    urlToViewClaimDraft (claimId, claimVersion) {
      const feature = this.$store.getters[GET_FEATURE_FLAG](CLAIM_V2)
      const user = this.$store.getters[GET_USER]
      const { partner_property: partnerProperty } = user
      let path = `/file-claim2/${claimId}`
      if (feature && feature.state && claimVersion === 2 && (partnerProperty === null || this.viewClaimsUrl)) {
        const t = this.$store.state.token
        path = `${this.viewClaimsUrl || process.env.VUE_APP_CLAIMS_V2}affected-items/${claimId}?t=${t}`
      }
      return path
    }
  }
}
</script>

<style scoped>
</style>
