<template>
  <main>
    <div class="mv2 f5">
      <img
        src="./icons/award.svg"
        alt="award-icon"
        class="dib h2 v-mid mr2"
      >
      By registering a bank account, you will receive <span class="b">$</span><strong><u>20 in deductible credit</u></strong> toward future claims!
    </div>
    <div class="mt3 mb2">
      <ak-button
        v-if="!isDataFilled"
        class="flex-grow flex-grow-off-ns inter fw6"
        primary
        thin
        @click.native="createACH"
      >
        Connect To Your Bank
      </ak-button>
      <div
        v-for="(bankAccountData, index) in bankAccountData.accounts"
        :key="index"
        class="ttc"
      >
        {{ institutionName }} - {{ bankAccountData.type }}
        <br>
        {{ bankAccountData.name }} ({{ bankAccountData.mask }})
        <ak-button
          class="flex-grow flex-grow-off-ns mt3 inter fw6"
          primary
          thin
          @click.native="createACH"
        >
          Update Bank Details
        </ak-button>
      </div>
    </div>
  </main>
</template>

<script>

import { REQUEST_PLAID_KEYS, SET_PAYMENT_DATA, GET_PLAID_DATA, GET_PAYMENT_DATA, CHECK_BANK_ACCOUNT_BALANCE } from '@/store/registrationv2'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import AkButton from '@/components/Button'
import PAYMENT_METHODS from '@/constants/registration/paymentMethods'
import { isEmpty } from 'lodash'

const COMPONENT_NAME = 'ak-ach'

export default {
  name: COMPONENT_NAME,
  components: {
    AkButton
  },
  data () {
    return {
      plaid: window.Plaid
    }
  },
  mounted () {
    const url = new URL(window.location.href)

    if (url.searchParams.get('oauth_state_id')) {
      this.handleRedirect()
    }
  },
  computed: {
    ...mapGetters({
      plaidData: GET_PLAID_DATA,
      paymentData: GET_PAYMENT_DATA
    }),
    isDataFilled () {
      return !isEmpty(this.paymentData) && this.paymentData.method === PAYMENT_METHODS.ACH
    },
    bankAccountData () {
      if (!this.isDataFilled) {
        return {
          accounts: []
        }
      }

      return this.paymentData.accountMetadata
    },
    institutionName () {
      const nameNotNull = !!this.bankAccountData &&
                          !!this.bankAccountData.institution &&
                          !!this.bankAccountData.institution.name
      return nameNotNull ? this.bankAccountData.institution.name : ''
    }
  },
  methods: {
    ...mapActions({
      requestPlaidKeys: REQUEST_PLAID_KEYS,
      checkBalance: CHECK_BANK_ACCOUNT_BALANCE
    }),
    ...mapMutations({
      setPaymentData: SET_PAYMENT_DATA
    }),
    async createACH () {
      await this.requestPlaidKeys()
      const handler = this.plaid.create({
        token: this.plaidData.plaid_link_token,
        onSuccess: (publicToken, metadata) => {
          this.setPaymentData({
            'public_token': publicToken,
            'account_id': metadata.account_id,
            'plaid_client_id': this.plaidData.plaid_client_id,
            accountMetadata: metadata,
            method: PAYMENT_METHODS.ACH
          })
          this.checkBalance({
            'public_token': publicToken,
            'account_id': metadata.account_id
          })
        }
      })
      handler.open()
    },
    async handleRedirect () {
      const handler = this.plaid.create({
        token: this.plaidData.plaid_link_token,
        receivedRedirectUri: window.location.href,
        onSuccess: (publicToken, metadata) => {
          this.setPaymentData({
            'public_token': publicToken,
            'account_id': metadata.account_id,
            'plaid_client_id': this.plaidData.plaid_client_id,
            accountMetadata: metadata,
            method: PAYMENT_METHODS.ACH
          })
          this.checkBalance({
            'public_token': publicToken,
            'account_id': metadata.account_id
          })
        }
      })
      handler.open()
    },
    async submitPayment () {
      return this.isDataFilled
    }
  }
}
</script>
