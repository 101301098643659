<template>
  <div>
    <Alert
      v-if="!user.is_activated"
      color="red"
    >
      <span class="alert-text">Please complete setup by activating your account. Your coverage cannot be activated without completing this step.</span>
      <router-link
        to="/account-activation/step1"
        class="color-theme alert-text"
      >
        Click here to complete setup.
      </router-link>
    </Alert>

    <Alert
      v-if="justActivated"
      color="green"
    >
      <span class="alert-text">Your account has been successfully activated.</span>
      <router-link
        to="/account/security"
        class="color-theme alert-text"
      >
        Click here to set your password.
      </router-link>
    </Alert>

    <Alert
      v-if="devicesLoaded && devices.length === 0 && user.is_activated && user.coverage_item_covered !== 'PHONE'"
      color="red"
    >
      <span class="alert-text">You currently have zero devices added to your account.</span>
      <router-link
        to="/devices"
        class="color-theme alert-text"
      >
        Click here to add a device.
      </router-link>
    </Alert>


    <Alert
      v-else-if="devicesLoaded && devices.length === 0 && user.is_activated"
      color="red"
    >
      <span class="alert-text">Welcome to AKKO!</span>
      <router-link
        to="/devices"
        class="color-theme alert-text"
      >
        Click here to add your phone to your protection plan.
      </router-link>
    </Alert>
  </div>
</template>

<script>
import Alert from '@/components/Alert'
import { mapGetters } from 'vuex'
import { GET_USER_JUST_ACTIVATED } from '@/store/index'

export default {
  name: 'DashboardAlerts',
  components: {
    Alert
  },
  props: {
    devicesLoaded: {
      type: Boolean
    },
    devices: {
      type: Array,
      default: () => []
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      justActivated: GET_USER_JUST_ACTIVATED
    })
  }
}
</script>

<style scoped>

.alert-text{
  font-size: 18px;
}

@media (max-width: 1280px) {
  .alert-text{
  font-size: 14px;
}


}
</style>
