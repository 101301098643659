<template>
  <div
    :class="classlist"
    class="pa4 bb b-theme bw3 br20 tc bg-white shadow-theme h-100 flex flex-column"
  >
    <div class="f3 mb3 flex justify-center items-center color-theme">
      <div
        v-if="icon"
        class="mr2 icon"
        v-html="icon"
      />
      {{ header }}
    </div>
    <div
      :class="compact ? 'f6' : ''"
      class="mt3 silver flex-grow flex-column flex justify-around"
    >
      {{ subheader }}
    </div>
    <slot />
  </div>
</template>

<script>
const SHIELD = `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shield"><path d="M12 22s8-4 8-10V4l-8-2-8 2v8c0 6 8 10 8 10z"></path></svg>`
const CLAIM = `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>`
const ACCOUNT = `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>`

export default {
  name: 'AkTile',
  props: {
    header: {
      required: true
    },
    subheader: {
      type: String
    },
    classlist: {
      type: String
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    icon () {
      if (this.header.includes('Stuff')) return SHIELD
      if (this.header.includes('Cosas')) return SHIELD
      if (this.header.includes('Claim')) return CLAIM
      if (this.header.includes('Reclamaciones')) return CLAIM
      if (this.header.includes('Account')) return ACCOUNT
      if (this.header.includes('Cuenta')) return ACCOUNT
      return false
    }
  }
}
</script>

<style scoped>
.icon {
  height: 25px;
}
</style>
