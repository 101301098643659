<template>
  <div class="p-custom b-gray br3 w-45 w-100-mobile mb0-l mb2">
    <div
      class="flex flex-row justify-between items-center plan-title"
    >
      <div class="flex flex-column items-center flex-grow-1 ">
        <div class="flex flex-row">
          <div>
            <upsell-cyber-scout
              v-if="isCyberScout"
              alt="AKKO Plan"
              height="64"
              width="64"
            />
            <upsell-dashlane
              v-else
              alt="AKKO Plan"
              height="64"
              width="64"
            />
          </div>
          <div
            class="flex flex-column flex-grow-1 mr1"
            style="margin-left: 0.75rem"
          >
            <h5
              class="cera-round-pro mb1"
              style="font-size: 1rem"
            >
              {{ title }}
            </h5>
            <h6
              class="gray-text inter fw3"
              style="font-size: 14px"
            >
              {{ content }}
            </h6>
          </div>
        </div>

        <hr
          v-if="includeOpen"
          :class="{ 'line-transition': includeOpen || open }"
          class="line"
        >
        <div
          :class="{ 'plan-details-container-transition': includeOpen, 'z-9': includeOpen }"
          class="flex flex-column items-start justify-between plan-details-container w-100"
        >
          <div
            :class="{ 'plan-details-container-text-transition': includeOpen }"
            class="plan-details-container-text inter"
          >
            <div class="mt3">
              <div
                v-for="(planInfoString, index) in planInfoStrings"
                :key="index"
                class="pb2 flex items-center"
              >
                <img
                  alt="✓"
                  class="pr2"
                  height="11"
                  src="@/assets/appicons/CheckNotRound.svg"
                >
                {{ planInfoString }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import UpsellCyberScout from '../multiplePhonePlan/icons/UpsellCyberScout.vue'
import UpsellDashlane from '../multiplePhonePlan/icons/UpsellDashlane.vue'

export default {
  name: 'PlanCardUpsell',
  components: {
    UpsellCyberScout,
    UpsellDashlane
  },
  props: {
    title: String,
    isCyberScout: Boolean,
    content: String,
    includeOpen: Boolean,
    planInfoStrings: {
      type: Array,
      default() {
        return [];
      }
    }
  }
}

</script>

<style scoped>

.w-45 {
  width: 48%;
}

.p-custom {
  padding: 1.5rem 2rem;
}

.gray-text {
  color: var(--theme-dark-gray-text);
  line-height: 1.5;
}
.b-gray {
  background-color: var(--theme-light-gray)
}

@media (max-width: 960px) {

.w-45 {
  width: 100%;
}
}

@media (max-width: 800px) {

.w-100-mobile {
  width: 100% !important;
}

}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif;
}

.plan-details-container {
  height: 87%;
  cursor: default;
  border-radius: 0 0 5px 5px;
  max-height: 0;
  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.plan-details-container-transition {
  cursor: pointer;
  max-height: 400px;
}

.pointer {
  cursor: pointer;
}

.plan-details-container-text {
  opacity: 0;
  font-size: 0.85rem !important;
  color: #7d7d7d;
  transition: opacity 0.2s ease-in-out;
  margin-top: -0.35rem;
  z-index: -1;
}

.z-9 {
  z-index: 9;
}

.plan-details-container-text-transition {
  opacity: 1;
  z-index: 1;
}

.line {
  width: 100%;
  height: 1px;
  opacity: 0;
  margin: 0.7rem 0;
  border: 0;
  background-color: #7d7d7d;
  transition: opacity 0.2s ease-in-out;
}

.line-transition {
  opacity: 25%;
}

@media (max-width: 479px) {
  .p-custom {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

</style>
