<template>
  <div class="mb4 fw3 flex flex-column items-baseline">
    <div class="fw3 flex flex-row justify-between items-baseline w-100">
      <div class="b mb2">
        <label class="price cera-round-pro f2">${{ pricePerMonth }}</label>
        <span class="cera-round-pro f5 gray-c small-text"> / month </span>
      </div>
      <div
        v-if="!isMonthly"
        class="mt2 silver"
      >
        <label class="cera-round-pro gray-c fw1 f5 small-text">
          Billed ${{ billedPrice }} / year
        </label>
      </div>
    </div>
    <label
      :class="isPhoneOnly ? 'color-gray' : 'color-theme'"
      class="cera-round-pro"
    >
      {{ planName }}
    </label>
    <div
      v-show="!isPhoneOnly"
      class="mt2"
    >
      <div
        v-for="(item) in totalDeductibles"
        :key="item.message"
        class="flex flex-row flex-wrap items-baseline lh-copy"
      >
        <label class="inter f6 mr1">{{ item.message }}</label>
        <label
          v-if="item.smallPrint"
          class="inter gray-c f7 tl"
        >
          for all other electronic devices and more (25 items total)
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryPrice',
  props: {
    pricePerMonth: {
      type: String,
      default: '0'
    },
    billedPrice: {
      type: String,
      default: '0'
    },
    planName: {
      type: String,
      default: ''
    },
    isPhoneOnly: {
      type: Boolean,
      default: true
    },
    deductibles: {
      type: Number,
      default: 0
    },
    totalDeductibles: {
      type: Array,
      default: () => []
    },
    isMonthly: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.extra-savings {
  padding-top: 0.5em;
  font-size: 0.8em;
}

.v-sub {
  vertical-align: sub;
}

.gray-c {
  color: var(--theme-gray-text);
}
.br-all {
  border-radius: 20px;
}

.savings-banner {
  border-radius: 20px 20px 0 0;
  margin: 0 -1px;
}

.bs-custom {
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
}

.discount {
  margin-bottom: -12px;
}

@media (max-width: 600px) {
  .price {
    font-size: 1.5em;
  }

  .small-text {
    font-size: 0.8em;
  }
}
</style>
