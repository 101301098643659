<template>
  <main>
    <div class="menu-item">
      <done-icon
        v-if="thisStep < currentStep"
        alt="step"
        class="absolute"
      />
      <in-progress-icon
        v-if="thisStep === currentStep"
        alt="step"
        class="absolute"
      />
      <activation-icon
        v-if="thisStep === ACTIVATION_CODE"
        :class="iconClass"
      />
      <personal-icon
        v-if="thisStep === PERSONAL_INFO"
        :class="iconClass"
      />
      <terms-icon
        v-if="thisStep === TERMS_CONDS"
        :class="iconClass"
      />
      <label :class="labelStyle">
        {{ stepLabel }}
      </label>
    </div>
  </main>
</template>

<script>
import ActivationIcon from './icons/ActivationIcon.vue'
import PersonalIcon from './icons/PersonalIcon.vue'
import TermsIcon from './icons/TermsIcon.vue'
import InProgressIcon from './icons/InProgressIcon.vue'
import DoneIcon from './icons/DoneIcon.vue'
import REDEEM_STEPS from '../../../constants/registration/redeemSteps'

const {ACTIVATION_CODE, PERSONAL_INFO, TERMS_CONDS} = REDEEM_STEPS

export default {
  name: 'ProgressStatus',
  components: {
    ActivationIcon,
    PersonalIcon,
    TermsIcon,
    InProgressIcon,
    DoneIcon
  },
  props: {
    currentStep: Number,
    thisStep: Number,
    stepLabel: String
  },
  data () {
    return {
      ACTIVATION_CODE: ACTIVATION_CODE,
      PERSONAL_INFO: PERSONAL_INFO,
      TERMS_CONDS: TERMS_CONDS
    }
  },
  computed: {
    labelStyle () {
      return 'step-label ' + (
        this.currentStep >= this.thisStep
          ? 'inter-medium'
          : 'inter-light'
      )
    },
    iconClass () {
      return 'dib v-mid mr2 icon'
    }
  }
}
</script>

<style scoped>
  .icon {
    width: 46px;
  }
  .step-label {
    font-size: 16px;
  }
  .menu-item {
    min-width: 47px;
    margin-left: -24px;
  }
  @media screen and (max-width: 1030px) {
    .step-label {
      display: none;
    }
  }
  @media screen and (max-width: 700px) {
    .menu-item {
      min-width: 46px;
      margin: 0;
    }
    .icon {
      margin: 0;
    }
  }
  @media screen and (max-width: 400px) {
    .icon {
      width: 35px;
    }
    .menu-item {
      min-width: 35px;
    }
  }
</style>
