<template>
  <div :class="get_class()">
    <div class="mb3 f4">
      <div v-if="status === 'DRAFT'">
        <div
          class="white mh3 mh4-ns dib"
          style="float:right;"
        >
          <router-link
            :to="`/claims/`"
            class="no-underline black"
          >
            <ak-button
              bordered
              thin
              primary
              @click.native="delete_claim"
            >
              Delete
            </ak-button>
          </router-link>
        </div>
      </div>

      <span class="cera-round-pro-medium">{{ $t('titles.claimsid', { claimsid: 'Claim' }) }} #{{ id }}</span>
      <span class="cera-round-pro-medium">({{ status_text }})</span>
    </div>
    <div
      v-if="!featureFlagMultiLossTypeActive"
      class="mv3"
    >
      {{ devices.length }}
      {{ devices.length === 1 ? 'item' : 'items' }} - {{ lossType }}
    </div>
    <div v-else>
      <div
        v-for="claim_device in claimDevices"
        :key="claim_device.id"
      >
        <span class="inter; font-size:1rem;">{{ claim_device.brand }} {{ claim_device.model }}</span><br>
        <span class="inter; font-size:1rem;">{{ claim_device.nickname }}</span><br>
        <div>
          <small
            v-for="loss_type in claim_device.loss_types"
            :key="loss_type.id"
            class="loss-type-label cera-round-pro"
            :style="get_label_style()"
          >
            <span v-if="loss_type.name == 'Other'">{{ loss_type.loss_type_category }} - </span>{{ loss_type.name }}
          </small>
        </div>
        <br>
      </div>
    </div>
    <div class="f6 silver inter">
      {{ filed_days_ago }}
    </div>
  </div>
</template>

<script>
import AkButton from './Button'

import CLAIM_STATUS_TEXT from '@/constants/claims/claimStatusText'
import CLAIM_STATUS from '@/constants/claims/claimStatus'
import { GET_FEATURE_FLAG } from '@/store/index'
import { MULTI_LOSS_TYPE } from '@/constants/feature_flag'

export default {
  name: 'AkClaimCard',
  components: {AkButton},
  props: {
    id: {
      type: Number,
      required: true
    },
    lossType: {
      type: String,
      required: true
    },
    lossDay: {
      type: Number,
      required: true
    },
    lossMonth: {
      type: Number,
      required: true
    },
    lossYear: {
      type: Number,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    knownDamage: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    filedOn: {
      type: String,
      required: true
    },
    devices: {
      type: Array,
      required: false
    },
    claimDevices: {
      type: Array,
      required: false
    }
  },
  computed: {
    filed_days_ago () {
      const filedTime = new Date(this.filedOn)
      const day = filedTime.getUTCDate()
      const month = filedTime.getMonth() + 1
      const year = filedTime.getFullYear()
      return `Filed on ${year}-${month}-${day}`
    },
    status_text () {
      return CLAIM_STATUS_TEXT[this.status]
    },
    featureFlagMultiLossTypeActive () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](MULTI_LOSS_TYPE)
      return feature ? feature.state : false
    }
  },
  methods: {
    get_class () {
      let theme = [CLAIM_STATUS.NEW, CLAIM_STATUS.APPROVED].includes(this.status) ? 'b-theme' : 'gray'
      if (this.status === CLAIM_STATUS.DRAFT) {
        theme = 'orange'
      }
      return 'pa4 bg-white shadow-theme bl bw3 br20 hover-lift ' + theme
    },
    get_label_style () {
      let theme = [CLAIM_STATUS.NEW, CLAIM_STATUS.APPROVED].includes(this.status) ? 'background: var(--theme-primary);' : 'background: gray;'
      if (this.status === CLAIM_STATUS.DRAFT) {
        theme = 'background: orange;'
      }
      return theme
    },
    delete_claim () {
      this.$store.dispatch(
        'deleteClaim',
        {id: this.id}
      )
    }
  }
}
</script>

<style>

</style>
