import { render, staticRenderFns } from "./RedeemProgress.vue?vue&type=template&id=8b5ed816&scoped=true"
import script from "./RedeemProgress.vue?vue&type=script&lang=js"
export * from "./RedeemProgress.vue?vue&type=script&lang=js"
import style0 from "./RedeemProgress.vue?vue&type=style&index=0&id=8b5ed816&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b5ed816",
  null
  
)

export default component.exports