<template>
  <div
    class="br3 bw1 b--silver bg-white items-center flex flex-row main-container mb3 pointer"
    @click="onClick"
  >
    <new-device class="mr3" />
    <div class="flex flex-column justify-center h-100">
      <div class="cera-round-pro">
        Add Another Device
      </div>
      <div
        v-if="discount"
        class="black-30 inter f6 mt1"
      >
        Save Additional 5%
      </div>
    </div>
  </div>
</template>

<script>
import NewDevice from '../icons/NewDevice.vue'

export default {
  name: 'AddPhone',
  components: {
    NewDevice
  },
  props: {
    onClick: Function,
    discount: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .main-container {
    border: 1px dashed var(--theme-gray);
    width: 45%;
    padding: 1em 1.4em;
    min-height: 9em;
    min-width: 16em;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 1100px) {
    .main-container {
      width: 49%;
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }
  @media (max-width: 1000px) {
    .main-container {
      width: 90%;
      height: 8em;
    }
  }
  @media (max-width: 600px) {
    .main-container {
      height: 6em;
      width: 100%;
    }
  }
</style>
