<template>
  <main class="">
    <div class="flex items-end ">
      <span class="f2 b title-responsive ml4-ns">{{ $t('menu.accountsettings', { accountsettings: 'Account Settings' }) }}</span>
    </div>
    <div class="mv4-l mv2">
      <ak-alert
        v-for="(message, index) in $store.state.messages"
        :key="index"
        :color="message.color"
        :deleteable="message.deleteable"
        @close="clearMessage(index)"
      >
        <span>{{ message.text }}</span>
      </ak-alert>
    </div>
    <!-- COMMENTED BY REQUEST -->
    <!-- <div class="flex items-center mb3 pa1 border">
       <img alt="" class="mr1 image-width" src="@/assets/appicons/Calque.svg"/>
        <span class="f5-l f7-m f7   cera-round-pro color-theme">{{ $t('menu.alert', { alert: ' Under Construction - We are hard at work to improve this section of our platform. Thank you for your patience and continued support!' }) }}</span>
      </div> -->
    <div class="flex flex-wrap justify-evenly content">
      <router-view />
      <div class="w-30-l pa4 bg-white account-card mb4-l mb4-m pointer">
        <router-link
          class="no-underline dib-ns"
          to="/account/personal"
        >
          <div class="responsive-card">
            <img
              alt=""
              class="mr1 mb1"
              src="@/assets/appicons/PersonalInformation.svg"
            >
            <div class="flex flex-column justify-center text-responsive">
              <h4 class="mb2 cera-round-pro-medium fw1">
                {{ $t('menu.personalinformation', { personalinformation: 'Personal Information' }) }}
              </h4>
              <span class="font-size-text inter">{{ $t('menu.personalinformationdescription', { personalinformationdescription: 'View and modify your personal information' }) }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="w-30-l pa4 bg-white account-card mb4-l mb4-m mb0 pointer">
        <router-link
          class="no-underline dib-ns"
          to="/account/security"
        >
          <div class="responsive-card">
            <img
              alt=""
              class="mr1 mb1"
              src="@/assets/appicons/Security.svg"
            >
            <div class="flex flex-column justify-center text-responsive">
              <h4 class="mb2 cera-round-pro-medium fw1">
                {{ $t('menu.loginsecurity', { loginsecurity: 'Login & Security' }) }}
              </h4>
              <span class="font-size-text inter">{{ $t('menu.loginsecuritydescription', { loginsecuritydescription: 'View & manage your security details' }) }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="w-30-l pa4 bg-white account-card mb4-l mb4-m mb0 pointer">
        <router-link
          class="no-underline dib-ns"
          to="/account/plans"
        >
          <div class="responsive-card">
            <img
              alt=""
              class="mr1 mb1"
              src="@/assets/appicons/Plans.svg"
            >
            <div class="flex flex-column justify-center text-responsive">
              <h4 class="mb2 cera-round-pro-medium fw1">
                {{ $t('menu.plans', { plans: 'Plans' }) }}
              </h4>
              <span class="font-size-text inter">{{ $t('menu.plansdescription', { plansdescription: 'View, change and add plans to your account' }) }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="w-30-l pa4 bg-white account-card mb4-l mb4-m pointer">
        <router-link
          class="no-underline dib-ns"
          to="/account/billing"
        >
          <div class="responsive-card">
            <img
              alt=""
              class="mr1 mb1"
              src="@/assets/appicons/BillingInformation.svg"
            >
            <div class="flex flex-column justify-center text-responsive">
              <h4 class="mb2 cera-round-pro-medium fw1">
                {{ $t('menu.billing', { billing: 'Billing' }) }}
              </h4>
              <span class="font-size-text inter">{{ $t('menu.billingdescription', { billingdescription: 'View and update billing details' }) }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <!-- <div class="w-30-l pa4 bg-white account-card mb4-l mb4-m mb0 pointer">
        <router-link class="no-underline dib-ns" to="/account/billing-partners">
          <div class="responsive-card">
            <img alt="" class="mr1 mb1" src="@/assets/appicons/BillingInformation.svg"/>
            <div class="flex flex-column justify-center text-responsive">
              <h4 class="mb2">{{ $t('menu.billingpartners', { billingpartners: 'Billing Partners' }) }}</h4>
              <span class="f6 inter">{{ $t('menu.billingpartnersdescription', { billingpartnersdescription: 'Select from a Trusted Partner' }) }}</span>
            </div>
          </div>
        </router-link>
      </div>  -->
      <div
        v-if="uiSettings[showReferrals] !== false"
        class="w-30-l pa4 bg-white account-card mb4-l mb4-m mb0 pointer"
      >
        <router-link
          class="no-underline dib-ns"
          to="/account/referrals"
        >
          <div class="responsive-card">
            <img
              alt=""
              class="mr1 mb1"
              src="@/assets/appicons/Referrals.svg"
            >
            <div class="flex flex-column justify-center text-responsive">
              <h4 class="mb2 cera-round-pro-medium fw1">
                {{ $t('menu.referrals', { referrals: 'Referrals' }) }}
              </h4>
              <span class="font-size-text inter">{{ $t('menu.referralsdescription', { referralsdescription: 'Earn deductible credits by sharing AKKO' }) }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <div
        v-else
        class="w-30-l empty-card pa4 bg-white mb4-l mb4-m mb0 o1"
      />
      <div class="w-30-l empty-card pa4 bg-white mb4-l mb4-m mb0 o1" />
    </div>
  </main>
</template>

<script>
import AkAlert from '../Alert'
import { BROWSER } from '../../constants/account-settings/accountSettings'
import { USER_WEB_SHOW_REFERRALS } from '@/constants/ui-settings/uiSettings'
import { GET_PARTNER } from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'UnifiedAccountSettings',
  components: {
    AkAlert,
  },
  data () {
    return {
      showPasswordModal: false,
      password: '',
      confirm: '',
      showNameModal: false,
      first_name: '',
      last_name: '',
      showSchoolModal: false,
      school: '',
      year: 0,
      showPhoneModal: false,
      phone_no: '',
      phone_verification_code: '',
      showCreditCardModal: false,
      showEditReferralCodeModal: false,
      referralCode: '',
      showShareReferralCodeModal: false,
      showPasswords: false,
      showSuccessResetMessage: false,
      showPhoneVerificationModal: false,
      copyPressed: false,
      showReferrals: USER_WEB_SHOW_REFERRALS
    }
  },
  computed: {
    ...mapGetters({
      partner: GET_PARTNER
    }),
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf(BROWSER) !== -1)
    },
    user () {
      return this.$store.state.user
    },
    uiSettings () {
      const uiSettings = this.partner && this.partner.settings && this.partner.settings.ui_settings
      return uiSettings || {}
    }
  },
  watch: {
    '$store.state.loading' (val) {
      if (!val && this.$store.state.errors.length === 0) {
        this.showSuccessResetMessage = true
        this.showEditReferralCodeModal = false
      }
    }
  },
  mounted () {
    this.$store.commit('SET_ERRORS', null)
    this.$store.dispatch('getCoverageOptions')
    this.$store.dispatch('getUserInformation')

    if (this.user.referral_code) this.referralCode = this.user.referral_code

    const urlSearch = location.search
    if (urlSearch.includes('update-payment=y')) {
      this.showCreditCardModal = true
    }
  },
  methods: {
    clearMessage (index) {
      this.$store.commit('DELETE_MESSAGE', index)
    }
  }
}
</script>

<style scoped>

.account-card {
  border-radius: 5px;
  border: 1px solid #E8E8E8;
}

.height {
  height: 50px;
}

.justify-evenly {
  justify-content: space-evenly;
}


.title-responsive {
    margin-top: 1.5rem;
  }

.border {
  border: 1px dashed var(--theme-primary);
  border-radius: 5px;
}

.image-width {
  width: 27px;
}

.font-size-text {
  font-size: 12px;
}

@media (min-width: 1281px) {
  .text-responsive {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1280px) {
  .account-card {
    width: 45%
  }

  .text-responsive {
    margin-left: 1rem;
  }

  .empty-card {
    width: 45%
  }

  .responsive-card {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .account-card {
    width: 100%;
    border: none;
    border-bottom: 1px solid #E8E8E8;
    border-radius: 0px;
    margin-bottom: 0px;
    padding: 1.9rem 0rem;
  }

  .account-card:nth-child(5) {
    border: none;
  }


  .empty-card {
    display: none;
  }

  .responsive-card {
    display: flex;
    flex-direction: row;
  }

  .title-responsive {
    font-size: 24px;
    margin-bottom: -10px;
    margin-top: 1rem;
  }

  .text-responsive {
    margin-left: 1rem;
  }

  .height {
    height: auto;
  }
}


a {
  color: inherit;
}

</style>
