import axios from 'axios'
import router from '@/router'
import {removeElementFromArray} from '@/utils'
import * as Sentry from '@sentry/vue'

import INTERVALS from '@/constants/registration/planIntervals'
import PAYMENT_METHODS from '@/constants/registration/paymentMethods'
import {TWO_YEAR_UPFRONT_KEY} from '@/constants/registration/discountKeys'
import {SET_ERRORS, SET_LOADING, SET_TOKEN} from './mutations'
import {trackConversion} from '@/services/tracking'
import DISCOUNT_CODE from '../constants/registration/discountCode'
import {DEFAULT_COUNTRY_CODE} from '../constants/registration/defaultCountryCode'
import { UPSELL } from '../constants/feature_flag'

const api = process.env.VUE_APP_API_URL

const PAYMENT_METHOD_ENDPOINTS = {
  [PAYMENT_METHODS.ACH]: '/registrations/ach/'
}

// Country
const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST'
const SET_CURRENT_COUNTRY_CURRENCY = 'SET_CURRENT_COUNTRY_CURRENCY'

// GETTERS
export const GET_REGISTRATION_COVERAGES = 'GET_REGISTRATION_COVERAGES'
export const GET_REGISTRATION_PRIMARY_EMAIL = 'GET_REGISTRATION_PRIMARY_EMAIL'
export const GET_REGISTRATION_STUDENT_DATA = 'GET_REGISTRATION_STUDENT_DATA'
export const GET_REGISTRATION_DISCOUNTS = 'GET_REGISTRATION_DISCOUNTS'
export const GET_REGISTRATION_INTERVAL = 'GET_REGISTRATION_INTERVAL'
export const GET_REGISTRATION_QUANTITY = 'GET_REGISTRATION_QUANTITY'
export const GET_PAYMENT_DATA = 'GET_PAYMENT_DATA'
export const GET_PLAID_DATA = 'GET_PLAID_DATA'
export const GET_TWO_YEARS_UPFRONT = 'GET_TWO_YEARS_UPFRONT'
export const GET_INTERNAL_DISCOUNT_VALUES = 'GET_INTERNAL_DISCOUNT_VALUES'
export const GET_REGISTRATION_SELECTED_COVERAGES = 'GET_REGISTRATION_SELECTED_COVERAGES'
export const GET_DISCOUNT_MESSAGE = 'GET_DISCOUNT_MESSAGE'
export const GET_BANKACCOUNT_ERROR = 'GET_BANKACCOUNT_ERROR'
export const GET_PARTNER_DISCOUNTS = 'GET_PARTNER_DISCOUNTS'
export const GET_PARTNER_DEVICES = 'GET_PARTNER_DEVICES'
export const GET_PARTNER_UI_SETTINGS = 'GET_PARTNER_UI_SETTINGS'
export const GET_PARTNER_STRATEGY_NAME = 'GET_PARTNER_STRATEGY_NAME'
export const GET_PRODUCT_ID_UPSELL = 'GET_PRODUCT_ID_UPSELL'

// MUTATIONS
export const SET_REGISTRATION_COVERAGES = 'SET_REGISTRATION_COVERAGES'
export const SET_REGISTRATION_COVERAGE_VALUE = 'SET_REGISTRATION_COVERAGE_VALUE'
export const SET_REGISTRATION_PRIMARY_EMAIL = 'SET_REGISTRATION_PRIMARY_EMAIL'
export const SET_REGISTRATION_REFFERAL = 'SET_REGISTRATION_REFFERAL'
export const SET_REGISTRATION_LEAD_ID = 'SET_REGISTRATION_LEAD_ID'
export const SET_REGISTRATION_STUDENT_DATA = 'SET_REGISTRATION_STUDENT_DATA'
export const SET_REGISTRATION_DISCOUNTS_ADD = 'SET_REGISTRATION_DISCOUNTS_ADD'
export const SET_REGISTRATION_DISCOUNTS_REMOVE = 'SET_REGISTRATION_DISCOUNTS_REMOVE'
export const SET_REGISTRATION_ENTERED_DISCOUNT_REMOVE = 'SET_REGISTRATION_ENTERED_DISCOUNT_REMOVE'
export const SET_REGISTRATION_DISCOUNTS_REMOVE_ALL = 'SET_REGISTRATION_DISCOUNTS_REMOVE_ALL'
export const SET_REGISTRATION_INTERVAL = 'SET_REGISTRATION_INTERVAL'
export const SET_REGISTRATION_QUANTITY_VALUE = 'SET_REGISTRATION_QUANTITY_VALUE'
export const SET_REGISTRATION_CLEAR = 'SET_REGISTRATION_CLEAR'
export const SET_REGISTRATION_ERROR = 'SET_REGISTRATION_ERROR'
export const SET_REGISTRATION_STATE_ON = 'SET_REGISTRATION_STATE_ON'
export const SET_REGISTRATION_STATE_OFF = 'SET_REGISTRATION_STATE_OFF'
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA'
export const SET_PARTNER_DISCOUNTS = 'SET_PARTNER_DISCOUNTS'
export const SET_PLAID_DATA = 'SET_PLAID_DATA'
export const SET_TWO_YEARS_UPFRONT = 'SET_TWO_YEARS_UPFRONT'
export const SET_INTERNAL_DISCOUNT_VALUES = 'SET_INTERNAL_DISCOUNT_VALUES'
export const SET_REGISTRATION_SELECTED_COVERAGES = 'SET_REGISTRATION_SELECTED_COVERAGES'
export const SET_REGISTRATION_SELECTED_COVERAGE_VALUE = 'SET_REGISTRATION_SELECTED_COVERAGE_VALUE'
export const SET_DISCOUNT_MESSAGE = 'SET_DISCOUNT_MESSAGE'
export const SET_ACCOUNT_BALANCE = 'SET_ACCOUNT_BALANCE'
export const SET_BANKACCOUNT_ERROR = 'SET_BANKACCOUNT_ERROR'
export const SET_PARTNER_PROPERTY_ID = 'SET_PARTNER_PROPERTY_ID'
export const SET_PARTNER_DEVICES = 'SET_PARTNER_DEVICES'
export const SET_PARTNER_UI_SETTINGS = 'SET_PARTNER_UI_SETTINGS'
export const SET_PARTNER_STRATEGY_NAME = 'SET_PARTNER_STRATEGY_NAME'
export const SET_REGISTRATION_COVERAGE_OPTIONS = 'SET_REGISTRATION_COVERAGE_OPTIONS'
export const ADD_PHONE_TO_PLAN = 'ADD_PHONE_TO_PLAN'
export const SET_PHONE_MAKE_AT_INDEX = 'SET_PHONE_MAKE_AT_INDEX'
export const SET_PHONE_MODEL_AT_INDEX = 'SET_PHONE_MODEL_AT_INDEX'
export const RESET_SELECTED_PHONES = 'RESET_SELECTED_PHONES'
export const REMOVE_PHONE_AT_INDEX = 'REMOVE_PHONE_AT_INDEX'
export const SET_CURRENCY_BASED_PHONE_MODELS = 'SET_CURRENCY_BASED_PHONE_MODELS'
export const SET_PRODUCT_UPSELL = 'SET_PRODUCT_UPSELL'
export const SET_PRODUCT_PRICE = 'SET_PRODUCT_PRICE'
export const SET_USER_CONTRACT = 'SET_USER_CONTRACT'
export const SET_UPSELL_ERROR = 'SET_UPSELL_ERROR'

// ACTIONS
export const ADD_DISCOUNT_CODE = 'ADD_DISCOUNT_CODE'
export const CHECK_PHONE_NUMBER = 'CHECK_PHONE_NUMBER'
export const RESET_REGISTRATION = 'RESET_REGISTRATION'
export const REQUEST_PARTNER_DISCOUNTS = 'REQUEST_PARTNER_DISCOUNTS'
export const SUBMIT_REGISTRATION = 'SUBMIT_REGISTRATION_V2'
export const REQUEST_PLAID_KEYS = 'REQUEST_PLAID_KEYS'
export const REQUEST_INTERNAL_DISCOUNT_VALUES = 'REQUEST_INTERNAL_DISCOUNT_VALUES'
export const CHECK_GENERAL_ERRORS = 'CHECK_GENERAL_ERRORS'
export const CHECK_BANK_ACCOUNT_BALANCE = 'CHECK_BANK_ACCOUNT_BALANCE'
export const GET_PARTNER_PROPERTY_ID = 'GET_PARTNER_PROPERTY_ID'
export const DECRYPT_PARTNER_DEVICES = 'GET_PARTNER_DEVICES'
export const PARTNER_UI_SETTINGS = 'PARTNER_UI_SETTINGS'
export const REQUEST_UPSELL = 'REQUEST_UPSELL'

const _getErrorMessage = exception => {
  let message = exception
  try {
    message = exception.response.data.message
  } catch (err) {
    console.warn('Error parsing error message:', err)
  }
  return message
}

const state = {
  coverages: [],
  discounts: [],
  interval: INTERVALS.ANNUAL,
  primaryEmail: null,
  quantity: {},
  error: '',
  registering: false,
  partnerDiscounts: [],
  paymentData: {},
  plaidData: {},
  twoYearsUpfront: false,
  internalDiscountValues: {},
  selectedCoverages: [],
  partnerDevices: [],
  discountMessage: {
    error: null,
    message: null
  },
  error_bankaccount: null,
  studentData: {},
  refferal: null,
  leadId: null,
  addressState: null,
  partner_property_id: undefined,
  partnerStrategyName: undefined,
  ui_settings: {},
  registrationCoverageOptions: [],
  countries: [],
  selectedCountryCurrency: {},
  selectedPhones: [{
    make: '',
    model: ''
  }],
  currencyBasedPhoneModels: [],
  productUpsell: undefined,
  productPrice: undefined,
  upsellError: ''
}

const getters = {
  [GET_REGISTRATION_COVERAGES]: state => state.coverages,
  [GET_REGISTRATION_PRIMARY_EMAIL]: state => state.primaryEmail,
  [GET_REGISTRATION_DISCOUNTS]: state => state.discounts,
  [GET_REGISTRATION_INTERVAL]: state => state.interval,
  [GET_REGISTRATION_QUANTITY]: state => state.quantity,
  [GET_PAYMENT_DATA]: state => state.paymentData,
  [GET_PLAID_DATA]: state => state.plaidData,
  [GET_DISCOUNT_MESSAGE]: state => state.discountMessage,
  [GET_BANKACCOUNT_ERROR]: state => state.error_bankaccount,
  [GET_TWO_YEARS_UPFRONT]: state => state.twoYearsUpfront,
  [GET_INTERNAL_DISCOUNT_VALUES]: state => state.internalDiscountValues,
  [GET_REGISTRATION_SELECTED_COVERAGES]: state => state.selectedCoverages,
  [GET_DISCOUNT_MESSAGE]: state => state.discountMessage,
  [GET_REGISTRATION_STUDENT_DATA]: state => state.studentData,
  [GET_PARTNER_DISCOUNTS]: state => state.partnerDiscounts,
  [GET_PARTNER_PROPERTY_ID]: state => state.partner_property_id,
  [GET_PARTNER_DEVICES]: state => [...state.partnerDevices],
  [GET_PARTNER_UI_SETTINGS]: state => state.ui_settings,
  [GET_PARTNER_STRATEGY_NAME]: state => state.partnerStrategyName,
  [GET_PRODUCT_ID_UPSELL]: state => state.productUpsell.id,

  getDeviceModelData: (state) => (deviceMake, deviceModel) => {
    return state.currencyBasedPhoneModels.find(phone => phone.make === deviceMake && phone.model === deviceModel)
  },
  getPhoneCoverageData: (state, getters) => (deviceMake, deviceModel, planIntervalKey) => { // TODO: rename to phone only registration
    let phone = getters.getDeviceModelData(deviceMake, deviceModel)
    if (phone === undefined) return undefined
    let phoneCoverage = state.registrationCoverageOptions.find(coverage => coverage.id === phone[planIntervalKey])
    if (phoneCoverage === undefined) return undefined
    return {
      make: deviceMake,
      model: deviceModel,
      ...phoneCoverage
    }
  },
  validSelectedPhones: (state) => state.selectedPhones.filter(phone => phone.make !== '' && phone.model !== ''),
  selectedPhoneModels: (state, getters) => {
    return getters.validSelectedPhones.map((phone) => getters.getDeviceModelData(
      phone.make,
      phone.model
    ))
  },
  multiPhonePlanPrice: (state, getters) => (coverageIdKeys, discountRate) => { // TODO: refactor to take in currency as param
    const devicePriceReducer = (currentSums, selectedPhone) => {
      coverageIdKeys.forEach((coverageIdKey, index) => {
        let deviceCoverage = getters.getPhoneCoverageData(selectedPhone.make, selectedPhone.model, coverageIdKey)
        currentSums[index] += deviceCoverage === undefined ? 0 : deviceCoverage.price
      })
      return currentSums
    }

    const calculateDiscount = (totalPrice) => getters.validSelectedPhones.length > 1 ? totalPrice * discountRate : totalPrice

    let totalPrices = getters.validSelectedPhones.reduce(devicePriceReducer, new Array(coverageIdKeys.length).fill(0))

    return totalPrices.map((totalPrice) => {
      let priceWithDiscount = calculateDiscount(totalPrice)
      return {
        totalPrice,
        priceWithDiscount
      }
    })
  },
  /**
   *
   * @param {array.<object>} planIntervals array of plan intervals to be queried
   * @param {string} planType plan type to be substring matched
   * @returns {array.<object>} array of coverages in the sequence plan intervals were provided
   */
  getAkkoPlanCoverageData: (state) => (planIntervals = [{}], planType) => {
    /**
     * Takes in a plan interval and coverage and returns true if
     * input coverage contains the correct tag AND the currency
     * matches what the user has selected. This is the most up to date
     * way to verify if an AKKO coverage is valid.
     */
    const isValidAkkoCoverage = (interval) => (coverage) => {
      let targetTag = planType.concat('_', interval).toUpperCase()
      let containsCorrectTag = coverage.tags === null
        ? false
        : coverage.tags.some(tag => tag.localeCompare(targetTag) === 0)
      /**
       * TODO: remove this once all database coverages are assigned a currency_id
       * currently as of 11/18/2022 many USD coverages have null for their currency_id which results in
       * the coverages api sending them along with CAD coverages
       */
      let matchCurrency = state.selectedCountryCurrency.currency_code === 'CAD'
        ? coverage.currency_id === state.selectedCountryCurrency.currency_id
        : true
      return matchCurrency && containsCorrectTag
    }
    let validCoverages = planIntervals.map((interval) => {
      let validCoverage = state.registrationCoverageOptions.find(isValidAkkoCoverage(interval))
      return validCoverage === undefined ? {} : validCoverage
    })
    return validCoverages
  }
}

const mutations = {
  [SET_PARTNER_PROPERTY_ID] (state, partnerPropertyId) {
    state.partner_property_id = partnerPropertyId
  },
  [SET_PARTNER_UI_SETTINGS] (state, uiSettings) {
    state.ui_settings = uiSettings
  },
  [SET_REGISTRATION_COVERAGES] (state, coverages) {
    state.coverages = coverages
  },
  [SET_REGISTRATION_COVERAGE_VALUE] (state, {index, key, value}) {
    state.coverages[index][key] = value
  },
  [SET_REGISTRATION_PRIMARY_EMAIL] (state, email = '') {
    state.primaryEmail = email.trim().toLowerCase()
  },
  [SET_REGISTRATION_REFFERAL] (state, refferal = null) {
    state.refferal = refferal
  },
  [SET_REGISTRATION_LEAD_ID] (state, leadId = null) {
    state.leadId = leadId
  },
  [SET_PAYMENT_DATA] (state, data) {
    state.paymentData = data
  },
  [SET_BANKACCOUNT_ERROR] (state, data) {
    state.error_bankaccount = data
  },
  [SET_REGISTRATION_DISCOUNTS_ADD] (state, discount) {
    if (discount) {
      const lookupKey = discount.code ? 'code' : discount.key_name ? 'key_name' : 'keyName'
      removeElementFromArray(state.discounts, discount[lookupKey], lookupKey)
      state.discounts.push(discount)
    }
  },
  [SET_REGISTRATION_DISCOUNTS_REMOVE] (state, discount) {
    if (discount) {
      const lookupKey = discount.code ? 'code' : discount.key_name ? 'key_name' : 'keyName'
      removeElementFromArray(state.discounts, discount[lookupKey], lookupKey)
    }
  },
  [SET_REGISTRATION_ENTERED_DISCOUNT_REMOVE] (state) {
    removeElementFromArray(state.discounts, true, 'entered')
  },
  [SET_REGISTRATION_DISCOUNTS_REMOVE_ALL] (state) {
    state.discounts = []
    state.discountMessage = {
      error: null,
      message: null
    }
  },
  [SET_PARTNER_DISCOUNTS] (state, discounts) {
    state.partnerDiscounts = discounts
  },
  [SET_REGISTRATION_INTERVAL] (state, interval = INTERVALS.ANNUAL) {
    if (Object.values(INTERVALS).includes(interval)) {
      state.interval = interval
    }
  },
  [SET_REGISTRATION_QUANTITY_VALUE] (state, {key, value = 0}) {
    state.quantity[key] = value
  },
  [SET_REGISTRATION_CLEAR] (state) {
    state.coverages = []
    state.discounts = []
    state.interval = INTERVALS.ANNUAL
    state.primaryEmail = ''
    state.quantity = {}
    state.error = ''
    state.registering = false
  },
  [SET_REGISTRATION_ERROR] (state, error) {
    state.error = error
  },
  [SET_REGISTRATION_STATE_ON] (state) {
    state.registering = true
  },
  [SET_REGISTRATION_STATE_OFF] (state) {
    state.registering = false
  },
  [SET_PLAID_DATA] (state, payload) {
    state.plaidData = payload
  },
  [SET_TWO_YEARS_UPFRONT] (state, value) {
    state.twoYearsUpfront = value
  },
  [SET_INTERNAL_DISCOUNT_VALUES] (state, {key, value}) {
    state.internalDiscountValues[key] = value
  },
  [SET_REGISTRATION_SELECTED_COVERAGES] (state, coverages) {
    state.selectedCoverages = coverages
  },
  [SET_REGISTRATION_SELECTED_COVERAGE_VALUE] (state, {index, key, value}) {
    state.selectedCoverages.annual.coverages[index][key] = value
    state.selectedCoverages.monthly.coverages[index][key] = value
  },
  [SET_DISCOUNT_MESSAGE] (state, message) {
    state.discountMessage = message
  },
  [SET_REGISTRATION_STUDENT_DATA] (state, data) {
    state.studentData = data
  },
  [SET_PARTNER_DEVICES] (state, devices) {
    state.partnerDevices = devices
  },
  [SET_PARTNER_STRATEGY_NAME] (state, strategyName) {
    state.partnerStrategyName = strategyName
  },
  [SET_REGISTRATION_COVERAGE_OPTIONS] (state, payload) {
    state.registrationCoverageOptions = payload
  },
  [SET_COUNTRY_LIST] (state, payload) {
    state.countries = payload
  },
  [SET_CURRENT_COUNTRY_CURRENCY] (state, payload) {
    state.selectedCountryCurrency = payload
  },
  [ADD_PHONE_TO_PLAN] (state) {
    state.selectedPhones.push({make: '', model: ''})
  },
  [SET_PHONE_MAKE_AT_INDEX] (state, payload) {
    state.selectedPhones[payload.index].make = payload.value
  },
  [SET_PHONE_MODEL_AT_INDEX] (state, payload) {
    let deviceMake = state.selectedPhones[payload.index].make
    state.selectedPhones.splice(payload.index, 1, {make: deviceMake, model: payload.value})
  },
  [REMOVE_PHONE_AT_INDEX] (state, payload) {
    state.selectedPhones.splice(payload, 1)
  },
  [RESET_SELECTED_PHONES] (state) {
    state.selectedPhones.splice(0)
    state.selectedPhones.push({make: '', model: ''})
  },
  [SET_CURRENCY_BASED_PHONE_MODELS] (state, payload) {
    state.currencyBasedPhoneModels = payload
  },
  [SET_PRODUCT_UPSELL] (state, payload) {
    state.productUpsell = payload
  },
  [SET_PRODUCT_PRICE] (state, payload) {
    state.productPrice = payload
  },
  [SET_USER_CONTRACT] (state, payload) {
    state.contract = payload
  },
  [SET_UPSELL_ERROR] (state, payload) {
    state.upsellError = payload
  },
  setAddressState (state, payload) {
    state.addressState = payload
  }
}

const actions = {
  [ADD_DISCOUNT_CODE] ({commit}, {code, entered = false}) {
    axios.get(`${api}/v1/discounts/check/${code}`)
      .then((response) => {
        // Only remove last entered discount; ignore family-plan.
        if (code !== DISCOUNT_CODE.FAMILY_PLAN) commit(SET_REGISTRATION_ENTERED_DISCOUNT_REMOVE)
        commit(SET_REGISTRATION_DISCOUNTS_ADD, {...response.data, entered})
        commit(SET_DISCOUNT_MESSAGE, {
          error: null,
          message: response.data.code_name
        })
      })
      .catch((error) => {
        commit(SET_REGISTRATION_DISCOUNTS_ADD, {
          code,
          entered,
          error: error.response.data.message
        })
        commit(SET_DISCOUNT_MESSAGE, {
          error: error.response.data.message,
          message: null
        })
      })
  },
  [REQUEST_PARTNER_DISCOUNTS] ({commit}, partnerId) {
    axios.get(`${api}/api/v2/partners/${partnerId}/discounts/`)
      .then((response) => {
        const {data} = response
        commit(SET_PARTNER_DISCOUNTS,
          data.map((rawCodePayload) => ({
            code: rawCodePayload.discount_code.code
          }))
        )
      })
      .catch((error) => {
        console.log(error)
      })
  },

  [PARTNER_UI_SETTINGS] ({commit}, partnerPropertyId) {
    axios.get(`${api}/partner-strategies/ui-settings/${partnerPropertyId}/`)
      .then((response) => {
        const {data} = response.data
        commit(SET_PARTNER_UI_SETTINGS, data.ui_settings)
        commit(SET_PARTNER_STRATEGY_NAME, data.partner_strategy_name)
      })
      .catch((error) => {
        console.log(error)
      })
  },

  [CHECK_PHONE_NUMBER] ({commit}, {index, phone}) {
    const phones = this.state.registrationv2.coverages.map(coverage => coverage.phone)
    phones.splice(index, 1)
    if (phones.includes(phone)) {
      commit(SET_REGISTRATION_COVERAGE_VALUE, {
        index,
        key: 'phoneError',
        value: 'Please enter a unique phone number for every plan.'
      })
    } else {
      axios.post(`${api}/checkphone/`, {phone})
        .then(() => {
          commit(SET_REGISTRATION_COVERAGE_VALUE, {index, key: 'phoneError', value: null})
        })
        .catch((error) => {
          if (error.response.status === 409) {
            commit(SET_REGISTRATION_COVERAGE_VALUE, {
              index,
              key: 'phoneError',
              value: 'This phone number is already used by another account.'
            })
          }
        })
    }
  },
  async requestUpsellProduct ({commit}) {
    await axios.get(`${api}/products/?name=walnut-cyber-dash`)
      .then((response) => {
        let walnutUpsell = response.data[0]
        commit(SET_PRODUCT_UPSELL, walnutUpsell)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getProductPrices ({commit}, payload) {
    await axios.get(`${api}/products/${payload.productId}/prices/`, {
      params: payload.params
    })
      .then((response) => {
        let price = response.data[0]
        commit(SET_PRODUCT_PRICE, price)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async postContract ({commit}, payload) {
    await axios.post(
      `${api}/users/${this.state.user_id}/contracts/`,
      payload,
      { headers: {Authorization: `Bearer ${this.state.token}`} })
      .then((response) => {
        let contract = response.data
        commit(SET_USER_CONTRACT, contract)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  [REQUEST_UPSELL] ({commit}, payload) {
    commit(SET_LOADING, true)
    axios.post(
      `${api}/users/${payload.user_id}/contracts/${payload.contract_id}/contract_line_items/`,
      payload.data,
      { headers: {Authorization: `Bearer ${this.state.token}`} })
      .then(() => {
        commit(SET_LOADING, false)
        router.push('/registration/complete')
      })
      .catch((error) => {
        commit(SET_LOADING, false)
        let message
        try {
          if (typeof error.response.data === 'string') {
            message = error.response.data
          } else {
            message = error.response.data.message
          }
        } catch (e) {
          message = 'An unknown error occurred.'
        }
        commit(SET_UPSELL_ERROR, message)
      })
  },
  async [CHECK_GENERAL_ERRORS] ({commit}, {phone}) {
    try {
      await axios.post(`${api}/checkphone/`, {phone})
      commit(SET_ERRORS, null)
    } catch (error) {
      const message = _getErrorMessage(error)
      commit(SET_ERRORS, message)
    }
  },
  async [REQUEST_PLAID_KEYS] ({commit}) {
    try {
      const response = await axios.get(`${api}/registrations/ach/keys/`)
      commit(SET_PLAID_DATA, response.data)
    } catch (error) {
      let message = error
      try {
        message = error.response.data.message
      } catch (e) {
        console.warn('Error parsing error message:', e);
      }
      commit(SET_ERRORS, message)
    }
  },
  async [REQUEST_INTERNAL_DISCOUNT_VALUES] ({commit}) {
    if (!state.internalDiscountValues[TWO_YEAR_UPFRONT_KEY]) {
      try {
        const response = await axios.get(`${api}/discounts/${TWO_YEAR_UPFRONT_KEY}`)
        commit(SET_INTERNAL_DISCOUNT_VALUES, {key: TWO_YEAR_UPFRONT_KEY, value: response.data})
        commit(SET_ERRORS, null)
      } catch (error) {
        console.log({error})
        let message
        try {
          if (typeof error.response.data === 'string') {
            message = error.response.data
          } else {
            message = error.response.data.message
          }
        } catch (e) {
          message = 'An unknown error occurred.'
        }
        commit(SET_ERRORS, message)
      }
    }
  },
  async [CHECK_BANK_ACCOUNT_BALANCE] ({commit}, payload) {
    try {
      commit(SET_BANKACCOUNT_ERROR, null)
      commit(SET_LOADING, true)
      await axios.post(`${api}/registrations/ach/check-balance/`, payload)
      commit(SET_LOADING, false)
    } catch (error) {
      let message = error
      try {
        message = error.response.data.message
      } catch (e) {
        console.warn('Error parsing error message:', e);
      }
      commit(SET_BANKACCOUNT_ERROR, message)
      commit(SET_LOADING, false)
    }
  },
  async [DECRYPT_PARTNER_DEVICES] ({commit}, devicesToken) {
    try {
      const response = await axios.post(`${api}/checkout/read`, {devices: devicesToken})
      commit(SET_PARTNER_DEVICES, [...response.data.data])
    } catch (error) {
      console.log(error)
    }
  },
  [SUBMIT_REGISTRATION] ({commit, rootGetters}, payload) {
    commit(SET_LOADING, true)
    commit(SET_ERRORS, null)
    commit(SET_REGISTRATION_STATE_ON)
    commit(SET_REGISTRATION_ERROR, '')

    const paymentData = {
      ...this.state.registrationv2.paymentData,
      ...this.state.registrationv2.studentData,
      ...payload
    }
    /**
     * TODO: remove unecessary ternary and duplicate code
     * ternary identation is incorrect as well
     */
    const partnerPropertyIdFromCookie = window.$cookies.get('akko-pp')
    const partnerPropertyId = this.state.registrationv2.partner_property_id || partnerPropertyIdFromCookie

    const registrationForm = partnerPropertyId !== undefined ? {
      coverages: this.state.registrationv2.coverages,
      discounts: this.state.registrationv2.discounts.filter((discount) => !discount.error),
      interval: this.state.registrationv2.interval,
      primary_email: this.state.registrationv2.primaryEmail,
      ref: this.state.registrationv2.refferal,
      lead_id: this.state.registrationv2.leadId != null ? Number(this.state.registrationv2.leadId) : null,
      site_url: 'https://app.getakko.com',
      two_year_upfront: this.state.registrationv2.twoYearsUpfront,
      partner_property: partnerPropertyId,
      ...paymentData
    }
      : {
        coverages: this.state.registrationv2.coverages,
        discounts: this.state.registrationv2.discounts.filter((discount) => !discount.error),
        interval: this.state.registrationv2.interval,
        primary_email: this.state.registrationv2.primaryEmail,
        ref: this.state.registrationv2.refferal,
        lead_id: this.state.registrationv2.leadId != null ? Number(this.state.registrationv2.leadId) : null,
        site_url: 'https://app.getakko.com',
        two_year_upfront: this.state.registrationv2.twoYearsUpfront,
        ...paymentData
      }

    const irclickid = window.$cookies.get('irclickid')
    if (irclickid) {
      registrationForm.irclickid = irclickid
    }
    const endpoint = PAYMENT_METHOD_ENDPOINTS[paymentData.method] || '/v2/registrations/'
    const upsellPath = '/registration/upsell'
    const registrationCompletePath = '/registration/complete'
    const upsellFlag = rootGetters['GET_FEATURE_FLAG'](UPSELL) ? rootGetters['GET_FEATURE_FLAG'](UPSELL).state : false
    const nextPath = upsellFlag ? upsellPath : registrationCompletePath

    // TODO: fix indentation
    axios.post(`${api}${endpoint}`, registrationForm)
      .then((response) => {
        if (response.data && response.data.registered_users) {
          trackConversion(response.data.registered_users, response.data.transaction_id, registrationForm.discounts)
        } else {
          Sentry.withScope(function (scope) {
            scope.setTag('Operation', 'track_registration')
            scope.setLevel('error')
            scope.setUser({id: response.data.id})

            // Forcing to send payload to Sentry
            console.error(JSON.stringify(registrationForm))
            Sentry.captureException(new Error('Error on tracking user registration'))
          })
        }
        commit(SET_TOKEN, response.data)
        if (paymentData.studentValidation == null || paymentData.studentValidation.file == null) {
          commit(SET_LOADING, false)
          commit(SET_REGISTRATION_STATE_OFF)
          commit(SET_REGISTRATION_ERROR, '')
          commit(SET_PAYMENT_DATA, {})
          commit(SET_REGISTRATION_DISCOUNTS_REMOVE_ALL)
          router.push(nextPath)
        } else {
          const verificationData = new FormData()
          verificationData.append('verification_file', paymentData.studentValidation.file, paymentData.studentValidation.name)
          verificationData.append('school', paymentData.school)
          axios.post(`${api}/registrations/verification/images/`, verificationData, {headers: {Authorization: `Bearer ${this.state.token}`}})
            .then(() => {
              commit(SET_LOADING, false)
              commit(SET_REGISTRATION_DISCOUNTS_REMOVE_ALL)
              router.push(nextPath)
            })
            .catch((error) => {
              commit(SET_LOADING, false)
              console.log(error)
              let message = error
              try {
                message = error.response.data.message
              } catch (e) {
                console.warn('Error parsing error message:', e);
              }
              commit(SET_ERRORS, message)
            })
        }
      })
      .catch((error) => {
        console.error(error)
        let message
        try {
          if (typeof error.response.data === 'string') {
            message = error.response.data
          } else {
            message = error.response.data.message
          }
        } catch (e) {
          message = 'An unknown error occurred.'
        }
        commit(SET_REGISTRATION_ERROR, message)
        commit(SET_ERRORS, message)
        commit(SET_LOADING, false)
        this.dispatch(REQUEST_PLAID_KEYS)
        commit(SET_PAYMENT_DATA, {})
      })
  },
  getFilteredCoverageOptions ({commit}, payload) {
    axios.get(`${api}/coverages/`, {
      params: payload
    })
      .then((response) => {
        commit(SET_REGISTRATION_COVERAGE_OPTIONS, response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  },
  getCountryList ({commit}, payload) {
    axios.get(`${api}/api/v1/countries/`)
      .then((response) => {
        let {data: {data}} = response
        data = data.filter((country) => country.currency_is_active === true)
        commit(SET_COUNTRY_LIST, data)
        let defaultCountry = data.find((country) => country.code === DEFAULT_COUNTRY_CODE)
        this.dispatch('setCurrentCountryCurrency', defaultCountry === undefined ? {} : defaultCountry)
        if (payload) {
          this.dispatch('getFilteredCoverageOptions', {...payload, currency_code: defaultCountry.currency_code})
        }
      })
      .catch((error) => {
        console.log(error.response ? error.response.data : error)
      })
  },
  setCurrentCountryCurrency ({commit}, payload) {
    commit(SET_CURRENT_COUNTRY_CURRENCY, payload)
    commit(RESET_SELECTED_PHONES)
    this.dispatch('getPhoneModelsByCurencyId', {currency_id: payload.currency_id})
  },
  addPhoneToPlan ({commit}, payload) {
    commit(ADD_PHONE_TO_PLAN, payload)
  },
  setPhoneMakeAtIndex ({commit}, payload) {
    commit(SET_PHONE_MAKE_AT_INDEX, payload)
    commit(SET_PHONE_MODEL_AT_INDEX, {...payload, value: ''}) // reset model upon make change
  },
  setPhoneModelAtIndex ({commit}, payload) {
    commit(SET_PHONE_MODEL_AT_INDEX, payload)
  },
  resetSelectedPhones ({commit}) {
    commit(RESET_SELECTED_PHONES)
  },
  removePhoneAtIndex ({commit}, payload) {
    commit(REMOVE_PHONE_AT_INDEX, payload)
  },
  getPhoneModelsByCurencyId ({commit}, payload) {
    axios.get(`${api}/phone_models/v2/`, {params: payload})
      .then((response) => {
        let {data} = response
        commit(SET_CURRENCY_BASED_PHONE_MODELS, data)
      })
      .catch((error) => {
        console.error(error.response ? error.response.data : error)
      })
  },
  setAddressState ({ commit }, payload) {
    commit('setAddressState', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
