<template>
  <main class="flex w-70 bg-white br3 new-shadow-theme mr5 h-100 responsive-box">
    <div class="flex flex-column w-100">
      <div class="w-100 container-box">
        <div
          class="flex"
          @click="goBack"
        >
          <img
            alt=""
            class="mr1 icon-back"
            src="@/assets/appicons/ArrowLeft.svg"
          >
          <div class="color-theme pointer mb3">
            Back
          </div>
        </div>
        <div class="cera-round-pro-medium f4 mb4 fw5">
          Coverage Information
        </div>
        <div
          v-for="(item, index) in coverages"
          :key="index"
          class="flex flex-column"
        >
          <div
            v-if="index !== 0"
            class="w-100 separation-line mv3"
          />
          <div class="flex flex-row phone-form ">
            <phone-form
              :device-model="item.make + ' ' + item.model"
              :devices="secondStepDevices"
              :index="index"
              :plan-info="item.name"
              :set-phone-number="setPhoneNumber"
            />
            <div class="flex flex-row primary items-center center-dot">
              <input
                :id="index"
                v-model="primary"
                :value="index"
                class="pointer"
                name="primary"
                type="radio"
                @change="onPrimaryChange"
              >
              <label
                :for="index"
                class="inter f7 silver tl buttonUnselected left ml2 pointer"
              >
                Primary Account
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="container-box mt4">
        <plan-type-toggle
          :set-value="setIsCreditCard"
          :toggle-value="isCreditCard"
          custom-class="tc mt2 w-100 items-center justify-between h-20 mb4 responsive-toggle"
          custom-text-class="f7"
          first-option="Credit/Debit Card"
          second-option="Bank Account"
          title="Payment Information"
          toggle-name="pay-method"
        />
        <div v-show="isCreditCard">
          <div
            v-if="isOpenMessage"
            class="flex flex-row pv2 ph3 justify-between align-center bank-box relative"
          >
            <triangle-icon
              class="triangle"
              height="21px"
              width="21px"
            />
            <div class="flex flex-row align-center">
              <alert-icon class="icons" />
              <div class="inte color-gray f6 ml3 justify-center self-center bank-message">
                Connect your “Bank Account” instead to receive $20 in deductible credit toward future claims.
              </div>
            </div>
            <div
              class="icons"
              @click="closeMessage"
            >
              <close-icon />
            </div>
          </div>
          <personal-form
            :email-error="emailError"
            :first-value.sync="internalFirstName"
            :second-value.sync="internalLastName"
            :third-value.sync="internalEmail"
            :fourth-value.sync="postalCode"
            first-label="First Name"
            second-label="Last Name"
            third-label="Email Address"
            fourth-label="Postal Code"
          />
          <div class="mb3">
            <div class="flex flex-row justify-between mb3 responsive-pay">
              <div class="w-50 max-card responsive-card">
                <div
                  id="card-number"
                  class="mr3 max-code"
                />
              </div>
              <div class="flex flex-row w-50 max-code">
                <div
                  id="card-expiry"
                  class="small-input w-50 mr3"
                />
                <div
                  id="card-cvc"
                  class="small-input w-50"
                />
              </div>
            </div>
            <div class="inter f6 color-theme">
              {{ cardErrorMessage }}
            </div>
          </div>
        </div>
        <bank-account
          v-show="!isCreditCard"
          :email.sync="internalEmail"
          :email-error="emailError"
          :is-data-filled="isBankDataFilled"
        />
      </div>
    </div>
  </main>
</template>

<script>
import PersonalForm from '../../forms/PersonalFormCheckout.vue'
import PhoneForm from './PhoneForm.vue'
import BankAccount from './BankAccount.vue'
import PlanTypeToggle from '../PlanTypeToggle.vue'
import AlertIcon from '../icons/AlertIcon.vue'
import CloseIcon from '../icons/CloseIcon.vue'
import TriangleIcon from '../icons/TriangleIcon.vue'

import {GET_REGISTRATION_COVERAGES, GET_REGISTRATION_DISCOUNTS, GET_REGISTRATION_INTERVAL} from '@/store/registrationv2'
import {trackOnMixpanel, MIXPANEL_EVENTS} from '@/services/tracking'

import {checkEmailFormat} from '@/utils'
import {mapGetters} from 'vuex'

import Vue from 'vue'

import {StripePlugin} from '@vue-stripe/vue-stripe'

const debug = process.env.NODE_ENV !== 'production'

const options = {
  pk: debug ? 'pk_test_jTPxTNZwPNAYq0F6Nwu5mPkc' : 'pk_live_ne4lzjFFUgcbVDlPJwK2ZmKb'
}

Vue.use(StripePlugin, options)

export default {
  name: 'AkCheckoutForm',
  components: {
    PersonalForm,
    PhoneForm,
    BankAccount,
    PlanTypeToggle,
    AlertIcon,
    CloseIcon,
    TriangleIcon
  },
  props: {
    email: String,
    cardFirstName: String,
    cardLastName: String,
    secondStepDevices: Array,
    isBankDataFilled: Boolean,
    isCreditCard: Boolean,
    creditCardCompleted: Boolean
  },
  data () {
    return {
      cardErrorMessage: '',
      internalEmail: '',
      internalFirstName: '',
      internalLastName: '',
      cardNumber: '',
      cardCvc: '',
      cardExpiry: '',
      primary: 0,
      isOpenMessage: true,
      cardInfoCompleted: false,
      cardInfo: {
        cardNumber: false,
        cardCvc: false,
        cardExpiry: false
      }
    }
  },
  computed: {
    ...mapGetters({
      coverages: GET_REGISTRATION_COVERAGES,
      discounts: GET_REGISTRATION_DISCOUNTS,
      interval: GET_REGISTRATION_INTERVAL
    }),
    emailError () {
      return checkEmailFormat(this.internalEmail.trim())
    },
    stripeElements () {
      return this.$stripe.elements()
    }
  },
  watch: {
    'cardInfo.cardNumber': 'setCreditCardFilled',
    'cardInfo.cardCvc': 'setCreditCardFilled',
    'cardInfo.cardExpiry': 'setCreditCardFilled',
    cardInfoCompleted: 'isCreditCardCompleted',
    $route: 'goBack'
  },
  updated () {
    this.$emit('update:cardFirstName', this.internalFirstName)
    this.$emit('update:cardLastName', this.internalLastName)
    this.$emit('update:email', this.emailError ? '' : this.internalEmail)
  },
  mounted () {
    this.coverages.forEach((_, index) => {
      this.$set(this.secondStepDevices[index], 'primary', index === 0)
    })
    this.initStripe()
    this.bus.$on('createToken', this.createToken)
  },
  methods: {
    initStripe () {
      const style = {
        base: {
          '::placeholder': {
            color: '#c8c8c8'
          }
        },
        invalid: {
          color: '#f3103d',
          iconColor: '#f3103d'
        }
      }

      this.cardNumber = this.stripeElements.create('cardNumber', {style})
      this.cardNumber.mount('#card-number')
      this.cardExpiry = this.stripeElements.create('cardExpiry', {style})
      this.cardExpiry.mount('#card-expiry')
      this.cardCvc = this.stripeElements.create('cardCvc', {style})
      this.cardCvc.mount('#card-cvc')

      this.cardNumber.on('change', this.enableForm)
      this.cardExpiry.on('change', this.enableForm)
      this.cardCvc.on('change', this.enableForm)
    },
    async createToken () {
      const {token, error} = await this.$stripe.createToken(this.cardNumber)

      if (error) {
        this.cardErrorMessage = error.message
        return ''
      } else {
        this.cardErrorMessage = ''
        return token.id
      }
    },
    setIsCreditCard (value) {
      trackOnMixpanel(MIXPANEL_EVENTS.TOGGLE_PAY_METHOD, { 'Is Credit Card': value })
      this.isOpenMessage = true
      this.$emit('update:isCreditCard', value)
    },
    onPrimaryChange () {
      this.coverages.forEach((_, index) => {
        this.$set(this.secondStepDevices[index], 'primary', false)
      })
      this.$set(this.secondStepDevices[this.primary], 'primary', true)
    },
    setPhoneNumber (phone, index) {
      this.$set(this.secondStepDevices[index], 'phone', phone)
    },
    closeMessage () {
      this.isOpenMessage = false
    },
    goBack () {
      this.$emit('update:isFirstStep', true)
      this.$router.push({
        query: {
          ...this.$route.query,
          step1: 'true'
        }
      })
    },
    enableForm: function (event) {
      if (event.complete) {
        this.cardInfo[event.elementType] = true
      } else {
        this.cardInfo[event.elementType] = false
      }
    },
    isCreditCardCompleted () {
      this.$emit('update:creditCardCompleted', this.cardInfoCompleted)
    },
    setCreditCardFilled () {
      this.cardInfoCompleted = this.cardInfo.cardNumber && this.cardInfo.cardCvc && this.cardInfo.cardExpiry
      return this.cardInfoCompleted
    }
  }
}
</script>

<style scoped>
.StripeElement {
  background-color: white;
  padding: 10px 12px;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
}

.StripeElement--invalid {
  border-color: var(--theme-primary);
}

.StripeElement--webkit-autofill {
  background-color: #fefde5;
}

.responsive-box {
  padding: 2.5em 4%;
  min-height: 25em;
}

.separation-line {
  border: 1px solid var(--theme-medium-gray);
}

.bank-box {
  width: 100%;
  border: solid 1px var(--theme-medium-gray);
  border-radius: 5px;
  background-color: var(--theme-light-gray);
  z-index: 10;
  margin-top: -10px;
}

.triangle {
  position: absolute;
  top: -18px;
  right: 20px;
}

.icons {
  width: 19px;
  height: 19px;
}

.icon-back {
  width: 19px;
  height: 19px;
}

.bank-message {
  margin-top: 1px;
}

.center-dot {
  margin-top: 0.75rem;
  height: fit-content;
}

@media (max-width: 1280px) {
  .responsive-box {
    width: 80%;
    margin: 1em 0;
  }
}

@media screen and (max-width: 900px) {
  .primary {
    align-items: center;
    padding-top: 1em;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 700px) {
  .responsive-toggle {
    flex-direction: column;
    align-items: start;
  }

  .triangle {
    display: none;
  }

  .bank-message {
    font-size: 12px;
    padding: 0 5px;
  }

  .icons {
    width: 50px;
    align-self: flex-start;
  }

  .bank-box {
    padding: 10px;
  }
}

@media (max-width: 600px) {
  .responsive-box {
    width: 100%;
    margin: 1em 0;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }

  .container-box {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 15px 0 rgba(35, 31, 32, 0.2);
    padding: 2.5em 1.5em;
  }

  .phone-form {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .bank-message {
    margin-left: 5px;
  }

  .responsive-card {
    min-width: 250px;
  }
}

@media (max-width: 500px) {
  .responsive-card {
    min-width: 200px;
  }

  .responsive-pay {
    flex-direction: column;
    width: 100%;
  }

  .max-card {
    width: 100%;
    margin-bottom: 2em;
  }

  .max-code {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 375px) {
  .phone-form {
    flex-direction: column;
  }

  .container-box {
    padding: 2.5em 2em;
  }

  .primary {
    margin-top: 15px;
  }
}
</style>
