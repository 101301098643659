<template>
  <main class="flex w-100 bg-white br3 new-shadow-theme h-100 responsive-box">
    <div class="flex flex-column w-100">
      <div class="w-100">
        <div
          v-if="showBack"
          class="flex"
          @click="goBack"
        >
          <img
            alt=""
            class="mr1 icon-back"
            src="@/assets/appicons/ArrowLeft.svg"
          >
          <div class="color-theme pointer mb3">
            Back
          </div>
        </div>
        <div class="container-box">
          <div class="cera-round-pro-medium f4 mb3 fw5">
            Checkout Summary
          </div>
          <div
            v-if="filteredPhones.length > 1"
            class="mb4"
          >
            <label class="f6 fw5 di mb2 lh-copy inter">
              Please enter the cell phone number that will be associated with each plan below.
              Each person will receive a text message with their own login credentials after you checkout.
              Each person must login from their own phone and complete verification on their own device.
            </label>
          </div>
          <div
            v-else
            class="mb4"
          >
            <label class="f6 fw5 di mb2 lh-copy inter">
              Please enter the cell phone number that will be associated with the plan below.
              You will receive a text message with login credentials after checkout.
            </label>
          </div>
          <div
            v-for="(item, index) in coverages"
            :key="index"
            class="flex flex-column"
          >
            <div
              v-if="index !== 0"
              class="w-100 separation-line mv3"
            />
            <div class="flex flex-row phone-form justify-between">
              <div class="flex">
                <div class="inter f6-ns black-40 mr2 responsive-none">
                  #{{ index + 1 }}
                </div>
                <phone-form
                  :device-model="item.make + ' ' + item.model"
                  :devices="secondStepDevices"
                  :index="index"
                  :plan-info="item.display_name === null ? item.name : item.display_name"
                  :set-phone-number="setPhoneNumber"
                />
                <div class="flex flex-row primary items-center center-dot">
                  <input
                    :id="index"
                    v-model="primary"
                    :disabled="disablePrimaryCellphone"
                    :value="index"
                    class="pointer"
                    name="primary"
                    type="radio"
                    @change="onPrimaryChange"
                  >
                  <label
                    :for="index"
                    class="inter f6 silver tl buttonUnselected left ml2 pointer"
                  >
                    Primary Account
                  </label>
                </div>
              </div>
              <div class="inter flex f6 center-dot responsive-none">
                ${{ filteredPhones[index].price.toFixed(2) }} /
                {{ monthlyToggle ? 'month' : 'year' }}
              </div>
            </div>
          </div>
        </div>
        <div class="container-box mt4">
          <div class="cera-round-pro-medium f4 mb3 fw5">
            Payment Information
          </div>
          <plan-type-toggle
            :set-value="setIsCreditCard"
            :toggle-value="!isCreditCard"
            custom-class="tc mt2 w-100 items-center justify-between h-20 mb2 responsive-toggle"
            custom-text-class="f7"
            first-option="Bank Account"
            second-option="Credit/Debit Card"
            toggle-name="pay-method"
          />
          <div
            v-show="isCreditCard"
            class="mt4"
          >
            <div
              v-if="isOpenMessage"
              class="flex flex-row pv2 ph3 justify-between align-center bank-box relative"
            >
              <triangle-icon
                class="triangle"
                height="21px"
                width="21px"
              />
              <div class="flex flex-row align-center">
                <alert-icon class="icons" />
                <div class="inte color-gray f6 ml3 justify-center self-center bank-message">
                  Change to bank account and get $20 in deductible credit for future claims!
                </div>
              </div>
              <div
                class="icons"
                @click="closeMessage"
              >
                <close-icon />
              </div>
            </div>
            <personal-form
              :email-error="emailError"
              :first-value.sync="internalFirstName"
              :is-open-message="isOpenMessage"
              :second-value.sync="internalLastName"
              :third-value.sync="internalEmail"
              :fourth-value.sync="internalPostalCode"
              first-label="First Name"
              second-label="Last Name"
              third-label="Email Address"
              fourth-label="Postal Code"
            />
            <div class="mb3">
              <div class="flex flex-row justify-between mb3 responsive-pay">
                <div class="w-50 max-card responsive-card">
                  <div
                    id="card-number"
                    class="mr3 max-code"
                  />
                </div>
                <div class="flex flex-row w-50 max-code">
                  <div
                    id="card-expiry"
                    class="small-input w-50 mr3"
                  />
                  <div
                    id="card-cvc"
                    class="small-input w-50"
                  />
                </div>
              </div>
              <div class="inter f6 color-theme">
                {{ cardErrorMessage }}
              </div>
            </div>
          </div>
          <bank-account
            v-show="!isCreditCard"
            :email.sync="internalEmail"
            :email-error="emailError"
            :is-data-filled="isBankDataFilled"
          />
        </div>
        <div class="container-box mt4">
          <label
            v-if="showCouponInput"
            class="cera-round-pro fw6 db title "
          >Promotions</label>
          <label class="inter black-40 enter-code">Enter a promotion code you have, or select from one of our current promotions below! Limit 1 per transaction/promotions are not stackable.</label>
          <discount-code3c
            v-show="showCouponInput"
            :discount.sync="discount"
            :is-bigger-coupon="isBiggerCoupon"
            :is-monthly="monthlyToggle"
            :one-time-verification.sync="oneTimeVerification"
            :price.sync="internalPrice"
            :total-price.sync="totalPrice"
            :total-price-without-discount="totalPriceWithoutDiscount"
            class="mt3"
          />
          <div class="flex flex-column">
            <div
              v-if="showPromotionBox"
              class="flex pb3 mb1 responsive-carousel"
            >
              <!-- THIS SHOULD BE THE TITLE ONCE THE FIRST RENDER PROBLEM IS SOLVED -->
              <!-- :title="`Pay 2 years upfront and save up to $${(twoYearsUpfrontDiscountAmount * 2).toFixed(2)}`" -->
              <carousel-discount
                :applied="twoYearsUpfrontSelected"
                :discount="twoYearsUpfrontDiscountPercent + '%'"
                :is-bigger-coupon="isBiggerCoupon"
                :set-annual="setSecondValue"
                :setis-bigger-coupon="setisBiggerCoupon"
                :subtitle="monthlyToggle ? `By switching to annual save an extra $${savings.toFixed(2)}/yr.` : ''"
                :title="twoYearsUpfrontDiscountTitle"
                is-two-year
                sub-discount="off"
              />
              <carousel-discount
                v-if="enteredDiscount.code_name"
                :applied="true"
                :discount="`$${(calculateDiscountAmount(enteredDiscount,0)).toFixed(2)}`"
                :title="enteredDiscount.code_name"
                :is-coupon="true"
                :code="enteredDiscount.code"
                sub-discount="off"
                subtitle=""
              />
              <carousel-discount
                v-if="isMultiPlanDiscount"
                :applied="true"
                discount="5%"
                sub-discount="off"
                subtitle="Already applied in your order total"
                title="Multi-Plan Discount"
              />
            </div>

            <div class="w-100 separation-line mv3 responsive-appear" />
            <checkout-summary
              :billable-per-year="billablePerYear"
              :discount="discount"
              :entered-discount="enteredDiscount"
              :filtered-phones="filteredPhones"
              :has-non-coupon-discount="hasNonCouponDiscount"
              :is-bigger-coupon="isBiggerCoupon"
              :monthly-toggle="monthlyToggle"
              :disable-interval-toggle="disableIntervalToggle"
              :non-coupon-discount="nonCouponDiscount"
              :price="price"
              :savings="savings"
              :set-first-value="setFirstValue"
              :set-second-value="setSecondValue"
              :total="total"
              :total-price="totalPrice"
              :two-years-upfront-selected="twoYearsUpfrontSelected"
              :two-years-upfront-discount-amount="twoYearsUpfrontDiscountAmount"
            />
          </div>
          <div class="separation-line w-100 mt4" />
          <div class="flex flex-column flex-column-m items-center mt4-ns mt3">
            <terms-and-conds
              :is-checked="hasAgreed"
              :is-student="isStudent"
              :on-check="onCheck"
              custom-class="ph0"
            />
            <!-- to have the terms in left side
            w-80-ns w-100 w-100-m -->
            <ak-button
              :new-disabled="disabledButton"
              block
              custom-class="btn-reg"
              new-primary
              @click.native="onSubmit"
            >
              Register Now
            </ak-button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PersonalForm from '../../forms/PersonalFormCheckout.vue'
import PhoneForm from './PhoneForm.vue'
import BankAccount from './BankAccount.vue'
import PlanTypeToggle from '../PlanTypeToggle.vue'
import AlertIcon from '../icons/AlertIcon.vue'
import CloseIcon from '../icons/CloseIcon.vue'
import TriangleIcon from '../icons/TriangleIcon.vue'
import DiscountCode3c from '../cartTotalInfo/DiscountCode3c.vue'
import TermsAndConds from '../../TermsAndConds.vue'
import AkButton from '@/components/Button'

import {TWO_YEAR_UPFRONT_KEY} from '@/constants/registration/discountKeys'
import {
  GET_INTERNAL_DISCOUNT_VALUES,
  GET_PARTNER_PROPERTY_ID,
  GET_PARTNER_UI_SETTINGS,
  // GET_REGISTRATION_COVERAGES,
  GET_REGISTRATION_DISCOUNTS,
  GET_REGISTRATION_INTERVAL,
  GET_TWO_YEARS_UPFRONT,
  SET_REGISTRATION_DISCOUNTS_REMOVE,
  SET_REGISTRATION_DISCOUNTS_REMOVE_ALL,
  SET_REGISTRATION_INTERVAL
} from '@/store/registrationv2'
import {FAMILY_PLAN_DISCOUNT} from '@/constants/registration/choosePlan'

import {checkEmailFormat} from '@/utils'
import {mapGetters, mapMutations} from 'vuex'

import Vue from 'vue'

import { StripePlugin } from '@vue-stripe/vue-stripe'

import INTERVALS from '@/constants/registration/planIntervals'
import CarouselDiscount from '../cartTotalInfo/CarouselDiscount.vue'
import DISCOUNT_CODE from '@/constants/registration/discountCode'
import CheckoutSummary from '../cartTotalInfo/CheckoutSummary'
import {trackOnMixpanel, MIXPANEL_EVENTS} from '@/services/tracking'

const debug = process.env.NODE_ENV !== 'production'

const options = {
  pk: debug ? 'pk_test_jTPxTNZwPNAYq0F6Nwu5mPkc' : 'pk_live_ne4lzjFFUgcbVDlPJwK2ZmKb'
}

Vue.use(StripePlugin, options)

export default {
  name: 'UnifiedCheckout',
  components: {
    CheckoutSummary,
    AkButton,
    AlertIcon,
    BankAccount,
    CarouselDiscount,
    CloseIcon,
    DiscountCode3c,
    PersonalForm,
    PlanTypeToggle,
    PhoneForm,
    TermsAndConds,
    TriangleIcon
  },
  props: {
    email: {
      type: String,
      default: ''
    },
    cardFirstName: {
      type: String,
      default: ''
    },
    postalCode: {
      type: String,
      default: ''
    },
    cardLastName: {
      type: String,
      default: ''
    },
    secondStepDevices: {
      type: Array,
      default: () => []
    },
    isBankDataFilled: {
      type: Boolean,
      default: false,
    },
    isCreditCard: {
      type: Boolean,
      default: false,
    },
    creditCardCompleted: {
      type: Boolean,
      default: false,
    },
    hasAgreed: {
      type: Boolean,
      default: false,
    },
    isStudent: {
      type: Boolean,
      default: false,
    },
    isBiggerCoupon: {
      type: Boolean,
      default: false,
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    setMonthly: {
      type: Function,
      default: () => {}
    },
    setPrimaryPhone: {
      type: Function,
      default: () => {}
    },
    filteredPhones: {
      type: Array,
      default: () => []
    },
    monthlyToggle: {
      type: Boolean,
      default: true
    },
    disableIntervalToggle: {
      type: Boolean,
      default: true
    },
    price: {
      type: Number,
      default: 10
    },
    totalPriceWithoutDiscount: {
      type: Number,
      default: 10
    },
    totalMultiPlanDiscount: {
      type: Number,
      default: 10
    },
    coverages: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      cardErrorMessage: '',
      internalEmail: '',
      internalFirstName: '',
      internalLastName: '',
      internalPostalCode: '',
      internalPrice: this.price,
      cardNumber: '',
      cardCvc: '',
      cardExpiry: '',
      primary: 0,
      totalPrice: '10.00',
      amountTwoYears: '',
      oneTimeVerification: true,
      isOpenMessage: true,
      cardInfoCompleted: false,
      cardInfo: {
        cardNumber: false,
        cardCvc: false,
        cardExpiry: false
      },
    }
  },
  computed: {
    ...mapGetters({
      // coverages: GET_REGISTRATION_COVERAGES,
      discounts: GET_REGISTRATION_DISCOUNTS,
      interval: GET_REGISTRATION_INTERVAL,
      twoYearsUpfrontSelected: GET_TWO_YEARS_UPFRONT,
      internalDiscountValues: GET_INTERNAL_DISCOUNT_VALUES,
      getPartnerPropertyId: GET_PARTNER_PROPERTY_ID,
      uiSettings: GET_PARTNER_UI_SETTINGS
    }),
    monthly () {
      let sum = 0
      for (let i in this.filteredPhones.filter(phone => phone.make !== '' && phone.model !== '')) {
        const phone = this.getModelData(this.filteredPhones[i].make, this.filteredPhones[i].model)
        const {coverageOptions} = this.$store.state
        const phoneModelMonthly = phone ? coverageOptions.find(el => el.id === phone.monthly_coverage_id) : undefined
        sum += phoneModelMonthly ? phoneModelMonthly.price : 0
      }

      const selectedMultiplePhones = this.filteredPhones
        .filter(phone => phone.make !== '' && phone.model !== '')
        .length > 1

      const priceWithDiscount = selectedMultiplePhones ? sum * FAMILY_PLAN_DISCOUNT : sum

      return {
        totalPrice: sum,
        priceWithDiscount
      }
    },
    annual () {
      let sum = 0

      for (let i in this.filteredPhones.filter(phone => phone.make !== '' && phone.model !== '')) {
        const phone = this.getModelData(this.filteredPhones[i].make, this.filteredPhones[i].model)
        const {coverageOptions} = this.$store.state
        const phoneModelAnnual = phone ? coverageOptions.find(el => el.id === phone.annual_coverage_id) : undefined
        sum += phoneModelAnnual ? phoneModelAnnual.price : 0
      }

      const selectedMultiplePhones = this.filteredPhones
        .filter(phone => phone.make !== '' && phone.model !== '')
        .length > 1

      const priceWithDiscount = selectedMultiplePhones ? sum * FAMILY_PLAN_DISCOUNT : sum

      return {
        totalPrice: sum,
        priceWithDiscount
      }
    },
    savings () {
      return ((this.monthly.priceWithDiscount * 12) - this.annual.priceWithDiscount) * (1 - this.couponDiscountPercentage)
    },
    applyTwoYearsUpfrontDiscount () {
      return this.twoYearsUpfrontSelected && this.interval === INTERVALS.ANNUAL
    },
    total () {
      // when the payment after all discounts goes to 0.0USD,
      // we force to pay at least 1.0USD
      let total = this.applyTwoYearsUpfrontDiscount ? (this.internalPrice - this.discount) * 2 : this.internalPrice - this.discount
      if (this.discount > 0 && total <= 1) {
        this.oneTimeVerificationCharge = true
        total = 1
      } else {
        this.oneTimeVerificationCharge = false
      }
      return total.toFixed(2)
    },
    enteredDiscount () {
      return this.discounts.find((discount) => discount.entered) || {}
    },
    twoYearsUpfrontDiscountAmount () {
      let priceBefore2yearDiscount = this.totalPriceWithoutDiscount * (this.filteredPhones.length > 1 ? FAMILY_PLAN_DISCOUNT : 1)
      let twoYearsUpfrontDiscount = priceBefore2yearDiscount * (Number(this.twoYearsUpfrontDiscountPercent) / 100) * 2
      return twoYearsUpfrontDiscount
    },
    isMultiPlanDiscount () {
      return this.discounts.find((discount) => discount.code === DISCOUNT_CODE.FAMILY_PLAN)
    },
    twoYearsUpfrontDiscountPercent () {
      return this.internalDiscountValues[TWO_YEAR_UPFRONT_KEY].amount
    },
    billablePerYear () {
      return (this.totalPriceWithoutDiscount - ((this.coverages.length > 1 && !this.hasEnteredCouponDiscount) ? this.totalPriceWithoutDiscount * (1 - FAMILY_PLAN_DISCOUNT) : 0))
    },
    discount () {
      let amount = 0
      this.discounts.forEach((discount) => {
        if ((discount.key_name !== TWO_YEAR_UPFRONT_KEY || this.interval === INTERVALS.ANNUAL) && discount.code !== DISCOUNT_CODE.FAMILY_PLAN) {
          amount += this.calculateDiscountAmount(discount)
        }
      })
      return amount
    },
    nonCouponDiscount () {
      let amount = 0
      this.discounts.forEach((discount) => {
        if ((discount.key_name !== TWO_YEAR_UPFRONT_KEY && !discount.coupon)) {
          amount += this.calculateDiscountAmount(discount)
        }
      })
      return amount
    },
    fullNonCouponDiscount () {
      let amount = 0
      this.discounts.forEach((discount) => {
        if (discount.key_name !== TWO_YEAR_UPFRONT_KEY && !discount.coupon) {
          amount += this.calculateDiscountAmount(discount) * (discount.number_months ? discount.number_months : 1)
        }
      })
      return amount
    },
    couponDiscountPercentage () {
      const coupon = this.discounts.find((discount) => discount.coupon && discount.code !== DISCOUNT_CODE.FAMILY_PLAN)
      return coupon ? coupon.amount / 200 : 0
    },
    hasNonCouponDiscount () {
      return this.discounts.some((discount) => !discount.coupon)
    },
    hasEnteredCouponDiscount () {
      return this.discounts.some((discount) => discount.coupon && discount.code !== DISCOUNT_CODE.FAMILY_PLAN)
    },
    calculateisBiggerCoupon () {
      let calculate = false
      this.discounts.forEach((discount) => {
        if (discount.code !== DISCOUNT_CODE.FAMILY_PLAN) {
          if (discount.number_months === null) {
            calculate = true
          }
        }
      })
      return calculate
    },
    intervalMonths () {
      return this.monthlyToggle ? 1 : 12
    },
    monthlyPrice () {
      return this.internalPrice / this.intervalMonths
    },
    monthlyPriceReal () {
      return this.totalPriceWithoutDiscount / this.intervalMonths
    },
    emailError () {
      return checkEmailFormat(this.internalEmail.trim())
    },
    stripeElements () {
      return this.$stripe.elements()
    },
    showCouponInput () {
      if (this.uiSettings.hide_coupon_input !== undefined) {
        return !this.uiSettings.hide_coupon_input
      }
      return true
    },
    showBack () {
      if (this.uiSettings.only_payment_information_checkout !== undefined) {
        return !this.uiSettings.only_payment_information_checkout
      }
      return true
    },
    showPromotionBox () {
      if (this.uiSettings.hide_promotion_box !== undefined) {
        return !this.uiSettings.hide_promotion_box
      }
      return true
    },
    disablePrimaryCellphone () {
      if (this.uiSettings.block_edit_primary_phone_number !== undefined) {
        return this.uiSettings.block_edit_primary_phone_number
      }
      return false
    },
    twoYearsUpfrontDiscountTitle () {
      let savingsValue = this.twoYearsUpfrontSelected || this.monthlyToggle
        ? `${this.twoYearsUpfrontDiscountPercent}%`
        : `$${(this.twoYearsUpfrontDiscountAmount).toFixed(2)}`
      return `Pay 2 years upfront and save ${savingsValue}`
    }
  },
  watch: {
    'cardInfo.cardNumber': 'setCreditCardFilled',
    'cardInfo.cardCvc': 'setCreditCardFilled',
    'cardInfo.cardExpiry': 'setCreditCardFilled',
    cardInfoCompleted: 'isCreditCardCompleted',
    '$route.query.step1' (val) {
      if (val) {
        this.goBack()
      }
      this.removeAllDiscountCodes()
    },
    '$route.query.interval': {
      handler (val) {
        if (val === 'monthly') {
          this.setFirstValue()
        } else {
          this.setSecondValue()
        }
      },
      immediate: true
    },
    '$store.state.registrationv2.partnerDevices' (val) {
      if (val) {
        if (this.getPartnerPropertyId !== undefined) {
          this.primary = this.$store.state.registrationv2.partnerDevices.map(function (e) {
            return e.is_primary
          }).indeqxOf(true)
        }
        this.onPrimaryChange()
      }
    },
    price(p) {
      this.internalPrice = p;
    }
  },
  updated () {
    this.$emit('update:cardFirstName', this.internalFirstName)
    this.$emit('update:postalCode', this.internalPostalCode)
    this.$emit('update:cardLastName', this.internalLastName)
    this.$emit('update:email', this.emailError ? '' : this.internalEmail)
  },
  methods: {
    ...mapMutations({
      removeDiscount: SET_REGISTRATION_DISCOUNTS_REMOVE,
      setInterval: SET_REGISTRATION_INTERVAL,
      removeAllDiscountCodes: SET_REGISTRATION_DISCOUNTS_REMOVE_ALL
    }),
    setisBiggerCoupon (val) {
      this.$emit('update:isBiggerCoupon', val)
    },
    setFirstValue (event) {
      this.removeDiscount(this.internalDiscountValues[TWO_YEAR_UPFRONT_KEY])
      this.$emit('update:isBiggerCoupon', false)
      this.setInterval(INTERVALS.MONTHLY)
      if (this.enteredDiscount.code) {
        this.removeDiscount(this.enteredDiscount)
      }
      this.setMonthly(true, event)
    },
    setSecondValue (event) {
      this.$emit('update:isBiggerCoupon', false)
      if (this.enteredDiscount.code) {
        this.removeDiscount(this.enteredDiscount)
      }
      this.setInterval(INTERVALS.ANNUAL)
      this.setMonthly(false, event)
    },
    calculateDiscountAmount (discount) {
      let discountAmount = 0
      let tempDiscount = 0
      const numberMonths = Math.min(discount.number_months || Infinity, this.intervalMonths)
      if (discount && discount.error) {
        this.$emit('update:isBiggerCoupon', false)
      }
      if (discount.amount_type === 'FIXED') {
        discountAmount = discount.amount * numberMonths
      } else if (discount.amount_type === 'PERCENT') {
        tempDiscount = (this.monthlyPrice) * discount.amount / 100 * numberMonths
        if (this.calculateisBiggerCoupon) {
          discountAmount = (this.monthlyPriceReal) * discount.amount / 100 * numberMonths
          this.$emit('update:isBiggerCoupon', true)
          this.removeDiscount({code: DISCOUNT_CODE.FAMILY_PLAN})
          this.removeDiscount(this.internalDiscountValues[TWO_YEAR_UPFRONT_KEY])
        } else {
          discountAmount = tempDiscount
          this.$emit('update:isBiggerCoupon', false)
        }
      }
      return Number(discountAmount.toFixed(2))
    },
    async createToken () {
      const {token, error} = await this.$stripe.createToken(this.cardNumber)

      if (error) {
        this.cardErrorMessage = error.message
        return ''
      } else {
        this.cardErrorMessage = ''
        return token.id
      }
    },
    getModelData (make, model) {
      return this.$store.state.allPhoneModels.find(phone => phone.make === make && phone.model === model)
    },
    setIsCreditCard (value) {
      trackOnMixpanel(MIXPANEL_EVENTS.TOGGLE_PAY_METHOD, { 'Is Credit Card': !value })
      this.isOpenMessage = true
      this.$emit('update:isCreditCard', !value)
    },
    onPrimaryChange () {
      this.setPrimaryPhone(this.primary)
    },
    setPhoneNumber (phone, index) {
      this.$set(this.secondStepDevices[index], 'phone', phone)
    },
    closeMessage () {
      this.isOpenMessage = false
    },
    goBack () {
      this.$emit('update:isFirstStep', true)
      this.$emit('update:isBiggerCoupon', false)

      this.$router.push({
        query: {
          ...this.$route.query,
          step1: 'true'
        }
      })
    },
    enableForm: function (event) {
      this.cardInfo[event.elementType] = !!event.complete
    },
    isCreditCardCompleted () {
      this.$emit('update:creditCardCompleted', this.cardInfoCompleted)
    },
    setCreditCardFilled () {
      this.cardInfoCompleted = this.cardInfo.cardNumber && this.cardInfo.cardCvc && this.cardInfo.cardExpiry
      return this.cardInfoCompleted
    },
    onCheck (value) {
      this.$emit('update:hasAgreed', value)
    }
  },
  mounted () {
    const style = {
      base: {
        '::placeholder': {
          color: '#c8c8c8'
        }
      },
      invalid: {
        color: '#f3103d',
        iconColor: '#f3103d'
      }
    }
    this.cardNumber = this.stripeElements.create('cardNumber', {style})
    this.cardNumber.mount('#card-number')
    this.cardExpiry = this.stripeElements.create('cardExpiry', {style})
    this.cardExpiry.mount('#card-expiry')
    this.cardCvc = this.stripeElements.create('cardCvc', {style})
    this.cardCvc.mount('#card-cvc')
    this.cardNumber.on('change', this.enableForm)
    this.cardExpiry.on('change', this.enableForm)
    this.cardCvc.on('change', this.enableForm)
    window.scrollTo(0, 0)
    this.onPrimaryChange()
    this.bus.$on('createToken', this.createToken)
  }
}
</script>

<style scoped>

.StripeElement {
  background-color: white;
  padding: 10px 12px;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
}

.StripeElement--invalid {
  border-color: var(--theme-primary);
}

.StripeElement--webkit-autofill {
  background-color: #fefde5;
}

.responsive-box {
  padding: 2.5em;
  min-height: 25em;
  max-width: 850px;
}

.separation-line {
  border: 1px solid #EAEAEA;
}

.responsive-appear {
  display: none;
}

.bank-box {
  width: 100%;
  border: solid 1px var(--theme-medium-gray);
  border-radius: 5px;
  background-color: var(--theme-light-gray);
  z-index: 10;
  margin-top: -10px;
}

.triangle {
  position: absolute;
  top: -18px;
  left: 25px;
}

.title {
  font-size: 20px;
}

.btn-reg {
  font-size: 16px;
  padding: 15px 0;
  height: 3em;
}

.icons {
  width: 19px;
  height: 19px;
}

.icon-back {
  width: 19px;
  height: 19px;
}

.bank-message {
  margin-top: 1px;
}

.center-dot {
  margin-top: 0.75rem;
  height: fit-content;
}

.responsive-carousel {
    flex-direction: column;
    overflow-x: hidden;
    margin-bottom: 1rem;
  }

.enter-code {
  font-size: 14px;
}

@media (max-width: 1280px) {
  .responsive-box {
    width: 100%;
    margin: 1em 0;
  }

  .responsive-none {
    display: none;
  }

  .responsive-appear {
    display: block;
  }

}

@media screen and (max-width: 900px) {
  .primary {
    align-items: center;
    padding-top: 1em;
    margin-bottom: 1em;
  }

  .btn-reg {
    width: 100%;
  }


}

@media screen and (max-width: 700px) {
  .responsive-toggle {
    flex-direction: column;
    align-items: start;
  }

  .title {
    margin-bottom: 1em;
  }

  .triangle {
    display: none;
  }

  .bank-message {
    font-size: 12px;
    padding: 0 5px;
  }

  .icons {
    width: 50px;
    align-self: flex-start;
  }

  .bank-box {
    padding: 10px;
  }
}

@media (max-width: 600px) {
  .responsive-box {
    width: 100%;
    margin: 1em 0;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }

  .container-box {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 15px 0 rgba(35, 31, 32, 0.2);
    padding: 2.5em 1.5em;
  }

  .phone-form {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .bank-message {
    margin-left: 5px;
  }

  .responsive-card {
    min-width: 250px;
  }
}

@media (max-width: 500px) {
  .responsive-card {
    min-width: 200px;
  }

  .responsive-pay {
    flex-direction: column;
    width: 100%;
  }

  .max-card {
    width: 100%;
    margin-bottom: 2em;
  }

  .max-code {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 375px) {
  .phone-form {
    flex-direction: column;
  }

  .container-box {
    padding: 2.5em 2em;
  }

  .primary {
    margin-top: 15px;
  }
}
</style>
