<template>
  <div
    v-if="images.length === 0"
    class="pv5 w-100 tc bg-washed-red"
  >
    <input
      id="file"
      class="input-reset dn"
      type="file"
      multiple
      @change="processImage($event)"
    >
    <label
      for="file"
      class="dib fw5 pv2 ph5 br-pill pointer ba bw1 white bg-theme b-theme"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        class="v-sub mr1"
        fill="white"
      >
        <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
      </svg>
      <span class="white">{{ $t('storage.uploadfiles', { uploadfiles: 'Upload files' }) }}</span>
    </label>
  </div>
  <div v-else>
    <div class="pv3 w-100 tc bg-washed-red">
      <input
        id="file"
        class="input-reset dn"
        type="file"
        multiple
        @change="processImage($event)"
      >
      <label
        for="file"
        class="dib fw5 pv2 ph5 br-pill pointer ba bw1 white bg-theme b-theme"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          class="v-sub mr1"
          fill="white"
        >
          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
        </svg>
        <span class="white">{{ $t('storage.choosemore', { choosemore: 'Choose more files' }) }}</span>
      </label>
    </div>

    <transition-group
      name="list"
      tag="div"
    >
      <div
        v-for="(image, index) in images"
        :key="image.uploadNumber"
        class="flex w-100 bl br bb b1 pa3 justify-between bg-white b--black-20 black list-item"
        :class="0 === index ? 'bt' : ''"
      >
        <div class="v-mid">
          {{ $t('storage.image', { image: 'Image' }) }} #{{ image.uploadNumber }} <span class="gray">- {{ truncateMiddle(image.file.name, 10, 8) }}</span>
        </div>
        <img
          class="pointer"
          src="@/assets/appicons/x-circle.svg"
          alt=""
          @click="deleteImage(index)"
        >
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'AkFileInput',
  data () {
    return {
      images: [],
      count: 0
    }
  },
  methods: {
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async processImage (event) {
      for (let file of event.target.files) {
        this.images.push({uploadNumber: this.count++, file: file})
        await this.sleep(300)
      }
      this.emitFiles()
    },
    deleteImage (index) {
      this.images.splice(index, 1)
      this.emitFiles()
    },
    truncateMiddle (str, len, amt) {
      if (str.length > len + (amt * 2)) {
        return `${str.substring(0, amt)}...${str.substring(str.length - amt, str.length)}`
      }
      return str
    },
    emitFiles () {
      this.$emit('filesUpdated', this.images)
    }
  }
}
</script>

<style scoped>
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
</style>
