<template>
  <main>
    <div class="height flex items-center justify-between mb3">
      <span class="f2 lh-copy pt3">Add New Plan</span>
    </div>
    <div class="mv4-l mv2" />
    <div
      class="mr3-ns flex-grow flex-basis-2 mb4"
      :class="isMobile ? '' : 'grid-half'"
    >
      <ak-plan-card
        :title="$t('planssettings.basic.plan1', { plan1: 'Phone Only' })"
        :subtitle="$t('planssettings.basic.plan2', { plan2: 'Protection' })"
        :description="$t('planssettings.basic.description', { description: 'Protect any phone against damage,<br> breakdowns, and theft' })"
        price="5-12"
        :button-title="$t('planssettings.basic.select', { select: 'Protect Your Phone' })"
        :on-click="addPhoneOnlyPlan"
        :plan-data="phoneOnly"
      />

      <ak-plan-card
        :title="$t('planssettings.advanced.plan1', { })"
        :subtitle="$t('planssettings.advanced.plan2', { plan2: 'The AKKO Plan' })"
        :description="$t('planssettings.advanced.description', { description: 'Protect 1 phone + all your electronic devices<br> and more (25 items total)' })"
        description-styles="color-theme"
        price="15"
        :button-title="$t('planssettings.advanced.select', { select: 'Protect Phone + 25 Items' })"
        :on-click="addAkkoPlan"
        :button-primary="true"
        :plan-data="akkoPlan"
      />
    </div>
  </main>
</template>

<script>
import AkPlanCard from './PlanCard'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AkAddPlan',
  components: {
    AkPlanCard
  },
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    category: {
      type: String,
      default: ''
    },
    types: {
      type: Array,
      default: () => []
    },
    brands: {
      type: Object,
      default: () => ({})
    },
    models: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      phoneOnly: [
        this.$i18n.t('planssettings.basic.feat1'), this.$i18n.t('planssettings.basic.feat2'), this.$i18n.t('planssettings.basic.feat3'), this.$i18n.t('planssettings.basic.feat4'), this.$i18n.t('planssettings.basic.feat5'), this.$i18n.t('planssettings.basic.feat6'), this.$i18n.t('planssettings.basic.feat7'), this.$i18n.t('planssettings.basic.feat8')
      ],
      akkoPlan: [
        this.$i18n.t('planssettings.advanced.feat1'), this.$i18n.t('planssettings.advanced.feat2'), this.$i18n.t('planssettings.advanced.feat3'), this.$i18n.t('planssettings.advanced.feat4'), this.$i18n.t('planssettings.advanced.feat5'), this.$i18n.t('planssettings.advanced.feat6'), this.$i18n.t('planssettings.advanced.feat7'), this.$i18n.t('planssettings.advanced.feat8')
      ]
    }
  },
  computed: {
    ...mapState({
      countriesList: state => state.registrationv2.countries,
      selectedCountryCurrency: state => state.registrationv2.selectedCountryCurrency,
      userCountryCode: state => state.user.country_code
    }),
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  },
  mounted () {
    if (this.countriesList.length === 0) {
      this.getCountryList()
    }
    let userCountry = this.countriesList.find((country) => country.code === this.userCountryCode)
    this.setCurrentCountryCurrency(userCountry)
  },
  methods: {
    ...mapActions([
      'getCountryList',
      'setCurrentCountryCurrency'
    ]),
    addPhoneOnlyPlan () {
      this.$router.push('/add-phone-only-plan')
    },
    addAkkoPlan () {
      this.$router.push('/add-akko-plan')
    }
  },
}
</script>

<style scoped>
.height {
  height: 50px;
}

@media (max-width: 768px) {

.height {
  height: auto;
}

}
</style>
