// mutation-types.js
export const SET_COVERAGE_OPTIONS = 'SET_COVERAGE_OPTIONS'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const SET_LOADING = 'SET_LOADING'
export const TOGGLE_KEYBOARD = 'TOGGLE_KEYBOARD'
export const SET_LOGIN_MESSAGE = 'SET_LOGIN_MESSAGE'
export const SET_JUST_ACTIVATED = 'SET_JUST_ACTIVATED'
export const SET_COMPLETE = 'SET_COMPLETE'
export const SET_SUCCESS = 'SET_SUCCESS'

// Registration
export const SUBMIT_NAME = 'SUBMIT_NAME'
export const SUBMIT_SCHOOL = 'SUBMIT_SCHOOL'
export const SUBMIT_ADDRESS = 'SUBMIT_ADDRESS'
export const SUBMIT_APT = 'SUBMIT_APT'
export const SUBMIT_COVERAGE = 'SUBMIT_COVERAGE'
export const SUBMIT_COVERAGE_SECOND = 'SUBMIT_COVERAGE_SECOND'
export const SUBMIT_COVERAGE_THIRD = 'SUBMIT_COVERAGE_THIRD'
export const SUBMIT_ALT_EMAIL = 'SUBMIT_ALT_EMAIL'
export const SUBMIT_BIRTHDAY = 'SUBMIT_BIRTHDAY'
export const SUBMIT_EMAIL = 'SUBMIT_EMAIL'
export const SUBMIT_HEAR_ABOUT_US = 'SUBMIT_HEAR_ABOUT_US'
export const SET_SUBSCRIPTION_ID = 'SET_SUBSCRIPTION_ID'
export const SET_ORDER_AMOUNT = 'SET_ORDER_AMOUNT'
export const SET_STRIPE_CUSTOMER_ID = 'SET_STRIPE_CUSTOMER_ID'
export const SET_PHONE = 'SET_PHONE'
export const SUBMIT_MOST_EXPENSIVE = 'SUBMIT_MOST_EXPENSIVE'
export const CLEAR_REGISTRATION = 'CLEAR_REGISTRATION'
export const CLEAR_ACTIVATION = 'CLEAR_ACTIVATION'
export const SET_VERIFICATION_FILE = 'SET_VERIFICATION_FILE'
export const SET_SCHOOL = 'SET_SCHOOL'
export const SET_ERRORS = 'SET_ERRORS'
export const SET_ACTIVATION_FEE = 'SET_ACTIVATION_FEE'
export const SET_FULL_PATH = 'SET_FULL_PATH'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_REG_EMAIL_ERROR = 'SET_REG_EMAIL_ERROR'
export const SET_REG_PHONE_ERROR = 'SET_REG_PHONE_ERROR'
export const SET_REG_PHONE_ERROR_V3 = 'SET_REG_PHONE_ERROR_V3'
export const SET_PROMO = 'SET_PROMO'
export const RESET_PROMO_STATUS = 'RESET_PROMO_STATUS'
export const RESET_PROMO_ALL = 'RESET_PROMO_ALL'
export const SET_REDEMPTION = 'SET_REDEMPTION'
export const RESET_REDEMPTION_STATUS = 'RESET_REDEMPTION_STATUS'
export const RESET_REDEMPTION_ALL = 'RESET_REDEMPTION_ALL'
export const RESET_ACTIVATION_FEE = 'RESET_ACTIVATION_FEE'
export const RESET_COVERAGE_SECOND = 'RESET_COVERAGE_SECOND'
export const RESET_COVERAGE_THIRD = 'RESET_COVERAGE_THIRD'
export const SUBMIT_STUDENT_CHECK = 'SUBMIT_STUDENT_CHECK'
export const SUBMIT_PREVIOUS_PROVIDER = 'SUBMIT_PREVIOUS_PROVIDER'

// User
export const SET_USER = 'SET_USER'
export const CLEAR_USER = 'CLEAR_USER'

// Devices
export const SET_DEVICES = 'SET_DEVICES'
export const SET_CURRENT_DEVICE = 'SET_CURRENT_DEVICE'

// Storage
export const SET_FILES = 'SET_FILES'
