<template>
  <div :class="`flex ${divClass}`">
    <img
      :src="svg"
      alt=""
      class="mr1 self-start"
      style="margin-right: 5px; min-width: 20px; margin-top: 1px"
      :style="svgStyle"
    >
    <label
      class="inter f6"
      v-html="text"
    />
  </div>
</template>

<script>
export default {
  name: 'SelectPlanIconWithText',
  props: {
    text: {
      type: String,
      default: ''
    },
    svg: {
      type: String,
      default: ''
    },
    svgStyle: {
      type: String,
      default: ''
    },
    divClass: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    }
  }
}
</script>
