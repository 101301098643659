import { render, staticRenderFns } from "./CarouselDiscount.vue?vue&type=template&id=f0b459ee&scoped=true"
import script from "./CarouselDiscount.vue?vue&type=script&lang=js"
export * from "./CarouselDiscount.vue?vue&type=script&lang=js"
import style0 from "./CarouselDiscount.vue?vue&type=style&index=0&id=f0b459ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0b459ee",
  null
  
)

export default component.exports