<template>
  <div class="flex flex-column">
    <main class="flex-grow-1">
      <h1 class="f2 lh-copy pt3">
        Support
      </h1>

      <div class="mt4">
        <div class="mb4 f3">
          We’ve got you covered. How can we help?
        </div>
      </div>
      <div class="grid-half mv4">
        <router-link
          to="/faq"
          class="no-underline"
        >
          <ak-tile
            header="FAQ"
            subheader="Info on pricing, plans, claims, and more"
            classlist="hover-lift"
          />
        </router-link>

        <router-link
          to="/contact"
          class="no-underline"
        >
          <ak-tile
            header="Support"
            subheader="Contact us"
            classlist="hover-lift"
          />
        </router-link>

        <!-- <router-link to="/mentor" class="no-underline">
          <ak-tile header="Contact Mentor"
            subheader="Ask for advice. Discuss any concerns."
            classlist="hover-lift" />
        </router-link> -->
      </div>
    </main>

    <div
      id="footer"
      class="tc mt7-ns"
    >
      <a
        href="mailto:billing@getakko.com"
        class="gray"
      >Click here to request to cancel your plan</a> or email billing@getakko.com
    </div>
  </div>
</template>

<script>
import AkTile from './Tile'

export default {
  name: 'SupportPage',
  components: {
    AkTile
  },
  beforeRouteLeave (to, from, next) {
    if (this.isMobile && (to.path === '/faq' || to.path === '/contact')) {
      next('support-chat')
    } else {
      next()
    }
  },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  },
  watch: {
    '$intercom.visible' (newVal) {
      if (!newVal) {
        this.$intercom.shutdown()
      }
    }
  },
  methods: {
    openIntercomChat () {
      const {email, first_name, user_id, phone_no} = this.$store.state.user
      this.$intercom.boot({
        userId: user_id,
        user_id: user_id,
        name: first_name,
        email: email,
        phone: phone_no
      })
      this.$intercom.show()
    }
  }
}
</script>

<style scoped>
button {
  all: unset;
  cursor: pointer;
}
</style>
