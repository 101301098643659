<template>
  <div
    :class="{'container-selected': selected}"
    class="container pointer flex flex-column w-45 w-100-mobile br3 pa3 mh3-l h-100"
    @click="onClick"
  >
    <div
      class="flex flex-row justify-between items-center plan-title"
    >
      <div class="flex flex-row items-center flex-grow-1 w-100-mobile">
        <akko-plan-icon
          v-if="isAKKOPlan"
          alt="AKKO Plan"
          height="60"
          width="60"
        />
        <device-icon
          v-else
          alt="Phone Plan"
          height="60"
          width="60"
        />
        <div
          class="flex flex-column flex-grow-1 mr1"
          style="margin-left: 0.75rem"
        >
          <h5
            class="ma0 cera-round-pro"
            style="font-size: 1.1rem"
          >
            {{ isAKKOPlan ? 'The AKKO' : 'Phone-Only' }} Plan
          </h5>
          <h6
            :class="{'red':selected}"
            class="ma0 cera-round-pro grey"
            style="font-size: 0.85rem"
          >
            {{ isAKKOPlan ? 'Protect Phone + 25 items' : 'Protect Your Phone' }}
          </h6>
        </div>
        <red-tick-icon
          v-if="selected"
          class="mr2"
        />
      </div>
    </div>
    <plan-info
      :type="type"
      :include-open="includeOpen"
      :toggle-include="toggleInclude"
    />
  </div>
</template>

<script>

import AkkoPlanIcon from '../icons/AkkoPlanIcon'
import DeviceIcon from '../icons/DeviceIcon'
import RedTickIcon from '../icons/RedTickIcon'
import PlanInfo from './PlanInfo'

export default {
  name: 'PlanToggleCard',
  components: {PlanInfo, RedTickIcon, DeviceIcon, AkkoPlanIcon},
  props: {
    type: {
      type: String,
      default: ''
    },
    onClick: Function,
    selected: {
      type: Boolean,
      default: false
    },
    toggleInclude: Function,
    includeOpen: Boolean
  },
  computed: {
    isAKKOPlan () {
      return this.type === 'AKKO'
    }
  }
}
</script>
<style scoped>

.container {
  border: 1px solid #e6e6e6;
}

.container-selected {
  border-color: #F3103D;
}

.plan-title {
  z-index: 4;
}

.flex-grow-1 {
  flex-grow: 1;
}

.w-45 {
  width: 45%;
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif !important;
}

.grey {
  color: var(--theme-gray-text);
}

.red {
  color: #F3103D;
}


@media (max-width: 800px) {
  .plan-title {
    flex-direction: column !important;
  }

  .w-100-mobile {
    width: 100% !important;
  }

  .btn-mobile {
    justify-content: center;
  }

  .details-item-right {
    padding-left: 0.25rem;
  }
}

@media (min-width: 1280px) {
  .no-wrap {
    white-space: nowrap;
  }
}
</style>
