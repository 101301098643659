<template>
  <div class="mb4 flex flex-wrap">
    <div class="flex flex-column tc bg-white new-shadow-theme br3 w-100 pv4 h-100 main-container">
      <div class="flex flex-column flex-direction h-100 ph4 justify-between content">
        <div>
          <div class="flex flex-row w-100 items-center justify-between h2 mb3">
            <div class="cera-round-pro-medium f4 black tl">
              Checkout
            </div>
          </div>
          <device-info
            v-for="(deviceInfo, index) in phoneModelsWithCount"
            :key="index"
            :index="index"
            :device-info="deviceInfo"
            :phone-models="phoneModels"
            :get-coverage-phone-only="getCoverage"
            :is-monthly="monthlyToggle"
            :is-phone-only="isPhoneOnly"
            :is-multi="filteredPhones.length > 1"
            :is-cart-info="true"
          />
        </div>
      </div>
      <discount-code
        :is-monthly="monthlyToggle"
        :total-price.sync="totalPrice"
        :price="price"
        :one-time-verification.sync="oneTimeVerification"
        :discount.sync="discount"
        class="mt3"
      />
      <div class="separation-line w-100" />
      <div class="flex flex-column">
        <div
          v-if="enteredDiscount.code_name || filteredPhones.length > 1"
          class="flex flex-column ph4 items-start justify-start mt3"
        >
          <div class="inter-medium fw6 f6 mb2">
            Discounts:
          </div>
          <div
            v-if="filteredPhones.length > 1"
            class="inter black-50 f7 mb2"
          >
            5% Multi-Plan Discount Applied
          </div>
          <div
            v-if="enteredDiscount.code_name"
            class="flex flex-row inter mb1 items-center"
          >
            <div class="inter f7">
              -${{ discount.toFixed(2) }}
            </div>
            <div class="inter black-50 f7 ml2">
              ({{ enteredDiscount.code_name }})
            </div>
          </div>
        </div>
        <div class="flex flex-row ph4 justify-between mv3 f4 total-price">
          <label class="inter-medium black-40">Grand Total</label>
          <div
            v-if="oneTimeVerification"
            class="flex flex-column cera-round-pro-medium margin items-end"
          >
            ${{ totalPrice }}
            <div
              v-if="oneTimeVerification"
              class="inter black-50 f7 mt1"
            >
              One-time verification charge
            </div>
          </div>
          <div
            v-else
            class="flex flex-column cera-round-pro-medium margin items-end"
          >
            ${{ totalPrice }} / {{ monthlyToggle ? 'month' : 'year' }}
          </div>
        </div>
      </div>
      <div class="separation-line mb3 w-100" />
      <terms-and-conds
        :is-checked="hasAgreed"
        :is-student="isStudent"
        :on-check="onCheck"
        custom-class="ph4"
      />
      <div class="mt3 center ph4 w-100">
        <ak-button
          block
          :new-disabled="disabledButton"
          new-primary
          custom-class="button-custom"
          @click.native="onSubmit"
        >
          Register Now
        </ak-button>
      </div>
    </div>
  </div>
</template>

<script>
import AkButton from '@/components/Button'
import DeviceInfo from '../summaryBox/DeviceInfo.vue'
import DiscountCode from './DiscountCode.vue'
import TermsAndConds from '../../../registration/TermsAndConds.vue'

import { mapGetters } from 'vuex'

import {
  GET_REGISTRATION_DISCOUNTS
} from '@/store/registrationv2'

export default {
  name: 'CartTotalInfo',
  components: {
    AkButton,
    DeviceInfo,
    DiscountCode,
    TermsAndConds
  },
  props: {
    name: {
      type: String,
      default: 'Basic'
    },
    price: {
      type: Number,
      default: 10
    },
    filteredPhones: {
      type: Array,
      default: () => []
    },
    selectedCoverages: {
      type: Array,
      default: () => []
    },
    isFirstStep: {
      type: Boolean,
      default: true
    },
    isPhoneOnly: {
      type: Boolean,
      default: false
    },
    monthlyToggle: {
      type: Boolean,
      default: false
    },
    disabledButton: {
      type: Boolean,
      default: false
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    hasAgreed: {
      type: Boolean,
      default: false
    },
    isStudent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      totalPrice: '10.00',
      oneTimeVerification: false
    }
  },
  computed: {
    ...mapGetters({
      discounts: GET_REGISTRATION_DISCOUNTS
    }),
    phoneModels () {
      return (this.filteredPhones.map(({make, model, price, individualCoverageName, adult}) => (
          {...this.getModelData(make, model), price, individualCoverageName, adult}
      )))
    },
    discount () {
      return this.calculateDiscountAmount(this.enteredDiscount)
    },
    phoneModelsWithCount () {
      const devices = {}
      this.phoneModels.forEach(phoneCoverage => {
        const key = `${phoneCoverage.id}-${phoneCoverage.annual_coverage_id}-${phoneCoverage.monthly_coverage_id}-${phoneCoverage.individualCoverageName}`
        if (devices[key]) {
          devices[key].count += 1
        } else {
          devices[key] = {
            count: 1,
            content: { ...phoneCoverage }
          }
        }
      })
      const result = []

      Object.keys(devices).forEach((key) =>
        result.push({
          count: devices[key].count,
          ...devices[key].content
        })
      )

      return result
    },
    enteredDiscount () {
      return this.discounts.find((discount) => discount.entered) || {}
    },
    intervalMonths () {
      return this.monthlyToggle ? 1 : 12
    },
    monthlyPrice () {
      return this.price / this.intervalMonths
    }
  },
  methods: {
    getCoverage (idToFind) {
      return this.selectedCoverages.find(({ id }) => id === idToFind)
    },
    getModelData (makeToFind, modelToFind) {
      return this.$store.state.allPhoneModels.find(({make, model}) =>
        makeToFind === make && modelToFind === model)
    },
    calculateDiscountAmount (discount, existingDiscount = 0) {
      let discountAmount = 0
      const numberMonths = Math.min(discount.number_months || Infinity, this.intervalMonths)
      if (discount.amount_type === 'FIXED') {
        discountAmount = discount.amount * numberMonths
      } else if (discount.amount_type === 'PERCENT') {
        discountAmount = (this.monthlyPrice - existingDiscount / this.intervalMonths) * discount.amount / 100 * numberMonths
      }
      return Number(discountAmount.toFixed(2))
    },
    onCheck (value) {
      this.$emit('update:hasAgreed', value)
    }
  }
}
</script>

<style scoped>
  .price {
    font-size: 3.5em;
  }
  .button-custom {
    font-size: 16px;
    padding: 15px 0;
    height: 3em;
  }
  .main-container {
    min-height: 15em;
  }
  .content {
    min-height: 8em;
    margin-bottom: 2em;
  }
  .margin {
    margin-top: 1px;
  }
  .separation-line {
    border: 1px solid var(--theme-medium-gray);
  }
  @media (max-width: 1280px) {
    .button-custom {
      font-size: 16px;
      padding: 10px 0;
    }
  }
  @media (max-width: 650px) {
    .button-custom {
      font-size: 14px;
      padding: 15px 0;
    }
    .main-container {
      min-height: 10em;
      padding: 1.5em 0;
    }
    .content {
      padding: 0 1.4em;
      margin-bottom: 1.5em;
    }
  }
  @media (max-width: 400px) {
    .responsive-title {
      font-size: 1em;
    }
    .total-price {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 16px;
    }
  }
</style>
