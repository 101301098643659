<template>
  <div class="db centered-container-ns">
    <div
      id="login-card"
      class="bg-white border-login ph3 ph4-ns mt6 mt0-ns pv5-ns br2-ns"
    >
      <h1 class="f3 lh-copy fw3 tc">
        {{ $t('login.getcode', { getcode: 'Get a New Passcode' }) }}
      </h1>

      <form>
        <div class="mv4">
          <label
            for="email"
            class="f6 db mb2 inter "
          >{{ $t('login.enteracc', { enteracc: "Enter your account's cell phone number to receive a new passcode. You will receive the code by SMS and to the email address associated with your account. If you have multiple phones protected with AKKO, remember each phone has its own account and login." }) }}</label>
          <InputMask
            id="email"
            v-model="email"
            class="input-reset ba border-login pa2 mb2 db w-100 br1"
            type="tel"
            mask="(999) 999-9999"
          />
        </div>

        <div class="mt4 mb3">
          <ak-button
            :disabled="!identifierValid"
            primary
            block
            class="br3-ns"
            @click.native.prevent="login"
          >
            {{ $t('login.sendcode', { sendcode: 'Send me a New Passcode' }) }}
          </ak-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AkButton from './Button'
import InputMask from 'primevue/inputmask'

export default {
  name: 'AkForgotPassword',
  components: {
    AkButton,
    InputMask
  },
  data () {
    return {
      email: ''
    }
  },
  computed: {
    identifierValid () {
      return this.phoneValid
    },
    emailValid () {
      return this.email.trim() !== '' &&
        this.email.includes('@') &&
        this.email.includes('.')
    },
    phoneValid () {
      return this.isValidPhone(this.email)
    }
  },
  mounted () {
    document.body.classList.add('bg-cream-ns')
  },
  beforeDestroy () {
    document.body.classList.remove('bg-cream-ns')
  },
  methods: {
    login () {
      this.$store.dispatch('resetpw', {email: this.email.toLowerCase().trim()})
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 30em) {
.centered-container-ns {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
}

.border-login {
  border: 1px solid #E8E8E8;
}

#login-card {
  flex-basis: 24rem;
}
</style>
