<template>
  <div
    v-if="showComponent"
    class="flex w-100 flex-column pa3 pl4 pr4 justify-around br3 mt4 mb3 container"
    :class="!isValidCode ? 'containerError' : ''"
  >
    <div class="flex items-center">
      <valid-icon
        v-if="isValidCode"
        :class="iconClass"
      />
      <invalid-icon
        v-else
        :class="iconClass"
      />
      <label>{{ title }}</label>
    </div>
    <label class="f6 silver inter margin lh-copy">{{ message }}</label>
    <label
      v-if="!isValidCode"
      class="f6 silver inter margin lh-copy"
    >
      If you think this is an error, please <a
        class="color-theme underline"
        @click="openIntercomChat"
      >contact our support team.</a>
    </label>
    <div
      v-if="isValidCode"
      class="flex margin mt2"
    >
      <div class="flex flex-column">
        <label
          v-if="!!$store.state.activation.coverage.name"
          class="f7 silver inter mb2"
        >
          PLAN
        </label>
        <label class="f6 inter">{{ $store.state.activation.coverage.name }}</label>
      </div>
      <div class="flex flex-column ml5 retail">
        <label class="f7 silver inter mb2">SUPPLIER</label>
        <label class="f6 inter">{{ $store.state.registration.redemption.supplier }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import ValidIcon from './icons/ValidIcon.vue'
import InvalidIcon from './icons/InvalidIcon.vue'

export default {
  name: 'CodeMessage',
  components: {
    ValidIcon,
    InvalidIcon
  },
  computed: {
    isMobile () {
      return this.windowWidth <= 500
    },
    isValidCode () {
      return this.$store.state.registration.redemption.status === 'valid'
    },
    title () {
      return (
        this.isValidCode
          ? this.$store.state.registration.redemption.msg
          : 'Code is invalid or has been already used'
      )
    },
    iconClass () {
      return 'self-start justify-start dib mr3 icon'
    },
    showComponent () {
      return (
        this.isValidCode ||
        this.$store.state.registration.redemption.status === 'invalid'
      )
    },
    message () {
      return (
        this.isValidCode
          ? this.$store.state.registration.redemption.notes
          : ''
      )
    }
  },
  methods: {
    openIntercomChat () {
      if (!this.isMobile) {
        this.$intercom.show()
      }
    }
  }
}
</script>

<style scoped>
  .icon {
    width: 1.4rem;
  }
  .margin {
    margin-left: 40px;
  }
  .container {
    background-color: var(--theme-light-gray);
    min-height: 14em;
  }
  .containerError {
    min-height: 7em;
    padding: 1.5em 2em 1.5em 2em
  }
  @media screen and (max-width: 500px) {
    .retail {
      margin-left: 20px;
    }
  }
</style>
