<template>
  <main
    v-if="!billedByPartner"
    class="mt3 responsive-margin"
  >
    <div class="mt3 flex flex-column inter responsive-none">
      <div class="flex f5 top-container-realign items-center mb2 flex-row">
        <div class="pl4 silver">
          <router-link
            class="no-underline account-hover soft-grey"
            to="/account/dashboard"
          >
            {{ $t('menu.accountsettings', { accountsettings: 'Account Settings' }) }}
          </router-link>
        </div>
        <div class="soft-grey">
          &nbsp;  /  &nbsp;
        </div>
        <div class="soft-grey">
          {{ $t('billingsettings.billingsettingsheader', { billingsettingsheader: 'Billing' }) }}
        </div>
      </div>
      <h2 class="pl4 f2 mb3 title-realign cera-round-pro">
        {{ $t('billingsettings.billingsettingsheader', { billingsettingsheader: 'Billing' }) }}
      </h2>
    </div>
    <div class="flex flex-column flex-row-ns">
      <div class="width inter responsive">
        <Billing />
      </div>
      <router-view />
      <div
        class="w-40 responsive-none "
        style="visibility: hidden"
      >
        <RightComponent
          image="billing.png"
          name="We process all your payments efficiently"
        />
      </div>
    </div>
  </main>
  <main v-else>
    <UnifiedBillingPartners />
  </main>
</template>

<script>

import Billing from './Billing.vue'
import RightComponent from '../RightComponent.vue'
import UnifiedBillingPartners from '../billing-partners/UnifiedBillingPartners.vue'
import { PARTNER } from '../../../../constants/account-settings/accountSettings'

export default {
  name: 'UnifiedBilling',
  components: {
    Billing,
    RightComponent,
    UnifiedBillingPartners
  },
  props: {
    screen: String
  },
  data () {
    return {
      personal: 'personal'
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    billedByPartner () {
      return this.user.payment_processor && this.user.payment_processor === PARTNER
    }
  },
  mounted () {
  },
  beforeDestroy () {
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  },
  methods: {}
}
</script>

<style scoped>

.width {
  width: 55%;
}

.title-realign {
  margin-left: -3px
}

.top-container-realign {
  margin-top: 1px;
}

.grey {
  color: #484848;
}

.soft-grey {
  color: #9BA1AB;
}

.chevron {
  color: #9BA1AB;
  height: 17px;
  width: 17px;
}

.account-hover {
  cursor: pointer;
}

.account-hover:hover {
  color: #7A7A7C;
  text-decoration: underline;
}


@media (max-width: 1280px) {

  .responsive-margin {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .responsive-none {
    display: none;
  }

  .responsive {
    width: 100%;
  }

  .responsive-margin {
    margin-top: 0;
  }
}

</style>
