<template>
  <div class="db centered-container-ns inter lh-copy flex justify-center">
    <div class="bg-white shadow-theme-ns w-100 mw7 ph4 mt6 pv5-ns br1-ns">
      <div class="mv3 tc f3">
        AKKO Plan Family Sign-up
      </div>

      <div class="mt4 mb4 pt4 grid-half">
        <div>
          <label
            for="adults"
            class="f6 b db mb2"
          >Adults:</label>
          <select
            id="adults"
            v-model="adults"
            class="ba b--black-20 pa2 mb2 db w-100 br1"
            name="adults"
            @blur="submit"
          >
            <option
              v-for="(num, index) in PLAN_TYPE_MAX"
              :key="index"
              :value="index"
            >
              {{ index }}
            </option>
          </select>
        </div>

        <div>
          <label
            for="students"
            class="f6 b db mb2"
          >Students:</label>
          <select
            id="students"
            v-model="students"
            class="ba b--black-20 pa2 mb2 db w-100 br1"
            name="students"
            @blur="submit"
          >
            <option
              v-for="(num, index) in PLAN_TYPE_MAX"
              :key="index"
              :value="index"
            >
              {{ index }}
            </option>
          </select>
        </div>
      </div>

      <router-link
        class="no-underline"
        to="/registration-v2/family/choose-plan"
      >
        <ak-button
          primary
          block
          class="mt4"
          :disabled="!valid"
        >
          Next
        </ak-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import AkButton from '../Button'
import { mapGetters, mapMutations } from 'vuex'
import { GET_REGISTRATION_QUANTITY, SET_REGISTRATION_QUANTITY_VALUE } from '@/store/registrationv2'
import {
  SET_REGISTRATION_TYPE
} from '../../store/index'
import REGISTRATION_TYPE from '../../constants/registration/registrationType'

const MIN_NUMBER_OF_PLANS = 2
const MAX_NUMBER_OF_PLAN_TYPE = 10

export default {
  name: 'AkChooseQuantity',
  components: {
    AkButton
  },
  data () {
    return {
      adults: 1,
      students: 1
    }
  },
  computed: {
    ...mapGetters({ quantity: GET_REGISTRATION_QUANTITY }),

    valid () {
      return this.adults + this.students >= MIN_NUMBER_OF_PLANS
    },
    PLAN_TYPE_MAX () {
      return MAX_NUMBER_OF_PLAN_TYPE + 1
    }
  },
  methods: {
    ...mapMutations({
      setQuantityValue: SET_REGISTRATION_QUANTITY_VALUE
    }),

    loadData () {
      this.adults = this.quantity.adults || 1
      this.students = this.quantity.students || 1
    },
    setQuantity (key, value) {
      this.setQuantityValue({ key, value })
    },
    submit () {
      this.setQuantity('adults', this.adults)
      this.setQuantity('students', this.students)
    }
  },
  mounted () {
    this.loadData()
    this.$store.commit(SET_REGISTRATION_TYPE, REGISTRATION_TYPE.FAMILY_PLAN)

    // Set initial state, since 1 is the preselected value
    this.setQuantity('adults', 1)
    this.setQuantity('students', 1)
  }
}
</script>

<style scoped>
</style>
