<template>
  <div class="">
    <div class="responsive-card">
      <div class="flex flex-row items-center mb2">
        <img
          :src="icon"
          alt=""
          class="img-responsive v-mid mr2 mb1"
          :class="icon !== '' ? 'w-20-l' : ''"
        >
        <div class="f5 mb2 color-theme title-responsive">
          {{ nickname }}
        </div>
      </div>
      <div class="flex flex-column justify-center text-responsive">
        <h4 class="mb2 color-theme title-responsive-neg">
          {{ nickname }}
        </h4>
        <span class="mb2 f6 inter">{{ brand }} - {{ model.trim() === '' ? type : model }}</span>
        <div v-if="serial">
          <div class="f6 gray inter mb1">
            {{ $t('device.serialnumber', { serialnumber: 'Serial Number' }) }}
          </div>
          {{ serial }}
        </div>
        <div
          v-else
          class="f6 gray inter"
        >
          {{ $t('device.noserialnumber', { noserialnumber: 'No serial number' }) }}
        </div>
      </div>
    </div>
  </div>
  <!-- OLD CARDS -->
  <!-- <div class="pa4 bl b-theme bw3 br20 bg-white hover-lift shadow-theme">
    <div class="f4">
      <img :src="icon" alt="" class="dib h2 v-mid mr2" />
      <router-link :to="`/devices/${this.id}`" class="color-theme no-underline">{{nickname}}</router-link>
    </div>
    <div class="mv3">
      <span class="v-mid ttc">{{brand}} - {{model.trim() === '' ? type : model}}</span>
    </div>
    <div v-if="serial">
      <div class="f6 gray">
        {{ $t('device.serialnumber', { serialnumber: 'Serial Number' }) }}
      </div>
      {{serial}}
    </div>
    <div class="f6 gray" v-else>
      {{ $t('device.noserialnumber', { noserialnumber: 'No serial number' }) }}
    </div>
  </div> -->
</template>

<script>
export default {
  name: 'AkDeviceTile',
  props: {
    brand: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    nickname: {
      type: String,
      required: true
    },
    serial: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    lossTypes: {
      type: Array,
      default: () => [],
      required: false
    },
    knownDamage: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {}
  },
  computed: {
    icon () {
      /* Electronics */
      if (this.type === 'phone') {
        return require('@/assets/appicons/PhoneNew.svg')
      }
      if (this.type === 'tablet') {
        return require('@/assets/appicons/TabletNew.svg')
      }
      if (this.type === 'laptop') {
        return require('@/assets/appicons/LaptopNew.svg')
      }
      if (this.type === 'desktop') {
        return require('@/assets/appicons/DesktopNew.svg')
      }
      if (this.type === 'smartwatch') {
        return require('@/assets/appicons/SmartwatchNew.svg')
      }
      if (this.type === 'tv') {
        return require('@/assets/appicons/TVNew.svg')
      }
      if (this.type === 'gaming console') {
        return require('@/assets/appicons/GameControllerNew.svg')
      }
      if (this.type === 'gaming handheld') {
        return require('@/assets/appicons/GameControllerNew.svg')
      }
      if (this.type === 'streaming device') {
        return require('@/assets/appicons/TVNew.svg')
      }
      /* Accessories */
      if (this.type === 'camera') {
        return require('@/assets/appicons/CameraNew.svg')
      }
      if (this.type === 'sunglasses') {
        return require('@/assets/appicons/SunglassesNew.svg')
      }
      if (this.type === 'glasses') {
        return require('@/assets/appicons/SunglassesNew.svg')
      }
      if (this.type === 'backpack') {
        return require('@/assets/appicons/BackpackNew.svg')
      }
      if (this.type === 'watch') {
        return require('@/assets/appicons/WatchNew.svg')
      }
      /* Audio */
      if (this.type === 'headphones') {
        return require('@/assets/appicons/HeadphoneNew.svg')
      }
      if (this.type === 'speakers') {
        return require('@/assets/appicons/SpeakerNew.svg')
      }
      if (this.type === 'professional audio equipment') {
        return require('@/assets/appicons/AudioEquipmentNew.svg')
      }
      if (this.type === 'dj equipment') {
        return require('@/assets/appicons/DJEquipmentNew.svg')
      }
      /* Photography */
      if (this.type === 'lens') {
        return require('@/assets/appicons/CameraLensNew.svg')
      }
      if (this.type === 'camera accessory') {
        return require('@/assets/appicons/CameraNew.svg')
      }
      /* Transport */
      if (this.type === 'bicycle') {
        return require('@/assets/appicons/BicycleNew.svg')
      }
      if (this.type === 'electric bike') {
        return require('@/assets/appicons/BicycleNew.svg')
      }
      if (this.type === 'skateboard') {
        return require('@/assets/appicons/SkateboardNew.svg')
      }
      if (this.type === 'electric skateboard') {
        return require('@/assets/appicons/SkateboardNew.svg')
      }
      if (this.type === 'scooter') {
        return require('@/assets/appicons/ScooterNew.svg')
      }
      if (this.type === 'electric scooter') {
        return require('@/assets/appicons/ScooterNew.svg')
      }
      /* Appliances */
      if (this.type === 'microwave') {
        return require('@/assets/appicons/MicrowaveNew.svg')
      }
      /* Sports */
      if (this.type === 'golf clubs') {
        return require('@/assets/appicons/GolfClubsNew.svg')
      }
      if (this.type === 'skiis') {
        return require('@/assets/appicons/SkiisNew.svg')
      }
      if (this.type === 'snowboard') {
        return require('@/assets/appicons/SnowboardNew.svg')
      }
      if (this.type === 'surfboard') {
        return require('@/assets/appicons/SurfboardNew.svg')
      }
      if (this.type === 'guitar') {
        return require('@/assets/appicons/GuitarNew.svg')
      }
      if (this.type === 'rollerskates') {
        return require('@/assets/appicons/RollerSkatesNew.svg')
      }
      return ''
    }
  }
}
</script>

<style scoped>

.title-responsive-neg {
  display: none;
}

@media (min-width: 1281px) {
  .text-responsive {
    margin-top: 0.5rem;
  }


}

@media (max-width: 1100px) {

  .title-responsive {
    font-size: 20px;
  }
  .img-responsive{
    width: 45px;
  }
}


@media (max-width: 768px) {

  .img-responsive{
    width: auto;
  }
  .responsive-card {
    display: flex;
    flex-direction: row;
  }
  .text-responsive {
    margin-left: 1rem;
  }
   .title-responsive {
    display: none;
   }

.title-responsive-neg {
  display: block;
}

}
</style>
