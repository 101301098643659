<template>
  <div
    class="br3 bw1 b--silver bg-white flex flex-column main-container mb3 relative justify-center"
  >
    <div class="flex flex-row">
      <div class="flex flex-column mr3 icon items-center justify-center">
        <device-icon />
        <div class="self-center cera-round-pro mt2 nowrap">
          Phone #{{ phoneIndex + 1 }}
        </div>
      </div>
      <div class="flex flex-column justify-between pv1 w-100">
        <device-input
          :disabled="partnerDevices && partnerDevices[phoneIndex] || null"
          :index="phoneIndex"
          :options-set="allMakes"
          :set-value="setMake"
          :value="selectedPhone.make"
          label="Make"
          placeholder="Select a make"
        />
        <device-input
          :disabled="partnerDevices && partnerDevices[phoneIndex] || null"
          :index="phoneIndex"
          :options-set="filterModelsBySelectedMake(selectedPhone.make)"
          :set-value="setModel"
          :value="selectedPhone.model"
          label="Model"
          placeholder="Select a model"
        />
      </div>
    </div>
    <div
      v-if="isRemovable"
      class="custom-position pointer"
      @click="removeItem(phoneIndex, $event)"
    >
      <trash-icon />
    </div>
    <plan-type-toggle
      v-if="!isPhoneOnly"
      :phone-index="phoneIndex"
      :set-value="setIsAdult"
      :toggle-name="'adultToggle' + phoneIndex"
      :toggle-value="selectedPhone.adult"
      custom-class="mt2 w-70 self-end justify-end h-20"
      custom-text-class="f7 padding"
      first-option="For Adult"
      second-option="For Student"
    />
  </div>
</template>

<script>
import DeviceIcon from '../icons/DeviceIcon.vue'
import TrashIcon from '../icons/TrashIcon.vue'
import DeviceInput from './DeviceInput.vue'
import PlanTypeToggle from '../PlanTypeToggle.vue'
import {mapGetters} from 'vuex'
import {GET_PARTNER_DEVICES, GET_PARTNER_PROPERTY_ID} from '../../../../store/registrationv2'

export default {
  name: 'AkEditDevice',
  components: {
    DeviceIcon,
    TrashIcon,
    DeviceInput,
    PlanTypeToggle
  },
  props: {
    selectedPhone: Object,
    removeItem: Function,
    setModel: Function,
    setMake: Function,
    setIsAdult: Function,
    filterModelsBySelectedMake: Function,
    isPhoneOnly: Boolean,
    allMakes: {
      type: Set,
      default: []
    },
    phoneIndex: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    this.$emit('update:existsData', false)
  },
  computed: {
    ...mapGetters({
      partnerDevices: GET_PARTNER_DEVICES,
      getPartnerPropertyId: GET_PARTNER_PROPERTY_ID
    }),
    isPartnerId () {
      return !!this.getPartnerPropertyId
    },
    isRemovable () {
      if (this.isPartnerId) {
        return false
      }
      return this.phoneIndex
    }
  }
}
</script>

<style scoped>
.main-container {
  border: 1px solid var(--theme-medium-gray);
  width: 45%;
  padding: 1em 3.5%;
  min-height: 8em;
  min-width: 16em;
  margin-left: 1rem;
  margin-right: 1rem;
}

.icon {
  width: auto;
}

.custom-position {
  position: absolute;
  right: -10px;
  top: -10px;
}

@media (max-width: 1100px) {
    .main-container {
      width: 49%;
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }

@media (max-width: 1000px) {
  .main-container {
    width: 90%;
    min-height: 8em;
  }
}

@media (max-width: 600px) {
  .main-container {
    width: 100%;
  }
}
</style>
