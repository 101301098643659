<template>
  <transition name="fade">
    <div
      id="overlay"
      class="absolute top-0 left-0 bottom-0 right-0 z-9999 overflow-hidden  bg-black-40 fade overflow-y-scroll flex"
      tabindex="-1"
      role="dialog"
      @click="closeModal"
    >
      <div
        id="modal"
        class="bg-white ba b--light-gray br2 mw6 center-custom animated-slow slide-in"
        :class="upsell ? 'upsell': ''"
      >
        <div
          class="pt4 ph4 flex justify-between"
          :class="upsell ? 'upsell-title': ''"
        >
          <div class="ma0 f4">
            {{ title }}
          </div>
          <div
            class="right f5 pointer"
            aria-hidden="true"
            @click="emitClose"
          >
            &#10005;
          </div>
        </div>

        <div class="ph3">
          <slot name="modal-body">
            Placeholder modal body
          </slot>
        </div>

        <slot
          name="modal-footer"
          :class="upsell ? 'upsell-footer': ''"
        >
          <div class="pa3 flex justify-end">
            <ak-button
              secondary
              @click.native="emitClose"
            >
              Close
            </ak-button>
          </div>
        </slot>
      </div>
    </div>
  </transition>
</template>

  <script>
  import AkButton from './Button.vue'

  export default {
    name: 'ModalWithoutInnerBorder',
    components: {
      AkButton
    },
    props: {
      title: {
        type: String,
        default: 'Placeholder Title'
      },
      onMount: {
        type: Function
      },
      upsell: {
        type: Boolean,
        default: false
      }
    },
    mounted () {
      document.body.classList.add('overflow-y-hidden')
      if (this.onMount) {
        this.onMount()
      }
    },
    beforeDestroy () {
      document.body.classList.remove('overflow-y-hidden')
    },
    methods: {
      emitClose () {
        this.$emit('close')
      },
      closeModal (event) {
        if (event.target.id === 'overlay') {
          this.$emit('close')
        }
      }
    }
  }
  </script>

  <style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .15s
  }

  .center-custom {
    margin: auto
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
  }

  .animated-slow {
    animation-duration: .30s;
    animation-fill-mode: both;
  }

  @keyframes slideInDown {
    from {
      transform: translate(0, -100%);
    }

    to {
      transform: none;
    }
  }

  .slide-in {
    animation-name: slideInDown;
  }

  @media (max-width: 479px) {
    .upsell {
      margin-left: 1rem;
      margin-right: 1rem;
      position: fixed;
      top: 25%;
    }

    .upsell-title {
      padding-left: 1rem;
    }

    .upsell-footer {
      display: block;
    }
  }
  </style>
