<template>
  <main>
    <h1 class="f2 lh-copy pt3">
      File New Claim
    </h1>
    <div v-if="!userHasAccess">
      <ak-alert
        v-if="user.membership_status === 'CANCELED'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'UNPAID'"
        color="red"
      >
        <span>Your coverage plan is currently unpaid. Please proceed to <router-link
          to="/account/dashboard"
          class="color-theme"
        >Account Settings</router-link> to resolve or </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'PAST_DUE'"
        color="red"
      >
        <span>Your coverage plan is currently past due. Please proceed to <router-link
          to="/account/dashboard"
          class="color-theme"
        >Account Settings</router-link> to resolve or </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'BANNED'"
        color="red"
      >
        <span>Your account has been banned. If you think this was done in error, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Inquiry Regarding Banned Account"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INACTIVE'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INVALID_USER'"
        color="red"
      >
        <span>Your account has been marked as invalid by our system due to unpaid premiums or other account issues. If you think this was done in error, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>
    </div>
    <div
      v-if="currentTab === 0"
      class="tab"
      style="display: block"
    >
      <!-- first step -->
      <div class="grid">
        <div class="db f4 fw8 mb3">
          Select your items that were damaged or stolen:<span class="color-theme f5"> *</span>
        </div>
        <div class="f8 mb3 lh-copy">
          If an item was affected which has not been added to your account, please
          <router-link
            to="/add-device-category"
            class="color-theme"
          >
            add it here
          </router-link>
          then file your claim.
        </div>
        <div
          v-if="registered_devices.length > 0"
          class="flex flex-wrap pb3"
        >
          <div
            v-for="(device, index) in registered_devices"
            :key="index"
            class="mr4 mb3"
          >
            <input
              :id="device.id"
              v-model="devices"
              type="checkbox"
              :value="device.id"
              class="dn"
            >
            <label
              :for="device.id"
              class="db pv2 ph3 f8 tc b ba br-pill b-theme bg-white color-theme pointer"
            >{{
              device.nickname
            }}</label>
          </div>
        </div>
        <ak-alert
          v-if="registered_devices.length == 0"
          color="red"
        >
          <span>You haven't added any items to your account.</span>
          <router-link
            to="/add-device-category"
            class="color-theme"
          >
            <u>Add your items here.</u>
          </router-link>
        </ak-alert>
      </div>
    </div> <!-- / first step -->

    <div
      v-if="currentTab === 1"
      class="tab"
      style="display: block"
    >
      <!-- second step -->
      <div class="grid">
        <div class="mt4">
          <div class="mv4">
            <label
              for="loss_type"
              class="db fw8 f3 mb3"
            >Type of loss<span class="color-theme f5"> *</span></label>
            <select
              id="loss_type"
              v-model="loss_type"
              class="b--black-20 pa2 mb3 db w-100 br1"
            >
              <option value="">
                Select a type of loss
              </option>
              <optgroup label="Accidental Damage">
                <option value="Cracked Screen">
                  Cracked Screen
                </option>
                <option value="Cracked Back">
                  Cracked Back
                </option>
                <option value="Cracked Camera">
                  Cracked Camera
                </option>
                <option value="Liquid Damage">
                  Liquid Damage
                </option>
                <option value="Accidental Damage - Other">
                  Other
                </option>
              </optgroup>
              <optgroup label="Malfunctions">
                <option value="Screen">
                  Screen
                </option>
                <option value="Camera">
                  Camera
                </option>
                <option value="Charging Port">
                  Charging Port
                </option>
                <option value="Battery">
                  Battery
                </option>
                <option value="Malfunctions - Other">
                  Other
                </option>
              </optgroup>
              <optgroup label="Theft and Vandalism">
                <option value="Theft">
                  Theft
                </option>
                <option value="Theft from Unattended Vehicle">
                  Theft from Unattended Vehicle
                </option>
                <option value="Vandalism">
                  Vandalism
                </option>
                <option value="Theft and Vandalism - Other">
                  Other
                </option>
              </optgroup>
            </select>
          </div>

          <div class="mt4">
            <label
              for="known_damage"
              class="db fw8 f3 mb2"
            >Previous known damage:</label>
            <textarea
              id="known_damage"
              v-model="known_damage"
              class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
              maxlength="1000"
              rows="6"
              @focus="toggleKeyboard(true)"
              @blur="toggleKeyboard(false)"
            />
            <span class="fr f8 mt1 silver">{{ dmg_count }}/1000 characters</span>
          </div>
        </div>
      </div>
    </div><!-- / second step -->

    <div
      v-if="currentTab === 2"
      class="tab"
      style="display: block"
    >
      <!-- third step -->
      <div class="grid">
        <div class="mv4">
          <label
            for="loss_date"
            class="db fw8 f3 mb2"
          >Date of loss<span class="color-theme f5"> *</span></label>
          <input
            id="loss_date"
            v-model="loss_date"
            class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
            type="date"
            :max="today_string"
            @focus="toggleKeyboard(true)"
            @blur="toggleKeyboard(false)"
          >
        </div>
        <div class="mt4">
          <label
            for="description"
            class="db fw8 f3 mb2"
          >Description of Incident:<span class="color-theme f5"> *</span></label>

          <textarea
            id="description"
            v-model="description"
            class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
            maxlength="1000"
            rows="6"
            @focus="toggleKeyboard(true)"
            @blur="toggleKeyboard(false)"
          />
          <span class="fr f8 mt1 silver">{{ desc_count }}/1000 characters</span>
          <div
            v-if="descriptionValidationMessages.error"
            id="descriptionvalidationmessage"
            class="red f7 mt2"
          >
            {{ descriptionValidationMessages.error }}
          </div>
        </div>
      </div>
    </div> <!-- / third step -->

    <div
      v-if="currentTab === 3"
      class="tab"
      style="display: block"
    >
      <!--  fourth step -->
      <div class="grid">
        <div class="mt3">
          <div class="f5 mb3 inter text-with-icon">
            <info-icon />&nbsp;Provide clear images of damage, evidence, and/or the copy of your police report.
          </div>
          <div class="uploadWrapper">
            <form
              v-if="!loading"
              id="imageUploadForm"
              class="imageUploadForm"
            >
              <image-icon />&nbsp;<span class="helpText">Upload Image</span>
              <input
                id="file"
                type="file"
                class="uploadButton"
                accept="image/*"
                @change="previewImage"
              >
            </form>
            <div
              v-if="getClaimImages.length > 0"
              class="images-list"
            >
              <div
                v-for="image in getClaimImages"
                :key="image.filename"
                class="images-list-item"
              >
                <div class="images-list-item-detail-icon">
                  <image-icon />
                </div>
                <div class="images-list-item-detail-name">
                  {{ image.filename }}
                </div>
                <div class="images-list-item-detail-remove">
                  <span
                    class="images-list-item-remove-btn"
                    @click="deleteImage(image.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  <!-- / fourth step -->

    <div
      v-if="currentTab === 4"
      class="tab"
      style="display: block"
    >
      <!-- fifth step -->
      <div class="grid-half">
        <div class="">
          <div class="">
            <label
              for="paypalemail"
              class="f3 b db mb2"
            >Your PayPal Email</label>
            <input
              id="paypalemail"
              v-model="paypalemail"
              name="paypalemail"
              class="input-email ba b--black-20 pa2 mb2 db w-100 br1 w-50-ns"
              type="email"
              :disabled="paypalEmailChkbx"
              autocomplete="paypalemail"
              @focus="toggleKeyboard(true)"
              @blur="toggleKeyboard(false)"
            >
            <div
              v-if="emailValidationMessages.error"
              id="emailvalidationmessage"
              class="red f7 mt2"
            >
              {{ emailValidationMessages.error }}
            </div>
          </div>
          <div class="mt2">
            <input
              id="paypalEmailChkbx"
              v-model="paypalEmailChkbx"
              type="checkbox"
              class="normal"
            >
            <label
              for="paypalEmailChkbx"
              class="dib fw5 f6 mb2 inter"
            >I don't have PayPal</label>
          </div>
        </div>
      </div>


      <div
        class="mt4"
        :class="[ !userHasAccess ? 'pb4' : '' ]"
      >
        <h3 class="db fw6 f4 mb1">
          DISCLAIMER:
        </h3>
        <p class="dark-gray i f6 inter">
          We must advise you that any person who knowingly, and with intent to commit fraud, files a statement of
          claim containing any materially false information,
          or conceals for the purpose of misleading information concerning any fact thereto, commits a fraudulent act,
          which is a crime and may be prosecuted to the
          full extent of the law. By signing, I agree that the statements above are true and correct to the best of my
          knowledge and cannot be changed once submitted to the company.
        </p>
        <div class="mt2">
          <label
            for="disclaimer"
            class="dib fw5 f6 mb2 inter"
          >
            <input
              id="disclaimer"
              v-model="disclaimer"
              type="checkbox"
              class="normal"
            >
            I agree and am filing this claim with full knowledge of my coverage benefits as outlined in the user
            agreement and terms and conditions.
          </label>
        </div>
        <div class="mv4">
          <label
            for="sig"
            class="db fw6 f3 mb2"
          >Please type your full name as a digital signature</label>
          <input
            id="sig"
            v-model="sig"
            class="input-reset ba b--black-20 pa2 mb2 db w-100 br1 w-50-ns"
            type="text"
            :disabled="!disclaimer"
            @focus="toggleKeyboard(true)"
            @blur="toggleKeyboard(false)"
          >
        </div>
      </div>
    </div> <!-- / fifth step -->

    <!-- Circles which indicates the steps of the form: -->
    <div class="dot-navigation">
      <!-- Generate 5 span elements to show tabs progress -->
      <span
        v-for="(x, index) in 5"
        :key="x"
        class="step"
        :class="[
          currentTab === index ? 'active': '',
          currentTab > index ? 'finish': ''
        ]"
      />
    </div>
    <div>
      <div class="claim-navigation">
        <div
          v-if="!!currentClaim.id"
          class="loading-state desk silver"
        >
          {{ loading ?'Loading...': 'Draft saved' }}
        </div>
        <ak-button
          v-if="currentTab > 0"
          type="button"
          secondary
          block
          @click.native="nextPrev(-1)"
        >
          Previous
        </ak-button>
        <ak-button
          v-if="currentTab < 4"
          type="button"
          primary
          block
          :disabled="devices.length <= 0"
          @click.native="nextPrev(1)"
        >
          Continue
        </ak-button>
        <ak-button
          v-if="currentTab >= 4 && userHasAccess"
          type="button"
          primary
          block
          :class="[!formComplete || loading ? 'submit-disabled' : 'submit-active']"
          :disabled="!formComplete || loading"
          @click.native="nextPrev(1)"
        >
          Submit
        </ak-button>
      </div>
      <div
        v-if="!!currentClaim.id"
        class="loading-state mob silver"
      >
        {{ loading ?'Loading...': 'Draft saved' }}
      </div>
    </div>
  </main>
</template>

<script>
import AkButton from './Button'
import AkAlert from './Alert'
import ImageIcon from './icons/Image'
import InfoIcon from './icons/Info'
import Vue from 'vue'
import Toast from 'vue-toastification'
import { DELETE_CURRENT_CLAIM_IMAGE, DELETE_IMAGE } from '../store/claims'
import 'vue-toastification/dist/index.css'

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 2,
  newestOnTop: true,
  timeout: 3500,
  closeOnClick: true
})

// Properly format today's day
const d = new Date()
const year = d.getFullYear()
const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`
const day = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`
const today = `${year}-${month}-${day}`

export default {
  name: 'AkAddClaim',
  components: {
    AkButton,
    AkAlert,
    ImageIcon,
    InfoIcon
  },
  data () {
    return {
      localCurrentTab: 0,
      // first tab
      loss_date: today,
      description: '',
      // second tab
      devices: [],
      // thirth tab
      loss_type: '',
      known_damage: '',
      // 4 tab
      images: [],
      hiddenImages: [],
      // 5 tab
      paypalemail: '',
      paypalEmailChkbx: false,
      disclaimer: false,
      sig: ''
    }
  },
  computed: {
    currentClaim () {
      return this.$store.state.claims.currentClaim
    },
    getClaimImages () {
      return this.$store.state.claims.claimImages
    },
    emailValidationMessages () {
      let error

      if (!this.paypalEmailChkbx && !this.emailValid && (this.paypalemail || '').trim()) {
        error = 'Must use a valid email address'
      } else {
        error = ''
      }
      return {error}
    },
    emailValid () {
      return (this.paypalemail || '').trim() ? this.paypalemail.includes('@') && this.paypalemail.includes('.') : false
    },
    descriptionValidationMessages () {
      let error

      if (!this.descriptionValid) {
        error = 'You should provide a full description of the incident'
      } else {
        error = ''
      }
      return {error}
    },
    descriptionValid () {
      return this.description.length > 0
    },
    today_string () {
      return today
    },
    loss_day () {
      return parseInt(this.loss_date.slice(8, 10))
    },
    loss_month () {
      return parseInt(this.loss_date.slice(5, 7))
    },
    loss_year () {
      return parseInt(this.loss_date.slice(0, 4))
    },
    desc_count () {
      return this.description.length
    },
    dmg_count () {
      return this.known_damage.length
    },
    registered_devices () {
      return this.$store.state.devices
    },
    loading () {
      return this.$store.state.loading
    },
    currentTab () {
      return this.localCurrentTab
    },
    formComplete () {
      return this.disclaimer &&
        this.sig.trim() !== '' &&
        (this.paypalemail.length || this.paypalEmailChkbx) &&
        this.emailValidationMessages.error === ''
    },
    user () {
      return this.$store.state.user
    },
    userHasAccess () {
      const status = this.user.membership_status
      return status !== 'BANNED' && status !== 'CANCELED' && status !== 'UNPAID' && status !== 'PAST_DUE'
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.$store.dispatch('getCurrentClaim', {id: this.$route.params.id}).then(() => {
        this.populateCurrentClaimData()
      }, error => {
        console.error(error)
        this.$toast.error('Error fetching your claim, try to reload')
      })
    }
    this.$store.dispatch('getDevices')
  },
  methods: {
    getPayload () {
      return {
        id: this.currentClaim.id,
        tab: this.localCurrentTab,
        loss_day: this.loss_day,
        loss_month: this.loss_month,
        loss_year: this.loss_year,
        loss_type: this.loss_type,
        description: this.description,
        known_damage: this.known_damage,
        devices: this.devices,
        // Images uploaded by the user
        newImages: this.$store.state.claims.claimImages,
        // Images deleted (hidden) by the user
        hiddenImages: this.hiddenImages,
        sig: this.sig,
        paypal_email: this.paypalEmailChkbx ? '' : this.paypalemail
      }
    },
    previewImage (evt) {
      this.$store.dispatch(
        'uploadToFilePipeline',
        {
          'type': 'CLAIM',
          'entity_id': this.currentClaim.id,
          'file': evt.target.files[0]
        }
      )
      .then(() => {
        this.$store.dispatch('patchClaim', this.getPayload())
      })
      .catch(() => {
        //
      })
    },
    nextPrev (stepsToMove) {
      // This function will figure out which tab to display
      // Exit the function if any field in the current tab is invalid:
      if (stepsToMove > -1) {
        if (!this.validateForm()) return false // do not validate when previous is clicked
      }
      if (this.currentTab === 0 && !this.$route.params.id) { // avoid double submission is user get back
        // we submit the claim for its creation
        this.$store.dispatch('submitPartialClaim', this.getPayload())
      } else {
        if (stepsToMove > 0) { // should not work when 'previous' is clicked
          this.$store.dispatch('patchClaim', this.getPayload())
        }
      }
      // Increase or decrease the current tab by 1:
      this.localCurrentTab = this.localCurrentTab + stepsToMove
    },
    validateForm () {
      // Validations per step
      let valid = true
      switch (this.currentTab) {
        case 0:
          if (!this.devices.length) {
            this.$toast.error('Select at least one device')
          }
          valid = this.devices.length > 0
          break
        case 1:
          if (!this.loss_type) {
            this.$toast.error('Select the type of loss')
          }
          valid = this.loss_type
          break
        case 2:
          if (!this.descriptionValid) {
            this.$toast.error('A description is needed')
          }
          valid = this.descriptionValid
          break
        default:
          valid = true
      }
      // If the valid status is true, mark the step as finished and valid:
      // if (valid) {
      //  document.getElementsByClassName('step')[this.$store.state.claims.currentTab].className += ' finish'
      // }
      return valid // return the valid status
    },
    toggleKeyboard (trueOrFalse) {
      this.$store.commit('TOGGLE_KEYBOARD', trueOrFalse)
    },
    deleteImage (id) {
      this.hiddenImages.push(id)
      // take image, delete it and refresh claimImages
      this.$store.commit(DELETE_IMAGE, id)
      this.$store.commit(DELETE_CURRENT_CLAIM_IMAGE, id)
    },
    populateCurrentClaimData () {
      this.description = this.$store.state.claims.currentClaim.description
      this.loss_type = this.$store.state.claims.currentClaim.loss_type
      this.known_damage = this.$store.state.claims.currentClaim.known_damage
      if (this.$store.state.claims.currentClaim.loss_year) { // only change date model if we have one
        let lossDateObject = new Date(
          this.$store.state.claims.currentClaim.loss_year, this.$store.state.claims.currentClaim.loss_month - 1, this.$store.state.claims.currentClaim.loss_day
        )
        this.loss_date = lossDateObject.toISOString().substring(0, 10)
      }
      if (this.$store.state.claims.currentClaim.devices) {
        let devicesClaim = []
        for (const device in this.$store.state.claims.currentClaim.devices) {
          devicesClaim.push(this.$store.state.claims.currentClaim.devices[device].id)
        }
        this.devices = devicesClaim
      }
      this.localCurrentTab = this.$store.state.claims.currentTab
      return this.$store.state.claims.currentClaim
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 30em) {
  .grid-custom-ns {
    display: grid;
    grid-template-columns: 2fr 3fr 3fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
  }
}
.dot-navigation {
  text-align:center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.claim-navigation {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 3rem;
  align-items: center;
}
.claim-navigation button{
  width: 100%;
  max-width: 210px;
}
@media screen and (max-width: 940px) {
  .claim-navigation {
    justify-content: center;
    margin-bottom: 1rem;
  }
}
.claim-navigation button:last-child {
  margin-left: 1rem;
}
.scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.flex-shrink-0 {
  flex-shrink: 0
}

textarea {
  resize: vertical;
}

input[type=radio]:checked + label {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}

input[type=checkbox]:checked:not(.normal) + label {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}

.submit-btn {
  flex: 1;
  cursor: pointer;
  border: 3px solid #f6103D;
  padding: 4px 12px;
  color: #fff;
  font-size: .9rem;
  font-weight: 800;
  background-color: #f6103D;
}


.submit-active {
  background-color: #f6103D;
}

.submit-disabled {
  background-color: #bbbbbb;
}

button:hover {
  opacity: 0.8;
}

.tab {
  display: none;
}

/* Make circles that indicate the steps of the form: */
.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: var(--theme-primary);
}

@import url(https://fonts.googleapis.com/css?family=Nunito);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.text-with-icon {
  display: flex;
  align-content: center;
}

.uploadWrapper {
  font-family: 'Nunito', sans-serif;
  min-height: 200px;
}

.imageUploadForm {
  background: var(--theme-primary);
  width: 200px;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  padding-top: .75em;
  padding-bottom: .75em;
  color: white;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
}

.imageUploadForm .helpText {

}

.image-loading {
  height: 43px;
}

.images-list {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: fit-content;
}
.images-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid var(--theme-primary);
  margin-bottom: 10px;
  border-radius: 5px;
}
.images-list-item-detail-icon svg {
  margin-top: 3px;
}
.images-list-item-remove-btn {
  cursor: pointer;
  font-size: 20px;
}
.images-list-item-remove-btn:hover {
  color: var(--theme-primary);
}
.images-list-item-remove-btn::after {
  content: '\f00d';
  font-family: 'FontAwesome';
}
.images-list-item-detail-name {
  width: 100%;
  text-align: center;
  padding-right: 10px;
}
.imageUploadForm .pickFile {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  background: white;
  height: 25%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.imageUploadForm .pickFileButton {
  display: inline-block;
  padding: 0.7em 2em;
  color: white;
  background: #FB92AE;
  text-decoration: none;
}

.imageUploadForm .pickFileButton:hover {
  text-decoration: none;
}

.imageUploadForm .uploadButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}

.imageUploadForm.loading .helpText {
  font-size: 0;
  top: 7%;
}

.imageUploadForm.loading .helpText:before {
  font-size: 30px;
  content: 'Uploading...';
}

.imageUploadForm.loading .helpText:after {
  display: none;
}

.imageUploadForm.loading .uploadedImg {
  position: absolute;
  bottom: 12.5%;
  left: 12.5%;
  width: 75%;
  height: 65%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 4px solid white;
  -moz-transition: opacity ease-out;
  -o-transition: opacity ease-out;
  -webkit-transition: opacity ease-out;
  transition: opacity ease-out;
}

.imageUploadForm.loading .unveil {
  position: absolute;
  background: #6E95F7;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -moz-animation: toTop 4s forwards;
  -webkit-animation: toTop 4s forwards;
  animation: toTop 4s forwards;
}

.imageUploadForm.loading .unveil:after {
  content: '';
  position: absolute;
  left: -3%;
  bottom: 0;
  height: 10px;
  width: 106%;
  background: #FFD16E;
  border-radius: 5px;
}

.imageUploadForm.loading .pickFile, .imageUploadForm.loading .uploadButton {
  display: none;
}

.imageUploadForm.loading.loaded {
  height: 200px;
}

.imageUploadForm.loading.loaded .uploadedImg {
  opacity: 0;
}

.imageUploadForm.loading.loaded .helpText:before {
  content: 'Upload Complete!';
}

.imageUploadForm.loading.loaded .helpText:after {
  display: block;
  opacity: 0;
  font-size: 0;
  line-height: 100px;
  -moz-animation: fadeIn 0.4s forwards;
  -webkit-animation: fadeIn 0.4s forwards;
  animation: fadeIn 0.4s forwards;
  content: '\f058';
  color: white;
  margin-top: 2%;
  border-width: 0;
}

@-moz-keyframes toTop {
  to {
    height: 0;
  }
}

@-webkit-keyframes toTop {
  to {
    height: 0;
  }
}

@keyframes toTop {
  to {
    height: 0;
  }
}

@-moz-keyframes fadeIn {
  to {
    opacity: 1;
    font-size: 90px;
  }
}

@-webkit-keyframes fadeIn {
  to {
    opacity: 1;
    font-size: 90px;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
    font-size: 90px;
  }
}

.img-wraps {
  position: relative;
  display: inline-block;
  font-size: 0;
}
.img-wraps .closes {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
  background-color: #FFF;
  padding: 5px 2px 2px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  opacity: .6;
  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
  border:1px solid red;
}
.img-wraps:hover .closes {
  opacity: 1;
}
.loading-state {
  text-align: center;
  padding: 0 0.5rem;
}
.loading-state.desk {
  display: block;
}
.loading-state.mob {
  display: none;
}
@media screen and (max-width: 940px) {
  .loading-state.desk {
    display: none;
  }
  .loading-state.mob {
    display: block;
  }
}
</style>
