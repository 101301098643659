<template>
  <div
    v-if="!loading"
    class="grid-modified"
  >
    <ak-app-sidebar />

    <div class="grid-main w-100 center pt4 pt0-xl ph3 mt3-ns mb55 max-w">
      <router-view />
    </div>

    <ak-app-footer />
  </div>
</template>

<script>
import AkAppFooter from './AppFooter'
import AkAppSidebar from './AppSidebar'
import { CLEAR_REGISTRATION } from '@/store/mutations'
import { mapActions } from 'vuex'

export default {
  name: 'AkAppBase',
  components: {
    AkAppFooter,
    AkAppSidebar
  },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },
    loading () {
      return this.$store.state.loading
    }
  },
  watch: {
    '$route' (val) {
      if (val.path !== '/support-chat' && this.isMobile) {
        this.$intercom.shutdown()
      }
    }
  },
  mounted () {
    this.$store.commit(CLEAR_REGISTRATION, {})
    document.body.classList.add('bg-cream')
    this.getUserInformation()
    this.getPartner()
    // Deleted since this function is being triggered in dashboard already
    // this.$store.dispatch('getDevices')
    this.getClaims()
    this.getCountryList()
    if (this.$route.path !== '/support-chat' && this.isMobile) {
      this.$intercom.shutdown()
    }
  },
  beforeDestroy () {
    document.body.classList.remove('bg-cream')
  },
  methods: {
    ...mapActions([
      'getUserInformation',
      'getClaims',
      'getCountryList',
      'getPartner'
    ])
  },
}
</script>

<style>
.mb55 {
  margin-bottom: 6em;
}


@media screen and (min-width: 80em) {
.mb55 {
  margin-bottom: 0;
}
.grid-dashboard {
  display: grid;
  grid-template-rows: 51px 1fr;
  grid-template-columns: 221px 1fr;
  grid-template-areas:
    "nav nav"
    "side main"
}
.grid-modified {
  display: grid;
  grid-template-rows: none;
  grid-template-columns: 221px 1fr;
  grid-template-areas:
    "nav nav"
    "side main"
}
.pt0-xl {
  padding-top: 0;
}
}

.max-w {
  max-width: 70rem;
}

.grid-main {
  grid-area: main;
}

@media (max-width: 1400px) {

.max-w {
  max-width: 65rem;
}

}

@media (max-width: 1330px) {

.max-w {
  max-width: 62rem;
}

}

</style>
