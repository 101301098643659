import PLAN_TAGS from './planTags'

export const SUPPORT_EMAIL = 'billing@getakko.com'
export const RE_ENABLE_PLAN = 'Re-enable my plan'
export const DEVICE_DATA = [
  {
    icon: require('@/assets/appicons/Electronics.svg'),
    name: 'electronics',
    types: [
      {
        name: 'Phone',
        notAllowedCoverageTags: [PLAN_TAGS.HOME_REGULAR_ANNUAL, PLAN_TAGS.HOME_REGULAR_MONTHLY]
      },
      {
        name: 'Laptop',
        notAllowedCoverageTags: []
      },
      {
        name: 'Tablet',
        notAllowedCoverageTags: []
      },
      {
        name: 'Smartwatch',
        notAllowedCoverageTags: []
      },
      {
        name: 'Desktop',
        notAllowedCoverageTags: []
      },
      {
        name: 'VR/AR Headset',
        notAllowedCoverageTags: []
      }
    ],
    description: 'Phones, Laptops & Tablets',
    brands: {
      phone: ['Apple', 'Samsung', 'Google', 'OnePlus', 'Motorola', 'HTC', 'LG', 'Sony', 'Blackberry', 'Nokia', 'Huawei', 'Lenovo', 'Xiaomi', 'ZTE'],
      laptop: ['Apple', 'Acer', 'Asus', 'Alienware', 'Dell', 'HP', 'Lenovo', 'Microsoft', 'MSI', 'Razer', 'Samsung'],
      tablet: ['Apple', 'Samsung', 'Microsoft', 'Google', 'Huawei', 'Asus'],
      'vr/ar headset': ['Apple', 'Meta', 'Oculus', 'Sony']
    },
    models: {
      phone: {
        Apple: ['iPhone SE', 'iPhone 6', 'iPhone 6+', 'iPhone 6S+', 'iPhone 6S+', 'iPhone 7', 'iPhone 7+', 'iPhone 8', 'iPhone 8+', 'iPhone X', 'iPhone XR', 'iPhone XS', 'iPhone XS Max', 'iPhone 11', 'iPhone 11 Pro', 'iPhone 11 Pro Max', 'iPhone SE 2nd Gen', 'iPhone 12 Mini', 'iPhone 12', 'iPhone 12 Pro', 'iPhone 12 Pro Max'],
        Samsung: ['Galaxy A50', 'Galaxy A51', 'Galaxy A51 5G', 'Galaxy A71 5G', 'Galaxy S7', 'Galaxy S7 Edge', 'Galaxy S8', 'Galaxy S8+', 'Galaxy S9', 'Galaxy S9+', 'Galaxy S10e', 'Galaxy S10', 'Galaxy S10+', 'Galaxy S20 FE 5G', 'Galaxy S20 5G', 'Galaxy S20+ 5G', 'Galaxy S20 Ultra 5G', 'Galaxy S21', 'Galaxy S21+', 'Galaxy S21 Ultra', 'Note 8', 'Note 9', 'Note 10', 'Note 10+', 'Note 10 Lite', 'Note 20 5G', 'Note 20 Ultra 5G', 'Galaxy Z Flip', 'Galaxy Fold', 'Galaxy Z Fold 2'],
        Google: ['Nexus 6P', 'Nexus 6', 'Pixel', 'Pixel XL', 'Pixel 2', 'Pixel 2 XL', 'Pixel 3', 'Pixel 3 XL', 'Pixel 3a', 'Pixel 4', 'Pixel 4 XL', 'Pixel 4a', 'Pixel 5'],
        OnePlus: ['6', '6T', '7', '7T', '7T Pro', '8', '8 Pro'],
        Motorola: ['Moto X', 'Moto X 2nd Gen', 'Nexus 6', 'razr', 'moto g stylus', 'moto g power', 'moto g', 'motorola one fusion+', 'motorla one hyper', 'motorola one zoom', 'motorola one action'],
        HTC: [ 'One', 'One M8', 'One M9', 'One X', 'One X+', 'edge+' ],
        LG: ['G2', 'G3', 'G4', 'V10']
      },
      laptop: {
        Apple: ['Macbook', 'Macbook Air', 'Macbook Pro'],
        Acer: ['Aspire E', 'Aspire F', 'Aspire R', 'Aspire 3', 'Aspire Swift', 'Nitro 5', 'Spin 5', 'Chromebook'],
        Asus: ['ZenBook', 'ZenBook Flip', 'ZenBook Pro', 'VivoBook', 'VivoBook Flip', 'P-Series', 'Chromebook'],
        Dell: ['Inspiron', 'Latitude', 'XPS', 'Chromebook'],
        HP: ['Envy', 'Envy x360', 'Spectre', 'Spectre x360', 'Pavilion', 'ProBook', 'EliteBook', 'Chromebook'],
        Lenovo: ['Yoga', 'Flex', 'Thinkpad', 'Ideapad', 'Edge', 'Chromebook'],
        Microsoft: ['Surface', 'Surface 2', 'Surface 3', 'Surface Go', 'Surface Go 2', 'Surface Laptop', 'Surface Laptop 2', 'Surface Laptop 3', 'Surface Book', 'Surface Book 2', 'Surface Book 3', 'Surface Pro 3', 'Surface Pro 4', 'Surface Pro', 'Surface Pro 6', 'Surface Pro 7', 'Surface Pro X', 'Surface Studio', 'Surface Studio 2'],
        Razer: ['The Razer Blade', 'Razer Blade Stealth', 'Razer Blade Pro'],
        Samsung: ['Chromebook']
      },
      'vr/ar headset': {
        Apple: ['Vision Pro'],
        Meta: ['Quest 3', 'Quest Pro'],
        Oculus: ['Quest 2', 'Quest', 'Rift S', 'Go', 'Rift', 'Rift DK2', 'Rift DK1'],
        Sony: ['PlayStation VR2', 'PlayStation VR']
      }
    },
    notAllowedCoverageTags: []
  },
  {
    icon: require('@/assets/appicons/Entertainment.svg'),
    name: 'entertainment',
    types: [
      {
        name: 'TV',
        notAllowedCoverageTags: []
      },
      {
        name: 'Gaming Console',
        notAllowedCoverageTags: []
      },
      {
        name: 'Gaming Handheld',
        notAllowedCoverageTags: []
      },
      {
        name: 'Streaming Device',
        notAllowedCoverageTags: []
      }
    ],
    description: 'TV\'s & Gaming Devices',
    notAllowedCoverageTags: []
  },
  {
    icon: require('@/assets/appicons/Audio.svg'),
    name: 'audio',
    types: [
      {
        name: 'Headphones',
        notAllowedCoverageTags: []
      },
      {
        name: 'Speakers',
        notAllowedCoverageTags: []
      },
      {
        name: 'Professional Audio Equipment',
        notAllowedCoverageTags: []
      },
      {
        name: 'DJ Equipment',
        notAllowedCoverageTags: []
      },
      {
        name: 'Guitar',
        notAllowedCoverageTags: []
      },
      {
        name: 'Keyboard',
        notAllowedCoverageTags: []
      }
    ],
    description: 'Headphones, Speakers etc.',
    notAllowedCoverageTags: []
  },
  {
    icon: require('@/assets/appicons/Photography.svg')  ,
    name: 'photography',
    types: [
      {
        name: 'Camera',
        notAllowedCoverageTags: []
      },
      {
        name: 'Lens',
        notAllowedCoverageTags: []
      },
      {
        name: 'Camera Accessory',
        notAllowedCoverageTags: []
      }
    ],
    description: 'Cameras & Lenses',
    notAllowedCoverageTags: []
  },
  {
    icon: require('@/assets/appicons/Transport.svg'),
    name: 'transport',
    types: [
      {
        name: 'Bicycle (non-motorized)',
        notAllowedCoverageTags: []
      },
      {
        name: 'Skateboard (non-motorized)',
        notAllowedCoverageTags: []
      },
      {
        name: 'Scooter (non-motorized)',
        notAllowedCoverageTags: []
      },
      {
        name: 'Rollerblades',
        notAllowedCoverageTags: []
      },
      {
        name: 'Rollerskates',
        notAllowedCoverageTags: []
      },
      {
        name: 'Unicycle',
        notAllowedCoverageTags: []
      }
    ],
    description: 'Bikes, Scooters & Skateboards',
    notAllowedCoverageTags: [PLAN_TAGS.HOME_PLAN]
  },
  {
    icon: require('@/assets/appicons/Sports.svg'),
    name: 'sports',
    types: [
      {
        name: 'Golf Clubs',
        notAllowedCoverageTags: []
      },
      {
        name: 'Skiis',
        notAllowedCoverageTags: []
      },
      {
        name: 'Snowboard',
        notAllowedCoverageTags: []
      },
      {
        name: 'Surfboard',
        notAllowedCoverageTags: []
      }
    ],
    description: 'Golf Clubs, Skiis etc.',
    notAllowedCoverageTags: [PLAN_TAGS.HOME_PLAN]
  },
  {
    icon: require('@/assets/appicons/Accessories.svg'),
    name: 'accessories',
    types: [
      {
        name: 'Backpack',
        notAllowedCoverageTags: []
      },
      {
        name: 'Watch',
        notAllowedCoverageTags: []
      },
      {
        name: 'Sunglasses',
        notAllowedCoverageTags: []
      },
      {
        name: 'Glasses',
        notAllowedCoverageTags: []
      },
      {
        name: 'Jewelry',
        notAllowedCoverageTags: []
      }
    ],
    description: 'Watches, Bags & Glasses',
    notAllowedCoverageTags: [PLAN_TAGS.HOME_PLAN]
  },
  {
    icon: require('@/assets/appicons/SmallAppliances.svg'),
    name: 'Household',
    types: [
      {
        name: 'Blender',
        notAllowedCoverageTags: []
      },
      {
        name: 'Microwave',
        notAllowedCoverageTags: []
      },
      {
        name: 'Coffee Maker (counter-top)',
        notAllowedCoverageTags: []
      }
    ],
    description: 'Microwaves, Mini-Fridges etc.',
    notAllowedCoverageTags: []
  }
]
const reEnablePlanHref = `mailto:${SUPPORT_EMAIL}?Subject=${RE_ENABLE_PLAN}`
const bannedPlanHref = `mailto:${SUPPORT_EMAIL}?Subject=Inquiry Regarding Banned Account`
export const INVALID_MEMBERSHIP_DISPLAY_TEXT = {
  'CANCELED': {
    'spanText': "Your coverage plan was cancelled. If you'd like to re-enable your coverage, please ",
    'emailHref': reEnablePlanHref
  },
  'UNPAID': {
    'spanText': 'Your coverage plan is currently unpaid. Please proceed to',
    'emailHref': reEnablePlanHref
  },
  'PAST_DUE': {
    'spanText': 'Your coverage plan is currently past due. Please proceed to ',
    'emailHref': reEnablePlanHref
  },
  'INACTIVE': {
    'spanText': "Your coverage plan was inactive. If you'd like to re-enable your coverage, please ",
    'emailHref': reEnablePlanHref
  },
  'INVALID_USER': {
    'spanText': 'Your account has been marked as invalid by our system due to unpaid premiums or other account issues. If you think this was done in error, please ',
    'emailHref': reEnablePlanHref
  },
  'BANNED': {
    'spanText': 'Your account has been banned. If you think this was done in error, please ',
    'emailHref': bannedPlanHref
  }
}
export const ITEM_SPEC_NOT_COVERED = 'is not supported by your plan. Please select a different item or contact support to upgrade your plan.'
export const ITEM_SPEC_LIMIT_REACHED_PREFIX = 'You have already reached the maximum number of allowed units for '
export const ITEM_SPEC_LIMIT_REACHED_SUFFIX = 'Please remove a device of the same kind or contact support to upgrade your plan.'
