<template>
  <transition name="fade">
    <div
      id="overlay"
      class="fixed top-0 left-0 bottom-0 right-0 z-9999 overflow-hidden w-100 h-100 bg-black-40 fade overflow-y-scroll"
      role="dialog"
      tabindex="-1"
      @click="closeModal"
    >
      <div
        id="modal"
        class="ma2 mv4 bg-white mt5-ns mb6-ns ba b--light-gray br2 center-ns animated-slow slide-in"
        :class="(small) ? 'mw6': 'mw7'"
      >
        <div class="pa3 flex justify-between items-center">
          <div :class="(ceraTitle) ? 'cera-round-pro mb2 mt3 mr2 ml3 f4' : 'ma0 f3'">
            {{ title }}
          </div>
          <div
            aria-hidden="true"
            class="right f3 pointer"
            :class="(ceraTitle)? 'mt3 mr3 mb2' : ''"
            @click="emitClose"
          >
            &#10005;
          </div>
        </div>

        <hr
          v-if="!noHr"
          class="b--black-10"
        >

        <div class="pa3">
          <slot name="modal-body">
            Placeholder modal body
          </slot>
        </div>

        <hr
          v-if="!noHr"
          class="b--black-10"
        >

        <slot name="modal-footer">
          <div class="pa3 flex justify-end">
            <ak-button
              secondary
              @click.native="emitClose"
            >
              Close
            </ak-button>
          </div>
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
import AkButton from './Button.vue'

export default {
  name: 'AkModal',
  components: {
    AkButton
  },
  props: {
    title: {
      type: String,
      default: 'Placeholder Title'
    },
    onMount: {
      type: Function
    },
    noHr: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    ceraTitle: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    document.body.classList.add('overflow-y-hidden')
    if (this.onMount) {
      this.onMount()
    }
  },
  beforeDestroy () {
    document.body.classList.remove('overflow-y-hidden')
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
    closeModal (event) {
      if (event.target.id === 'overlay') {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .15s
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0
}

.animated-slow {
  animation-duration: .30s;
  animation-fill-mode: both;
}

@keyframes slideInDown {
  from {
    transform: translate(0, -100%);
  }

  to {
    transform: none;
  }
}

.slide-in {
  animation-name: slideInDown;
}
</style>
