<template>
  <div class="db mt5-ns centered-container-ns inter lh-copy">
    <div class="shadow-theme-ns ph4-ns pv4-ns">
      <center>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#3dbf00"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-check"
        ><polyline points="20 6 9 17 4 12" /></svg>
      </center>
      <h2 class="f3 lh-copy cera-round-pro-bold tc-ns">
        New plan added successfully!
      </h2>

      <br>
      <br>

      <div>
        <div
          v-for="plan in addedPlans"
          :key="plan.coverage.id"
          class="flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-smartphone"
          ><rect
            x="5"
            y="2"
            width="14"
            height="20"
            rx="2"
            ry="2"
          /><line
            x1="12"
            y1="18"
            x2="12.01"
            y2="18"
          /></svg>
          <span class="mh2 cera-round-pro"><strong>{{ plan.phoneNumber }}</strong> - {{ plan.coverage.name }}</span>
          <div v-if="addedPlans && addedPlans.length > 1">
            <br>
            <br>
          </div>
        </div>
      </div>

      <br>

      <div class="flex items-center">
        <span class="f4 cera-round-pro-bold">Next Steps:</span>
      </div>

      <div class="flex">
        <span class="inter">Please follow the instructions sent via SMS to the new device. <strong>New lines must log in to complete activation separately for coverage to be in effect.</strong></span>
      </div>
    </div>

    <div class="mv4 tc">
      If you have any questions or need help completing your registration, don't hesitate to reach out to us via the links below!
    </div>

    <div class="mv4 flex justify-around">
      <ak-button
        secondary
        @click.native="goToSupportPage"
      >
        Support
      </ak-button>
      <ak-button
        primary
        @click.native="returnToAccountPage"
      >
        Return to Account
      </ak-button>
    </div>
  </div>
</template>

<script>
import AkButton from '@/components/Button'
import { SET_ADD_PLAN_SELECTED_COVERAGES } from '../../store/index'
import { mapMutations } from 'vuex'

export default {
  name: 'AkPlanAddedConfirmation',
  components: {
    AkButton
  },
  data () {
    return {
      addedPlans: []
    }
  },
  mounted () {
    this.addedPlans = this.$store.state.addPlanSelectedCoverages
    this.setAddPlanSelectedCoverages()
  },
  methods: {
    ...mapMutations({
      setAddPlanSelectedCoverages: SET_ADD_PLAN_SELECTED_COVERAGES
    }),
    goToSupportPage () {
      this.$router.push('/support')
    },
    returnToAccountPage () {
      this.$router.push('/account/dashboard')
    }
  }
}
</script>

<style scoped>

@media screen and (max-width: 480px) {
  .pt4 {
    padding-top: 5.5rem;
  }
}

</style>
