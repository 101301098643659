<template>
  <div class="mt7 mh3 mh4-ns">
    <div class="mw8-ns center">
      <div
        v-if="$store.state.errors.length"
        class="tc"
      >
        {{ $store.state.errors[0] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AKConfirmation',
  props: {
    token: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.confirmEmail()
  },
  methods: {
    confirmEmail () {
      this.$store.dispatch('confirmEmail', {token: this.token, redirectUrl: '/'})
    }
  }
}
</script>

<style>

</style>
