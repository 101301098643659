<template>
  <main class="flex bg-white br3 new-shadow-theme mr5 custom-padding responsive-box">
    <div class="flex flex-column w-100 responsive-plan">
      <div class="cera-round-pro-medium f4 mb3 fw5">
        Choose Your Plan
      </div>
      <div class="flex flex-row flex-wrap w-100 justify-between cards mv2">
        <plan-select-toggle-vue
          :is-phone-only="isPhoneOnly"
          :set-value="setValue"
          :set-akko-plan="setAkkoPlan"
          :set-phone-plan="setPhonePlan"
        />
      </div>
      <div>
        <div class="cera-round-pro mv3">
          Protect 1 phone per plan
        </div>
        <div class="f5 fw gray mt2 flex">
          <div class="flex flex-wrap gradual-width">
            <select-plan-icon-with-text
              v-for="(info, index) in planInfo"
              :key="index"
              div-class="mv2 mr2 plan-be inter"
              :text="info"
              :svg="require('@/assets/appicons/Check.svg')"
              svg-style="margin-top: -1px"
            />
          </div>
          <div class="flex">
            <div class="vertical-bar mr3" />
            <div class="flex flex-column justify-around">
              <div class="cera-round-pro-medium f3">
                {{ planValues }} <span class="inter f5">Per Month</span>
              </div>
              <div class="inter">
                $29 - $99 deductibles
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import PlanSelectToggleVue from '../PlanSelectToggle.vue'
import SelectPlanIconWithText from './SelectPlanIconWithText.vue'

export default {
  name: 'AkSelectPlan',
  components: {
    PlanSelectToggleVue,
    SelectPlanIconWithText
  },
  props: {
    isPhoneOnly: {
      type: Boolean,
      default: false
    },
    setValue: Function
  },
  data () {
    return {
      phoneOnly: [
        'Protect any phone', '$29 - $99 deductibles', 'Accidental damage', 'Parts &amp; battery failures'
        // ,'Theft', 'Replacement cost of your phone', 'Unlimited claims', 'Save up to 15% paying annually!'
      ],
      akkoPlan: [
        'Protect 1 phone + 25 Items', '$29 - $99 deductibles', 'Accidental damage', 'Phone parts &amp; battery failures'
        // , 'Theft', 'Up to $2,000 per claim', 'Unlimited claims', 'Students save up to 33%!'
      ]
    }
  },
  computed: {
    planInfo () {
      return this.isPhoneOnly ? this.phoneOnly : this.akkoPlan
    },
    planValues () {
      return this.isPhoneOnly ? '$5-$12' : '$17'
    }
  },
  methods: {
    setAkkoPlan (event) {
      this.setValue(false, event)
    },
    setPhonePlan (event) {
      this.setValue(true, event)
    }
  }
}
</script>

<style scoped>
.custom-padding {
  padding: 2.5em 4% 2.5em 4%;
}

.plan-be {
  width: 195px;
}

.gradual-width {
  width: 55%;
}
.vertical-bar {
  border: 0.7px solid #E8E8E8;
  background-color: #E8E8E8
}
/* .responsive-box {
  margin-top: 35px;
} */
@media (max-width: 1280px) {
  .responsive-box {
    width: 80%;
    margin: 1em 0;
  }
}
@media (max-width: 1000px) {
  .cards {
    justify-content: center;
  }
  .outside {
    align-self: center;
  }
}
@media (max-width: 600px) {
  .responsive-box {
    width: 100%;
    margin: 1em 0;
  }
  .custom-padding {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .responsive-plan {
    border: 1px solid var(--theme-medium-gray);
    padding: 1.5em 2.2em;
    border-radius: 0.5em;
    background-color: white;
  }
}
</style>
