import * as Sentry from '@sentry/vue'

const isProduction = process.env.NODE_ENV === 'production'

if (window.mixpanel) {
  window.mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, { batch_requests: true })
}

export const MIXPANEL_EVENTS = {
  // Register Page
  TOGGLE_PLAN_TYPE: 'Toggle Plan Type',
  SELECT_PHONE_MAKE: 'Select Phone Make',
  SELECT_PHONE_MODEL: 'Select Phone Model',
  ADD_DEVICE: 'Add Device',
  REMOVE_DEVICE: 'Remove Device',
  TOGGLE_IS_ADULT: 'Toggle Is Adult',
  TOGGLE_PLAN_INTERVAL: 'Toggle Plan Interval', // handled on both register and checkout page
  CLICK_PROCEED_TO_CHECKOUT: 'Click Proceed To Checkout',

  // Checkout Page
  TOGGLE_PAY_METHOD: 'Toggle Pay Method',
  APPLY_PROMOTION: 'Apply Promotion',
  PURCHASE: 'Purchase',

  // Upsell
  UPSELL_CLICK_EXPAND: 'Upsell Click Expand Toggle',
  UPSELL_SUBMIT: 'Upsell Submit',
  UPSELL_PAGE_VIEW: 'Upsell Page View',

  // Login
  LOGIN: 'Login'
}

const googleTagManagerInstance = function (...args) {
  if (!window.dataLayer) return
  return window.dataLayer.push(...args)
}

const tapfiliateInstance = function (...args) {
  if (!window.tap) return
  return window.tap(...args)
}

const fbInstance = function (...args) {
  if (!window.fbq) return
  return window.fbq(...args)
}

// TODO: remove this as it doesn't work: window.mixpanel will always be defined as
// we are initializing it in the html file (it will have the init method at least)
// const getMixpanelInstance = function () {
//   if (!window.mixpanel) {
//     return {
//       identify: () => {
//       },
//       track: () => {
//       },
//       people: {
//         set: () => {
//         }
//       }
//     }
//   }
//   return window.mixpanel
// }

function trackConversionOnFb (conversionPayload) {
  fbInstance('track', 'Purchase', {
    value: conversionPayload.reduce((acum, conversion) => acum + conversion.coverage_price, 0),
    currency: 'USD',
    content_name: conversionPayload.length === 1 ? conversionPayload[0].coverage_name : 'several coverages'
  })
}

function trackConversionOnTapfiliate (conversionPayload, transactionId) {
  const amount = conversionPayload.reduce((acum, conversion) => acum + conversion.coverage_price, 0)
  const mainUserConversion = conversionPayload.find(conversion => conversion.is_user_primary)
  const meta = `main user is: ${mainUserConversion ? mainUserConversion.user_id : ''}`
  conversionPayload.forEach(conversion => {
    tapfiliateInstance(
      'conversion',
      transactionId,
      conversion.is_user_primary ? amount : 0,
      {
        customer_id: conversion.user_id,
        meta_data: meta
      }
    )
  })
}

export function trackOnMixpanel (eventName, payload = {}) {
  const mixpanel = window.mixpanel;
  if (!mixpanel || !mixpanel.track) return;
  mixpanel.track(eventName, payload)
}

function trackConversionsOnMixpanel (conversionPayload, transactionId) {
  const mixpanel = window.mixpanel;
  if (!mixpanel || !mixpanel.track || !mixpanel.identify || !mixpanel.people) return;
  const mainUser = conversionPayload.find(conversion => conversion.is_user_primary)
  mixpanel.identify(mainUser.user_id)
  conversionPayload.forEach(conversion => {
    mixpanel.people.set({
      'internalId': conversion.user_id,
      'plan': conversion.coverage_name,
      'purchaseDate': new Date(),
      'name': conversion.user_id,
      'phone': conversion.user_phone,
      'email': conversion.user_email ? conversion.user_email : '',
      'userMembershipStatus': 'Active'
    })
    mixpanel.track(MIXPANEL_EVENTS.PURCHASE, {
      'mainUser': mainUser,
      'value': conversion.coverage_price,
      'itemPrice': conversion.coverage_price,
      'currency': 'USD',
      'transactionId': transactionId,
      'plan': conversion.coverage_name,
      'planQuantity': 1,
      'familyPlan': conversionPayload.length > 1,
      'primary': conversion.is_user_primary
    })
  })
}

function trackConversionsOnGoogleTagManager (conversionPayload) {
  conversionPayload.forEach(conversion => {
    googleTagManagerInstance({'userId': conversion.user_id})
    const transactionId = `${conversion.user_id}-${(new Date()).getTime()}`
    googleTagManagerInstance({
      'event': 'purchase-trigger',
      'conversionValue': conversion.coverage_price,
      'conversionCurrency': 'USD',
      'conversionTransactionId': transactionId,
      'plan': conversion.coverage_name,
      'user': conversion.user_id,
      'transactionId': transactionId,
      'transactionTotal': conversion.coverage_price,
      'transactionProducts': [
        {
          'sku': conversion.coverage_id.toString(),
          'name': conversion.coverage_name,
          'price': conversion.coverage_price,
          'quantity': 1
        }]
    })
  })
}

/*
* conversionPayload is expected to have the following form:
* [{
*    user_id: Number.,
*    coverage_id: Number.,
*    coverage_name: String,
*    coverage_price: Number,
*    is_user_primary: Boolean,
*    user_phone: String,
*    user_email: String,
*    item_covered: String,
*  }]
* */
export function trackConversion (conversionPayload, transactionId) {
  try {
    trackConversionsOnMixpanel(conversionPayload, transactionId)
  } catch (e) {
    Sentry.captureException(e)
  }

  if (isProduction) {
    try {
      trackConversionsOnGoogleTagManager(conversionPayload)
    } catch (e) {
      Sentry.captureException(e)
    }
    try {
      trackConversionOnTapfiliate(conversionPayload, transactionId)
    } catch (e) {
      Sentry.captureException(e)
    }

    try {
      trackConversionOnFb(conversionPayload)
    } catch (e) {
      Sentry.captureException(e)
    }
  }
}
