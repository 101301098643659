<template>
  <main id="intercom-element">
    <div
      class="flex flex-row items-center mobile-flex pl2 pr2 pb2"
      @click="goBack"
    >
      <arrow-back1 />
      <h3 class="ml3 f4 cera-round-pro">
        Previous Payments
      </h3>
    </div>

    <div class="mt3 flex flex-column inter responsive-none desktop">
      <div class="flex f5 top-container-realign items-center mb2 flex-row">
        <div class="pl4 silver">
          <router-link
            class="no-underline account-hover soft-grey"
            to="/account/dashboard"
          >
            {{ $t('menu.accountsettings', { accountsettings: 'Account Settings' }) }}
          </router-link>
        </div>
        <div class="soft-grey">
          &nbsp;  /  &nbsp;
        </div>
        <router-link
          class="no-underline account-hover soft-grey"
          to="/account/billing"
        >
          {{ $t('billingsettings.billingsettingsheader', { billingsettingsheader: 'Billing' }) }}
        </router-link>
        <div class="soft-grey">
          &nbsp;  /  &nbsp;
        </div>
        <div class="soft-grey">
          {{ $t('billingsettings.viewprevious', { viewprevious: 'Previous Payments' }) }}
        </div>
      </div>
      <h2 class="pl4 f2 mb4 title-realign cera-round-pro">
        {{ $t('billingsettings.viewprevious', { viewprevious: 'Previous Payments' }) }}
      </h2>
    </div>

    <div class="pl4-l pl3-m pl3 flex flex-column justify-between items-start">
      <div
        v-for="(invoice, index) in invoices"
        :key="index"
        class="mb3 invoice-box"
      >
        <div class="flex flex-row items-center">
          <div class="mr2">
            <img
              src="@/assets/appicons/File.svg"
              alt=""
            >
          </div>
          <label
            for="first_name"
            class="f6 b inter "
          >{{ invoiceData(invoice) }} (<a
            :href="invoice.hosted_invoice_url"
            class="red no-underline"
            target="_blank"
            rel="noopener"
          >
            View Invoice</a>)
          </label>
        </div>
      </div>
      <ak-button
        block
        class="btn-add inter"
        :disabled="disableButton"
        secondary
        @click.native="increaseTotalInvoice"
      >
        {{ disableButton ? 'No More Invoices Available': 'View More' }}
      </ak-button>
    </div>
  </main>
</template>

<script>

import ArrowBack1 from '../../icons/ArrowBack.vue'
import AkButton from '../../../Button.vue'
import { CURRENCY, LANGUAGE, BROWSER } from '../../../../constants/account-settings/accountSettings'

export default {
  name: 'BillingInvoices',
  components: {
    ArrowBack1,
    AkButton
  },
  data () {
    return {
      totalInvoices: 10
    }
  },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf(BROWSER) !== -1)
    },
    user () {
      return this.$store.state.user
    },
    invoices () {
      return this.totalInvoices === 10 ? this.user.invoice_information.all_invoices.slice(0, 10) : this.user.invoice_information.all_invoices.slice(0, 20)
    },
    isLastIndex () {
      return this.invoices.length === this.user.invoice_information.all_invoices.length
    },
    disableButton () {
      return this.totalInvoices === 20 || this.isLastIndex
    }
  },
  mounted () {
    if (!this.isMobile) {
      const {email, phone_no, first_name, user_id} = this.$store.state.user
      this.$intercom.boot({
        userId: user_id,
        user_id: user_id,
        name: first_name,
        email: email,
        phone: phone_no
      })
    }
  },
  methods: {
    invoiceData (invoice) {
      const invoiceDueDate = this.formatDate(invoice.status_transitions.paid_at)
      const status = invoice.status
      const total = (invoice.total / 100).toLocaleString(LANGUAGE, {style: 'currency', currency: CURRENCY})
      return `${invoiceDueDate} - ${total} - ${status}`
    },
    formatDate (date) {
      const toDate = new Date(date * 1000)
      const day = ('0' + (toDate.getUTCDate())).slice(-2)
      const month = ('0' + (toDate.getMonth() + 1)).slice(-2)
      const year = toDate.getFullYear()
      return `${month}/${day}/${year}`
    },
    goBack () {
      this.$router.push('/account/billing')
    },
    increaseTotalInvoice () {
      this.totalInvoices = this.totalInvoices + 10
    }
  }
}
</script>

<style>

.mobile-flex {
  display: none;
}

.title-realign {
  margin-left: -3px
}

.top-container-realign {
  margin-top: 1px;
}

.account-hover {
  cursor: pointer;
}

.account-hover:hover {
  color: #7A7A7C;
  text-decoration: underline;
}

.soft-grey {
  color: #9BA1AB;
}

.chevron {
  color: #9BA1AB;
  height: 17px;
  width: 17px;
}

.btn-add {
  height: 30px;
  width:310px;
  font-weight: bold;
  margin-top: 1rem;
  border-radius: 5px;
}

.invoice-box {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {

  .mobile-flex {
    display: flex;
    margin-bottom: 1rem;
  }

.desktop {
    display: none;
  }

}
</style>
