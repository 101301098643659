import Vue from 'vue'
import Router from 'vue-router'

import Faq from '@/components/Faq'

// Utility
import FourZeroFour from '@/components/404'
import Confirmation from '@/components/Confirmation'

// New Registration Form
import RegistrationBase from '@/components/registration/Base'
import SelectCoverage from '@/components/registration/SelectCoverage'
import SelectCoverageWm from '@/components/registration/SelectCoverageWm'
import SelectAdultCoverage from '@/components/registration/SelectAdultCoverage'
import SelectAdultCoverageWm from '@/components/registration/SelectAdultCoverageWm'
import SelectPhoneCoverageSingleBilling from '@/components/registration/SelectPhoneCoverageSingleBilling'
import SelectMultiplePhoneCoverageBilling from '@/components/registration/SelectMultiplePhoneCoverageBilling'
import SelectMultiplePhoneCoverageBillingWm from '@/components/registration/SelectMultiplePhoneCoverageBillingWm'
import ReviewAndSubmit from '@/components/registration/ReviewAndSubmit'
import AdultReviewAndSubmit from '@/components/registration/AdultReviewAndSubmit'
import Redeem from '@/components/registration/Redeem'
import StudentRedeem from '@/components/registration/StudentRedeem'
import PartnerRedeem from '@/components/registration/PartnerRedeem'
import RegistrationComplete from '@/components/registration/RegistrationComplete'
import UnifiedRegistration from '@/components/registration/UnifiedRegistration'
import UnifiedUpsell from '@/components/registration/upsell/UnifiedUpsell'

// New Account Seting
import UnifiedAccountSettingsScreen
  from '@/components/account-settings/screens/personal-information/UnifiedAccountSettingsScreen'
import UnifiedPlans from '@/components/account-settings/screens/plans/UnifiedPlans'
import UnifiedSecurity from '@/components/account-settings/screens/security/UnifiedSecurity'
import UnifiedReferrals from '@/components/account-settings/screens/referrals/UnifiedReferrals'
import UnifiedBilling from '@/components/account-settings/screens/billing/UnifiedBilling'
import PastInvoices from '@/components/account-settings/screens/billing/BillingInvoices'

// Registration V2
import ChoosePlan from '@/components/registration-v2/ChoosePlan'
import ChooseQuantity from '@/components/registration-v2/ChooseQuantity'
import Checkout from '@/components/registration-v2/Checkout'
import CheckoutWm from '@/components/registration-v2/CheckoutWm'

// Account Activation
import PersonalInfo from '@/components/activation/PersonalInfo'
import ActivateAccount from '@/components/activation/ActivateAccount'

// Login
import Login from '@/components/Login'
import ForgotPassword from '@/components/ForgotPassword'

// Account Settings
import AccountSettings from '@/components/account-settings/UnifiedAccountSettings'

// Application
import AppBase from '@/components/AppBase'
import Dashboard from '@/components/Dashboard/index'
import AddDevice from '@/components/AddDevice'
import AddDeviceV2 from '@/components/AddDeviceV2'
import AddDeviceCategory from '@/components/AddDeviceCategory'
import DevicesPage from '@/components/DevicesPage'
import DevicePage from '@/components/DevicePage'
import LossType from '@/components/LossType'
import AddClaim from '@/components/AddClaim'
import AddClaim2 from '@/components/AddClaim2'
import ClaimInfoPage from '@/components/ClaimInfoPage'
import ClaimsPage from '@/components/ClaimsPage'
import ClaimsV2Page from '@/components/ClaimsV2Page'
import ClaimPage from '@/components/ClaimPage'
import AccountPage from '@/components/AccountPage'
import EditCoverage from '@/components/EditCoverage'
import Invoices from '@/components/Invoices'
import StoragePage from '@/components/StoragePage'
import SupportPage from '@/components/SupportPage'
import ContactPage from '@/components/ContactPage'
import MentorPage from '@/components/MentorPage'
import SupportChatPage from '@/components/SupportChatPage'
import AddPlan from '@/components/AddPlan'
import AddPhoneOnlyPlan from '@/components/add-plan/AddPhoneOnlyPlan'
import AddAkkoPlan from '@/components/add-plan/AddAkkoPlan'
import AddPlanConfirmation from '@/components/add-plan/PlanAddedConfirmation'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior () {
    return new Promise((resolve) => {
      setTimeout(() => resolve(localStorage.getItem('scrollPosition')), 300)
    })
  },
  beforeEach (to, from, next) {
    window.fbq('track', 'PageView')
    next()
  },
  routes: [
    {
      path: '/',
      component: AppBase,
      children: [
        {
          path: '/',
          name: 'Akko Dashboard',
          component: Dashboard
        },
        {
          path: 'add-device-category',
          name: 'Akko Add Device Category',
          component: AddDeviceCategory
        },
        {
          path: 'add-device',
          name: 'Add Device',
          component: AddDevice,
          props: true
        },
        {
          path: 'add-device-v2',
          name: 'Add Device V2',
          component: AddDeviceV2,
          props: true
        },
        {
          path: 'add-plan',
          name: 'Add Plan',
          component: AddPlan
        },
        {
          path: 'add-plan/confirmation',
          name: 'Add Plan Confirmation',
          component: AddPlanConfirmation
        },
        {
          path: 'add-phone-only-plan',
          name: 'Add Phone Only Plan',
          component: AddPhoneOnlyPlan
        },
        {
          path: 'add-akko-plan',
          name: 'Add Akko Plan',
          component: AddAkkoPlan
        },
        {
          path: 'devices',
          name: 'Akko Devices',
          component: DevicesPage
        },
        {
          path: 'devices/:id',
          name: 'Akko Device',
          component: DevicePage
        },
        {
          path: 'select-loss-type',
          name: 'Select Loss Type',
          component: LossType
        },
        {
          path: 'file-claim',
          name: 'Akko File Claim',
          component: AddClaim
        },
        {
          path: 'file-claim2',
          name: 'Akko File Claim2',
          component: AddClaim2
        },
        {
          path: 'file-claim2/:id',
          name: 'Akko File Claim2 id',
          component: AddClaim2
        },
        {
          path: 'claim-info',
          name: 'Akko Claim Info',
          component: ClaimInfoPage
        },
        {
          path: 'claims',
          name: 'Akko Claims',
          component: ClaimsPage
        },
        {
          path: 'claims-v2',
          name: 'Akko Claims v2',
          component: ClaimsV2Page
        },
        {
          path: 'claims/:id',
          name: 'Akko Claim',
          component: ClaimPage
        },
        {
          path: 'account-settings',
          name: 'Akko Account',
          component: AccountPage
        },
        {
          path: 'personal-new',
          name: 'Akko Personal New',
          component: UnifiedAccountSettingsScreen
        },
        {
          path: '/account',
          redirect: '/account/dashboard',
          name: 'Account',
          component: {
            template: '<router-view/>'
          },
          children: [
            {
              path: 'dashboard',
              name: 'Dashboard',
              component: AccountSettings
            },
            {
              path: 'plans',
              name: 'Plans',
              component: UnifiedPlans
            },
            {
              path: 'personal',
              name: 'Personal',
              component: UnifiedAccountSettingsScreen
            },
            {
              path: 'security',
              name: 'Security',
              component: UnifiedSecurity
            },
            {
              path: 'referrals',
              name: 'Referrals',
              component: UnifiedReferrals
            },
            {
              path: 'billing',
              name: 'Billing',
              component: UnifiedBilling
            },
            // {
            //   path: 'billing-partners',
            //   name: 'Billing',
            //   component: UnifiedBillingPartners
            // },
            {
              path: 'invoices',
              name: 'Past Invoices',
              component: PastInvoices
            }
          ]
        },
        // {
        //   path: '/account-settings-new',
        //   name: 'Akko New Settings',
        //   component: AccountSettings,
        //   children: [
        //     {
        //       path: 'plans',
        //       name: 'Plans',
        //       component: ChoosePlan
        //     }
        //  {
        //    path: 'personal',
        //    name: 'Personal Information',
        //    component: PersonalInfo
        //  },
        // {
        //   path: 'billing',
        //   name: 'Billing Information',
        //   component: UnifiedRegistration
        // },
        // {
        //   path: 'referrals',
        //   name: 'Referrals',
        //   component: UnifiedRegistration
        // },
        // {
        //   path: 'security',
        //   name: 'Security',
        //   component: UnifiedRegistration
        // },
        //   {
        //   path: 'socials',
        //   name: 'Socials',
        //   component: UnifiedRegistration
        // }
        //   ]
        // },
        {
          path: 'edit-coverage',
          name: 'Akko Edit Coverage',
          component: EditCoverage
        },
        {
          path: 'invoices',
          name: 'Akko Invoices',
          component: Invoices
        },
        {
          path: 'storage',
          name: 'Akko Cloud Storage',
          component: StoragePage
        },
        {
          path: 'support',
          name: 'Akko Support',
          component: SupportPage
        },
        {
          path: 'faq',
          name: 'Akko FAQ',
          component: ContactPage
        },
        {
          path: 'contact',
          name: 'Akko Contact',
          component: ContactPage
        },
        {
          path: 'mentor',
          name: 'Akko Mentor',
          component: MentorPage
        },
        {
          path: 'support-chat',
          name: 'Akko Support Chat',
          component: SupportChatPage
        }
      ]
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: Faq
    },
    {
      path: '/registration',
      component: RegistrationBase,
      redirect: 'registration/3c/akko',
      children: [
        {
          path: '3c/akko',
          name: 'Registration Unified Akko 3c',
          component: UnifiedRegistration
        },
        {
          path: '3b/akko',
          name: 'Registration Unified Akko 3b',
          component: UnifiedRegistration
        },
        {
          path: '3b/phone',
          name: 'Registration Unified Phone 3b',
          component: UnifiedRegistration
        },
        {
          path: '3c/phone',
          name: 'Registration Unified Phone 3c',
          component: UnifiedRegistration
        },
        {
          path: 'choose-adult-plan',
          name: 'Akko Adult Registration Step 1',
          component: SelectAdultCoverage
        },
        {
          path: 'choose-phone-plan-billing',
          name: 'Akko Phone Registration Step 1',
          component: SelectPhoneCoverageSingleBilling
        },
        {
          path: 'choose-multiple-phone-plan-billing',
          name: 'Registration Multiple Phone Step 1',
          component: SelectMultiplePhoneCoverageBilling
        },
        {
          path: 'choose-a-plan',
          name: 'Akko Registration Step 1',
          component: SelectCoverage
        },
        {
          path: 'adult-checkout',
          name: 'Akko Adult Registration Step 2',
          component: AdultReviewAndSubmit
        },
        {
          path: 'checkout',
          name: 'Akko Registration Step 2',
          component: ReviewAndSubmit
        },
        {
          path: 'complete',
          name: 'Registration Complete',
          component: RegistrationComplete
        },
        {
          path: 'upsell',
          name: 'Registration Upsell',
          component: UnifiedUpsell
        },
        {
          path: 'redeem',
          name: 'Registration Adult Step 2 - Redeem',
          component: Redeem
        },
        {
          path: 'studentredeem',
          name: 'Registration Student Step 2 - Redeem',
          component: StudentRedeem
        },
        {
          path: 'partner-redeem',
          name: 'Registration Partner Step 2 - Redeem',
          component: PartnerRedeem
        },
        {
          path: 'wm',
          name: 'With Monthly',
          component: RegistrationBase,
          children: [
            {
              path: 'choose-multiple-phone-plan-billing',
              name: 'Registration Multiple Phone Monthly Step 1',
              component: SelectMultiplePhoneCoverageBillingWm
            },
            {
              path: 'choose-a-plan',
              name: 'Akko Registration Monthly Step 1',
              component: SelectCoverageWm
            },
            {
              path: 'choose-adult-plan',
              name: 'Akko Adult Registration Monthly Step 1',
              component: SelectAdultCoverageWm
            }
          ]
        }
      ]
    },
    {
      path: '/registration-v2',
      component: RegistrationBase,
      children: [
        {
          path: 'family/choose-quantity',
          name: 'Akko Registration - Choose Quantity',
          component: ChooseQuantity
        },
        {
          path: ':type/choose-plan',
          name: 'Akko Registration - Choose Plan',
          component: ChoosePlan
        },
        {
          path: 'checkout',
          name: 'Akko Adult Registration - Checkout',
          component: Checkout
        },
        {
          path: 'wm',
          name: 'With Monthly v2',
          component: RegistrationBase,
          children: [
            {
              path: 'checkout',
              name: 'Akko Adult Registration Monthly v2 - Checkout',
              component: CheckoutWm
            }
          ]
        }
      ]
    },
    {
      path: '/account-activation',
      component: RegistrationBase,
      children: [
        {
          path: 'step1',
          name: 'Akko Account Activation Step 1',
          component: PersonalInfo
        },
        {
          path: 'step3',
          name: 'Akko Account Activation Step 3',
          component: ActivateAccount
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/forgotpassword',
      name: 'Forgot Password',
      component: ForgotPassword
    },
    {
      path: '/confirm/:token',
      name: 'Confirm',
      component: Confirmation,
      props: true
    },
    {
      path: '*',
      name: '404',
      component: FourZeroFour
    }
  ]
})
