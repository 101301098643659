<template>
  <div
    class="mv2-l mv0 pa3 br1 flex justify-between font-responsive br-pill"
    :class="[bgColor, changeMargin && 'margin-fixed']"
  >
    <div
      class="fw5 mid-gray"
      :class="textColor"
    >
      <slot />
    </div>
    <div
      v-if="deleteable"
      class="f4 ml3 pointer mid-gray"
      :class="textColor"
      @click="emitClose"
    >
      &#10005;
    </div>
  </div>
</template>

<script>
export default {
  name: 'AkAlert',
  props: {
    deleteable: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    bgColor () {
      switch (this.color) {
        case 'red':
          return 'b--red bg-washed-red'
        case 'green':
          return 'b--green bg-washed-green'
        default:
          return ''
      }
    },
    textColor () {
      switch (this.color) {
        case 'red':
          return 'dark-red'
        case 'green':
          return 'dark-green'
        default:
          return ''
      }
    },
    changeMargin () {
      return this.$route.path === '/'
    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.dark-red {
  color: #A5030B;
}
.dark-green {
  color: #007516
}
.bg-washed-red {
  background-color: #FFEBEB;
}
.bg-washed-green {
  background-color: #F0FFF4;
}
.font-responsive {
  font-size: 18px;
}
.margin-fixed {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

@media (max-width: 768px) {

  .font-responsive {
  font-size: 14px;
}

  .margin-fixed {
  margin-left: 0;
  margin-right: 0;
}

}
</style>
