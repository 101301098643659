<template>
  <div class="mb4 flex flex-wrap outer">
    <div
      class="flex flex-column tc bg-white new-shadow-theme br3  w-100 ph4 main-container"
      :class="[
        !isSomeComplete && 'h-100',
        showSavings && 'border-annual'
      ]"
    >
      <no-device-data v-if="!isSomeComplete" />
      <div
        v-else
        class="flex flex-column flex-direction h-100 justify-between content"
      >
        <div :class="showSavings ? 'quote-contents' : ''">
          <div class="flex flex-row w-100 items-center justify-between h2 mb4">
            <div class="cera-round-pro-medium f4-ns black tl">
              Your Quote
            </div>
            <div v-if="!disableIntervalToggle">
              <plan-type-toggle
                :disabled="partnerDevices.length > 0"
                :set-value="setMonthly"
                :toggle-value="monthlyToggle"
                custom-class="mt4 mb4"
                first-option="Monthly"
                second-option="Annual"
                toggle-name="monthlyToggle"
              />
            </div>
          </div>
          <summary-price
            :billed-frecuency="monthlyToggle ? 'month' : 'year'"
            :billed-price="commonPrice"
            :deductibles="generalDeductibles"
            :is-monthly="monthlyToggle"
            :is-phone-only="isPhoneOnly"
            :plan-name="name"
            :price-per-month="monthlyToggle ? commonPrice : yearlyPrice"
            :total-deductibles="totalDeductibles"
          />
          <device-info-3c-vue
            v-for="(deviceInfo, index) in phoneModelsWithCount"
            :key="index"
            :device-info="deviceInfo"
            :get-coverage-phone-only="getCoverage"
            :index="index"
            :is-monthly="monthlyToggle"
            :is-phone-only="isPhoneOnly"
            :phone-models="phoneModels"
          />
          <discount-message
            :is-applied="phoneModels.length > 1"
            :is-phone-only="isPhoneOnly"
          />
        </div>
        <div
          :class="showSavings ? 'proceed-annual' : ''"
          class="mt4 center w-100"
        >
          <VTooltip
            :disabled="!disabled"
            placement="bottom"
          >
            <template #popper>
              <span class="inter f7">
                Please complete device information to proceed
              </span>
            </template>
            <div>
              <ak-button
                :new-disabled="disabled"
                :new-primary="!monthlyToggle"
                :new-secondary="monthlyToggle"
                block
                custom-class="button-custom"
                @click.native="submit"
              >
                {{ conversionCaption }}
              </ak-button>
            </div>
          </VTooltip>
        </div>
      </div>
    </div>
    <div
      v-if="showSavings"
      class="f4 bg-theme white tc pv3 flex savings-banner"
    >
      <strong>SAVE ${{ savings.toLocaleString() }}!</strong>
    </div>
    <div class="mt3 silver lh-copy f6 tc w-100 mb4">
      <div>
        Upgrade, downgrade, or cancel anytime • Prorated adjustments & refunds
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import {mapGetters} from 'vuex'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import AkButton from '@/components/Button'
import PlanTypeToggle from '../PlanTypeToggle.vue'
import NoDeviceData from './NoDeviceData.vue'
import SummaryPrice from './SummaryPrice.vue'
import DeviceInfo3cVue from './DeviceInfo3c.vue'
import DiscountMessage from './DiscountMessage.vue'
import {GET_PARTNER_DEVICES} from '../../../../store/registrationv2'

Vue.use(FloatingVue)

export default {
  name: 'NewSummaryCard3c',
  components: {
    AkButton,
    PlanTypeToggle,
    NoDeviceData,
    SummaryPrice,
    DeviceInfo3cVue,
    DiscountMessage
  },
  props: {
    conversionCaption: {
      type: String,
      default: 'Select'
    },
    name: {
      type: String,
      default: 'Basic'
    },
    price: {
      type: Number,
      default: 10
    },
    monthlyToggle: {
      type: Boolean,
      default: true
    },
    disableIntervalToggle: {
      type: Boolean,
      default: true
    },
    filteredPhones: {
      type: Array,
      default: () => []
    },
    selectedCoverages: {
      type: Array,
      default: () => []
    },
    completeData: {
      type: Array,
      default: () => []
    },
    generalDeductibles: {
      type: Number,
      default: 0
    },
    specificDeductibles: {
      type: Object,
      default: () => {}
    },
    setMonthly: Function,
    isPhoneOnly: Boolean
  },
  computed: {
    ...mapGetters({
      partnerDevices: GET_PARTNER_DEVICES
    }),
    phoneModels () {
      return (this.filteredPhones.map(({make, model, price, individualCoverageName}) => (
        {...this.getModelData(make, model), price, individualCoverageName}
      )))
    },
    disabled () {
      return this.completeData.includes(false)
    },
    isSomeComplete () {
      return this.completeData.includes(true)
    },
    commonPrice () {
      return this.price.toFixed(2)
    },
    yearlyPrice () {
      return (this.price / 12).toFixed(2)
    },
    savings () {
      let monthlyPlans = this.$parent.selectedMonthlyCoverages
      let annualPlans = this.$parent.selectedAnnualCoverages
      let savings = 0
      let totalAnnual = 0
      let discount = 0
      for (let i in annualPlans) {
        totalAnnual += annualPlans[i].price
      }
      discount = this.price / totalAnnual
      for (let i in monthlyPlans) {
        savings += monthlyPlans[i].price * 12
      }
      for (let i in annualPlans) {
        savings -= annualPlans[i].price
      }
      if (discount < 1) {
        savings = (savings * discount).toFixed(2)
      }
      return savings
    },
    showSavings () {
      return !this.monthlyToggle && this.savings > 0
    },
    totalDeductibles () {
      if ((this.specificDeductibles.adults.deductible === this.specificDeductibles.students.deductible) ||
      !(this.specificDeductibles.adults.quantity > 0 && this.specificDeductibles.students.quantity > 0)) {
        return [{'message': `Non-phone item deductibles for any claim are $${this.specificDeductibles.adults.deductible}`, 'smallPrint': false}]
      }
      return [{'message': `$${this.specificDeductibles.adults.deductible} Adult Deductible`, 'smallPrint': true}, {'message': `${this.specificDeductibles.students.deductible} Student Deductible`, 'smallPrint': true}]
    },
    phoneModelsWithCount () {
      const devices = {}
      this.phoneModels.forEach(phoneCoverage => {
        const key = `${phoneCoverage.id}-${phoneCoverage.annual_coverage_id}-${phoneCoverage.monthly_coverage_id}-${phoneCoverage.individualCoverageName}`
        if (devices[key]) {
          devices[key].count += 1
        } else {
          devices[key] = {
            count: 1,
            content: {...phoneCoverage}
          }
        }
      })
      const result = []

      Object.keys(devices).forEach((key) =>
        result.push({
          count: devices[key].count,
          ...devices[key].content
        })
      )

      return result
    }
  },
  methods: {
    submit () {
      const coverage = {
        id: this.coverageid,
        name: this.name,
        price: this.price,
        deductible: this.deductible,
        max_coverage: this.claims
      }
      this.$store.commit('SUBMIT_COVERAGE', coverage)
      this.$emit('click')
    },
    getCoverage (idToFind) {
      return this.selectedCoverages.find(({id}) => id === idToFind)
    },
    getModelData (makeToFind, modelToFind) {
      return this.$store.state.allPhoneModels.find(({make, model}) =>
        makeToFind === make && modelToFind === model)
    }
  }
}
</script>

<style scoped>
.price {
  font-size: 3.5em;
}

.button-custom {
  font-size: 16px;
  padding: 15px 0;
  height: 3em;
}

.main-container {
  padding: 2.5em 3.2em;
}

.savings-banner {
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem 0;
  z-index: 2;
  position: absolute;
  flex-direction: column;
}

.outer {
  position: relative
}

.quote-contents {
  z-index: 1;
  margin-top: 1rem;
}

.proceed-annual {
  margin-top: 1rem;
}

.border-annual {
  border: solid;
  border-color: var(--theme-primary);
  border-width: thin;
}

@media (max-width: 1280px) {
  .button-custom {
    font-size: 16px;
    padding: 10px 0;
  }
}

@media (max-width: 650px) {
  .main-container {
    padding: 1.5em 2.2em;
  }

  .button-custom {
    font-size: 14px;
    padding: 15px 0;
  }
}

@media (max-width: 400px) {
  .main-container {
    padding: 20px 15px;
  }

  .responsive-title {
    font-size: 1em;
  }
}
</style>
