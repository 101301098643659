<template>
  <div class="w-100 mt4">
    <div
      v-if="isApplied"
      class="f7 inter flex flex-row items-center common applied tl"
    >
      <red-tick-icon class="mr3" />
      5% {{ isPhoneOnly ? 'Multi-Plan' : 'Family Plan' }} Discount Applied
    </div>
  </div>
</template>

<script>
import RedTickIcon from '../icons/RedTickIcon.vue'

export default {
  name: 'DiscountMessage',
  components: {
    RedTickIcon
  },
  props: {
    isApplied: {
      type: Boolean,
      default: false
    },
    isPhoneOnly: Boolean
  }
}
</script>

<style scoped>
.common {
  width: 100%;
  border-radius: 5px;
  height: 2.4em;
  padding: 1.3em;
}
.applied {
  background-color: var(--theme-light-pink);
  color: var(--theme-primary);
}
</style>
