<template>
  <input
    type="search"
    placeholder="Search"
    :value="searchParam"
    class="ba b--black-20 h-100 w-100"
    @input="$emit('input', $event.target.value)"
  >
</template>

<script>

export default {
  name: 'AkSearch',
  props: {
    searchParam: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
input {
  background: white url("@/assets/appicons/Search.svg") no-repeat 15px center;
  background-size: 15px 15px;
  padding: 10px 45px;
  border-radius: 5px;
  border: 1px solid #E8E8E8;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757D8A;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #757D8A;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #757D8A;
}
</style>
