<template>
  <div class="h-100">
    <div
      :class="{ 'includes-container-open': includeOpen || open, pointer: !open }"
      class="flex includes-container mt2 pv2 pr2 pl4 justify-between items-center"
      @click="(e) => toggleInclude(e)"
    >
      <span class="includes-text cera-med">What's included?</span>
      <img
        v-if="!open"
        :class="{ 'chevron-rotate': includeOpen }"
        alt="Chevron"
        class="chevron"
        src="@/assets/appicons/Chevron.svg"
      >
    </div>
    <div
      :class="{
        'plan-details-container-transition': includeOpen || open,
        'z-9': includeOpen || open,
      }"
      class="flex flex-column items-start justify-between plan-details-container ph4 "
    >
      <div
        :class="{ 'plan-details-container-text-transition': includeOpen || open }"
        class="plan-details-container-text inter"
      >
        <span>
          {{ descriptionText }}
        </span>
        <div class="mt3">
          <div
            v-for="(planInfoString, index) in planInfoStrings"
            :key="index"
            class="ph1 pb1 flex items-center"
          >
            <img
              alt="✓"
              class="pr2"
              height="11"
              src="@/assets/appicons/Check.svg"
            >
            {{ planInfoString }}
          </div>
        </div>
      </div>
      <div class="w-100">
        <hr
          :class="{ 'line-transition': includeOpen || open }"
          class="line"
        >
        <div
          :class="{ 'plan-details-container-text-transition': includeOpen || open }"
          class="flex flex-row plan-details-container-text justify-between mb2 w-100 inter pv1"
        >
          <div class="flex items-center justify-between pl1 flex-column">
            <h2 class="cera-med nowrap mb0 lh-solid">
              {{ planCostRange }}
            </h2>
            <div class="mh2">
              per month
            </div>
          </div>
          <div class="flex items-center pr1 justify-end flex-column">
            <div class="pr1">
              $29-$99
            </div>
            <div>deductibles</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const akkoPlanDescription = 'Protect 1 phone + 25 items per plan. Personal and home electronics and more!'
const akkoPlanInfoStrings = [
  'Protect 1 phone + 25 Items', 'Accidental damage', 'Parts and battery failures',
  'Theft protection', 'Up to $2,000 per claim', 'Unlimited claims'
]
const phoneOnlyDescription = 'Protect 1 phone per plan against damages, breakdowns, and theft'
const phoneOnlyInfoStrings = [
  'Accidental damage', 'Parts and battery failures',
  'Theft protection', 'Unlimited claims'
]
export default {
  name: 'PlanInfo',
  props: {
    type: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    },
    toggleInclude: Function,
    includeOpen: Boolean
  },
  computed: {
    isAKKOPlan () {
      return this.type === 'AKKO'
    },
    planInfoStrings () {
      return this.isAKKOPlan ? akkoPlanInfoStrings : phoneOnlyInfoStrings
    },
    descriptionText () {
      return this.isAKKOPlan ? akkoPlanDescription : phoneOnlyDescription
    },
    planCostRange () {
      return this.isAKKOPlan ? '$17' : '$5-$12'
    }
  }
}
</script>

<style scoped>
.chevron {
  transition: transform 0.4s;
  transform: rotate(0deg);
}

.chevron-rotate {
  transform: rotate(180deg);
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif;
}

.includes-container {
  border-radius: 5px;
  background-color: #eeeeee70;
  transition: border-radius 0.4s ease-in-out;
  z-index: 2;
}

.includes-container-open {
  border-radius: 5px 5px 0 0;
}

.includes-text {
  color: #6c6c6c;
  font-weight: 100;
  font-size: 0.85rem !important;
}

.plan-details-container {
  height: 87%;
  cursor: default;
  border-radius: 0 0 5px 5px;
  max-height: 0;
  background-color: #eeeeee70;
  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.plan-details-container-transition {
  cursor: pointer;
  max-height: 400px;
}

.pointer {
  cursor: pointer;
}

.plan-details-container-text {
  opacity: 0;
  font-size: 0.85rem !important;
  color: #7d7d7d;
  transition: opacity 0.2s ease-in-out;
  margin-top: -0.35rem;
  z-index: -1;
}

.z-9 {
  z-index: 9;
}

.plan-details-container-text-transition {
  opacity: 1;
  z-index: 1;
}

.line {
  width: 100%;
  height: 1px;
  opacity: 0;
  margin: 1rem 0;
  border: 0;
  background-color: #7d7d7d;
  transition: opacity 0.2s ease-in-out;
}

.line-transition {
  opacity: 25%;
}

</style>
