const CLAIM_STATUS_TEXT = Object.freeze({
  DRAFT: 'Draft',
  NEW: 'New',
  PENDING: 'In Review',
  APPROVED: 'Approved',
  REJECTED: 'Denied',
  FRAUD_PRE_FILING: 'Denied',
  FRAUD_POST_FILING: 'Denied',
  DENIED_WAITING_PERIOD: 'Denied',
  DENIED_PHOTO_BLOCK: 'Denied',
  CLOSED_BY_USER: 'Canceled',
  UNCOVERED_LOSS: 'Canceled',
  COVERED_BY_MANUFACTURER_WARRANTY: 'Not Covered',
  NOT_COVERED: 'Not Covered'
})

export default CLAIM_STATUS_TEXT
