<template>
  <main id="intercom-element">
    <div class="flex items-end ">
      <span class="f2 b title-responsive ml4-ns">Contact Us</span>
    </div>

    <div class="mv2">
      <div
        class="mv2 br1 flex justify-between f6"
      >
        <div class="ml4-ns fw2 mid-gray inter">
          <span>
            Please note that our operating hours are Monday-Friday, 7am-5pm Pacific.
          </span>
        </div>
      </div>
    </div>

    <div class="mv4-l mv2" />
    <div class="flex flex-wrap justify-evenly content">
      <div
        class="w-30-l pa4 bg-white account-card mb4-l mb4-m pointer"
        @click="openIntercomChat"
      >
        <div class="responsive-card">
          <img
            alt=""
            class="mr1 mb1"
            src="@/assets/appicons/Contactus.svg"
          >
          <div class="flex flex-column justify-center text-responsive">
            <h4 class="mb2 cera-round-pro-medium fw1">
              Online Chat & FAQs
            </h4>
            <span class="f6 inter">Get quick help</span>
          </div>
        </div>
      </div>
      <div class="w-30-l pa4 bg-white account-card mb4-l mb4-m mb0 pointer">
        <a
          href="mailto:support@getakko.com"
          class="no-underline dib-ns"
        >
          <div class="responsive-card">
            <img
              alt=""
              class="mr1 mb1"
              src="@/assets/appicons/Help.svg"
            >
            <div class="flex flex-column justify-center text-responsive">
              <h4 class="mb2 cera-round-pro-medium fw1">Email</h4>
              <span class="f6 inter">support@getakko.com</span>
            </div>
          </div>
        </a>
      </div>
      <div class="w-30-l pa4 bg-white account-card mb4-l mb4-m mb0 pointer">
        <a
          href="https://akko.link/Text-us"
          class="no-underline dib-ns"
          target="_blank"
          rel="noopener"
        >
          <div class="responsive-card">
            <img
              alt=""
              class="mr1 mb1"
              src="@/assets/appicons/Help.svg"
            >
            <div class="flex flex-column justify-center text-responsive">
              <h4 class="mb2 cera-round-pro-medium fw1">Send a Text</h4>
              <span class="f6 inter">Message us on your own time</span>
            </div>
          </div>
        </a>
      </div>
      <div class="w-30-l empty-card pa4 bg-white mb4-l mb4-m mb0 o1" />
    </div>
  </main>
</template>

<script>
export default {
  name: 'ContactPage',
  beforeRouteLeave (to, from, next) {
    if (!this.isMobile) {
      this.$intercom.shutdown()
      next()
    }
  },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  },
  mounted () {
    if (!this.isMobile) {
      const {email, phone_no, first_name, user_id} = this.$store.state.user
      this.$intercom.boot({
        userId: user_id,
        user_id: user_id,
        name: first_name,
        email: email,
        phone: phone_no
      })
    }
  },
  methods: {
    openIntercomChat () {
      if (!this.isMobile) {
        this.$intercom.show()
      }
    }
  }
}
</script>

<style scoped>
.account-card {
  border-radius: 5px;
  border: 1px solid #E8E8E8;
}

.justify-evenly {
  justify-content: space-evenly;
}

.border {
  border: 1px dashed var(--theme-primary);
  border-radius: 5px;
}

.image-width {
  width: 27px;
}

.red-circle {
  border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 6px;
    background: red;
    border: 0px solid #000;
    color: white;
    text-align: center;
    font-size: 12px;
    position: absolute;
    left: -10px;
    top: -3px;
}



.icon-account {
  margin-top: 2px;
}

.responsive-card {
    position: relative;
  }


  .title-responsive {
    margin-top: 1.5rem;
  }

@media (min-width: 1281px) {
  .text-responsive {
    margin-top: 0.5rem;
  }
}

@media (max-width: 1280px) {
  .account-card {
    width: 45%
  }

  .text-responsive {
    margin-left: 1rem;
  }

  .empty-card {
    width: 45%
  }

  .responsive-card {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .account-card {
    width: 100%;
    border: none;
    border-bottom: 1px solid #E8E8E8;
    border-radius: 0px;
    margin-bottom: 0px;
    padding: 1.9rem 0rem;
  }

  .account-card:nth-child(3) {
    border: none;
  }



  .empty-card {
    display: none;
  }

  .responsive-card {
    display: flex;
    flex-direction: row;
  }

  .title-responsive {
    font-size: 24px;
    margin-bottom: -10px;
    margin-top: 1rem;
  }

  .text-responsive {
    margin-left: 1rem;
  }

  .height {
    height: auto;
  }

  .red-circle {
    left: 0px;
    top: -5px;
}
}


a {
  color: inherit;
}
</style>
