import get from 'lodash/get'
import {AKKO_PLAN_TAGS} from './constants/add-plan/akkoPlanTags'

const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

const newArrayOfObject = (count, obj = {}) => {
  const arr = []
  for (let i = 0; i < count; ++i) {
    arr[i] = deepCopy(obj)
  }
  return arr
}

const removeElementFromArray = (arr, value, key) => {
  const index = arr.findIndex((el) => get([el], `0${key ? '.' : ''}${key}`) === value)
  if (index > -1) {
    arr.splice(index, 1)
  }
}

const getAkkoPlanTag = (planType, planInterval) => {
  const akkoPlanTagKey = `${planType}_${planInterval}`
  return AKKO_PLAN_TAGS[akkoPlanTagKey]
}

const formatPhoneNumber = (phoneNumber) => {
  const formattedNumber = phoneNumber
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)

  return (!formattedNumber[2]
      ? formattedNumber[1]
      : '(' +
      formattedNumber[1] +
      ') ' +
      formattedNumber[2] +
      (formattedNumber[3] ? '-' + formattedNumber[3] : '')
  )
}

const checkEmailFormat = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return (email && !re.test(String(email).toLowerCase()))
}

const checkValidNumber = (number) => {
  const expression = /(?:^|\D)\(([2-9])(?:\d(?!\1)\d|(?!\1)\d\d)\)\s*[2-9]\d{2}-\d{4}/
  return (number && !expression.test(number))
}

const getObjectArrayFromString = (string) => {
  // eslint-disable-next-line no-useless-escape
  return JSON.parse(string.replace(/([{,])(\s*)([A-Za-z0-9_\-]+?)\s*:/g, '$1"$3":').replace('"{"', '{'))
}

const parseJwt = (token) => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export {
  deepCopy,
  newArrayOfObject,
  removeElementFromArray,
  getAkkoPlanTag,
  formatPhoneNumber,
  checkEmailFormat,
  checkValidNumber,
  getObjectArrayFromString,
  parseJwt
}
