<template>
  <div class="flex justify-center h-100 flex-column no-data">
    <div class="cera-round-pro-medium f4 black tl">
      Recommended Plan
    </div>
    <div class="flex justify-center items-center h-100 flex-column inside-data">
      <no-device-icon class="no-device-icon" />
      <div>
        <div class="cera-round-pro-medium f4 black mt3">
          No Device Selected
        </div>
        <div class="inter silver f6 mt3">
          Select A Device to View
        </div>
        <div class="inter silver f6 mt2">
          The Recommended Plan
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoDeviceIcon from '../icons/NoDeviceIcon.vue'

export default {
  name: 'NoDeviceData',
  components: {
    NoDeviceIcon
  }
}
</script>

<style scoped>
.no-data {
  min-width: 8rem;
}
.no-device-icon {
  width: 12em;
  height: 12em;
}
@media (max-width: 1280px) {
  .no-device-icon {
    width: 17em;
    height: 17em;
    margin-right: 3em;
  }
  .inside-data {
    margin-top: 3rem;
    flex-direction: row;
  }
}
@media (max-width: 1000px) {
  .no-device-icon {
    width: 15em;
    height: 15em;
    margin-right: 0;
    margin-bottom: 10px
  }
}
@media (max-width: 700px) {
  .inside-data {
    flex-direction: column;
  }
}
</style>
