<template>
  <main class="main-box">
    <akko-header />
    <div class="circle" />
    <div class="mt-5 container-box">
      <redeem-progress
        :current-step="registration_step"
      />
      <div class="flex form-area flex-column justify-between">
        <activation-code
          v-model="redemption_code"
          :current-step="registration_step"
          :check-redemption-code="checkRedemptionCode"
        />
        <div>
          <div v-show="registration_step === PERSONAL_INFO">
            <personal-form
              title="Personal Information"
              subtitle="Enter your personal information"
              first-label="Cell Phone Number"
              second-label="Email Address"
              auto-focus
              :first-value.sync="phone_no"
              :second-value.sync="email"
              :phone-error="phoneError"
              :email-error="emailError"
              :student-error="studentError"
              :email-unfocus.sync="email_unfocus"
              :max-length="14"
            />
            <div
              v-show="email !== '' && !emailError && !email.endsWith('.edu') && email_unfocus"
              class="mt3"
            >
              <div class="cera-round-pro f5 db mb3">
                Student Verification
              </div>
              <input
                v-model="school"
                class="inter f6 input-reset w-40 ba db mr3 br2 mb4 code-input school"
                placeholder="School Name"
                type="text"
              >
              <label class="f6 inter silver">
                Please upload proof of your student status (school ID, unofficial transcript, receipt of tuition paid, or class schedule)
              </label>
              <div :class="'flex h4 justify-center items-center br2 w-40 tc mb5 mt3 ' + (image_file_name ? 'container-ready' : 'container-empty')">
                <input
                  id="file"
                  class="input-reset dn"
                  type="file"
                  accept="image/*, .pdf,.heic,.heif,.HEIC,.HEIF"
                  @change="processImage($event)"
                >
                <label
                  for="file"
                  class="flex justify-around items-center inter dib fw5 pv2 ph3 pointer ba bw1 f7 white br2"
                  :class="image_file_name ? 'bg-green b-green' : 'bg-theme b-theme'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    class="v-sub mr1"
                    fill="white"
                  >
                    <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                  </svg>
                  <span class="white">{{ image_file_name ? 'Replace File' : 'Upload Transcript/ID' }}</span>
                </label>
              </div>
            </div>
            <personal-form
              title="Payment Information"
              subtitle="Enter your payment related informations"
              first-label="Card Holder First Name"
              second-label="Card Holder Last Name"
              :first-value.sync="first_name"
              :second-value.sync="last_name"
              margin="mt4"
            />
            <div class="mt3 mb5">
              <div class="flex flex-row justify-start mb3">
                <div
                  id="card-number"
                  class="w-40 mr3"
                />
                <div class="flex flex-row w-40">
                  <div
                    id="card-expiry"
                    class="small-input w-50 mr3"
                  />
                  <div
                    id="card-cvc"
                    class="small-input w-50"
                  />
                </div>
              </div>
              <div class="inter f6 color-theme">
                {{ cardErrorMessage }}
              </div>
            </div>
          </div>
          <agreement
            :accept.sync="accept"
            :registration-step="registration_step"
          />
          <div
            v-show="errorList.length > 0"
            class="mb3"
          >
            <span class="inter-medium red mt3 mb2">Error submitting registration:</span>
            <span
              v-for="(e, index) in errorList"
              :key="index"
              class="red"
              v-html="e"
            />
          </div>
        </div>
        <div class="flex justify-end buttons">
          <ak-button
            v-if="registration_step > ACTIVATION_CODE"
            class="w4"
            shadow
            new-secondary
            @click.native="setCurrentStep(registration_step - 1)"
          >
            Previous
          </ak-button>
          <ak-button
            :new-disabled="!enableButton()"
            class="ml3 w4"
            shadow
            new-primary
            @click.native="nextStep"
          >
            {{ registration_step === TERMS_CONDS ? 'Register' : 'Continue' }}
          </ak-button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from 'vue'

import AkButton from '@/components/Button'
import Agreement from '@/components/registration/Agreement'
import AkkoHeader from '../AkkoHeader.vue'
import RedeemProgress from '@/components/registration/progressSideBar/RedeemProgress'
import ActivationCode from '@/components/registration/forms/ActivationCode'
import PersonalForm from './forms/PersonalForm.vue'
import REDEEM_STEPS from '../../constants/registration/redeemSteps'

const {ACTIVATION_CODE, PERSONAL_INFO, TERMS_CONDS} = REDEEM_STEPS

import { formatPhoneNumber, checkEmailFormat } from '@/utils'

import { StripePlugin } from '@vue-stripe/vue-stripe'

const debug = process.env.NODE_ENV !== 'production'

const options = {
  pk: debug ? 'pk_test_jTPxTNZwPNAYq0F6Nwu5mPkc' : 'pk_live_ne4lzjFFUgcbVDlPJwK2ZmKb'
}

Vue.use(StripePlugin, options)

export default {
  name: 'AkRedeem',
  components: {
    AkButton,
    AkkoHeader,
    RedeemProgress,
    ActivationCode,
    PersonalForm,
    Agreement
  },
  beforeRouteLeave (to, from, next) {
    next()
  },
  data () {
    return {
      email: '',
      school: '',
      image_file: null,
      image_file_name: '',
      first_name: '',
      last_name: '',
      phone_no: '',
      accept: false,
      redemption_code: '',
      registration_step: ACTIVATION_CODE,
      stripe: null,
      stripe_token: '',
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      email_unfocus: true,
      cardErrorMessage: '',
      ACTIVATION_CODE: ACTIVATION_CODE,
      PERSONAL_INFO: PERSONAL_INFO,
      TERMS_CONDS: TERMS_CONDS
    }
  },
  computed: {
    stripeElements () {
      return this.$stripe.elements()
    },
    phoneError () {
      return (
        this.registration.phone_error ||
        this.phone_no.trim().length < 14
      )
    },
    emailError () {
      return checkEmailFormat(this.email.trim())
    },
    studentError () {
      return (!this.email.endsWith('.edu') && (!this.image_file || !this.school))
    },
    registration () {
      return this.$store.state.registration
    },
    errorList () {
      return this.$store.state.errors
    },
    codeRemainsEqual () {
      return this.redemption_code.toLowerCase() === this.registration.redemption.code.toLowerCase()
    },
  },
  mounted () {
    this.$store.commit('SET_ERRORS', null)
    this.redemption_code = this.$store.state.registration.redemption.code || this.$route.query.code
    this.$store.dispatch('getAllCoverageOptions')
    this.$store.commit('RESET_PROMO_ALL')
    this.initStripe()
  },
  updated () {
    if (this.registration_step === PERSONAL_INFO) {
      this.setPhoneNumber()
      this.emailError()
    }
  },
  methods: {
    setPhoneNumber () {
      this.phone_no = formatPhoneNumber(this.phone_no)

      this.$store.commit('SET_PHONE', {phone_no: this.phone_no.trim()})
      this.$store.dispatch('checkPhoneExists', {phone: this.phone_no.trim()})
      this.$store.dispatch('generateLead')
    },
    processImage (event) {
      this.image_file = event.target.files[0]
      this.image_file_name = this.image_file.name
    },
    async submit () {
      window.fbq('trackCustom', 'Clicked Student Redeem')

      this.$store.commit('SET_LOADING', true)

      const payload = {
        'email': this.email,
        'phone_no': this.phone_no,
        'stripe_token': this.stripe_token,
        'card_first_name': this.first_name,
        'card_last_name': this.last_name,
        'school': this.school,
        'verification_file': this.image_file,
        'redemption_code': this.redemption_code
      }

      this.$store.dispatch('submitRegistration', payload)
    },
    initStripe () {
      const style = {
        base: {
          '::placeholder': {
            color: '#c8c8c8'
          }
        },
        invalid: {
          color: '#f3103d',
          iconColor: '#f3103d'
        }
      }

      this.cardNumber = this.stripeElements.create('cardNumber', { style })
      this.cardNumber.mount('#card-number')
      this.cardExpiry = this.stripeElements.create('cardExpiry', { style })
      this.cardExpiry.mount('#card-expiry')
      this.cardCvc = this.stripeElements.create('cardCvc', { style })
      this.cardCvc.mount('#card-cvc')
    },
    checkRedemptionCode () {
      const payload = {
        redemption_code: this.redemption_code.toUpperCase()
      }
      this.$store.dispatch('checkRedemptionCode', payload)
    },
    beforeDestroy () {
      this.cardNumber.destroy()
      this.cardExpiry.destroy()
      this.cardCvc.destroy()
    },
    setCurrentStep (newStep) {
      this.$store.commit('SET_ERRORS', null)
      this.registration_step = newStep
    },
    async nextStep () {
      switch (this.registration_step) {
        case ACTIVATION_CODE: {
          this.setCurrentStep(PERSONAL_INFO)
          break
        }
        case PERSONAL_INFO: {
          this.$store.commit('SET_LOADING', true)
          const { token, error } = await this.$stripe.createToken(this.cardNumber)

          if (error) {
            this.cardErrorMessage = error.message
            this.$store.commit('SET_LOADING', false)
          } else {
            this.cardErrorMessage = ''
            this.stripe_token = token.id
            this.setCurrentStep(2)
          }
          this.$store.commit('SET_LOADING', false)
          break
        }
        case TERMS_CONDS: {
          this.submit()
        }
      }
    },
    enableButton () {
      switch (this.registration_step) {
        case ACTIVATION_CODE: {
          return (
            this.registration.redemption.status === 'valid' &&
            this.codeRemainsEqual
          )
        }
        case PERSONAL_INFO: {
          return (
            !this.emailError &&
            !this.phoneError &&
            !this.studentError &&
            this.first_name.trim() &&
            this.last_name.trim()
          )
        }
        case TERMS_CONDS: {
          return this.accept
        }
      }
    }
  }
}
</script>

<style scoped>
  .circle {
    position: fixed;
    background-color: var(--theme-pink);
    width: 600px;
    height: 600px;
    border-radius: 50%;
    right: -300px;
    bottom: -300px;
    z-index: -1
  }
  .main-box {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding-top: 6em
  }
  .container-box {
    background: white;
    box-shadow: 0px 1px 3px 0px rgba(32, 48, 80, 0.16);
    border-radius: 6px;
    width: 93%;
    min-height: 35em;
    justify-self: center;
    padding: 1.5em;
    display: flex;
    flex-direction: row;
    margin-bottom: 6em
  }
  .form-area {
    width: 70%;
    padding: 3em 0em 0em 4em
  }
  .input-group-flex {
    display: flex;
    align-items: stretch
  }
  .text-field-flex {
    flex: 1;
  }
  .submit-btn {
    flex: 1;
    cursor: pointer;
    border: 3px solid #f6103d;
    padding: 4px 12px;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 800;
    background-color: #f6103d
  }
  .buttons {
    height: 2.5em;
  }
  #custom-button {
    height: 30px;
    outline: 1px solid grey;
    background-color: green;
    padding: 5px;
    color: white;
  }
  #card-error {
    color: red;
  }
  .StripeElement {
    background-color: white;
    padding: 10px 12px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0,.2);
  }
  .StripeElement--invalid {
    border-color: var(--theme-primary);
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5;
  }
  .container-ready {
    background-color: var(--theme-light-green);
    border: 1px dashed var(--theme-green);
  }
  .container-empty {
    background-color: var(--theme-pink);
    border: 1px dashed var(--theme-primary);
  }
  .code-input {
    border-color: var(--theme-gray);
    padding: 10px 15px 10px 15px;
  }
  .code-input::placeholder {
    color: var(--theme-gray);
  }
  .code-input:focus {
    border-color: var(--theme-gray);
    outline: none;
  }
  @media screen and (max-width: 1030px) {
    .form-area {
      width: 80%;
      padding: 2em 0em 0em 2em;
    }
  }
  @media screen and (max-width: 850px) {
    .school {
      width: 100%;
    }
    .container-empty {
      width: 100%;
    }
    .container-ready {
      width: 100%;
    }
  }
  @media screen and (max-width: 700px) {
    .container-box {
      flex-direction: column;
    }
    .form-area {
      width: 100%;
      min-height: 27em;
      padding-left: 0;
    }
  }
  @media screen and (max-width: 500px) {
    .container-box {
      padding: 1em;
    }
  }
</style>
