<template>
  <div class="mb4 bg-white shadow-theme br-all br20 pv3 flex-1">
    <div class="container pointer flex flex-column w-100-mobile br3 pa3 mh3-l h-100">
      <div class="flex flex-row justify-between items-center plan-title">
        <div class="flex flex-row items-center flex-grow-1 w-100-mobile">
          <home-plus-icon
            alt="Home Plus Plan"
            height="60"
            width="60"
          />
          <div
            class="flex flex-column flex-grow-1 mr1"
            style="margin-left: 0.75rem"
          >
            <h5
              class="ma0 cera-med"
              style="font-size: 1.1rem"
            >
              {{ title }}
            </h5>
            <h6
              class="m-0 cera-med grey"
              style="font-size: 0.85rem"
            >
              {{ subtitle }}
            </h6>
          </div>
        </div>
      </div>
      <upgrade-plan-info
        :include-open="includeOpen"
        :toggle-include="toggleInclude"
        :plan-data="planData"
        :description="description"
        :plan-cost-difference="planCostDifference"
        :plan-deductible-range="planDeductibleRange"
      />
    </div>
  </div>
</template>
<script>
import HomePlusIcon from '../icons/HomePlusIcon'
import UpgradePlanInfo from './UpgradePlanInfo'

export default {
  name: 'AkUpgradePlanCard',
  components: {
    HomePlusIcon,
    UpgradePlanInfo
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    planData: {
      type: Array,
      default: () => []
    },
    planCostDifference: {
      type: String,
      default: ''
    },
    planDeductibleRange: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      includeOpen: true
    }
  },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  },
  methods: {
    toggleInclude () {
      this.includeOpen = !this.includeOpen
    }
  }
}
</script>

<style scoped>
.card-margin {
  margin: auto;
}

.grey {
  color: #9ba1ab;
}
</style>
