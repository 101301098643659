<template>
  <main>
    <Notifications
      :user-has-access="userHasAccess"
      :user="user"
    />
    <Alerts
      :devices-loaded="devicesLoaded"
      :devices="devices"
      :user="user"
    />
    <Items
      :claims-subheader="claimsSubheader"
      :items-subheader="itemsSubheader"
      :claims="claims"
      :user="user"
      :devices="devices"
    />
  </main>
</template>

<script>
import Items from '@/components/Dashboard/items'
import Notifications from '@/components/Dashboard/notifications'
import Alerts from '@/components/Dashboard/alerts'

export default {
  name: 'AKDashboard',
  components: {
    Items,
    Notifications,
    Alerts
  },
  data () {
    return {
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    devices () {
      return this.$store.state.devices
    },
    devicesLoaded () {
      return this.$store.state.devicesLoaded
    },
    claims () {
      return this.$store.state.claims.claims
    },
    userHasAccess () {
      const status = this.user.membership_status
      return status !== 'BANNED' && status !== 'CANCELED' && status !== 'UNPAID' && status !== 'PAST_DUE' && status !== 'INACTIVE' && status !== 'INVALID_USER'
    },
    hasAddress () {
      return this.user.street &&
        this.user.city &&
        this.user.state &&
        this.user.zip
    },
    hasSchoolInfo () {
      return this.user.school &&
        this.user.year
    },
    hasPhone () {
      return Boolean(this.user.phone)
    },
    itemsSubheader () {
      if (this.devices.length === 0) {
        return 'No items added to your plan yet'
      }
      if (this.devices.length === 1) {
        return '1 item added to your plan'
      }
      return `${this.devices.length} items added to your plan`
    },
    claimsSubheader () {
      if (!this.claims.Open || !this.claims.Open.length) {
        return 'No currently open claims'
      }
      if (this.claims.Open.length === 1) {
        return '1 claim currently open'
      }
      return `${this.claims.Open.length} claims currently open`;
    },
  },
  destroyed () {
    this.$store.dispatch('resetJustActivated')
  },
  mounted () {
    this.$store.dispatch('getDevices')
  },
  methods: {
    resendEmail () {
      this.$store.dispatch('requestVerificationEmail')
    }
  }
}
</script>

<style scoped>
</style>
