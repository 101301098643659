<template>
  <main class="flex flex-column justify-center self-center center-ns mb4 main-container">
    <akko-header class="header-responsive" />
    <div class="circle" />
    <div>
      <div class="flex responsive justify-center">
        <div class="flex flex-column w-65 responsive-choose-plan items-center">
          <select-upsell
            :finish-upsell="finishUpsell"
            :product-price="productPrice"
            class="select-upsell"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import router from '@/router'
import {isEqual} from 'lodash'
import {mapActions, mapState} from 'vuex'
import AkkoHeader from '../../AkkoHeader.vue'
import SelectUpsell from './SelectUpsell.vue'
import {REQUEST_UPSELL} from '../../../store/registrationv2'
import {SET_TOKEN} from '../../../store/mutations'
import {trackOnMixpanel, MIXPANEL_EVENTS} from '@/services/tracking'

export default {
  name: 'UnifiedUpsell',
  components: {
    AkkoHeader,
    SelectUpsell,
  },
  data () {
    return {
      isFirstStep: true
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user_id,
      productUpsell: state => state.registrationv2.productUpsell,
      productPrice: state => state.registrationv2.productPrice,
      contract: state => state.registrationv2.contract
    })
  },
  async mounted () {
    await this.initPlanType()
    await this.$store.dispatch('postContract', { force_new: false })
    await this.$store.dispatch('requestUpsellProduct')
    await this.$store.dispatch('getProductPrices', {
      productId: this.productUpsell.id,
      params: {
        is_default: true,
        interval: this.$store.state.registrationv2.interval,
        currency: 'USD'
      }
    })
    trackOnMixpanel(MIXPANEL_EVENTS.UPSELL_PAGE_VIEW, {
      'Product Price ID': this.productPrice.id,
      'Current Price': this.productPrice.current_price,
      'Retail Price': this.productPrice.retail_price,
      'Plan Interval': this.productPrice.interval
    })
  },
  methods: {
    ...mapActions({
      submitUpsell: REQUEST_UPSELL
    }),
    finishUpsell (optIn) {
      trackOnMixpanel(MIXPANEL_EVENTS.UPSELL_SUBMIT, {
        'Opt In': optIn,
        'Product Price ID': this.productPrice.id,
        'Current Price': this.productPrice.current_price,
        'Retail Price': this.productPrice.retail_price,
        'Plan Interval': this.productPrice.interval
      })
      if (optIn) {
        const payload = {
          user_id: this.userId,
          contract_id: this.contract.id,
          data: {
            product_price_id: this.productPrice.id,
            on_contract: true
          }
        }
        this.submitUpsell(payload)
      } else {
        router.push('/registration/complete')
      }
    },
    async initPlanType () {
      const currentQuery = this.$router.history.current.query

      let defaultQuery = {
        ref: this.$route.query.ref,
        lead_id: this.$route.query.lead_id
      }
      if (this.isFirstStep) {
        defaultQuery.step1 = 'true'
      }
      let authenticationInformation = {
        access_token: this.$route.query.at || '',
        id: this.$route.query.uid || ''
      }
      if (authenticationInformation.access_token) {
        this.$cookies.set('userActive', authenticationInformation.access_token)
        this.$store.commit(SET_TOKEN, authenticationInformation)
      }
      if (!isEqual(currentQuery, defaultQuery)) {
        this.$router.push({
          query: {
            ...defaultQuery
          }
        })
      }
    }
  },
}

</script>

<style scoped>

.circle {
  position: fixed;
  background-color: var(--theme-pink);
  width: 700px;
  height: 700px;
  border-radius: 50%;
  left: -300px;
  top: -350px;
  z-index: -1
}

.main-container {
  width: 93%;
}

.w-65 {
  width: 994px;
}

.responsive {
  display: flex;
  flex-direction: row;
  margin-top: 6em;
}

@media (max-width: 1280px) {
  .responsive-choose-plan {
    width: 100%;
    align-items: center;
  }

  .responsive {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .main-container {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .main-container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .responsive {
    margin-top: 5em;
    width: 100%;
  }
}

@media (max-width: 479px) {
  .select-upsell {
    width: 100%;
  }

  .main-container {
    display: contents;
  }

  .header-responsive {
    background-color: white;
  }

  .justify-center {
    left: -1rem;
    width: 100vw;
    position: relative;
    margin-top: 6em;
  }
}

</style>
