<template>
  <div class="flex flex-column">
    <input
      class="inter f6 input-reset ba db mr3 br2 code-input"
      :placeholder="placeholder"
      :value="inputValue"
      @input="onChange"
    >
    <div
      class="color-theme f6 mt2 mr3 w-40 mb2 w-100"
      :class="{invisible: !isError || !inputValue}"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AkkoInput',
  props: {
    placeholder: String,
    inputValue: String,
    errorMessage: String,
    setValue: Function,
    isError: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange (event) {
      this.setValue(event.target.value)
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 20px;
  }
  .code-input {
    border-color: var(--theme-gray);
    padding: 10px 15px 10px 15px;
  }
  .code-input::placeholder {
    color: var(--theme-gray);
  }
  .code-input:focus {
    border-color: var(--theme-gray);
    outline: none;
  }
  .invisible {
    visibility: hidden;
  }
  @media (max-width: 450px) {
    .responsive {
      width: 100%;
      flex-direction: column;
    }
    .code-input {
      margin-right: 0;
    }
  }
</style>

