<template>
  <div class="db centered-container-ns inter lh-copy">
    <akko-header />
    <div
      id="complete-card"
      class="bg-white shadow-theme-ns ph3 ph4-ns mt6 mt0-ns pv5-ns br1-ns tc"
    >
      <div class="mv4">
        Great! You've successfully registered your AKKO Plan and will now proceed to add the items you want protected.
      </div>

      <div
        v-if="!user.is_verified"
        class="mv4"
      >
        Please don't forget to verify your phone number by clicking the link in the SMS sent to you from "AKKO".
      </div>

      <!-- <div class="mv4 theme-color">
        {{ user.email }}
      </div> -->

      <div class="mv4">
        Now click the button below to continue to your account dashboard.
      </div>

      <div class="mv4">
        From there, set your password by clicking the "update password" notice and then continue to the "items" section to add your items to your plan!
      </div>

      <div class="mv4 mh3 center-ns">
        <router-link
          to="/"
          class="no-underline"
        >
          <ak-button
            primary
            block
          >
            Continue
          </ak-button>
        </router-link>
      </div>

      <div class="mv4">
        To request modifications to your plan or upgrade, go to your "account settings" and click "change plan".
      </div>

      <div class="mt4 mb3 tc">
        <div class="mb3">
          If you need help or have any questions, just contact us!
        </div>
        <a
          href="mailto:support@getakko.com"
          class="no-underline db mv1"
        >
          Email
        </a>
        <!-- <a href="tel:+14156336248" class="no-underline db mv1">
          Phone
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>

import AkButton from '../Button'
import AkkoHeader from '../AkkoHeader.vue'

export default {
  name: 'AkActivateAccount',
  components: {
    AkButton,
    AkkoHeader
  },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },
    coverageName () {
      const coverage = this.$store.state.activation.coverage
      return coverage.max_coverage === 2000
        ? 'AKKO College Base Plan'
        : `AKKO College $${coverage.max_coverage / 1000}K Plan`
    },
    coverageAmount () {
      const coverage = this.$store.state.activation.coverage
      return coverage.max_coverage
    },
    user () {
      return this.$store.state.user
    }
  },
  mounted () {
    // TODO: fix protected routes
    // this.submit()
    this.$store.dispatch('getUserInformation')
  },
  methods: {
    submit () {
      this.$store.commit('SET_COMPLETE', true)
      this.$store.dispatch('submitActivation', this.$store.state.user.user_id)
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 30em) {
.centered-container-ns {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
}

#complete-card {
  flex-basis: 38rem;
}
</style>
