<template>
  <header class=" w-100 h4 left-0 top z-5 flex justify-between items-center main pos-absolute">
    <router-link to="/">
      <div
        v-if="logoSrc"
        class="white dib mt3"
      >
        <img
          alt=""
          class="h2"
          :src="logoSrc"
          style="padding-top:.2rem; padding-bottom:0; margin-bottom: -0.2rem;"
        >
      </div>
      <div v-else>
        <akko-logo
          v-if="!isGenius"
          class="logo"
        />
        <GenuisCPRLogo v-if="isGenius" />
      </div>
    </router-link>
    <div
      v-if="showHelp"
      class="flex flex-row justify-between items-center right-button"
    >
      <legend
        v-if="!isMobile"
        class=".inter need-help"
      >
        Need Help?
      </legend>
      <ak-button
        custom-class="bg-cream"
        new-secondary
        @click.native="openIntercomChat"
      >
        Contact Us
      </ak-button>
    </div>
  </header>
</template>

<script>
import AkButton from '@/components/Button'
import AkkoLogo from '@/components/icons/AkkoLogo.vue'
import GenuisCPRLogo from './icons/GeniusCPRLogo'
import {mapGetters} from 'vuex'
import {GET_PARTNER_DEVICES, GET_PARTNER_STRATEGY_NAME, GET_PARTNER_UI_SETTINGS} from '../store/registrationv2'

export default {
  name: 'AkkoHeader',
  components: {
    GenuisCPRLogo,
    AkButton,
    AkkoLogo
  },
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapGetters({
      partnerDevices: GET_PARTNER_DEVICES,
      uiSettings: GET_PARTNER_UI_SETTINGS,
      partnerStrategyName: GET_PARTNER_STRATEGY_NAME
    }),
    isMobile () {
      return this.windowWidth <= 500
    },
    isGenius () {
      return this.uiSettings.custom_logo && this.partnerStrategyName === 'GENIUS_CPR'
    },
    logoSrc () {
      const logoSrc = process.env.VUE_APP_WHITE_LABEL_BRAND_IMAGE_URL
      return logoSrc || require('@/assets/menu-icons/logo.svg')
    },
    showHelp () {
      return !(this.$route.name.split(' ')[0] === 'Registration') || this.$route.path.includes('redeem')
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    if (!this.isMobile) {
      this.$intercom.boot({})
    }
  },
  methods: {
    openIntercomChat () {
      if (!this.isMobile) {
        this.$intercom.show()
      }
    },
    beforeRouteLeave (to, from, next) {
      if (!this.isMobile) {
        this.$intercom.shutdown()
        next()
      }
    }
  }
}
</script>

<style scoped>
.main {
  padding: 0 6% 0 5%;
}

.top {
  top: .25rem;
}

.logo {
  height: 12em;
  width: 12em
}

.need-help {
  font-size: 14px;
  margin-right: 1.3rem;
}

.pos-absolute {
  position: absolute;
}

@media screen and (max-width: 500px) {
  .right-button {
    justify-content: flex-end;
  }

  .logo {
    height: 8em;
    width: 8em
  }

  .need-help {
    font-size: 10px;
  }
}

@media screen and (max-width: 300px) {
  .logo {
    height: 5em;
    width: 5em
  }
}
</style>
