<template>
  <div class="mt3 mb2 flex flex-column">
    <div class="congratulation-box flex flex-row pv3 ph3 mb4">
      <medal
        height="30px"
        width="30px"
      />
      <div class="ml3 flex flex-column justify-center">
        <div class="inte color-gray f6">
          By registering a bank account, you will receive $20 in deductible credit toward future claims!
        </div>
      </div>
    </div>
    <div class="flex flex-column w-50 responsive">
      <input
        v-model="postalCode"
        class="inter f6 input-reset ba db br2 mr3 code-input responsive-last"
        placeholder="Postal Code"
      >
    </div>
    <ak-button
      v-if="!isDataFilled"
      custom-class="self-start"
      new-primary
      @click.native="createACH"
    >
      Connect Your Bank
    </ak-button>
    <div
      v-else
      class="flex flex-column"
    >
      <akko-input
        :input-value="email"
        :is-error="emailError"
        :set-value="setEmail"
        class="email-input"
        error-message="Please enter a valid email"
        placeholder="Email Address"
      />
    </div>
    <div
      v-for="(bankAccountData, index) in bankAccountData.accounts"
      :key="index"
      class="flex flex-row justify-between ttc mt3 responsive"
    >
      <div class="flex flex-column justify-center">
        <div class="inter color-gray f7 mt2 selected">
          SELECTED ACCOUNT
        </div>
        <div class="flex flex-row justify-center mt2 responsive-info">
          <div class="inter-medium color-gray f6">
            {{ institutionName }} - {{ bankAccountData.type }}
          </div>
          <div class="inter color-gray f6 responsive-name">
            <label class="separator">&nbsp;-</label>
            {{ bankAccountData.name }} ({{ bankAccountData.mask }})
          </div>
        </div>
      </div>
      <ak-button
        v-if="isDataFilled"
        custom-class="responsive-button fw7 f-btn"
        new-secondary
        @click.native="createACH"
      >
        Update Bank Details
      </ak-button>
    </div>
    <div
      v-if="bankAccountError"
      class="balance-error-box flex flex-row pv2 ph2 mt3"
    >
      <div class="ml1 mr1 flex flex-column justify-center">
        <div class="inter color-theme f6">
          {{ bankAccountError }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  CHECK_BANK_ACCOUNT_BALANCE,
  GET_BANKACCOUNT_ERROR,
  GET_PAYMENT_DATA,
  GET_PLAID_DATA,
  REQUEST_PLAID_KEYS,
  SET_PAYMENT_DATA
} from '@/store/registrationv2'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import Medal from '../icons/MedalIcon.vue'
import AkButton from '@/components/Button'
import AkkoInput from '@/components/registration/forms/AkkoInput.vue'
import PAYMENT_METHODS from '@/constants/registration/paymentMethods'

export default {
  name: 'BankAccount',
  components: {
    AkButton,
    Medal,
    AkkoInput
  },
  props: {
    isDataFilled: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    },
    emailError: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      plaid: window.Plaid,
      postalCode: '',
      loading: false,
      internalPaymentData: null
    }
  },
  computed: {
    ...mapGetters({
      plaidData: GET_PLAID_DATA,
      paymentData: GET_PAYMENT_DATA,
      bankAccountError: GET_BANKACCOUNT_ERROR
    }),
    bankAccountData () {
      if (!this.isDataFilled) {
        return {
          accounts: []
        }
      }

      return this.paymentData.accountMetadata
    },
    institutionName () {
      const nameNotNull = !!this.bankAccountData &&
        !!this.bankAccountData.institution &&
        !!this.bankAccountData.institution.name
      return nameNotNull ? this.bankAccountData.institution.name : ''
    }
  },
  watch: {
    'internalPaymentData': {
      handler: function (newVal) {
        this.setPaymentData({ ...newVal, postal_code: this.postalCode })
      },
      deep: true
    },
    'postalCode': {
      handler: function (newVal) {
        if (this.internalPaymentData != null) {
          this.setPaymentData({ ...this.internalPaymentData, postal_code: newVal })
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      requestPlaidKeys: REQUEST_PLAID_KEYS,
      checkBalance: CHECK_BANK_ACCOUNT_BALANCE
    }),
    ...mapMutations({
      setPaymentData: SET_PAYMENT_DATA
    }),
    async createACH () {
      this.loading = true
      await this.requestPlaidKeys()
      const handler = this.plaid.create({
        token: this.plaidData.plaid_link_token,
        onSuccess: (publicToken, metadata) => {
          this.internalPaymentData = {
            'public_token': publicToken,
            'account_id': metadata.account_id,
            'plaid_client_id': this.plaidData.plaid_client_id,
            accountMetadata: metadata,
            method: PAYMENT_METHODS.ACH,
            'stripe_token': undefined,
            'card_first_name': undefined,
            'card_last_name': undefined
          }
          this.checkBalance({
            'public_token': publicToken,
            'account_id': metadata.account_id
          })
        }
      })
      handler.open()
      this.loading = false
    },
    setEmail (value) {
      this.$emit('update:email', value)
    }
  }
}
</script>
<style scoped>
.responsive-last {
  margin-right: 0;
  margin-bottom: 1em;
}
.code-input {
  border-color: var(--theme-gray);
  padding: 10px 15px 10px 15px;
}

.f-btn {
  font-size: .875rem
}

.congratulation-box {
  min-height: 4em;
  width: 100%;
  border: solid 1px var(--theme-medium-gray);
  background-color: var(--theme-light-gray);
}

.balance-error-box {
  min-height: 4em;
  width: 100%;
  border: solid 1px var(--theme-pink);
  background-color: var(--theme-pink);
}

.selected {
  font-size: 10px;
}

.email-input {
  width: 50%;
}

@media (max-width: 750px) {
  .responsive {
    flex-direction: column;
    align-items: start;
  }

  .responsive-button {
    margin-top: 10px;
  }

  .email-input {
    width: 80%;
  }
}

@media (max-width: 400px) {
  .responsive-info {
    flex-direction: column;
    align-items: start;
  }

  .separator {
    display: none;
  }

  .responsive-name {
    margin-top: 5px;
  }

  .responsive-button {
    margin-top: 15px;
  }

  .email-input {
    width: 100%;
  }
}
</style>
