<template>
  <div class="fixed br-pill z-999 fab-pos">
    <button
      class="bg-theme white h3 w3 br-pill flex items-center justify-center pointer tc b--none"
      @click="open = !open"
    >
      <slot>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line
            x1="12"
            y1="5"
            x2="12"
            y2="19"
          />
          <line
            x1="5"
            y1="12"
            x2="19"
            y2="12"
          />
        </svg>
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'AkFab',
  data () {
    return {
      open: false
    }
  }
}
</script>

<style scoped>
.fab-pos {
  bottom: 5.5em;
  right: 1em;
}
@media screen and (min-width: 80em) {
  .fab-pos {
    bottom: 2em;
    right: 2em;
  }
}
.br-pill {
  border-radius: 9999px;
}
</style>
