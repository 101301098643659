<template>
  <main class="mt3 responsive-margin">
    <div class="mt3 flex flex-column inter responsive-none">
      <div class="flex f5 top-container-realign items-center mb2 flex-row">
        <div class="pl4 silver">
          <router-link
            class="no-underline account-hover soft-grey"
            to="/account/dashboard"
          >
            {{ $t('menu.accountsettings', { accountsettings: 'Account Settings' }) }}
          </router-link>
        </div>
        <div class="soft-grey">
          &nbsp;  /  &nbsp;
        </div>
        <div class="soft-grey">
          {{ $t('menu.loginsecurity', { loginsecurity: 'Login & Security' }) }}
        </div>
      </div>
      <h2 class="pl4 f2 mb3 title-realign cera-round-pro">
        {{ $t('menu.loginsecurity', { loginsecurity: 'Login & Security' }) }}
      </h2>
    </div>
    <div class="flex flex-row">
      <div class="width responsive">
        <LoginAndSecurity />
      </div>
      <router-view />
      <div
        class="w-40 responsive-none"
        style="visibility: hidden"
      >
        <RightComponent
          image="security-secure.png"
          name="Your privacy will always be our priority"
        />
      </div>
    </div>
  </main>
</template>

<script>

import LoginAndSecurity from './LoginAndSecurity.vue'
import RightComponent from '../RightComponent.vue'

export default {
  name: 'UnifiedSecurity',
  components: {
    LoginAndSecurity,
    RightComponent
  },
  props: {
    screen: String
  },
  computed: {},
  mounted () {
  },
  beforeDestroy () {
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  },
  methods: {}
}
</script>

<style scoped>

.width {
  width: 100%;
}

.title-realign {
  margin-left: -3px
}

.top-container-realign {
  margin-top: 1px;
}


.soft-grey {
  color: #9BA1AB;
}

.chevron {
  color: #9BA1AB;
  height: 17px;
  width: 17px;
}

.account-hover {
  cursor: pointer;
}

.account-hover:hover {
  color: #7A7A7C;
  text-decoration: underline;
}


@media (max-width: 1280px) {

  .responsive-margin {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .responsive-none {
    display: none;
  }

  .responsive {
    width: 100%;
  }

  .responsive-margin {
    margin-top: 0;
  }
}

</style>
