<template>
  <div class="flex flex-column items-center justify-center w-100">
    <div
      v-if="index > 0"
      class="separation-line mt3 w-100"
    />
    <div class="mt3 black flex flex-row w-100">
      <div class="inter f6-ns gray-c mr2">
        #{{ index + 1 }}
      </div>
      <div class="flex flex-column w-100">
        <div class="flex flex-row flex-nowrap model-price justify-between cera-round-pro-medium items-baseline tl f6-ns w-100 text-line">
          <div class="model">
            {{ modelCount }} {{ deviceInfo.make }} {{ deviceInfo.model }}
          </div>
          <div class="flex flex-wrap tr f6 price">
            ${{ deviceInfo.price.toFixed(2) }} / {{ isMonthly ? 'month' : 'year' }} {{ each }}
          </div>
        </div>

        <div
          v-if="isCartInfo"
          class="mt1 flex flex-column justify-end items-start"
        >
          <div class="inter color-theme f7 mb1">
            {{ deviceInfo.individualCoverageName }}
          </div>
          <div
            v-if="!(isPhoneOnly || deviceInfo.adult)"
            class="inter gray-c f7 mb1"
          >
            Student-Plan Discount Applied
          </div>
        </div>

        <div
          v-else
          class="mt3 flex flex-column items-start justify-start"
        >
          <div class="inter gray-c f7 mr2 mb1">
            DEDUCTIBLES
          </div>
          <div class="lh-copy gray-c fs7">
            ${{ deviceInfo.screen_replacement_deductible }} for Screen Replacement
          </div>
          <div class="lh-copy gray-c fs7">
            ${{ deviceInfo.repair_deductible }} for Other Repairs
          </div>
          <div class="lh-copy gray-c fs7">
            ${{ deviceInfo.replacement_deductible }} for Replacement or Theft
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeviceInfo3c',
  props: {
    deviceInfo: {
      type: Object,
      default: () => {}
    },
    isMonthly: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    isPhoneOnly: {
      type: Boolean,
      default: true
    },
    isCartInfo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modelCount () {
      return (this.deviceInfo.count && this.deviceInfo.count > 1 ? `${this.deviceInfo.count} x` : '')
    },
    each () {
      return (this.deviceInfo.count && this.deviceInfo.count > 1 ? `each` : '')
    }
  }
}
</script>

<style scoped>
.extra-savings {
  padding-top: 0.5em;
  font-size: 0.8em;
}

.gray-c {
  color: var(--theme-gray-text);
}
.v-sub {
  vertical-align: sub;
}
.br-all {
  border-radius: 20px;
}
.savings-banner {
  border-radius: 20px 20px 0 0;
  margin: 0 -1px;
}
.bs-custom {
  box-shadow: 0 5px 30px 0px rgba(0,0,0,0.2);
}
.separation-line {
  border: 1px solid var(--theme-medium-gray);
}
@media (max-width: 600px) {
  .model {
    overflow:hidden;
    white-space:nowrap;
    -ms-text-overflow:ellipsis;
    text-overflow:ellipsis;
    max-width:75%;
    font-size: 1em;
  }
  .price {
    font-size: 0.8em;
  }
  .text-line {
    margin-top: 1px;
  }
  .deductibles {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media (max-width: 600px) {
  .model {
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 75%;
    font-size: 1em;
  }
  .price {
    font-size: 0.8em;
  }
  .text-line {
    margin-top: 1px;
  }
  .deductibles {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media (max-width: 400px) {
  .model-price {
    flex-direction: column;
  }
  .model {
    max-width: 90%;
  }
  .price {
    margin-top: 4px;
  }
}
</style>
