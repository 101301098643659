// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import VueGtag from "vue-gtag";
import VueGtm from '@gtm-support/vue2-gtm';
import VueCookies from 'vue-cookies'
import VueIntercom from 'vue-intercom'
import VueSocialSharing from 'vue-social-sharing'
import VueClipboard from 'vue-clipboard2'


import App from './App'
import router from './router'
import store from './store'
import i18n from './i18n'
import { initTracking } from './services/posthog'
import { initSentry } from './services/sentry'

// Initializing external services
initTracking();
initSentry(Vue, router, process.env.NODE_ENV);

Vue.config.productionTip = false

Vue.use(VeeValidate)
Vue.use(VueCookies)
Vue.use(VueSocialSharing)
Vue.use(VueClipboard)
Vue.use(VueIntercom, { appId: 'dnvys30i' })

const prod = process.env.NODE_ENV === 'production'
if (prod) {
  Vue.use(VueGtag, {
    config: {
      id: 'UA-135624033-1',
    },
  }, router);

  Vue.use(VueGtm, {
    id: 'GTM-PKJRGW5',
    enabled: prod,
    debug: false,
    vueRouter: router
  })
}

// Register a global custom directive called v-focus
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el, binding) {
    // Focus the element
    if (binding.value) {
      el.focus()
    } else if (binding.value === undefined) {
      el.focus()
    }
  }
})

Vue.mixin({
  methods: {
    isValidPhone: phoneNumber => {
      const PATTERN = /^1?-?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
      return PATTERN.test(phoneNumber)
    }
  }
})

/* eslint-disable no-new */
// app =
new Vue({
  el: '#app',
  components: { App },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  },
  created () {
    if (this.$route.path !== '/support-chat' && this.isMobile) {
      this.$intercom.shutdown()
    }
  },
  mounted () {
    prod && window.fbq('init', '922477498176677')
    if (this.$store.state.token && this.$store.state.user_id) {
      this.$store.dispatch('getUserInformation')
    }
  },
  template: '<App/>',
  router,
  i18n,
  store
})
