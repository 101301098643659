export const PARTNER = 'PARTNER'
export const CURRENCY = 'USD'
export const LANGUAGE = 'en-US'
export const BROWSER = 'IEMobile'
export const VISA = 'VISA'
export const MASTERCARD = 'MASTERCARD'
export const VISA_CAPITAL = 'Visa'
export const MASTERCARD_CAPITAL = 'Mastercard'
export const DATE_MONTH = 'long'
export const DATE_DAY = 'numeric'
export const DATE_YEAR = 'numeric'
export const ZONE = 'UTC'
export const LOCALITY = 'locality'
export const POSTAL_CODE = 'postal_code'
export const ADMINISTRATIVE_AREA = 'administrative_area_level_1'
export const LAPTOP_TABLET = 'LAPTOP_TABLET'
export const AKKO = 'AKKO'
export const ERROR_PHONE_NUMBER = 'Error on updating phone number'
export const ERROR_CODE = 'Error on verifying code'
