<template>
  <div class="flex flex-row flex items-start justify-between-l justify-around flex-mobile-col w-100 lh-copy">
    <plan-toggle-card
      :on-click="setAkkoPlan"
      :selected="!isPhoneOnly"
      type="AKKO"
      :include-open="includeOpen"
      :toggle-include="toggleInclude"
    />
    <plan-toggle-card
      :on-click="setPhonePlan"
      :selected="isPhoneOnly"
      type="PHONE"
      :include-open="includeOpen"
      :toggle-include="toggleInclude"
    />
  </div>
</template>

<script>
import PlanToggleCard from './selectBox/PlanToggleCard'

export default {
  name: 'PlanSelectToggleC3',
  components: {PlanToggleCard},
  props: {
    isPhoneOnly: {
      type: Boolean,
      default: false
    },
    setValue: Function,
    setAkkoPlan: Function,
    setPhonePlan: Function
  },
  data () {
    return {
      includeOpen: false
    }
  },
  mounted () {
    let includeOpenQueryValue = this.$route.query.openPlans
    if (includeOpenQueryValue !== undefined) {
      this.includeOpen = includeOpenQueryValue === 'true'
    }
    this.setQueryParams(this.includeOpen)
  },
  methods: {
    toggleInclude (e) {
      this.includeOpen = !this.includeOpen
      this.setQueryParams(this.includeOpen)
      e.stopPropagation()
    },
    setQueryParams (value) {
      this.$router.push({
        query: {
          ...this.$route.query,
          openPlans: value
        }
      })
    }
  }
}
</script>

<style scoped>
@media (max-width: 800px) {
  .flex-mobile-col {
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
