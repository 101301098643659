<template>
  <main class="inter">
    <div
      class="flex flex-row items-center responsive-title pl2 pr2 pb2"
      @click="goBack"
    >
      <arrow-back1 />
      <h3 class="ml2 f4 cera-round-pro">
        {{ $t('referralssettings.referralsettingsheader', { referralsettingsheader: 'Referrals' }) }}
      </h3>
    </div>
    <div
      v-if="!showEditReferralCodeModal"
      class="mv2 ph3 flex flex-column justify-center items-start height-dv"
    >
      <div class="flex flex-column">
        <div class="mt1 elementLeft">
          {{ $t('referralssettings.mycode', { mycode: 'My Code' }) }}
        </div>
      </div>
      <div class="flex flex-row justify-between w-100">
        <span class="data fw5">{{ user.referral_code }}</span>
        <span
          class="color-theme data pointer"
          @click="showEditReferralCodeModal = true"
        >{{ $t('edit.edit', { edit: 'Edit' }) }}</span>
      </div>
    </div>

    <!--Edit Code Desktop-->
    <div
      v-if="showEditReferralCodeModal"
      class="row-edit mv2 desktop modal-opened-row"
    >
      <div class="elementLeft modal-opened-row-text">
        {{ $t('referralssettings.editreferral', { editreferral: 'Edit your referral code' }) }}
      </div>
      <div class="modal w-75">
        <form
          slot="modal-body"
          class="flex-column"
        >
          <div class="mt3 mb3 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="pass"
            >{{ $t('referralssettings.newcode', { newcode: 'NEW REFERRAL CODE' }) }}</label>
            <input
              id="pass"
              v-model="referralCode"
              v-focus
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
            >
          </div>
        </form>
        <div
          slot="modal-footer"
          class="pa3 flex flex-row justify-between justify-end-ns"
        >
          <div class="mr3 flex-grow flex-grow-off-ns">
            <ak-button
              block
              class="modal-button inter fw6 cancel"
              secondary
              @click.native="showEditReferralCodeModal = false"
            >
              {{ $t('edit.cancel', { cancel: 'Cancel' }) }}
            </ak-button>
          </div>
          <div class="flex-grow flex-grow-off-ns">
            <ak-button
              block
              class="modal-button inter fw6 save"
              primary
              @click.native="updateReferralCode"
            >
              {{ $t('edit.save', { save: 'Save' }) }}
            </ak-button>
          </div>
        </div>
      </div>
    </div>
    <!--Edit Code Mobile-->
    <div
      v-if="showEditReferralCodeModal"
      class="modal flex-row mobile w-75"
    >
      <form class="flex-column mh3 mt4">
        <div class="mb3">
          <span>{{ $t('referralssettings.editreferral2', { editreferral2: 'Edit your referral code' }) }}</span>
        </div>
        <div class="mt3 mb2 flex-grow mh3-ns">
          <label
            class="f6 b db mb2 label"
            for="mobile-code"
          >{{ $t('referralssettings.newcode', { newcode: 'NEW REFERRAL CODE' }) }}</label>
          <input
            id="mobile-code"
            v-model="referralCode"
            v-focus
            class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
            type="text"
          >
        </div>
      </form>
      <div class="flex mh4 flex-row pb3 justify-between">
        <div class="w-48">
          <ak-button
            block
            class="modal-button inter"
            secondary
            @click.native="resetState"
          >
            {{ $t('edit.cancel', { cancel: 'Cancel' }) }}
          </ak-button>
        </div>
        <div class="w-48">
          <ak-button
            block
            class="modal-button inter"
            primary
            @click.native="updateReferralCode"
          >
            {{ $t('edit.save', { save: 'Save' }) }}
          </ak-button>
        </div>
      </div>
    </div>

    <hr class="line mh3">
    <div class="mv2 ph3 flex flex-column justify-center items-start height-dv">
      <div class="flex flex-column">
        <div class="mt1 elementLeft">
          {{ $t('referralssettings.deductible', { deductible: 'Total Deductible Credit Balance' }) }}
        </div>
      </div>
      <div class="flex flex-row">
        <div class="data">
          ${{ $store.state.user.total_deductible_credit.toFixed(2) }}
        </div>
      </div>
    </div>
    <!--         title="Share AKKO with your friends and family!"-->

    <div class="pl3 desktop pr3 mt3">
      <router-link
        class="no-underline"
        to=""
      >
        <ak-button
          :disabled="!user.is_activated"
          class="inter share-btn fw6"
          primary
          @click.native="shareResetState"
        >
          {{ $t('referralssettings.share', { share: 'Share Referral Code' }) }}
        </ak-button>
      </router-link>
    </div>
    <ak-modal
      v-if="showShareReferralCodeModal"
      cera-title
      no-hr
      small
      :title="$t('referralssettings.title', { title: 'Share AKKO with your friends and family' })"
      @close="resetState"
    >
      <!--         title="Share AKKO with your friends and family!"-->
      <div
        slot="modal-body"
        class="mh3 btn-container flex"
      >
        <div class="flex flex-grow btn-height mb2 mr2">
          <ak-button
            v-clipboard:copy="referralCode"
            block
            class="inter btn-height flex flex-grow copy-btn"
            primary
            @click.native="copyReferralCode"
          >
            <span class="copy-btn-code cera-round-pro">{{ referralCode }}</span>
            <span class="flex-grow" />
            <span class="copy-btn-text f7">{{ $t('referralssettings.copycode', { copycode: 'Copy Code' }) }}</span>
          </ak-button>
        </div>

        <div class="flex items-center logo-height mb2">
          <a
            :href="emailPath"
            class="flex logo-height pointer email-logo"
          ><img
            alt="email"
            src="@/assets/appicons/Email.svg"
          ></a>
        </div>
        <div class="flex items-center logo-height mb2">
          <ShareNetwork
            :description="`I just signed up with AKKO to protect my phone and all electronics for only $17/mo. Check it out! ${shareBody}`"
            :quote="`I just signed up with AKKO to protect my phone and all electronics for only $17/mo. Check it out! ${shareBody}`"
            network="facebook"
            title="I just signed up with AKKO to protect my phone and all electronics for only $17/mo. Check it out!"
            url="https://getAKKO.com"
          >
            <div class="facebook-logo" />
          </ShareNetwork>
        </div>
        <div class="flex items-center logo-height mb2">
          <ShareNetwork
            :description="shareBody"
            :quote="shareBody"
            :title="`I just signed up with AKKO to protect my phone and all electronics for only $17/mo. Check it out! ${shareBody}`"
            network="twitter"
            style="height: 40px"
            url="https://getAKKO.com"
          >
            <img
              alt="Twitter"
              class="twitter-logo"
              src="@/assets/social/twitter_sky_blue.png"
            >
          </ShareNetwork>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="flex ph4 pb4 flex-column lh-copy f6 silver"
      >
        <div
          v-if="copyPressed"
          class="flex w-100 f5 justify-start mb4"
          style="height:10px; color: black"
        >
          <div>
            {{ $t('referralssettings.copied', { copied: 'Copied!' }) }}
          </div>
        </div>
        {{ $t('referralssettings.alert', { alert: "Anyone who uses your code will get their 1st month FREE and you'll get a $20 credit towards your deductibles on any future claims you have! You get a $20 credit per referral, with no limit on how much credit you can stack up." }) }}
      </div>
    </ak-modal>


    <!--MOBILE SHARE BUTTONS-->
    <div class="mar-horizontal mobile-flex btn-container flex-wrap flex share-container justify-between">
      <div class="flex btn-height mb2">
        <ak-button
          v-clipboard:copy="referralCode"
          block
          class="inter btn-height"
          primary
          @click.native="copyReferralCode"
        >
          {{ $t('referralssettings.copycode2', { copycode2: 'Copy Referral Code' }) }}
        </ak-button>
      </div>
      <div class="flex items-center flex-row">
        <div class="flex silver mb2 mr2">
          {{ $t('referralssettings.share2', { share2: 'Or Share Code' }) }}
        </div>
        <div
          v-if="isMobile"
          class="flex items-center logo-height mb2"
        >
          <ShareNetwork
            :description="shareBody"
            class="flex logo-height pointer sms-logo"
            network="sms"
            title="Message (SMS)"
            url="https://getAKKO.com"
          >
            <img
              alt="SMS"
              src="/appicons/Message.svg"
            >
          </ShareNetwork>
        </div>
        <div class="flex items-center logo-height mb2">
          <a
            :href="emailPath"
            class="flex logo-height pointer email-logo"
          ><img
            alt="email"
            src="/appicons/Email.svg"
          ></a>
        </div>
        <div class="flex items-center logo-height mb2">
          <ShareNetwork
            :description="`I just signed up with AKKO to protect my phone and all electronics for only $17/mo. Check it out! ${shareBody}`"
            :quote="`I just signed up with AKKO to protect my phone and all electronics for only $17/mo. Check it out! ${shareBody}`"
            network="facebook"
            title="I just signed up with AKKO to protect my phone and all electronics for only $17/mo. Check it out!"
            url="https://getAKKO.com"
          >
            <div class="facebook-logo" />
          </ShareNetwork>
        </div>
        <div class="flex items-center logo-height mb2">
          <ShareNetwork
            :description="shareBody"
            :quote="shareBody"
            :title="`I just signed up with AKKO to protect my phone and all electronics for only $17/mo. Check it out! ${shareBody}`"
            network="twitter"
            style="height: 40px"
            url="https://getAKKO.com"
          >
            <img
              alt="Twitter"
              class="twitter-logo"
              src="@/assets/social/twitter_sky_blue.png"
            >
          </ShareNetwork>
        </div>
      </div>
      <div
        v-if="copyPressed"
        class="flex w-100 justify-center mb3"
        style="height:10px"
      >
        <div>
          {{ $t('referralssettings.copied', { copied: 'Copied!' }) }}
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AkButton from '../../../Button.vue'
import ArrowBack1 from '../../icons/ArrowBack.vue'
import AkModal from '../../../Modal'

export default {
  name: 'ReferralsComponent',
  components: {
    AkModal,
    AkButton,
    ArrowBack1
  },
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showShareReferralCodeModal: false,
      showEditReferralCodeModal: false,
      copyPressed: false,
      referralCode: '',
      windowWidth: window.innerWidth
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth <= 500
    },
    user () {
      return this.$store.state.user
    },
    birthday () {
      return this.$store.state.activation.dateOfBirth
    },
    canUpdateName () {
      return this.last_name.trim() !== '' || this.first_name.trim() !== ''
    },
    shareBody () {
      return `Hi! Use my code ${this.user.referral_code} to get device protection FREE for 1 month with AKKO!

      Click here to learn more https://getakko.com
      `
    },
    emailPath () {
      return `mailto:?subject=I just signed up with AKKO to protect my phone an all electronics for only $17/mo you should check it out! &body=Hi! Use my code ${this.user.referral_code} to get device protection FREE for 1 month with AKKO!  Click here to learn more: https://getakko.com `
    }
  },
  mounted () {
    if (this.user.referral_code) this.referralCode = this.user.referral_code
  },
  beforeDestroy () {
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  },
  methods: {
    shareResetState () {
      if (this.showShareReferralCodeModal) {
        this.showEditReferralCodeModal = false
      }
      this.showShareReferralCodeModal = !this.showShareReferralCodeModal
    },
    resetState () {
      this.showShareReferralCodeModal = false
      this.showEditReferralCodeModal = false
    },
    copyReferralCode () {
      this.copyPressed = true
      setTimeout(function () {
        this.copyPressed = false
      }.bind(this), 3000)
    },
    goBack () {
      this.$router.push('/account/dashboard')
    },
    updateReferralCode () {
      this.$store.dispatch('updateReferralCode', {'referral_code': this.referralCode})
      this.resetState()
    }
  }
}
</script>

<style scoped>

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  height: 71px;
  border-radius: 5px;
  padding: 0 18px;
}

.row-edit {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  height: 71px;
  border-radius: 5px;
  padding: 0 18px;
}
.row:hover, .mobile-row:hover {
  background: #FAFAFA;
  border-radius: 4px;
}

input {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757D8A;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #757D8A;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #757D8A;
}

input:focus {
  border: 1px solid #F3103D;
}

.modal-opened-row {
  align-items: flex-start;
  height: auto;
}

.modal-button {
  height: 40px;
  border-radius: 5px;
}

.modal-opened-row-text {
  margin-top: 2.75em;
}

.modal {
  width: 75%;
  align-items: flex-start;
}

main {
  padding-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  padding-top: .25rem;
}

.data {
  margin-top: .75rem;
}

.height-dv {
  height: 71px;
}

.height-dv:hover {
  background-color: #fafafa;
  border-radius: 4px;
}

.elementLeft {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  color: #757D8A;
}

.elementRight {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.pad-horizontal {
  padding-left: 18px;
  padding-right: 18px;
}

.mar-horizontal {
  margin-left: 18px;
  margin-right: 18px;
}

.save {
  width: 90px;
}

.cancel {
  width: 105px;
  border-width: 1.5px;
}

.content:last-child hr {
  display: none;
}

.line {
  border: 1px solid #E8E8E8;
}

.label {
  color: #757D8A;
  font-size: 11px;
}

.mobile-flex, .mobile {
  display: none;
}

.btn-container {
  column-gap: .5rem;
}

.btn-height, .logo-height {
  height: 52px;
}

.responsive-title {
  display: none;
}

.facebook-logo {
  mask: url("@/assets/social/facebook.png");
  mask-size: 40px 40px;
  height: 40px;
  width: 40px;
  background: linear-gradient(135deg, #2AA4F4 14.98%, #007AD9 91.54%);
  border-radius: 500px;
  margin-right: .5rem;
}

.twitter-logo {
  height: 40px;
  width: 40px;
}

.email-logo {
  height: 40px;
  width: 40px;
  border-radius: 500px;
  background-color: #5003F4;
  padding: .7rem;
  margin-right: .5rem;
}

.sms-logo {
  height: 40px;
  width: 40px;
  border-radius: 500px;
  background-color: #2fc238;
  padding: .7rem;
  margin-right: .5rem;
}

.copy-btn {
  background-color: #FDEDEF70;
  border-color: #F3103D;
  border-style: dashed;
  padding-left: 1rem;
  padding-right: 1rem;
}

.copy-btn:hover {
  background-color: #e6d8d970;
  border-color: #c20a2f;
}

.copy-btn-code {
  color: black;
}

.copy-btn-text {
  color: #F3103D;
}

@media (max-width: 768px) {

  .mobile-flex {
    display: flex;
  }

  .mobile {
    display: block;
  }

  .w-48 {
    width: 48%;
  }

  .responsive-title {
    display: flex;
    margin-bottom: 2rem;
  }

  main {
    padding-top: 0;
    padding-left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .edit-btn-container {
    flex-direction: column-reverse;
    /*row-gap: .5rem;*/
  }

  .edit-btn-back {
    margin: 0
  }

  .height-dv {
    height: 74px;
  }

  .row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .modal {
    width: 100%
  }

  .btn-height {
    width: 100%;
  }

  .desktop {
    display: none;
  }

  .share-container {
    justify-content: center;
  }

}

</style>
