<template>
  <div class="h-100">
    <div
      class="flex mt2 justify-center items-center"
      @click="(e) => toggleInclude(e)"
    >
      <div class="flex items-center includes-container pa2 benefits-button">
        <span class="includes-text cera-med mr2">{{ includeOpen ? 'Hide All Benefits' : 'View All Benefits' }}</span>
        <img
          :class="{ 'chevron-rotate': includeOpen }"
          alt="Chevron"
          class="chevron"
          src="@/assets/appicons/Chevron.svg"
        >
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UpsellPlanInfo',
  props: {
    type: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    },
    toggleInclude: {
      type: Function,
      default: () => {}
    },
    includeOpen: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.chevron {
  transition: transform 0.4s;
  transform: rotate(0deg);
}

.chevron-rotate {
  transform: rotate(180deg);
}

.cera-med {
  font-family: 'Cera Round Pro Medium', sans-serif;
}

.includes-container {
  border-radius: 5px;
  transition: border-radius 0.4s ease-in-out;
  z-index: 2;
}

.includes-container:hover {
  background-color: #eeeeee70;
}

.includes-container-open {
  border-radius: 5px 5px 0 0;
}

.includes-text {
  color: #6c6c6c;
  font-weight: 100;
  font-size: 0.85rem !important;
}

.plan-details-container {
  height: 87%;
  cursor: default;
  border-radius: 0 0 5px 5px;
  max-height: 0;
  background-color: #eeeeee70;
  transition: max-height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.plan-details-container-transition {
  cursor: pointer;
  max-height: 400px;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 479px) {
  .benefits-button {
    background-color: transparent !important;
  }

  .includes-text {
    color: black;
  }
}

</style>
