<template>
  <div class="mb4 flex flex-wrap">
    <div
      :class="isSomeComplete ? '' : 'h-100'"
      class="flex flex-column tc bg-white new-shadow-theme br3  w-100 ph4 main-container"
    >
      <no-device-data v-if="!isSomeComplete" />
      <div
        v-else
        class="flex flex-column flex-direction h-100 justify-between content"
      >
        <div>
          <div class="flex flex-row w-100 items-center justify-between h2 mb4">
            <div class="cera-round-pro-medium f4-ns black tl">
              Your Quote
            </div>
            <div v-if="!disableIntervalToggle">
              <plan-type-toggle
                :set-value="setMonthly"
                :toggle-value="monthlyToggle"
                custom-class="mt4 mb4"
                first-option="Monthly"
                second-option="Annual"
                toggle-name="monthlyToggle"
              />
            </div>
          </div>
          <summary-price
            :billed-price="commonPrice"
            :deductibles="generalDeductibles"
            :is-monthly="monthlyToggle"
            :is-phone-only="isPhoneOnly"
            :plan-name="name"
            :price-per-month="monthlyToggle ? commonPrice : yearlyPrice"
            :total-deductibles="totalDeductibles"
          />
          <device-info
            v-for="(deviceInfo, index) in phoneModelsWithCount"
            :key="index"
            :device-info="deviceInfo"
            :get-coverage-phone-only="getCoverage"
            :index="index"
            :is-monthly="monthlyToggle"
            :is-phone-only="isPhoneOnly"
            :phone-models="phoneModels"
          />
          <discount-message
            :is-applied="phoneModels.length > 1"
            :is-phone-only="isPhoneOnly"
          />
        </div>
        <div class="mt4 center w-100">
          <ak-button
            :new-disabled="disabled"
            :new-primary="!monthlyToggle"
            :new-secondary="monthlyToggle"
            block
            custom-class="button-custom"
            @click.native="submit"
          >
            {{ conversionCaption }}
          </ak-button>
        </div>
      </div>
    </div>
    <div class="mt3 silver lh-copy f6 tc w-100 mb4">
      <div class="inter">
        Upgrade, Downgrade or Cancel Anytime • Pro-Rated Adjustments & Refund
      </div>
    </div>
  </div>
</template>

<script>
import AkButton from '@/components/Button'
import PlanTypeToggle from '../PlanTypeToggle.vue'
import NoDeviceData from './NoDeviceData.vue'
import SummaryPrice from './SummaryPrice.vue'
import DeviceInfo from './DeviceInfo.vue'
import DiscountMessage from './DiscountMessage.vue'

export default {
  name: 'NewSummaryCard',
  components: {
    AkButton,
    PlanTypeToggle,
    NoDeviceData,
    SummaryPrice,
    DeviceInfo,
    DiscountMessage
  },
  props: {
    conversionCaption: {
      type: String,
      default: 'Select'
    },
    name: {
      type: String,
      default: 'Basic'
    },
    price: {
      type: Number,
      default: 10
    },
    monthlyToggle: {
      type: Boolean,
      default: true
    },
    disableIntervalToggle: {
      type: Boolean,
      default: true
    },
    filteredPhones: {
      type: Array,
      default: () => []
    },
    selectedCoverages: {
      type: Array,
      default: () => []
    },
    completeData: {
      type: Array,
      default: () => []
    },
    generalDeductibles: {
      type: Number,
      default: 0
    },
    specificDeductibles: {
      type: Object,
      default: () => {}
    },
    setMonthly: Function,
    isPhoneOnly: Boolean
  },
  computed: {
    phoneModels () {
      return (this.filteredPhones.map(({make, model, price, individualCoverageName}) => (
        {...this.getModelData(make, model), price, individualCoverageName}
      )))
    },
    disabled () {
      return this.completeData.includes(false)
    },
    isSomeComplete () {
      return this.completeData.includes(true)
    },
    commonPrice () {
      return this.price.toFixed(2)
    },
    yearlyPrice () {
      return (this.price / 12).toFixed(2)
    },
    totalDeductibles () {
      if ((this.specificDeductibles.adults.deductible === this.specificDeductibles.students.deductible) ||
      !(this.specificDeductibles.adults.quantity > 0 && this.specificDeductibles.students.quantity > 0)) {
        return [{'message': `Non-phone item deductibles for any claim are $${this.specificDeductibles.adults.deductible}`, 'smallPrint': false}]
      }
      return [{'message': `$${this.specificDeductibles.adults.deductible} Adult Deductible`, 'smallPrint': true}, {'message': `${this.specificDeductibles.students.deductible} Student Deductible`, 'smallPrint': true}]
    },
    phoneModelsWithCount () {
      const devices = {}
      this.phoneModels.forEach(phoneCoverage => {
        const key = `${phoneCoverage.id}-${phoneCoverage.annual_coverage_id}-${phoneCoverage.monthly_coverage_id}-${phoneCoverage.individualCoverageName}`
        if (devices[key]) {
          devices[key].count += 1
        } else {
          devices[key] = {
            count: 1,
            content: {...phoneCoverage}
          }
        }
      })
      const result = []

      Object.keys(devices).forEach((key) =>
        result.push({
          count: devices[key].count,
          ...devices[key].content
        })
      )

      return result
    }
  },
  methods: {
    submit () {
      const coverage = {
        id: this.coverageid,
        name: this.name,
        price: this.price,
        deductible: this.deductible,
        max_coverage: this.claims
      }
      this.$store.commit('SUBMIT_COVERAGE', coverage)
      this.$emit('click')
    },
    getCoverage (idToFind) {
      const cov = this.selectedCoverages.find(({id}) => id === idToFind)

      return cov
    },
    getModelData (makeToFind, modelToFind) {
      const result = this.$store.state.allPhoneModels.find(({make, model}) =>
        makeToFind === make && modelToFind === model)

      return result
    }
  }
}
</script>

<style scoped>
.price {
  font-size: 3.5em;
}

.button-custom {
  font-size: 16px;
  padding: 15px 0;
  height: 3em;
}

.main-container {
  padding: 2.5em 2.5em;
}

@media (max-width: 1280px) {
  .button-custom {
    font-size: 16px;
    padding: 10px 0;
  }
}

@media (max-width: 650px) {
  .main-container {
    padding: 1.5em 2.2em;
  }

  .button-custom {
    font-size: 14px;
    padding: 15px 0;
  }
}

@media (max-width: 400px) {
  .main-container {
    padding: 20px 15px;
  }

  .responsive-title {
    font-size: 1em;
  }
}
</style>
