<template>
  <main>
    <div class="mt4">
      <div class="f5 db mb3">
        Student Verification
      </div>
      <label
        for="school"
        class="f6 b db mb2"
      >School Name</label>
      <input
        id="school"
        v-model="studentData.school"
        class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
        type="text"
      >
      <p>Please upload proof of your student status (school ID, unofficial transcript, receipt of tuition paid, or class schedule)</p>
      <div
        class="pv5 w-100 tc mb5"
        :class="filePickerClass"
      >
        <input
          id="file"
          class="input-reset dn"
          type="file"
          accept="image/*, .pdf,.heic,.heif,.HEIC,.HEIF"
          @change="processImage($event)"
        >
        <label
          for="file"
          class="dib fw5 pv2 ph5 br-pill pointer ba bw1  white "
          :class="studentData.studentValidation.name ? 'bg-green b-green' : 'bg-theme b-theme'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            class="v-sub mr1"
            fill="white"
          >
            <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
          </svg>
          <span class="white">{{ studentData.studentValidation.name ? 'File Selected' : 'Upload Transcript/ID' }}</span>
        </label>
      </div>
    </div>
  </main>
</template>

<script>
import { mapMutations } from 'vuex'
import { SET_REGISTRATION_STUDENT_DATA } from '@/store/registrationv2'

export default {
  name: 'AkStudentForm',
  data () {
    return {
      studentData: {
        school: '',
        studentValidation: {}
      }
    }
  },
  computed: {
    filePickerClass () {
      return this.studentData.studentValidation.name ? 'bg-washed-green' : 'bg-washed-red'
    }
  },
  watch: {
    studentData: {
      handler (value) {
        this.setStudentData({...value})
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      setStudentData: SET_REGISTRATION_STUDENT_DATA
    }),
    processImage (event) {
      this.studentData.studentValidation.file = event.target.files[0]
      this.studentData.studentValidation.name = this.studentData.studentValidation.file.name
      this.$forceUpdate()
      this.setStudentData({
        school: this.studentData.school,
        studentValidation: {
          name: this.studentData.studentValidation.file.name,
          file: event.target.files[0]
        }
      })
    }
  }
}
</script>
