<template>
  <div>
    <main
      class="flex bg-white new-shadow-theme br3  custom-padding responsive-box"
      :class="modalVisible ? 'blur-background': ''"
    >
      <div class="flex flex-column w-100 responsive-plan">
        <div class="cera-round-pro-medium f4 mb3 fw5 ">
          Special Offer for AKKO Members!
        </div>
        <div class="inter mb1 f6 self-center items-center justify-start text-container ">
          You've taken the first step to protect your devices from physical harm,
          but don't forget to add this great offer to protect your digital identity and wellbeing
        </div>
        <div
          :class="includeOpen ? 'mb3 mb5-l' : 'mb4 mb6-l'"
          class=" container pointer flex flex-column  w-100-mobile br3 pa4 mt3 h-100 relative"
        >
          <div class="cera-round-pro-medium f5 mb3 fw5 mb3 plan-title">
            Bundled Plan&nbsp;&nbsp;
            <span class="inter gray-text f6">CyberScout + Dashlane Premium</span>
          </div>
          <div class="flex flex-row-l flex-column justify-between plan-card">
            <plan-card-upsell
              title="CyberScout"
              :is-cyber-scout="true"
              :include-open="includeOpen"
              :plan-info-strings="cyberScoutStrings"
              content="$1M identify theft protection + ransomware protection"
              class="plan-card-inner"
            />
            <plan-card-upsell
              title="Dashlane Premium"
              :is-cyber-scout="false"
              :include-open="includeOpen"
              :plan-info-strings="dashLaneStrings"
              content="Premium password manager + VPN service & dark web monitoring"
              class="plan-card-inner"
            />
          </div>
          <div class="red-tick">
            <red-tick-icon class="mr2" />
          </div>

          <div class="mt2 upsell-toggle">
            <upsell-plan-info
              :toggle-include="toggleInclude"
              :include-open="includeOpen"
            />
          </div>
        </div>
        <div class="flex price-responsive justify-between">
          <div class="flex flex-row items-center text-price-responsive">
            <upsell-prices
              :upsell-retail-price="upsellRetailPrice"
              :upsell-current-price="upsellCurrentPrice"
              :interval="productPrice.interval"
            />
          </div>
          <div>
            <div class="tc flex justify-center">
              <ak-button
                class="button-responsive mr3"
                new-secondary
                @click.native="finishUpsell(false)"
              >
                Skip for now
              </ak-button>
              <ak-button
                class="button-responsive "
                new-primary
                @click.native="showModal"
              >
                Add to My Plan
              </ak-button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <modal-without-inner-border
      v-if="modalVisible"
      upsell
      title="Confirm Add-On Purchase"
      @close="hideModal"
    >
      <form
        slot="modal-body"
        class="flex-ns flex-wrap"
      >
        <div class="mt3 mb2 f6 mh3-ns gray-text">
          Upon confirming, the bundled add-on plan will be instantly added to your account followed by instructions on how to set it up. Are you sure you want to continue?
        </div>
      </form>

      <div
        slot="modal-footer"
        class="pb4 ph3 mt2 flex flex-wrap justify-between justify-end-ns upsell-footer"
      >
        <ak-button
          class="button-responsive mr3"
          new-secondary
          upsell
          @click.native="hideModal"
        >
          Skip for now
        </ak-button>
        <ak-button
          class="button-responsive "
          new-primary
          upsell
          @click.native="finishUpsell(true)"
        >
          Add to my plan
        </ak-button>
      </div>
    </modal-without-inner-border>
  </div>
</template>

<script>

import PlanCardUpsell from './PlanCardUpsell.vue'
import RedTickIcon from '../multiplePhonePlan/icons/RedTickIcon'
import UpsellPlanInfo from './UpsellPlanInfo.vue'
import AkButton from '@/components/Button'
import ModalWithoutInnerBorder from '@/components/ModalWithoutInnerBorder'
import { CYBERSCOUTSTRINGS, DASHLANESTRINGS } from '../../../constants/upsell/upsell'
import UpsellPrices from '@/components/registration/upsell/UpsellPrices'
import {trackOnMixpanel, MIXPANEL_EVENTS} from '@/services/tracking'

export default {
  name: 'SelectUpsell',
  components: {
    PlanCardUpsell,
    RedTickIcon,
    UpsellPlanInfo,
    AkButton,
    ModalWithoutInnerBorder,
    UpsellPrices
  },
  props: {
    modifyStep: Function,
    finishUpsell: Function,
    productPrice: {
      type: Object,
      default: () => {
        return {
          retail_price: null,
          current_price: null
        }
      }
    }
  },
  data () {
    return {
      includeOpen: true,
      modalVisible: false,
      isConfirmed: false,
      cyberScoutStrings: CYBERSCOUTSTRINGS,
      dashLaneStrings: DASHLANESTRINGS
    }
  },
  computed: {
    upsellRetailPrice () {
      return this.productPrice.retail_price
    },
    upsellCurrentPrice () {
      return this.productPrice.current_price
    }
  },
  methods: {
    toggleInclude (e) {
      this.includeOpen = !this.includeOpen
      trackOnMixpanel(MIXPANEL_EVENTS.UPSELL_CLICK_EXPAND, { 'Expand': this.includeOpen })
      e.stopPropagation()
    },
    showModal () {
      this.modalVisible = true
    },
    hideModal () {
      this.modalVisible = false
    },
    confirmPlan () {
      this.isConfirmed = true
    }
  }
}
</script>

<style scoped>

.container {
  border: 1px solid var(--theme-primary);
}
.vertical-bar {
  border-left: 1px solid #E8E8E8;
}
.price-responsive {
  flex-direction: row;
  gap: 5.125rem;
}

.button-responsive {
  width: 8rem
}
.red-tick {
  position: absolute;
  right: 5px;
  top: 15px;
}
.w-45 {
  width: 45%;
}

.custom-width {
  max-width: 850px;
  width: max-content;
}

.gray-normal-text {
  color: var(--theme-gray-text)
}
.gray-text {
  color: var(--theme-dark-gray-text)
}

.text-container {
  text-align: justify;
  color: var(--theme-dark-gray-text);
  width: 100%;
  line-height: 1.5;
}

.custom-padding {
  padding: 56px;
}

@media (max-width: 1280px) {
  .responsive-box {
    margin: 1em 0;
  }
  .custom-padding {
    padding: 2.7em 4% 2.7em 4%;
  }
}

@media (max-width: 1100px) {
  .price-responsive {
    flex-direction: column;
  }
  .text-price-responsive {
    margin-bottom: 1rem;
  }

  .button-responsive {
    width: 47%;
  }

}

@media (max-width: 800px) {
  .w-100-mobile {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .responsive-box {
    width: 100%;
    margin: 1em 0;
  }
  .text-container {
    width: 100%;
  }
  .custom-padding {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .responsive-plan {
    border: 1px solid var(--theme-medium-gray);
    padding: 1.5em 2.2em;
    border-radius: 0.5em;
    background-color: white;
  }
}

@media (max-width: 479px) {
  .responsive-plan {
    border: none;
    padding: 1.5em 1em;
  }

  .text-container {
    text-align: left;
  }

  .price-responsive {
    row-gap: 1rem;
  }

  .relative {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
  }

  .plan-title {
    display: contents;
  }

  .plan-card {
    margin-top: 1rem;
  }

  .plan-card-inner {
    margin-bottom: 1rem;
  }

  .upsell-toggle {
    margin-top: 0;
  }

  .upsell-footer {
    padding-bottom: 0.5rem;
    margin-top: 2rem;
    padding-left: 0;
    padding-right: 0;
  }

  .blur-background {
    filter: blur(4px);
  }
}

</style>
