<template>
  <div
    v-if="!keyboardOpen"
    class="fixed bottom-0 left-0 w-100 pt3 pb4 ph3 ph4-ns bg-white silver flex justify-between shadow-4 z-999 dn-xl"
  >
    <div class="flex-basis-2 tc">
      <router-link
        to="/"
        class="no-underline"
        :class="dashboardRoute ? 'color-theme' : 'silver'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-home"
        ><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg>
        <span class="db f7 pt1">{{ $t('menu.home', { home: 'Home' }) }}</span>
      </router-link>
    </div>
    <div class="flex-basis-2 tc">
      <router-link
        to="/devices"
        class="no-underline"
        :class="devicesRoute ? 'color-theme' : 'silver'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-shield"
        ><path d="M12 22s8-4 8-10V4l-8-2-8 2v8c0 6 8 10 8 10z" /></svg>
        <span class="db f7 pt1">{{ $t('menu.items', { items: 'Items' }) }}</span>
      </router-link>
    </div>
    <div class="flex-basis-2 tc">
      <router-link
        :to="featureFlagRedirectClaimV2 ? '/claims-v2' : '/claims'"
        class="no-underline"
        :class="claimsRoute ? 'color-theme' : 'silver'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-file-text"
        ><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" /><polyline points="14 2 14 8 20 8" /><line
          x1="16"
          y1="13"
          x2="8"
          y2="13"
        /><line
          x1="16"
          y1="17"
          x2="8"
          y2="17"
        /><polyline points="10 9 9 9 8 9" /></svg>
        <span class="db f7 pt1">{{ $t('menu.claims', { claims: 'Claims' }) }}</span>
      </router-link>
    </div>
    <div class="flex-basis-2 tc">
      <router-link
        to="/account"
        class="no-underline"
        :class="accountRouteNew ? 'color-theme' : 'silver'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-user"
        ><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle
          cx="12"
          cy="7"
          r="4"
        /></svg>
        <span class="db f7 pt1">{{ $t('menu.account', { account: 'Account' }) }}</span>
      </router-link>
    </div>
    <div class="flex-basis-2 tc">
      <router-link
        to="/contact"
        class="no-underline"
        :class="supportRoute ? 'color-theme' : 'silver'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-help-circle"
        ><circle
          cx="12"
          cy="12"
          r="10"
        /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><line
          x1="12"
          y1="17"
          x2="12"
          y2="17"
        /></svg>
        <span class="db f7 pt1">{{ $t('appmenu.support', { support: 'Support' }) }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { GET_FEATURE_FLAG } from '@/store/index'
import { REDIRECT_CLAIM_V2 } from '@/constants/feature_flag'

export default {
  name: 'AkAppFooter',
  computed: {
    devicesRoute () {
      return this.$route.name === 'Akko Devices'
    },
    accountRoute () {
      return this.$route.name === 'Akko Account'
    },
    accountRouteNew () {
      return this.$route.name === 'Dashboard'
    },
    claimsRoute () {
      return this.$route.name === 'Akko Claims'
    },
    dashboardRoute () {
      return this.$route.name === 'Akko Dashboard'
    },
    storageRoute () {
      return this.$route.name === 'Akko Cloud Storage'
    },
    supportRoute () {
      return this.$route.name === 'Akko Support'
    },
    keyboardOpen () {
      return this.$store.state.keyboardOpen
    },
    featureFlagRedirectClaimV2 () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](REDIRECT_CLAIM_V2)
      return feature ? feature.state : false
    }
  }
}
</script>

<style scoped>
.pb4 {
  padding-bottom: 1.5em;
}
.flex-basis-2 {
  flex-basis: 15%;
}
@media screen and (min-width: 80em) {
.dn-xl {
  display: none;
}
}
</style>
