<template>
  <main>
    <h1 class="f2 lh-copy pt3">
      {{ $t('titles.addclaims') }}
    </h1>

    <div v-if="!userHasAccess">
      <ak-alert
        v-if="user.membership_status === 'CANCELED'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'UNPAID'"
        color="red"
      >
        <span>Your coverage plan is currently unpaid. Please proceed to <router-link
          to="/account/dashboard"
          class="color-theme"
        >Account Settings</router-link> to resolve or </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'PAST_DUE'"
        color="red"
      >
        <span>Your coverage plan is currently past due. Please proceed to <router-link
          to="/account/dashboard"
          class="color-theme"
        >Account Settings</router-link> to resolve or </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'BANNED'"
        color="red"
      >
        <span>Your account has been banned. If you think this was done in error, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Inquiry Regarding Banned Account"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INACTIVE'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INVALID_USER'"
        color="red"
      >
        <span>Your account has been marked as invalid by our system due to unpaid premiums or other account issues. If you think this was done in error, </span>
        <a
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
          class="color-theme"
        >please contact our billing team.</a>
      </ak-alert>
    </div>

    <div class="grid-custom-ns">
      <div class="mt4">
        <label
          for="loss_date"
          class="db fw5 f5 mb2 cera-round-pro-medium"
        >Date of Loss<span class="color-theme f5"> *</span></label>
        <input
          id="loss_date"
          v-model="loss_date"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="date"
          :max="today_string"
          @focus="toggleKeyboard(true)"
          @blur="toggleKeyboard(false)"
        >
      </div>
      <div
        v-if="!featureFlagMultiLossTypeActive"
        class="mv4"
      >
        <label
          for="loss_type"
          class="db fw5 f6 mb2"
        >Type of loss<span class="color-theme f5"> *</span></label>
        <select
          id="loss_type"
          v-model="loss_type"
          class="b--black-20 pa2 mb2 db w-100 br1"
        >
          <option value="">
            Select a type of loss
          </option>
          <optgroup label="Accidental Damage">
            <option value="Cracked Screen">
              Cracked Screen
            </option>
            <option value="Cracked Back">
              Cracked Back
            </option>
            <option value="Cracked Camera">
              Cracked Camera
            </option>
            <option value="Liquid Damage">
              Liquid Damage
            </option>
            <option value="Accidental Damage - Other">
              Other
            </option>
          </optgroup>
          <optgroup label="Malfunctions">
            <option value="Screen">
              Screen
            </option>
            <option value="Camera">
              Camera
            </option>
            <option value="Charging Port">
              Charging Port
            </option>
            <option value="Battery">
              Battery
            </option>
            <option value="Malfunctions - Other">
              Other
            </option>
          </optgroup>
          <optgroup label="Theft and Vandalism">
            <option value="Theft">
              Theft
            </option>
            <option value="Theft from Unattended Vehicle">
              Theft from Unattended Vehicle
            </option>
            <option value="Vandalism">
              Vandalism
            </option>
            <option value="Theft and Vandalism - Other">
              Other
            </option>
          </optgroup>
        </select>
      </div>
    </div>
    <div :class="featureFlagMultiLossTypeActive ? '' : 'grid-half'">
      <div class="mb4">
        <label
          for="description"
          class="db fw5 f5 mb2 cera-round-pro-medium"
        >Description of Incident:<span class="color-theme f5"> *</span></label>
        <textarea
          id="description"
          v-model="description"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          maxlength="1000"
          rows="6"
          @focus="toggleKeyboard(true)"
          @blur="toggleKeyboard(false)"
        />
        <span class="fr f6 mt1 silver">{{ desc_count }}/1000 characters</span>
      </div>
    </div>

    <div>
      <div class="db fw5 f5 mb2 cera-round-pro-medium">
        Affected Items:<span class="color-theme f5"> *</span>
      </div>
      <div
        v-if="userXPIsWeb"
        class="f6 mb2 lh-copy inter"
      >
        Not seeing your item? Please
        <router-link
          to="/add-device-category"
          class="color-theme"
        >
          add it here
        </router-link>
        then return to file a claim.
      </div>
      <div
        v-if="registered_devices.length > 0"
        :class=" featureFlagMultiLossTypeActive? 'devices-affected' : 'flex flex-wrap pb3' "
      >
        <div
          v-for="(device, index) in registered_devices"
          :key="index"
          class="mr2 mb3"
        >
          <div v-if="!featureFlagMultiLossTypeActive">
            <input
              :id="device.id"
              v-model="devices"
              type="checkbox"
              :value="device.id"
              :disabled="device.has_active_claims"
              class="dn"
            >
            <label
              :for="device.id"
              class="db pv2 ph3 f6 tc b ba br-pill b-theme bg-white color-theme pointer inter"
            >{{ device.nickname }}</label>
          </div>
          <div v-if="featureFlagMultiLossTypeActive">
            <input
              :id="device.id"
              v-model="devices"
              type="checkbox"
              :value="device.id"
              :disabled="device.has_active_claims"
              class="dn"
            >
            <label
              :for="device.id"
              class="db mb2 pv2 ph3 f6 tc ba br-pill b-theme bg-white color-theme pointer"
            >{{ device.nickname }}</label>
            <div v-if="isSelected(device.id)">
              <div class="mb2">
                <label
                  for="loss_type"
                  class="db fw5 f6 mb2 cera-round-pro-medium"
                >Type of Loss<span class="color-theme f5"> *</span></label>
                <Multiselect
                  v-model="lossTypeValues[device.id]"
                  placeholder="Select loss types"
                  label="name"
                  track-by="name"
                  group-label="category"
                  group-values="typeLoss"
                  :searchable="false"
                  :group-select="false"
                  :options="lossTypesOptions"
                  :multiple="true"
                  :taggable="true"
                />
              </div>
              <div
                v-if="displayItemOperability(device.id)"
                class="mb2"
              >
                <label
                  for="item_operability"
                  class="db fw5 f6 mb2 cera-round-pro-medium"
                >Item Operability<span class="color-theme f5"> *</span></label>
                <Multiselect
                  v-model="itemOperabilityValues[device.id]"
                  placeholder="Select item operability"
                  label="description"
                  track-by="id"
                  :searchable="false"
                  :options="itemOperabilityOptions"
                />
              </div>
              <div class="mb2">
                <label
                  for="known_damage_"
                  class="db fw5 f6 mb2 cera-round-pro-medium"
                >Previous Known Damage:<span class="color-theme f5"> *</span></label>
                <div class="f6 mb2">
                  Did this item have any damage prior to this incident? If so, please explain.
                </div>
                <input
                  id="known_damage_"
                  v-model="previousDamages[device.id]"
                  class="input-reset ba b--black-10 pa2 mb2 db w-100 br1"
                  maxlength="1000"
                  rows="6"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <ak-alert
        v-if="registered_devices.length == 0"
        color="red"
      >
        <span>You haven't added any items to your account.</span>
        <router-link
          to="/add-device-category"
          class="color-theme"
        >
          <u>Add your items here.</u>
        </router-link>
      </ak-alert>
    </div>

    <!-- <div v-if="laptop_selected" class="mt3 grid-half">
      <fieldset class="input-reset bn pa0 ma0">
        <legend class="f6 b db mb2">Would you like to borrow a phone or laptop?</legend>
        <div class="flex justify-around w-50">
          <input id="borrow1" class="dn" type="radio" :value="true" name="borrow" v-model="borrow">
          <label tabindex="0" @keyup.enter="borrow=true" for="borrow1" class="flex-grow pv2 tc b ba br1 br--left b--silver silver bg-white pointer">Yes</label>
          <input id="borrow2" class="dn" type="radio" :value="false" name="borrow" v-model="borrow">
          <label tabindex="0" @keyup.enter="borrow=false" for="borrow2" class="flex-grow pv2 tc b ba bl-0 br1 br--right b--silver silver bg-white pointer">No</label>
        </div>
      </fieldset>
    </div> -->

    <div :class="featureFlagMultiLossTypeActive ? '' : 'grid-half'">
      <div
        v-if="!featureFlagMultiLossTypeActive"
        class="mt4"
      >
        <label
          for="known_damage"
          class="db fw5 f6 mb2"
        >Previous known damage:<span class="color-theme f5"> *</span></label>
        <textarea
          id="known_damage"
          v-model="known_damage"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          maxlength="1000"
          rows="6"
          @focus="toggleKeyboard(true)"
          @blur="toggleKeyboard(false)"
        />
        <span class="fr f6 mt1 silver">{{ dmg_count }}/1000 characters</span>
      </div>
    </div>
    <div class="mt3">
      <br>
      <label class="db fw5 f5 mb2 cera-round-pro-medium">Upload Proof:<span class="color-theme f5"> *</span></label>
      <div class="f6 mb2 lh-copy inter">
        Provide clear images of damage, evidence, and/or a copy of your police report.
      </div>
      <ak-img-input
        id="images"
        :upload-types="acceptedUploadTypes"
        :description="uploadTypeDescription"
        @filesUpdated="(payload) => images = payload"
      >
        <div class="db f6 gray pt2">
          {{ uploadTypeDescription }}
          <br>
          <a
            :href="fileFormatConversionUrl"
            target="_blank"
          > {{ heicfConversionDescription }} </a>
        </div>
      </ak-img-input>
    </div>
    <div
      v-if="requiresDeviceVerification"
      class="mt3"
    >
      <br>
      <label class="db fw5 f5 mb2 cera-round-pro-medium"> {{ requiresAboutPage ? aboutPageTitle: phoneUniqueIdTitle }} <span class="color-theme f5"> *</span></label>
      <div class="f6 mb2 lh-copy inter">
        {{ requiresAboutPage ? aboutPageDescription: phoneUniqueIdDescription }}
        <a
          :href="requiresAboutPage ? aboutPageExampleUrl : phoneUniqueIdExampleUrl"
          target="_blank"
        >Click here to see examples.</a>
      </div>
      <div class="mt2">
        <label
          for="hasDeviceVerificationException"
          class="dib fw5 f6 mb2 inter i"
        >
          <br>
          <input
            id="hasDeviceVerificationException"
            v-model="hasDeviceVerificationException"
            type="checkbox"
            class="normal"
          >
          I am unable to turn on my damaged phone or use the screen.
        </label>
      </div>
      <ak-img-input
        id="imagesDeviceVerification"
        :upload-types="acceptedUploadTypes"
        :description="uploadTypeDescription"
        @filesUpdated="(payload) => imagesDeviceVerification = payload"
      >
        <div class="db f6 gray pt2">
          {{ uploadTypeDescription }}
          <br>
          <a
            :href="fileFormatConversionUrl"
            target="_blank"
          > {{ heicfConversionDescription }} </a>
        </div>
      </ak-img-input>
    </div>

    <div class="mt3">
      <div class="mt2">
        <label class="db fw5 f5 mb2 cera-round-pro-medium">Select Payout Method<span class="color-theme f5"> *</span></label>
        <div class="f6 mb2">
          Selecting a payout method now ensures the fastest possible transfer once your claim is approved for repair or replacement
        </div>
      </div>
    </div>

    <div class="grid-half">
      <fieldset class="input-reset bn pa0 ma0">
        <div class="flex justify-around w-50">
          <input
            id="payoutpaypal"
            v-model="payoutMethod"
            class="dn"
            type="radio"
            :value="payoutMethods.PAYPAL"
            name="payoutmethod"
          >
          <label
            tabindex="0"
            for="payoutpaypal"
            class="flex-grow pv2 tc fw5 ba br1 br--left b--silver silver bg-white pointer"
            @keyup.enter="payoutMethod=payoutMethods.PAYPAL"
          >PayPal</label>
          <input
            id="payoutvenmo"
            v-model="payoutMethod"
            class="dn"
            type="radio"
            :value="payoutMethods.VENMO"
            name="payoutmethod"
          >
          <label
            tabindex="0"
            for="payoutvenmo"
            class="flex-grow pv2 tc fw5 ba bl-0 br1 br--right b--silver silver bg-white pointer"
            @keyup.enter="payoutMethod=payoutMethods.VENMO"
          >Venmo</label>
          <input
            id="payoutskip"
            v-model="payoutMethod"
            class="dn"
            type="radio"
            :value="payoutMethods.WITHOUT_PAYOUT_METHOD"
            name="payoutmethod"
          >
          <label
            tabindex="0"
            for="payoutskip"
            class="flex-grow pv2 tc fw5 ba bl-0 br1 br--right b--silver silver bg-white pointer"
            @keyup.enter="payoutMethod=payoutMethods.WITHOUT_PAYOUT_METHOD"
          >Skip</label>
        </div>
      </fieldset>
    </div>

    <div
      v-if="payoutMethod == payoutMethods.PAYPAL"
      class="grid-half"
    >
      <div>
        <div class="mt2">
          <label
            for="paypalemail"
            class="f6 b db mb2"
          >PayPal Email</label>
          <input
            id="paypalemail"
            v-model="paypalemail"
            name="paypalemail"
            class="input-email ba b--black-20 pa2 mb2 db w-100 br1 w-100-ns"
            type="email"
            autocomplete="paypalemail"
            @focus="toggleKeyboard(true)"
            @blur="toggleKeyboard(false)"
          >
          <div
            v-if="emailValidationMessages.error"
            id="emailvalidationmessage"
            class="red f7 mt2"
          >
            {{ emailValidationMessages.error }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="payoutMethod == payoutMethods.VENMO"
      class="grid-half"
    >
      <div class="mt3">
        <div class="mt2">
          <label
            for="venmoPhone"
            class="f6 b db mb2"
          >Venmo Phone</label>
          <label
            for="venmoPhone"
            class="f6 b db mb2"
          >(Verify your phone number)</label>
          <InputMask
            id="venmoPhone"
            v-model="venmoPhone"
            name="venmoPhone"
            class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
            type="tel"
            mask="(999) 999-9999"
            :unmask="true"
            @focus="toggleKeyboard(true)"
            @blur="toggleKeyboard(false)"
          />
          <div
            v-if="venmoPhoneValidationMessages.error"
            id="venmoPhonevalidationmessage"
            class="red f7 mt2"
          >
            {{ venmoPhoneValidationMessages.error }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="payoutMethod == payoutMethods.WITHOUT_PAYOUT_METHOD">
      <div class="mt3">
        <div class="mt2">
          <label class="f6 b db mb2">Our support team will be happy to coordinate an alternative payout method with you upon resolution.</label>
        </div>
      </div>
    </div>

    <!-- <div class="mt3">
        <div class="fw6 f6 mb2">Receipts and proof of ownership<span class="color-theme f5"> * </span><span class="color-theme underline pointer">more info</span></div>
        <div class="f6 mb2">Upload pictures of purchase receipts or PDFs of email/online receipts.</div>
        <ak-img-input id="receipts" @filesUpdated="(payload) => receipts = payload"></ak-img-input>
      </div>
    </div> -->


    <div
      class="mt4"
      :class="[ !userHasAccess ? 'pb4' : '' ]"
    >
      <h3 class="db fw5 f6 mb1 inter">
        DISCLAIMER:
      </h3>
      <p class="dark-gray i f6 inter">
        We must advise you that any person who knowingly, and with intent to commit fraud, files a statement of claim containing any materially false information,
        or conceals for the purpose of misleading information concerning any fact thereto, commits a fraudulent act, which is a crime and may be prosecuted to the
        full extent of the law. By signing, I agree that the statements above are true and correct to the best of my knowledge and cannot be changed once submitted to the company.
      </p>
      <div class="mt2">
        <label
          for="disclaimer"
          class="dib fw5 f6 mb2 inter"
        >
          <input
            id="disclaimer"
            v-model="disclaimer"
            type="checkbox"
            class="normal"
          >
          I agree and am filing this claim with full knowledge of my coverage benefits as outlined in the user agreement and terms and conditions.
        </label>
      </div>
      <div
        v-show="showAccept"
        class="mt2"
      >
        <p class="dark-gray f6 inter">
          Please note, your phone will be reported as stolen and unusable once this claim is submitted.
        </p>
        <input
          id="acceptChkbx"
          v-model="acceptChkbx"
          type="checkbox"
          class="normal"
        >
        <label
          for="acceptChkbx"
          class="dib fw5 f6 mb2 inter"
        >I Accept</label>
      </div>
      <div class="mv4">
        <label
          for="sig"
          class="db fw5 f6 mb2"
        >Please type your full name as a digital signature</label>
        <input
          id="sig"
          v-model="sig"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1 w-50-ns"
          type="text"
          :disabled="!disclaimer"
          @focus="toggleKeyboard(true)"
          @blur="toggleKeyboard(false)"
        >
      </div>
    </div>

    <div
      v-if="userHasAccess"
      class="mt4 mb5 mw5-ns"
    >
      <ak-button
        block
        :disabled="!formComplete || loading"
        primary
        @click.native="fileClaim"
      >
        File Claim
      </ak-button>
    </div>
  </main>
</template>

<script>
import Multiselect from 'vue-multiselect'
import AkButton from './Button'
import AkImgInput from './ImgInput'
import AkAlert from './Alert'
import { GET_FEATURE_FLAG } from '@/store/index'
import { MULTI_LOSS_TYPE } from '@/constants/feature_flag'
import PAYOUT_METHODS from '@/constants/claims/payoutMethods'
import InputMask from 'primevue/inputmask'

// Properly format todays day
const d = new Date()
const year = d.getFullYear()
const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`
const day = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`
const today = `${year}-${month}-${day}`

const lossTypesForPhoneId = ['Screen', 'Cracked Screen', 'Accidental Damage - Other', 'Malfunctions - Other']
const multiLossTypesForPhoneId = {
  'Accidental Damage': ['Cracked Screen', 'Other'],
  'Malfunctions': ['Screen', 'Other']
}
const aboutPageExampleUrl = 'https://help.getakko.com/en/articles/6544004-how-to-take-a-photo-of-your-phone-s-about-page'
const phoneUniqueIdExampleUrl = 'https://help.getakko.com/en/articles/6503820-how-to-provide-a-photo-of-my-device-s-id'
const fileFormatConversionUrl = 'https://help.getakko.com/en/articles/6612703-how-do-i-change-image-file-formats-when-submitting-claim-photos'
// move descriptions to json file once i18n is implemented
const aboutPageDescription = 'Please take a photo of your damaged phone while on the "About Page" within your phone\'s settings is displayed on the screen. The phone serial number and IMEl should be visible on the screen. Be sure the entire screen is visible.'
const phoneUniqueIdDescription = 'Please take a photo of your damaged phone while on the "Claims" page within your AKKO account, or the Login page of the AKKO mobile app is open on your damaged phone\'s screen.'
const aboutPageTitle = 'Take Photo of "About Page" in Phone Settings'
const phoneUniqueIdTitle = 'Take Photo of Device Unique ID'
const acceptedUploadTypes = ['.png', '.pdf', '.jpg', '.jpeg']
const uploadTypeDescription = 'The current supported file extensions are the following: png, jpg, jpeg, pdf.'
const heicfConversionDescription = 'How to convert .heic or .heif file types.'

export default {
  name: 'AkAddClaim',
  components: {
    AkButton,
    AkImgInput,
    AkAlert,
    Multiselect,
    InputMask
  },
  data () {
    return {
      loss_date: today,
      loss_type: '',
      description: '',
      known_damage: '',
      devices: [],
      borrow: false,
      disclaimer: false,
      sig: '',
      imagesDeviceVerification: [],
      images: [],
      receipts: [],
      showImageModal: false,
      paypalemail: '',
      acceptChkbx: false,
      lossTypeValues: {},
      previousDamages: {},
      itemOperabilityValues: {},
      venmoPhone: '',
      payoutMethod: '',
      payoutMethods: PAYOUT_METHODS,
      phoneId: undefined,
      aboutPageDescription,
      phoneUniqueIdDescription,
      aboutPageTitle,
      phoneUniqueIdTitle,
      aboutPageExampleUrl,
      phoneUniqueIdExampleUrl,
      fileFormatConversionUrl,
      hasDeviceVerificationException: false,
      acceptedUploadTypes,
      uploadTypeDescription,
      heicfConversionDescription,
      isPhoneFunctional: true
    }
  },
  computed: {
    // Validate PayPal email
    emailValidationMessages () {
      let error

      if (this.payoutMethod === PAYOUT_METHODS.PAYPAL && !this.emailValid && (this.paypalemail || '').trim()) {
        error = 'Must use a valid email address'
      } else {
        error = ''
      }
      return { error }
    },
    emailValid () {
      // eslint-disable-next-line
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.paypalemail)
    },
    today_string () {
      return today
    },
    loss_day () {
      return parseInt(this.loss_date.slice(8, 10))
    },
    loss_month () {
      return parseInt(this.loss_date.slice(5, 7))
    },
    loss_year () {
      return parseInt(this.loss_date.slice(0, 4))
    },
    desc_count () {
      return this.description.length
    },
    dmg_count () {
      return this.known_damage.length
    },
    registered_devices () {
      return this.$store.state.devices
    },
    showAccept () {
      if (['Theft', 'Theft from Unattended Vehicle', 'Vandalism', 'Theft and Vandalism - Other'].indexOf(this.loss_type) !== -1) {
        let tempDevices = this.registered_devices
         .filter(el => this.devices.includes(el.id))
         .map(el => el.type)
        return tempDevices.includes('phone')
      } else return false
    },
    laptop_selected () {
      let tempDevices = this.registered_devices
        .filter(el => this.devices.includes(el.id))
        .map(el => el.type)
      return tempDevices.includes('laptop') || tempDevices.includes('phone')
    },
    loading () {
      return this.$store.state.loading
    },
    validateDeviceLossTypes () {
      return this.devices.every((d) => {
        return this.lossTypeValues[d] && this.lossTypeValues[d].length > 0
      })
    },
    validateDevicePrevDamage () {
      // return true
      return this.devices.every((d) => {
        // It is string but is also array
        return this.previousDamages[d] && this.previousDamages[d].length > 0
      })
    },
    formComplete () {
      if (this.loss_day === null) return false
      if (this.loss_month === null) return false
      if (this.loss_year === null) return false
      if (this.featureFlagMultiLossTypeActive) {
        if (this.validateDeviceLossTypes === false) return false
      }
      if (this.devices === null || this.devices.length <= 0) return false
      if (this.description === '') return false
      if (this.disclaimer === false) return false
      if (this.featureFlagMultiLossTypeActive) {
        if (this.validateDevicePrevDamage === false) return false
      }
      if (this.sig === '') return false
      if (this.emailValidationMessages.error !== '') return false
      if (this.payoutMethod === PAYOUT_METHODS.PAYPAL && this.paypalemail === '') return false
      if (this.acceptChkbx === false && this.showAccept === true) return false
      if (this.venmoPhoneValidationMessages.error !== '') return false
      if (this.payoutMethod === PAYOUT_METHODS.VENMO && this.venmoPhone === '') return false
      if (!this.payoutMethod) return false
      if (!this.hasDeviceVerificationException && this.requiresDeviceVerification && this.imagesDeviceVerification.length === 0) return false
      return true
    },
    user () {
      return this.$store.state.user
    },
    userHasAccess () {
      const status = this.user.membership_status
      return status !== 'BANNED' && status !== 'CANCELED' && status !== 'UNPAID' && status !== 'PAST_DUE' && status !== 'INACTIVE' && status !== 'INVALID_USER'
    },
    userXPIsWeb () {
      return this.userHasAccess && (this.user.user_experience_path === 'web' || this.user.user_experience_path === 'all')
    },
    lossTypesOptions () {
      const lossTypes = this.$store.state.claims.lossTypes
      const lossTypesOpts = lossTypes.map((l) => {
        return {
          category: l.name,
          typeLoss: l.loss_types.map((ll) => {
            return {
              id: ll.id,
              name: ll.name,
              category: l.name,
              category_id: l.id
            }
          })
        }
      })
      return lossTypesOpts
    },
    featureFlagMultiLossTypeActive () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](MULTI_LOSS_TYPE)
      return feature ? feature.state : false
    },
    // Validate venmo user
    venmoPhoneValidationMessages () {
      let error

      if (this.payoutMethod === PAYOUT_METHODS.VENMO && !this.venmoPhoneValid && (this.venmoPhone || '').trim()) {
        error = 'Must use a valid phone'
      } else {
        error = ''
      }
      return { error }
    },
    venmoPhoneValid () {
      return this.isValidPhone(this.venmoPhone)
    },
    requiresDeviceVerification () {
      return this.hasPhoneSelected && this.isLossTypeforPhoneIdUpload && this.isPhoneFunctional
    },
    hasPhoneSelected () {
      return this.devices.some((id) => id === this.phoneId)
    },
    isLossTypeforPhoneIdUpload () {
      const containsRequiredLossType = (lossTypeObj) => {
        let currentCategory = lossTypeObj.category
        let currentLossType = lossTypeObj.name
        let validLossTypes = multiLossTypesForPhoneId[currentCategory]

        if (validLossTypes) {
          return validLossTypes.includes(currentLossType)
        } else {
          return false
        }
      }

      if (this.featureFlagMultiLossTypeActive) {
        let selectedPhoneLossTypes = this.lossTypeValues[this.phoneId]

        if (selectedPhoneLossTypes) {
          return selectedPhoneLossTypes.some(containsRequiredLossType)
        } else {
          return false
        }
      } else {
        return lossTypesForPhoneId.includes(this.loss_type)
      }
    },
    requiresAboutPage () {
      return this.hasDeviceIdentifier === false
    },
    hasDeviceIdentifier () {
      let currentDevice = this.$store.state.currentDevice

      if (currentDevice.device_identifier !== null) {
        return true
      } else {
        let currentDeviceImages = currentDevice.images

        if (currentDeviceImages && currentDeviceImages.length > 0) {
          return currentDeviceImages.some((image) => image.device_identifier !== null)
        } else {
          return false
        }
      }
    },
    itemOperabilityOptions () {
      return this.$store.state.itemOperabilities
    },
    images_payload () {
      return [...this.imagesDeviceVerification, ...this.images, ...this.receipts]
    },
    claim_media () {
      return this.images_payload.map(({file}) => {
        return {
          file_type: file.type,
          file_name: file.name,
          file_size: file.size
        }
      })
    }
  },
  watch: {
    registered_devices () {
      this.phoneId = this.getPhoneId()
      this.setCurrentDeviceToPhone()
    },
    requiresDeviceVerification (newState) {
      if (newState === false) {
        this.hasDeviceVerificationException = false
        this.imagesDeviceVerification.length = 0
      }
    },
    itemOperabilityValues: {
      handler (newState) {
        let phoneOperability = newState[this.phoneId]

        if (phoneOperability && phoneOperability.description) {
          this.isPhoneFunctional = !phoneOperability.description.toLowerCase().includes('completely unusable')
        } else {
          this.isPhoneFunctional = true
        }
      },
      deep: true
    }
  },
  mounted () {
    this.$store.dispatch('getDevices')
    this.$store.dispatch('getLossTypes')
    this.$store.dispatch('getItemOperabilities')
    this.venmoPhone = this.user.phone_no
  },
  beforeDestroy () {
    this.$store.dispatch('resetCurrentDevice')
  },
  methods: {
    toggleKeyboard (trueOrFalse) {
      this.$store.commit('TOGGLE_KEYBOARD', trueOrFalse)
    },
    fileClaim () {
      const payload = {
        loss_day: this.loss_day,
        loss_month: this.loss_month,
        loss_year: this.loss_year,
        loss_type: this.loss_type,
        description: this.description,
        known_damage: this.known_damage,
        devices: JSON.stringify(this.devices),
        images: [...this.imagesDeviceVerification, ...this.images, ...this.receipts],
        borrow: this.borrow,
        sig: this.sig,
        paypal_email: this.payoutMethod !== PAYOUT_METHODS.PAYPAL ? '' : this.paypalemail,
        venmo_phone: this.payoutMethod !== PAYOUT_METHODS.VENMO ? '' : this.venmoPhone,
        item_operabilities: JSON.stringify(this.itemOperabilityValues)
      }
      if (this.images_payload.length > 0) {
        payload.claim_media = JSON.stringify(this.claim_media)
      }
      if (this.featureFlagMultiLossTypeActive) {
        payload['loss_types'] = JSON.stringify(this.lossTypeValues)
        payload['previous_damages'] = JSON.stringify(this.previousDamages)
      }
      this.$store.dispatch('fileClaim', payload)
    },
    isSelected (deviceId) {
      return this.devices.find((d) => d === deviceId)
    },
    getPhoneId () {
      for (let index = 0; index < this.registered_devices.length; index++) {
        let curDevice = this.registered_devices[index]
        if (curDevice.type.toLowerCase() === 'phone') {
          return curDevice.id
        }
      }
      return undefined
    },
    setCurrentDeviceToPhone () {
      if (this.phoneId) {
        try {
          this.$store.dispatch('getCurrentDevice', {id: this.phoneId})
        } catch (e) {
          console.error(e)
        }
      }
    },
    displayItemOperability (deviceId) {
      // for multi-loss only
      // current logic is hardcoded, will need to modify if conditions change
      let deviceLossTypes = this.lossTypeValues[deviceId]

      if (deviceLossTypes && deviceLossTypes.length) {
        let hasInvalidLossType = deviceLossTypes.some((lossType) => {
          return lossType.category === 'Theft and Vandalism' && lossType.name.toLowerCase().includes('theft')
        })
        if (hasInvalidLossType) {
          this.resetItemOperability(deviceId) // clear previously selected item operability
          return false
        }
        return true
      }
      return false
    },
    resetItemOperability (deviceId) {
      delete this.itemOperabilityValues[deviceId]
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
@media screen and (min-width: 30em) {
.grid-custom-ns {
  display: grid;
  grid-template-columns: 2fr 3fr 3fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
}
}

.scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.flex-shrink-0 {
  flex-shrink: 0
}

textarea {
  resize: vertical;
}

input[type=radio]:checked + label{
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}

input[type=checkbox]:checked:not(.normal) + label{
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}

input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  pointer-events: all !important;
  border-color: gray;
  color: gray;
}
.devices-affected {
  width: 100%;
  max-width: 400px;
}
@media screen and (max-width: 960px)  {
  .devices-affected {
    max-width: 100%;
  }
}
</style>
