export const akkoPlanUpgradeStrings = {
  title: "Upgrade to 'AKKO' Plan",
  subHeading: 'Protect Phone + 25 Items',
  planBenefitsSummary: 'Protect 1 phone + all your electronic devices and more',
  planBenefitsLineItems: [
    'Protect 1 phone + 25 items',
    'Accidental damage',
    'Phone parts & battery failure',
    'Theft protection',
    'Up to $2,000 per claim',
    'Unlimited claim'
  ],
  deductibleItems: [
    '$29-$99 Deductibles for phone',
    '$99 for all other items'
  ]
}
