<template>
  <div :class="'flex flex-column ' + margin">
    <div
      v-if="title"
      class="flex flex-column"
    >
      <label class="cera-round-pro fw6 db mb3 title">{{ title }}</label>
      <label class="f6 silver inter mb4">{{ subtitle }}</label>
    </div>
    <br>
    <div class="flex flex-row">
      <input
        v-focus="autoFocus"
        class="inter f6 input-reset w-40 ba db mr3 br2 code-input"
        :placeholder="firstLabel"
        :value="firstValue"
        :maxlength="maxLength"
        @input="setFirstValue"
      >
      <input
        class="inter f6 input-reset w-40 ba db mr3 br2 code-input"
        :placeholder="secondLabel"
        :value="secondValue"
        @input="setSecondValue"
        @focus="emailInput(false)"
        @blur="emailInput(true)"
      >
    </div>
    <div class="flex flex-row w-100 h2">
      <div
        v-if="firstValue.length < 14 || !phoneError"
        :class="errorClass"
      >
        Please enter a valid phone
      </div>
      <div
        v-if="firstValue.length === 14 && phoneError"
        :class="errorClass"
      >
        A user with this phone number already exists
      </div>
      <div
        v-show="showEmailError"
        class="red f6 mt2 w-40"
      >
        {{ emailError ? 'Please enter a valid email' : "Student's Email (use .edu address)" }}
      </div>
    </div>
    <div
      v-if="showStudentMessage"
      class="f6 silver inter mt4 mb2"
    >
      If you do not have a .edu email, enter an alternate email address.
      <br>
      You will need to provide verification of student status.
    </div>
  </div>
</template>

<script>

export default {
  name: 'PersonalForm',
  props: {
    title: String,
    subtitle: String,
    firstLabel: String,
    firstValue: String,
    secondLabel: String,
    secondValue: String,
    phoneError: {
      type: Boolean,
      default: false
    },
    emailError: {
      type: Boolean,
      default: false
    },
    margin: {
      type: String,
      default: ''
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    studentError: {
      type: Boolean,
      default: false
    },
    emailUnfocus: {
      type: Boolean,
      default: true
    },
    maxLength: {
      type: Number,
      default: null
    }
  },
  computed: {
    errorClass () {
      return ('red f6 mt2 mr3 w-40 ' + (
        !this.phoneError ||
        this.firstValue === '' ? 'visible' : ''
      ))
    },
    showEmailError () {
      return (
        ((this.studentError && this.emailUnfocus) || this.emailError) &&
        this.secondValue !== ''
      )
    },
    showStudentMessage () {
      return (
        this.studentError &&
        this.emailUnfocus &&
        !this.emailError &&
        this.secondValue !== ''
      )
    }
  },
  methods: {
    setFirstValue (event) {
      this.$emit('update:firstValue', event.target.value)
    },
    setSecondValue (event) {
      this.$emit('update:secondValue', event.target.value)
    },
    emailInput (unfocus) {
      this.$emit('update:emailUnfocus', unfocus)
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 20px;
  }
  .code-input {
    border-color: var(--theme-gray);
    padding: 10px 15px 10px 15px;
  }
  .code-input::placeholder {
    color: var(--theme-gray);
  }
  .code-input:focus {
    border-color: var(--theme-gray);
    outline: none;
  }
  .visible {
    visibility: hidden;
  }
</style>
