<template>
  <main
    :class="[
      !showCustomWidth && 'mr5',
      showCustomWidth && 'custom-width',
      !showPlans && 'plans-hidden'
    ]"
    class="flex bg-white br3 new-shadow-theme custom-padding responsive-box"
  >
    <div class="flex flex-column w-100 responsive-device">
      <div class="cera-round-pro-medium f4 mb4 fw5">
        Select Your Phone{{ selectedPhones.length > 1 ? 's' : '' }}
      </div>
      <div class="inter mb1 f6 self-center items-center justify-start text-container">
        {{ selectPhoneDescription }}
      </div>
      <div
        v-if="selectedPhones.length > 1"
        class="inter mv1 f6 self-center items-center text-container justify-start flex"
      >
        <img
          alt="Users"
          class="icon"
          src="@/assets/appicons/Users.svg"
        >If registering more than one plan, each
        person will have their own account and login tied to their mobile number.
      </div>
      <div
        v-if="!isPhoneOnly"
        class="inter mv1 f6 self-center items-center text-container justify-start flex"
      >
        <img
          alt="Info"
          class="icon"
          src="@/assets/appicons/Info.svg"
        >{{ selectPhoneVerbiage }}
      </div>
      <div class="flex flex-row flex-wrap w-100 justify-between cards mt3">
        <edit-device
          v-for="(phone, index) in selectedPhones"
          :key="phone.id"
          :all-makes="allMakes"
          :filter-models-by-selected-make="filterModelsBySelectedMake"
          :is-family="isFamily"
          :is-phone-only="isPhoneOnly"
          :phone-index="index"
          :phones-number="selectedPhones.length"
          :remove-item="removePhone"
          :selected-phone="phone"
          :set-is-adult="setIsAdult"
          :set-make="setMake"
          :set-model="setModel"
        />
        <add-phone
          v-if="isOddPhones && !isParnertId "
          :discount="selectedPhones.length === 1"
          :on-click="addPhone"
        />
      </div>
      <add-phone
        v-if="(!isOddPhones && !isParnertId)"
        :on-click="addPhone"
        class="outside"
      />
    </div>
  </main>
</template>

<script>
import {mapGetters} from 'vuex'
import EditDevice from './EditDevice.vue'
import AddPhone from '../selectBox/AddPhone.vue'
import {GET_PARTNER_PROPERTY_ID} from '@/store/registrationv2'

export default {
  name: 'AkSelectDevices',
  components: {
    EditDevice,
    AddPhone
  },
  props: {
    selectedPhones: {
      type: Array,
      default: () => []
    },
    specificDeductibles: {
      type: Object,
      default: () => {}
    },
    allMakes: {
      type: Set,
      default: () => []
    },
    completeData: {
      type: Array,
      default: () => []
    },
    filterModelsBySelectedMake: Function,
    removePhone: Function,
    setModel: Function,
    setMake: Function,
    setIsAdult: Function,
    addPhone: Function,
    isFamily: Boolean,
    isPhoneOnly: Boolean,
    is3cRegistration: Boolean,
    showPlans: Boolean
  },
  computed: {
    ...mapGetters({
      getPartnerPropertyId: GET_PARTNER_PROPERTY_ID
    }),
    isOddPhones () {
      return this.selectedPhones.length % 2
    },
    isParnertId () {
      if (this.getPartnerPropertyId) {
        return true
      } else {
        return false
      }
    },
    isSomeComplete () {
      return this.completeData.includes(true)
    },
    isCompleteand3c () {
      return this.completeData.includes(true) && this.is3cRegistration
    },
    showCustomWidth () {
      return !this.isCompleteand3c && !this.isSomeComplete && this.is3cRegistration
    },
    selectPhoneVerbiage () {
      if (this.specificDeductibles.adults.deductible !== this.specificDeductibles.students.deductible) {
        return `Non-phone item deductibles: $${this.specificDeductibles.adults.deductible} for adults, $${this.specificDeductibles.students.deductible} for students`
      }
      return `Non-phone item deductibles for any claim are $${this.specificDeductibles.adults.deductible}`
    },
    selectPhoneDescription () {
      const defaultString = 'Select your device\'s make and model to see its deductibles.'
      const akkoPlanAddonString = ' All non-phone items can be added to your account after successful checkout and login.'
      return !this.isPhoneOnly ? defaultString + akkoPlanAddonString : defaultString
    }
  }
}
</script>

<style scoped>
.custom-padding {
  padding: 2.5em 4% 2.5em 4%;
}

.icon {
  margin-right: 0.5rem;
  width: 20px;
}

.custom-width {
  width: 850px;
  max-width: 850px;
  min-width: 19.5rem;
}

.text-container {
  text-align: justify;
  color: var(--theme-gray-text);
  width: 100%;
}

.responsive-box {
  margin-top: 35px;
}

.plans-hidden {
  margin-top: 0;
}

@media (max-width: 1280px) {
  .responsive-box {
    width: 80%;
    margin: 1em 0;
  }
}

@media (max-width: 1000px) {
  .cards {
    justify-content: center;
  }

  .text-container {
    width: 90%;
  }

  .outside {
    align-self: center;
  }
}

@media (max-width: 600px) {
  .responsive-box {
    width: 100%;
    margin: 1em 0;
  }

  .text-container {
    width: 100%;
  }

  .custom-padding {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .responsive-device {
    border: 1px solid var(--theme-medium-gray);
    padding: 1.5em 2.2em;
    border-radius: 0.5em;
    background-color: white;
  }
}

</style>
