<template>
  <main>
    <div class="inter">
      <div
        v-if="billedByPartner || uiSettings[hideDeductibleCreditArea] !== true"
        class="mv2"
      >
        <div class="cell-multiple ph3 flex flex-column justify-center">
          <div class=" flex justify-between items-center responsive-text">
            <span class="mb1 silver-element">{{ $t('referralssettings.deductible', { deductible: 'Total Deductible Credit Balance' }) }}</span>
            <span>${{ user.total_deductible_credit.toFixed(2) }}</span>
          </div>
        </div>
      </div>
      <hr class="line mv3">
      <div class="mv3">
        <div class="flex ph3 justify-between">
          <span class="silver-element">{{ $t('planssettings.yourplan', { yourplan: 'Your Plan' }) }}</span>
        </div>
        <div v-if="hasContract">
          <contract-line-items
            :contract-line-items="contractLineItemsByCategory(userContract.line_items, 'COVERAGE')"
            :is-billed-by-partner="billedByPartner"
            :discount="discount"
            :phone="user.phone_no"
            class="mv3"
          >
            <template #changePlan>
              <router-link
                v-if="uiSettings[hideChangePlan] !== true"
                class="no-underline dib-ns"
                to="/edit-coverage"
              >
                <span class="color-theme">{{ $t('planssettings.changeplan', { changeplan: 'Change plan' }) }}</span>
              </router-link>
            </template>
          </contract-line-items>
          <div
            v-if="contractLineItemsByCategory(userContract.line_items, 'UPSELL').length"
            class="mt2"
          >
            <span class=" ph3 silver-element mb3">Add-On Plan(s)</span>
            <contract-line-items
              :contract-line-items="contractLineItemsByCategory(userContract.line_items, 'UPSELL')"
              :is-billed-by-partner="billedByPartner"
              :discount="discount"
              :phone="user.phone_no"
              class="mv3"
            />
          </div>
        </div>
        <div v-else>
          <div
            v-if="!billedByPartner"
            class="cell-multiple ph3 flex flex-column justify-center"
          >
            <div class=" flex justify-between items-center responsive-text">
              <span class="mb1">{{ coveragePlanFirstLine }}</span>
              <span>${{ discount_amount }}</span>
            </div>
            <div class="flex justify-between">
              <span class="silver-element">{{ coveragePayPeriodFirstLine }} • {{ $t('login.phone', { phone: 'Phone' }) }}: {{ user.phone_no }}</span>
              <router-link
                v-if="uiSettings[hideChangePlan] !== true"
                class="no-underline dib-ns"
                to="/edit-coverage"
              >
                <span class="color-theme">{{ $t('planssettings.changeplan', { changeplan: 'Change plan' }) }}</span>
              </router-link>
            </div>
          </div>
          <!-- BILLED BY PARTNER -->
          <div
            v-else
            class="cell-multiple ph3 flex flex-column justify-center"
          >
            <div class=" flex justify-between items-center responsive-text">
              <span class="mb1">{{ coveragePlanFirstLine }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="!billedByPartner"
        v-if="user.related_users && user.related_users.length > 0"
        class=" mv3"
      >
        <hr class="line mb3">
        <div class="ph3 flex justify-between items-center">
          <span class="silver-element mb2">{{ $t('planssettings.otherplans', { otherplans: 'Other Plans' }) }}</span>
        </div>
        <div v-if="hasSecondaryUserContracts">
          <div
            v-for="(contract, index) in secondaryContracts"
            :key="index"
          >
            <contract-line-items
              :contract-line-items="contractLineItemsByCategory(contract.line_items, 'COVERAGE')"
              :is-billed-by-partner="billedByPartner"
              :discount="discount"
              :phone="contract.user.phone"
              class="mv3"
            />
            <contract-line-items
              :contract-line-items="contractLineItemsByCategory(contract.line_items, 'UPSELL')"
              :is-billed-by-partner="billedByPartner"
              :discount="discount"
              :phone="contract.user.phone"
              class="mv3"
            />
          </div>
        </div>
        <div v-else>
          <div
            v-for="({plan, planType, discountAmount, phone}, index) in otherPlans"
            :key="index"
            class="content"
          >
            <div class="3 flex justify-between items-center cell-multiple responsive-text">
              <div class="flex ph3 flex-column">
                <span class="mb1">{{ plan }}</span>
                <span class="silver-element">{{ planType }} • {{ $t('login.phone', { phone: 'Phone' }) }}: {{ phone }}</span>
              </div>
              <div class="ph3 flex items-end flex-column">
                <span>${{ discountAmount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="!billedByPartner"
        class="mt4 ph3"
      >
        <router-link
          v-if="uiSettings[hideAddPlan] !== true"
          class="no-underline dib-ns responsive-width"
          to="/add-plan"
        >
          <ak-button
            :disabled="!user.is_activated"
            class="height-diff inter"
            primary
          >
            {{ $t('planssettings.addplan', { addplan: 'Add Plan' }) }}
          </ak-button>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>

import AkButton from '../../../Button.vue'
import _ from 'lodash'
import { AKKO, LAPTOP_TABLET, PARTNER } from '../../../../constants/account-settings/accountSettings'
import { GET_PARTNER } from '@/store'
import { HIDE_CHANGE_PLAN, HIDE_ADD_PLAN, HIDE_DEDUCTIBLE_CREDIT_AREA } from '@/constants/ui-settings/uiSettings'
import { mapGetters, mapActions, mapState } from 'vuex'
import ContractLineItems from './ContractLineItems.vue'

export default {
  name: 'PlansComponent',
  components: {
    AkButton,
    ContractLineItems
  },
  data () {
    return {
      hideChangePlan: HIDE_CHANGE_PLAN,
      hideAddPlan: HIDE_ADD_PLAN,
      hideDeductibleCreditArea: HIDE_DEDUCTIBLE_CREDIT_AREA
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      userContract: state => state.contract,
      secondaryContracts: state => state.secondaryContracts,
      productCategories: state => state.productCategories
    }),
    ...mapGetters({
      partner: GET_PARTNER,
      isUserPrimary: 'isUserPrimary'
    }),
    discount_amount () {
      return this.user.discount_amount.toFixed(2).toString()
    },
    coveragePayPeriodFirstLine () {
      return this.user.coverage_plan_interval === this.$i18n.t('planssettings.phoneonly.annual') ? this.$i18n.t('planssettings.annual') : this.$i18n.t('planssettings.monthly')
    },
    coveragePlanFirstLine () {
      return this.user.coverage_display_name || `${this.coverageNameFirstLine} protection`
    },
    coverageNameFirstLine () {
      let itemCovered
      switch (this.user.coverage_item_covered) {
        case LAPTOP_TABLET:
          itemCovered = 'Laptop / Tablet'
          break
        case AKKO:
          itemCovered = 'AKKO Plan'
          break
        default:
          itemCovered = _.startCase(_.toLower(this.user.coverage_item_covered))
      }
      return itemCovered
    },
    otherPlans () {
      const relatedUsers = this.user.related_users
      // add item_covered
      if (relatedUsers && relatedUsers.length > 0) {
        const otherPlansPayload = relatedUsers.map((relatedUser) => {
          const {
            coverage_name: coverageName,
            coverage_plan_type: coveragePlan,
            first_name: firstName,
            last_name: lastName,
            phone,
            price,
            item_covered,
            discount_amount
          } = relatedUser
          const coverageText = coverageName
          const fullNameText = firstName && lastName ? `${firstName} ${lastName}` : ''
          const phoneText = phone ? `${phone}` : ''
          const priceText = price ? `$${price}`.replace('.', ',') : ''
          const discountAmountRelated = discount_amount.toFixed(2).toString()

          return {
            user: fullNameText,
            plan: coverageText,
            phone: phoneText,
            planType: `${_.startCase(coveragePlan)} (${priceText})`,
            discountAmount: discountAmountRelated,
            itemCovered: item_covered
          }
        })
        return otherPlansPayload
      } else {
        return []
      }
    },
    billedByPartner () {
      return this.user.payment_processor ? this.user.payment_processor === PARTNER : false
    },
    uiSettings () {
      const uiSettings = this.partner && this.partner.settings && this.partner.settings.ui_settings
      return uiSettings || {}
    },
    hasContract () {
      return (
        this.userContract &&
        Object.keys(this.userContract).length > 0
      )
    },
    hasSecondaryUserContracts () {
      return this.secondaryContracts ? this.secondaryContracts.length === this.user.related_users.length : false
    },
    discount () {
      return this.user.discounts[0]
    }
  },
  async beforeMount () {
    await this.getProductCategories()
    await this.getActiveContract(this.isUserPrimary)
  },
  beforeDestroy () {
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  },
  methods: {
    ...mapActions([
      'getActiveContract',
      'getProductCategories'
    ]),
    contractLineItemsByCategory (contractLineItems, categoryName) {
      return contractLineItems.filter((lineItem) => {
        let lineItemProductCategoryId = lineItem.product.product_category_id
        let productCategory = this.productCategories.find((productCategory) => productCategory.id === lineItemProductCategoryId)
        return productCategory.name === categoryName && lineItem.on_contract === true
      })
    }
  }
}
</script>

<style scoped>

main {
  padding-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  padding-top: .25rem;
}

.cell-multiple {
  height: 71px;
  border-radius: 4px;
}

.silver-element {
  color: #757D8A;
}

.cell-multiple:hover {
  background-color: #fafafa;

}

.line {
  border: 1px solid #E8E8E8;
}

.content:last-child hr {
  display: none;
}

a {
  color: inherit;
}

.responsive-title {
  display: none;
}
.height-diff {
  height: 45px;
  border-radius: 5px;
  font-size: 17px;
}

@media (max-width: 768px) {

  main {
    padding-top: 0;
    padding-left: 0;
    margin-right: 0;
    margin-top: 0;
  }



  .responsive-title {
    display: flex;
  }

  .responsive-text {
    padding-left: 0;
  }
}

</style>
