export const CYBERSCOUTSTRINGS = [
  '$1M Identity Reimbursement Coverage', '$25K Ransomware Coverage', '$25K Social Engineering Coverage',
  '24/7 Cyber Support Line', 'Credit Monitoring Services'
]

export const DASHLANESTRINGS = [ 'Unlimited Password Storage', 'Dark Web Monitoring',
  'Personalized Security Alerts', 'Secure Notes', 'Encrypted File Storage (1 GB)',
  'Password Health Checker', 'Password Generator',
  'Form & Payment Autofill', 'Automatic Password Changer',
  'Unlimited Device Access', 'Secure Password Sharing'
]
