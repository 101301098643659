<template>
  <main class="flex flex-column justify-center self-center center-ns mb4 main-container">
    <akko-header />
    <div class="circle" />
    <div v-if="spin" />
    <div v-else>
      <div
        v-if="isFirstStep"
        :class="existsData.includes(true) ? 'justify-between': 'justify-center'"
        class="flex responsive"
      >
        <div
          :class="existsData.includes(true) ? '': is3cRegistration ? 'items-center' : ''"
          class="flex flex-column w-65 responsive-choose-plan"
        >
          <div v-show="showPlans">
            <select-plan-3c-vue
              v-if="is3cRegistration"
              :complete-data="existsData"
              :is-phone-only="isPhoneOnly"
              :set-value="setIsPhoneOnly"
            />
            <select-plan
              v-else
              :is-phone-only="isPhoneOnly"
              :set-value="setIsPhoneOnly"
            />
          </div>

          <div class="devices-div">
            <select-devices
              :add-phone="addPhone"
              :all-makes="allMakes"
              :complete-data="existsData"
              :filter-models-by-selected-make="filterModelsBySelectedMake"
              :is3c-registration="is3cRegistration"
              :is-family="isFamily"
              :is-phone-only="isPhoneOnly"
              :remove-phone="removePhone"
              :selected-phones.sync="selectedPhones"
              :set-is-adult="setIsAdult"
              :set-make="setMake"
              :set-model="setModel"
              :specific-deductibles="coverageAkkoPlan.total"
              :show-plans="showPlans"
            />
          </div>
        </div>

        <div
          :class="is3cRegistration ? existsData.includes(true) ? '' : 'visibility' : '' "
          class="mb4 flex flex-wrap flex-column responsive-box w-35"
        >
          <new-summary-card-3c-vue
            v-if="is3cRegistration"
            :complete-data="existsData"
            :filtered-phones="filteredPhones"
            :general-deductibles="coverageAkkoPlan.deductible"
            :is-family="isFamily"
            :is-phone-only="isPhoneOnly"
            :monthly-toggle="monthlyToggle"
            :disable-interval-toggle="disableIntervalToggle"
            :name="coverageName"
            :price="totalPrice"
            :selected-coverages="monthlyToggle ? selectedMonthlyCoverages : selectedAnnualCoverages"
            :set-monthly="setMonthly"
            :specific-deductibles="coverageAkkoPlan.total"
            conversion-caption="Proceed to Checkout"
            plan-type="PHONE"
            @click="nextStep"
          />
          <new-summary-card
            v-else
            :complete-data="existsData"
            :filtered-phones="filteredPhones"
            :general-deductibles="coverageAkkoPlan.deductible"
            :is-family="isFamily"
            :is-phone-only="isPhoneOnly"
            :monthly-toggle="monthlyToggle"
            :disable-interval-toggle="disableIntervalToggle"
            :name="coverageName"
            :price="totalPrice"
            :selected-coverages="monthlyToggle ? selectedMonthlyCoverages : selectedAnnualCoverages"
            :set-monthly="setMonthly"
            :specific-deductibles="coverageAkkoPlan.total"
            conversion-caption="Proceed to Checkout"
            plan-type="PHONE"
            @click="nextStep"
          />
        </div>
      </div>
      <div
        v-else
        :class="is3cRegistration ? 'justify-center' : 'justify-between'"
        class="mt6 flex responsive"
      >
        <unified-checkout
          v-if="is3cRegistration"
          ref="cardForm"
          :card-first-name.sync="cardFirstName"
          :card-last-name.sync="cardLastName"
          :postal-code.sync="postalCode"
          :credit-card-completed.sync="creditCardCompleted"
          :disabled-button="secondStepDisabled"
          :email.sync="email"
          :filtered-phones="filteredPhones"
          :has-agreed.sync="hasAgreed"
          :is-bank-data-filled="isBankDataFilled"
          :is-bigger-coupon.sync="isBiggerCoupon"
          :is-credit-card.sync="isCreditCard"
          :is-first-step.sync="isFirstStep"
          :is-student="studentQuantity > 0"
          :monthly-toggle="monthlyToggle"
          :disable-interval-toggle="disableIntervalToggle"
          :on-submit="submit"
          :price="totalPrice"
          :second-step-devices.sync="secondStepDevices"
          :set-monthly="setMonthly"
          :total-multi-plan-discount="totalMultiPlanDiscount"
          :total-price-without-discount="totalPriceWithoutDiscount"
          :coverages="monthlyToggle ? selectedMonthlyCoverages : selectedAnnualCoverages"
          :set-primary-phone="setPrimaryPhone"
        />
        <checkout-form
          v-else
          ref="cardForm"
          :card-first-name.sync="cardFirstName"
          :card-last-name.sync="cardLastName"
          :credit-card-completed.sync="creditCardCompleted"
          :email.sync="email"
          :is-bank-data-filled="isBankDataFilled"
          :is-credit-card.sync="isCreditCard"
          :is-first-step.sync="isFirstStep"
          :second-step-devices.sync="secondStepDevices"
        />
        <div
          :class="[(isPhoneOnly ? 'w-40' : 'w-30'), (is3cRegistration ? 'dn' : 'db')]"
          class="mb4 responsive-box"
        >
          <cart-total-info
            :disabled-button="secondStepDisabled"
            :filtered-phones="filteredPhones"
            :has-agreed.sync="hasAgreed"
            :is-first-step.sync="isFirstStep"
            :is-phone-only="isPhoneOnly"
            :is-student="studentQuantity>0"
            :monthly-toggle="monthlyToggle"
            :name="coverageName"
            :on-submit="submit"
            :price="totalPrice"
            :selected-coverages="monthlyToggle ? selectedMonthlyCoverages : selectedAnnualCoverages"
            plan-type="PHONE"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {isEmpty, isEqual} from 'lodash'

import AkkoHeader from '../AkkoHeader.vue'
import NewSummaryCard from './multiplePhonePlan/summaryBox/NewSummaryCard.vue'
import NewSummaryCard3cVue from './multiplePhonePlan/summaryBox/NewSummaryCard3c.vue'
import SelectDevices from './multiplePhonePlan/selectBox/SelectDevices.vue'
import SelectPlan from './multiplePhonePlan/selectBox/SelectPlan.vue'
import SelectPlan3cVue from './multiplePhonePlan/selectBox/SelectPlan3c.vue'
import UnifiedCheckout from './multiplePhonePlan/formBox/UnifiedCheckout.vue'
import CheckoutForm from './multiplePhonePlan/formBox/CheckoutForm.vue'
import CartTotalInfo from './multiplePhonePlan/cartTotalInfo/CartTotalInfo.vue'
import PLAN_INTERVALS from '@/constants/registration/planIntervals'
import PLAN_TYPES from '@/constants/registration/planTypes'
import {
  ADD_DISCOUNT_CODE,
  DECRYPT_PARTNER_DEVICES,
  GET_BANKACCOUNT_ERROR,
  GET_PARTNER_DEVICES,
  GET_PARTNER_PROPERTY_ID,
  GET_PARTNER_STRATEGY_NAME,
  GET_PARTNER_UI_SETTINGS,
  GET_PAYMENT_DATA,
  PARTNER_UI_SETTINGS,
  REQUEST_INTERNAL_DISCOUNT_VALUES,
  REQUEST_PARTNER_DISCOUNTS,
  SET_PARTNER_PROPERTY_ID,
  SET_PAYMENT_DATA,
  SET_REGISTRATION_COVERAGES,
  SET_REGISTRATION_DISCOUNTS_REMOVE_ALL,
  SET_REGISTRATION_INTERVAL,
  SET_REGISTRATION_LEAD_ID,
  SET_REGISTRATION_PRIMARY_EMAIL,
  SET_REGISTRATION_REFFERAL,
  SUBMIT_REGISTRATION
} from '../../store/registrationv2'
import {SET_REGISTRATION_TYPE, SET_SPINNER_TEXT} from '../../store/index'
import REGISTRATION_TYPE from '../../constants/registration/registrationType'
import DISCOUNT_CODE from '../../constants/registration/discountCode'
import {SET_LOADING} from '../../store/mutations'
import {trackOnMixpanel, MIXPANEL_EVENTS} from '@/services/tracking'

export default {
  name: 'AkUnifiedRegistration',
  components: {
    AkkoHeader,
    NewSummaryCard,
    NewSummaryCard3cVue,
    SelectDevices,
    SelectPlan,
    SelectPlan3cVue,
    CheckoutForm,
    UnifiedCheckout,
    CartTotalInfo
  },
  data () {
    let firstPhone =
      {
        make: '',
        model: '',
        price: 0,
        individualCoverageName: '',
        id: 0
      }
    this.$route.query.student === 'true' ? firstPhone.adult = false : firstPhone.adult = true
    return {
      existsData: [false],
      monthlyToggle: this.$route.query.interval === 'monthly',
      disableIntervalToggle: this.$route.query.disableIntervalToggle === 'true',
      isPhoneOnly: this.$route.name === 'Registration Unified Phone',
      isFamily: this.$route.query.family === 'true',
      selectedPhones: [firstPhone],
      phonesId: 1,
      selectedMonthlyCoverages: [],
      selectedAnnualCoverages: [],
      filteredPhones: [],
      isFirstStep: true,
      cardFirstName: '',
      cardLastName: '',
      postalCode: '',
      secondStepDevices: [],
      email: '',
      creditCardCompleted: false,
      spin: !!this.$route.query.partner_property_id,
      isCreditCard: true,
      // THIS IS MEANT TO BE TRUE, IS FALSE FOR TESTING PURPOSES
      discountCode: '',
      hasAgreed: false,
      multiplan: false,
      isBiggerCoupon: false,
      disableSelectDevice: false,
      showPlans: true
    }
  },
  computed: {
    ...mapGetters({
      partnerDevices: GET_PARTNER_DEVICES,
      uiSettings: GET_PARTNER_UI_SETTINGS,
      partnerStrategyName: GET_PARTNER_STRATEGY_NAME,
      paymentData: GET_PAYMENT_DATA,
      bankAccountError: GET_BANKACCOUNT_ERROR,
      getPartnerPropertyId: GET_PARTNER_PROPERTY_ID,
      getAkkoPlanCoverageData: 'getAkkoPlanCoverageData'
    }),
    // VERIFY IF PARTNER PROPERTY ID EXISTS
    isPartnerWeb () {
      return !!this.$route.query.partner_property_id
    },
    isPartnerId () {
      return !!this.getPartnerPropertyId
    },
    is3cRegistration () {
      return this.$route.path.includes('3c')
    },
    secondStepDisabled () {
      const infoNeeded = this.secondStepDevices.every(({phone}) => phone) &&
        this.email &&
        this.hasAgreed
      return ((this.isCreditCard
          ? !this.cardFirstName || !this.postalCode || !this.cardLastName || !this.creditCardCompleted
          : this.bankAccountError || !this.isBankDataFilled
      ) || !infoNeeded)
    },
    isBankDataFilled () {
      return !isEmpty(this.paymentData)
    },
    coverageName () {
      let planName = ''
      if (this.secondStepDevices.length > 0) {
        let firstDevice = this.secondStepDevices[0]
        let planType = firstDevice.plan_type.charAt(0).toUpperCase() + firstDevice.plan_type.slice(1)
        planName = this.isPhoneOnly ? 'AKKO Phone Protection Plan' : `AKKO Everything ${planType} Plan`
        if (firstDevice.display_name != null) {
          planName = firstDevice.display_name
        }
      }
      if (this.selectedPhones.length > 1) {
        planName = this.isPhoneOnly ? 'Phone-only Bundle Plan' : 'AKKO Family Bundle Plan'
      }

      return planName
    },
    allMakes () {
      return new Set(['Apple', 'Samsung', 'Google', ...this.$store.state.allPhoneModels.map(model => model.make).sort()])
    },
    basePlans () {
      let planIntervals = [PLAN_INTERVALS.ANNUAL, PLAN_INTERVALS.MONTHLY]
      let [annualStudentPrices, monthlyStudentPrices] = this.getAkkoPlanCoverageData(planIntervals, PLAN_TYPES.STUDENT)
      let [annualAdultPrices, monthlyAdultPrices] = this.getAkkoPlanCoverageData(planIntervals, PLAN_TYPES.ADULT)
      return {
        student: {
          annual: annualStudentPrices,
          monthly: monthlyStudentPrices
        },
        adult: {
          annual: annualAdultPrices,
          monthly: monthlyAdultPrices
        }
      }
    },
    coverageAkkoPlan () {
      const studentPrice = this.basePlans.student[this.$route.query.interval]
      const adultPrice = this.basePlans.adult[this.$route.query.interval]

      return {
        price: (this.studentQuantity * studentPrice.price + this.adultQuantity * adultPrice.price),
        deductible: Math.max(adultPrice.deductible, studentPrice.deductible),
        total: {
          students: {quantity: this.studentQuantity, deductible: studentPrice.deductible},
          adults: {quantity: this.adultQuantity, deductible: adultPrice.deductible}
        }
      }
    },
    plans () {
      const plans = this.monthlyToggle ? this.selectedMonthlyCoverages : this.selectedAnnualCoverages
      plans.forEach(plan => (plan['phoneError'] = null))

      return plans
    },
    coverageOptions () {
      return this.$store.state.coverageOptions
    },
    adultQuantity () {
      return this.filteredPhones.filter(({adult}) => adult).length
    },
    studentQuantity () {
      return this.filteredPhones.length - this.adultQuantity
    },
    totalPrice () {
      let finalPrice = 0
      this.filteredPhones.forEach(({price}) => {
        finalPrice += price
      })
      return finalPrice
    },
    totalPriceWithoutDiscount () {
      let finalPriceWithoutDiscount = 0
      this.filteredPhones.forEach(({priceWithoutDiscount}) => {
        finalPriceWithoutDiscount += priceWithoutDiscount
      })
      return finalPriceWithoutDiscount
    },
    totalMultiPlanDiscount () {
      let multiPlanAmount = 0
      this.filteredPhones.forEach(({multiPlanDiscountAmount}) => {
        multiPlanAmount += multiPlanDiscountAmount
      })
      return multiPlanAmount
    }
  },
  watch: {
    '$route.query.step1' () {
      this.removeAllDiscountCodes()
    },
    isBiggerCoupon () {
      this.updateCoveragesInfo()
    },
    '$store.state.allPhoneModels' () {
      if (this.getPartnerPropertyId) {
        this.getDevices()
      } else {
        this.updateCoveragesInfo()
      }
    },
    partnerDevices () {
      this.getDevices()
    }
  },
  async mounted () {
    const {name} = this.$route.query
    this.setSpinnerText(name ? 'Welcome from ' + name : 'Loading...')

    this.setLoading(true)
    this.getCountryList({})
    await this.requestInternalDiscountValues()
    window.fbq('trackCustom', 'Phone Select Billing Period')
    await this.$store.dispatch('getAllCoverageOptions')
    await this.$store.dispatch('getAllPhoneModels')
    this.removeAllDiscountCodes()

    if (this.selectedPhones && !this.selectedPhones.length) this.removeAllDiscountCodes()
    await this.initPlanType()

    this.setLoading(false)
    this.setSpinnerText(null)
    this.spin = false
  },
  beforeDestroy () {
    this.cardNumber.destroy()
    this.cardExpiry.destroy()
    this.cardCvc.destroy()
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  },
  methods: {
    ...mapActions({
      requestPartnerDiscounts: REQUEST_PARTNER_DISCOUNTS,
      addDiscountCode: ADD_DISCOUNT_CODE,
      submitRegistration: SUBMIT_REGISTRATION,
      requestInternalDiscountValues: REQUEST_INTERNAL_DISCOUNT_VALUES,
      decryptDevices: DECRYPT_PARTNER_DEVICES,
      getUiSettings: PARTNER_UI_SETTINGS,
      getCountryList: 'getCountryList'
    }),
    ...mapMutations({
      removeAllDiscountCodes: SET_REGISTRATION_DISCOUNTS_REMOVE_ALL,
      setCoverages: SET_REGISTRATION_COVERAGES,
      setInterval: SET_REGISTRATION_INTERVAL,
      setPaymentData: SET_PAYMENT_DATA,
      setPrimaryEmail: SET_REGISTRATION_PRIMARY_EMAIL,
      setRefferal: SET_REGISTRATION_REFFERAL,
      setLeadId: SET_REGISTRATION_LEAD_ID,
      setSpinnerText: SET_SPINNER_TEXT,
      setLoading: SET_LOADING,
      setPartnerPropertyId: SET_PARTNER_PROPERTY_ID
    }),
    getDevices () {
      this.disableSelectDevice = !!this.partnerDevices.find(x => x.brand !== undefined)

      this.partnerDevices.forEach((device, index) => {
        this.setMakeNoUpdate(index, device.brand)
        this.setModelNoUpdate(index, device.model)
        this.setExternalId(index, device.external_id)
        if (device.isAdult !== undefined) this.setIsAdultNoUpdate(index, device.isAdult)
        if (index !== this.partnerDevices.length - 1) {
          this.addPhone()
        }
      })
      this.updateCoveragesInfo()
    },
    async initPlanType () {
      const currentQuery = this.$router.history.current.query
      this.setRegistrationType()

      if (this.isFamily) {
        if (!this.isPartnerWeb) {
          this.addPhone()
        }
      }

      this.savePosition()
      let defaultQuery = {
        ref: this.$route.query.ref,
        lead_id: this.$route.query.lead_id,
        openPlans: this.$route.query.openPlans
      }

      if (!(this.$route.query.interval === 'monthly')) {
        defaultQuery.interval = 'annual'
      } else {
        defaultQuery.interval = this.$route.query.interval
      }
      if (this.$route.query.disableIntervalToggle === 'false') {
        defaultQuery.disableIntervalToggle = this.$route.query.disableIntervalToggle
      } else {
        defaultQuery.disableIntervalToggle = 'true'
      }
      if (!this.isPhoneOnly && !(this.selectedPhones[0].adult)) {
        defaultQuery.student = 'true'
      } else if (!this.isPhoneOnly && this.selectedPhones[0].adult) {
        defaultQuery.adult = 'true'
      }
      if (!this.isPhoneOnly && !(this.$route.query.family === 'true')) {
        defaultQuery.family = 'false'
        this.isFamily = false
      } else if (!this.isPhoneOnly && this.$route.query.family === 'true') {
        defaultQuery.family = this.$route.query.family
      }
      if (this.isFirstStep) {
        defaultQuery.step1 = 'true'
      }
      // PARTNER QUERIES HERE
      // PARTNER PROPERTY ID
      if (this.$route.query.partner_property_id !== undefined) {
        defaultQuery.partner_property_id = this.$route.query.partner_property_id
        this.setPartnerPropertyId(this.$route.query.partner_property_id)
        this.requestPartnerDiscounts(this.$route.query.partner_property_id)
        this.getUiSettings(this.$route.query.partner_property_id)
      }
      if (this.$route.query.is_input !== undefined) {
        defaultQuery.is_input = this.$route.query.is_input
        localStorage.setItem('is_input', this.$route.query.is_input)
      }
      if (this.$route.query.is_promotion !== undefined) {
        defaultQuery.is_promotion = this.$route.query.is_promotion
        localStorage.setItem('is_promotion', this.$route.query.is_promotion)
      }
      if (this.$route.query.name !== undefined) {
        defaultQuery.name = this.$route.query.name
      }

      // DEVICES
      // EXAMPLE OF COMING ARRAY WITH DEVICE OBJECTS
      // [
      // {
      // deviceModelId: 6,
      // model: "iPhone XS",
      // brand: "Apple",
      // cost: 1000,
      // isPrimary: true,
      // phoneNumber: 3213334567,
      // isAdult: true
      // }
      // ]
      // SelectedPlan=akko || phone
      // Interval
      // partner_property_id
      if (this.$route.query.external_id !== undefined) {
        defaultQuery.external_id = this.$route.query.external_id

        localStorage.setItem('external_id', (this.$route.query.external_id))
      }
      if (this.$route.query.devices !== undefined) {
        defaultQuery.devices = this.$route.query.devices
        await this.decryptDevices(this.$route.query.devices)
      }
      if (this.$route.query.showPlans !== undefined) {
        defaultQuery.showPlans = this.$route.query.showPlans
        this.showPlans = this.$route.query.showPlans === 'true'
      }

      if (this.$route.query.step1 === 'false' || this.uiSettings.only_payment_information_checkout) {
        defaultQuery.step1 = 'false'
        this.nextStep()
      }

      if (this.uiSettings.preferred_payment_method === 'CREDIT_DEBIT_CARD') {
        this.isCreditCard = true
        // this.$emit('update:isCreditCard', false)
      } else if (this.uiSettings.preferred_payment_method === 'ACH') {
        this.isCreditCard = false
        // this.$emit('update:isCreditCard', false)
      }
      if (!isEqual(currentQuery, defaultQuery)) {
        this.$router.push({
          query: {
            ...defaultQuery
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    setPrimaryPhone (primaryIndex) {
      this.secondStepDevices.forEach((_, index) => {
        this.secondStepDevices[index].primary = index === primaryIndex
      })
    },
    updateCoveragesInfo () {
      this.setFilteredPhones()
      this.setAnnualCoverages()
      this.setMonthlyCoverages()
      if (this.plans.length > 1 && (!this.isBiggerCoupon)) {
        this.addDiscountCode({code: DISCOUNT_CODE.FAMILY_PLAN})
      }
      let oldSecondStepDevicesLength = this.secondStepDevices.length
      let newDevices = this.plans.map((plan, index) => {
        let indexInBound = index < oldSecondStepDevicesLength
        let primaryStatus = indexInBound ? this.secondStepDevices[index].primary : undefined
        let phoneNumber = indexInBound ? this.secondStepDevices[index].phone : undefined
        return {
          ...plan,
          primary: primaryStatus,
          phone: phoneNumber
        }
      })
      this.secondStepDevices = newDevices
    },
    setFilteredPhones () {
      this.filteredPhones = this.selectedPhones.filter(({make, model}) => make && model)
      this.filteredPhones = this.filteredPhones.map((phone) => {
        return {...phone, ...this.getIndividualCoverage(phone)}
      })
    },
    individualCoverageAkkoPlan (adult, multiDiscount) {
      const basePlan = this.basePlans[adult ? 'adult' : 'student'][this.$route.query.interval]
      return {
        price: basePlan.price * multiDiscount,
        individualCoverageName: basePlan.name,
        priceWithoutDiscount: basePlan.price,
        multiPlanDiscountAmount: basePlan.price - (basePlan.price * multiDiscount)
      }
    },
    savePosition () {
      const scrollOffset = {
        x: document.documentElement.scrollTop,
        y: document.documentElement.scrollLeft
      }
      localStorage.setItem('scrollPosition', scrollOffset)
    },
    setRegistrationType () {
      let registrationType = 'MULTI_PHONE'
      if (!this.isPhoneOnly) {
        registrationType = this.isFamily ? 'FAMILY_PLAN' : 'SOLO'
      }
      this.$store.commit(SET_REGISTRATION_TYPE, REGISTRATION_TYPE[registrationType])
    },
    setIsFamily () {
      if (this.isPhoneOnly) return

      this.isFamily = this.selectedPhones.length > 1

      if ((this.isFamily && this.$route.query.family === 'true') ||
        (!this.isFamily && this.$route.query.family === 'false')) {
        return
      }

      this.savePosition()
      this.$router.push({
        query: {
          ...this.$route.query,
          family: this.isFamily
        }
      }).catch(err => {
        console.log(err)
      })
    },
    setMonthly (value) {
      this.savePosition()
      const intervalName = value ? 'monthly' : 'annual'
      this.$router.push({
        query: {
          ...this.$route.query,
          interval: intervalName
        }
      }).catch(err => {
        console.log(err)
      })
      if (this.monthlyToggle !== value) {
        trackOnMixpanel(MIXPANEL_EVENTS.TOGGLE_PLAN_INTERVAL, { 'Plan Interval': intervalName })
      }
      this.monthlyToggle = value

      this.updateCoveragesInfo()
    },
    setIsPhoneOnly (isPhone, event) {
      event.preventDefault()

      const pathName = isPhone ? 'phone' : 'akko'

      this.savePosition()
      this.$router.push({
        path: pathName,
        query: this.setQuery(!isPhone, this.selectedPhones[0].adult)
      }).catch(err => {
        console.log(err)
      })

      if (this.isPhoneOnly !== isPhone) {
        trackOnMixpanel(MIXPANEL_EVENTS.TOGGLE_PLAN_TYPE, { 'Plan Type': pathName })
      }
      this.isPhoneOnly = isPhone
      this.setIsFamily()
      this.setRegistrationType()

      this.updateCoveragesInfo()
    },
    setIsAdult (isAdult, event, index) {
      event.preventDefault()

      if (!index) {
        this.savePosition()
        this.$router.push({query: this.setQuery(true, isAdult)}).catch(err => {
          console.log(err)
        })
      }

      if (this.selectedPhones[index].adult !== isAdult) {
        trackOnMixpanel(MIXPANEL_EVENTS.TOGGLE_IS_ADULT, { 'Is Adult': isAdult, 'Device Index': index })
      }
      this.$set(this.selectedPhones[index], 'adult', isAdult)

      this.updateCoveragesInfo()
    },
    setIsAdultNoUpdate (index, isAdult) {
      this.$set(this.selectedPhones[index], 'adult', isAdult)
    },
    setQuery (isAkkoPlan, isAdult) {
      let query = {
        ...this.$route.query,
        adult: isAkkoPlan && isAdult ? true : undefined,
        student: isAkkoPlan && !isAdult ? true : undefined,
        family: isAkkoPlan ? this.isFamily : undefined,
        step1: true
      }
      return query
    },
    getIndividualCoverage ({make, model, adult}) {
      let coverageInfo = {}
      const coverageId = this.monthlyToggle ? 'monthly_coverage_id' : 'annual_coverage_id'
      this.multiplan = this.filteredPhones.length > 1 && !this.isBiggerCoupon
      const multiDiscount = this.multiplan ? 0.95 : 1

      if (this.isPhoneOnly) {
        const phone = this.getModelData(make, model)
        const phoneCoverage = this.coverageOptions.find(({id}) =>
          id === phone[coverageId])

        coverageInfo = {
          price: phoneCoverage ? phoneCoverage.price * multiDiscount : 0,
          individualCoverageName: phoneCoverage.name,
          priceWithoutDiscount: phoneCoverage.price,
          multiPlanDiscountAmount: phoneCoverage.price - (phoneCoverage.price * multiDiscount)
        }
      } else {
        coverageInfo = this.individualCoverageAkkoPlan(adult, multiDiscount)
      }
      return coverageInfo
    },
    setModel (index, model) {
      if (this.$data.selectedPhones[index].model !== model && model !== '') {
        trackOnMixpanel(MIXPANEL_EVENTS.SELECT_PHONE_MODEL, { 'Phone Model': model, 'Device Index': index })
      }
      this.$set(this.$data.selectedPhones[index], 'model', model)
      this.$set(this.$data.existsData, index, model !== '')
      this.updateCoveragesInfo()
    },
    setMake (index, make) {
      if (this.$data.selectedPhones[index].make !== make && make !== '') {
        trackOnMixpanel(MIXPANEL_EVENTS.SELECT_PHONE_MAKE, { 'Phone Make': make, 'Device Index': index })
      }
      this.$set(this.selectedPhones[index], 'make', make)
      this.setModel(index, '')
      this.updateCoveragesInfo()
    },
    setModelNoUpdate (index, model) {
      this.$set(this.$data.selectedPhones[index], 'model', model)
      this.$set(this.$data.existsData, index, model !== '')
    },
    setMakeNoUpdate (index, make) {
      this.$set(this.selectedPhones[index], 'make', make)
      this.setModelNoUpdate(index, '')
    },
    setExternalId (index, externalId) {
      this.$set(this.selectedPhones[index], 'external_id', externalId)
    },
    async submit () {
      if (!this.hasAgreed) return

      this.setCoverages(this.secondStepDevices)
      this.setInterval(this.monthlyToggle ? PLAN_INTERVALS.MONTHLY : PLAN_INTERVALS.ANNUAL)

      localStorage.setItem('scrollPosition', {y: 0, x: 0})
      this.$store.commit('SET_LOADING', true)
      window.fbq('trackCustom', 'Clicked Register')

      if (this.isCreditCard) {
        const token = await this.$refs.cardForm.createToken()

        if (!token) {
          this.$store.commit('SET_LOADING', false)
          return
        } else {
          this.setPaymentData({
            accountMetadata: this.paymentData.accountMetadata,
            'stripe_token': token,
            'card_first_name': this.cardFirstName,
            'card_last_name': this.cardLastName,
            postal_code: this.postalCode,
            method: 'CREDIT_DEBIT_CARD'
          })
        }
      }
      this.setPrimaryEmail(this.email)
      this.setRefferal(this.$route.query.ref)
      this.setLeadId(this.$route.query.lead_id)

      this.submitRegistration()
      this.$store.commit('SET_LOADING', false)
    },
    nextStep () {
      const interval = this.monthlyToggle ? PLAN_INTERVALS.MONTHLY : PLAN_INTERVALS.ANNUAL
      trackOnMixpanel(MIXPANEL_EVENTS.CLICK_PROCEED_TO_CHECKOUT, {
        'Plan Interval': interval,
        'Plan Type': this.isPhoneOnly ? 'phone' : 'akko',
        'Selected Phones': this.$data.selectedPhones
      })
      this.setCoverages(this.plans)
      this.setInterval(interval)
      this.secondStepDevices = this.plans
      this.isFirstStep = false
      this.$router.push({
        query: {
          ...this.$route.query,
          step1: 'false'
        }
      }).catch(err => {
        console.log(err)
      })
    },
    addPhone () {
      if (this.partnerDevices.length === this.selectedPhones.length) {
        return
      }
      trackOnMixpanel(MIXPANEL_EVENTS.ADD_DEVICE)
      this.$data.selectedPhones.push({make: '', model: '', adult: true, id: this.phonesId})
      this.phonesId++
      this.existsData.push(false)

      this.setIsFamily()
    },
    removePhone (index, event) {
      event.preventDefault()

      trackOnMixpanel(MIXPANEL_EVENTS.REMOVE_DEVICE)
      this.$data.selectedPhones.splice(index, 1)
      this.$data.existsData.splice(index, 1)

      this.updateCoveragesInfo()
      this.setIsFamily()
    },
    setMonthlyCoverages () {
      if (this.isPartnerId) {
        this.selectedMonthlyCoverages = (
          this.filteredPhones.map(({
            make,
            model,
            adult,
            external_id
          }) => this.getCoverageData(make, model, adult, 'monthly_coverage_id', external_id, true))
        )
      } else {
        this.selectedMonthlyCoverages = (
          this.filteredPhones.map(({
            make,
            model,
            adult
          }) => this.getCoverageData(make, model, adult, 'monthly_coverage_id', false, true))
        )
      }
    },
    setAnnualCoverages () {
      if (this.isPartnerId) {
        this.selectedAnnualCoverages = (
          this.filteredPhones.map(({
            make,
            model,
            adult,
            external_id
          }) => this.getCoverageData(make, model, adult, 'annual_coverage_id', external_id, false))
        )
      } else {
        this.selectedAnnualCoverages = (
          this.filteredPhones.map(({
            make,
            model,
            adult
          }) => this.getCoverageData(make, model, adult, 'annual_coverage_id', false, false))
        )
      }
    },
    filterModelsBySelectedMake (make) {
      return new Set(this.$store.state.allPhoneModels.filter(makeModel => makeModel.make === make)
        .sort((a, b) => {
          if (a.display_order && b.display_order) {
            return a.display_order - b.display_order
          } else if (a.display_order && !b.display_order) {
            return 1
          } else if (!a.display_order && b.display_order) {
            return -1
          } else {
            return a.make.localeCompare(b.make)
          }
        }).map(makeModel => makeModel.model))
    },
    getModelData (wantedMake, wantedModel) {
      return this.$store.state.allPhoneModels.find(({make, model}) =>
        make === wantedMake && model === wantedModel)
    },
    getCoverageData (make, model, isAdult, coverageId, externalId, isMonthly) {
      const phone = this.getModelData(make, model)
      if (!phone) return phone
      const extraInfo = this.coverageOptions.find(({id}) => {
        if (this.isPhoneOnly) {
          return id == phone[coverageId]
        } else {
          if (isMonthly) {
            if (isAdult) {
              return id == process.env.VUE_APP_ADULT_MONTH_PLAN
            } else {
              return id == process.env.VUE_APP_STUDENT_MONTH_PLAN
            }
          } else {
            if (isAdult) {
              return id == process.env.VUE_APP_ADULT_ANNUAL_PLAN
            } else {
              return id == process.env.VUE_APP_STUDENT_ANNUAL_PLAN
            }
          }
        }
      })
      if (externalId) {
        return {
          make,
          model,
          external_id: externalId,
          ...extraInfo
        }
      } else {
        return {
          make,
          model,
          ...extraInfo
        }
      }
    }
  }
}
</script>

<style scoped>
.circle {
  position: fixed;
  background-color: var(--theme-pink);
  width: 700px;
  height: 700px;
  border-radius: 50%;
  left: -300px;
  top: -350px;
  z-index: -1
}

.visibility {
  display: none;
}

.w-65 {
  width: 65%;
}

.w-35 {
  width: 35%;
}

.responsive {
  display: flex;
  flex-direction: row;
  margin-top: 6em;
}

.main-container {
  width: 93%;
}

@media (max-width: 1280px) {
  .responsive {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .responsive-box {
    width: 80%;
    margin: 2em 0;
  }

  .main-container {
    width: 90%;
  }

  .responsive-choose-plan {
    width: 100%;
    align-items: center;
  }

  .devices-div {
    width: inherit;
    max-width: 850px;
  }
}

@media (max-width: 1000px) {
  .main-container {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .devices-div {
    width: inherit;
    max-width: 600px;
  }
}

@media (max-width: 600px) {
  .responsive {
    margin-top: 5em;
    width: 100%;
  }

  .responsive-box {
    width: 100%;
    margin: 1em 0;
  }
}

@media (max-width: 344px) {
  .devices-div {
    width: inherit;
    min-width: 312px;
  }
}

</style>
