<template>
  <main>
    <div
      v-if="!userXPIsWeb && (userXPIsAll || userXPIsMobile)"
      class="mv3"
    >
      <ak-alert
        class="font-size-responsive inter br-pill "
        color="red"
      >
        Looking to add an item to your account? Download our mobile app on iOS or Android! Please <a href="`/contact`">
          contact support</a> if you have any
        questions.
        <div class="flex-apps">
          <a
            class="flex btn-ios btn-container"
            href="https://akko.app/home"
          >
            <img
              alt="Download on the iOS App Store"
              class="btn"
              src="@/assets/download-app/download-on-the-app-store-logo.png"
            >
          </a>

          <a
            class="flex btn-container android"
            href="https://akko.app/home"
          >
            <img
              alt="Download on the Google PlayStore"
              class="btn"
              src="@/assets/download-app/download-on-google-store.png"
            >
          </a>
        </div>
      </ak-alert>
    </div>

    <div class="height flex items-center justify-between">
      <span class="f2-l f3 lh-copy mt4-l mt2 b">Your Items</span>
      <!-- <div class="">
        <ak-button v-if="!userXPIsMobile && (userXPIsWeb || userXPIsAll)" block class="mw5-ns fr" primary
                   @click.native="addDevice">Add New Item
        </ak-button>
      </div> -->
    </div>
    <div class="flex flex-row-l flex-column mt1">
      <div class="mv3-l  w-100-ns">
        <ak-search v-model="searchParam" />
      </div>
      <div class="mv3-l mt2 w-100-ns">
        <ak-button
          v-if="!userXPIsMobile && (userXPIsWeb || userXPIsAll)"
          block
          class="mw5-l fr"
          primary
          @click.native="addDevice"
        >
          Add New Item
        </ak-button>
      </div>
    </div>
    <div v-if="!userHasAccess">
      <ak-alert
        v-if="user.membership_status === 'CANCELED'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        >please contact our billing
          team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'UNPAID'"
        color="red"
      >
        <span>Your coverage plan is currently unpaid. Please proceed to <router-link
          class="color-theme"
          to="/account/dashboard"
        >Account Settings</router-link> to resolve or </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'PAST_DUE'"
        color="red"
      >
        <span>Your coverage plan is currently past due. Please proceed to <router-link
          class="color-theme"
          to="/account/dashboard"
        >Account Settings</router-link> to resolve or </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        >contact our billing team</a>.
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'BANNED'"
        color="red"
      >
        <span>Your account has been banned. If you think this was done in error, </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Inquiry Regarding Banned Account"
        >please contact
          our billing team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INACTIVE'"
        color="red"
      >
        <span>Your coverage plan was cancelled. If you'd like to re-enable your coverage, </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        >please contact our billing
          team.</a>
      </ak-alert>

      <ak-alert
        v-if="user.membership_status === 'INVALID_USER'"
        color="red"
      >
        <span>Your account has been marked as invalid by our system due to unpaid premiums or other account issues. If you think this was done in error, </span>
        <a
          class="color-theme"
          href="mailto:billing@getakko.com?Subject=Re-enable my plan"
        >please contact our billing
          team.</a>
      </ak-alert>
    </div>

    <div
      v-if="$store.state.messages.length"
      class="mv4"
    >
      <ak-alert
        v-for="(message, index) in $store.state.messages"
        :key="index"
        :color="message.color"
        :deleteable="message.deleteable"
        @close="clearMessage(index)"
      >
        <span>{{ message.text }}</span>
      </ak-alert>
    </div>



    <div
      v-if="devices.length > 0 && userHasAccess"
      class="flex flex-wrap content justify-start"
    >
      <div
        v-for="(device, index) in devices"
        :key="index"
        class="card-width pa3 bg-white account-card mb4-l mb4-m pointer mr3"
      >
        <router-link
          :to="`/devices/${device.id}`"
          class="no-underline black"
        >
          <ak-device-tile
            :id="device.id"
            :brand="device.brand"
            :model="device.model"
            :nickname="device.nickname ? device.nickname : `Item ${index+1}`"
            :serial="device.serial_number"
            :type="device.type"
          />
        </router-link>
      </div>
    </div>
    <!-- ALL WAY TO DISPLAY WITH GRID -->
    <!-- <div v-if="devices.length > 0 && userHasAccess" class="flex flex-wrap justify-between mt1 mb6 mb4-ns device-table">
      <div v-for="(device, index) in this.devices" :key="index" class="device-tile">
        <router-link :to="`/devices/${device.id}`" class="no-underline black">
          <ak-device-tile
            :id="device.id"
            :brand="device.brand"
            :model="device.model"
            :nickname="device.nickname ? device.nickname : `Item ${index+1}`"
            :serial="device.serial_number"
            :type="device.type"/>
        </router-link>
      </div>
    </div> -->

    <!-- <ak-fab v-if="userHasAccess" @click.native="addDevice" /> -->
  </main>
</template>

<script>
import AkButton from './Button'
import AkAlert from './Alert'
import AkDeviceTile from './DeviceTile'
import AkSearch from './Search'

export default {
  name: 'AkDevicesPage',
  components: {
    AkButton,
    AkAlert,
    AkDeviceTile,
    AkSearch
  },
  data () {
    return {
      searchParam: ''
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    devices () {
      return this.$store.getters.getDevicesByQuery(this.searchParam)
    },
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },
    userHasAccess () {
      const status = this.user.membership_status
      return status !== 'BANNED' && status !== 'CANCELED' && status !== 'UNPAID' && status !== 'PAST_DUE' && status !== 'INACTIVE' && status !== 'INVALID_USER'
    },
    userXPIsWeb () {
      return this.userHasAccess && this.user.user_experience_path === 'web'
    },
    userXPIsAll () {
      return this.userHasAccess && this.user.user_experience_path === 'all'
    },
    userXPIsMobile () {
      return this.userHasAccess && this.user.user_experience_path === 'mobile'
    }
  },
  mounted () {
    this.$store.dispatch('getDevices')
  },
  methods: {
    clearMessage (index) {
      this.$store.commit('DELETE_MESSAGE', index)
    },
    addDevice () {
      this.$router.push('/add-device-category')
    }
  }
}
</script>

//TODO: Remove unused flex box classes
<style scoped>

.flex-apps {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.justify-evenly {
  justify-content: space-evenly;
}


.card-width {
  width: 23%;
  height: 155px;
}

.account-card {
  border-radius: 5px;
  border: 1px solid #E8E8E8;
}

.height {
  height: 50px;
}

.btn {
  height: 50px;
}

.btn-container {
  margin: 1rem;
}

.btn-ios {
  margin-left: 0;
}

i {
  width: 20%;
  text-align: center;
  margin-right: 7px;
}

@media (max-width: 1280px) {
  .account-card {
    width: 31%
  }


}

@media (max-width: 1100px) {
  .account-card {
    width: 47%
  }

  .card-width {
    height: auto;
  }
}


@media (max-width: 768px) {
  .account-card {
    width: 100%;
    border: none;
    border-bottom: 1px solid #E8E8E8;
    border-radius: 0px;
    margin-bottom: 0px;
    padding: 1.5rem 0rem;
  }

  .flex-apps {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .btn {
    height: unset;
    width: 170px
  }

  .btn-container {
    margin: .25rem 0;
  }

  .btn-ios {
    margin: 1rem 0 .25rem 0;
  }

  .font-size-responsive {
    font-size: 1.1rem;
  }

}


.device-table {
  gap: 1rem 0.25rem;
}

.device-tile {
  flex-basis: 100%;
}

@media (max-width: 768px) {

  .height {
    height: auto;
  }

}

@media screen and (min-width: 64em) {
  .device-tile {
    flex-basis: 32%;
  }
}

</style>
