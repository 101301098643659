import { render, staticRenderFns } from "./AddPhoneOnlyPlan.vue?vue&type=template&id=65415a4a&scoped=true"
import script from "./AddPhoneOnlyPlan.vue?vue&type=script&lang=js"
export * from "./AddPhoneOnlyPlan.vue?vue&type=script&lang=js"
import style0 from "./AddPhoneOnlyPlan.vue?vue&type=style&index=0&id=65415a4a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65415a4a",
  null
  
)

export default component.exports