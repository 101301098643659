<template>
  <div class="pa4 bl b-theme bw3 br20 bg-white hover-lift shadow-theme">
    <div class="f4">
      <img
        :src="icon"
        alt=""
        class="dib h2 v-mid mr2"
      >
      <router-link
        :to="`/devices/${id}`"
        class="color-theme no-underline"
      >
        {{ nickname }}
      </router-link>
    </div>
    <div class="mv3">
      <span class="v-mid ttc">{{ brand }} - {{ model.trim() === '' ? type : model }}</span>
    </div>
    <div v-if="serial">
      <div class="f6 gray">
        Serial Number:
      </div>
      {{ serial }}
    </div>
    <div
      v-else
      class="f6 gray"
    >
      No serial number
    </div>
    <div
      v-if="featureFlagMultiLossTypeActive"
      class="mv3"
    >
      <div class="f6 gray">
        Loss Types
      </div>
      <small
        v-for="loss_type in lossTypes"
        :key="loss_type.id"
        class="loss-type-label"
      >
        <span v-if="loss_type.name == 'Other'">{{ loss_type.loss_type_category }} - </span>{{ loss_type.name }}
      </small>
    </div>
    <div
      v-if="featureFlagMultiLossTypeActive"
      class="mv3"
    >
      <div class="f6 gray">
        Previous Damage
      </div>
      {{ knownDamage }}
    </div>
  </div>
</template>

<script>

import { GET_FEATURE_FLAG } from '@/store/index'
import { MULTI_LOSS_TYPE } from '@/constants/feature_flag'

export default {
  name: 'AkClaimDeviceTile',
  props: {
    brand: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    nickname: {
      type: String,
      required: true
    },
    serial: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    lossTypes: {
      type: Array,
      default: () => [],
      required: false
    },
    knownDamage: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {}
  },
  computed: {
    icon () {
      const path = '@/assets/appicons/'
      /* Electronics */
      if (this.type === 'phone') {
        return require(path + 'Phone.svg')
      }
      if (this.type === 'tablet') {
        return require(path + 'Tablet.svg')
      }
      if (this.type === 'laptop') {
        return require(path + 'Laptop.svg')
      }
      if (this.type === 'desktop') {
        return require(path + 'Desktop.svg')
      }
      if (this.type === 'smartwatch') {
        return require(path + 'Smartwatch.svg')
      }
      if (this.type === 'tv') {
        return require(path + 'TV.svg')
      }
      if (this.type === 'gaming console') {
        return require(path + 'GameController.svg')
      }
      if (this.type === 'gaming handheld') {
        return require(path + 'GameController.svg')
      }
      if (this.type === 'streaming device') {
        return require(path + 'TV.svg')
      }
      /* Accessories */
      if (this.type === 'camera') {
        return require(path + 'Camera.svg')
      }
      if (this.type === 'sunglasses') {
        return require(path + 'Sunglasses.svg')
      }
      if (this.type === 'glasses') {
        return require(path + 'Sunglasses.svg')
      }
      if (this.type === 'backpack') {
        return require(path + 'Backpack.svg')
      }
      if (this.type === 'watch') {
        return require(path + 'Watch.svg')
      }
      /* Audio */
      if (this.type === 'headphones') {
        return require(path + 'Headphone.svg')
      }
      if (this.type === 'speakers') {
        return require(path + 'Speaker.svg')
      }
      if (this.type === 'professional audio equipment') {
        return require(path + 'AudioEquipment.svg')
      }
      if (this.type === 'dj equipment') {
        return require(path + 'DJEquipment.svg')
      }
      /* Photography */
      if (this.type === 'lens') {
        return require(path + 'CameraLens.svg')
      }
      if (this.type === 'camera accessory') {
        return require(path + 'Camera.svg')
      }
      /* Transport */
      if (this.type === 'bicycle') {
        return require(path + 'Bicycle.svg')
      }
      if (this.type === 'electric bike') {
        return require(path + 'Bicycle.svg')
      }
      if (this.type === 'skateboard') {
        return require(path + 'Skateboard.svg')
      }
      if (this.type === 'electric skateboard') {
        return require(path + 'Skateboard.svg')
      }
      if (this.type === 'scooter') {
        return require(path + 'Skateboard.svg')
      }
      if (this.type === 'electric scooter') {
        return require(path + 'Skateboard.svg')
      }
      /* Appliances */
      if (this.type === 'microwave') {
        return require(path + 'Microwave.svg')
      }
      /* Sports */
      if (this.type === 'golf clubs') {
        return require(path + 'GolfClubs.svg')
      }
      if (this.type === 'skiis') {
        return require(path + 'Skiis.svg')
      }
      if (this.type === 'snowboard') {
        return require(path + 'Snowboard.svg')
      }
      if (this.type === 'surfboard') {
        return require(path + 'Surfboard.svg')
      }
      return ''
    },
    featureFlagMultiLossTypeActive () {
      const feature = this.$store.getters[GET_FEATURE_FLAG](MULTI_LOSS_TYPE)
      return feature ? feature.state : false
    }
  }
}
</script>

<style>
</style>
