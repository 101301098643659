<template>
  <div
    :class="customClass"
    class="flex flex-row tc"
  >
    <label
      v-if="title"
      class="cera-round-pro fw6 db title"
    >
      {{ title }}
    </label>
    <div class="flex flex-row tc toggle-row">
      <input
        :id="toggleName + '1'"
        :checked="toggleValue"
        :class="disabled ? 'no-pointer': ''"
        :disabled="disabled"
        :name="toggleName"
        class="dn br3"
        type="radio"
        @input="setFirstValue"
      >
      <label
        :class="[customTextClass, disabled ? '': 'pointer']"
        :for="toggleName + '1'"
        class="inter f6 pa2 tc b buttonUnselected left"
        tabindex="0"
      >
        {{ firstOption }}
      </label>
      <input
        :id="toggleName + '2'"
        :checked="!toggleValue"
        :disabled="disabled"
        :name="toggleName"
        class="dn"
        type="radio"
        @input="setSecondValue"
      >
      <label
        :class="[customTextClass, disabled ? '': 'pointer']"
        :for="toggleName + '2'"
        class="inter f6 pa2 tc b buttonUnselected right"
        tabindex="0"
      >
        {{ secondOption }}
      </label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AkPlanTypeToggle',
  props: {
    toggleValue: {
      type: Boolean,
      default: true
    },
    setValue: Function,
    firstOption: String,
    secondOption: String,
    toggleName: String,
    phoneIndex: {
      type: Number,
      default: 0
    },
    customClass: {
      type: String,
      default: ''
    },
    customTextClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setFirstValue (event) {
      this.setValue(true, event, this.phoneIndex)
    },
    setSecondValue (event) {
      this.setValue(false, event, this.phoneIndex)
    }
  }
}
</script>

<style scoped>
.buttonUnselected {
  background-color: white;
  border: 1px solid var(--theme-medium-gray);
  border-radius: 5px;
  color: var(--theme-gray-text);
  min-width: 6em;
  z-index: 0;
}

.right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
  margin-left: -2px;
}

.left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}

input[type=radio]:checked + label {
  background-color: var(--theme-pink);
  border-color: var(--theme-primary);
  color: var(--theme-primary);
  border-radius: 5px;
  border-width: 1px;
  z-index: 1;
}

.title {
  font-size: 20px;
}

@media (max-width: 700px) {
  .title {
    margin-bottom: 1em;
  }
}

@media (max-width: 650px) {
  .toggle-row {
    margin-top: 1rem;
  }
}

@media (max-width: 500px) {
  .buttonUnselected {
    font-size: 0.6em;
  }
}
</style>
