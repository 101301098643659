<template>
  <div>
    <upgrade-plan-card
      :title="titlePlusPlan"
      :subtitle="subtitlePlusPlan"
      :description="descriptionPlusPlan"
      :price="999"
      :plan-data="homePlusPlanDetail"
      :plan-cost-difference="planCostDifference"
      :plan-deductible-range="deductibleRangePlusPlan"
    />
    <div class="pa3 flex-ns flex-wrap justify-end">
      <div class="order-1 mb3 mb0-ns">
        <ak-button
          block
          primary
          @click.native="debounceMethod(() => updateCoverage(coverageUpgrade.id))"
        >
          Upgrade Now
        </ak-button>
      </div>
      <div class="mr3-ns">
        <ak-button
          block
          secondary
          @click.native="resetState"
        >
          Cancel
        </ak-button>
      </div>
    </div>
  </div>
</template>

<script>
import UpgradePlanCard from './UpgradePlanCard'
import AkButton from '../Button'
import _ from 'lodash'

export default {
  name: 'AkUpgradeHomePlusPlan',
  components: {
    UpgradePlanCard,
    AkButton
  },
  props: {
    coverageUpgrade: {
      type: Object,
      default: () => {}
    },
    updateCoverage: {
      type: Function,
      default: () => {}
    },
    resetState: {
      type: Function,
      default: () => {}
    },
    currentCoveragePrice: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      titlePlusPlan: this.$i18n.t('planssettings.homeplusplan.plan2Upgrade'),
      subtitlePlusPlan: this.$i18n.t('planssettings.homeplusplan.select'),
      descriptionPlusPlan: this.$i18n.t('planssettings.homeplusplan.description'),
      deductibleRangePlusPlan: this.$i18n.t('planssettings.homeplusplan.rangeofdeductibles'),
      homePlusPlanDetail: [
        this.$i18n.t('planssettings.homeplusplan.feat1'),
        this.$i18n.t('planssettings.homeplusplan.feat2'),
        this.$i18n.t('planssettings.homeplusplan.feat3'),
        this.$i18n.t('planssettings.homeplusplan.feat4'),
        this.$i18n.t('planssettings.homeplusplan.feat5'),
        this.$i18n.t('planssettings.homeplusplan.feat6')
      ]
    }
  },
  computed: {
    planCostDifference () {
      return (this.coverageUpgrade.price - this.currentCoveragePrice).toFixed(2)
    }
  },
  methods: {
    debounceMethod: _.debounce(function (e) {
      e()
    }, 3000, {'leading': true})
  }
}
</script>

<style scoped>
.card-margin {
  margin: auto;
}
</style>
