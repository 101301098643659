<template>
  <div class="main">
    <!-- Mobile -->
    <div
      class="flex flex-row items-center mobile-flex pl2 pr2 pb2"
      @click="goBack"
    >
      <arrow-back1 />
      <h3 class="ml3 f4 cera-round-pro">
        {{ $t('accountsettings.personalinformationheader', {personalinformationheader: 'Personal Information'}) }}
      </h3>
    </div>
    <div
      v-if="!showNameModal"
      class="pv2 ph3 mv2 mh0 flex flex-column justify-center items-start mobile-row"
    >
      <div class="flex flex-column">
        <span class="silver-element">{{ $t('accountsettings.legalname', {legalname: 'Legal Name'}) }}</span>
      </div>
      <div class="flex flex-row justify-between w-100">
        <span
          :class="{ 'modal-opened-row-text': showNameModal }"
          class="data"
        >{{ `${user.first_name} ${user.last_name}` }}
        </span>
        <span
          class="color-theme pointer data"
          @click="openNameModal"
        >{{ $t('edit.edit', {edit: 'Edit'}) }}</span>
      </div>
    </div>

    <!--      Edit name desktop-->
    <div
      v-show="showNameModal"
      class="row-edit mv2 desktop modal-opened-row"
    >
      <span class="elementLeft modal-opened-row-text">{{
        $t('accountsettings.editlegalname', {editlegalname: 'Edit Legal Name'})
      }}</span>
      <div class="modal w-75">
        <form
          slot="modal-body"
          class="flex-column"
        >
          <div class="mt3 mb3 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="first_name"
            >{{ $t('accountsettings.firstname', {firstname: 'FIRST NAME'}) }}</label>
            <input
              id="first_name"
              v-model="first_name"
              v-focus
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
            >
          </div>

          <div class="mt3 mb2 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="last_name"
            >{{
              $t('accountsettings.lastname', {lastname: 'LAST NAME'})
            }}</label>
            <input
              id="last_name"
              v-model="last_name"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
            >
          </div>
        </form>

        <div
          slot="modal-footer"
          class="pa3 flex flex-row justify-between justify-end-ns"
        >
          <div class="mr3 flex-grow flex-grow-off-ns">
            <ak-button
              block
              class="modal-button inter fw6 cancel"
              secondary
              @click.native="resetState"
            >
              {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
            </ak-button>
          </div>
          <div class="flex-grow flex-grow-off-ns">
            <ak-button
              :disabled="!canUpdateName"
              block
              class="modal-button inter fw6 save"
              primary
              @click.native="updateName"
            >
              {{ $t('edit.save', {save: 'Save'}) }}
            </ak-button>
          </div>
        </div>
      </div>
    </div>

    <!--      Edit name Mobile-->
    <ak-modal
      v-if="showNameModal"
      class="modal flex-row mobile w-75"
      title="Edit your name"
      @close="resetState"
    >
      <form
        slot="modal-body"
        class="flex-column mh3 mt4"
      >
        <div class="mb3">
          <span>{{ $t('accountsettings.editlegalname', {editlegalname: 'Edit Legal Name'}) }}</span>
        </div>
        <div class="mt3 mb2 flex-grow mh3-ns">
          <label
            class="f6 b db mb2 label"
            for="first_name"
          >{{ $t('accountsettings.firstname', {firstname: 'FIRST NAME'}) }}</label>
          <input
            id="first_name"
            v-model="first_name"
            v-focus
            class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
            type="text"
          >
        </div>

        <div class="mt3 mb3 flex-grow mh3-ns">
          <label
            class="f6 b db mb2 label"
            for="last_name"
          >{{
            $t('accountsettings.lastname', {lastname: 'LAST NAME'})
          }}</label>
          <input
            id="last_name"
            v-model="last_name"
            class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
            type="text"
          >
        </div>
      </form>

      <div
        slot="modal-footer"
        class="flex mh4 flex-row pb3 justify-between"
      >
        <div class="w-48">
          <ak-button
            block
            class="modal-button inter"
            secondary
            @click.native="resetState"
          >
            {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
          </ak-button>
        </div>
        <div class="w-48">
          <ak-button
            :disabled="!canUpdateName"
            block
            class="modal-button inter"
            primary
            @click.native="updateName"
          >
            {{ $t('edit.save', {save: 'Save'}) }}
          </ak-button>
        </div>
      </div>
    </ak-modal>

    <hr class="line mh3">

    <div
      v-show="!isEditAddress"
      class="pv2 ph3 mv2 mh0 flex flex-column justify-center items-start mobile-row"
    >
      <div class="flex flex-column">
        <span class="silver-element">{{ $t('accountsettings.address', {address: 'Address'}) }}</span>
      </div>
      <div class="flex flex-row justify-between w-100">
        <span class="data">{{ user.street }},
          {{ user.apt }} {{ user.city }}, {{ user.state }} {{ user.zip }}</span>
        <span
          class="color-theme pointer data"
          @click="isEditAddress = true"
        >{{
          $t('edit.edit', {edit: 'Edit'})
        }}</span>
      </div>
    </div>

    <!--      Edit Address desktop-->
    <div
      v-show="isEditAddress"
      class="row-edit desktop mv2 modal-opened-row-address"
    >
      <span class="elementLeft modal-opened-row-text">{{
        $t('accountsettings.editaddress', {editaddress: 'Edit your address'})
      }}</span>
      <div class="modal w-75">
        <div
          slot="modal-body"
          class="flex-column"
        >
          <div class="mt3 mb3 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="first_name"
            >{{
              $t('accountsettings.street', {street: 'STREET'})
            }}</label>
            <input
              id="street"
              v-model="street"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
              @change="updateAddress"
            >
          </div>

          <div class="mv2 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="last_name"
            >{{ $t('accountsettings.unit', {unit: 'UNIT'}) }} #</label>
            <input
              id="apt"
              v-model="apt"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
            >
          </div>


          <div class="mv2 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="last_name"
            >{{ $t('accountsettings.city', {city: 'CITY'}) }}</label>
            <input
              id="city"
              v-model="city"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
            >
          </div>


          <div class="mv2 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="last_name"
            >{{ $t('accountsettings.state', {state: 'STATE / PROVINCE / TERRITORY'}) }}</label>
            <input
              id="state"
              v-model="state"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
            >
          </div>

          <div class="mv2 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="last_name"
            >{{ $t('accountsettings.zip', {zip: 'ZIP CODE'}) }}</label>
            <input
              id="zip"
              v-model="zip"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
            >
          </div>
        </div>

        <div
          slot="modal-footer"
          class="pa3 inter flex flex-row justify-between justify-end-ns"
        >
          <div class="mr3 flex-grow flex-grow-off-ns">
            <ak-button
              block
              class="modal-button inter fw6 cancel"
              secondary
              @click.native="resetState"
            >
              {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
            </ak-button>
          </div>
          <div class="flex-grow flex-grow-off-ns">
            <ak-button
              :disabled="!canUpdateAddress"
              block
              class="modal-button inter fw6 save"
              primary
              @click.native="changeAddress"
            >
              {{ $t('edit.save', {save: 'Save'}) }}
            </ak-button>
          </div>
        </div>
      </div>
    </div>

    <!--      Edit Address Mobile-->
    <div
      v-show="isEditAddress"
      class="mobile mh3 mt3"
    >
      <div class="modal">
        <div class="mb3">
          <span>{{ $t('accountsettings.editaddress', {editaddress: 'Edit your address'}) }}</span>
        </div>
        <div
          slot="modal-body"
          class="flex-column"
        >
          <div class="mt2 mb3 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="first_name"
            >{{
              $t('accountsettings.street', {street: 'STREET'})
            }}</label>
            <input
              id="street"
              v-model="street"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
              @change="updateAddress"
            >
          </div>

          <div class="mv2 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="last_name"
            >{{ $t('accountsettings.unit', {unit: 'UNIT'}) }} #</label>
            <input
              id="apt"
              v-model="apt"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
            >
          </div>

          <div class="mv2 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="last_name"
            >{{ $t('accountsettings.city', {city: 'CITY'}) }}</label>
            <input
              id="city"
              v-model="city"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
            >
          </div>

          <div class="mv2 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="last_name"
            >{{ $t('accountsettings.state', {state: 'STATE / PROVINCE / TERRITORY'}) }}</label>
            <input
              id="state"
              v-model="state"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="text"
            >
          </div>

          <div class="mt2 mb3 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="last_name"
            >{{ $t('accountsettings.zip', {zip: 'ZIP CODE'}) }}</label>
            <input
              id="zip"
              v-model="zip"
              class="input-reset ba b--black-20 pa2 db w-100 br1"
              type="text"
            >
          </div>
        </div>

        <div class="flex inter flex-row pb3 mh3 justify-between">
          <div class="w-48">
            <ak-button
              block
              class="modal-button inter"
              secondary
              @click.native="resetState"
            >
              {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
            </ak-button>
          </div>
          <div class="w-48">
            <ak-button
              :disabled="!canUpdateAddress"
              block
              class="modal-button inter"
              primary
              @click.native="changeAddress"
            >
              {{ $t('edit.save', {save: 'Save'}) }}
            </ak-button>
          </div>
        </div>
      </div>
    </div>

    <hr class="line mh3">

    <div
      v-show="!showBirthdayInput"
      class="pv2 ph3 mv2 mh0 flex flex-column justify-center items-start mobile-row"
    >
      <div class="flex flex-column">
        <span class="silver-element">{{ $t('accountsettings.dateofbirth', {dateofbirth: 'Date of Birth'}) }}</span>
      </div>
      <div class="flex flex-row justify-between w-100">
        <span
          :class="{ 'modal-opened-row-text': showBirthdayInput }"
          class="data"
        >{{ birth_day }}
        </span>
        <span
          class="color-theme pointer data"
          @click="openChangeBirthdayInput"
        >{{ $t('edit.edit', {edit: 'Edit'}) }}</span>
      </div>
    </div>

    <!-- Edit date of birth desktop -->
    <div
      v-show="showBirthdayInput"
      class="row-edit mv2 desktop modal-opened-birthday"
    >
      <span class="elementLeft modal-opened-row-text">Edit Date of Birth</span>
      <div class="modal w-75">
        <form
          slot="modal-body"
          class="flex-column"
        >
          <div class="mt3 mb3 flex-grow mh3-ns">
            <label
              class="f6 b db mb2 label"
              for="first_name"
            >DATE OF BIRTH</label>
            <input
              id="birth_day"
              v-model="birth_day"
              class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
              type="date"
            >
          </div>
        </form>

        <div
          slot="modal-footer"
          class="pa3 flex flex-row justify-between justify-end-ns"
        >
          <div class="mr3 flex-grow flex-grow-off-ns">
            <ak-button
              block
              class="modal-button inter fw6 cancel"
              secondary
              @click.native="resetState"
            >
              {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
            </ak-button>
          </div>
          <div class="flex-grow flex-grow-off-ns">
            <ak-button
              block
              class="modal-button inter fw6 save"
              primary
              @click.native="updateDateofBirth"
            >
              {{ $t('edit.save', {save: 'Save'}) }}
            </ak-button>
          </div>
        </div>
      </div>
    </div>

    <!-- edit date of birth mobile -->
    <div
      v-if="showBirthdayInput"
      class="modal flex-row mobile w-75"
      title="Edit your name"
      @close="resetState"
    >
      <form
        slot="modal-body"
        class="flex-column mh3 mt4"
      >
        <div class="mb3">
          <span>Edit Date of Birth</span>
        </div>
        <div class="mt3 mb2 flex-grow mh3-ns">
          <label
            class="f6 b db mb2 label"
            for="first_name"
          >Date of Birth</label>
          <input
            id="birth_day"
            v-model="birth_day"
            class="input-reset ba b--black-20 pa2 mb1 db w-100 br1"
            type="date"
          >
        </div>
      </form>
      <div
        slot="modal-footer"
        class="flex mh4 flex-row pb3 justify-between"
      >
        <div class="w-48">
          <ak-button
            block
            class="modal-button inter"
            secondary
            @click.native="resetState"
          >
            {{ $t('edit.cancel', {cancel: 'Cancel'}) }}
          </ak-button>
        </div>
        <div class="w-48">
          <ak-button
            block
            class="modal-button inter"
            primary
            @click.native="updateDateofBirth"
          >
            {{ $t('edit.save', {save: 'Save'}) }}
          </ak-button>
        </div>
      </div>
    </div>

    <!-- <div class="pv2 ph3 mv2 mh0 flex flex-column justify-center items-start mobile-row">
      <div class="flex flex-column">
        <span class="silver">{{ $t('accountsettings.language', { language: 'Language' }) }}</span>
      </div>
      <div class="flex flex-row">
        <span class="data"><SwitchLanguage /></span>
      </div>
    </div> -->
  </div>
</template>

<script>
// import SwitchLanguage from '@/components/SwitchLanguage.vue'
import AkButton from '@/components/Button'
import { DATE_DAY, DATE_MONTH, DATE_YEAR, LANGUAGE, ZONE, ADMINISTRATIVE_AREA, LOCALITY, POSTAL_CODE } from '../../../../constants/account-settings/accountSettings'
import ArrowBack1 from '../../icons/ArrowBack.vue'

export default {
  name: 'PersonalInformation',
  components: {
    // SwitchLanguage,
    AkButton,
    ArrowBack1
  },
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showNameModal: false,
      isEditAddress: false,
      first_name: '',
      last_name: '',
      apt: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      windowWidth: window.innerWidth,
      birth_day: '',
      showBirthdayInput: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    birthday () {
      const bday = this.user.birthday
      const bdate = new Date(bday)
      return this.formatDate(bdate)
    },
    canUpdateName () {
      return this.last_name.trim() !== '' || this.first_name.trim() !== ''
    },
    canUpdateAddress () {
      return this.street.trim() !== '' && this.city.trim() !== '' && this.state.trim() !== '' && this.zip.trim() !== '' && this.zip.length > 4
    },
    isMobile () {
      return this.windowWidth <= 768
    }
  },
  watch: {
    isEditAddress: 'updateAddress'
  },
  mounted () {
    if (this.user.first_name) this.first_name = this.user.first_name
    if (this.user.last_name) this.last_name = this.user.last_name
    if (this.user.birthday) this.birth_day = this.birthday
  },
  beforeDestroy () {
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  },
  methods: {
    formatDate (date) {
      return date.toLocaleDateString(LANGUAGE, {
        day: DATE_DAY,
        month: DATE_MONTH,
        year: DATE_YEAR,
        timeZone: ZONE
      })
    },
    openNameModal () {
      this.showNameModal = true
      this.first_name = this.user.first_name
      this.last_name = this.user.last_name
    },
    openChangeBirthdayInput () {
      this.showBirthdayInput = true
    },
    resetState () {
      this.first_name = ''
      this.last_name = ''
      this.showNameModal = false
      this.isEditAddress = false
      this.first_name = ''
      this.last_name = ''
      this.apt = ''
      this.street = ''
      this.city = ''
      this.state = ''
      this.zip = ''
      this.birth_day = this.birthday
      this.showBirthdayInput = false
    },
    updateDateofBirth () {
      let payload = {}
      const bday = new Date(this.birth_day)
      const formatted = this.formatDate(bday)
      payload.birthday = formatted
      this.$store.dispatch('updateProfile', payload)
      this.resetState()
      this.birth_day = formatted
    },
    updateName () {
      let payload = {}
      if (this.last_name.trim() !== '') {
        const updatedLastName = this.last_name.trim()
        payload.last_name = updatedLastName
        this.user.last_name = updatedLastName
      }
      if (this.first_name.trim() !== '') {
        const updatedName = this.first_name.trim()
        payload.first_name = updatedName
        this.user.first_name = updatedName
      }
      this.$store.dispatch('updateProfile', payload)
      this.resetState()
    },
    changeAddress () {
      let payload = {}
      const updatedStreet = this.street.trim()
      const updatedCity = this.city.trim()
      const updatedState = this.state.trim()
      const updatedZip = this.zip.trim()
      const updatedApt = this.apt.trim !== '' && this.apt.trim()

      this.user.street = updatedStreet
      this.user.apt = updatedApt
      this.user.city = updatedCity
      this.user.state = updatedState
      this.user.zip = updatedZip

      payload.street = updatedStreet
      payload.city = updatedCity
      payload.state = updatedState
      payload.zip = updatedZip
      payload.apt = updatedApt

      this.$store.dispatch('updateProfile', payload)
      this.resetState()
    },
    goBack () {
      this.$router.push('/account')
    },
    updateAddress () {
      this.apt = ''
      this.street = ''
      this.city = ''
      this.state = ''
      this.zip = ''
      const google = window.google
      /* eslint-disable no-new */
      /* eslint-disable no-unused-vars */
      let binder = document.getElementById('street')

      const addressAutocomplete = new google.maps.places.Autocomplete(
        binder, {
          types: ['address'],
          fields: ['address_components'],
          componentRestrictions: {country: ['us', 'ca']}
        })
      // addressAutocomplete.setComponentRestrictions({country: [this.country]})
      addressAutocomplete.addListener('place_changed', () => {
        let place = {
          address_components: [],
          ...addressAutocomplete.getPlace()
        }

        this.street = `${addressAutocomplete.getPlace().address_components[0].long_name} ${addressAutocomplete.getPlace().address_components[1].long_name}`
        addressAutocomplete.getPlace().address_components.forEach((component) => {
          component.types.forEach((type) => {
            if (type === LOCALITY) {
              this.city = component.long_name
            }
            if (type === POSTAL_CODE) {
              this.zip = component.long_name
            }
            if (type === ADMINISTRATIVE_AREA) {
              this.state = component.short_name
            }
          })
        })
      })
      // google.maps.event.clearInstanceListeners(binder)
      // return
    }
  }
}
</script>

<style scoped>

.modal {
  width: 75%;
  align-items: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  height: 71px;
  border-radius: 5px;
  padding: 0 18px;
}

.row-edit {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  height: 71px;
  border-radius: 5px;
  padding: 0 18px;
}
.row:hover, .mobile-row:hover {
  background: #FAFAFA;
  border-radius: 4px;
}

.elementLeft {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  margin-right: 50px;
  white-space: nowrap;
  color: #757D8A;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757D8A;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #757D8A;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #757D8A;
}

.silver-element{
  color: #757D8A;
}

.elementRight {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.mobile, .mobile-flex {
  display: none;
}

.line {
  border: 1px solid #E8E8E8;
}

.label {
  color: #757D8A;
  font-size: 11px;
}

input {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
}

input:focus {
  border: 1px solid #F3103D;
}

.modal-opened-birthday {
  align-items: flex-start;
  height: 160px;
}
.modal-opened-row {
  align-items: flex-start;
  height: 230px;
}

.modal-opened-row-address {
  align-items: flex-start;
  height: 422px;
}

.modal-opened-row-text {
  margin-top: 2.75em;
}

.modal-button {
  height: 40px;
  border-radius: 5px;
}

.mobile-row {
  height: 71px
}

.data {
  margin-top: .75rem;
}

.save {
  width: 90px;
}

.cancel {
  width: 105px;
  border-width: 1.5px;
}

.main {
  padding-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  padding-top: .25rem;
}

@media (max-width: 768px) {

  .mobile-flex {
    display: flex;
  }

  .w-48 {
    width: 48%;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .mobile-row {
    height: 74px;
  }

  .mobile-row:hover {
    background: #FAFAFA;
    border-radius: 4px;
  }

  .modal {
    width: 100%
  }

  .main {
    padding-top: 0;
    padding-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}


</style>
