<template>
  <main class="center-ns mb4">
    <div class="mw5-ns center-ns tc mb4 pt4 lh-copy flex">
      <input
        id="interval1"
        v-model="interval"
        class="dn"
        type="radio"
        :value="'annual'"
        name="interval"
      >
      <label
        tabindex="0"
        for="interval1"
        class="flex-grow pv2 tc b ba br1 br--right b--silver silver bg-white pointer"
        @keyup.enter="interval='annual'"
      >Annual</label>
      <span class="pv2 ph3 tc b silver">OR</span>
      <input
        id="interval2"
        v-model="interval"
        class="dn"
        type="radio"
        :value="'monthly'"
        name="interval"
      >
      <label
        tabindex="0"
        for="interval2"
        class="flex-grow pv2 tc b ba br1 br--left b--silver silver bg-white pointer"
        @keyup.enter="interval='monthly'"
      >Monthly</label>
    </div>

    <div class="mb4 center-ns flex-ns flex-wrap justify-center">
      <ak-coverage-card
        :name="selectedOption.name"
        :price="selectedOption.price"
        :deductible="selectedOption.deductible"
        :claims="selectedOption.max_coverage"
        :featured="interval === 'annual'"
        :coverageid="selectedOption.id"
        :annual="interval === 'annual'"
        :savings="savings"
        :activation-fee="false"
        :plan-type="checkoutType"
        @click="submit"
      >
        <ak-coverage-currency-toggle />
      </ak-coverage-card>
    </div>

    <div class="mb4 f7 f5-ns mw7-ns center-ns tc cera-round-pro">
      <span class="lh-copy">Plans can be cancelled at anytime for a pro-rated refund.</span>
      <br>
      Contact <a
        class="color-theme lh-copy"
        href="mailto:billing@getakko.com"
      >billing@getakko.com</a>
      <br>
      <br>
    </div>
  </main>
</template>

<script>
import AkCoverageCard from '@/components/CoverageCard'
import AkCoverageCurrencyToggle from '@/components/CoverageCurrencyToggle'
import { newArrayOfObject } from '@/utils'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  GET_REGISTRATION_QUANTITY,
  SET_REGISTRATION_COVERAGES,
  SET_REGISTRATION_INTERVAL,
  SET_REGISTRATION_QUANTITY_VALUE,
  ADD_DISCOUNT_CODE,
  GET_TWO_YEARS_UPFRONT,
  GET_INTERNAL_DISCOUNT_VALUES,
  SET_REGISTRATION_DISCOUNTS_ADD,
  SET_REGISTRATION_DISCOUNTS_REMOVE,
  SET_REGISTRATION_SELECTED_COVERAGES,
  SET_REGISTRATION_DISCOUNTS_REMOVE_ALL
} from '@/store/registrationv2'

import { FAMILY_PLAN_DISCOUNT } from '@/constants/registration/choosePlan'

import PLAN_INTERVALS from '@/constants/registration/planIntervals'
import DISCOUNT_CODE from '../../constants/registration/discountCode'
import { TWO_YEAR_UPFRONT_KEY } from '@/constants/registration/discountKeys'
import PLAN_TYPES from '@/constants/registration/planTypes'

const byPrice = (a, b) => a.price - b.price

export default {
  name: 'AkChoosePlan',
  components: {
    AkCoverageCard,
    AkCoverageCurrencyToggle
  },
  data () {
    return {
      interval: PLAN_INTERVALS.ANNUAL
    }
  },
  computed: {
    ...mapGetters({
      quantity: GET_REGISTRATION_QUANTITY,
      twoYearUpfrontSelected: GET_TWO_YEARS_UPFRONT,
      internalDiscountValues: GET_INTERNAL_DISCOUNT_VALUES,
      getAkkoPlanCoverageData: 'getAkkoPlanCoverageData'
    }),

    quantityAdults () {
      return this.quantity.adults
    },
    quantityStudents () {
      return this.quantity.students
    },
    checkoutType () {
      return this.$route.params.type
    },
    coverageOptions () {
      return [...this.$store.state.coverageOptions].sort(byPrice)
    },
    basePlans () {
      let planIntervals = [PLAN_INTERVALS.ANNUAL, PLAN_INTERVALS.MONTHLY]
      let [annualStudentPrices, monthlyStudentPrices] = this.getAkkoPlanCoverageData(planIntervals, PLAN_TYPES.STUDENT)
      let [annualAdultPrices, monthlyAdultPrices] = this.getAkkoPlanCoverageData(planIntervals, PLAN_TYPES.ADULT)

      return {
        student: {
          annual: annualStudentPrices,
          monthly: monthlyStudentPrices
        },
        adult: {
          annual: annualAdultPrices,
          monthly: monthlyAdultPrices
        }
      }
    },
    monthly () { // TODO: investigate if the introduction of new coverages will impact this method of searching for coverages
      return this.coverageOptions.find(
        co => co.plan_type === this.checkoutType && co.plan_interval === 'monthly',
      )
    },
    annual () { // TODO: investigate if the introduction of new coverages will impact this method of searching for coverages
      return this.coverageOptions.find(
        co => co.plan_type === this.checkoutType && co.plan_interval === 'annual',
      )
    },
    familyCoverage () {
      return this.getFamilyPackageInformation({ discount: FAMILY_PLAN_DISCOUNT })
    },
    savings () {
      if (this.checkoutType === 'family') {
        const s = this.basePlans.student.annual
        const sm = this.basePlans.student.monthly
        const a = this.basePlans.adult.annual
        const am = this.basePlans.adult.monthly
        return ((this.quantityStudents * sm.price + this.quantityAdults * am.price) * 12) - (this.quantityStudents * s.price + this.quantityAdults * a.price)
      }

      return ((this.monthly.price * 12) - this.annual.price)
    },
    selectedOption () {
      if (this.checkoutType === 'family') return this.familyCoverage

      switch (this.interval) {
        case 'annual':
          return this.annual
        case 'monthly':
          return this.monthly
        default:
          return null
      }
    },
    plans () {
      const adultPlan = { phoneError: null, ...this.basePlans.adult[this.interval] }
      const studentPlan = { phoneError: null, ...this.basePlans.student[this.interval] }

      const plans = [
        ...newArrayOfObject(this.quantityAdults, adultPlan),
        ...newArrayOfObject(this.quantityStudents, studentPlan)
      ]

      return plans
    }
  },
  methods: {
    ...mapActions({
      addDiscountCode: ADD_DISCOUNT_CODE,
      setQuantityValue: SET_REGISTRATION_QUANTITY_VALUE
    }),
    ...mapMutations({
      setCoverages: SET_REGISTRATION_COVERAGES,
      setInterval: SET_REGISTRATION_INTERVAL,
      addDiscount: SET_REGISTRATION_DISCOUNTS_ADD,
      removeDiscount: SET_REGISTRATION_DISCOUNTS_REMOVE,
      setSelectedCoverages: SET_REGISTRATION_SELECTED_COVERAGES,
      setRegistrationDiscountsRemoveAll: SET_REGISTRATION_DISCOUNTS_REMOVE_ALL
    }),

    setQuantity (key, value) {
      this.setQuantityValue({ key, value })
    },
    getFamilyPackageInformation ({interval = this.interval, discount = 1}) {
      const s = this.basePlans.student[interval]
      const a = this.basePlans.adult[interval]
      const totalPrice = this.quantityStudents * s.price + this.quantityAdults * a.price

      return {
        name: 'AKKO Family Package',
        id: 0,
        price: totalPrice * discount,
        deductible: Math.max(a.deductible, s.deductible),
        claims: Math.min(a.max_coverage, s.max_coverage),
        totalPrice
      }
    },
    familySelectedCoverage () {
      const adultMonthlyPlan = { phoneError: null, ...this.basePlans.adult[PLAN_INTERVALS.MONTHLY] }
      const studentMonthlyPlan = { phoneError: null, ...this.basePlans.student[PLAN_INTERVALS.MONTHLY] }
      const adultAnnualPlan = { phoneError: null, ...this.basePlans.adult[PLAN_INTERVALS.ANNUAL] }
      const studentAnnualPlan = { phoneError: null, ...this.basePlans.student[PLAN_INTERVALS.ANNUAL] }

      const monthlyPlans = [
        ...newArrayOfObject(this.quantityAdults, adultMonthlyPlan),
        ...newArrayOfObject(this.quantityStudents, studentMonthlyPlan)
      ]

      const annualPlans = [
        ...newArrayOfObject(this.quantityAdults, adultAnnualPlan),
        ...newArrayOfObject(this.quantityStudents, studentAnnualPlan)
      ]

      return {
        monthlyPlans,
        annualPlans
      }
    },
    selectedCoverage () {
      if (this.checkoutType === 'family') {
        const monthlyFamilyCoverage = this.getFamilyPackageInformation({ interval: PLAN_INTERVALS.MONTHLY })
        const annualFamilyCoverages = this.getFamilyPackageInformation({ interval: PLAN_INTERVALS.ANNUAL })
        const { monthlyPlans, annualPlans } = this.familySelectedCoverage()

        return {
          monthly: {
            coverages: monthlyPlans.map((coverage, index) => ({ primary: index === 0, ...coverage })),
            totalPrice: monthlyFamilyCoverage.price.toFixed(2)
          },
          annual: {
            coverages: annualPlans.map((coverage, index) => ({ primary: index === 0, ...coverage })),
            totalPrice: annualFamilyCoverages.price.toFixed(2)
          }
        }
      } else {
        return {
          monthly: {
            coverages: [this.monthly],
            totalPrice: this.monthly.price.toFixed(2)
          },
          annual: {
            coverages: [this.annual],
            totalPrice: this.annual.price.toFixed(2)
          }
        }
      }
    },
    submit () {
      const selectedCoverage = this.selectedCoverage()
      this.setSelectedCoverages(selectedCoverage)
      this.setInterval(this.interval)

      const updateDiscount = this.twoYearUpfrontSelected ? this.addDiscount : this.removeDiscount
      const twoYearUpfrontDiscount = this.internalDiscountValues[TWO_YEAR_UPFRONT_KEY]
      updateDiscount(twoYearUpfrontDiscount)

      if (this.checkoutType === 'family') {
        this.addDiscountCode({code: DISCOUNT_CODE.FAMILY_PLAN})
      }

      this.$router.push(`/registration-v2/checkout`)
    }
  },
  mounted () {
    if (this.checkoutType !== 'family') {
      this.setRegistrationDiscountsRemoveAll()
      this.setQuantityValue({ key: 'adults', value: this.checkoutType === 'adult' ? 1 : 0 })
      this.setQuantityValue({ key: 'students', value: this.checkoutType === 'student' ? 1 : 0 })
    }
  }
}
</script>

<style scoped>
input[type=radio]:checked + label{
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}
</style>
