<template>
  <div class="db centered-container inter lh-copy">
    <akko-header />
    <div class="circle" />
    <div class="flex pv5 justify-center">
      <div
        id="complete-card"
        class="shadow-theme-ns ph5 mt0-ns pv5-ns br3 tc box w-50"
      >
        <div class="cera-round-pro-medium f3 lh-copy fw3 mb2">
          Welcome to AKKO!
        </div>
        <img
          class="image-bot"
          src="@/assets/social/akkobot.png"
          alt=""
        >
        <div class="cera-round-pro color-theme f3 lh-copy fw3 mb3">
          IMPORTANT:
        </div>

        <div class="inter larger-text">
          To complete your plan activation, please follow the instructions in the SMS you just received to your phone.
          Click the link and use your temporary password to login.
        </div>

        <div
          v-if="true"
          class="inter mv4 larger-text"
        >
          Each of the other users you registered received their own SMS with a password and instructions.
          Each user will need to login and activate their own account from their phone as part of the verification process.
        </div>

        <div class="inter mt4 mb5">
          If you have any questions or need help completing your registration, don't hesitate to reach out to us!
        </div>

        <div class="mt4 tc flex justify-center">
          <ak-button
            class="w4 mr3"
            new-primary
            @click.native="onPressEmail"
          >
            Email
          </ak-button>
          <ak-button
            class="w4"
            new-secondary
            @click.native="onPressChat"
          >
            FAQs
          </ak-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AkButton from '../Button'
import AkkoHeader from '../AkkoHeader.vue'
import REGISTRATION_TYPE from '../../constants/registration/registrationType'
import {SET_REGISTRATION_CLEAR} from '../../store/registrationv2'

export default {
  name: 'AkRegistrationComplete',
  components: {
    AkButton,
    AkkoHeader
  },
  beforeRouteLeave (to, from, next) {
    this.$intercom.shutdown()
    next()
  },
  computed: {
    multiPlan () {
      return this.$store.state.registrationType === REGISTRATION_TYPE.FAMILY_PLAN || this.$store.state.registrationType === REGISTRATION_TYPE.MULTI_PHONE && this.$store.state.user.related_users.length > 0
    }
  },
  mounted () {
    this.$store.dispatch('getUserInformation', () => {
      this.$store.commit(SET_REGISTRATION_CLEAR)
    })
  },
  methods: {
    onPressEmail () {
      window.location.href = 'mailto:support@getakko.com'
    },
    onPressChat () {
      window.open('https://getakko.com/coverage-info-faqs', '_blank').focus()
    }
  }
}
</script>

<style scoped>
  .box {
    background-color: var(--theme-cream);
  }
  #complete-card {
    flex-basis: 38rem;
  }
  .larger-text {
    font-size: 1.2em;
  }
  .image-bot {
    height: 100px;
  }
  @media screen and (min-width: 30em) {
    .centered-container {
      display: flex;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      margin-top: 0;
    }
    .box {
      background-color: white;
    }
  }
</style>
