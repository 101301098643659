<template>
  <main>
    <div class="f5 db mb3 fw6">
      Payment Information
    </div>
    <ak-email-form
      ref="emailForm"
      :is-student-plan="isStudentPlan"
    />
    <slot />
    <br>

    <label class="f6 b db">Payment Method</label>
    <div class="flex flex-wrap">
      <div
        v-for="(method, index) in paymentMethods"
        :key="index"
        class="mt3 mb3"
      >
        <input
          :id="`paymentMethod${index}`"
          v-model="selectedPaymentMethod"
          class="dn"
          type="radio"
          :value="method.value"
          name="selectedPaymentMethod"
        >
        <label
          tabindex="0"
          :for="`paymentMethod${index}`"
          class="flex-grow pv2 ph5 tc b ba br1 br--left b--silver silver bg-white pointer"
          @keyup.enter="selectedPaymentMethod=method.value"
        >
          {{ method.label }}
        </label>
      </div>
    </div>

    <div
      v-for="(method, index) in paymentMethods"
      :key="index"
    >
      <component
        :is="method.value"
        v-show="method.value === selectedPaymentMethod"
        :ref="method.value"
      />
    </div>

    <div
      v-if="generalError"
      role="alert"
      class="red f6 mt2"
    >
      {{ generalError }}
    </div>

    <br>

    <hr>

    <ak-student-form
      v-show="emailValid && requiresStudentValidation"
      ref="studentForm"
    />
  </main>
</template>

<script>

import AkCreditDebitCard from './credit-debit-card/CreditDebitCard.vue'
import AkAch from './ach/Ach.vue'
import AkEmailForm from './shared/components/EmailForm.vue'
import AkStudentForm from './shared/components/StudentForm.vue'
import { PAYMENT_METHODS } from './shared/constants'
import { mapGetters } from 'vuex'
import { GET_REGISTRATION_STUDENT_DATA } from '@/store/registrationv2'

export default {
  name: 'AkPaymentMethod',
  components: {
    AkAch,
    AkEmailForm,
    AkStudentForm,
    AkCreditDebitCard
  },
  props: {
    isStudentPlan: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      emailValid: false,
      hasEduEmail: false,
      paymentMethods: PAYMENT_METHODS,
      selectedPaymentMethod: PAYMENT_METHODS[0].value
    }
  },
  watch: {
    selectedPaymentMethod: {
      handler () {
        this.$store.commit('SET_ERRORS', null)
      },
      immediate: true
    }
  },
  mounted () {
    this.emailValid = this.$refs.emailForm.emailValid
    this.hasEduEmail = this.$refs.emailForm.hasEduEmail
    this.$watch(() => this.$refs.emailForm.emailValid, (emailValid) => {
      this.emailValid = emailValid
    })
    this.$watch(() => this.$refs.emailForm.hasEduEmail, (hasEduEmail) => {
      this.hasEduEmail = hasEduEmail
    })
  },
  computed: {
    ...mapGetters({
      studentData: GET_REGISTRATION_STUDENT_DATA
    }),
    generalError () {
      return this.$store.state.errors[0]
    },
    requiresStudentValidation () {
      return this.isStudentPlan && !this.hasEduEmail
    },
    paymentMethodCompleted () {
      const isEmailFormValid = !!this.$refs.emailForm && this.$refs.emailForm.isDataFilled

      const isStudentFormValid = this.studentData &&
                                  this.studentData.school &&
                                  this.studentData.studentValidation &&
                                  this.studentData.studentValidation.file &&
                                  this.studentData.studentValidation

      const isValidStudent = !this.isStudentPlan ||
                              this.hasEduEmail ||
                              isStudentFormValid

      const selectedPaymentMethodRef = this.$refs[this.selectedPaymentMethod]
      const isPaymentFormValid = !!selectedPaymentMethodRef &&
                                 !!selectedPaymentMethodRef[0] &&
                                 !!selectedPaymentMethodRef[0].isDataFilled

      return isEmailFormValid && isPaymentFormValid && isValidStudent && !this.generalError
    }
  },
  methods: {
    async submitPayment () {
      const selectedPaymentMethodRef = this.$refs[this.selectedPaymentMethod]
      if (!selectedPaymentMethodRef || !selectedPaymentMethodRef[0]) return false
      return await selectedPaymentMethodRef[0].submitPayment()
    }
  }
}
</script>
<style scoped>
.flex-basis-4 {
  flex-basis: 16em;
}
.flex-basis-3 {
  flex-basis: 12em;
}
.flex-basis-25 {
  flex-basis: 23%;
}
input[type=radio]:checked + label{
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}
.grid-fourth {
  grid-column-gap: 1.5em;
  grid-row-gap: 2.25em;
}
.not-allowed-cursor {
  cursor: not-allowed;
}

/* Hide input number arrows in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide input number arrows in  Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
