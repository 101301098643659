<template>
  <main class="center-ns mb4">
    <div class="mw5-ns center-ns tc mb4 pt4 lh-copy flex">
      <input
        id="monthlyToggle1"
        v-model="monthlyToggle"
        class="dn"
        type="radio"
        :value="false"
        name="monthlyToggle"
      >
      <label
        tabindex="0"
        for="monthlyToggle1"
        class="flex-grow pv2 tc b ba br1 br--right b--silver silver bg-white pointer"
        @keyup.enter="monthlyToggle=false"
      >Annual</label>
      <span class="pv2 ph3 tc b silver">OR</span>
      <input
        id="monthlyToggle2"
        v-model="monthlyToggle"
        class="dn"
        type="radio"
        :value="true"
        name="monthlyToggle"
      >
      <label
        tabindex="0"
        for="monthlyToggle2"
        class="flex-grow pv2 tc b ba br1 br--left b--silver silver bg-white pointer"
        @keyup.enter="monthlyToggle=true"
      >Monthly</label>
    </div>

    <div class="mb4 center-ns flex-ns flex-wrap justify-center items-end">
      <div>
        <ak-coverage-card
          :name="coverageName"
          :price="monthlyToggle ? monthly.price : annual.price"
          :deductible="monthlyToggle ? monthly.deductible : annual.deductible"
          :claims="monthlyToggle ? monthly.max_coverage : annual.max_coverage"
          :featured="monthlyToggle ? false : true"
          :coverageid="monthlyToggle ? monthly.id : annual.id"
          :annual="!monthlyToggle"
          :savings="savings"
          :activation-fee="false"
          @click="submit"
        >
          <ak-coverage-currency-toggle
            :plan-type="planType"
          />
        </ak-coverage-card>
      </div>
    </div>

    <div class="mb4 f7 f5-ns mw7-ns center-ns tc cera-round-pro">
      <span class="lh-copy">Plans can be cancelled at anytime for a pro-rated refund.</span>
      <br>
      Contact <a
        class="color-theme lh-copy"
        href="mailto:billing@getakko.com"
      >billing@getakko.com</a>
      <br>
      <br>
      <div class="black f6 mb3 mw7-ns center tc lh-copy">
        Elite Risk Insurance Solutions Lic#0G40499. <!-- All coverage plans provided by NSSI and underwritten by
        <br />
        <a href="https://www.hanover.com/about-our-companies.html" target="_blank" rel="noopener noreferrer" class="black">
          Allmerica Financial Benefit Company a member of The Hanover Insurance Group (est. 1852)
        </a> -->
      </div>
    </div>
  </main>
</template>

<script>
import AkCoverageCard from '@/components/CoverageCard'
import AkCoverageCurrencyToggle from '@/components/CoverageCurrencyToggle'

import {
  SET_REGISTRATION_TYPE
} from '../../store/index'
import '@/constants/registration/registrationType'
import REGISTRATION_TYPE from '../../constants/registration/registrationType'
import {
  SET_REGISTRATION_CLEAR,
  SET_REGISTRATION_COVERAGES,
  SET_REGISTRATION_DISCOUNTS_REMOVE_ALL,
  SET_REGISTRATION_INTERVAL,
  GET_TWO_YEARS_UPFRONT,
  GET_INTERNAL_DISCOUNT_VALUES,
  SET_REGISTRATION_DISCOUNTS_ADD,
  SET_REGISTRATION_DISCOUNTS_REMOVE,
   SET_REGISTRATION_SELECTED_COVERAGES
} from '../../store/registrationv2'
import {mapMutations, mapGetters} from 'vuex'
import PLAN_INTERVALS from '@/constants/registration/planIntervals'
import { TWO_YEAR_UPFRONT_KEY } from '@/constants/registration/discountKeys'
import PLAN_TYPES from '@/constants/registration/planTypes'

export default {
  name: 'AkSelectCoverage',
  components: {
    AkCoverageCard,
    AkCoverageCurrencyToggle
  },
  data () {
    return {
      coverageAmount: 2000,
      monthlyToggle: false,
      planType: PLAN_TYPES.ADULT
    }
  },
  mounted () {
    window.fbq('trackCustom', 'Adult Select Coverage')
    this.$store.dispatch('getAllCoverageOptions')
    this.$store.commit(SET_REGISTRATION_CLEAR, REGISTRATION_TYPE.SOLO)
    this.$store.commit(SET_REGISTRATION_TYPE, REGISTRATION_TYPE.SOLO)
    this.removeAllDiscountCodes()
  },
  computed: {
    ...mapGetters({
      twoYearUpfrontSelected: GET_TWO_YEARS_UPFRONT,
      internalDiscountValues: GET_INTERNAL_DISCOUNT_VALUES,
      getAkkoPlanCoverageData: 'getAkkoPlanCoverageData'
    }),
    coveragePricePlans () {
      let [annualPrices, monthlyPrices] = this.getAkkoPlanCoverageData([PLAN_INTERVALS.ANNUAL, PLAN_INTERVALS.MONTHLY], this.planType)
      return {
        annualPrices,
        monthlyPrices
      }
    },
    monthly () {
      return this.coveragePricePlans.monthlyPrices
    },
    annual () {
      return this.coveragePricePlans.annualPrices
    },
    coverageName () {
      return 'AKKO Adult Plan'
    },
    savings () {
      return ((this.monthly.price * 12) - this.annual.price)
    }
  },
  methods: {
    ...mapMutations({
      removeAllDiscountCodes: SET_REGISTRATION_DISCOUNTS_REMOVE_ALL,
      setCoverages: SET_REGISTRATION_COVERAGES,
      setInterval: SET_REGISTRATION_INTERVAL,
      addDiscount: SET_REGISTRATION_DISCOUNTS_ADD,
      removeDiscount: SET_REGISTRATION_DISCOUNTS_REMOVE,
      setSelectedCoverages: SET_REGISTRATION_SELECTED_COVERAGES
    }),
    submit () {
      this.$store.commit('RESET_PROMO_ALL', false)
      this.$store.commit('RESET_REDEMPTION_ALL', false)
      this.setCoverages([this.monthlyToggle ? this.monthly : this.annual])
      this.setSelectedCoverages({
        monthly: {
          coverages: [this.monthly],
          totalPrice: this.monthly.price
        },
        annual: {
          coverages: [this.annual],
          totalPrice: this.annual.price
        }
      })
      this.setInterval(this.monthlyToggle ? PLAN_INTERVALS.MONTHLY : PLAN_INTERVALS.ANNUAL)

      const updateDiscount = this.twoYearUpfrontSelected ? this.addDiscount : this.removeDiscount
      const twoYearUpfrontDiscount = this.internalDiscountValues[TWO_YEAR_UPFRONT_KEY]
      updateDiscount(twoYearUpfrontDiscount)

      this.$router.push(`/registration-v2/checkout`)
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$intercom.shutdown()
    next()
  }
}
</script>

<style scoped>
input[type=radio]:checked + label{
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}
</style>
