<template>
  <div
    v-if="registrationStep === TERMS_CONDS"
    class="mv3"
  >
    <label class="cera-round-pro fw6 db mb3 title">Terms & Conditions</label>
    <div
      for="accept"
      class="inter f6 fw5 silver lh-copy"
    >
      I agree that I have thoroughly read, understand, and accept the
      <a
        href="https://getakko.com/legal"
        target="_blank"
        class="color-theme"
      >terms of service &amp; conditions</a>
      as well as the <a
        href="https://getakko.com/coverage-info-faqs/"
        target="_blank"
        rel="noopener"
        class="color-theme"
      >AKKO website's FAQs</a> about plan protections and claims. I also acknowledge and agree to receive and view all plan documentation, including Terms and Conditions and Plan Confirmation (if any), electronically as well as receive automated transactional messages via email and SMS in accordance with AKKO's Privacy Policy.
    </div>
    <div
      for="accept"
      class="inter f6 fw5 silver mt3 lh-copy"
    >
      You must complete activation in the following step and verify your email address. You must add your items to your account with their information and pictures according to all instructions for you to be eligible for reimbursements of losses. All plans are billed on a recurring annual or monthly basis until cancelled.
    </div>
    <div class="flex flex-row items-center mt4">
      <input
        id="accept"
        type="checkbox"
        class="di"
        :checked="accept"
        @input="onCheckChange"
      >
      <label class="inter f6 ml3">I agree to the ‘Terms & Conditions’</label>
    </div>
  </div>
</template>

<script>
import REDEEM_STEPS from '../../constants/registration/redeemSteps'

export default {
  name: 'RegistrationAgreement',
  props: {
    registrationStep: {
      type: Number,
      default: 0
    },
    accept: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      TERMS_CONDS: REDEEM_STEPS.TERMS_CONDS
    }
  },
  methods: {
    onCheckChange (event) {
      this.$emit('update:accept', event.target.checked)
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 20px;
  }
</style>
