<template>
  <button
    class="flex db fw5 pv3 ph4 br-pill pointer ba transition-fast cera-round-pro"
    :class="[
      block && 'w-100',
      primary && !disabled && 'bg-theme white b-theme',
      secondary && !disabled && 'bg-white color-theme b-theme bg-theme-active white-active',
      green && !disabled && 'bg-green white b-green',
      newSecondary && !newDisabled && 'bg-white inter default-button b-theme color-theme ba f7',
      newPrimary && !newDisabled && 'bg-theme inter default-button b-theme white color-white ba f7',
      shadow && 'shadow-style',
      bordered && !disabled && 'bg-theme white b--white',
      thin ? 'pv2' : 'pv3',
      newDisabled && 'inter bg-light-grey gray b--black-20 default-button disabled f7',
      disabled && 'bg-light-grey gray b--gray br3',
      customClass,
      upsell && 'upsell'
    ]"
    :disabled="disabled || newDisabled"
    @click="emitClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AkButton',
  props: {
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    green: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    thin: {
      type: Boolean,
      default: false
    },
    newStyle: {
      type: Boolean,
      default: false
    },
    shadow: {
      type: Boolean,
      default: false
    },
    newSecondary: {
      type: Boolean,
      default: false
    },
    newPrimary: {
      type: Boolean,
      default: false
    },
    newDisabled: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    upsell: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    emitClick () {
      // this.$emit('click')
    }
  }
}
</script>

<style scoped>
.bg-gradient {
  background: linear-gradient(var(--theme-primary), #CE1033);
}

.pv2 {
  padding-top: .75em;
  padding-bottom: .75em;
}

.default-button {
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
}

.shadow-style {
  padding: 8px 25px 8px 25px;
  box-shadow: 0 1px 3px 0 rgba(32, 48, 80, 0.233);
}

@media (max-width: 479px) {
  .upsell {
    width: 100%;
    margin-bottom: 0.5rem;
    height: 2.5rem;
    position: inherit;
    width: -webkit-fill-available;
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .default-button {
    padding: 7px 10px 7px 10px;
    font-size: 12px;
  }
}

.disabled {
  box-shadow: none;
  background-color: var(--theme-light-gray);
  cursor: not-allowed;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
