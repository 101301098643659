<template>
  <main>
    <div>
      <h1 class="page-title f2 lh-copy pt3 mb2">
        File New Claim
      </h1>
      <div class="losstype-heading cera-round-pro-medium">
        Type of Loss
      </div>
      <div class="losstype-desc">
        What happened to your device?
      </div>
      <div class="losstypes-btns">
        <a
          :href="userDevicesOnlyPhones"
          class="losstypes-btn"
        >
          <span class="losstypes-ic">
            <img src="@/assets/icons/ic_physical_damage_generic.png">
          </span>
          <span class="losstypes-txt-heading cera-round-pro-medium">
            Physical Damage
          </span>
          <span class="losstypes-txt-desc">
            Cracked screen, Physical /<br>Liquid Damage
          </span>
        </a>
        <a
          href="/file-claim"
          class="losstypes-btn"
        >
          <span class="losstypes-ic">
            <img src="@/assets/icons/ic_malfunction_generic.png">
          </span>
          <span class="losstypes-txt-heading cera-round-pro-medium">
            Malfunction
          </span>
          <span class="losstypes-txt-desc">
            Screen flickering, Camera /<br>Connectivity Issues
          </span>
        </a>
        <a
          href="/file-claim"
          class="losstypes-btn"
        >
          <span class="losstypes-ic">
            <img src="@/assets/icons/ic_stolen_generic.png">
          </span>
          <span class="losstypes-txt-heading cera-round-pro-medium">
            Stolen
          </span>
          <span class="losstypes-txt-desc">
            Theft / Robbery,<br>Unrecoverable, Misplaced
          </span>
        </a>
      </div>
    </div>
  </main>
</template>

<script>
import { CUSTOM_CLAIMS_URL } from '@/constants/ui-settings/uiSettings'
import { GET_PARTNER } from '@/store'
import { mapGetters } from 'vuex'

export default {
  name: 'AkLossType',
  data () {
    return {
      customClaimsUrl: CUSTOM_CLAIMS_URL
    }
  },
  computed: {
    ...mapGetters({
      partner: GET_PARTNER
    }),
    uiSettings () {
      const uiSettings = this.partner && this.partner.settings && this.partner.settings.ui_settings
      return uiSettings || {}
    },
    claimsUrl () {
      return process.env.VUE_APP_CLAIMS_V2
    },
    userDevicesOnlyPhones () {
      const path = `${this.claimsUrl}affected-items?t=${this.$store.state.token}`
      return path
    }
  },
  mounted () {
    this.$store.dispatch('getDevices')
  },
  methods: {
  }
}
</script>

<style scoped>
.page-title {
  margin-bottom: 40px;
}
.losstype-heading {
  font-size: 19px;
  margin-bottom: 8px;
}
.losstype-desc {
  font-size: 16px;
  color: #757D8A;
  margin-bottom: 28px;
}
.losstypes-btns {
  display: flex;
  justify-content: space-between;
  max-width: 54rem;
}
@media screen and (max-width: 960px)  {
  .losstypes-btns {
    display: block;
  }
}
.losstypes-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  text-align: center;
  width: 280px;
  height: 220px;
  text-decoration: none;
  margin-bottom: 1rem;
}
@media screen and (max-width: 960px) {
  .losstypes-btn {
    width: 100%;
  }
}
.losstypes-btn:hover {
  border: 1px solid #F3103D;
  text-decoration: none;
}
.losstypes-btn,
.losstypes-btn:active,
.losstypes-btn:visited,
.losstypes-btn:focus,
.losstypes-btn:target {
  color: inherit;
}
.losstypes-ic {
  width: 68px;
  height: 68px;
  margin-bottom: 12px;
}
.losstypes-ic img {
  display: block;
  width: 100%;
}
.losstypes-txt-heading {
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
}
.losstypes-txt-desc {
  font-size: 14px;
}
</style>
