<template>
  <main id="intercom-element">
    <div class="invoice-box">
      <router-link
        to="/account/dashboard"
        class="no-underline dib-ns red mb3 mt4 f4 b invoice-box"
      >
        <img
          src="@/assets/appicons/ArrowLeft.svg"
          alt=""
          class="mr1"
        >
        Back
      </router-link>
    </div>

    <h1 class="f2 lh-copy pt">
      Invoices
    </h1>
    <div class="flex flex-column justify-between items-start">
      <div
        v-for="(invoice, index) in user.invoice_information.all_invoices.slice(0, 10)"
        :key="index"
        class="mb3 invoice-box"
      >
        <div class="mr2">
          <img
            src="@/assets/appicons/FileText.svg"
            alt=""
          >
        </div>
        <label
          for="first_name"
          class="f6 b"
        >{{ invoiceData(invoice) }} (<a
          :href="invoice.hosted_invoice_url"
          class="red no-underline"
          target="_blank"
          rel="noopener"
        >
          View Invoice</a>)
        </label>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: 'AKInvoices',
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },
    user () {
      return this.$store.state.user
    }
  },
  mounted () {
    if (!this.isMobile) {
      const {email, phone_no, first_name, user_id} = this.$store.state.user
      this.$intercom.boot({
        userId: user_id,
        user_id: user_id,
        name: first_name,
        email: email,
        phone: phone_no
      })
    }
  },
  methods: {
    invoiceData (invoice) {
      const invoiceDueDate = this.formatDate(invoice.status_transitions.paid_at)
      const status = invoice.status
      const total = (invoice.total / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
      return `${invoiceDueDate} - ${total} - ${status}`
    },
    formatDate (date) {
      const toDate = new Date(date * 1000)
      const day = ('0' + (toDate.getUTCDate())).slice(-2)
      const month = ('0' + (toDate.getMonth() + 1)).slice(-2)
      const year = toDate.getFullYear()
      return `${month}/${day}/${year}`
    }
  }
}
</script>

<style scoped>
  .invoice-box {
    display: flex;
    align-items: center;
  }
</style>
