export default Object.freeze({
  ADULT_ANNUAL: 'ADULT_ANNUAL',
  STUDENT_ANNUAL: 'STUDENT_ANNUAL',
  ADULT_MONTHLY: 'ADULT_MONTHLY',
  STUDENT_MONTHLY: 'STUDENT_MONTHLY',
  ALL_PHONE_ALLOWED: 'ALL_PHONE_ALLOWED',
  NO_PHONES_ALLOWED: 'NO_PHONES_ALLOWED',
  UNLIMITED_PHONES_ALLOWED: 'UNLIMITED_PHONES_ALLOWED',
  HOME_PLAN: 'HOME_PLAN',
  HOME_PLUS_ANNUAL: 'HOME_PLUS_ANNUAL',
  HOME_PLUS_MONTHLY: 'HOME_PLUS_MONTHLY',
  HOME_REGULAR_MONTHLY: 'HOME_REGULAR_MONTHLY',
  HOME_REGULAR_ANNUAL: 'HOME_REGULAR_ANNUAL',
  BUSINESS: 'BUSINESS',
  SCREEN: 'SCREEN',
  PREACTIVATED: 'PREACTIVATED',
  PREPAID: 'PREPAID'
})
