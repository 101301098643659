<template>
  <main>
    <h1 class="f2 lh-copy pt3">
      {{ $t('planssettings.editcoverage1', { editcoverage1: 'Which plan would you like?' }) }}
    </h1>

    <div class="tc mt7-ns">
      <a
        href="mailto:billing@getakko.com"
        class="gray"
        target="_blank"
        rel="noopener noreferrer"
      >{{ $t('planssettings.editcoverage2', { editcoverage2: 'Dale Clic Aquí para Solicitar una Actualización o Cambio a tu Plan' }) }}</a> {{ $t('planssettings.editcoverage3', { editcoverage3: 'ó envía un correo a billing@getakko.com' }) }}
    </div>
  </main>
</template>

<script>
export default {
  name: 'AkEditCoverage',
  data () {
    return {
      showModal: false,
      newCoverageId: 0
    }
  },
  computed: {
    coverageOptions () {
      if (this.$store.state.user.coverage_name.includes('Adult')) {
        return this.$store.state.coverageOptions.filter(el => el.name.includes('Adult'))
      } else {
        return this.$store.state.coverageOptions.filter(el => !el.name.includes('Adult'))
      }
    },
    currentCoverage () {
      return this.$store.state.user.coverage_id
    },
    currentCoverageName () {
      return this.$store.state.user.coverage_name
    }
  },
  mounted () {
    this.$store.dispatch('getAllCoverageOptions')
    this.$store.dispatch('getUserInformation')
  },
  methods: {
    chooseCoverage (coverageId) {
      this.showModal = true
      this.newCoverageId = coverageId
    },
    submit () {
      this.showModal = false
      this.$store.dispatch('updateCoverage', {coverage_id: this.newCoverageId})
    }
  }
}
</script>

<style scoped>
.flex-basis-4 {
  flex-basis: 20em;
}
</style>
