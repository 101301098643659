<template>
  <iframe
    src="//getakko.com/app-faqs"
    frameborder="0"
    style="overflow: hidden; height: 100vh; width: 100%;"
    height="100vh"
    width="100%"
    title="Frequently Asked Questions"
  >
    Your browser does not support iFrames.
  </iframe>
</template>

<script>
export default {
  name: 'AKFaq',
  computed: {
    inApp () {
      return this.$route.path.includes('app')
    }
  }
}
</script>

<style scoped>
ul, ol {
  margin-left: 2em;
}
</style>
