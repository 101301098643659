<!--http://localhost:8080/registration/choose-multiple-phone-plan-billing-adult-->

<template>
  <main class="center-ns mb4 limit-width">
    <div>
      <!--TODO - move inline styles to classes-->
      <div class="responsive">
        <div
          class="mt4"
          style="flex:1;display:flex;flex-direction:row;flex-wrap:wrap;padding-left:1rem;padding-right:1rem;"
        >
          <div style="flex:100%">
            <div
              v-for="i in selectedPhones.length"
              :key="i"
              class="mr2 mt3 responsive-center"
              style="margin-left:auto;width:95%;max-width:800px"
            >
              <span
                style="display:flex;"
                class="mb2"
              >
                <h2
                  class="f5 db mb3"
                  style="margin-top:auto;margin-bottom:auto;text-decoration-line:underline "
                >Phone #{{ i }}</h2>
                <span
                  v-if="i > 1"
                  style="margin-left:1rem;"
                  @click="removePhone(i - 1, $event)"
                ><a
                  href=""
                  class="f6 fw4 ttc color-theme"
                >Remove</a></span>
              </span>
              <div>
                <label
                  for="make"
                  class="f6 b db mb2"
                >Make<span class="red">*</span></label>
                <select
                  id="make"
                  class="ba b--black-20 pa2 mb2 db w-100 br1"
                  :value="selectedPhones[i - 1].make"
                  @input="e => setPhoneMake(e, i - 1)"
                >
                  <option value="">
                    Select a make
                  </option>
                  <option
                    v-for="(make) in allMakes"
                    :key="make"
                    :value="make"
                  >
                    {{ make }}
                  </option>
                </select>
              </div>
              <div style="padding-bottom: 5px;">
                <label
                  for="model"
                  class="f6 b db mb2"
                >Model<span class="red">*</span></label>
                <select
                  id="model"
                  class="ba b--black-20 pa2 mb2 db w-100 br1"
                  :value="selectedPhones[i - 1].model"
                  @input="e => setPhoneModel(e, i - 1)"
                >
                  <option value="">
                    Select a model
                  </option>
                  <option
                    v-for="(model) in filterModelsBySelectedMake(selectedPhones[i - 1].make)"
                    :key="model"
                    :value="model"
                  >
                    {{ model }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="flex justify-center w-100 mt3 mb5 mw5 f7"
              style="max-width:800px"
            >
              <ak-button
                primary
                style="font-size: 1rem; line-height: 1.3;"
                @click.native="addPhone"
              >
                <pre class="cera-round-pro">{{ addPhoneButtonCaption }}</pre>
              </ak-button>
            </div>
          </div>
        </div>

        <div
          style="flex:1;padding-left:1rem;padding-right:1rem;"
          class="mb4 flex-ns flex-wrap justify-center"
        >
          <div
            class="tc mb4 pt4 lh-copy flex responsive-center"
            style="flex:100%;max-width:800px;margin-right:auto;"
          >
            <div style="flex:2;min-width:327px;">
              <input
                id="monthlyToggle1"
                v-model="monthlyToggle"
                class="dn"
                type="radio"
                :value="false"
                name="monthlyToggle"
              >
              <label
                tabindex="0"
                for="monthlyToggle1"
                class="pv2 tc b ba br1 br--right b--silver silver bg-white pointer"
                style="padding:.5rem 1rem;"
                @keyup.enter="monthlyToggle=false"
              >Annual</label>
              <span class="pv2 ph3 tc b silver">OR</span>
              <input
                id="monthlyToggle2"
                v-model="monthlyToggle"
                class="dn"
                type="radio"
                :value="true"
                name="monthlyToggle"
              >
              <label
                tabindex="0"
                for="monthlyToggle2"
                class="pv2 tc b ba br1 br--left b--silver silver bg-white pointer"
                style="padding:.5rem 1rem;"
                @keyup.enter="monthlyToggle=true"
              >Monthly</label>
            </div>
          </div>
          <div
            class="responsive-center"
            style="flex:100%; max-width:800px;margin-right:auto;"
          >
            <ak-summary-card
              :name="coverageName"
              :price="monthlyToggle ? monthly.priceWithDiscount : annual.priceWithDiscount"
              :featured="!monthlyToggle"
              :coverage-ids="monthlyToggle ? selectedMonthlyCoverageIds : selectedAnnualCoverageIds"
              :annual="!monthlyToggle"
              :savings="savings"
              :activation-fee="false"
              :plan-type="'PHONE'"
              :phone-models="selectedPhoneModels"
              :selected-coverages="monthlyToggle ? selectedMonthlyCoverages : selectedAnnualCoverages"
              :conversion-caption="'Register Now'"
              :disabled="!allPhoneDataWasSelected"
              @click="submit"
            >
              <ak-coverage-currency-toggle
                :plan-type="planType"
              />
            </ak-summary-card>
          </div>
        </div>
      </div>

      <div class="mb4 f7 f5-ns mw7-ns center-ns tc cera-round-pro">
        <span class="lh-copy">Plans can be cancelled at anytime for a pro-rated refund.</span>
        <br>
        Contact <a
          class="color-theme lh-copy"
          href="mailto:billing@getakko.com"
        >billing@getakko.com</a>
        <br>
        <br>
        <div class="black f6 mb3 mw7-ns center tc lh-copy">
          Elite Risk Insurance Solutions Lic#0G40499.
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import AkSummaryCard from '@/components/SummaryCard'
import AkButton from '@/components/Button'
import AkCoverageCurrencyToggle from '@/components/CoverageCurrencyToggle'
import math from 'lodash'
import {
  GET_REGISTRATION_COVERAGES,
  GET_REGISTRATION_INTERVAL,
  SET_REGISTRATION_COVERAGES,
  ADD_DISCOUNT_CODE, SET_REGISTRATION_DISCOUNTS_REMOVE_ALL, SET_REGISTRATION_INTERVAL,
  SET_REGISTRATION_DISCOUNTS_ADD,
  SET_REGISTRATION_DISCOUNTS_REMOVE,
  GET_TWO_YEARS_UPFRONT,
  GET_INTERNAL_DISCOUNT_VALUES,
  SET_REGISTRATION_SELECTED_COVERAGES
} from '../../store/registrationv2'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  SET_REGISTRATION_TYPE
} from '../../store/index'
import REGISTRATION_TYPE from '../../constants/registration/registrationType'
import DISCOUNT_CODE from '../../constants/registration/discountCode'
import PLAN_INTERVALS from '@/constants/registration/planIntervals'
import PLAN_INTERVAL_KEYS from '@/constants/registration/planIntervalKeys'
import PLAN_TYPES from '@/constants/registration/planTypes'
import { TWO_YEAR_UPFRONT_KEY } from '@/constants/registration/discountKeys'

import { FAMILY_PLAN_DISCOUNT } from '@/constants/registration/choosePlan'

export default {
  name: 'AkSelectMultipleCoverage',
  components: {
    AkSummaryCard,
    AkButton,
    AkCoverageCurrencyToggle
  },
  data () {
    return {
      monthlyToggle: false,
      planType: PLAN_TYPES.ADULT
    }
  },
  mounted () {
    window.fbq('trackCustom', 'Phone Select Billing Period')
    if (this.coverages && this.coverages.length > 0) this.selectedPhones = this.coverages
    this.monthlyToggle = this.interval && this.interval === PLAN_INTERVALS.MONTHLY
    this.$store.dispatch('getAllCoverageOptions')
    this.$store.dispatch('getAllPhoneModels')

    this.$store.commit(SET_REGISTRATION_TYPE, REGISTRATION_TYPE.MULTI_PHONE)
    this.resetSelectedPhones()
    if (this.selectedPhones && this.selectedPhones.length === 0) this.removeAllDiscountCodes()
  },
  computed: {
    ...mapState({
      selectedPhones: state => state.registrationv2.selectedPhones
    }),
    ...mapGetters({
      coverages: GET_REGISTRATION_COVERAGES,
      interval: GET_REGISTRATION_INTERVAL,
      twoYearUpfrontSelected: GET_TWO_YEARS_UPFRONT,
      internalDiscountValues: GET_INTERNAL_DISCOUNT_VALUES,
      getPhoneCoverageData: 'getPhoneCoverageData',
      selectedPhoneModels: 'selectedPhoneModels',
      getDeviceModelData: 'getDeviceModelData',
      multiPhonePlanPrice: 'multiPhonePlanPrice'
    }),
    allPhoneDataWasSelected () {
      for (let phoneIndex in this.selectedPhones) {
        if (!this.selectedPhones[phoneIndex].make || !this.selectedPhones[phoneIndex].model) return false
      }
      return true
    },
    addPhoneButtonCaption () {
      if (this.selectedPhones.length > 1) return 'Add Another Phone'
      return `Need to protect another phone? \nClick here and save an additional 5%`
    },
    totalPlanPrices () {
      let coverageIdKeys = [PLAN_INTERVAL_KEYS.ANNUAL, PLAN_INTERVAL_KEYS.MONTHLY]
      let [annualPrices, monthlyPrices] = this.multiPhonePlanPrice(coverageIdKeys, FAMILY_PLAN_DISCOUNT)
      return {
        monthlyPrices,
        annualPrices
      }
    },
    monthly () {
      return this.totalPlanPrices.monthlyPrices
    },
    annual () {
      return this.totalPlanPrices.annualPrices
    },
    coverageName () {
      return 'AKKO Phone Protection Plan'
    },
    savings () {
      return math.round((this.monthly.priceWithDiscount * 12) - this.annual.priceWithDiscount)
    },
    allMakes () {
      return new Set(['Apple', 'Samsung', 'Google', ...this.$store.state.allPhoneModels.map(model => model.make).sort()])
    },
    allModels () {
      return new Set(this.$store.state.allPhoneModels.map(model => model.model).sort())
    },
    selectedMonthlyCoverages () {
      return this.selectedPhones.map((phone, index) => ({
        primary: index === 0,
        ...this.getMonthlyCoverageData(phone.make, phone.model)
      })).filter(coverage => coverage !== undefined)
    },
    selectedMonthlyCoverageIds () {
      return this.selectedMonthlyCoverages.map(coverage => coverage.id)
    },
    selectedAnnualCoverages () {
      return this.selectedPhones.map((phone, index) => ({
        primary: index === 0,
        ...this.getAnnualCoverageData(phone.make, phone.model)
      })).filter(coverage => coverage !== undefined)
    },
    selectedAnnualCoverageIds () {
      return this.selectedAnnualCoverages.map(coverage => coverage && coverage.id)
    },
    plans () {
      const plans = this.monthlyToggle ? this.selectedMonthlyCoverages : this.selectedAnnualCoverages
      plans.forEach(plan => (plan['phoneError'] = null))

      return plans
    },
    selectedPlanInterval () {
      return this.monthlyToggle ? PLAN_INTERVALS.MONTHLY : PLAN_INTERVALS.ANNUAL
    }
  },
  methods: {
    ...mapActions({
      addDiscountCode: ADD_DISCOUNT_CODE,
      setPhoneMakeAtIndex: 'setPhoneMakeAtIndex',
      setPhoneModelAtIndex: 'setPhoneModelAtIndex',
      addPhone: 'addPhoneToPlan',
      resetSelectedPhones: 'resetSelectedPhones',
      removePhoneAtIndex: 'removePhoneAtIndex'
    }),
    ...mapMutations({
      removeAllDiscountCodes: SET_REGISTRATION_DISCOUNTS_REMOVE_ALL,
      removeDiscountCode: SET_REGISTRATION_DISCOUNTS_REMOVE,
      setCoverages: SET_REGISTRATION_COVERAGES,
      setInterval: SET_REGISTRATION_INTERVAL,
      addDiscount: SET_REGISTRATION_DISCOUNTS_ADD,
      removeDiscount: SET_REGISTRATION_DISCOUNTS_REMOVE,
      setSelectedCoverages: SET_REGISTRATION_SELECTED_COVERAGES
    }),

    submit () {
      this.setCoverages(this.plans)
      this.setSelectedCoverages({
        monthly: {
          coverages: this.selectedMonthlyCoverages,
          totalPrice: this.monthly.totalPrice
        },
        annual: {
          coverages: this.selectedAnnualCoverages,
          totalPrice: this.annual.totalPrice
        }
      })
      this.setInterval(this.monthlyToggle ? PLAN_INTERVALS.MONTHLY : PLAN_INTERVALS.ANNUAL)
      if (this.plans.length > 1) this.addDiscountCode({code: DISCOUNT_CODE.FAMILY_PLAN})
      else this.removeDiscountCode({code: DISCOUNT_CODE.FAMILY_PLAN})

      const updateDiscount = this.twoYearUpfrontSelected ? this.addDiscount : this.removeDiscount
      const twoYearUpfrontDiscount = this.internalDiscountValues[TWO_YEAR_UPFRONT_KEY]
      updateDiscount(twoYearUpfrontDiscount)

      this.$router.push(`/registration-v2/checkout`)
    },
    removePhone (index, event) {
      this.removePhoneAtIndex(index)
      event.preventDefault()
    },
    filterModelsBySelectedMake (make) {
      return new Set(this.$store.state.allPhoneModels.filter(makeModel => makeModel.make === make)
        .sort((a, b) => {
          if (a.display_order && b.display_order) {
            return a.display_order - b.display_order
          } else if (a.display_order && !b.display_order) {
            return 1
          } else if (!a.display_order && b.display_order) {
            return -1
          } else {
            return a.make.localeCompare(b.make)
          }
        }).map(makeModel => makeModel.model))
    },
    getAnnualCoverageData (make, model) {
      return this.getPhoneCoverageData(make, model, PLAN_INTERVAL_KEYS.ANNUAL)
    },
    getMonthlyCoverageData (make, model) {
      return this.getPhoneCoverageData(make, model, PLAN_INTERVAL_KEYS.MONTHLY)
    },
    setPhoneMake (event, index) {
      this.setPhoneMakeAtIndex({index, value: event.target.value})
    },
    setPhoneModel (event, index) {
      let deviceMake = this.selectedPhones[index].make
      let deviceModel = event.target.value
      let validCoverage = this.monthlyToggle
        ? this.getMonthlyCoverageData(deviceMake, deviceModel)
        : this.getAnnualCoverageData(deviceMake, deviceModel)

      if (validCoverage === undefined) {
        this.setPhoneModelAtIndex({index, value: ''})
      } else {
        this.setPhoneModelAtIndex({index, value: deviceModel})
      }
    }
  }
}
</script>

<style scoped>
.limit-width {
  max-width: 1000px;
}
select {
  height:2.5rem;
}
input[type=radio]:checked + label {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}
@media (max-width: 992px) {
  .responsive-center {
    margin-left:auto;
    margin-right:auto;
  }
}
@media (min-width: 992px) {
  .responsive {
    display: flex;
    flex-direction: row;
  }
}
</style>
