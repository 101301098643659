<template>
  <main>
    <div class="height flex items-center justify-between">
      <span class="f2 lh-copy pt3">Add a New Phone-Only Plan</span>
    </div>
    <ak-alert
      v-if="error"
      color="red"
    >
      <span>{{ error }}</span>
    </ak-alert>
    <div class="grid-half mr3-ns flex-grow flex-basis-2 mb4 mt4">
      <div style="flex:100%">
        <div
          v-for="i in selectedPhones.length"
          :key="i"
          class="mr2 mt3 responsive-center"
          style="margin-left:auto;max-width:800px"
        >
          <div>
            <!-- Shouldn't be "Brand" intead of "Make"? -->
            <label
              for="make"
              class="f6 b db mb2 inter"
            >{{ $t('planssettings.phoneonly.make') }}<span class="red">*</span></label>
            <select
              id="make"
              v-model="selectedPhones[i - 1].make"
              type="text"
              class="ba b--black-20 pa2 mb2 db w-100 br1 placeholder"
              @change="selectedPhones[i - 1].model = ''"
            >
              <option
                class="placeholder"
                value=""
              >
                {{ $t('planssettings.phoneonly.selectmake') }}
              </option>
              <option
                v-for="(make) in allMakes"
                :key="make"
                :value="make"
              >
                {{ make }}
              </option>
            </select>
          </div>

          <div class="mt4">
            <label
              for="model"
              class="f6 b db mb2 inter"
            >{{ $t('planssettings.phoneonly.model') }}<span class="red">*</span></label>
            <select
              id="model"
              v-model="selectedPhones[i - 1].model"
              class="ba b--black-20 pa2 mb2 db w-100 br1 placeholder"
            >
              <option
                class="placeholder"
                value=""
              >
                {{ $t('planssettings.phoneonly.selectmodel') }}
              </option>
              <option
                v-for="(model) in filterModelsBySelectedMake(selectedPhones[i - 1].make)"
                :key="model"
                :value="model"
              >
                {{ model }}
              </option>
            </select>
          </div>
          <div class="mt4">
            <label
              for="make"
              class="f6 b db mb2 inter"
            >{{ $t('planssettings.phoneonly.usersphone') }}<span class="red">*</span></label>
            <input
              id="phone"
              v-model="phone"
              class="ba b--black-20 pa2 mb2 db w-100 br1 placeholder"
              type="text"
              @input="acceptNumber"
              @focus="toggleKeyboard(true)"
              @blur="phoneOnBlur"
            >
          </div>
          <div v-show="errorList.length > 0">
            <span
              v-for="(e, index) in errorList"
              :key="index"
              class="red f7"
            >{{ e.message || e }}</span>
          </div>
        </div>
      </div>
      <div
        style="flex:1;padding-left:1rem;padding-right:1rem;"
        class="mb4 flex-ns flex-wrap justify-center"
      >
        <div
          class="responsive-center"
          style="flex:100%; max-width:800px;margin-right:auto;"
        >
          <ak-summary-card
            :name="$t('planssettings.phoneonly.name')"
            :price="isMonthly ? monthlyPrice : annualPrice"
            :featured="!isMonthly"
            :coverage-ids="isMonthly ? selectedMonthlyCoverageIds : selectedAnnualCoverageIds"
            :annual="!isMonthly"
            :activation-fee="false"
            :plan-type="$t('planssettings.phoneonly.type')"
            :phone-models="selectedPhoneModels"
            :selected-coverages="isMonthly ? selectedMonthlyCoverages : selectedAnnualCoverages"
            :conversion-caption="$t('planssettings.phoneonly.caption')"
            :disabled="!allPhoneDataWasSelected"
            @click="openConfirmationModal"
          />
        </div>
      </div>
    </div>

    <ak-modal
      v-if="showConfirmationModal"
      title="Confirm adding new plan"
      @close="resetState"
    >
      <div slot="modal-body">
        <div>
          {{ $t('planssettings.phoneonly.adding') }}
          <br>
          <br>
          <div class="flex flex-column justify-between pl4 h3">
            <span>{{ $t('planssettings.phoneonly.plan') }} {{ selectedCoverage.name }}<br></span>
            <span>{{ $t('planssettings.phoneonly.number') }} {{ phone }}<br></span>
            <span>{{ $t('planssettings.phoneonly.charge') }} ${{ coveragePriceWithDiscount }} {{ $t('planssettings.phoneonly.more') }} {{ selectedCoverage.plan_interval === $t('planssettings.phoneonly.annual') ? $t('planssettings.phoneonly.billedannual') : $t('planssettings.phoneonly.monthly') }}<br></span>
          </div>
        </div>
      </div>

      <div
        slot="modal-footer"
        class="pa3 flex flex-wrap justify-between justify-end-ns"
      >
        <div class="mr3 flex-grow flex-grow-off-ns">
          <ak-button
            secondary
            block
            @click.native="resetState"
          >
            {{ $t('planssettings.phoneonly.nothanks') }}
          </ak-button>
        </div>
        <div class="flex-grow flex-grow-off-ns">
          <ak-button
            primary
            block
            @click.native="submit"
          >
            {{ $t('planssettings.phoneonly.confirm') }}
          </ak-button>
        </div>
      </div>
    </ak-modal>
  </main>
</template>

<script>
import AkSummaryCard from '@/components/SummaryCard'
import AkButton from '@/components/Button'
import AkModal from '@/components/Modal'
import AkAlert from '@/components/Alert'
import { CHECK_GENERAL_ERRORS } from '../../store/registrationv2'
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  SET_REGISTRATION_TYPE,
  SET_ADD_PLAN_ERROR
} from '../../store/index'
import REGISTRATION_TYPE from '../../constants/registration/registrationType'
import PLAN_INTERVAL_KEYS from '@/constants/registration/planIntervalKeys'
import { isEmpty } from 'lodash'
import PLAN_TYPES from '@/constants/registration/planTypes'

export default {
  name: 'AkAddPhoneOnlyPlan',
  components: {
    AkSummaryCard,
    AkButton,
    AkModal,
    AkAlert
  },
  data () {
    return {
      phone: '',
      planType: PLAN_TYPES.ADULT,
      showConfirmationModal: false,
      selectedPhones: [
        {
          make: '',
          model: ''
        }
      ]
    }
  },
  mounted () {
    window.fbq('trackCustom', 'Phone Select Billing Period')
    this.getAllPhoneModels()
    this.getFilteredCoverageOptions({
      currency_code: this.selectedCountryCurrency.currency_code,
      plan_interval: this.currentUser.coverage_plan_interval,
      plan_type: this.planType
    })
    this.$store.commit(SET_REGISTRATION_TYPE, REGISTRATION_TYPE.MULTI_PHONE)
    this.$store.commit(SET_ADD_PLAN_ERROR)
  },
  computed: {
    ...mapState({
      selectedCountryCurrency: state => state.registrationv2.selectedCountryCurrency,
      currentUser: state => state.user
    }),
    ...mapGetters({
      getPhoneCoverageData: 'getPhoneCoverageData',
      selectedPhoneModels: 'selectedPhoneModels',
      getDeviceModelData: 'getDeviceModelData',
      multiPhonePlanPrice: 'multiPhonePlanPrice'
    }),
    coveragePriceWithDiscount () {
      const discounts = this.currentUser.discounts
      const emptyDiscounts = !discounts || discounts.length === 0
      const defaultDiscount = Number((this.selectedCoverage.price * 0.05).toFixed(2))
      const discount = emptyDiscounts ? defaultDiscount : discounts.reduce((accumulativeDiscount, currentDiscount) => {
        return this.calculateDiscountAmount(currentDiscount, accumulativeDiscount)
      }, 0)

      const twoDecimalsPrice = (this.selectedCoverage.price - discount).toFixed(2)
      const priceWithDiscount = Number(twoDecimalsPrice)
      return priceWithDiscount > 0 ? twoDecimalsPrice : 0
    },
    errorList () {
      return this.$store.state.errors
    },
    allPhoneDataWasSelected () {
      if (!this.phone || !this.validPhoneNumber || !isEmpty(this.errorList)) return false
      for (let phoneIndex in this.selectedPhones) {
        if (!this.selectedPhones[phoneIndex].make || !this.selectedPhones[phoneIndex].model) return false
      }
      return true
    },
    validPhoneNumber () {
      return [10, 11].includes(this.phone.replace(/[^0-9]/g, '').length)
    },
    currentUser () {
      return this.$store.state.user
    },
    isMonthly () {
      return this.currentUser.coverage_plan_interval === 'monthly'
    },
    monthlyPrice () {
      let sum = 0

      for (let i in this.selectedPhones.filter(phone => phone.make !== '' && phone.model !== '')) {
        const phone = this.getModelData(this.selectedPhones[i].make, this.selectedPhones[i].model)
        const phoneModelMonthly = this.$store.state.coverageOptions.find(
          el => el.id === phone.monthly_coverage_id)

        sum += phoneModelMonthly ? phoneModelMonthly.price : 0
      }

      return this.selectedPhones.filter(phone => phone.make !== '' && phone.model !== '')
        .length > 1 ? sum * 0.95 : sum
    },
    annualPrice () {
      let sum = 0

      for (let i in this.selectedPhones.filter(phone => phone.make !== '' && phone.model !== '')) {
        const phone = this.getModelData(this.selectedPhones[i].make, this.selectedPhones[i].model)
        const phoneModelAnnual = this.$store.state.coverageOptions.find(
          el => el.id === phone.annual_coverage_id)

        sum += phoneModelAnnual ? phoneModelAnnual.price : 0
      }

      return this.selectedPhones.filter(phone => phone.make !== '' && phone.model !== '')
        .length > 1 ? sum * 0.95 : sum
    },
    allMakes () {
      return new Set(['Apple', 'Samsung', 'Google', ...this.$store.state.allPhoneModels.map(model => model.make).sort()])
    },
    selectedMonthlyCoverages () {
      return this.selectedPhones.map(phone => this.getMonthlyCoverageData(phone.make, phone.model))
        .filter(coverage => coverage !== undefined)
    },
    selectedMonthlyCoverageIds () {
      return this.selectedMonthlyCoverages.map(coverage => coverage.id)
    },
    selectedAnnualCoverages () {
      return this.selectedPhones.map(phone => this.getAnnualCoverageData(phone.make, phone.model))
        .filter(coverage => coverage !== undefined)
    },
    selectedAnnualCoverageIds () {
      return this.selectedAnnualCoverages.map(coverage => coverage && coverage.id)
    },
    selectedPhoneModels () {
      const filteredSelectedPhones = this.selectedPhones.filter(phone => phone.make !== '' && phone.model !== '')
      const phoneModels = filteredSelectedPhones.map(phone => this.getModelData(phone.make, phone.model))
      return phoneModels
    },
    selectedCoverage () {
      return this.isMonthly ? this.selectedMonthlyCoverages[0] : this.selectedAnnualCoverages[0]
    },
    error () {
      return this.$store.state.addPlanError
    }
  },
  methods: {
    ...mapActions({
      checkPhone: CHECK_GENERAL_ERRORS,
      getFilteredCoverageOptions: 'getFilteredCoverageOptions',
      getAllPhoneModels: 'getAllPhoneModels'
    }),
    resetState () {
      this.showConfirmationModal = false
      this.phone = ''
      this.planType = 'adult'
      this.selectedPhones = [
        {
          make: '',
          model: ''
        }
      ]
    },
    calculateDiscountAmount (discount, existingDiscount = 0) {
      let discountAmount = 0
      const intervalMonths = this.selectedCoverage.plan_interval === 'monthly' ? 12 : 1
      const numberMonths = Math.min(discount.number_months || Infinity, intervalMonths)
      if (discount.amount_type === 'FIXED') {
        discountAmount = existingDiscount + discount.amount * numberMonths
      } else if (discount.amount_type === 'PERCENT') {
        discountAmount = (this.selectedCoverage.price - existingDiscount / intervalMonths) * discount.amount / 100 * numberMonths
      }

      return Number(discountAmount.toFixed(2))
    },
    openConfirmationModal () {
      this.showConfirmationModal = true
    },
    submit () {
      this.$store.dispatch('addPlan', {
        coverage: this.selectedCoverage,
        phoneNumber: this.phone
      })
      this.resetState()
    },
    filterModelsBySelectedMake (make) {
      return new Set(this.$store.state.allPhoneModels.filter(makeModel => makeModel.make === make)
        .sort((a, b) => {
          if (a.display_order && b.display_order) {
            return a.display_order - b.display_order
          } else if (a.display_order && !b.display_order) {
            return 1
          } else if (!a.display_order && b.display_order) {
            return -1
          } else {
            return a.make.localeCompare(b.make)
          }
        }).map(makeModel => makeModel.model))
    },
    getModelData (make, model) {
      return this.$store.state.allPhoneModels.find(phone => phone.make === make && phone.model === model)
    },
    getAnnualCoverageData (make, model) {
      return this.getPhoneCoverageData(make, model, PLAN_INTERVAL_KEYS.ANNUAL)
    },
    getMonthlyCoverageData (make, model) {
      return this.getPhoneCoverageData(make, model, PLAN_INTERVAL_KEYS.MONTHLY)
    },
    acceptNumber () {
      const formattedNumber = this.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.phone = !formattedNumber[2] ? formattedNumber[1] : '(' + formattedNumber[1] + ') ' + formattedNumber[2] + (formattedNumber[3] ? '-' + formattedNumber[3] : '')
    },
    toggleKeyboard (trueOrFalse) {
      this.$store.commit('TOGGLE_KEYBOARD', trueOrFalse)
    },
    phoneOnBlur () {
      this.toggleKeyboard(false)
      this.checkPhone({phone: this.phone})
    }
  }
}
</script>

<style scoped>


input, select {
  height: 40px;
  border-radius: 5px;
  border: 1px solid #E8E8E8;
}


label, legend {
  color: #757D8A;
}


.height {
  height: 50px;
}
.flex-basis-4 {
  flex-basis: 16em;
}
.flex-basis-3 {
  flex-basis: 12em;
}

.placeholder {
  color: #7e7e7e;
}
.flex-basis-25 {
  flex-basis: 23%;
}
input[type=radio]:checked + label{
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
}
.grid-fourth {
  grid-column-gap: 1.5em;
  grid-row-gap: 2.25em;
}
.not-allowed-cursor {
  cursor: not-allowed;
}

/* Hide input number arrows in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide input number arrows in  Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 768px) {

.height {
  height: auto;
}
}
</style>
