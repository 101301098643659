<template>
  <div
    v-if="currentStep === 0"
    class="f5 db mb3"
  >
    <label
      for="redemptionCode"
      class="cera-round-pro fw6 db mb3 title"
    >
      Activation Code
    </label>
    <div class="f6 silver inter mb4">
      Enter your activation code below and click the red "submit" button
      before continuing.
    </div>
    <br>
    <div class="input-group-flex flex flex-row mb2 w-100">
      <input
        id="redemptionCode"
        v-focus
        class="inter f6 input-reset w-40 ba db mr3 br2 code-input ttu"
        type="text"
        :value="value"
        placeholder="Activation Code"
        @keyup.enter="submit"
        @input="onChangeValue"
      >
      <ak-button
        :new-disabled="!value"
        new-secondary
        shadow
        @click.native="submit"
      >
        Submit
      </ak-button>
    </div>
    <code-message
      v-if="$store.state.registration &&
        $store.state.activation &&
        $store.state.registration.redemption &&
        $store.state.activation.coverage &&
        showMessage"
    />
  </div>
</template>

<script>
import AkButton from '@/components/Button'
import CodeMessage from './CodeMessage.vue'

export default {
  name: 'ActivationCode',
  components: {
    AkButton,
    CodeMessage
  },
  props: {
    currentStep: {
      type: Number,
      default: 0
    },
    checkRedemptionCode: {
      type: Function,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showMessage: true
    }
  },
  methods: {
    onChangeValue (event) {
      this.showMessage = false
      this.$emit('input', event.target.value)
    },
    submit () {
      this.showMessage = true
      this.checkRedemptionCode()
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 20px;
  }
  .code-input {
    border-color: var(--theme-gray);
    padding: 10px 15px;
  }
  .code-input::placeholder {
    color: var(--theme-gray);
    text-transform: capitalize;
  }
  .code-input:focus {
    border-color: var(--theme-gray);
    outline: none;
  }
  @media screen and (max-width: 500px) {
    .code-input {
      width: 70%;
    }
  }
</style>
