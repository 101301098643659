<template>
  <main class="mt3 responsive-margin">
    <div class="mt3 flex flex-column inter responsive-none">
      <div class="flex f5 top-container-realign items-center mb2 flex-row">
        <div class="pl4 silver">
          <router-link
            class="no-underline account-hover soft-grey "
            to="/account/dashboard"
          >
            {{ $t('menu.accountsettings', { accountsettings: 'Account Settings' }) }}
          </router-link>
        </div>
        <div class="soft-grey">
          &nbsp;  /  &nbsp;
        </div>
        <div class="soft-grey">
          {{ $t('accountsettings.personalinformationheader', { personalinformationheader: 'Personal Information' }) }}
        </div>
      </div>
      <h2 class="pl4 f2 mb3 title-realign cera-round-pro">
        {{ $t('accountsettings.personalinformationheader', { personalinformationheader: 'Personal Information' }) }}
      </h2>
    </div>
    <div class="flex flex-row">
      <div class="width inter responsive">
        <PersonalInformation />
      </div>
      <div
        class="w-40 responsive-none"
        style="visibility: hidden"
      >
        <RightComponent image="personal-info-secure.png" />
      </div>
    </div>
  </main>
</template>

<script>

import PersonalInformation from './PersonalInformation.vue'
import RightComponent from '../RightComponent.vue'

export default {
  name: 'UnifiedAccountSettings',
  components: {
    RightComponent,
    PersonalInformation
  },
  data () {
    return {
      personal: 'personal'
    }
  },
  computed: {},
  mounted () {
  },
  beforeDestroy () {
  },
  unmounted () {
    localStorage.setItem('scrollPosition', {y: 0, x: 0})
  },
  methods: {}
}
</script>

<style scoped>

.width {
  width: 100%;
}

@media (max-width: 1280px) {
  .responsive-margin {
    margin-top: 0;
  }
}

.title-realign {
  margin-left: -3px
}

.top-container-realign {
  margin-top: 1px;
}


.soft-grey {
  color: #9BA1AB;

}

.account-hover {
  cursor: pointer;

}

.account-hover:hover {
  color: #7A7A7C;
  text-decoration: underline;
}

.mr25 {
  margin-right: .75rem;
}

.chevron {
  color: #9BA1AB;
  height: 17px;
  width: 17px;
}

@media (max-width: 768px) {
  .responsive-none {
    display: none;
  }

  .responsive {
    width: 100%;
  }

  .mt4 {
    margin-top: auto;
  }

  .responsive-margin {
    margin-top: 0;
  }
}

</style>
