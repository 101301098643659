<template>
  <main>
    <h1 class="f2 lh-copy pt3">
      College Mentor
    </h1>

    <div class="mt4">
      <div class="mb4 f3">
        Ask for advice. Discuss any concerns.
      </div>
    </div>
    <div class="grid-third mv4">
      <a
        href="mailto:campus-mentor@akkocollegecoverage.com"
        class="no-underline"
      >
        <ak-tile
          header="Email"
          subheader="Send us a message"
          classlist="hover-lift"
        />
      </a>

      <a
        :href=" isMobile ? 'http://m.me/AKKOCollegeCoverage' : 'https://www.facebook.com/messages/t/AKKOcollegecoverage'"
        class="no-underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ak-tile
          header="Chat"
          subheader="Chat with us on Facebook"
          classlist="hover-lift"
        />
      </a>

      <a
        href="https://akkocollegecoverage.setmore.com"
        class="no-underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ak-tile
          header="Schedule"
          subheader="1-on-1 Appointment with a mentor"
          classlist="hover-lift"
        />
      </a>
    </div>
  </main>
</template>

<script>
import AkTile from './Tile'

export default {
  name: 'ContactPage',
  components: {
    AkTile
  },
  computed: {
    isMobile () {
      return (window.orientation !== undefined) || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  }
}
</script>

<style scoped>
</style>
