<template>
  <main>
    <div class="flex items-end ">
      <span class="f2 b title-responsive">Add new item</span>
    </div>
    <div v-if="!userHasAccess">
      <ak-alert color="red">
        <span>
          {{ membershipStatusDisplayValues.spanText }}
          <span v-if="user.membership_status === 'UNPAID' || user.membership_status === 'PAST_DUE'">
            <router-link
              class="color-theme"
              to="/account/dashboard"
            >Account Settings</router-link> to resolve or
          </span>
        </span>
        <a
          class="color-theme"
          :href="membershipStatusDisplayValues.emailHref"
        >
          contact our billing team.
        </a>
      </ak-alert>
    </div>
    <router-link
      class="no-underline mv4 db"
      to="/add-device-category"
    >
      <div class="color-theme">
        &larr; View all categories
      </div>
    </router-link>
    <div
      class="grid-fourth mt4"
      :class="[ !userHasAccess ? 'pb5' : '' ]"
    >
      <div>
        <label
          class="f6 db mb2 inter"
          for="device_type"
        >Item<span class="red">*</span></label>
        <select
          id="device_type"
          v-model="itemTypeIndex"
          class="b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @change="changeType"
        >
          <option :value="-1">
            Select a type of item
          </option>
          <option
            v-for="(type, index) in itemTypes"
            :key="index"
            :value="index"
          >
            {{ type.name }}
          </option>
        </select>
      </div>
      <div>
        <label
          class="f6 db mb2 inter"
          for="brand"
        >Brand<span class="red">*</span></label>
        <select
          v-if="itemMakes.length > 0"
          id="brand"
          v-model="itemMakeIndex"
          class="ba b--black-20 pa2 mb2 db w-100 br1"
          @change="changeMake"
        >
          <option
            class="placeholder"
            :value="-1"
          >
            Select a brand
          </option>
          <option
            v-for="(make, index) in itemMakes"
            :key="index"
            :value="index"
          >
            {{ make.name }}
          </option>
          <option
            v-if="itemTypeName !== 'Phone'"
            value="other"
          >
            Other
          </option>
        </select>
        <input
          v-else
          id="brand"
          v-model="itemMakeString"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>
      <div>
        <label
          class="f6 db mb2 inter"
          for="model"
        >Model<span class="red">*</span></label>
        <select
          v-if="itemModels.length > 0"
          id="model"
          v-model="itemModelIndex"
          class="ba b--black-20 pa2 mb2 db w-100 br1"
          @change="changeModel"
        >
          <option value="-1">
            Select a model
          </option>
          <option
            v-for="(model, index) in itemModels"
            :key="index"
            :value="index"
          >
            {{ model.name }}
          </option>
          <option
            v-if="itemTypeName !== 'Phone'"
            value="other"
          >
            Other
          </option>
        </select>
        <input
          v-else
          id="model"
          v-model="itemModelString"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>
      <div>
        <label
          class="f6 db mb2 inter"
          for="price"
        >Purchase price<span class="red">*</span></label>
        <div class="flex">
          <input
            id="price"
            v-model.number="price"
            class="flex-grow br5-box-left input-reset ba br--left b--black-20 pa2 br1 tr"
            type="number"
            @focus="toggleKeyboard(true)"
          >
          <div class="flex items-center inter br5-box-right bg-black-10 pa2">
            .00
          </div>
        </div>
      </div>
      <div v-if="device_type === 'laptop'">
        <label
          class="f6 db mb2 inter"
          for="model_number"
        >Model
          {{ itemMakeName === 'Apple' ? 'Year' : 'Number' }}</label>
        <select
          v-if="brand === 'Apple' && device_type === 'laptop'"
          id="model_number"
          v-model="model_number"
          :class="model_number === '' ? 'placeholder' : ''"
          class="ba b--black-20 pa2 mb2 db w-100 br1"
        >
          <option
            class="placeholder"
            value=""
          >
            Select a year
          </option>
          <option
            v-for="(year) in available_years"
            :key="year"
            :value="year"
          >
            {{ year }}
          </option>
        </select>
        <input
          v-else
          id="model_number"
          v-model="model_number"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>
      <div
        v-if="(device_type === 'laptop' || device_type === 'tablet' || device_type === 'tv')"
      >
        <label
          class="f6 db mb2 inter"
          for="screen_size"
        >Screen Size</label>
        <div class="flex">
          <input
            id="screen_size"
            v-model.number="screen_size"
            class="flex-grow input-reset br5-box-left ba br--left b--black-20 pa2 mb2 br1 tr"
            type="tel"
            @blur="toggleKeyboard(false)"
            @focus="toggleKeyboard(true)"
          >
          <div class="flex items-center inter br5-box-right bg-black-10 pa2 mb2">
            in.
          </div>
        </div>
      </div>
      <div v-if="device_type === 'laptop' || device_type === 'phone'">
        <label
          class="f6 db mb2 inter"
          for="storage"
        >Storage</label>
        <div class="flex">
          <input
            id="storage"
            v-model.number="storage"
            class="flex-grow input-reset br5-box-left ba br--left b--black-20 w-60 pa2 mb2 br1 tr"
            type="tel"
            @blur="toggleKeyboard(false)"
            @focus="toggleKeyboard(true)"
          >
          <div class="flex items-center inter br5-box-right bg-black-10 pa2 mb2">
            GB
          </div>
        </div>
      </div>
      <div v-if="device_type === 'phone'">
        <label
          class="f6 db mb2 inter"
          for="carrier"
        >Carrier</label>
        <select
          id="carrier"
          v-model="carrier"
          :class="carrier === '' ? 'placeholder' : ''"
          class="b--black-20 pa2 mb2 db w-100 br1"
        >
          <option value="">
            Select a carrier
          </option>
          <option
            v-for="(carrier, idx) in mobileOperators"
            :key="idx"
            :value="carrier.id"
          >
            {{ carrier.name }}
          </option>
        </select>
      </div>
      <div v-if="device_type === 'phone' && isOtherMobileCarrierSelected">
        <label
          class="f6 db mb2 inter"
          for="storage"
        >Other Carrier</label>
        <div class="flex">
          <input
            id="device_carrier_other"
            v-model="device_carrier_other"
            class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
            type="text"
            @blur="toggleKeyboard(false)"
            @focus="toggleKeyboard(true)"
          >
        </div>
      </div>
      <div v-if="device_type === 'phone'">
        <fieldset class="input-reset bn pa0 ma0">
          <legend class="inter f6 b db mb2">
            Do you pay in installments
          </legend>
          <div class="flex justify-around">
            <input
              id="installments1"
              v-model="installments"
              :value="true"
              class="dn"
              name="installments"
              type="radio"
            >
            <label
              class="flex-grow pv2 inter b br-0 ba tc br5-box-left br--left b--grey silver bg-white pointer"
              for="installments1"
              tabindex="0"
              @keyup.enter="installments=true"
            >Yes</label>
            <input
              id="installments2"
              v-model="installments"
              :value="false"
              class="dn"
              name="installments"
              type="radio"
            >
            <label
              class="flex-grow pv2 inter bl-0 b ba tc br5-box-right br--right b--grey silver bg-white pointer"
              for="installments2"
              tabindex="0"
              @keyup.enter="installments=false"
            >No</label>
          </div>
        </fieldset>
      </div>
      <div v-if="device_type === 'laptop' || device_type === 'tv'">
        <fieldset class="input-reset bn pa0 ma0">
          <legend class="f6 inter b db mb2">
            {{ device_type === 'tv' ? 'Is it a smart TV' : 'Is it touchscreen' }}
          </legend>
          <div class="flex justify-around">
            <input
              id="touchscreen1"
              v-model="touchscreen"
              :value="true"
              class="dn"
              name="touchscreen"
              type="radio"
            >
            <label
              class="flex-grow pv2 tc b ba br-0 br5-box-left b--grey silver bg-white pointer"
              for="touchscreen1"
              tabindex="0"
              @keyup.enter="touchscreen=true"
            >Yes</label>
            <input
              id="touchscreen2"
              v-model="touchscreen"
              :value="false"
              class="dn"
              name="touchscreen"
              type="radio"
            >
            <label
              class="flex-grow pv2 tc b ba bl-0 br5-box-right b--grey silver bg-white pointer"
              for="touchscreen2"
              tabindex="0"
              @keyup.enter="touchscreen=false"
            >No</label>
          </div>
        </fieldset>
      </div>
      <div>
        <label class="f6 db mb2 inter">Condition when purchased<span class="red">*</span></label>
        <select
          v-model="condition"
          class="b--black-20 pa2 mb2 db w-100 br1"
        >
          <option
            v-for="(condition, idx) in itemPurchaseConditions"
            :key="idx"
            :value="condition.id"
          >
            {{ condition.description }}
          </option>
        </select>
      </div>
      <div>
        <label
          class="f6 db mb2 inter"
          for="nickname"
        >Where was it purchased?<span class="red">*</span></label>
        <input
          id="purchased_at"
          v-model="purchased_at"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>
      <div>
        <label
          class="f6 db mb2 inter"
          for="nickname"
        >When was it purchased?<span class="red">*</span></label>
        <input
          id="purchase_date"
          v-model="purchase_date"
          :max="todayString"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="date"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>
      <div>
        <label
          class="f6 db mb2 inter"
          for="serial_number"
        >Serial Number<span
          v-if="device_type === 'tablet' || device_type === 'phone' || device_type === 'laptop'"
          class="red"
        >*</span></label>
        <input
          id="serial_number"
          v-model="serial_number"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
        <a
          v-if="device_type === 'phone'"
          class="red inter f7 no-underline"
          href="https://help.getakko.com/en/articles/4641960-how-to-find-my-serial-number-on-an-iphone-samsung-or-google-phone"
          target="_blank"
        >Click
          here to find your serial number</a>
      </div>
      <div v-if="device_type === 'phone'">
        <label
          class="f6 db mb2 inter"
          for="imei"
        >IMEI<span class="red">*</span></label>
        <input
          id="imei"
          v-model="imei"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          maxlength="16"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
        <a
          v-if="device_type === 'phone'"
          class="red inter f7 no-underline"
          href="https://help.getakko.com/en/articles/6082165-how-to-find-my-imei-number-on-an-iphone-samsung-or-google-phone"
          target="_blank"
        >Click
          here to find your IMEI number</a>
      </div>
      <div>
        <label
          class="f6 db mb2 inter"
          for="nickname"
        >Nickname<span class="red">*</span></label>
        <input
          id="nickname"
          v-model="nickname"
          class="input-reset ba b--black-20 pa2 mb2 db w-100 br1"
          type="text"
          @blur="toggleKeyboard(false)"
          @focus="toggleKeyboard(true)"
        >
      </div>
    </div>
    <ak-alert
      v-if="$store.state.errors.length"
      color="red"
    >
      <span>{{ $store.state.errors[0] }}</span>
    </ak-alert>
    <div
      v-if="userHasAccess"
      class="mt4 mb5 mw5-ns"
    >
      <ak-button
        :class="submitBtnClass"
        :disabled="!formComplete"
        block
        primary
        @click.native="addDevice"
      >
        Add Item
      </ak-button>
    </div>
    <ak-modal
      v-if="errorDetected"
      title="Unable to Add Device to Account"
      @close="resetState"
    >
      <div slot="modal-body">
        <div class="mb4">
          {{ restrictionLimitReachedMessage }}
        </div>
      </div>
      <div
        slot="modal-footer"
        class="pa3 flex-ns flex-wrap justify-end"
      >
        <div class="mr3-ns">
          <ak-button
            block
            secondary
            @click.native="resetState"
          >
            Close
          </ak-button>
        </div>
      </div>
    </ak-modal>
  </main>
</template>
<script>
import AkAlert from './Alert'
import AkButton from './Button'
import AkModal from './Modal'
import {
  INVALID_MEMBERSHIP_DISPLAY_TEXT,
  ITEM_SPEC_NOT_COVERED,
  ITEM_SPEC_LIMIT_REACHED_PREFIX,
  ITEM_SPEC_LIMIT_REACHED_SUFFIX } from '../constants/add-devices/addDevices'
import { mapState, mapGetters, mapActions } from 'vuex'

// Properly format todays day
const d = new Date()
const year = d.getFullYear()
const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`
const day = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`
const today = `${year}-${month}-${day}`

export default {
  name: 'AkAddDeviceV2',
  components: {
    AkButton,
    AkAlert,
    AkModal
  },
  props: {
    categoryData: {
      type: Object
    }
  },
  data () {
    return {
      nickname: '',
      imei: '',
      price: '',
      serial_number: '',
      model_number: '',
      screen_size: 0,
      touchscreen: false,
      storage: 0,
      carrier: '',
      device_carrier_other: '',
      installments: false,
      condition: 'new',
      purchased_at: '',
      purchase_date: today,
      itemTypeIndex: -1,
      itemMakeIndex: -1,
      itemModelIndex: -1,
      itemMakeString: '',
      itemModelString: '',
      restrictionLimitReachedMessage: '',
      errorDetected: false
    }
  },
  mounted () {
    this.getDevices()
  },
  methods: {
    ...mapActions([
      'addDeviceV2',
      'getDevices'
    ]),
    addDevice () {
      this.addDeviceV2(this.createPayload())
    },
    resetState () {
      this.restrictionLimitReachedMessage = ''
      this.errorDetected = false
      this.itemTypeIndex = -1
      this.changeType()
    },
    countDeviceTypesByItemType (itemType) {
      return this.$store.state.devices.filter((device) => device.type === itemType).length + 1
    },
    changeType () {
      this.changeMake()
      this.itemMakeIndex = -1
      this.imei = ''
      this.nickname = `My ${this.itemTypeName === 'Other' ? 'Item ' + this.countDeviceTypesByItemType(this.device_type) : this.itemTypeName.charAt(0).toUpperCase() + this.itemTypeName.slice(1) + ' ' + this.countDeviceTypesByItemType(this.device_type)}`
      this.validateSelectedItemAttribute(this.itemRestrictions.item_types, this.selectedItemType, 'type')
    },
    changeMake () {
      this.changeModel()
      this.itemModelIndex = -1
      this.itemMakeString = ''
      this.validateSelectedItemAttribute(this.itemRestrictions.item_makes, this.selectedItemMake, 'brand')
    },
    changeModel () {
      this.itemModelString = ''
      this.price = ''
      this.serial_number = ''
      this.model_number = ''
      this.screen_size = 0
      this.touchscreen = false
      this.storage = 0
      this.carrier = null
      this.device_carrier_other = ''
      this.installments = false
      this.restrictionLimitReachedMessage = ''
      this.validateSelectedItemAttribute(this.itemRestrictions.item_models, this.selectedItemModel, 'model')
    },
    toggleKeyboard (trueOrFalse) {
      this.$store.commit('TOGGLE_KEYBOARD', trueOrFalse)
    },
    createPayload () {
      // TODO: modify after implementing create device v2
      let payload = {
        category: this.categoryData.name, // item category string
        type: this.device_type, // item type name string, for some reason this field needs to be lower case in create device v1
        make: this.itemMakeName, // item make name string
        model: this.itemModelName, // item model name string

        item_category: this.categoryData.id,
        item_type: this.selectedItemType.id ? this.selectedItemType.id : undefined,
        item_make: this.selectedItemMake.id ? this.selectedItemMake.id : undefined,
        item_model: this.selectedItemModel ? this.selectedItemModel.id : undefined,

        nickname: this.nickname,
        price: this.price,
        serial_number: this.serial_number,
        model_number: this.model_number,
        screen_size: this.screen_size,
        touchscreen: this.touchscreen,
        storage: this.storage,
        carrier: this.carrier,
        device_carrier_other: this.device_carrier_other,
        imei: this.imei,
        condition: this.condition,
        purchased_at: this.purchased_at,
        purchase_date: this.purchase_date,
        installments: this.installments
      }
      return payload
    },
    validateSelectedItemAttribute (itemRestrictionsList, selectedItemAttribute, itemSpecName) {
      if (selectedItemAttribute) {
        let errorDetected = false
        let itemSpecRestriction = itemRestrictionsList.find((itemSpec) => {
          return itemSpec.id === selectedItemAttribute.id
        })
        if (itemSpecRestriction) {
          if (itemSpecRestriction.quantity_allowed === 0) {
            errorDetected = true
            this.restrictionLimitReachedMessage =
              `Item ${itemSpecName} ${this.selectedItemType.name.toLowerCase()} ` + ITEM_SPEC_NOT_COVERED
          } else if (itemSpecRestriction.remainder === 0) {
            errorDetected = true
            this.restrictionLimitReachedMessage =
              ITEM_SPEC_LIMIT_REACHED_PREFIX +
              `item ${itemSpecName} ${this.selectedItemType.name.toLowerCase()}. ` +
              ITEM_SPEC_LIMIT_REACHED_SUFFIX
          }
        }
        this.errorDetected = errorDetected
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user
    }),
    ...mapGetters({
      itemRestrictions: 'itemRestrictions',
      itemPurchaseConditions: 'itemPurchaseConditions',
      mobileOperators: 'mobileOperators'
    }),
    todayString () {
      return today
    },
    formComplete () {
      let basicCheck = this.device_type !== '' &&
        this.itemMakeName !== '' &&
        this.itemModelName !== '' &&
        this.nickname !== '' &&
        this.condition !== '' &&
        this.purchase_date !== '' &&
        (this.device_type === 'laptop' || this.device_type === 'phone' || this.device_type === 'tablet' ? this.serial_number !== '' : true) &&
        (this.device_type === 'phone' ? this.imei !== '' : true) &&
        this.priceValid && this.purchased_at !== ''
      return basicCheck
    },
    priceValid () {
      return this.price !== '' && parseInt(this.price) > 0 && parseInt(this.price) <= 99999
    },
    submitBtnClass () {
      return {'not-allowed-cursor': !this.formComplete, 'inter': true}
    },
    user () {
      return this.$store.state.user
    },
    userHasAccess () {
      const status = this.user.membership_status
      return status !== 'BANNED' && status !== 'CANCELED' && status !== 'UNPAID' && status !== 'PAST_DUE' && status !== 'INACTIVE' && status !== 'INVALID_USER'
    },
    membershipStatusDisplayValues () {
      return INVALID_MEMBERSHIP_DISPLAY_TEXT[this.user.membership_status]
    },
    itemTypes () {
      return this.categoryData ? this.categoryData.item_types : []
    },
    itemMakes () {
      return this.itemTypeIndex > -1 ? this.itemTypes[this.itemTypeIndex].item_makes : []
    },
    itemModels () {
      return this.itemMakeIndex !== 'other' && this.itemMakeIndex > -1
        ? this.itemMakes[this.itemMakeIndex].item_models : []
    },
    selectedItemType () {
      return this.itemTypeIndex === -1 ? false : this.itemTypes[this.itemTypeIndex]
    },
    itemTypeName () {
      return this.selectedItemType ? this.selectedItemType.name : ''
    },
    device_type () { // old computed propertly from add device v1
      return this.itemTypeName.toLowerCase()
    },
    selectedItemMake () {
      if (this.itemMakeIndex === 'other') {
        return false
      }
      return this.itemMakeIndex === -1 ? false : this.itemMakes[this.itemMakeIndex]
    },
    itemMakeName () {
      if (this.selectedItemMake) {
        return this.selectedItemMake.name
      } else {
        return this.itemMakeIndex === 'other' ? 'other' : this.itemMakeString
      }
    },
    selectedItemModel () {
      if (this.itemModelIndex === 'other') {
        return false
      }
      return this.itemModelIndex === -1 ? false : this.itemModels[this.itemModelIndex]
    },
    itemModelName () {
      if (this.selectedItemModel) {
        return this.selectedItemModel.name
      } else {
        return this.itemModelIndex === 'other' ? 'other' : this.itemModelString
      }
    },
    isOtherMobileCarrierSelected () {
      let otherCarrier = this.mobileOperators.find((operator) => operator.name === 'Other')
      return otherCarrier === undefined ? false : this.carrier === otherCarrier.id
    },
    available_years () {
      const currentYear = (new Date()).getFullYear()
      let availableYears = []
      for (let i = 0; i < 20; i++) {
        availableYears.push((currentYear - i).toString())
      }
      return availableYears
    }
  }
}
</script>

<style scoped>
.flex-basis-4 {
  flex-basis: 16em;
}

.flex-basis-3 {
  flex-basis: 12em;
}

.flex-basis-25 {
  flex-basis: 23%;
}

label, legend {
  color: #757D8A;
}

.title-responsive {
    margin-top: 1.5rem;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #757D8A;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #757D8A;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #757D8A;
}

.br5-box-left {
  border-radius: 5px 0 0 5px;
}

.br5-box-right {
  border-radius: 0 5px 5px 0;
}

input, select {
  height: 40px;
  border-radius: 5px;
  border: 1px solid #E8E8E8;
}

.b--grey {
  border-color: #E8E8E8;
}

.placeholder {
  color: #7e7e7e;
}

input[type=radio]:checked + label {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: #FFFFFF;
  border-radius: 5px;
}

input[type=radio]:checked + label.br5-box-left {
  margin-right: -5px;
  z-index: 1;
}

input[type=radio]:checked + label.br5-box-right {
  margin-left: -5px;
  z-index: 1;
}

.grid-fourth {
  grid-column-gap: 1.5em;
  grid-row-gap: 2.25em;
}

.not-allowed-cursor {
  cursor: not-allowed;
}

.not-allowed-text-color {
  color: #cacaca;
}

@media (max-width: 768px) {

  .title-responsive {
    font-size: 24px;
    margin-bottom: -10px;
    margin-top: 1rem;
  }
}

/* Hide input number arrows in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide input number arrows in  Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
