<template>
  <div :class="`flex ${justify} ${divClass}`">
    <img
      :src="svg"
      alt=""
      class="mr1 self-start"
      style="margin-right: 5px; min-width: 20px; margin-top: 1px"
      :style="svgStyle"
    >
    <label
      :class="labelClass"
      class="inter"
      v-html="text"
    />
  </div>
</template>

<script>
export default {
  name: 'AkIconWithText',
  props: {
    text: {
      type: String,
      default: ''
    },
    svg: {
      type: String,
      default: ''
    },
    svgStyle: {
      type: String,
      default: ''
    },
    divClass: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    justify: {
      type: String,
      default: 'justify-center'
    }
  }
}
</script>
