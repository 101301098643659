<template>
  <div>
    <div class="flex flex-row justify-between mv2 f6 total-price">
      <label class="inter black-40">Sub Total</label>
      <div class="flex flex-column inter items-end">
        {{ subTotal }}
      </div>
    </div>
    <div
      v-show="discount > 0 || filteredPhones.length > 1"
      class="flex flex-row justify-between mb2 mt3 f6 total-price"
    >
      <label class="inter black-40">Discounts:</label>
      <div class="flex flex-column inter items-end">
        <div
          v-for="discountRow, index in discountRows"
          :key="index"
        >
          <div class="flex flex-row inter items-center mb1">
            <div class="inter black-50 ml2 f7">
              {{ discountRow.label }}
            </div>
            <div class="inter">
              &nbsp;&nbsp;{{ `-$${discountRow.value.toFixed(2)}` }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between mv3 f4 total-price">
      <label class="cera-round-pro black-40">
        Payment Due Today
      </label>
      <div class="flex flex-column inter items-end">
        {{ paymentDueToday }}
        <div
          v-if="total === (1).toFixed(2)"
          class="inter black-50 f7 mt1"
        >
          One-time verification charge
        </div>
      </div>
    </div>
    <div
      v-if="showSwitch && !disableIntervalToggle"
      class="flex justify-end"
    >
      <div
        class="color-theme f6 pointer"
        @click="monthlyToggle ? setSecondValue() : setFirstValue() "
      >
        {{ monthlyToggle ? `Switch to annual - Save $${savings.toFixed(2)}!` : 'Switch to monthly' }}
      </div>
    </div>
    <div class="flex flex-row justify-between mv2 f5 total-price">
      <label class="cera-round-pro black-40">{{ billableAfterVerbiage }}</label>
      <div class="flex flex-column inter margin items-end billable-after">
        {{ billableAfter }}
      </div>
    </div>
  </div>
</template>
<script>

import {mapGetters} from 'vuex'
import {GET_PARTNER_UI_SETTINGS, GET_REGISTRATION_DISCOUNTS} from '../../../../store/registrationv2'
import DISCOUNT_CODE from '../../../../constants/registration/discountCode'
import {FAMILY_PLAN_DISCOUNT} from '../../../../constants/registration/choosePlan'

export default {
  name: 'CheckoutSummary',
  props: {
    twoYearsUpfrontSelected: Boolean,
    hasNonCouponDiscount: Boolean,
    monthlyToggle: Boolean,
    disableIntervalToggle: Boolean,
    setFirstValue: Function,
    setSecondValue: Function,
    total: String,
    discount: Number,
    price: Number,
    isBiggerCoupon: Boolean,
    billablePerYear: Number,
    savings: Number,
    nonCouponDiscount: Number,
    totalPrice: String,
    enteredDiscount: Object,
    twoYearsUpfrontDiscountAmount: Number,
    filteredPhones: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      discounts: GET_REGISTRATION_DISCOUNTS,
      uiSettings: GET_PARTNER_UI_SETTINGS
    }),
    showSwitch () {
      if (this.uiSettings.hide_plan_interval_switch !== undefined) {
        return !this.uiSettings.hide_plan_interval_switch
      }
      return true
    },
    multiPlanDiscountOffset () {
      if (this.discounts && this.discounts.find(d => d.code === DISCOUNT_CODE.FAMILY_PLAN)) {
        return this.price / FAMILY_PLAN_DISCOUNT - this.price
      } else {
        return 0
      }
    },
    billableAfter () {
      return `$${this.billablePerYear.toFixed(2)} / ${this.interval}`
    },
    interval () {
      return this.monthlyToggle ? 'month' : 'year'
    },
    discountRows () {
      let discountsArray = []
      if (this.filteredPhones.length > 1 && !this.isBiggerCoupon) {
        discountsArray.push({label: '5% Multi-Plan Discount Applied', value: this.multiPlanDiscountOffset})
      }
      if (this.enteredDiscount.code_name) {
        discountsArray.push({label: this.enteredDiscount.code_name, value: this.discount})
      }
      if (this.twoYearsUpfrontSelected) {
        discountsArray.push({label: 'Two Years upfront', value: this.twoYearsUpfrontDiscountAmount})
      }
      return discountsArray
    },
    subTotal () {
      let subTotal = this.price + this.multiPlanDiscountOffset
      return `$${subTotal.toFixed(2)} / ${this.interval}`
    },
    paymentDueToday () {
      let value = (this.totalPrice * (this.twoYearsUpfrontSelected ? 2 : 1)).toFixed(2)
      return `$${value}`
    },
    billableAfterVerbiage () {
      if (this.monthlyToggle) {
        return 'Billable after first month'
      }
      if (this.twoYearsUpfrontSelected) {
        return 'Billable after second year'
      }
      return 'Billable after first year'
    }
  }
}

</script>
<style scoped>

@media (max-width: 365px) {
  .billable-after {
    white-space: nowrap;
  }
}

@media (max-width: 240px) {
  .billable-after {
    white-space: normal;
  }
}

</style>
