<template>
  <div class="flex flex-column">
    <div
      v-if="title"
      class="flex flex-column"
    >
      <label class="cera-round-pro fw6 db mb3 title">{{ title }}</label>
      <label
        v-if="subtitle"
        class="f6 silver inter mb4"
      >{{ subtitle }}</label>
    </div>
    <br v-if="isOpenMessage">
    <div class="flex  justify-between responsive-email">
      <div class="flex responsive w-100">
        <div class="flex flex-column max-card w-50 responsive">
          <input
            v-focus="autoFocus"
            class="inter f6 input-reset ba db mr3 br2 code-input"
            :placeholder="firstLabel"
            :value="firstValue"
            :maxlength="maxLength"
            @input="setFirstValue"
          >
        </div>
        <div class="flex flex-column w-50 responsive">
          <input
            v-focus="autoFocus"
            class="inter f6 input-reset ba db br2 code-input responsive-last"
            :placeholder="secondLabel"
            :value="secondValue"
            :maxlength="maxLength"
            @input="setSecondValue"
          >
        </div>
      </div>
      <div class="flex responsive w-100">
        <div class="flex flex-column max-card w-50 responsive">
          <input
            class="inter f6 input-reset ba db br2 mr3 code-input"
            :placeholder="thirdLabel"
            :value="thirdValue"
            @input="setThirdValue"
            @focus="emailInput(false)"
            @blur="emailInput(true)"
          >
          <div
            class="color-theme f6 mt2 w-100 mb2"
            :class="showEmailError ? '' : 'invisible'"
          >
            {{ emailError ? 'Please enter a valid email' : "Student's Email (use .edu address)" }}
          </div>
        </div>
        <div class="flex flex-column w-50 responsive">
          <input
            v-focus="autoFocus"
            class="inter f6 input-reset ba db br2 code-input responsive-last"
            :placeholder="fourthLabel"
            :value="fourthValue"
            :maxlength="maxLength"
            @input="setFourthValue"
          >
        </div>
      </div>
    </div>
    <div
      v-if="showStudentMessage"
      class="f6 silver inter mt4 mb2"
    >
      If you do not have a .edu email, enter an alternate email address.
      <br>
      You will need to provide verification of student status.
    </div>
  </div>
</template>

<script>

export default {
  name: 'PersonalForm',
  props: {
    firstLabel: String,
    firstValue: String,
    secondLabel: String,
    secondValue: String,
    thirdLabel: String,
    thirdValue: String,
    fourthLabel: String,
    fourthValue: String,
    isOpenMessage: Boolean,
    phoneError: {
      type: Boolean,
      default: false
    },
    emailError: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    studentError: {
      type: Boolean,
      default: false
    },
    emailUnfocus: {
      type: Boolean,
      default: true
    },
    maxLength: {
      type: Number,
      default: null
    },
    isCheckout: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  },
  computed: {
    showEmailError () {
      return (
        ((this.studentError && this.emailUnfocus) || this.emailError) &&
        this.thirdValue !== ''
      )
    },
    showStudentMessage () {
      return (
        this.studentError &&
        this.emailUnfocus &&
        !this.emailError &&
        this.thirdValue !== ''
      )
    }
  },
  methods: {
    setFirstValue (event) {
      this.$emit('update:firstValue', event.target.value)
    },
    setSecondValue (event) {
      this.$emit('update:secondValue', event.target.value)
    },
    setThirdValue (event) {
      this.$emit('update:thirdValue', event.target.value)
    },
    setFourthValue (event) {
      this.$emit('update:fourthValue', event.target.value)
    },
    emailInput (unfocus) {
      this.$emit('update:emailUnfocus', unfocus)
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 20px;
  }
  .responsive-last {
    margin-right: 1rem;
  }
  .responsive-email {
      width: 100%;
      flex-direction: column;
    }
    .responsive-last {
    margin-right: 0;
    margin-bottom: 1em;
    }
  .code-input {
    border-color: var(--theme-gray);
    padding: 10px 15px 10px 15px;
  }
  .code-input::placeholder {
    color: var(--theme-gray);
  }
  .code-input:focus {
    border-color: var(--theme-gray);
    outline: none;
  }
  .invisible {
    display: none;
  }
  @media (max-width: 450px) {
    .responsive {
      width: 100%;
      flex-direction: column;
    }
    .max-card{
      margin-bottom: 1em;
    }
    .code-input {
      margin-right: 0;
    }
  }

</style>
